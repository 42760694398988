import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';

import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hourPart, minutePart } from 'global/services/DateTimeService';

import '../Statistics.scss';
import './StatsHeader.scss';

class StatsHeader extends Component {

    static defaultProps = {
        createdIssues: '-',
        snoozedIssues: '-',
        closedIssues: '-',
        avgResolutionTime: '-'
    };

    render() {
        let avgResolutionTime = this.props.avgResolutionTime;
        if (avgResolutionTime !== '-') {
            avgResolutionTime = (
                <>
                    { hourPart(this.props.avgResolutionTime) }
                    <span className="time"> h </span>
                    { minutePart(this.props.avgResolutionTime) }
                    <span className="time"> min </span>
                </>
            );
        }

        return (
            <div className="team-card info-card stats-header">
                <div className="issue-count-heading row equal grid-divider">
                    <div className="col">
                        Created Issues
                    </div>
                    <div className="col">
                        Snooze History
                    </div>
                    <div className="col">
                        Closed Issues
                    </div>
                    <div className="col">
                        Avg. Resolution Time
                    </div>
                </div>
                <div className="issue-count large-font row grid-divider small-screen-font">
                    <div className="col">
                        <span> { this.props.createdIssues } </span>
                    </div>
                    <div className="col">
                        <span> { this.props.snoozedIssues } </span>
                        { this.props.snoozedIssues !== '-' && (
                            <Link to={ `/statistics/snoozed${ window.location.search }` }
                                  target="_blank"
                                  rel="noopener noreferrer">
                                <FontAwesomeIcon icon={ faArrowUpRightFromSquare } className="issue-link-icon" />
                            </Link>
                        ) }
                    </div>
                    <div className="col">
                        <span>{ this.props.closedIssues } </span>
                        { this.props.closedIssues !== '-' && (
                            <Link to={ `/statistics/closed${ window.location.search }` }
                                  target="_blank"
                                  rel="noopener noreferrer">
                                <FontAwesomeIcon icon={ faArrowUpRightFromSquare } className="issue-link-icon" />
                            </Link>
                        ) }
                    </div>
                    <div className="col">
                        { avgResolutionTime }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(StatsHeader);
