import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell as notificationsIcon, faTimesCircle as closeIssueReasonsIcon } from '@fortawesome/free-solid-svg-icons';
import { faAlarmSnooze as snoozeIssueReasonsIcon, faGarage as dropYardsIcon } from '@fortawesome/pro-solid-svg-icons';

import {
    adminCloseReasonNotesUrl,
    adminSnoozeReasonNotesUrl,
    closeReasonsUrl,
    contactGroupsUrl,
    notificationIssueTypeRulesUrl,
    notificationRulesUrl,
    snoozeReasonsUrl
} from 'global/constants';
import history from "global/history";
import AuthorizationService from 'global/services/AuthorizationService';
import { PERMISSION } from 'global/utils/auth';
import NotificationsManagement from './NotificationsManagement';
import EmailRecipientGroups from './EmailRecipientGroups';
import CloseIssueReasons from './CloseIssueReasons';
import SnoozeIssueReasons from './SnoozeIssueReasons';
import DropYards from './DropYards';
import AuthContext from 'AuthContext';

import "./Settings.scss";

class Settings extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            closeReasons: [],
            closeNotes: [],
            snoozeReasons: [],
            snoozeNotes: [],
            tab: this.props.tab,
            contactGroups: [],
            notificationRules: [],
            notificationIssueTypeRules: [],
        }
    }

    async componentDidMount() {
        try {
            let closeReasons = [];
            let closeNotes = [];
            let snoozeReasons = [];
            let snoozeNotes = [];
            let contactGroups = [];
            let notificationRules = [];
            let notificationIssueTypeRules = [];

            const closeResponse = await this.context.get(closeReasonsUrl, {});

            if (closeResponse.status !== "error") {
                closeReasons = closeResponse.data
            }

            const closeNotesResponse = await this.context.get(adminCloseReasonNotesUrl, {});

            if (closeNotesResponse.status !== "error") {
                closeNotes = closeNotesResponse.data
            }

            const snoozeResponse = await this.context.get(snoozeReasonsUrl, {});

            if (snoozeResponse.status !== "error") {
                snoozeReasons = snoozeResponse.data
            }

            const snoozeNotesResponse = await this.context.get(adminSnoozeReasonNotesUrl, {});

            if (snoozeNotesResponse.status !== "error") {
                snoozeNotes = snoozeNotesResponse.data
            }

            const contactGroupsResponse = await this.context.get(contactGroupsUrl, {});

            if (contactGroupsResponse.status !== "error") {
                contactGroups = contactGroupsResponse.data
            }

            const notificationRulesResponse = await this.context.get(notificationRulesUrl, {});

            if (notificationRulesResponse.status !== "error") {
                notificationRules = notificationRulesResponse.data
            }

            const issueTypeRulesResponse = await this.context.get(notificationIssueTypeRulesUrl, {});

            if (issueTypeRulesResponse.status !== "error") {
                notificationIssueTypeRules = issueTypeRulesResponse.data
            }

            this.setState({
                closeReasons,
                closeNotes,
                snoozeReasons,
                snoozeNotes,
                contactGroups,
                notificationRules,
                notificationIssueTypeRules,
            });
        } catch (error) {
            console.error(error);
            this.setState({
                closeReasons: [],
                closeNotes: [],
                snoozeReasons: [],
                snoozeNotes: [],
                contactGroups: [],
                notificationRules: [],
                notificationIssueTypeRules: [],
            });
        }
    }

    handleAdd(newReason, snooze) {
        if (snooze) {
            this.setState({
                snoozeReasons: [...this.state.snoozeReasons, newReason]
            });
        } else {
            this.setState({
                closeReasons: [...this.state.closeReasons, newReason]
            });
        }

    }

    handleUpdate(updatedReason, snooze) {
        if (snooze) {
            this.setState({
                snoozeReasons: this.state.snoozeReasons.map(reason => reason.id === updatedReason.id ? updatedReason : reason)
            });
        } else {
            this.setState({
                closeReasons: this.state.closeReasons.map(reason => reason.id === updatedReason.id ? updatedReason : reason)
            });
        }
    }

    handleDelete(deletedReason, snooze) {
        if (snooze) {
            this.setState({
                snoozeReasons: this.state.snoozeReasons.filter(reason => reason.id !== deletedReason.id)
            });
        } else {
            this.setState({
                closeReasons: this.state.closeReasons.filter(reason => reason.id !== deletedReason.id)
            });
        }
    }

    changeTab = (tab) => history.push("/settings/" + tab);

    addRule(rule) {
        this.setState({
            notificationRules: [...this.state.notificationRules, rule]
        });
    }

    updateRule(updatedRule) {
        this.setState({
            notificationRules: this.state.notificationRules.map(rule => rule.id === updatedRule.id ? updatedRule : rule)
        });
    }

    addContactGroup(group) {
        this.setState({
            contactGroups: [...this.state.contactGroups, group]
        });
    }

    updateContactGroup(updatedGroup) {
        this.setState({
            contactGroups: this.state.contactGroups.map(group => group.id === updatedGroup.id ? updatedGroup : group)
        });
    }

    deleteContactGroup(groupId) {
        this.setState({
            contactGroups: this.state.contactGroups.filter(group => group.id !== groupId)
        });
    }

    addMember(member, contactGroup) {
        this.setState({
            contactGroups: this.state.contactGroups.map(group => {
                if (group.id !== contactGroup.id || group.contactInfos.some(contact => contact.id === member.id)) {
                    return group;
                }
                return {...group, contactInfos: [ ...group.contactInfos, member ]};
            })
        });
    }

    updateIssueTypeRule(updatedRule) {
        this.setState({
            notificationIssueTypeRules: this.state.notificationIssueTypeRules.map(rule => rule.id === updatedRule.id ? updatedRule : rule)
        });
    }

    render() {
        const tabs = [];
        const canReadAll = AuthorizationService.canAccess(this.context.permissions, PERMISSION.SETTINGS.READ);

        if (canReadAll || AuthorizationService.canAccess(this.context.permissions, PERMISSION.SETTINGS.NOTIFICATIONS.READ)) {
            const notificationsTabTitle = (
                <>
                    <FontAwesomeIcon icon={ notificationsIcon } className="tab-icon" />
                    Notifications
                </>
            );

            const notificationsTab = (
                <Tab key="notifications" eventKey="notifications" title={notificationsTabTitle} tabClassName="tab">
                    <NotificationsManagement
                        addRule={this.addRule.bind(this)}
                        updateRule={this.updateRule.bind(this)}
                        updateIssueTypeRule={this.updateIssueTypeRule.bind(this)}
                        contactGroups={this.state.contactGroups}
                        notificationRules={this.state.notificationRules}
                        notificationIssueTypeRules={this.state.notificationIssueTypeRules}
                    />
                    <EmailRecipientGroups
                        contactGroups={this.state.contactGroups}
                        addContactGroup={this.addContactGroup.bind(this)}
                        updateContactGroup={this.updateContactGroup.bind(this)}
                        deleteContactGroup={this.deleteContactGroup.bind(this)}
                        addMember={this.addMember.bind(this)}
                    />
                </Tab>
            );

            tabs.push(notificationsTab);
        }

        if (canReadAll || AuthorizationService.canAccess(this.context.permissions, PERMISSION.SETTINGS.CLOSE_REASONS.READ)) {
            const closeIssueReasonsTabTitle = (
                <>
                    <FontAwesomeIcon icon={ closeIssueReasonsIcon } className="tab-icon" />
                    Close Issue Reasons
                </>
            );

            const closeIssueReasonsTab = (
                <Tab key="close-reasons" eventKey="close-reasons" title={closeIssueReasonsTabTitle} tabClassName="tab">
                    <CloseIssueReasons
                        closeReasons={this.state.closeReasons}
                        closeNotes={this.state.closeNotes}
                        handleAdd={this.handleAdd.bind(this)}
                        handleUpdate={this.handleUpdate.bind(this)}
                        handleDelete={this.handleDelete.bind(this)}
                    />
                </Tab>
            );

            tabs.push(closeIssueReasonsTab);
        }

        if (canReadAll || AuthorizationService.canAccess(this.context.permissions, PERMISSION.SETTINGS.SNOOZE_REASONS.READ)) {
            const snoozeIssueReasonsTabTitle = (
                <>
                    <FontAwesomeIcon icon={ snoozeIssueReasonsIcon } className="tab-icon" />
                    Snooze Issue Reasons
                </>
            );

            const snoozeIssueReasonsTab = (
                <Tab key="snooze-reasons" eventKey="snooze-reasons" title={snoozeIssueReasonsTabTitle} tabClassName="tab">
                    <SnoozeIssueReasons
                        snoozeReasons={this.state.snoozeReasons}
                        snoozeNotes={this.state.snoozeNotes}
                        handleAdd={this.handleAdd.bind(this)}
                        handleUpdate={this.handleUpdate.bind(this)}
                        handleDelete={this.handleDelete.bind(this)}
                    />
                </Tab>
            );

            tabs.push(snoozeIssueReasonsTab);
        }

        if (canReadAll || AuthorizationService.canAccess(this.context.permissions, PERMISSION.SETTINGS.DROP_YARDS.READ)) {
            const dropYardsTabTitle = (
                <>
                    <FontAwesomeIcon icon={ dropYardsIcon } className="tab-icon" />
                    Drop Yards
                </>
            );

            const dropYardsTab = (
                <Tab key="drop-yards" eventKey="drop-yards" title={ dropYardsTabTitle } tabClassName="tab">
                    <DropYards />
                </Tab>
            );

            tabs.push(dropYardsTab);
        }

        return (
            <div className="container-fluid page settings">
                <div className="row">
                    <div className="col">
                        <h1 className="page-title">
                            Settings
                        </h1>
                    </div>
                </div>
                <Tabs id="settings-tabs" defaultActiveKey={this.state.tab} mountOnEnter unmountOnExit onSelect={this.changeTab}>
                    { tabs }
                </Tabs>
            </div>
        )
    }
}

export default Settings;
