import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";
import CustomDropdown from "components/input/CustomDropdown";
import { mapDivisionToColor } from 'global/constants'
import { hourPart, minutePart } from 'global/services/DateTimeService';

class InfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
      let colorStyle= {
          borderTop: `4px solid ${mapDivisionToColor(this.props.team)}`
      };

      return (
        <div className="team-card info-card" style={colorStyle}>
          <div className="issue-count-heading row equal is-flex  grid-divider"> 
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2" >
              Orders
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              Issues
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              Resolved issues
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 nowrap">
              Avg. resolution time
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              Snoozed issues
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              Members
            </div>
          </div>
          <div className="issue-count large-font row grid-divider small-screen-font"> 
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 flex">
              <span>{this.props.orderCount || 0} </span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 flex">
              <span>{this.props.issueCount || 0}</span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 flex">
              <span>{this.props.resolvedIssueCount || 0}</span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 flex nowrap-always">
              <span className="small-screen-font">{hourPart(this.props.avgResolutionTime || 0)}<span className="time right-margin"> h </span> {minutePart(this.props.avgResolutionTime || 0)} <span className="time"> min </span> </span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 flex ">
              <span>{this.props.snoozedIssueCount || 0}</span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 flex">
              { this.props.membersLoaded ?
                  <span className="members-number">
                    { this.props.members.length }
                  </span>
              : <Loader
                  type="TailSpin"
                  color="#289AC2"
                  height={ 20 }
                  width={ 20 }
                  visible={ !this.props.membersLoaded }
                  className="members-number"
                />
              }
              { this.props.membersLoaded && this.props.members.length ?
                  <CustomDropdown
                      title="SEE MEMBERS"
                      items={ this.props.members.map(member => (
                          <Link
                              key={ member.id }
                              className="teams-members no-style-link"
                              to={ {
                                pathname: '/statistics/user/' + member.id,
                                state: { userName: member.name, team: this.props.team }
                              } }
                          >
                              <div className="circle">
                                  <div className="initials">
                                      { member.name.split(" ").map((n) => n[0]) }
                                  </div>
                              </div>
                              <div className="member-name">
                                  { member.name }
                              </div>
                          </Link>
                      )) }
                  />
              : null }
            </div>
          </div>
        </div>
    );
  }
}
export default InfoCard;