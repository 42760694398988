import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { faPlusCircle as addIcon } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash as deleteIcon } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'react-bootstrap';
import { adminYardsUrl, defaultGridRecordsNumber, mapSort } from 'global/constants';
import { PERMISSION } from 'global/utils/auth';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import CanAccess from 'components/can-access/CanAccess';
import SimpleContainer from 'components/container/SimpleContainer';
import DataTableComp from 'components/datatable/DataTableComp';
import AddDropYardPopup from 'components/popup/AddDropYardPopup';
import DeletePopup from "components/popup/DeletePopup";
import AuthorizationService from "global/services/AuthorizationService";
import AuthContext from 'AuthContext';

import './DropYards.scss';

export default class DropYards extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.canWrite = AuthorizationService.canAccess(
            context.permissions,
            [PERMISSION.SETTINGS.WRITE, PERMISSION.SETTINGS.DROP_YARDS.WRITE]
        );

        this.columnNames = {
            0: 'YARD',
            1: 'COMPANY_NAME',
            2: 'COMPANY_ADDRESS',
            3: 'ACTIONS'
        };

        this.columns = [
            { title: 'Drop Yard', data: 'company.businessId' },
            { title: 'Name', data: 'company.name' },
            { title: 'Address', data: 'company.address' },
            { title: 'Actions', data: "action", defaultContent: "" },
        ];

        this.columnDefs = [
            { width: '20%', 'targets': [0] },
            { width: '35%', 'targets': [1, 2] },
            { width: '10%', 'targets': [3] },
            { className: "dt-align-center", targets: [3] },
            {
                searchable: false,
                targets: [0, 1, 2, 3],
            },
            {
                orderable: false,
                targets: [3]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(rowData.company.businessId, td)
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(rowData.company.name, td)
            },
            {
                targets: 2,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(rowData.company.address, td)
            },
            {
                targets: 3,
                createdCell: (td, _cellData, rowData) => {
                    ReactDOM.render(
                        <div className="actions">
                            <FontAwesomeIcon icon={ deleteIcon } onClick={ () => this.deleteYardClick(rowData) }/>
                        </div>,
                        td
                );
            },
            visible: this.canWrite
            }
        ];

        this.state = {
            showAddModal: false,
            showDeletePopup: false,
            yard: {},
            dropYards: [],
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            },
            sort: []
        };
        
        this.onAddYard = this.onAddYard.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleFirstPage = this.handleFirstPage.bind(this);
        this.handleLastPage = this.handleLastPage.bind(this);
        this.handleRecordsNumber = this.handleRecordsNumber.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.addSortingForColumn = this.addSortingForColumn.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
    }

    componentDidMount() {
        this.fetchYards();
    }

    async fetchYards() {
        try {
            const sortingArray = this.state.sort;
            const sort = sortingArray ? mapSort(sortingArray) : [];
            const response = await this.context.get(adminYardsUrl, {
                local: false,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber,
                sort
            });

            let dropYards = [];

            if (response.status !== 'error') {
                dropYards = response.data;
            }

            this.setState({
                dropYards,
                pagingInfo: mapGridResponseData(dropYards, response).pagingInfo
            });
        } catch (error) {
            console.error(error);
        }
    }

    onAddYard() {
        this.setState({
            showAddModal: true
        });
    }

    onCloseModal(dropYard) {
        this.setState({
            showAddModal: false
        });

        if (dropYard) {
            this.fetchYards();
        }
    }

    deleteYardClick = (yard) => {
        this.setState({
            showDeletePopup: true,
            yard
        });
    }

    onCloseDeleteModal(deletedYard) {
        this.setState({
            showDeletePopup: false
        });
        if (deletedYard) {
            this.fetchYards();
        }
    }

    handlePage(page) {
        this.setState({ page }, this.fetchYards);
    }

    handlePreviousPage() {
        this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage() {
        this.handlePage(parseInt(this.state.page) + 1);
    }

    handleFirstPage() {
        this.handlePage(1);
    }

    handleLastPage() {
        this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handleRecordsNumber(event) {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
        }, this.fetchYards);
    }

    handlePageChange(event) {
        const value = event.target.value;
        if (isNaN(value) || value === '') {
            this.setState({
                page: ''
            });
        } else {
            this.setState({
                page: parseInt(value)
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchYards();
            }, 1000);
        }
        this.setState(prevState => ({
            pagingInfo: {
                ...prevState.pagingInfo,
                currentPage: prevState.page
            }
        }));
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const existing = sortingArray.findIndex(x => x && x.columnName === this.columnNames[index]);

        if (existing === -1) {
            sortingArray.push({
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            });
        } else if (sorting === '') {
            sortingArray.splice(existing, 1);
        } else {
            sortingArray[existing] = {
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            }
        }

        this.setState({ sort: sortingArray }, this.fetchYards);
    }

    render() {
        return (
            <div className="drop-yards">
                <div className="row">
                    <div className="col">
                        <CanAccess
                            action={ [PERMISSION.SETTINGS.WRITE, PERMISSION.SETTINGS.DROP_YARDS.WRITE] }
                            yes={
                                <Button variant="continue" onClick={ this.onAddYard }>
                                    <FontAwesomeIcon icon={ addIcon } className="add-icon" />
                                    ADD DROP YARD
                                </Button>
                            }
                        />
                    </div>
                </div>
                <SimpleContainer title="DROP YARDS">
                    <DataTableComp
                        tableId="dropYards"
                        tableHeight="none"
                        columns={ this.columns }
                        columnDefs={ this.columnDefs }
                        data={ this.state.dropYards }
                        handlePreviousPage={ this.handlePreviousPage }
                        handleNextPage={ this.handleNextPage }
                        handleFirstPage={ this.handleFirstPage }
                        handleLastPage={ this.handleLastPage }
                        handleRecordsNumber={ this.handleRecordsNumber }
                        handlePageChange={ this.handlePageChange }
                        addSortingForColumn={ this.addSortingForColumn }
                        pagingInfo={ this.state.pagingInfo }
                        sortRule={ this.state.sort.concat([]) }
                    />
                </SimpleContainer>
                <AddDropYardPopup closeModal={ this.onCloseModal } showModal={ this.state.showAddModal } />
                <DeletePopup
                    closeModal={ this.onCloseDeleteModal }
                    showModal={ this.state.showDeletePopup }
                    url={ `${adminYardsUrl}/${this.state.yard.id}` }
                    title="DELETE YARD"
                    subtitle="Delete Yard?"
                >
                    Are you sure you want to delete <span className="bold">“{ (this.state.yard.company || {}).name }”</span> yard?
                </DeletePopup>
            </div>
        )
    }
}
