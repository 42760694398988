import React, { Component } from "react";

import "./TextTooltip.scss";

export default class TextTooltip extends Component {
    constructor(props) {
        super();

        this.state = {
            text: props.text
        }
    }

    render() {
        return (
            <div className="text-tooltip">
                <div className="text-arrow-box">
                    <p className="text last">
                        {this.state.text}
                    </p>
                </div>
            </div>
        );
    }
}