import React, { Component } from 'react';

import './SimpleContainer.scss';

export default class SimpleContainer extends Component {

    render() {
        const hasTitle = !!this.props.title
        const notActive = !!this.props.notActive
        const modalCentered = !!this.props.modal

        const titleClassName = "title" + (notActive ? " inactive" : " active") + (modalCentered ? " centered" : " ");
        const title = hasTitle ? <h5 className={titleClassName} >{this.props.title}</h5> : <></>

        const className = (this.props.className || "") + " simple-container" + (hasTitle ? " titled" : "") ;

        return (
            <div className={className}>
                {title}
                {this.props.children}
            </div>
        );
    }
}
