import React, { Component } from "react";
import "./PagingComp.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { defaultGridRecordsNumber } from "global/constants";

class PagingComp extends Component {

  static defaultProps = {
    className: '',
  }

  constructor(props) {
    super(props);
    if(props.pagingInfo) {
      const pagingInfo = props.pagingInfo;
      this.state = {
        recordsNumber: pagingInfo.recordsNumber || defaultGridRecordsNumber,
        currentPage: props.pagingInfo.currentPage || 1,
        inputValid: true
      };
    } else {
      this.state = {
        recordsNumber: defaultGridRecordsNumber,
        currentPage: props.pagingInfo.currentPage || 1,
        inputValid: true
      };
    }
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidUpdate(previousProps) {
    if (this.props !== previousProps) {
      this.setState({
        currentPage: this.props.pagingInfo.currentPage || 1,
        recordsNumber: this.props.pagingInfo.recordsNumber || defaultGridRecordsNumber,
      })
    }
  }
  
  checkInputValidity(value) {
    if(value === "" || Number.isNaN(Number(value))|| Number(value) > Number(this.props.pagingInfo.totalPageNumber) || Number(value) <= 0) {
      this.setState({
        inputValid: false
      });
      return false;
    } else {
      this.setState({
        inputValid: true
      })
      return true;
    }
  }

  onInputChange(e) {
    if (this.checkInputValidity(e.target.value)) {
      this.props.handlePageChange(e);
    }
  }

  onIconFirstClick(e) {
    this.props.handleFirstPage();
    this.checkInputValidity(1)
  }

  onIconLastClick(e) {
    if (this.checkInputValidity(this.props.pagingInfo.totalPageNumber)) {
      this.props.handleLastPage();
    }
  }

  onIconPreviousClick(e) {
    if(this.checkInputValidity(this.props.pagingInfo.currentPage) && this.props.pagingInfo.currentPage > 1) {
      this.props.handlePreviousPage();
    }  
  }

  onIconNextClick(e) {
    if(this.checkInputValidity(this.props.pagingInfo.currentPage) && this.props.pagingInfo.currentPage < this.props.pagingInfo.totalPageNumber) {
      this.props.handleNextPage();
    }  
  }

  render() {
    const inputClassName = this.state.inputValid ? "bold inputValid" : "bold inputError";

    let totalItems = (<></>);
    if (this.props.pagingInfo.totalItemNumber || this.props.pagingInfo.totalItemNumber === 0) {
      totalItems = (<>Showing <span className="bold">{this.props.pagingInfo.itemNumber}</span> out of  <span className="bold">{this.props.pagingInfo.totalItemNumber}</span> items</>);
    }

    return (
      <div className={ `paging ${this.props.className}` }>
      <div className = "showing-label-div">{ totalItems }</div>
      <div className = "page-number-div">
        <FontAwesomeIcon icon={faAngleDoubleLeft} className="icon" onClick={e => { this.onIconFirstClick(e); }}></FontAwesomeIcon>
        <FontAwesomeIcon icon={faAngleLeft} className="icon" onClick={e => { this.onIconPreviousClick(e); }}></FontAwesomeIcon>
        <label className="page-number-label">
          Page
          <input type="text" className={inputClassName} name="page" value={this.state.currentPage || 1} onChange={(e) => {this.onInputChange(e)}} />
          out of  <span className="bold">{this.props.pagingInfo.totalPageNumber || 1}</span>
        </label>
        <FontAwesomeIcon icon={faAngleRight} className="icon" onClick={e => { this.onIconNextClick(e); }}></FontAwesomeIcon>
        <FontAwesomeIcon icon={faAngleDoubleRight} className="icon" onClick={e => { this.onIconLastClick(e); }}></FontAwesomeIcon>
      </div>

      <div className="select-div">
      <span className="bold">{this.state.recordsNumber}</span> per page
        {/*<div className="select-wrapper">
        <label>
           <select onChange={this.props.handleRecordsNumber} defaultValue={this.state.recordsNumber}>
            <option default value="20">20</option>
          </select> 
          </label>
        </div>   */}
      </div>
    </div>
    );
  }
}
export default PagingComp;
