import React, { Component } from 'react';
import DataTableLink from 'components/datatable/DataTableLink';
import AuthorizationService from 'global/services/AuthorizationService';
import { PERMISSION } from 'global/utils/auth';

export default class AssignmentCell extends Component {
    render() {
        const { className, rowData, permissions } = this.props;

        if (!rowData.onClickState.assignmentId || !AuthorizationService.canAccess(permissions, PERMISSION.ASSIGNMENT.READ)) {
            return (
                <div className={ className }>
                    { rowData.orderId }
                </div>
            );
        }

        return (
            <DataTableLink className={ className } to={ `/assignment-details/${ rowData.onClickState.assignmentId }` }>
                { rowData.orderId }
            </DataTableLink>
        );
    }
}
