import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAlarmSnooze,
    faComment,
    faEye,
    faInfoCircle,
    faTimesCircle,
    faCheckCircle,
    faExclamationCircle,
    faQuestionCircle
} from '@fortawesome/pro-solid-svg-icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withRouter } from 'react-router';
import Loader from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { reactPlugin } from 'AppInsights';
import AuthContext from 'AuthContext';
import DataTableComp from 'components/datatable/DataTableComp';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import ActiveSelection from 'components/input/ActiveSelection';
import SimpleContainer from 'components/container/SimpleContainer';
import CanAccess from "components/can-access/CanAccess";
import { defaultGridRecordsNumber, issuesDetailsUrl, mapSort, SortParam } from 'global/constants';
import { issueSnoozeable } from 'global/services/IssueTypeService';
import { mapIssuesFromResponse } from 'global/services/IssueApiService';
import { getCurrentDate as currentDate, getDifferenceBetweenDates } from 'global/services/DateTimeService';
import QueryParamService from 'global/services/QueryParamService';
import { PERMISSION } from 'global/utils/auth';
import deleteComment from 'global/utils/deleteComment';
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import './InactiveInYard.scss';

import $ from 'jquery';

const CACHE = {};

class InactiveInYard extends Component {
    static contextType = AuthContext;
    
    constructor(props, context) {
        super(props, context);
        this.columnNames = {
            0: SortParam.TRAILER,
            3: SortParam.START_TIME
        };

        this.columns = [
            { title: "Trailer #", data: "trailerId" },
            { title: "Status", data: "status" },
            { title: "Trailer position", data: "trailerPosition" },
            { title: "Created", data: "created" },
            { title: "Last arrival date", data: "lastArrivalDate" },
            { title: "Drop yard", data: "companyName" },
            { title: "Company address", data: "companyAddress" },
            { title: "Alerts", data: "alerts" },
            { title: "Resolved", data: "resolved" },
            { title: "Action", data: "action" }
        ];

        this.sortingRule = [];
        this.data = Array.from([]);

        this.columnDefs = [
            { width: "5%", "targets": [0, 9] },
            { width: "10%", "targets": [1, 3, 4, 5, 6, 7, 8] },
            { width: "20%", "targets": [2] },
            { className: "dt-align-center", targets: [9] },
            { className: "dt-align-left", targets: [1, 2, 3, 4, 5, 6, 7, 8] },
            { className: "dt-align-right", targets: [0] },
            {
                orderable: false,
                targets: [1, 2, 4, 5, 6, 7, 8, 9]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let mainText = "";
                    let otherText = "";
                    if (rowData.inactiveInYard) {
                        mainText = "Inactive in yard \xa0";
                        otherText = ` (for ${getDifferenceBetweenDates(rowData.lastArrivalDate, currentDate())})`;
                        className += "bold orangeColorText";
                    } else if (rowData.status == null) {
                        mainText = "Unknown";
                        className += "bold redColorText";
                    } else if (rowData.status === true) {
                        mainText = "Moving";
                    } else {
                        mainText = "Stopped";
                        className += "bold orangeColorText";
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <div className={className}>
                                {mainText}
                            </div>
                            <span className={className}>
                                {otherText}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex">
                            <span>
                                {rowData.trailerPosition}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex">
                            {rowData.lastArrivalDate}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex" >
                            {rowData.companyName}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex" >
                            {rowData.companyAddress}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "greenColorText bold";
                    let coloredText = "";
                    let otherText = "";
                    if (!rowData.alerts) {
                        coloredText = "No";
                        className = "redColorText bold";
                        otherText = ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`
                    } else if (rowData.alerts === "Escalation") {
                        coloredText = "Escalation"
                        className = "bold";
                    } else if (rowData.alerts === "Warning") {
                        coloredText = "Warning";
                        className = "bold";
                    }

                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }
                    const showIconStyle = coloredText === "No" ? { display: "none" } : {};

                    ReactDOM.render(
                        <div className="notifications" >
                            <div className={`notification-level ` + className}>
                                {coloredText}
                            </div>
                            <div className="notification-info" style={showIconStyle}>
                                &nbsp;&nbsp;<FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
                            </div>
                            {otherText !== "" &&
                                <div className="greyColorText italic snoozed-info">
                                    <br /> {otherText}
                                </div>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";

                    if (!rowData.resolved) {
                        className += "bold redColorText";
                        coloredText = "No";
                        otherText += ` (for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`;
                    } else if (rowData.resolved) {
                        className += "greenColorText bold";
                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.beginning, rowData.resolved)})`;
                        }
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer" >
                            <div className={className}>
                                {rowData.resolved ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faExclamationCircle} />}
                                &nbsp;&nbsp;
                                {coloredText}&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.resolved ? { display: "none" } : { marginRight: 4 }} />
                            </div>
                            <div className="greyColorText italic">{otherText}</div>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 9,
                className: "justify",
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass = "";
                    let snoozeIconClass = "";

                    const issueSnoozeableCode = issueSnoozeable(rowData.issue);
                    if (issueSnoozeableCode === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeableCode === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon"
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;

                    ReactDOM.render(
                        <div className="flex-always">
                            <div className="seen" id={rowData.id}>
                                <FontAwesomeIcon icon={faEye} className={seenIconClass} />
                            </div>
                            &nbsp; &nbsp;
                            <CanAccess
                                permissions={ context.permissions }
                                action={ [PERMISSION.ISSUE.SNOOZE, PERMISSION.ISSUE.INACTIVE_IN_YARD.SNOOZE] }
                                yes={
                                    <div className={snoozeDivClass}>
                                        {snoozeIconClass === "disabled-icon" ? <svg width="16" height="12" style={{marginTop: "2px"}} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z"/>
                                                <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z"/>
                                                <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z"/>
                                                <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z"/>
                                                <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z"/>
                                                <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z"/>
                                            </svg>
                                            : <FontAwesomeIcon icon={faAlarmSnooze} className={snoozeIconClass}/>}
                                    </div>
                                }
                            />
                            &nbsp; &nbsp;
                            <div className="comment">
                                <FontAwesomeIcon icon={faComment} className={commentIconClass} />
                            </div>
                            &nbsp; &nbsp;
                            <CanAccess
                                permissions={ context.permissions }
                                action={ [PERMISSION.ISSUE.CLOSE, PERMISSION.ISSUE.INACTIVE_IN_YARD.CLOSE] }
                                yes={
                                    <div className={isRelevant ? "resolve" : "disabled-resolve"}>
                                        <FontAwesomeIcon icon={faTimesCircle} className={isRelevant ? "resolve-icon" : "disabled-icon"} />
                                    </div>
                                }
                            />
                            <CanAccess
                                permissions={ context.permissions } 
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <ReeferControlSvg />
                                    </> 
                                }
                            />
                        </div>,
                        td
                    );
                }
            }
        ];

        let relevantIssues = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : undefined;

        let buttonNode;
        if (!!relevantIssues || relevantIssues === undefined) {
            relevantIssues = true;
            buttonNode = $(`button[id=relevant]`);
        } else {
            relevantIssues = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addActiveLegToQueryString(window.location.search.slice(1), relevantIssues);

        const page = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page');

        this.state = {
            data: this.data,
            columns: this.columns,
            columnDefs: this.columnDefs,
            sort: [],
            activeLeg: relevantIssues,
            sortingRule: this.sortingRule,
            issuesLoaded: false,
            dataUpdated: false,
            showModal: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).inactiveInYardIssues !== undefined ? JSON.parse(localStorage.getItem('showModals')).inactiveInYardIssues : true,
            doNotShowTutorial: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).inactiveInYardIssues !== undefined ? !JSON.parse(localStorage.getItem('showModals')).inactiveInYardIssues : false,
            page: page ? parseInt(page) : 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            }
        }

        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
        this.openTutorialModal = this.openTutorialModal.bind(this);
    }

    changeActiveness(relevantIssues) {
        const queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevantIssues);

        this.setState({
            activeLeg: relevantIssues,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            },
            dataUpdated: false
        }, () => {
            this.fetchAllIssues()
        });
    }

    onReceiveNewComment(comment) {
        let newData = this.state.data;
        newData.find((data) => data.issue.id === comment.issue.id).comments.unshift(comment);

        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        const newData = this.state.data;

        this.setState({
            allIssues: deleteComment(deletedComment, newData)
        });
    }

    componentDidMount() {
        this.fetchAllIssues();
    }

    componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            this.handlePage(1, true);
        }
    }

    async handlePage(page, force) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchAllIssues(force);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value),
            },
            page: 1,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;

        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchAllIssues();
            }, 1000);
        }

        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    onCheckboxChange() {
        this.setState({
            doNotShowTutorial: !this.state.doNotShowTutorial
        });
    }

    openTutorialModal() {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        const existingItem = JSON.parse(localStorage.getItem('showModals'));
        localStorage.setItem('showModals', JSON.stringify({ ...existingItem, ...{ inactiveInYardIssues: !this.state.doNotShowTutorial } }));
        this.setState({
            showModal: false
        });
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);
        if (existing === -1) {
            sortingArray.push({
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            });
        } else if (sorting === "") {
            sortingArray.splice(existing, 1)
        } else {
            sortingArray[existing] = {
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            };
        }
        let queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray
        }, () => {
            this.fetchAllIssues();
        });
    }

    async fetchAllIssues(force) {
        let cachingIndex = this.context.team.slice().join('_');
        cachingIndex += this.state.activeLeg ? '_relevant' : '_irrelevant';

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        let sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
        let tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }
            const params = {
                types: ["INACTIVE_IN_YARD"],
                active: this.state.activeLeg,
                sort: sort,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            }

            const response = await this.context.get(issuesDetailsUrl, params);

            if (response.status === "error") {
                console.error(response.message);
                return {};
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    notificationsLoaded: true,
                    dataUpdated: true
                });
                return {};
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const issues = await mapIssuesFromResponse(data, true);

            const newData = mapGridResponseData(issues, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                data: Array.from([]),
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    render() {
        let tutorial;

        if (!this.state.doNotShowTutorial || this.state.showModal) {
            tutorial = (
                <Modal
                    show={this.state.showModal}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                    className="tutorial-modal-inactive-in-yard"
                >
                    <SimpleContainer
                        className="tutorial-modal-inactive-in-yard"
                        title="INACTIVE IN YARD TRAILERS TUTORIAL"
                        modal={true}
                    >
                        <Modal.Body className="tutorial-modal-inactive-in-yard">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{}}>
                                            <svg
                                                width="46"
                                                height="36"
                                                viewBox="0 0 46 36"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M3.52427 26.4375C3.73521 27 4.29771 27.3516 4.93052 27.2109L20.118 23.1328C20.7508 22.9922 21.1024 22.3594 20.9618 21.7266L17.4461 8.71875C17.3055 8.08594 16.6727 7.73438 16.0399 7.875L10.6258 9.35156L12.3836 15.8906L8.02427 17.0156L6.26646 10.5469L0.852398 11.9531C0.219586 12.1641 -0.131977 12.7969 0.0789606 13.3594L3.52427 26.4375ZM37.1336 20.25C33.9696 20.25 31.2977 22.0781 30.0321 24.75H28.1336V4.5C28.1336 3.86719 28.6258 3.375 29.2586 3.375H43.3211C44.2352 3.375 45.0086 2.60156 45.0086 1.6875C45.0086 0.773438 44.2352 0 43.3211 0H29.2586C26.7977 0 24.7586 2.03906 24.7586 4.5V25.4531L1.20396 32.6953C0.289898 32.9766 -0.202289 33.8906 0.0789606 34.8047C0.289898 35.5078 0.993023 36 1.69615 36C1.83677 36 2.04771 36 2.18834 35.9297L27.2899 28.125H29.2586C29.2586 32.4844 32.7743 36 37.1336 36C41.493 36 45.0086 32.4844 45.0086 28.125C45.0086 23.7656 41.493 20.25 37.1336 20.25ZM37.1336 32.625C34.6727 32.625 32.6336 30.5859 32.6336 28.125C32.6336 25.6641 34.6727 23.625 37.1336 23.625C39.5946 23.625 41.6336 25.6641 41.6336 28.125C41.6336 30.5859 39.5946 32.625 37.1336 32.625Z"
                                                    fill="#828A95"
                                                />
                                            </svg>
                                        </td>
                                        <td style={{ marginLeft: "20%" }} colSpan="2">
                                            <p className="modalText" style={{ paddingBottom: "4px" }}>
                                                STEP 1:
                                            </p>
                                            <p className="modalText">
                                                Open the Trailer's latest load in TMW
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="vl"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{}}>
                                            <svg
                                                width="34"
                                                height="34"
                                                viewBox="0 0 34 34"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M16.875 0C7.52344 0 0 7.52344 0 16.875C0 26.2266 7.52344 33.75 16.875 33.75C26.2266 33.75 33.75 26.2266 33.75 16.875C33.75 7.52344 26.2266 0 16.875 0ZM16.875 30.375C9.42188 30.375 3.375 24.3281 3.375 16.875C3.375 9.42188 9.42188 3.375 16.875 3.375C24.3281 3.375 30.375 9.42188 30.375 16.875C30.375 24.3281 24.3281 30.375 16.875 30.375ZM18.5625 17.1562V9.5625C18.5625 8.64844 17.7891 7.875 16.875 7.875C15.9609 7.875 15.1875 8.64844 15.1875 9.5625V18C15.1875 18.5625 15.4688 19.0547 15.8906 19.3359L20.3906 22.7109C20.6719 22.9219 21.0234 23.0625 21.375 23.0625C21.8672 23.0625 22.3594 22.8516 22.7109 22.3594C23.2734 21.6562 23.1328 20.6016 22.3594 20.0391L18.5625 17.1562Z"
                                                    fill="#828A95"
                                                />
                                            </svg>
                                        </td>
                                        <td style={{ marginLeft: "20%" }} colSpan="2">
                                            <p className="modalText" style={{ paddingBottom: "4px" }}>
                                                STEP 2:
                                            </p>
                                            <p className="modalText">
                                                Verify the date/time the load delivered.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="vl"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{}}>
                                            <svg
                                                width="36"
                                                height="27"
                                                viewBox="0 0 36 27"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M31.5 0H4.5C2.03906 0 0 2.03906 0 4.5V22.5C0 24.9609 2.03906 27 4.5 27H31.5C33.9609 27 36 24.9609 36 22.5V4.5C36 2.03906 33.9609 0 31.5 0ZM4.5 3.375H31.5C32.1328 3.375 32.625 3.86719 32.625 4.5V6.04688L20.8828 15.8203C19.2656 17.1562 16.7344 17.1562 15.1172 15.8203L3.375 6.04688V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375ZM31.5 23.625H4.5C3.86719 23.625 3.375 23.1328 3.375 22.5V10.4766L12.9375 18.4219C14.3438 19.6172 16.1719 20.25 18 20.25C19.8281 20.25 21.6562 19.6172 23.0625 18.4219L32.625 10.4766V22.5C32.625 23.1328 32.1328 23.625 31.5 23.625Z"
                                                    fill="#828A95"
                                                />
                                            </svg>
                                        </td>
                                        <td style={{ marginLeft: "20%" }} colSpan="2">
                                            <p className="modalText" style={{ paddingBottom: "4px" }}>
                                                STEP 3:
                                            </p>
                                            <p className="modalText">
                                                Email the customer to request that the trailer # be the next one loaded out.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="vl"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{}}>
                                            <svg
                                                width="45"
                                                height="32"
                                                viewBox="0 0 45 32"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M43.3125 21.375H40.5V4.5C40.5 2.03906 38.4609 0 36 0H4.5C2.03906 0 0 2.03906 0 4.5V20.25C0 22.7109 2.03906 24.75 4.5 24.75H6.75C6.75 28.4766 9.77344 31.5 13.5 31.5C17.2266 31.5 20.25 28.4766 20.25 24.75H43.3125C44.2266 24.75 45 23.9766 45 23.0625C45 22.1484 44.2266 21.375 43.3125 21.375ZM13.5 28.125C11.6719 28.125 10.125 26.5781 10.125 24.75C10.125 22.9219 11.6719 21.375 13.5 21.375C15.3281 21.375 16.875 22.9219 16.875 24.75C16.875 26.5781 15.3281 28.125 13.5 28.125ZM37.125 21.375H19.3359C18.1406 19.3359 15.9609 18 13.5 18C11.0391 18 8.85938 19.3359 7.66406 21.375H4.5C3.86719 21.375 3.375 20.8828 3.375 20.25V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H36C36.6328 3.375 37.125 3.86719 37.125 4.5V21.375ZM23.625 5.625C22.7109 5.625 21.9375 6.39844 21.9375 7.3125V17.4375C21.9375 18.3516 22.7109 19.125 23.625 19.125C24.5391 19.125 25.3125 18.3516 25.3125 17.4375V7.3125C25.3125 6.39844 24.5391 5.625 23.625 5.625ZM16.875 5.625C15.9609 5.625 15.1875 6.39844 15.1875 7.3125V14.0625C15.1875 14.9766 15.9609 15.75 16.875 15.75C17.7891 15.75 18.5625 14.9766 18.5625 14.0625V7.3125C18.5625 6.39844 17.7891 5.625 16.875 5.625ZM10.125 5.625C9.21094 5.625 8.4375 6.39844 8.4375 7.3125V14.0625C8.4375 14.9766 9.21094 15.75 10.125 15.75C11.0391 15.75 11.8125 14.9766 11.8125 14.0625V7.3125C11.8125 6.39844 11.0391 5.625 10.125 5.625ZM30.375 5.625C29.4609 5.625 28.6875 6.39844 28.6875 7.3125V17.4375C28.6875 18.3516 29.4609 19.125 30.375 19.125C31.2891 19.125 32.0625 18.3516 32.0625 17.4375V7.3125C32.0625 6.39844 31.2891 5.625 30.375 5.625Z"
                                                    fill="#828A95"
                                                />
                                            </svg>
                                        </td>
                                        <td style={{ marginLeft: "20%" }} colSpan="2">
                                            <p className="modalText" style={{ paddingBottom: "4px" }}>
                                                STEP 4:
                                            </p>
                                            <p className="modalText">
                                                If there are multiple trailers sitting at the customer for over 4 days, combine them into one email request.
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Button variant="continue" onClick={this.handleClose.bind(this)} style={{ width: "240px", height: "36px", marginTop: "15px" }}>
                                Got it!
                            </Button>
                            <div className="form-check">
                                <label className={`checkBoxText ${this.state.doNotShowTutorial ? "bold" : ""}`}>
                                    <input
                                        type="checkbox"
                                        name={this.state.label}
                                        checked={this.state.doNotShowTutorial}
                                        onChange={this.onCheckboxChange.bind(this)}
                                        className="form-check-input"
                                    />
                                    Do not show this tip again
                                </label>
                            </div>
                        </Modal.Body>
                    </SimpleContainer>
                </Modal>
            );
        }

        return (
            <div className="container-fluid page inactive-in-yard-issues">
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading">
                                Inactive in Yard
                            </p>
                            <div className="tutorial-info">
                                <p className="tutorial-text"> <FontAwesomeIcon className="tutorial-icon" icon={faQuestionCircle} onClick={this.openTutorialModal} /></p>
                            </div>
                            &nbsp; &nbsp; <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.issuesLoaded || !this.state.dataUpdated} />
                        </div>
                        <div className="active-toggle">
                            <ActiveSelection activeCallback={this.changeActiveness.bind(this)} initiallyActive={this.state.activeLeg} />
                        </div>
                        {tutorial}
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="inactive-in-yard-issues-div">
                            {this.state.issuesLoaded && <DataTableComp
                                tableId="inactiveInYard"
                                columns={this.state.columns}
                                orderRule={this.state.sortingRule}
                                data={this.state.data}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                isDetailView={false}
                                columnDefs={this.state.columnDefs}
                                customTableClass="cell-border"
                                tableHeight="1000px"
                                handlePreviousPage={this.handlePreviousPage}
                                handleNextPage={this.handleNextPage}
                                handleFirstPage={this.handleFirstPage}
                                handleLastPage={this.handleLastPage}
                                handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                                handlePageChange={(event) => this.handlePageChange(event)}
                                pagingInfo={this.state.pagingInfo}
                                sortRule={this.state.sort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, InactiveInYard, "InactiveInYard"));
