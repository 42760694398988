import * as moment from 'moment';

import config from 'config';

const {
    SILENT_UNIT_THRESHOLD
} = config;

export function getCurrentDate(date) {
    return moment(date);
}

export const getRange = (start, end) => [
    ...Array((end - start) + 1).keys(),
].map((
    (i) => i + start
));

export function getCurrentDateWithoutTime() {
    return moment({h: 0, m: 0, s: 0, ms: 0});
}

export function getFullDifferenceBetweenDates(startDate, endDate) {
    let startTime = moment(new Date(startDate));
    let endTime = moment(new Date(endDate));
    let returnString = "";

    const years = endTime.diff(startTime, 'year');
    returnString += years > 0 ? years === 1 ? years + " year " : years + " years " : "";
    startTime.add(years, 'years');

    const months = endTime.diff(startTime, 'months');
    startTime.add(months, 'months');
    const days = endTime.diff(startTime, 'days');
    startTime.add(days, 'days');

    returnString += months > 0 ? months === 1 && days === 0 ? " 30 days " : months === 1 ? months + " month " : months + " months " : "";
    returnString += days > 0 ? days === 1 ? days + " day " : days + " days " : "";

    const hours = endTime.diff(startTime, 'hours');
    startTime.add(hours, 'hours');
    returnString += hours > 0 ? hours === 1 ? hours + " hour " : hours + " hours " : "";

    const minutes = endTime.diff(startTime, 'minutes');
    startTime.add(minutes, 'minutes');
    returnString += minutes > 0 ? minutes === 1 ? minutes + " minute " : minutes + " minutes " : "";

    // const seconds = endTime.diff(startTime, 'seconds');
    // returnString += seconds > 0 ? seconds === 1 ? seconds + " second " : seconds + " seconds " : "";

    return returnString;
}

export function minsToHours(mins) {
    const hours = Math.floor(mins / 60);
    const minutes = mins % 60;
    if (hours) {
        const days = Math.floor(hours / 24);
        const hrs = hours % 24;
        if (days) {
            return days + "d " + hrs + "h " + minutes.toFixed(0) + "min";
        } else {
            return hours + "h " + minutes.toFixed(0) + "min";
        }
    } else {
        return minutes.toFixed(0) + "min";
    }
}

export function minutePart(mins) {
    const minutes = mins % 60;
    return minutes.toFixed(0);
}

export function hourPart(mins) {
    const hours = Math.floor(mins / 60);
    return hours || 0;
}


export function getDifferenceBetweenDates(startDate, endDate) {
    const startTime = moment(new Date(startDate));
    const endTime = moment(new Date(endDate));
    const diff = startTime.from(endTime);
    return diff.replace(" ago", "").replace("in ", "").replace("minutes", "mins");
}

export function getDifferenceInDays(date1, date2) {
    let startTime = moment(new Date(date1));
    let endTime = moment(new Date(date2));

    const days = endTime.diff(startTime, 'days');

    if (!days) {
        return 'Less than a day';
    } else {
        return days === 1 ? '1 day' : `${days} days`;
    }
}

export function getUnalteredDifferenceBetweenDateTime(start, end) {
    const startTime = moment(new Date(start));
    const endTime = moment(new Date(end));
    return startTime.from(endTime);
}

export function addSilentUnitThresholdToDate(date) {
    return moment(date).add(SILENT_UNIT_THRESHOLD, 'm').toDate();
}

export function getDayBefore(date) {
    return getHoursBefore(date, 24);
}

export function getHoursBefore(date, hours) {
    const ts = Math.round(date.getTime() / 1000);
    const tsBefore = ts - (hours * 60 * 60);
    return tsBefore;
}

export function getMonthsBefore(date, monthNumber) {
    return moment(date).subtract(monthNumber, 'months');
}

export function formatDate(date, toLocal) {
    return date.toLocaleString("en-US", {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: toLocal ? 'UTC' : undefined
    });
}

export function formatDateWithoutTime(date) {
    return date.format("YYYY-MM-DD");
}

export function formatTime(date) {
    const newDate = date.toLocaleString("en-US", {
        hour: "2-digit",
        minute: "2-digit"
    });
    return newDate;
}

export function formatLongDate(date) {
    const newDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    });
    return newDate;
}

export function formatLongDateWithoutSeconds(date) {
    return date.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
    });
}

export function formatShortDate(date) {
    const newDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
    });
    return newDate;
}

export function formatLongDateWithoutTime(date) {
    const newDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric"
    });
    return newDate;
}

export function formatDateTimeNumeric(date) {
    const newDate = date.toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });
    return newDate;
}

export const timeOptions = (startTime, endTime, interval) => {
    const periodsInADay = moment.duration(1, "day").as("m");

    const timeOptions = [];
    const startTimeMoment = moment.utc(startTime || "00:00", "HH:mm");

    for (let i = 0; i < periodsInADay; i += interval) {
        if (endTime && startTimeMoment.format("HH:mm") === endTime) {
            break;
        }
        startTimeMoment.add(i === 0 ? 0 : interval, "m");
        timeOptions.push({
            value: moment(startTimeMoment.format("HH:mm:ss"), "HH:mm:ss").utc().format("HH:mm:ss"),
            label: startTimeMoment.format("hh:mmA"),
        });
    }

    return timeOptions;
};

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

export function dayOptions(startDay, endDay) {
    return [
        { value: 1, label: days[0] },
        { value: 2, label: days[1] },
        { value: 3, label: days[2] },
        { value: 4, label: days[3] },
        { value: 5, label: days[4] },
        { value: 6, label: days[5] },
        { value: 7, label: days[6] },
    ].slice(startDay - 1 || 0, endDay || 7);
}

export function getPmTime(time) {
    return moment.utc(time, "HH:mm:ss").local().format("hh:mmA");
}

export function toLocalHour(utcHour) {
    return moment.utc(utcHour, "H").local().hour();
}

export function pmHourFormat(amHour) {
    return moment(amHour, "H").format("h A");
}

export function getDayName(dayNumber) {
    return days[dayNumber - 1];
}

export function getShortDayName(dayNumber) {
    return days[dayNumber - 1].substring(0, 3) + ".";
}

export function compareDates(d1, d2) {
    return moment.utc(d1).diff(moment.utc(d2));
}

export function getStartOfDay(date) {
    return moment(date).startOf('day').toDate();
}

export function getEndOfDay(date) {
    return moment(date).endOf('day').toDate();
}

export function getDateWithOffset(date, seconds) {
    return moment.utc(date).add(seconds, 'seconds').toDate();
}
