import React from 'react';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, ReferenceLine, Label } from 'recharts';
import TeamIcon from 'components/team-icon/TeamIcon'
import { TEAMS } from "global/constants"
import { issueTypeMap } from "global/services/IssueTypeService"
import { minsToHours } from 'global/services/DateTimeService';

class GroupedStackedBar extends React.Component {

  renderStackedTeamXAxis = ({ x, y, payload }) => {
    switch(payload.value) {
      case "LOCAL":
      case "UNK": 
      case "NONE":
        return (
          <svg style={{overflow: "auto"}} x={x - 5} y={y + 4} width={170} height={30.5}  >
            <TeamIcon color={payload.value} size="12"></TeamIcon>
            <text dx={-11}  dy="2.2em" fill="black" className="xFullTeamLabelTick" >{(TEAMS.find(team => team.name === payload.value) || {label: ""}).label}</text>
          </svg>
        )
      default:
        return (
          <svg style={{overflow: "auto"}} x={x - 5} y={y + 4} width={155} height={30.5}  >
            <TeamIcon color={payload.value} size="12"></TeamIcon>
            <text dx={-17}  dy="2.2em" fill="black" className="xFullTeamLabelTick" >{(TEAMS.find(team => team.name === payload.value) || {label: ""}).label}</text>
          </svg>
        );
    }
  };
  
  renderSimpleTeamXAxis = ({ x, y, payload }) => {
    switch(payload.value) {
      case "LOCAL":
        return (
          <svg style={{overflow: "auto"}} x={x - 5} y={y + 4} width={160} height={30.5}  >
            <TeamIcon color={payload.value} size="12"></TeamIcon>
            <text className="xSimpleTeamLabelTick" dx={-6}  dy="2.2em"  fill="black" style={{fontSize: "12px", color: "#030D1D"}} >{(TEAMS.find(team => team.name === payload.value) || {label: ""}).label.split(' ')[0]}</text>
          </svg>
        )
      case "UNK": 
        return (
          <svg style={{overflow: "auto"}} x={x - 5} y={y + 4} width={160} height={30.5}  >
            <TeamIcon color={payload.value} size="12"></TeamIcon>
            <text className="xSimpleTeamLabelTick" dx={-16}  dy="2.2em"  fill="black" style={{fontSize: "12px", color: "#030D1D"}} >{(TEAMS.find(team => team.name === payload.value) || {label: ""}).label.split(' ')[0]}</text>
          </svg>
        )
      case "NONE":
        return (
          <svg style={{overflow: "auto"}} x={x - 5} y={y + 4} width={160} height={30.5}  >
            <TeamIcon color={payload.value} size="12"></TeamIcon>
            <text className="xSimpleTeamLabelTick" dx={-4}  dy="2.2em"  fill="black" style={{fontSize: "12px", color: "#030D1D"}} >None</text>
          </svg>
        )
      default:
        return (
          <svg style={{overflow: "auto"}} x={x - 5} y={y + 4} width={160} height={30.5}  >
            <TeamIcon color={payload.value} size="12"></TeamIcon>
            <text className="xSimpleTeamLabelTick" dx={-6} width={40} dy="2.2em" fill="black" style={{fontSize: "12px", color: "#030D1D"}} >{(TEAMS.find(team => team.name === payload.value) || {label: ""}).label.split(' ')[0]}</text>
          </svg>
        );
    }
  };

  renderSimpleIssueXAxis = ({ x, y, payload }) => {
    return (
      <text
        x={x}
        y={y}
        fontSize={4}
        width="100px"
        className="xTypeLabelTick"
      >
        {issueTypeMap(payload.value).split(" ").map(p => <tspan textAnchor="middle" key={p} x={x} dy="1.2em">{p}</tspan>)}
      </text>
      );
  };
  

  renderLegend = (props) => {
    const { payload } = props;
    return (
      <div className="legend-wrapper">
        {
          payload.length > 1 && payload.map((entry, index) => (
            <div key={index} className="legend inline">
              <div className="inline color-rectangle" style={{background: entry.color}} />
              <div className={`inline`} key={`item-${index}`}>{this.legendValue(entry.value)}</div>
            </div>
          ))
        }
        <div className="right"> Showing results for {this.props.timeFrame ? this.props.timeFrame.replace(/^a/, '') : "month"}</div>
      </div>
    );
  }
  
  tooltip
  CustomTooltip = ({ active, payload }) => {
      if (!active || !this.tooltip || !payload)  return null
      for (const bar of payload)
          if (bar.dataKey === this.tooltip)
              return <div className="custom-tooltip"><div className="tooltiptext">{this.props.minuteBar ? minsToHours(bar.value) : bar.value}&nbsp;{this.legendValue(bar.name)}<br/></div> </div>
      return null
  }

  legendValue = (value) => {
    return this.props.dataKeys.find(dataKey => dataKey.name === value) ? this.props.dataKeys.find(dataKey => dataKey.name === value).label : "Unknown"
  }

  render(){
      return (
        <ResponsiveContainer width="100%" height="100%" >
          <BarChart
            data={this.props.data}
            margin={{ left: 50, right: 50 }}
            barSize={this.props.barSize || 20} //10 or 35
            height={410}
          >
            <CartesianGrid  vertical={false}  strokeDasharray="15 20" stroke="#E2E5E9"/>
            <XAxis dataKey="label" minTickGap={1} tickMargin={1} height={50} interval={0} tick={this.props.simpleTeamXAxis ? this.renderSimpleTeamXAxis : this.props.simpleXAxis ? this.renderSimpleIssueXAxis : this.renderStackedTeamXAxis} axisLine={{ stroke: '#828A95' }}/>
            <YAxis  tick={{dx: -10}} axisLine={{ stroke: '#fff' }} stroke="#828A95"  style={{ fontSize: '0.8rem' }} interval={0} tickFormatter={this.props.yTickFormatter} />
            <Legend verticalAlign="top" content={this.renderLegend}></Legend>
            <Tooltip content={this.CustomTooltip} cursor={false} />
            { this.props.referenceLine && <ReferenceLine y={this.props.referenceLine.value} stroke="black" strokeWidth={1}> <Label value={this.props.referenceLine.value + " " + this.props.referenceLine.label} position="left"/> </ReferenceLine>}
            { this.props.dataKeys.map(
              (dataKey, index) =>  
              <Bar key={index} dataKey={dataKey.name} fill={dataKey.disabled ? "" : dataKey.color} stackId={dataKey.stackId} onMouseOver={ () => this.tooltip = dataKey.name }>
                { 
                this.props.data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.disabled ? "#F4F5FA" : dataKey.color} />
                ))
                }
              </Bar>
            )}
          </BarChart>
        </ResponsiveContainer>
    );
  }
}

export default GroupedStackedBar;