import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './RecipientsTooltip.scss';

export default class RecipientsTooltip extends Component {

    static propTypes = {
        recipients: PropTypes.arrayOf(PropTypes.string).isRequired
    };

    render() {
        return (
            <div className="recipients-tooltip">
                <div className="arrow-box">
                    <div className="bold">
                        Sent to:
                    </div>
                    { this.props.recipients.map(recipient => (
                        <div key={ recipient } className="email-field">
                            { recipient }
                        </div>
                    )) }
                </div>
            </div>
        );
    }
}
