/**
 * @description
 * Adds comment to the appropriate issue in order to 
 *
 * @param deletedComment The comment which the user decided to delete.
 * @param issueDetails The details for all displayes issues, including comments
 *
 * @returns Issue details with the updated deletedComment, which now has a deletedAt attribute as well as
 * hidden text.
 */

function deleteComment(deletedComment, issueDetails) {
    deletedComment.text = null;
    let comments = issueDetails.find((data) => data.issue.id === deletedComment.issue.id).comments;
    let currentComment = comments.find((comment) => comment.id === deletedComment.id);
    comments.splice(comments.indexOf(currentComment), 1, deletedComment);

    issueDetails.find((data) => data.issue.id === deletedComment.issue.id).comments = comments;
    return issueDetails;
}

export default deleteComment;