import React, { Component } from 'react';
import UnreadContext from 'UnreadContext';
import AuthContext from 'AuthContext';
import { PERMISSION } from 'global/utils/auth';
import AuthorizationService from 'global/services/AuthorizationService';

export default class UnreadContextProvider extends Component {
    static contextType = AuthContext;

    state = {
        data: JSON.parse(localStorage.getItem('unread') || '{}')
    }

    async componentDidMount() {
        if (AuthorizationService.canAccess(this.context.permissions, PERMISSION.RELEASE_NOTES.READ)) {
            const data = this.state.data;
            const releaseNotes = ((await this.context.get('/api/v1/release-notes', { pageSize: 1 }) || {}).data) || [];

            if (releaseNotes && releaseNotes.length === 1) {
                const newNotes = releaseNotes[0].id !== (this.state.data.releaseNotes || {}).latest;

                data.releaseNotes = {
                    latest: releaseNotes[0].id,
                    read: newNotes ? false : (this.state.data.releaseNotes || {}).read || false
                };
            }

            this.updateData(data);
        }
    }

    updateData = (data) => {
        const newData = { ...this.state.data, ...data };

        localStorage.setItem('unread', JSON.stringify(newData));
        this.setState({ data: newData })
    }

    render() {
        return (
            <UnreadContext.Provider value={{ data: this.state.data, updateData: this.updateData }}>
                { this.props.children }
            </UnreadContext.Provider>
        );
    }
}
