import React, { Component } from 'react';
import { formatDate } from "global/services/DateTimeService";

import './SeenByTooltip.scss';

export default class SeenByTooltip extends Component {
    constructor(props) {
        super();

        this.state = {
            seenByBefore: props.seenByBefore,
            seenByAfter: props.seenByAfter
        }

        this.mapPeople = this.mapPeople.bind(this);
    }

    componentDidUpdate(props) {
        if (props !== this.props) {
            this.setState({
                seenByBefore: this.props.seenByBefore,
                seenByAfter: this.props.seenByAfter
            });
        }
    }

    mapPeople(people) {
        return people.map(
            person => <div className="content" key={person.id}>
                <span className="circle">
                    <div className="initials">
                        {person.name.split(' ').map(function (letter) {
                            return letter[0]
                        }).join('')
                        }
                    </div>
                </span>
                <p className="name">{person.name}</p>
                <p className="time">{formatDate(new Date(person.time))}</p>
            </div>
        );
    }

    render() {
        return (
            <div className="seen-by-tooltip">
                <div className="right-arrow-box">
                    {this.state.seenByBefore.length === 0 && this.state.seenByAfter.length === 0 ?
                    <p className="info-label">
                        This issue hasn't been seen by anyone.
                    </p> : <></>}
                    {this.state.seenByBefore.length > 0 ?
                    <>
                        <p className="info-label">
                            While active, issue seen by:
                        </p>
                        {this.mapPeople(this.state.seenByBefore)}
                    </> : <></>
                    }
                    {this.state.seenByAfter.length > 0 ? 
                    <>
                        {this.state.seenByBefore.length !== 0 ? <hr></hr> : <></>}
                        <p className="info-label">
                            While inactive, issue seen by:
                        </p>
                        {this.mapPeople(this.state.seenByAfter)}
                    </> : <></>}
                </div>
            </div>
        )
    }
}