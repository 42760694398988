import { Logger, LogLevel, UserAgentApplication } from 'msal';

import { TRAILER_REPORT_CATEGORY } from 'global/constants';
import config from 'config';

const {
    AUTH_CLIENT_ID,
    AUTH_TENANT_ID,
    AUTHENTICATION
} = config;

const {
    REACT_APP_NAME,
    REACT_APP_VERSION
} = process.env;

export const isAuthEnabled = AUTHENTICATION && AUTHENTICATION.toLowerCase() === "enabled";

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read"
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    ME_PROFILE_PHOTO: "https://graph.microsoft.com/v1.0/me/photo/$value"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ]
    }
};

export const msal = new UserAgentApplication({
    auth: {
        clientId: AUTH_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${AUTH_TENANT_ID}`,
        validateAuthority: true,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE()
    },
    system: {
        navigateFrameWait: 1000,
        tokenRenewalOffsetSeconds: 1000,
        loadFrameTimeout: 30000,
        logger: new Logger(
            (logLevel, message) => {
                // console.log(message);
            },
            {
                level: LogLevel.Verbose,
                piiLoggingEnabled: true
            }
        ),
        telemetry: {
            applicationName: REACT_APP_NAME,
            applicationVersion: REACT_APP_VERSION,
            telemetryEmitter: (events) => {
                // console.log('Telemetry Events:', events);
            }
        }
    }
});

export const PERMISSION = Object.freeze({
    ACTIVITY_LOG: Object.freeze({
        READ: "activity-log:read",
        WRITE: "activity-log:write",
    }),
    ASSIGNMENT: Object.freeze({
        READ: "assignment:read",
        WRITE: "assignment:write",
    }),
    COMPANY: Object.freeze({
        READ: "company:read",
    }),
    EVENT: Object.freeze({
        READ: "event:read",
    }),
    FLEET: Object.freeze({
        READ: "fleet:read",
        WRITE: "fleet:write",
    }),
    ISSUE: Object.freeze({
        READ: "issue:read",
        SNOOZE: "issue:snooze",
        COMMENT: "issue:comment",
        CLOSE: "issue:close",
        POWER_STATE: Object.freeze({
            READ: "issue:power-state:read",
            SNOOZE: "issue:power-state:snooze",
            COMMENT: "issue:power-state:comment",
            CLOSE: "issue:power-state:close",
        }),
        TEMPERATURE: Object.freeze({
            READ: "issue:temperature:read",
            SNOOZE: "issue:temperature:snooze",
            COMMENT: "issue:temperature:comment",
            CLOSE: "issue:temperature:close",
        }),
        UNIT_MODE: Object.freeze({
            READ: "issue:unit-mode:read",
            SNOOZE: "issue:unit-mode:snooze",
            COMMENT: "issue:unit-mode:comment",
            CLOSE: "issue:unit-mode:close",
        }),
        DATA_ENTRY_ERROR: Object.freeze({
            READ: "issue:data-entry-error:read",
            SNOOZE: "issue:data-entry-error:snooze",
            COMMENT: "issue:data-entry-error:comment",
            CLOSE: "issue:data-entry-error:close",
        }),
        SILENT: Object.freeze({
            READ: "issue:silent:read",
            SNOOZE: "issue:silent:snooze",
            COMMENT: "issue:silent:comment",
            CLOSE: "issue:silent:close",
        }),
        LOW_FUEL_ALARM: Object.freeze({
            READ: "issue:low-fuel-alarm:read",
            SNOOZE: "issue:low-fuel-alarm:snooze",
            COMMENT: "issue:low-fuel-alarm:comment",
            CLOSE: "issue:low-fuel-alarm:close",
            LOCAL: Object.freeze({
                READ: "issue:low-fuel-alarm:local:read",
                SNOOZE: "issue:low-fuel-alarm:local:snooze",
                COMMENT: "issue:low-fuel-alarm:local:comment",
                CLOSE: "issue:low-fuel-alarm:local:close",
            })
        }),
        DETENTION: Object.freeze({
            READ: "issue:detention:read",
            SNOOZE: "issue:detention:snooze",
            COMMENT: "issue:detention:comment",
            CLOSE: "issue:detention:close",
        }),
        INACTIVITY: Object.freeze({
            READ: "issue:inactivity:read",
            SNOOZE: "issue:inactivity:snooze",
            COMMENT: "issue:inactivity:comment",
            CLOSE: "issue:inactivity:close",
        }),
        INACTIVE_IN_YARD: Object.freeze({
            READ: "issue:inactive-in-yard:read",
            SNOOZE: "issue:inactive-in-yard:snooze",
            COMMENT: "issue:inactive-in-yard:comment",
            CLOSE: "issue:inactive-in-yard:close",
        }),
        MISSING: Object.freeze({
            READ: "issue:missing:read",
            SNOOZE: "issue:missing:snooze",
            COMMENT: "issue:missing:comment",
            CLOSE: "issue:missing:close",
        }),
        DETACHED: Object.freeze({
            READ: "issue:detached:read",
            SNOOZE: "issue:detached:snooze",
            COMMENT: "issue:detached:comment",
            CLOSE: "issue:detached:close",
        }),
        ALARM: Object.freeze({
            READ: "issue:alarm:read",
            SNOOZE: "issue:alarm:snooze",
            COMMENT: "issue:alarm:comment",
            CLOSE: "issue:alarm:close",
            SEARCH: "issue:alarm:search",
        }),
        LOCATION_MISMATCH: Object.freeze({
            READ: "issue:location-mismatch:read",
            SNOOZE: "issue:location-mismatch:snooze",
            COMMENT: "issue:location-mismatch:comment",
            CLOSE: "issue:location-mismatch:close",
        }),
        CLAIM: Object.freeze({
            READ: "issue:claim:read",
            SNOOZE: "issue:claim:snooze",
            COMMENT: "issue:claim:comment",
            CLOSE: "issue:claim:close",
        }),
        SEE: "issue:see",
    }),
    PAGE: Object.freeze({
        ASSIGNMENT: Object.freeze({
            LEGS: "page:assignment:legs",
            ISSUES: "page:assignment:issues",
            ALARMS: "page:assignment:alarms",
        }),
        TRAILER: Object.freeze({
            SETTINGS: "page:trailer:settings",
            ISSUES: "page:trailer:issues",
            LOCATION: "page:trailer:location",
            MEASUREMENTS: "page:trailer:measurements",
            ALARMS: "page:trailer:alarms",
            INACTIVE_IN_YARD: "page:trailer:inactive-in-yard",
            COMMANDS: "page:trailer:commands",
        }),
        ALL_ISSUES: "page:all-issues",
        TRAILER_MAP: "page:trailer-map",
        STOLEN_PROCEDURE: "page:stolen-procedure",
        HELP: "page:help"
    }),
    RELEASE_NOTES: Object.freeze({
        READ: "release-notes:read",
        WRITE: "release-notes:write",
    }),
    SETTINGS: Object.freeze({
        READ: "settings:read",
        WRITE: "settings:write",
        NOTIFICATIONS: Object.freeze({
            READ: "settings:notifications:read",
            WRITE: "settings:notifications:write",
        }),
        CLOSE_REASONS: Object.freeze({
            READ: "settings:close-reasons:read",
            WRITE: "settings:close-reasons:write",
        }),
        SNOOZE_REASONS: Object.freeze({
            READ: "settings:snooze-reasons:read",
            WRITE: "settings:snooze-reasons:write",
        }),
        SNOOZE_DURATIONS: Object.freeze({
            WRITE: "settings:snooze-durations:write"
        }),
        DROP_YARDS: Object.freeze({
            READ: "settings:drop-yards:read",
            WRITE: "settings:drop-yards:write",
        })
    }),
    STATISTICS: Object.freeze({
        READ: "statistics:read",
    }),
    TEAM: Object.freeze({
        SELECT: "team:select",
        DEFAULT: Object.freeze({
            BLACK: "team:default:black",
            BLUE: "team:default:blue",
            GREEN: "team:default:green",
            GREY: "team:default:grey",
            ORANGE: "team:default:orange",
            PURPLE: "team:default:purple",
            RED: "team:default:red",
            LOCAL: "team:default:local",
            UNKNOWN: "team:default:unknown",
            NO_TEAM: "team:default:no-team",
        })
    }),
    TRAILER: Object.freeze({
        READ: "trailer:read",
        COMMANDS: Object.freeze({
            READ: "trailer:commands:read",
            WRITE: "trailer:commands:write",
        }),
        FILTER: Object.freeze({
            READ: "trailer:filter:read",
            WRITE: "trailer:filter:write",
        })
    }),
    TRAILER_AUDIT: Object.freeze({
        COMMENT: "trailer-audit:comment",
        READ: "trailer-audit:read",
        WRITE: "trailer-audit:write",
        REFRESH: "trailer-audit:refresh",
        TOTAL: Object.freeze({
            COMMENT: "trailer-audit:total:comment",
            READ: "trailer-audit:total:read",
        }),
        DISPATCH: Object.freeze({
            COMMENT: "trailer-audit:dispatch:comment",
            READ: "trailer-audit:dispatch:read",
        }),
        HOME_TIME: Object.freeze({
            COMMENT: "trailer-audit:home-time:comment",
            READ: "trailer-audit:home-time:read",
        }),
        YARD: Object.freeze({
            COMMENT: "trailer-audit:yard:comment",
            READ: "trailer-audit:yard:read",
        }),
        DROP_YARD: Object.freeze({
            COMMENT: "trailer-audit:drop-yard:comment",
            READ: "trailer-audit:drop-yard:read",
        }),
        CLAIM: Object.freeze({
            READ: "trailer-audit:claim:read",
        }),
        REPAIR: Object.freeze({
            COMMENT: "trailer-audit:repair:comment",
            READ: "trailer-audit:repair:read",
        }),
        MISMATCH: Object.freeze({
            READ: "trailer-audit:mismatch:read",
        }),
        ONEWAY: Object.freeze({
            COMMENT: "trailer-audit:oneway:comment",
            READ: "trailer-audit:oneway:read",
        }),
        UNACCOUNTED: Object.freeze({
            COMMENT: "trailer-audit:unaccounted:comment",
            READ: "trailer-audit:unaccounted:read",
        }),
        SILENT: Object.freeze({
            READ: "trailer-audit:silent:read",
        }),
        INACTIVE: Object.freeze({
            READ: "trailer-audit:inactive:read",
        }),
        MISSING: Object.freeze({
            READ: "trailer-audit:missing:read",
        }),
        DETACHED: Object.freeze({
            READ: "trailer-audit:detached:read",
        }),
        FLEET: Object.freeze({
            READ: "trailer-audit:fleet:read",
        })
    })
});

export function reportCategoryToCommentPermission(category) {
    switch (category) {
        case TRAILER_REPORT_CATEGORY.TOTAL:
            return PERMISSION.TRAILER_AUDIT.TOTAL.COMMENT;
        case TRAILER_REPORT_CATEGORY.DISPATCH:
            return PERMISSION.TRAILER_AUDIT.DISPATCH.COMMENT;
        case TRAILER_REPORT_CATEGORY.HOME_TIME:
            return PERMISSION.TRAILER_AUDIT.HOME_TIME.COMMENT;
        case TRAILER_REPORT_CATEGORY.AT_YARD:
            return PERMISSION.TRAILER_AUDIT.YARD.COMMENT;
        case TRAILER_REPORT_CATEGORY.AT_DROP_YARD:
            return PERMISSION.TRAILER_AUDIT.DROP_YARD.COMMENT;
        case TRAILER_REPORT_CATEGORY.REPAIR:
            return PERMISSION.TRAILER_AUDIT.REPAIR.COMMENT;
        case TRAILER_REPORT_CATEGORY.ONEWAY:
            return PERMISSION.TRAILER_AUDIT.ONEWAY.COMMENT;
        case TRAILER_REPORT_CATEGORY.UNACCOUNTED:
            return PERMISSION.TRAILER_AUDIT.UNACCOUNTED.COMMENT;
        default:
            console.error("Unknown category:", category);
    }
}
