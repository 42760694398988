import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Banner.scss';

class Banner extends Component {
    static defaultProps = {
        type: 'warning'
    };

    static propTypes = {
        type: PropTypes.oneOf(['warning'])
    }

    render() {
        return (
            <div className={ `banner ${ this.props.type }` }>
                <FontAwesomeIcon icon={ faExclamationTriangle } className="icon" />
                { this.props.children }
            </div>
        );
    }
}

export default Banner;
