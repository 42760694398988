import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import TeamIcon from "components/team-icon/TeamIcon";
import SimpleTooltip from 'components/tooltip/SimpleTooltip';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import { SortParam } from 'global/constants';

const categoriesColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div className="long-column categories">
                { rowData.categories }
            </div>,
            td
        );
    }
};

const lastKnownAddressColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div className="long-column">
                { rowData.lastKnownAddress }
            </div>,
            td
        );
    }
};

const lastDepartureDateColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div>
                { rowData.lastDepartureDate }
            </div>,
            td
        );
    }
};

const fuelLevelColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div className="semi-bold">
                { rowData.fuelLevel }
            </div>,
            td
        );
    }
};

const companyNameColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div>
                { rowData.companyName }
            </div>,
            td
        );
    }
};

const dropYardColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div className="categories">
                { rowData.dropYard }
            </div>,
            td
        );
    }
};

const stationarySinceColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div>
                { rowData.trailerStationarySince }
            </div>,
            td
        );
    }
};

const expirationsColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div>
                { rowData.expirations }
            </div>,
            td
        );
    }
};

const earliestExpirationDateColumn = {
    createdCell: (td, cellData, rowData) => {
        const expirations = rowData.expirations.split(', ');

        ReactDOM.render(
            <div>
                { rowData.earliestExpirationDate }
                { rowData.earliestExpirationDate !== '-' && expirations.length > 1 && (
                    <div className="tooltip-container">
                        <FontAwesomeIcon icon={ faInfoCircle } className="tooltip-info-icon" />
                        <SimpleTooltip>
                            Showing expiration date of
                            <br />
                            earliest expiration: { expirations[0] }.
                        </SimpleTooltip>
                    </div>
                ) }
            </div>,
            td
        );
    }
};

const homeTimeStartDateColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div>
                { rowData.homeTimeStartDate }
            </div>,
            td
        );
    }
};

const homeTimeEndDateColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div>
                { rowData.homeTimeEndDate }
            </div>,
            td
        );
    }
};

const tripUpdateColumn = {
    createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
            <div>
                { rowData.legType2 }
            </div>,
            td
        );
    }
};

const actionColumn = {
    createdCell: (td, cellData, rowData) => {
        let commentIconClass = "comment-icon";

        if (rowData.hasComments) {
            commentIconClass += " medium";
        } else {
            commentIconClass += " light";
        }

        ReactDOM.render(
            <div className={ `${rowData.temporary ? "disabled-comment" : "comment"} d-flex justify-content-center` }>
                <FontAwesomeIcon icon={ faComment } className={ `${commentIconClass} ${rowData.temporary && "disabled-comment-icon"}` } />
            </div>,
            td
        );
    }
};

// columns for total trailer grid view
export function getTotalTrailerTableColumns(permissions) {
    let trailerTableColumns = getBaseTrailerTableColumns(permissions);
    trailerTableColumns = {
        columnNames: {
            ...trailerTableColumns.columnNames,
            4: SortParam.YARD,
            5: SortParam.STATIONARY_SINCE,
            6: SortParam.LAST_KNOWN_ADDRESS,
            7: SortParam.LAST_DEPARTURE_DATE
        },
        columns: [
            ...trailerTableColumns.columns,
            { title: "Categories", data: "categories" }, // 3
            { title: "Drop yard", data: "dropYard" }, // 4
            { title: "Stationary for", data: "trailerStationarySince" }, // 5
            { title: "Last known position", data: "lastKnownAddress" }, // 6
            { title: "Last departure date", data: "lastDepartureDate" } // 7
        ],
        columnDefs: [
            ...trailerTableColumns.columnDefs,
            { width: "20%", "targets": [3, 6] },
            { width: "10%", "targets": [4, 5, 7, 8] },
            { className: "dt-align-left", targets: [3, 4, 5, 6, 7] },
            { searchable: false, targets: [3, 4, 5, 6, 7] },
            { orderable: false, targets: [3, 8] },
            {
                targets: 3,
                ...categoriesColumn
            },
            {
                targets: 4,
                ...dropYardColumn
            },
            {
                targets: 5,
                ...stationarySinceColumn
            },
            {
                targets: 6,
                ...lastKnownAddressColumn
            },
            {
                targets: 7,
                ...lastDepartureDateColumn
            }
        ]
    };

    return addActionColumn(trailerTableColumns, 8);
}

// columns for dispatch, oneway trailer grid view
export function getDefaultTrailerTableColumns(permissions) {
    let trailerTableColumns = getBaseTrailerTableColumns(permissions);
    trailerTableColumns = {
        columnNames: {
            ...trailerTableColumns.columnNames,
            3: SortParam.LAST_KNOWN_ADDRESS,
            4: SortParam.LAST_DEPARTURE_DATE
        },
        columns: [
            ...trailerTableColumns.columns,
            { title: "Last known position", data: "lastKnownAddress" },
            { title: "Last departure date", data: "lastDepartureDate" }
        ],
        columnDefs: [
            ...trailerTableColumns.columnDefs,
            { width: "50%", "targets": [3] },
            { width: "20%", "targets": [4] },
            { className: "dt-align-left", targets: [3, 4] },
            { searchable: false, targets: [3, 4] },
            {
                targets: 3,
                ...lastKnownAddressColumn
            },
            {
                targets: 4,
                ...lastDepartureDateColumn
            },
        ]
    };

    return addActionColumn(trailerTableColumns, 5);
}

// columns for home time trailer grid view
export function getHomeTimeTrailerTableColumns(permissions) {
    let trailerTableColumns = getBaseTrailerTableColumns(permissions);
    trailerTableColumns = {
        columnNames: {
            ...trailerTableColumns.columnNames,
            3: SortParam.HOME_TIME_START_DATE,
            4: SortParam.HOME_TIME_END_DATE,
            5: SortParam.LAST_KNOWN_ADDRESS,
            6: SortParam.LAST_DEPARTURE_DATE,
        },
        columns: [
            ...trailerTableColumns.columns,
            { title: "Start date", data: "homeTimeStartDate" },
            { title: "End date", data: "homeTimeEndDate" },
            { title: "Last known position", data: "lastKnownAddress" },
            { title: "Last departure date", data: "lastDepartureDate" }
        ],
        columnDefs: [
            ...trailerTableColumns.columnDefs,
            { width: "25%", "targets": [5, 6] },
            { width: "10%", "targets": [3, 4] },
            { className: "dt-align-left", targets: [5, 6] },
            { searchable: false, targets: [3, 4, 5, 6] },
            {
                targets: 3,
                ...homeTimeStartDateColumn
            },
            {
                targets: 4,
                ...homeTimeEndDateColumn
            },
            {
                targets: 5,
                ...lastKnownAddressColumn
            },
            {
                targets: 6,
                ...lastDepartureDateColumn
            },
        ]
    };

    return addActionColumn(trailerTableColumns, 7);
}

// columns for yard trailer grid view
export function getYardTrailerTableColumns(permissions) {
    let trailerTableColumns = getBaseTrailerTableColumns(permissions);
    trailerTableColumns = {
        columnNames: {
            ...trailerTableColumns.columnNames,
            3: SortParam.POWER_STATE,
            4: SortParam.FUEL_LEVEL,
            5: SortParam.LAST_DEPARTURE_DATE
        },
        columns: [
            ...trailerTableColumns.columns,
            { title: "Power", data: "power" },
            { title: "Fuel level", data: "fuelLevel" },
            { title: "Last departure date", data: "lastDepartureDate" }
        ],
        columnDefs: [
            { width: "35%", "targets": [2] },
            ...trailerTableColumns.columnDefs,
            { width: "5%", "targets": [3] },
            { width: "20%", "targets": [4, 5] },
            { className: "dt-align-left", targets: [3, 4, 5] },
            { searchable: false, targets: [3, 4, 5] },
            {
                targets: 4,
                ...fuelLevelColumn
            },
            {
                targets: 5,
                ...lastDepartureDateColumn
            },
        ]
    };

    return addActionColumn(trailerTableColumns, 6);
}

// columns for single drop yard trailer grid view
export function getSingleDropYardTrailerTableColumns(permissions) {
    let trailerTableColumns = getBaseTrailerTableColumns(permissions);
    trailerTableColumns = {
        columnNames: {
            ...trailerTableColumns.columnNames,
            3: SortParam.POWER_STATE,
            4: SortParam.FUEL_LEVEL,
            5: SortParam.EXPIRATIONS,
            6: SortParam.EXPIRATION_DATE,
            7: SortParam.DAYS_AT_YARD,
            8: SortParam.LAST_DEPARTURE_DATE
        },
        columns: [
            ...trailerTableColumns.columns,
            { title: "Power", data: "power" },
            { title: "Fuel level", data: "fuelLevel" },
            { title: "Expirations", data: "expirations" },
            { title: "Expiration date", data: "earliestExpirationDate" },
            { title: "Days at drop yard", data: "atYardSince" },
            { title: "Last departure date", data: "lastDepartureDate" }
        ],
        columnDefs: [
            ...trailerTableColumns.columnDefs,
            { width: "5%", "targets": [3, 4] },
            { width: "10%", "targets": [7] },
            { width: "15%", "targets": [5, 6, 8] },
            { className: "dt-align-left", targets: [3, 4, 5, 6, 7, 8] },
            {
                targets: 4,
                ...fuelLevelColumn
            },
            {
                targets: 5,
                ...expirationsColumn
            },
            {
                targets: 6,
                ...earliestExpirationDateColumn
            },
            {
                targets: 8,
                ...lastDepartureDateColumn
            },
        ]
    };

    return addActionColumn(trailerTableColumns, 9);
}

// columns for drop yard trailer grid view
export function getDropYardTrailerTableColumns(permissions) {
    let trailerTableColumns = getBaseTrailerTableColumns(permissions);
    trailerTableColumns = {
        columnNames: {
            ...trailerTableColumns.columnNames,
            3: SortParam.YARD,
            4: SortParam.COMPANY_NAME,
            5: SortParam.POWER_STATE,
            6: SortParam.FUEL_LEVEL,
            7: SortParam.EXPIRATIONS,
            8: SortParam.EXPIRATION_DATE,
            9: SortParam.DAYS_AT_YARD,
            10: SortParam.LAST_DEPARTURE_DATE
        },
        columns: [
            ...trailerTableColumns.columns,
            { title: "Drop Yard", data: "dropYard" },
            { title: "Company name", data: "companyName" },
            { title: "Power", data: "power" },
            { title: "Fuel level", data: "fuelLevel" },
            { title: "Expirations", data: "expirations" },
            { title: "Expiration date", data: "earliestExpirationDate" },
            { title: "Days at drop yard", data: "atYardSince" },
            { title: "Last departure date", data: "lastDepartureDate" }
        ],
        columnDefs: [
            { width: "10%", "targets": [2] },
            ...trailerTableColumns.columnDefs,
            { width: "10%", "targets": [3, 8, 9, 10] },
            { width: "15%", "targets": [4] },
            { width: "5%", "targets": [5, 6, 7] },
            { className: "dt-align-left", targets: [3, 4, 5, 6, 7, 8, 9, 10] },
            {
                targets: 3,
                ...dropYardColumn
            },
            {
                targets: 4,
                ...companyNameColumn
            },
            {
                targets: 6,
                ...fuelLevelColumn
            },
            {
                targets: 7,
                ...expirationsColumn
            },
            {
                targets: 8,
                ...earliestExpirationDateColumn
            },
            {
                targets: 10,
                ...lastDepartureDateColumn
            }
        ]
    };

    return addActionColumn(trailerTableColumns, 11);
}

// columns for repair trailer grid view
export function getRepairTrailerTableColumns(permissions) {
    let trailerTableColumns = getBaseTrailerTableColumns(permissions);
    trailerTableColumns = {
        columnNames: {
            ...trailerTableColumns.columnNames,
            3: SortParam.EXPIRATIONS,
            4: SortParam.EXPIRATION_DATE,
            5: SortParam.YARD,
            6: SortParam.LAST_KNOWN_ADDRESS,
            7: SortParam.LAST_DEPARTURE_DATE
        },
        columns: [
            ...trailerTableColumns.columns,
            { title: "Expirations", data: "expirations" },
            { title: "Expiration date", data: "earliestExpirationDate" },
            { title: "Drop Yard", data: "dropYard" },
            { title: "Last known position", data: "lastKnownAddress" },
            { title: "Last departure date", data: "lastDepartureDate" }
        ],
        columnDefs: [
            { width: "10%", "targets": [2] },
            ...trailerTableColumns.columnDefs,
            { width: "15%", "targets": [3] },
            { width: "10%", "targets": [4, 7] },
            { width: "5%", "targets": [5] },
            { width: "30%", "targets": [6] },
            { className: "dt-align-left", targets: [3, 4, 5, 6, 7] },
            {
                targets: 3,
                ...expirationsColumn
            },
            {
                targets: 4,
                ...earliestExpirationDateColumn
            },
            {
                targets: 5,
                ...dropYardColumn
            },
            {
                targets: 6,
                ...lastKnownAddressColumn
            },
            {
                targets: 7,
                ...lastDepartureDateColumn
            }
        ]
    };

    return addActionColumn(trailerTableColumns, 8);
}

// columns for unaccounted trailer grid view
export function getUnaccountedTrailerTableColumns(permissions) {
    let trailerTableColumns = getBaseTrailerTableColumns(permissions);
    trailerTableColumns = {
        columnNames: {
            ...trailerTableColumns.columnNames,
            3: SortParam.LAST_KNOWN_ADDRESS,
            4: SortParam.LAST_DEPARTURE_DATE,
            5: SortParam.LEG_TYPE2
        },
        columns: [
            ...trailerTableColumns.columns,
            { title: "Last known position", data: "lastKnownAddress" },
            { title: "Last departure date", data: "lastDepartureDate" },
            { title: "Trip Update", data: "legType2" }
        ],
        columnDefs: [
            ...trailerTableColumns.columnDefs,
            { width: "40%", targets: [3] },
            { width: "20%", targets: [4] },
            { width: "10%", targets: [5] },
            { targets: 3, ...lastKnownAddressColumn },
            { targets: 4, ...lastDepartureDateColumn },
            { targets: 5, ...tripUpdateColumn }
        ]
    };

    return addActionColumn(trailerTableColumns, 6);
}

function getBaseTrailerTableColumns(permissions) {
    const columnNames = {
        0: SortParam.TRAILER,
        1: SortParam.ASSIGNMENT,
        2: SortParam.DRIVER
    };

    const columns = [
        { title: "Trailer #", data: "trailerId" },
        { title: "Latest Order #", data: "orderId" },
        { title: "Driver", data: "driver" }
    ];

    const columnDefs = [
        { width: "5%", "targets": [0] },
        { width: "10%", "targets": [1] },
        { width: "15%", "targets": [2] },
        { className: "dt-align-left", targets: [2] },
        { className: "dt-align-right", targets: [0, 1] },
        { searchable: false, targets: [0, 1, 2] },
        {
            targets: 0,
            createdCell: (td, _cellData, rowData) => ReactDOM.render(
                <TrailerCell rowData={ rowData } permissions={ permissions } />,
                td
            )
        },
        {
            targets: 1,
            createdCell: (td, _cellData, rowData) => ReactDOM.render(
                <AssignmentCell rowData={ rowData } permissions={ permissions } />,
                td
            )
        },
        {
            targets: 2,
            createdCell: (td, cellData, rowData) => {
                ReactDOM.render(
                    <div className="centerAlign">
                        { rowData.driver !== "Unknown" && rowData.driver !== "-" && (
                            <TeamIcon style={ { marginRight: 6 } } color={ rowData.division } />
                        ) }
                        { rowData.driver }
                    </div>,
                    td
                );
            }
        }
    ];

    return {
        columnNames,
        columns,
        columnDefs
    };
}

function addActionColumn(trailerTableColumns, target) {
    return {
        ...trailerTableColumns,
        columns: [
            ...trailerTableColumns.columns,
            { title: "Action", defaultContent: "" }
        ],
        columnDefs: [
            ...trailerTableColumns.columnDefs,
            { width: "5%", "targets": [target] },
            { className: "dt-align-center", targets: [target] },
            { orderable: false, targets: [target] },
            { targets: target, ...actionColumn }
        ]
    };
}
