import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Link } from 'react-router-dom';

import AuthContext from 'AuthContext';
import { reactPlugin } from 'AppInsights';
import DataTableComp from 'components/datatable/DataTableComp';
import { defaultGridRecordsNumber, mapSort } from 'global/constants';
import QueryParamService from 'global/services/QueryParamService';
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import { getCurrentDate, getMonthsBefore } from 'global/services/DateTimeService';
import StatsFilter from '../StatsFilter';

import './IssuesTable.scss';

const CACHE = {};

class IssuesTable extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        const queryString = window.location.search.slice(1);
        const pageQueryParam = QueryParamService.parseSimpleValueFromQueryString(queryString, 'page');
        const currentPage = pageQueryParam ? parseInt(pageQueryParam) : 1;

        let teamsParam;
        if (queryString.includes('team')) {
            const param = QueryParamService.parseSimpleValueFromQueryString(queryString, 'team');
            teamsParam = Array.isArray(param) ? param : [param];
        }

        let issueTypesParam;
        if (queryString.includes('issueType')) {
            const param = QueryParamService.parseSimpleValueFromQueryString(queryString, 'issueType');
            issueTypesParam = Array.isArray(param) ? param : [param];
        }

        this.state = {
            sort: [],
            dataUpdated: false,
            issuesLoaded: false,
            filter: {
                startTime: queryString.includes('from') ? getCurrentDate(new Date(QueryParamService.parseSimpleValueFromQueryString(queryString, 'from'))) : getMonthsBefore(new Date(), 1),
                endTime: queryString.includes('to') ? getCurrentDate(new Date(QueryParamService.parseSimpleValueFromQueryString(queryString, 'to'))) : getCurrentDate(new Date()),
                teams: teamsParam,
                issueTypes: issueTypesParam
            },
            page: currentPage,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            }
        };

        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleFirstPage = this.handleFirstPage.bind(this);
        this.handleLastPage = this.handleLastPage.bind(this);
        this.addSortingForColumn = this.addSortingForColumn.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleRecordsNumberChange = this.handleRecordsNumberChange.bind(this);
    }

    async componentDidMount() {
        await this.fetchIssues();
    }

    async handlePage(page) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            QueryParamService.addPageToQueryString(window.location.search.slice(1), page);
            await this.fetchIssues();
        });
    }

    async handlePreviousPage() {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    async handleNextPage() {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    async handleFirstPage() {
        await this.handlePage(1);
    }

    async handleLastPage() {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    async handlePageChange(event) {
        const value = event.target.value;

        if (isNaN(value) || value === '') {
            this.setState({
                page: ''
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchIssues();
            }, 1000);
        }

        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    async handleRecordsNumberChange(event) {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
            dataUpdated: false
        }, async () => await this.fetchIssues());
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const existing = sortingArray.findIndex(x => x && x.columnName === this.props.columnNames[index]);

        if (existing === -1) {
            sortingArray.push({
                columnIndex: index,
                columnName: this.props.columnNames[index],
                direction: sorting
            });
        } else if (sorting === '') {
            sortingArray.splice(existing, 1);
        } else {
            sortingArray[existing] = {
                columnIndex: index,
                columnName: this.props.columnNames[index],
                direction: sorting
            };
        }

        QueryParamService.addSortToQueryString(window.location.search.slice(1), sortingArray);

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, async () => await this.fetchIssues());
    }

    handleFilterChange(selectedTeams, selectedTypes, selectedStartTime, selectedEndTime) {
        let newTeams = [];
        if (selectedTeams.indexOf('ALL') === -1 && selectedTeams && selectedTeams.length !== 0) {
            newTeams = selectedTeams;
        }

        let newTypes = [];
        if (selectedTypes.indexOf('ALL') === -1) {
            newTypes = selectedTypes;
        }

        this.setState({
            filter: {
                teams: newTeams,
                issueTypes: newTypes,
                startTime: selectedStartTime,
                endTime: selectedEndTime
            }
        }, async () => {
            await this.fetchIssues();
            QueryParamService.addParamsToUrl({ from: this.state.filter.startTime, to: this.state.filter.endTime });
            QueryParamService.addTeamsToQueryString(window.location.search.slice(1), this.state.filter.teams);
            QueryParamService.addIssueTypesToQueryString(window.location.search.slice(1), this.state.filter.issueTypes);
        });
    }

    async fetchIssues() {
        const cachingIndex = this.context.team;

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({ dataUpdated: false });
        }

        try {
            const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
            const tableSortObject = formSortObject(sort, this.props.columnNames);

            const response = await this.props.fetchIssues(sort, this.state.filter, this.state.page, this.state.pagingInfo.recordsNumber);

            if (!response) {
                this.setState({ dataUpdated: false });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const newData = mapGridResponseData(response.data, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                pagingInfo: newData.pagingInfo,
                dataUpdated: true,
                issuesLoaded: true,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                data: [],
                dataUpdated: true,
                issuesLoaded: true,
                sort: []
            });
        }
    }

    render() {
        return (
            <div className="container-fluid page issues">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/statistics/all-teams"> Statistics </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="#!">{ this.props.title }</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <div className="d-flex">
                                <p className="heading">
                                    { this.props.title }
                                </p>
                                &nbsp; &nbsp;
                                <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={ !this.state.dataUpdated || !this.state.issuesLoaded } />
                            </div>
                            <StatsFilter
                                startTime={ this.state.filter.startTime }
                                endTime={ this.state.filter.endTime }
                                filter={ this.state.filter }
                                onFilterChange={ this.handleFilterChange }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="issues-div">
                            { this.state.issuesLoaded && <DataTableComp
                                tableId="statisticsIssues"
                                columns={ this.props.columns }
                                columnDefs={ this.props.columnDefs }
                                orderRule={ [] }
                                data={ this.state.data }
                                customTableClass="cell-border"
                                tableHeight="1200px"
                                handlePreviousPage={ this.handlePreviousPage }
                                handleNextPage={ this.handleNextPage }
                                handleFirstPage={ this.handleFirstPage }
                                handleLastPage={ this.handleLastPage }
                                handlePageChange={ this.handlePageChange }
                                handleRecordsNumber={ this.handleRecordsNumberChange }
                                pagingInfo={ this.state.pagingInfo }
                                addSortingForColumn={ this.addSortingForColumn }
                                sortRule={ this.state.sort.concat([]) }
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, IssuesTable, 'IssuesTable'));
