import React, { Component } from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faEye } from "@fortawesome/free-solid-svg-icons";

import { formatShortDate, getDifferenceBetweenDates } from "global/services/DateTimeService";
import { mapSort, SortParam } from "global/constants";
import DataTableComp from "components/datatable/DataTableComp";
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import TeamIcon from "components/team-icon/TeamIcon";
import AuthContext from "AuthContext";

class DetachedMissingTable extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            0: SortParam.TRAILER,
            2: SortParam.DRIVER,
            3: SortParam.START_TIME
        };

        this.columns = [
            { title: "Trailer ID", data: "trailerId" },
            { title: "Last Order ID", data: "orderId" },
            { title: "Driver", data: "driver" },
            { title: "Created", data: "created" },
            { title: props.detached ? "Detached For" : "Missing For", data: "created" },
            { title: "Last known truck position", data: "truckPosition" },
            { title: "Last known trailer position", data: "trailerPosition" },
            { title: "Distance", data: "distance" },
            { title: "Action", data: "action" }
        ];

        const textColor = this.props.detached ? 'orangeColorText orangeColorTextHover' : 'redColorText redColorTextHover';
        this.columnDefs = [
            { width: "5%", "targets": [0, 1, 7, 8]},
            { width: "10%", "targets": [2, 3, 4]},
            { width: "25%", "targets": [5, 6]},
            { className: "dt-align-center", targets: [8] },
            { className: "dt-align-left", targets: [2, 3, 5, 6, 7] },
            { className: "dt-align-right", targets: [0, 1, 4] },
            {
                orderable: false,
                targets: [1, 4, 5, 6, 7, 8]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell className={ textColor } rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="centerAlign">
                            <TeamIcon color={ rowData.division } />
                            <span style={ { marginLeft: 6 } }>
                                { rowData.driver }
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className={ "light-bold " + textColor }>
                            { getDifferenceBetweenDates(rowData.created, this.props.reportDate) }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="no-flex">
                            { rowData.truckPosition }
                            <br />
                            <span className="greyColorText">
                                ({ formatShortDate(new Date(rowData.truckUpdated)) })
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="no-flex">
                            { rowData.trailerPosition }
                            <br />
                            <span className="greyColorText">
                                ({ formatShortDate(new Date(rowData.trailerUpdated)) })
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            { rowData.distance + " mi." }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData) => {
                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon";
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    ReactDOM.render(
                        <div className="action-items">
                            <div className="seen" id={ rowData.id }>
                                <FontAwesomeIcon icon={ faEye } className={ seenIconClass } />
                            </div>
                            <div className="comment">
                                <FontAwesomeIcon icon={ faComment } className={ commentIconClass } />
                            </div>
                        </div>,
                        td
                    );
                }
            }
        ];

        this.state = {
            sort: []
        };
    }

    addSortingForColumn(index, sorting) {
        this.setState(prevState => {
            const sortingArray = prevState.sort;
            const existing = sortingArray.findIndex(x => x && x.columnName === this.columnNames[index]);

            if (existing === -1) {
                sortingArray.push({
                    columnIndex: index,
                    columnName: this.columnNames[index],
                    direction: sorting
                });
            } else if (sorting === "") {
                sortingArray.splice(existing, 1);
            } else {
                sortingArray[existing] = {
                    columnIndex: index,
                    columnName: this.columnNames[index],
                    direction: sorting
                };
            }

            return {
                sort: sortingArray
            };
        }, () => this.props.sortBy(mapSort(this.state.sort)));
    }

    render() {
        return (
            <div className="detached-missing-table-container">
                <DataTableComp
                    tableId={ this.props.id }
                    data={ this.props.data }
                    columns={ this.columns }
                    columnDefs={ this.columnDefs }
                    isDetailView={ false }
                    onClickLocation={ {
                        pathname: "/details",
                        state: { trailerId: this.props.data.onClickState },
                    } }
                    sortRule={ this.state.sort.concat([]) }
                    addSortingForColumn={ this.addSortingForColumn.bind(this) }
                    onReceiveNewComment={ this.props.onReceiveNewComment }
                    onDeleteComment={ this.props.onDeleteComment }
                />
            </div>
        );
    }
}

export default DetachedMissingTable;
