import React, { Component } from 'react';
import { Dropdown } from "react-bootstrap";

import "./CustomDropdown.scss";

class CustomDropdown extends Component {
    render() {
        const { title, items, disabled } = this.props;
        const itemsToShow = items || [];

        return (
            <Dropdown bsPrefix="custom-dropdown">
                <Dropdown.Toggle
                    id="custom-dropdown-toggle"
                    disabled={ disabled || !itemsToShow.length }
                    variant="custom"
                >
                    { title }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    { itemsToShow.map(item => <Dropdown.Item key={ item.key } as="div">{ item }</Dropdown.Item>) }
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export default CustomDropdown;
