import React, { Component } from 'react';

import AuthContext from 'AuthContext';
import Banner from 'components/banner/Banner';
import { monitoredServicesStatusUrl } from 'global/constants';
import StringUtils from 'global/utils/StringUtils';

class OutageBanner extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            outages: []
        };

        this.fetchOutageStatus = this.fetchOutageStatus.bind(this);

        this.interval = setInterval(this.fetchOutageStatus, 60000);
    }

    componentDidMount() {
        this.fetchOutageStatus();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    fetchOutageStatus() {
        this.context.get(monitoredServicesStatusUrl, { available: false })
            .then(response => {
                this.setState({
                    outages: response.map(service => service.serviceName)
                });
            })
            .catch(console.error);
    }

    buildOutageString() {
        if (this.state.outages.length === 1) {
            return `${ this.state.outages[0] } system outage has been detected - the data might not be updating.`;
        } else {
            return `${ StringUtils.concatSentence(this.state.outages) } system outages have been detected - the data might not be updating.`;
        }
    }

    render() {
        if (this.state.outages.length === 0) {
            return null;
        }

        return <Banner>{ this.buildOutageString() }</Banner>;
    }
}

export default OutageBanner;
