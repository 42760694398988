import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment as commentIcon, faEye as seenIcon } from '@fortawesome/free-solid-svg-icons';
import {
    faArrowLeft as leftArrowIcon,
    faArrowsRotate as refreshIcon, faArrowUpRightFromSquare as linkIcon,
    faDownload as downloadIcon
} from '@fortawesome/pro-solid-svg-icons';

import AuthContext from 'AuthContext';
import { reactPlugin } from 'AppInsights';
import CanAccess from 'components/can-access/CanAccess';
import DataTableComp from 'components/datatable/DataTableComp';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import TeamIcon from 'components/team-icon/TeamIcon';
import ConfirmPopup from 'components/popup/ConfirmPopup';
import Message from 'components/Message';
import {
    mapSort,
    moreGridRecordsNumber,
    reportUrl,
    setupTemporaryAuditInterval,
    SortParam,
    summaryReportUrl
} from 'global/constants';
import { formatDateTimeNumeric } from 'global/services/DateTimeService';
import history from 'global/history';
import { mapTrailersFromResponse } from 'global/services/TrailerReportApiService';
import { mapReportIssuesFromResponse } from 'global/services/IssueApiService';
import QueryParamService from 'global/services/QueryParamService';
import { PERMISSION } from 'global/utils/auth';
import deleteComment from 'global/utils/deleteComment';
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';

import './IssueTrailerTable.scss';

class TrailerWithClaimTable extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            0: SortParam.TRAILER,
            1: SortParam.ASSIGNMENT,
            2: SortParam.DRIVER,
            4: SortParam.START_TIME
        }

        this.columns = [
            { title: "Trailer #", data: "trailerId" },
            { title: "Latest Order #", data: "orderId" },
            { title: "Driver", data: "driver" },
            { title: "Last known position", data: "lastKnownAddress" },
            { title: "Created", data: "formattedCreated" },
            { title: "Last departure date", data: "lastDepartureDate" },
            { title: "Action", defaultContent: "" }
        ];

        this.columnDefs = [
            { width: "5%", "targets": [6] },
            { width: "10%", "targets": [0, 1, 4, 5] },
            { width: "15%", "targets": [2] },
            { width: "40%", "targets": [3] },
            { className: "dt-align-right", targets: [0, 1] },
            { className: "dt-align-center", targets: [6] },
            {
                orderable: false,
                targets: [3, 5, 6]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="centerAlign">
                            {rowData.driver !== "Unknown" && (
                                <TeamIcon style={{ marginRight: 6 }} color={rowData.division} />
                            )}
                            {rowData.driver}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="long-column">
                            {rowData.lastKnownAddress}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            {rowData.lastDepartureDate}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData) => {
                    const hasActions = !!rowData.issue;
                    let seenIconClass, seenDivClass, commentIconClass, commentDivClass;

                    if (hasActions) {
                        seenDivClass = "seen";
                        seenIconClass = "seen-icon";
                        if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                            seenIconClass += " light";
                        } else {
                            seenIconClass += " medium";
                        }

                        commentDivClass = "comment";
                        commentIconClass = "comment-icon";
                        if (rowData.comments.length === 0) {
                            commentIconClass += " light";
                        } else {
                            commentIconClass += " medium";
                        }
                    } else {
                        seenDivClass = "disabled-seen";
                        seenIconClass = "disabled-seen-icon";

                        commentDivClass = "disabled-comment";
                        commentIconClass = "disabled-comment-icon";
                    }

                    ReactDOM.render(
                        <div className="flex-always action-items">
                            <div className={ seenDivClass } id={ rowData.id }>
                                <FontAwesomeIcon icon={ seenIcon } className={ seenIconClass } />
                            </div>
                            <div className={ commentDivClass }>
                                <FontAwesomeIcon icon={ commentIcon } className={ commentIconClass } />
                            </div>
                        </div>,
                        td
                    );
                }
            }
        ];

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        const page = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page');

        this.state = {
            data: [],
            columns: this.columns,
            columnDefs: this.columnDefs,
            sort: [],
            loading: true,
            initialLoad: false,
            csvLoading: false,
            page: page ? parseInt(page) : 1,
            pagingInfo: {
                recordsNumber: moreGridRecordsNumber,
                totalItemNumber: 0
            }
        }

        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
        this.addSortingForColumn = this.addSortingForColumn.bind(this);
        this.exportCsv = this.exportCsv.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleFirstPage = this.handleFirstPage.bind(this);
        this.handleLastPage = this.handleLastPage.bind(this);
        this.handleRecordsNumber = this.handleRecordsNumber.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.onRefreshAuditClick = this.onRefreshAuditClick.bind(this);
        this.onCloseConfirmModal = this.onCloseConfirmModal.bind(this);
        this.fetchTemporaryAudit = this.fetchTemporaryAudit.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    async componentDidUpdate(prevProps, prevState) {
        const oldTeam = (prevProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            await this.handleFirstPage();
        }

        if (prevState.temporaryReport !== this.state.temporaryReport) {
            this.checkForTemporaryAuditInterval = setupTemporaryAuditInterval(this.state.temporaryReport,
                this.state.temporary,
                this.fetchTemporaryAudit,
                this.checkForTemporaryAuditInterval
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.checkForTemporaryAuditInterval);
    }

    async fetchData() {
        this.setState({
            loading: true
        });

        const queryString = window.location.search.slice(1);
        const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(queryString);
        const tableSortObject = formSortObject(sort, this.columnNames);

        try {
            const claimIssueDetails = await this.context.get(`${reportUrl}/${this.props.match.params.id}/issues`, {
                issueTypes: ["CLAIM"],
                sort: sort,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            const state = this.props.location.state;

            let temporary;
            let reportCreated;
            let newerTemporaryExists;
            let breadcrumb;

            if (state && state.time && state.newerTemporaryExists && state.temporary) {
                temporary = state.temporary;
                reportCreated = state.time;
                newerTemporaryExists = state.newerTemporaryExists;
                breadcrumb = "Trailer Audit for " + formatDateTimeNumeric(new Date(reportCreated));
            } else {
                const reportDetails = await this.context.get(summaryReportUrl, { reportId: this.props.match.params.id });
                temporary = reportDetails.temporary;
                reportCreated = reportDetails.time;
                breadcrumb = "Trailer Audit for " + formatDateTimeNumeric(new Date(reportCreated));
            }

            if (!state || !state.time || !state.newerTemporaryExists || !state.temporary) {
                const temporaryReport = await this.fetchTemporaryAudit();
                const latestReport = await this.context.get(summaryReportUrl, { temporary: false });

                newerTemporaryExists = temporaryReport.time > latestReport.time;
            }

            let data = [];
            let pagingInfo = this.state.pagingInfo;
            let currentPage;
            let totalPageNumber;
            if (claimIssueDetails.data.length > 0) {
                const trailerIds = claimIssueDetails.data.map(detail => detail.issue.trailer.id);

                const response = await this.context.get(`${reportUrl}/${this.props.match.params.id}/trailers`, {
                    claim: true,
                    sort: sort,
                    trailerIds: trailerIds
                });

                data = mapReportIssuesFromResponse(claimIssueDetails.data, response.data);
                pagingInfo = mapGridResponseData(data, claimIssueDetails).pagingInfo;
                totalPageNumber = Math.ceil(claimIssueDetails.available / claimIssueDetails.pageSize);
                currentPage = this.state.page || 1;
            } else {
                const response = await this.context.get(`${reportUrl}/${this.props.match.params.id}/trailers`, {
                    claim: true,
                    sort: sort,
                    pageNumber: this.state.page,
                    pageSize: this.state.pagingInfo.recordsNumber
                });

                if (response.status === "error" || !response.data) {
                    const statusCode = response.message.response.status;

                    if (404 === statusCode) {
                        history.push({
                            pathname: '/trailer-audit',
                        });

                        return;
                    }

                    console.error(response.message);
                    this.setState({
                        loading: false
                    });

                    return;
                }

                data = mapTrailersFromResponse(response.data, { claim: true }, null, "Trailers with Claim");
                pagingInfo = mapGridResponseData(data, response).pagingInfo;

                totalPageNumber = Math.ceil(response.available / response.pageSize);
                currentPage = this.state.page || 1;
            }
            
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            QueryParamService.addPageToQueryString(queryString, currentPage || 1);

            this.setState({
                data,
                reportCreated,
                newerTemporaryExists,
                initialLoad: true,
                loading: false,
                pagingInfo,
                sort: tableSortObject,
                breadcrumb,
                temporary
            });
        } catch (error) {
            console.error("Error while fetching trailers with claim.", error);
            this.setState({
                loading: false,
                sort: tableSortObject
            });
        }
    }

    addSortingForColumn(index, sorting) {
        this.setState(prevState => {
            const sortingArray = prevState.sort;
            const existing = sortingArray.findIndex(x => x && x.columnName === this.columnNames[index]);

            if (existing === -1) {
                sortingArray.push({
                    columnIndex: index,
                    columnName: this.columnNames[index],
                    direction: sorting
                });
            } else if (sorting === "") {
                sortingArray.splice(existing, 1);
            } else {
                sortingArray[existing] = {
                    columnIndex: index,
                    columnName: this.columnNames[index],
                    direction: sorting
                };
            }

            const queryString = window.location.search.slice(1);
            QueryParamService.addSortToQueryString(queryString, sortingArray);

            return {
                sort: sortingArray
            };
        }, this.fetchData);
    }

    onReceiveNewComment(comment) {
        const newData = this.state.data;
        newData.find(data => data.issue.id === comment.issue.id).comments.unshift(comment);

        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        this.setState({
            allIssues: deleteComment(deletedComment, this.state.data)
        });
    }

    async handlePage(page) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchData();
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handlePreviousPage() {
        this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage() {
        this.handlePage(parseInt(this.state.page) + 1);
    }

    handleFirstPage() {
        this.handlePage(1);
    }

    handleLastPage() {
        this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handleLastAvailablePage(lastAvailablePage) {
        this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber(event) {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
            dataUpdated: false
        }, this.fetchData);
    }

    async handlePageChange(event) {
        const value = event.target.value;
        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchData();
            }, 1000);
        }
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    async exportCsv() {
        try {
            this.setState({
                csvLoading: true
            });

            const queryString = window.location.search.slice(1);
            const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(queryString);

            await this.context.download(`${reportUrl}/${this.props.match.params.id}/export`, {
                claim: true,
                sort
            });
        } catch (error) {
            console.error('Error while downloading report claim trailers csv.', error);
        } finally {
            this.setState({
                csvLoading: false
            });
        }
    }

    async fetchTemporaryAudit() {
        const response = await this.context.get(`${reportUrl}/temporary`, { });

        this.setState({
            temporaryReport: response
        });

        return response;
    }

    async onRefreshAuditClick() {
        await this.context.post(`${reportUrl}/temporary`, {});
    }

    onCloseConfirmModal(confirmRefresh) {
        this.setState({
            showConfirmPopup: false
        });
        if (confirmRefresh) {
            history.push({
                pathname: "/trailer-audit"
            });
        }
    }

    render() {
        let infoMessage = '';
        let linkButton = '';
        if (this.state.temporary) {
            infoMessage = 'This is a manually generated Temporary Trailer Audit and its action functionality may be disabled for some audit categories.';
            linkButton = (
                <Link className="refresh-button" to={ `/trailer-audit` }>
                    <FontAwesomeIcon icon={ leftArrowIcon } className="icon" />
                    BACK TO TRAILER AUDIT
                </Link>
            );
        } else if (this.state.temporaryReport && !this.state.temporaryReport.completed) {
            infoMessage = 'The Trailer Audit is currently being updated. This may take a couple of minutes.';
        } else if (this.state.temporaryReport && this.state.temporaryReport.completed) {
            infoMessage = `A new temporary Trailer Audit has been manually generated at ${formatDateTimeNumeric(new Date(this.state.temporaryReport.time))}.`;
            linkButton = (
                <Link className="refresh-button" to={ `/trailer-audit/${this.state.temporaryReport.id}` }>
                    <FontAwesomeIcon icon={ linkIcon } className="icon" />
                    OPEN TEMPORARY AUDIT
                </Link>
            );
        }

        const disableRefresh = !this.state.temporary && this.state.temporaryReport && !this.state.temporaryReport.completed;

        const showTemporaryReportMessage = this.state.initialLoad && (this.state.temporary || this.state.newerTemporaryExists);

        return (
            <div className="container-fluid page report-issue-trailers">
                { showTemporaryReportMessage && (
                    <Message>
                        <div className="message">
                            { infoMessage }
                            { linkButton }
                        </div>
                    </Message>
                ) }
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/trailer-audit/" + this.props.match.params.id}>
                                        { this.state.temporary && "Temporary" } { this.state.breadcrumb ? this.state.breadcrumb : "TRAILER AUDIT" }
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to={window.location.pathname}>
                                        Trailers with Claim
                                    </Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading">
                                Trailers with Claim
                                &nbsp;
                                {!this.state.loading && (
                                    <span className="trailer-count">
                                        ({this.state.pagingInfo.totalItemNumber} trailer{this.state.pagingInfo.totalItemNumber !== 1 && "s"})
                                    </span>
                                )}
                                <Loader
                                    className="align-self-center"
                                    type="TailSpin"
                                    color="#289AC2"
                                    height={36}
                                    width={36}
                                    visible={this.state.loading}
                                />
                            </p>
                            <div className="d-flex">
                                { this.state.initialLoad && (
                                    <CanAccess
                                        action={ [PERMISSION.TRAILER_AUDIT.REFRESH] }
                                        yes={
                                            <button className={ `refresh-button ${disableRefresh && "disabled"}` }
                                                    onClick={ () => this.setState({ showConfirmPopup: true }) }
                                                    disabled={ disableRefresh }>
                                                <FontAwesomeIcon icon={ refreshIcon } className="icon" />
                                                REFRESH
                                            </button>
                                        }
                                    />
                                ) }
                                { this.state.data.length > 0 && !this.state.loading &&
                                    <div className="row">
                                        <div className="col">
                                            <Button variant="continue" onClick={this.exportCsv}>
                                                <FontAwesomeIcon icon={ downloadIcon } className="add-icon" />
                                                Export CSV
                                                <Loader
                                                    className="csv-loader"
                                                    type="TailSpin"
                                                    color="white"
                                                    height={ 13 }
                                                    width={ 13 }
                                                    visible={ this.state.csvLoading }
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="report-trailers-div">
                            { this.state.initialLoad && (
                                <DataTableComp
                                    tableId="reportTrailersWithClaim"
                                    columns={this.columns}
                                    columnDefs={this.columnDefs}
                                    data={this.state.data}
                                    customTableClass="cell-border"
                                    tableHeight="1200px"
                                    handlePreviousPage={this.handlePreviousPage}
                                    handleNextPage={this.handleNextPage}
                                    handleFirstPage={this.handleFirstPage}
                                    handleLastPage={this.handleLastPage}
                                    handleRecordsNumber={this.handleRecordsNumber}
                                    handlePageChange={this.handlePageChange}
                                    onReceiveNewComment={this.onReceiveNewComment}
                                    onDeleteComment={this.onDeleteComment}
                                    pagingInfo={this.state.pagingInfo}
                                    sortRule={this.state.sort.concat([])}
                                    addSortingForColumn={this.addSortingForColumn}
                                    account={this.props.account}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <ConfirmPopup
                    closeModal={ this.onCloseConfirmModal }
                    onConfirm={ this.onRefreshAuditClick }
                    showModal={ this.state.showConfirmPopup }
                    title="REFRESH AUDIT"
                    subtitle="Refresh Audit?"
                >
                    Are you sure you want to initiate the refresh procedure for the Trailer Audit?
                    <br /><br />This manually generated Audit is read-only and will not be stored in the Audit History. You will still be able to access the current Trailer Audit via side navigation bar.
                </ConfirmPopup>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, TrailerWithClaimTable, "AuditTrailerWithClaimTable"));
