import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';

import AuthContext from 'AuthContext';
import SimpleContainer from 'components/container/SimpleContainer';
import Message from 'components/Message';
import Pill from 'components/input/Pill';

import './ReeferControlPopup.scss';

const COMMAND_OPTIONS = [
    { value: 'POWER', label: 'Power' },
    { value: 'CONFIGURE_UNIT_MODE', label: 'Unit Mode' },
    { value: 'CONFIGURE_SET_POINT', label: 'Set Point' },
    { value: 'PING', label: 'Trailer Ping' }
];

const DEFAULT_STATE = {
    command: undefined,
    power: true,
    unitMode: 'CONTINUOUS',
    setPoint: 0,
    setPointValid: true,
    error: undefined,
    info: undefined,
    derive: true,
    preventSubmit: false
};

const PING_INFO = 'Request an updated trailer reading. If successful, the reading will be synced in the next few minutes.';

export default class ReeferControlPopup extends Component {
    static contextType = AuthContext;

    static defaultProps = {
        isLoading: false
    }

    state = DEFAULT_STATE;

    static getDerivedStateFromProps(props, state) {
        if (!state.derive) {
            return null;
        }

        return {
            power: props.currentPower == null || props.currentPower === 'Off',
            unitMode: props.currentMode === 'Continuous' ? 'CYCLE_SENTRY' : 'CONTINUOUS',
            setPoint: props.currentSetPoint ? Number.parseInt(props.currentSetPoint) : 0
        };
    }

    componentDidMount() {
        this._fetchExisting();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.showModal && !prevProps.showModal) {
            this._fetchExisting();
        }
    }

    async _fetchExisting() {
        if (this.props.trailerId) {
            const response = await this.context.get(`/api/v1/trailers/commands/${this.props.trailerId}`, {});
            if (response) {
                this.setState({
                    info: 'There already exists an active command for this trailer. ' +
                        'Please wait for it to complete before submitting a new one.'
                });
            }
        }
    }

    _onCommandChanged(object) {
        return this.setState({ command: object.value, derive: false });
    }

    _onPowerChanged(power) {
        return this.setState({ power, derive: false });
    }

    _onUnitModeChanged(event) {
        return this.setState({ unitMode: event.target.value, derive: false });
    }

    _onSetPointChanged(event) {
        return this.setState({ setPoint: Number.parseInt(event.target.value), derive: false, setPointValid: true });
    }

    _onFormSubmit(event) {
        event.preventDefault();

        this.setState({ preventSubmit: true }, async () => {
            let command = this.state.command;

            switch (command) {
                case 'POWER':
                    command += '_' + (this.state.power ? 'ON' : 'OFF');
                    break;
                case 'CONFIGURE_UNIT_MODE':
                    command += '_' + this.state.unitMode;
                    break;
                case 'CONFIGURE_SET_POINT':
                    if (this.props.currentSetPoint !== undefined && this.props.currentSetPoint !== null) {
                        if (this.props.currentSetPoint === this.state.setPoint) {
                            this.setState({ setPointValid: false });
                            return;
                        }
                    }

                    break;
                case 'PING':
                    break;
                default:
                    console.error("Unknown command:", this.state.command);
                    return;
            }

            const request = {
                command,
                setPoint: this.state.setPoint
            };

            const response = await this.context.post(
                `/api/v1/admin/trailers/commands/${this.props.trailerId}`,
                request
            );

            if ('error' === response.status) {
                const message = ((((response || {}).message || {}).response || {}).data || {}).error || 'Unknown Error';
                this.setState({ error: message });
            } else {
                this.setState({ error: undefined });

                await this._fetchExisting();
                this.onClose();
            }
        });
    }

    onClose() {
        this.setState(DEFAULT_STATE, () => {
            if (this.props.closeModal) {
                this.props.closeModal();
            }
        });
    }

    render() {
        let preventSubmit = false;

        let powerInput = (<></>);
        let unitModeInput = (<></>);
        let setPointInput = (<></>);

        const inputLabelClass = 'label input-label';
        const commandLabelClass = 'label command-label';

        switch (this.state.command) {
            case 'POWER': {
                const canPick = this.props.currentPower == null || this.props.currentPower === 'NO DATA';

                let message = (<></>);
                if (!canPick) {
                    message = (
                        <Message type="info">
                            Power can only be turned { this.state.power ? 'on ' : 'off ' }
                            because the trailer is already { this.state.power ? 'off' : 'on' }.
                        </Message>
                    );
                }

                powerInput = (
                    <>
                        <label className={ commandLabelClass }>
                            Reefer Power
                        </label>

                        { message }

                        <Pill uncheckedGreyColor
                              checked={ this.state.power }
                              onChange={ this._onPowerChanged.bind(this) }
                              disabled={ !canPick }/>
                    </>
                );
                break;
            }
            case 'CONFIGURE_UNIT_MODE': {
                const canPick = this.props.currentMode == null || this.props.currentMode === 'NO DATA';

                let message = (<></>);
                if (!canPick) {
                    message = (
                        <Message type="info">
                            Unit Mode can only be set to { this.state.unitMode === 'CONTINUOUS' ? 'Continuous ' : 'Cycle-Sentry ' }
                            because the trailer is already in { this.props.currentMode } mode.
                        </Message>
                    );
                }

                if (this.props.currentPower === 'Off') {
                    preventSubmit = true;
                    message = (
                        <Message type="error">
                            Unit Mode can not be configured while the trailer is turned off.
                        </Message>
                    );
                }

                unitModeInput = (
                    <>
                        <label className={commandLabelClass}>
                            Unit Mode
                        </label>

                        { message }

                        <div className="unit-mode-picker">
                            <input id="c-unit-mode"
                                   type="radio"
                                   value="CONTINUOUS"
                                   name="unitMode"
                                   checked={ 'CONTINUOUS' === this.state.unitMode }
                                   onChange={ this._onUnitModeChanged.bind(this) }
                                   disabled={ !canPick } />
                            <label htmlFor="c-unit-mode">Continuous</label>

                            <input id="cs-unit-mode"
                                   type="radio"
                                   value="CYCLE_SENTRY"
                                   name="unitMode"
                                   checked={ 'CYCLE_SENTRY' === this.state.unitMode }
                                   onChange={ this._onUnitModeChanged.bind(this) }
                                   disabled={ !canPick } />
                            <label htmlFor="cs-unit-mode">Cycle Sentry</label>
                        </div>
                    </>
                );
                break;
            }
            case 'CONFIGURE_SET_POINT': {
                const isValid = this.state.setPointValid;

                let invalidMessage = (<></>);
                if (!isValid) {
                    invalidMessage = (
                        <Message type="error">
                            Set point is already configured at this value.
                        </Message>
                    );
                }

                if (this.props.currentPower === 'Off') {
                    preventSubmit = true;
                    invalidMessage = (
                        <Message type="error">
                            Set point can not be configured while the trailer is turned off.
                        </Message>
                    );
                }

                setPointInput = (
                    <>
                        <label className={ commandLabelClass }>
                            Set Point
                        </label>

                        { invalidMessage }

                        <label className={ inputLabelClass }>
                            Configure Set Point <span>(&deg;F)</span>
                        </label>

                        <input type="number"
                               className={ `input set-point-input ${ isValid ? '' : 'invalid' }` }
                               value={ this.state.setPoint }
                               onChange={ this._onSetPointChanged.bind(this) } />
                    </>
                );
                break;
            }
            default:
                // Nothing to do here.
        }

        let errorMessage = (<></>);
        if (this.state.error) {
            errorMessage = (
                <Message type="error">
                    Request Failed: { this.state.error }
                </Message>
            );
        }

        let infoMessage = (<></>);
        if (this.state.info || this.state.command === 'PING') {
            infoMessage = (
                <Message type="info">
                    { this.state.info || PING_INFO }
                </Message>
            );
        }

        let modalContent;
        if (this.props.isLoading) {
            modalContent = (
                <Loader type="TailSpin" color="#289AC2" height={ 47 } width={ 47 } visible className="loader-spinner" />  
            );
        } else {
            modalContent = (
                <>
                    { infoMessage }
                    { errorMessage }

                    <div className="section">
                        <label className={ inputLabelClass }>
                            Reefer Command
                        </label>

                        <Select id="command"
                                className="command-select"
                                options={ COMMAND_OPTIONS }
                                onChange={ this._onCommandChanged.bind(this) } />
                    </div>

                    <div className="section">
                        { powerInput }
                        { unitModeInput }
                        { setPointInput }
                    </div>

                    <div className="buttons">
                        <Button variant="light"
                                onClick={ this.onClose.bind(this) }
                                className="cancel-button">
                            Cancel
                        </Button>

                        <Button variant="continue"
                                className="submit-button"
                                disabled={ !this.state.command || this.state.info || preventSubmit || this.state.preventSubmit }
                                onClick={ this._onFormSubmit.bind(this) }>
                            Submit
                        </Button>
                    </div>
                </>
            );
        }
        return (
            <Modal show={ this.props.showModal }
                   onHide={ this.onClose.bind(this) }
                   backdrop="static"
                   keyboard={false}
                   centered
                   dialogClassName="reefer-remote-control-modal">
                    
                <SimpleContainer className="modal-container" modal title="Reefer Remote Control">
                    { modalContent }
                </SimpleContainer>
            </Modal>
        );
    }
}
