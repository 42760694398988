import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCheckCircle, faTruck, faUser } from '@fortawesome/free-solid-svg-icons';

import SimpleContainer from 'components/container/SimpleContainer';
import TrailerSettings from "components/trailer-settings/TrailerSettings";
import CanAccess from "components/can-access/CanAccess";
import { PERMISSION } from "global/utils/auth";

import "./DetailView.scss"

export default class LegCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legId: props.legInfo.legBusinessId,
      assignmentId: props.legInfo.assignmentId,
      trailerId: props.legInfo.trailerId,
      trailerBusinessId: props.legInfo.trailerBusinessId,
      resolvedIssues: props.legInfo.resolvedIssues,
      issues: props.legInfo.issues,
      legStatus: props.legInfo.legStatus,
      driver: props.legInfo.driver,
      startDate: props.legInfo.startDate,
      endDate: props.legInfo.endDate,
      location: props.legInfo.location,
      active: props.legInfo.active,
      relevant: props.isRelevant,
      trailerSettingsVisible: props.legInfo.active || props.isRelevant ? true : false
    }
    this.onExpandButtonClick = this.onExpandButtonClick.bind(this);
    this.onCollapseButtonClick = this.onCollapseButtonClick.bind(this);
  }

  componentDidUpdate(prevProps) {
      if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
          this.setState({
            legId: this.props.legInfo.legBusinessId,
            assignmentId: this.props.legInfo.assignmentId,
            trailerId: this.props.legInfo.trailerId,
            trailerBusinessId: this.props.legInfo.trailerBusinessId,
            resolvedIssues: this.props.legInfo.resolvedIssues,
            issues: this.props.legInfo.issues,
            legStatus: this.props.legInfo.legStatus,
            driver: this.props.legInfo.driver,
            startDate: this.props.legInfo.startDate,
            endDate: this.props.legInfo.endDate,
            location: this.props.legInfo.location,
            active: this.props.legInfo.active,
            relevant: this.props.isRelevant,
            trailerSettingsVisible: this.props.legInfo.active || this.props.isRelevant ? true : false
          });
      }
  }

  onExpandButtonClick() {
      this.setState({
          trailerSettingsVisible: true
      });
  }

  onCollapseButtonClick() {
    this.setState({
        trailerSettingsVisible: false
    }); 
  }

  render() {
    const legTitle = "LEG ID - #" + (this.state.legId || "Unknown");
    return (
    <div className="row">
      <div className="col-12 col-lg-12">
        <SimpleContainer title={legTitle} className="basic-info" notActive={!this.state.active && !this.state.relevant}>
            <div className="row trailer-info">
                <div className="col-9 col-lg-11 col-sm-11 col-xs-9">
                    <div className="row">
                <div>
                    <p className="info-label">
                        Resolved issues: 
                    </p>
                    <p className="gathered-info">
                        {this.state.resolvedIssues === this.state.issues && <span><FontAwesomeIcon icon={faCheckCircle} className="col-0 col-sm-0 issue-icon greenColorText"></FontAwesomeIcon> <span>&nbsp;</span></span>}
                        {this.state.resolvedIssues}/{this.state.issues}
                    </p>
                </div>
                <div>
                    <p className="info-label">
                        Trailer ID:
                    </p>
                    <CanAccess
                        action={ PERMISSION.TRAILER.READ }
                        yes={
                            <Link className="gathered-info link"
                                  to={ `/details/${ this.state.trailerId }/${ this.state.assignmentId }` }>
                                { this.state.trailerBusinessId }
                            </Link>
                        }
                        no={
                            <p className="gathered-info">
                                { this.state.trailerBusinessId }
                            </p>
                        }
                    />
                </div>

                <div>
                    <p className="info-label">
                        Driver:
                    </p>
                    <p className="gathered-info">
                        <FontAwesomeIcon icon={faUser} className="icon"></FontAwesomeIcon> {this.state.driver}
                    </p>
                </div>
                <div>
                    <p className="info-label">
                        Status:
                    </p>
                    <p className={`gathered-info ${this.state.legStatus === "Stopped" ? "orangeColorText" : "greenColorText"}`}>
                        {!this.state.active && !this.state.relevant && <span><FontAwesomeIcon icon={faCheckCircle} className="col-0 col-sm-0 issue-icon"></FontAwesomeIcon> <span>&nbsp;</span></span>}
                        {(this.state.active || this.state.relevant) && <span><FontAwesomeIcon icon={faTruck} className="col-0 col-sm-0 issue-icon"></FontAwesomeIcon> <span>&nbsp;</span></span>}
                        {this.state.legStatus}
                    </p>
                </div>
                <div>
                    <p className="info-label">
                        Start date:
                    </p>
                    <p className="gathered-info">
                        {this.state.startDate}
                    </p>
                </div>
                <div>
                    <p className="info-label">
                        End date:
                    </p>
                    <p className="gathered-info">
                        {this.state.endDate}
                    </p>
                </div>
                <div>
                    <p className="info-label">
                        Last known position:
                    </p>
                    <p className={this.state.location !== "Unknown" ? "gathered-info greenColorText" : "gathered-info redColorText"}>
                        {this.state.location}
                    </p>
                </div>
                </div>
                </div>
                <div className="col-3 col-lg-1 col-sm-1 col-xs-3">
                {(this.state.active || this.state.relevant) && <div className="expand-button">
                    {!this.state.trailerSettingsVisible && <FontAwesomeIcon icon={faAngleDown} className="icon arrow-icon" onClick={e => { this.onExpandButtonClick(e); }}></FontAwesomeIcon>}
                    {this.state.trailerSettingsVisible && <FontAwesomeIcon icon={faAngleUp} className="icon arrow-icon" onClick={e => { this.onCollapseButtonClick(e); }}></FontAwesomeIcon>}
                </div>}
                </div>
            </div>
            {this.state.trailerSettingsVisible && this.props.currentInfo && <div className="trailerSettingsDetails" >
                <div className="trailer-settings">
                    <TrailerSettings currentInfo={this.props.currentInfo}></TrailerSettings>
                </div>
            </div>}
        </SimpleContainer>
      </div>
    </div>
    )
  }
}
