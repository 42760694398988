import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import './Message.scss';

export default class Message extends Component {

    static defaultProps = {
        type: 'info' // Possible types: info, warning, error
    };

    render() {
        return (
            <div className={ 'message-card ' + this.props.type }>
                <FontAwesomeIcon icon={ faExclamationCircle } className="icon" />
                { this.props.children }
            </div>
        );
    }
}
