import React, { Component } from 'react';
import history from 'global/history';

/**
 * A Data Table Link Component
 *
 * Renders an `<a>` tag that can be used for navigation without reloading the page utilizing a custom history object.
 * This component should only be used when the standard `Link` component from
 * `react-router-dom` cannot be used because it is outside a `Router`.
 *
 * @param to The destination URL of the link or a location object containing pathname, search, hash and/or state
 * @param className Additional CSS classes to be applied to the link.
 */
export default class DataTableLink extends Component {

    static defaultProps = {
        className: '',
        styled: true
    };

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(e) {
        // Allows opening links in a new tab using the Windows Control key and Mac Command key
        if (e.ctrlKey || e.metaKey) {
            return;
        }

        e.preventDefault();
        history.push(this.props.to);
    }

    render() {
        let href;
        if (typeof this.props.to === 'string') {
            href = this.props.to;
        } else if (typeof this.props.to === 'object') {
            href = this.props.to.pathname;
        }

        return (
            <a className={ `${ this.props.styled ? 'link' : 'no-style-link' } ${ this.props.className }` }
               href={ href }
               onClick={ this.onClick }>
                { this.props.children }
            </a>
        );
    }
}
