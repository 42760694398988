import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faComment,
    faExclamationCircle,
    faEye,
    faInfoCircle,
    faQuestionCircle,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { faAlarmSnooze } from '@fortawesome/pro-solid-svg-icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { withRouter } from "react-router";
import Loader from 'react-loader-spinner';

import { reactPlugin } from 'AppInsights';
import AuthContext from "AuthContext";
import DataTableComp from 'components/datatable/DataTableComp';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import TeamIcon from "components/team-icon/TeamIcon";
import SimpleContainer from 'components/container/SimpleContainer';
import ActiveSelection from "components/input/ActiveSelection";
import CanAccess from "components/can-access/CanAccess";
import { defaultGridRecordsNumber, issuesDetailsUrl, mapSort, SortParam } from 'global/constants';
import { getCurrentDate as currentDate, getDifferenceBetweenDates } from 'global/services/DateTimeService';
import { mapIssuesFromResponse } from 'global/services/IssueApiService';
import QueryParamService from "global/services/QueryParamService";
import { issueSnoozeable } from "global/services/IssueTypeService";
import { PERMISSION } from 'global/utils/auth';
import deleteComment from 'global/utils/deleteComment';
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import './SilentUnitIssues.scss';

const $ = require("jquery");

const CACHE = {};

class SilentUnitIssues extends Component {
    static contextType = AuthContext;
    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            0: SortParam.ASSIGNMENT,
            1: SortParam.TRAILER,
            2: SortParam.DRIVER,
            5: SortParam.START_TIME,
            7: SortParam.ISSUE_STATE
        }

        this.columns = [{
            title: "Order #",
            data: "orderId"
        }, {
            title: "Trailer #",
            data: "trailerId"
        }, {
            title: "Driver",
            data: "driver"
        }, {
            title: "Status",
            data: "status"
        }, {
            title: "Last known position",
            data: "address"
        }, {
            title: '<span data-toggle="tooltip" title="Last time trailer reported via ThermoKing">Last event date</span>',
            data: "created"
        }, {
            title: "Alerts",
            data: "notifications"
        }, {
            title: "Resolved?",
            data: "resolved"
        }, {
            title: "Action",
            data: "action"
        }];

        this.sortingRule = [];
        this.data = Array.from([]);

        this.columnDefs = [
            { width: "2%", "targets": [6] },
            { width: "5%", "targets": [0, 1, 8] },
            { width: "10%", "targets": [2, 5, 7] },
            { width: "15%", "targets": [3, 4] },
            { className: "dt-align-center", targets: [8] },
            { className: "dt-align-left", targets: [1, 2, 3, 4, 5, 6, 7] },
            { className: "dt-align-right", targets: [0] },
            {
                searchable: false,
                targets: [0, 1, 2, 3, 4, 5, 6, 7, 8]
            },
            {
                orderable: false,
                targets: [3, 4, 6, 7, 8]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="centerAlign" style={{ marginRight: "2%" }}>
                            <TeamIcon color={rowData.division} />
                            &nbsp;&nbsp;
                            <span>{rowData.driver}</span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let mainText = "";
                    let otherText = "";
                    if (rowData.silentUnit) {
                        mainText = "Silent \xa0";
                        otherText = ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                        className += "bold orangeColorText";
                    } else if (rowData.status == null) {
                        mainText = "Unknown"
                        className += "bold redColorText"
                    } else if (rowData.status === true) {
                        mainText = "Moving"
                    } else {
                        mainText = "Stopped"
                        className += "bold orangeColorText"
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <div className={className}>
                                {mainText}
                            </div>
                            <span className={className}>
                                {otherText}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex" style={{ minWidth: '40px' }}>
                            {rowData.address}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";

                    if (!rowData.alerts) {
                        coloredText = "No";
                        className = "redColorText bold";
                        otherText = `  (for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`
                    } else if (rowData.alerts === "Escalation") {
                        coloredText = "Escalation"
                        className = "bold"
                    } else if (rowData.alerts === "Warning") {
                        coloredText = "Warning";
                        className = "bold"
                    }
                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }

                    ReactDOM.render(
                        <div className="notifications" style={{ minWidth: '80px' }}>
                            <div className={`notification-level ` + className}>
                                {coloredText}
                            </div>
                            {
                                <div className="notification-info">
                                    &nbsp; <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={coloredText === "No" ? { display: "none" } : {}} />
                                </div>
                            }
                            {
                                otherText !== "" &&
                                <div className="greyColorText italic snoozed-info">
                                    <br /> {otherText}
                                </div>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";
                    if (!rowData.resolved) {
                        className += "bold redColorText";
                        coloredText = "No";
                        otherText += ` (for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`;
                    } else if (rowData.resolved) {
                        className += "greenColorText bold";
                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.beginning, rowData.resolved)})`;
                        }
                    }
                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <div className={className}>
                                {rowData.resolved ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faExclamationCircle} />}
                                &nbsp;&nbsp;
                                {coloredText}&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.resolved ? { display: "none" } : { marginRight: 4 }} />
                            </div>
                            <span className="timestamp greyColorText italic">
                                {otherText}
                            </span>
                        </div>,
                        td
                    );
                },
            },
            {
                targets: 8,
                className: "justify",
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass = "";
                    let snoozeIconClass = "";
                    if (issueSnoozeable(rowData.issue) === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeable(rowData.issue) === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon"
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;

                    ReactDOM.render(
                        <div className="flex-always">
                            <div className="seen" id={rowData.id}>
                                <FontAwesomeIcon icon={faEye} className={seenIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={[PERMISSION.ISSUE.SNOOZE, PERMISSION.ISSUE.SILENT.SNOOZE]}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={snoozeDivClass}>
                                            {snoozeIconClass === "disabled-icon" ? <svg width="16" height="12" style={{marginTop: "2px"}} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                    <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z"/>
                                                    <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z"/>
                                                    <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z"/>
                                                    <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z"/>
                                                    <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z"/>
                                                    <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z"/>
                                                </svg>
                                                : <FontAwesomeIcon icon={faAlarmSnooze} className={snoozeIconClass}/>}
                                        </div>
                                    </>
                                }
                            />
                            &nbsp; &nbsp;
                            <div className="comment">
                                <FontAwesomeIcon icon={faComment} className={commentIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={[PERMISSION.ISSUE.CLOSE, PERMISSION.ISSUE.SILENT.CLOSE]}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={isRelevant ? "resolve" : "disabled-resolve"}>
                                            <FontAwesomeIcon icon={faTimesCircle} className={isRelevant ? "resolve-icon" : "disabled-icon"} />
                                        </div>
                                    </>
                                }
                            />
                            <CanAccess
                                permissions={ context.permissions }
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <ReeferControlSvg />
                                    </>
                                }
                            />
                        </div>,
                        td
                    );
                }
            }
        ];

        let relevantIssues = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : undefined;

        let buttonNode;
        if (!!relevantIssues || relevantIssues === undefined) {
            relevantIssues = true;
            buttonNode = $(`button[id=relevant]`);
        } else {
            relevantIssues = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addActiveLegToQueryString(window.location.search.slice(1), relevantIssues);

        let currentPage = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page') ? parseInt(QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page')) : 1;

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        this.state = {
            account: this.props.account,
            data: this.data,
            columns: this.columns,
            columnDefs: this.columnDefs,
            sortingRule: this.sortingRule,
            issuesLoaded: false,
            dataUpdated: false,
            page: currentPage,
            sort: [],
            activeLeg: relevantIssues,
            showModal: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).silentUnits !== undefined ? JSON.parse(localStorage.getItem('showModals')).silentUnits : true,
            doNotShowTutorial: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).silentUnits !== undefined ? !JSON.parse(localStorage.getItem('showModals')).silentUnits : false,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            }
        }
        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
    }

    async componentDidMount() {
        await this.fetchAllIssues();
    }

    async componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            this.handlePage(1, true);
        }
    }

    async handlePage(page, force) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchAllIssues(force);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value),
            },
            page: 1,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;

        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchAllIssues();
            }, 1000);
        }

        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    onReceiveNewComment(comment) {
        let newData = this.state.data;
        newData.find((data) => data.issue.id === comment.issue.id).comments.unshift(comment);

        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        let newData = this.state.data;

        this.setState({
            allIssues: deleteComment(deletedComment, newData)
        });
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }

        let queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray
        }, async () => {
            await this.fetchAllIssues();
        }
        );
    }

    async fetchAllIssues(force) {
        let cachingIndex = this.context.team.slice().join('_');
        cachingIndex += this.state.activeLeg ? '_relevant' : '_irrelevant';

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        let sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
        let tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }

            const response = await this.context.get(issuesDetailsUrl, {
                active: this.state.activeLeg,
                sort: sort,
                types: ["SILENT"],
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                console.error(response.message);
                return {};
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    issuesLoaded: true,
                    dataUpdated: true
                });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const issues = mapIssuesFromResponse(data);
            const newData = mapGridResponseData(issues, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                data: Array.from([]),
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    onCheckboxChange() {
        this.setState({
            doNotShowTutorial: !this.state.doNotShowTutorial
        });
    }

    openTutorialModal() {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        const existingItem = JSON.parse(localStorage.getItem('showModals'));
        localStorage.setItem('showModals', JSON.stringify({ ...existingItem, ...{ silentUnits: !this.state.doNotShowTutorial } }));
        this.setState({
            showModal: false
        });
    }

    changeActiveness = (relevantIssues) => {
        let queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevantIssues);

        this.setState({
            activeLeg: relevantIssues,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber,
            }
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    render() {
        return (
            <div className="container-fluid page silent-units">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <CanAccess
                                    action={PERMISSION.PAGE.ALL_ISSUES}
                                    yes={<li className="breadcrumb-item"><a href="/issues/all">All issues</a></li>}
                                    no={<li className="breadcrumb-item"><a href="/">Fleet Overview</a></li>}
                                />
                                <li className="breadcrumb-item active"><a href="#!">Silent units</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading">
                                Silent units (2hrs)
                            </p>
                            <div className="tutorial-info">
                                <p className="tutorial-text"> <FontAwesomeIcon className="tutorial-icon" icon={faQuestionCircle} onClick={this.openTutorialModal.bind(this)}></FontAwesomeIcon></p>
                            </div>
                            &nbsp; &nbsp; <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.issuesLoaded || !this.state.dataUpdated} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="active-toggle">
                            <ActiveSelection activeCallback={this.changeActiveness.bind(this)} initiallyActive={this.state.activeLeg} />
                        </div>
                    </div>
                </div>
                {(!this.state.doNotShowTutorial || this.state.showModal) &&
                    <Modal
                        show={this.state.showModal}
                        onHide={this.handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                        keyboard={false}
                        className="tutorial-modal-silent"
                    >
                        <SimpleContainer className="tutorial-modal-silent" title="SILENT UNITS TUTORIAL" modal={true}>
                            <Modal.Body className="tutorial-modal-silent">
                                <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                    <tbody>
                                        <tr>
                                            <td className="highlightModalText" style={{ marginLeft: "20%" }} colSpan="5">
                                                <span className="bold">DO NOT</span> drop a trailer with these issues at a customer facility for reloading before it is repaired.
                                            </td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td className="align-top">
                                                <svg width="42px" height="32px" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M40 2.66667H36V1.33333C36 0.596667 35.4033 0 34.6667 0H26.6667C25.93 0 25.3333 0.596667 25.3333 1.33333V2.665L17.3333 2.66667V1.33333C17.3333 0.596667 16.7367 0 16 0H8C7.26333 0 6.66667 0.596667 6.66667 1.33333V2.66667H2.66667C1.19417 2.66667 0 3.86083 0 5.33333V29.3333C0 30.8058 1.19417 32 2.66667 32H40C41.4725 32 42.6667 30.8058 42.6667 29.3333V5.33333C42.6667 3.86083 41.4725 2.66667 40 2.66667ZM38.6667 28H4V6.66667H38.6667V28ZM16.6667 14H7.33333C6.965 14 6.66667 14.2983 6.66667 14.6667V17.3333C6.66667 17.7017 6.965 18 7.33333 18H16.6667C17.035 18 17.3333 17.7017 17.3333 17.3333V14.6667C17.3333 14.2983 17.035 14 16.6667 14ZM35.3333 14H32.6667V11.3333C32.6667 10.965 32.3683 10.6667 32 10.6667H29.3333C28.965 10.6667 28.6667 10.965 28.6667 11.3333V14H26C25.6317 14 25.3333 14.2983 25.3333 14.6667V17.3333C25.3333 17.7017 25.6317 18 26 18H28.6667V20.6667C28.6667 21.035 28.965 21.3333 29.3333 21.3333H32C32.3683 21.3333 32.6667 21.035 32.6667 20.6667V18H35.3333C35.7017 18 36 17.7017 36 17.3333V14.6667C36 14.2983 35.7017 14 35.3333 14Z" fill="#828A95" />
                                                </svg>
                                            </td>
                                            <td style={{ paddingLeft: 10 }} colSpan="3">
                                                <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                    STEP 1:
                                                </p>
                                                <p className="modalText">
                                                    Check if trailer reported on TK Dashboard
                                                </p>
                                            </td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td className="modalText modalStepText" style={{ marginLeft: "20%" }} colSpan="4">
                                                CALL DRIVER TO VERIFY CURRENT REEFER SETTINGS
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="vllContainer">
                                                <div className="vll"></div>
                                            </td>
                                            <td className="align-top">
                                                <svg style={{ marginLeft: -25 }} width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M25.6641 3.65625C24.8906 3.16406 23.8359 3.375 23.3438 4.14844C22.9219 4.99219 23.1328 6.04688 23.9062 6.46875C27.9844 9 30.375 13.2891 30.375 18C30.375 25.4531 24.3281 31.5 16.875 31.5C9.42188 31.5 3.375 25.4531 3.375 18C3.375 13.2188 5.83594 8.92969 9.84375 6.46875C10.6875 5.97656 10.8984 4.92188 10.4766 4.14844C9.98438 3.375 8.92969 3.09375 8.15625 3.58594C3.02344 6.67969 0 12.0938 0 18C0 27.2812 7.59375 34.875 16.875 34.875C26.1562 34.875 33.75 27.2812 33.75 18C33.75 12.0938 30.7266 6.75 25.6641 3.65625ZM16.875 20.25C17.7891 20.25 18.5625 19.4766 18.5625 18.5625V1.6875C18.5625 0.773438 17.7891 0 16.875 0C15.9609 0 15.1875 0.773438 15.1875 1.6875V18.5625C15.1875 19.4766 15.9609 20.25 16.875 20.25Z" fill="#828A95" />
                                                </svg>
                                            </td>
                                            <td style={{ paddingLeft: 5 }} colSpan="2">
                                                <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                    STEP 2:
                                                </p>
                                                <p className="modalText">
                                                    If reefer is <span className="bold">ON</span> but not reporting via TK, instruct Driver to call Uptime Elite and go to the nearest ThermoKing for repair
                                                </p>
                                            </td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td style={{ paddingTop: 8 }} className="vllContainer">
                                                <div className="vll"></div>
                                            </td>
                                            <td className="align-top">
                                                <svg style={{ marginLeft: -25 }} width="41" height="23" viewBox="0 0 41 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M38.25 6.75V4.5C38.25 2.03906 36.2109 0 33.75 0H4.5C2.03906 0 0 2.03906 0 4.5V18C0 20.4609 2.03906 22.5 4.5 22.5H33.75C36.2109 22.5 38.25 20.4609 38.25 18V15.75C39.5156 15.75 40.5 14.7656 40.5 13.5V9C40.5 7.73438 39.5156 6.75 38.25 6.75ZM34.875 18C34.875 18.6328 34.3828 19.125 33.75 19.125H4.5C3.86719 19.125 3.375 18.6328 3.375 18V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H33.75C34.3828 3.375 34.875 3.86719 34.875 4.5V18Z" fill="#828A95" />
                                                </svg>
                                            </td>
                                            <td style={{ paddingLeft: 5 }} colSpan="2">
                                                <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                    STEP 3:
                                                </p>
                                                <p className="modalText">
                                                    If reefer battery is <span className="bold">DEAD</span>, instruct Driver to call Uptime Elite to jump or replace the battery and go to the nearest ThermoKing for assistance
                                                </p>
                                            </td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td className="align-top">
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M28 0H4C1.79375 0 0 1.79375 0 4V22C0 24.2062 1.79375 26 4 26H10V31.25C10 31.6938 10.3625 32 10.75 32C10.9 32 11.0563 31.9563 11.1938 31.85L19 26H28C30.2062 26 32 24.2062 32 22V4C32 1.79375 30.2062 0 28 0ZM29 22C29 22.55 28.55 23 28 23H18L17.2 23.6L13 26.75V23H4C3.45 23 3 22.55 3 22V4C3 3.45 3.45 3 4 3H28C28.55 3 29 3.45 29 4V22ZM23 8.5H9C8.45 8.5 8 8.95 8 9.5V10.5C8 11.05 8.45 11.5 9 11.5H23C23.55 11.5 24 11.05 24 10.5V9.5C24 8.95 23.55 8.5 23 8.5ZM17 14.5H9C8.45 14.5 8 14.95 8 15.5V16.5C8 17.05 8.45 17.5 9 17.5H17C17.55 17.5 18 17.05 18 16.5V15.5C18 14.95 17.55 14.5 17 14.5Z" fill="#828A95" />
                                                </svg>
                                            </td>
                                            <td style={{ paddingLeft: 10 }} colSpan="3">
                                                <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                    STEP 4:
                                                </p>
                                                <p className="modalText">
                                                    Leave notes on the load in TMW Trip Folder.
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Button variant="continue" onClick={this.handleClose.bind(this)} style={{ width: "240px", height: "36px", marginTop: "15px" }}>
                                    Got it!
                                </Button>
                                <div className="form-check">
                                    <label className={`checkBoxText ${this.state.doNotShowTutorial ? "bold" : ""}`}>
                                        <input
                                            type="checkbox"
                                            name={this.state.label}
                                            checked={this.state.doNotShowTutorial}
                                            onChange={this.onCheckboxChange.bind(this)}
                                            className="form-check-input"
                                        />
                                        Do not show this tip again
                                    </label>
                                </div>
                            </Modal.Body>
                        </SimpleContainer>
                    </Modal>}
                <div className="row">
                    <div className="col">
                        <div className="silent-units-div">
                            {this.state.issuesLoaded && <DataTableComp
                                tableId="silentUnitIssues"
                                columns={this.state.columns}
                                orderRule={this.state.sortingRule}
                                data={this.state.data}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                isDetailView={false}
                                columnDefs={this.state.columnDefs}
                                customTableClass="cell-border"
                                tableHeight="1200px"
                                handlePreviousPage={this.handlePreviousPage}
                                handleNextPage={this.handleNextPage}
                                handleFirstPage={this.handleFirstPage}
                                handleLastPage={this.handleLastPage}
                                handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                                handlePageChange={(event) => this.handlePageChange(event)}
                                pagingInfo={this.state.pagingInfo}
                                account={this.state.account}
                                onClickLocation={{ pathname: "/details", state: { trailerId: this.state.data.onClickState } }}
                                sortRule={this.state.sort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, SilentUnitIssues, "SilentUnitIssues"));
