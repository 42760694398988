import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

import SimpleContainer from 'components/container/SimpleContainer';
import StackedGroupedBar from '../StackedGroupedBar'
import TeamCard from './TeamCard'
import StatsFilter from "../StatsFilter"
import StatsHeader from '../stats-header/StatsHeader';
import { getCurrentDate, getMonthsBefore, getFullDifferenceBetweenDates } from 'global/services/DateTimeService';
import QueryParamService from 'global/services/QueryParamService';
import { TEAMS, issuesByStateUrl, issueEscalationsUrl, divisionIssueInfoUrl, statisticsIssuesDetailsUrl } from 'global/constants';
import AuthContext from 'AuthContext'

import "../Statistics.scss"

const dataKeys = [
  { name: "automaticallyResolved", label: "Automatically resolved issues", stackId: "issues", color: "#172437" },
  { name: "manuallyResolved", label: "Manually resolved issues",  stackId: "issues", color: "#7B838E" },
  { name: "unresolved", label: "Unresolved issues",  stackId: "issues", color: "#BDC1C7" },
  { name: "orders", label: "Orders",  stackId: "orders", color: "#D0D2DD" },
]

const avgIssueResolutionKeys = [
  { name: "avgResolutionTime", label: "average issue resolution time", stackId: "avgResolution", color: "#172437" },
]

const numOfEscalationKeys = [
  { name: "numEscalation", label: "Escalations", stackId: "numEscalation", color: "#172437" },
]
const formatter = (value) => `${value} min`;

const data = TEAMS.map(team => ({division: team.name, label: team.name}));
const teamData = TEAMS.map(team => ({division: team.name, label: team.name}));

class AllTeamsStats extends Component {
    static contextType = AuthContext;

    constructor(props) {
      super(props);

      const queryString = window.location.search.slice(1);
      this.state = {
        filter: {
          startTime: queryString.includes('from') ? getCurrentDate(new Date(QueryParamService.parseSimpleValueFromQueryString(queryString, 'from'))) : getMonthsBefore(new Date(), 1),
          endTime: queryString.includes('to') ? getCurrentDate(new Date(QueryParamService.parseSimpleValueFromQueryString(queryString, 'to'))) : getCurrentDate(new Date()),
          teams: queryString.includes('team') ? Array.of(QueryParamService.parseSimpleValueFromQueryString(queryString, 'team')) : [],
          issueTypes: queryString.includes('issueType') ? Array.of(QueryParamService.parseSimpleValueFromQueryString(queryString, 'issueType')) : []
      },
        data: data,
        teamData: teamData,
        statisticsLoaded: false
      }
    }

    async fetchStatistics() {
      this.setState({
        statisticsLoaded: false
      });

      await Promise.all([
        this.fetchIssuesByState(),
        this.fetchDivisionIssueInfo(),
        this.fetchEscalations(),
        this.fetchIssuesInfo()
      ]);

      this.setState({
        statisticsLoaded: true
      });
    }

    async fetchIssuesByState() {
      const response = await this.context.get(issuesByStateUrl, {
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: this.state.filter.teams
      });
      if (response.status === "error") {
        console.error(response.message);
        return {};
      }

      const data = this.state.data.map(team => ({
        ...team,
        label: team.division,
        automaticallyResolved: this.state.filter.teams.indexOf(team.division) !== -1 || this.state.filter.teams.length === 0 ? (response.find(t => t.division === team.division) || {}).automaticallyResolved || 0 : team.automaticallyResolved || 0,
        manuallyResolved: this.state.filter.teams.indexOf(team.division) !== -1 || this.state.filter.teams.length === 0 ? (response.find(t => t.division === team.division) || {}).manuallyResolved || 0 : team.manuallyResolved || 0,
        unresolved: this.state.filter.teams.indexOf(team.division) !== -1 || this.state.filter.teams.length === 0 ? (response.find(t => t.division === team.division) || {}).unresolved || 0 : team.unresolved || 0,
        orders: this.state.filter.teams.indexOf(team.division) !== -1 || this.state.filter.teams.length === 0 ? (response.find(t => t.division === team.division) || {}).orders || 0 : team.orders || 0,
      }));

      this.setState({
        data,
        orderCount: data.reduce((t1, t2) => t1 + t2.orders, 0)
      });
    }

    async fetchEscalations() {

      const response = await this.context.get(issueEscalationsUrl, {
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: this.state.filter.teams
      });
      if (response.status === "error") {
        console.error(response.message);
        return {};
      }
      this.setState({
        teamData: this.state.teamData.map(team => ({
          ...team,
          label: team.division,
          numEscalation: this.state.filter.teams.indexOf(team.division) !== -1 || this.state.filter.teams.length === 0 ? (response.find(t => t.division === team.division) || {}).count || 0 : team.numEscalation || 0
        }))
      })
    }

    async fetchDivisionIssueInfo() {
      const response = await this.context.get(divisionIssueInfoUrl, {
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: this.state.filter.teams
      });
      if (response.status === "error") {
        console.error(response.message);
        return {};
      }
      this.setState({
        teamData: this.state.teamData.map(team => ({
          ...team,
          issueCount: this.state.filter.teams.indexOf(team.division) !== -1 || this.state.filter.teams.length === 0 ? (response.find(t => t.division === team.division) || {}).issueCount || 0 : team.issueCount || 0,
          avgResolutionTime: this.state.filter.teams.indexOf(team.division) !== -1 || this.state.filter.teams.length === 0  ? ((response.find(t => t.division === team.division) || {}).avgResolutionTime || 0).toFixed(1) : team.avgResolutionTime || 0,
          resolvedIssueCount: this.state.filter.teams.indexOf(team.division) !== -1  || this.state.filter.teams.length === 0 ? (response.find(t => t.division === team.division) || {}).resolvedIssueCount || 0 : team.resolvedIssueCount || 0
        }))
    });
  }

    async fetchIssuesInfo() {
      const response = await this.context.get(statisticsIssuesDetailsUrl, {
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: this.state.filter.teams
      });

      if (response.status === 'error') {
        console.error(response.message);
        return {};
      }

      this.setState({
        createdIssuesCount: response.issuesCount,
        snoozedIssuesCount: response.snoozedIssuesCount,
        avgResolutionTime: response.avgResolutionTime,
        closedIssuesCount: response.closedIssuesCount
      });
    }

    async componentDidMount() {
      await this.fetchStatistics();
    }


    onFilterChange(selectedTeams, selectedTypes, selectedStartTime, selectedEndTime) {
      let newTeams = [];
      let newData = [];
      let newTeamData = [];
      if (this.state.filter.teams !== selectedTeams) {
        if(selectedTeams.indexOf("ALL") !== -1 || !selectedTeams || selectedTeams.length === 0) {
          newTeams = [];
          newData = this.state.data.map(team => ({...team, disabled: false}));
          newTeamData = this.state.teamData.map(team => ({...team, disabled: false}));
        } else {
          newTeams = selectedTeams;
          newData = this.state.data.map(team => newTeams.indexOf(team.division) === -1 ? {...team, disabled: true} : {...team, disabled: false});
          newTeamData = this.state.teamData.map(team => newTeams.indexOf(team.division) === -1 ? {...team, disabled: true} : {...team, disabled: false});
        }
      }

      if(selectedTypes.indexOf("ALL") !== -1) {
        selectedTypes = [];
      }

      this.setState({
        data: newData,
        teamData: newTeamData,
        filter : {
          ...this.state.filter,
          teams: newTeams,
          startTime: selectedStartTime,
          endTime: selectedEndTime,
          issueTypes: selectedTypes
        }
      }, async () => {
        await this.fetchStatistics();
        QueryParamService.addParamsToUrl({ from: this.state.filter.startTime, to: this.state.filter.endTime });
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), this.state.filter.teams);
        QueryParamService.addIssueTypesToQueryString(window.location.search.slice(1), this.state.filter.issueTypes);
      })
    }

    render(){
      return (
        <div className="container-fluid page statistics">
            <div className="row page-heading-row">
              <nav className="col-12">
                  <ol className="breadcrumb">
                     <li className="breadcrumb-item active"><a href="#!">Shipex stats</a></li>
                  </ol>
              </nav>
              <div className="pageHeading col">
                 <span className="margin-right">
                   Teams Stats
                 </span>
                 <Loader
                    type="TailSpin"
                    color="#289AC2"
                    height={ 47 }
                    width={ 47 }
                    visible={ !this.state.statisticsLoaded }
                 />
              </div>
                <StatsFilter filter={this.state.filter} onFilterChange={this.onFilterChange.bind(this)}></StatsFilter>
              </div>
              <div className="row">
                <div className="col">
                  <StatsHeader 
                    createdIssues={ this.state.createdIssuesCount }
                    snoozedIssues={ this.state.snoozedIssuesCount }
                    closedIssues={ this.state.closedIssuesCount }
                    avgResolutionTime= { this.state.avgResolutionTime }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <SimpleContainer className="issues-orders-stats" title="ISSUES AND ORDERS">
                    { !!this.state.orderCount &&
                      <div className="order-count">
                        <div className="orders">
                          ORDERS:
                        </div>
                        { this.state.orderCount  }
                      </div>
                    }
                    <StackedGroupedBar barSize={35}  data={this.state.data} dataKeys={dataKeys} timeFrame={getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime)}></StackedGroupedBar>
                  </SimpleContainer>
                </div>
              </div>

            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <SimpleContainer className="issues-orders-stats" title="AVERAGE ISSUE RESOLUTION TIME">
                    <StackedGroupedBar minuteBar={true} yTickFormatter={formatter} simpleTeamXAxis={true} barSize={10} data={this.state.teamData} dataKeys={avgIssueResolutionKeys} timeFrame={getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime)}></StackedGroupedBar>
                  </SimpleContainer>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <SimpleContainer className="issues-orders-stats" title="NUMBER OF ESCALATIONS">
                    <StackedGroupedBar simpleTeamXAxis={true} barSize={10}  data={this.state.teamData} dataKeys={numOfEscalationKeys} timeFrame={getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime)}></StackedGroupedBar>
                  </SimpleContainer>
                </div>
            </div>

            <div className="row equal">
               {this.state.teamData.map(team => (
                  <div key={team.division} className="col-lg-4 col-md-6 col-sm-6 col-xs-6">
                    <TeamCard team={team.division} issueCount={team.issueCount} resolvedIssueCount={team.resolvedIssueCount} avgResolutionTime={team.avgResolutionTime}></TeamCard>
                  </div>
               ) )}
            </div>
          </div>
        );
    }
}

export default AllTeamsStats;
