import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import DataTableComp from 'components/datatable/DataTableComp';
import CanAccess from 'components/can-access/CanAccess';
import SimpleContainer from 'components/container/SimpleContainer';
import { mapSort, notificationDetailsUrl, notificationReasonMap, SortParam } from 'global/constants';
import { formatShortDate, getDifferenceBetweenDates } from "global/services/DateTimeService";
import { issueTypeMap } from "global/services/IssueTypeService";
import { PERMISSION } from "global/utils/auth";

import './NotificationHistoryPopup.scss';

import AuthContext from "AuthContext";

const CACHE = {};

export default class NotificationHistoryPopup extends Component {
  static contextType = AuthContext;
    constructor(props) {
      super();

      this.columnNames = {
        1: SortParam.TIME
      }

      const columns = [
        { title: "Notification type", data: "type" }, // 0
        { title: "Time sent", data: "time" }, // 1
        { title: "Sent to:", data: "recipients" }, // 2
        { title: "Snoozed by:", data: "snoozedBy" }, // 3
        { title: "Snoozed at:", data: "snoozedAt" }, // 4
        { title: "Reason:", data: "snoozeReason"}, // 5
      ];

      const columnDefs = [

          { className: "dt-align-center", targets: [] },
          { className: "dt-align-left", targets: [0, 2, 3, 5] },
          { className: "dt-align-right", targets: [1, 4] },
          { width: "20%", "targets": [ 4, 5]},
          { width: "30%", "targets": [2] },
          { width: "10%", "targets": [0, 1, 3] },
          {
              orderable: false,
              targets: [0, 2, 3, 4, 5]
          },
          {
            targets: 0,
            createdCell: (td, cellData, rowData, row, col) => {
                ReactDOM.render(
                    <div>
                        <span className="">
                            {rowData.type}
                        </span>
                    </div>,
                    td
                );
            }
          },
          {
              targets: 1,
              createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                      <div className="" style={{minWidth: '40px'}}>
                          {rowData.time}
                      </div>,
                      td
                  );
              }
          },
          {
              targets: 2,
              createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                      <div className="flex" >
                          {rowData.recipients}
                      </div>,
                      td
                  );
              }
          },
          {
              targets: 3,
              createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                      <div className="flex">
                          {rowData.snoozedBy} <span className="greyColorText">{rowData.snoozedFor && "(for " + rowData.snoozedFor + ")"} </span>
                      </div>,
                      td
                  );
              }
          },
          {
              targets: 4,
              createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                      <div>
                          {rowData.snoozedAt}
                      </div>,
                      td
                  );
              }
          },
          {
            targets: 5,
            createdCell: (td, cellData, rowData, row, col) => {
                ReactDOM.render(
                    <div className="flex reason">
                        {rowData.snoozeReason}
                        {rowData.snoozeNote && <FontAwesomeIcon icon={faInfoCircle} className="info-icon"/>}
                    </div>,
                    td
                );
            }
        }
      ];

      this.state = {
          showNotificationHistoryModal: props.showNotificationHistoryModal,
          issue: props.issue,
          account: props.account,
          columns: columns,
          columnDefs: columnDefs,
          sort: [{
            columnIndex: 1,
            columnName: this.columnNames[1],
            direction: 'DESC'
        }],
          sortingRule: [],
          page: 1,
          pagingInfo: {
              recordsNumber: 10 //default value
          }
      };
    }

    handleClose = () => {
        this.setState({
          showNotificationHistoryModal: false
        }, () => {
            this.props.modalClosed();
        });
    }

    async mapNotifications(data) {
      const notifications = [];
      await Promise.all(data.map(async (obj, index) => {
        notifications.push({
          time: formatShortDate(new Date(obj.time)),
          type: notificationReasonMap(obj.reason),
          recipients: [...new Set(obj.contactInfos.map(c => c.email))].join(', '),
          snoozedFor: obj.snoozedIssue ? (getDifferenceBetweenDates(new Date(obj.snoozedIssue.snoozeEnd), new Date(obj.snoozedIssue.snoozeStart))) : "",
          snoozedBy: obj.snoozedIssue ? obj.snoozedIssue.snoozedBy || "" : "",
          snoozedAt: obj.snoozedIssue && obj.snoozedIssue.snoozeStart ? formatShortDate(new Date(obj.snoozedIssue.snoozeStart)) : "",
          snoozeReason: obj.snoozedIssue ? obj.snoozedIssue.snoozeReason.reason || "" : "",
          snoozeNote: obj.snoozedIssue && obj.snoozedIssue.snoozeReason.extended ? obj.snoozedIssue.note : "",
        });
      }));
      return notifications;
    }


    async componentDidMount() {
        await this.fetchNotifications();
    }

    mapResponseData(notifications, response) {
        return {
            notifications: Array.from(notifications),
            pagingInfo: {
                itemNumber: Math.min(response.pageSize, response.data.length),
                totalItemNumber: response.available,
                totalPageNumber: Math.ceil(response.available / response.pageSize),
                currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
                recordsNumber: response.pageSize
            }
        }
    }

    async fetchNotifications() {
        let cachingIndex = this.context.team.slice().join('_');

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                notifications: CACHE[cachingIndex].notifications,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        }
        try {
            const response = await this.context.get(notificationDetailsUrl, {
                issueId: this.state.issue.id,
                sort: mapSort(this.state.sort),
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });
            if (response.status === "error") {
                console.error(response.message);
                return {};
            }

            const notifications = await this.mapNotifications(response.data);

            if (!notifications) {
                return;
            }

            const newData = this.mapResponseData(notifications, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    notifications: newData.notifications,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                notifications: newData.notifications,
                notificationsLoaded: true,
                pagingInfo: newData.pagingInfo
            });
        } catch (error) {
            console.error(error);
            this.setState({
            notifications: Array.from([]),
            columns: this.columns,
            columnDefs: this.columnDefs,
            sortingRule: this.sortingRule,
            notificationsLoaded: true
            });
        }
    }

    handleFirstPage = async () => {
        this.setState({
            page: 1
        }, async () => {
            await this.fetchNotifications();
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleLastPage = async () => {
        this.setState({
            page: parseInt(this.state.pagingInfo.totalPageNumber)
        }, async () => {
            await this.fetchNotifications();
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleNextPage = async () => {
        this.setState({
            page: parseInt(this.state.page) + 1
        }, async () => {
            await this.fetchNotifications();
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handlePreviousPage = async () => {
        this.setState({
            page: parseInt(this.state.page) - 1
        }, async () => {
            await this.fetchNotifications();
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleRecordsNumber = async (event) => {
        let recordsNumber = parseInt(event.target.value);
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: recordsNumber
            },
            page: 1
        }, async () => {
            await this.fetchNotifications();
        });
    }

    handlePageChange = async (event) => {
        if (isNaN(event.target.value) || event.target.value === "") {
            this.state.page = "";
        } else {
            this.state.page = parseInt(event.target.value);
            const that = this;
            setTimeout(async function () {
                await that.fetchNotifications();
            }, 1000);
        }
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);
        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }
        this.setState({
            sort: sortingArray
        }, async () => {
            await this.fetchNotifications();
        });
    }


    render() {
        let assignmentText;
        if (this.state.issue.assignment) {
            assignmentText = (
                <Link className="gathered-info link"
                      to={ `/assignment-details/${ this.state.issue.assignment.id }` }>
                    { this.state.issue.assignment.businessId }
                </Link>
            );
        } else {
            assignmentText = (
                <p className="gathered-info">
                    No order
                </p>
            );
        }

        let trailerDetailsLink = `/details/${ this.state.issue.trailer.id }`;
        if (this.state.issue.assignment) {
            trailerDetailsLink += `/${ this.state.issue.assignment.id }`;
        }

        return(
            <Modal
                show={this.state.showNotificationHistoryModal}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="notificationHistoryModal"
            >
                <SimpleContainer className="tutorial-modal-mode" title="NOTIFICATION HISTORY">
                <Modal.Body>
                    <div className="close-icon" onClick={this.handleClose.bind(this)}>
                        <FontAwesomeIcon icon={['far', 'times']} className="icon"></FontAwesomeIcon>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12 issue-info">
                            <p className="info-label">
                                Order:
                            </p>
                            <CanAccess
                                action={ PERMISSION.ASSIGNMENT.READ }
                                yes={ assignmentText }
                                no={
                                    <p className="gathered-info">
                                        { this.state.issue.assignment ? this.state.issue.assignment.businessId : "No order" }
                                    </p>
                                }
                            />
                            <p className="info-label">
                                Trailer:
                            </p>
                            <CanAccess
                                action={ PERMISSION.TRAILER.READ }
                                yes={
                                    <Link className="gathered-info link" to={ trailerDetailsLink }>
                                        { this.state.issue.trailer ? this.state.issue.trailer.businessId : 'Unknown' }
                                    </Link>
                                }
                                no={
                                    <p className="gathered-info">
                                        { this.state.issue.trailer ? this.state.issue.trailer.businessId : 'Unknown' }
                                    </p>
                                }
                            />
                            <p className="info-label">
                                Driver:
                            </p>
                            <p className="gathered-info">
                                { this.state.issue.leg ? this.state.issue.leg.driver.contactInfo.name : "Unknown" }
                            </p>
                            <p className="info-label">
                                Issue type:
                            </p>
                            <p className="gathered-info">
                                { issueTypeMap(this.state.issue.type) }
                            </p>
                        </div>
                    </div>
                    <hr style={{marginTop: "2px"}}></hr>
                    <div className="notifications-table-div">
                        {this.state.notificationsLoaded && <DataTableComp
                        tableId="notificationHistoryTable"
                        columns={this.state.columns}
                        columnDefs={this.state.columnDefs}
                        data={this.state.notifications}
                        tableHeight="500px"
                        account={this.state.account}
                        handlePreviousPage={this.handlePreviousPage}
                        handleNextPage={this.handleNextPage}
                        handleFirstPage={this.handleFirstPage}
                        handleLastPage={this.handleLastPage}
                        handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                        handlePageChange={(event) => this.handlePageChange(event)}
                        pagingInfo = {this.state.pagingInfo}
                        sortRule={this.state.sort.concat([])}
                        addSortingForColumn={this.addSortingForColumn.bind(this)}
                        />}
                    </div>
                </Modal.Body>
                </SimpleContainer>
            </Modal>
        );
    }
}
