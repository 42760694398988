import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import TeamIcon from 'components/team-icon/TeamIcon';
import { TEAMS } from 'global/constants';
import QueryParamService from "global/services/QueryParamService";

import './TeamSelection.scss';

const multipleTeamIcon = <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="10">
        <path d="M12.1875 6.875H11.875V4.76562C11.875 4.53125 11.7578 4.27734 11.582 4.10156L9.64844 2.16797C9.47266 1.99219 9.21875 1.875 8.98438 1.875H8.125V0.9375C8.125 0.429688 7.69531 0 7.1875 0H0.9375C0.410156 0 0 0.429688 0 0.9375V7.1875C0 7.71484 0.410156 8.125 0.9375 8.125H1.25C1.25 9.16016 2.08984 10 3.125 10C4.16016 10 5 9.16016 5 8.125H7.5C7.5 9.16016 8.33984 10 9.375 10C10.4102 10 11.25 9.16016 11.25 8.125H12.1875C12.3438 8.125 12.5 7.98828 12.5 7.8125V7.1875C12.5 7.03125 12.3438 6.875 12.1875 6.875ZM3.125 9.0625C2.59766 9.0625 2.1875 8.65234 2.1875 8.125C2.1875 7.61719 2.59766 7.1875 3.125 7.1875C3.63281 7.1875 4.0625 7.61719 4.0625 8.125C4.0625 8.65234 3.63281 9.0625 3.125 9.0625ZM9.375 9.0625C8.84766 9.0625 8.4375 8.65234 8.4375 8.125C8.4375 7.61719 8.84766 7.1875 9.375 7.1875C9.88281 7.1875 10.3125 7.61719 10.3125 8.125C10.3125 8.65234 9.88281 9.0625 9.375 9.0625ZM10.9375 5H8.125V2.8125H8.98438L10.9375 4.76562V5Z" fill="#289AC2" />
    </mask>
    <g mask="url(#mask0)">
        <rect x="11.3203" y="-7" width="5" height="20" transform="rotate(60 11.3203 -7)" fill="#D3424E" />
        <rect x="13.8203" y="-2.66992" width="5" height="20" transform="rotate(60 13.8203 -2.66992)" fill="#F27C46" />
        <rect x="16.3203" y="1.66016" width="5" height="20" transform="rotate(60 16.3203 1.66016)" fill="#289AC2" />
    </g>
</svg>;

const NON_TEAM_SELECTION_PAGES = ['release-notes', 'settings', 'statistics', 'details', 'assignment-details', 'trailer-map', 'events']

export default class TeamSelection extends Component {

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    state = {
        ...(localStorage.getItem('selectedTeams') ? JSON.parse(localStorage.getItem('selectedTeams')) : this.props.team || [])
            .map(team => ({ [team === '*' ? 'all' : team]: true }))
            .reduce((previous, value) => ({ ...previous, ...value }), []),
        showDropdown: false
    }

    static defaultProps = {
        value: [TEAMS[0].name, 'all'],
        onChange: (values) => { }
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClickOutside.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOutside.bind(this));
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ showDropdown: false });
        }
    }

    onChange(event) {
        const target = event.target;
        this.setState(
            { [target.name]: target.checked },
            () => this.handleStateChange(),

        );
    }

    toggleDropdown() {
        this.setState(state => ({ showDropdown: !state.showDropdown }));
    }

    handleStateChange() {
        const selectedTeams = this.getSelection();

        const all = selectedTeams.indexOf('all') !== -1;

        let result = all ? ['*'] : selectedTeams;

        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), result);

        localStorage.setItem('selectedTeams', JSON.stringify(result));
        this.props.setTeam(result);
    }

    isMultiSelection() {
        return this.getSelection().filter(k => k !== 'all').length > 1;
    }

    getSelection() {
        return Object.entries(this.state)
            .filter(([_, value]) => value)
            .map(([key, _]) => key)
            .filter(key => key !== 'showDropdown');
    }

    render() {
        const items = TEAMS.map(team => {
            return (
                <li className={`item ${this.state.all ? "disable-all" : ""}`} key={ team.name }>
                    <input
                        type="checkbox"
                        id={ team.name }
                        name={ team.name }
                        disabled={ this.state.all }
                        checked={ this.state[team.name] }
                        style={{ width: "12px", height: "12px" }}
                        onChange={ this.onChange } />
                    <label htmlFor={ team.name }>
                        <TeamIcon color={ team.name } size="12px" />
                        &nbsp;&nbsp;
                        { team.label }
                    </label>
                </li>
            )
        })
        
        const allTeamsItem = (
            <li className="item" key="all">
                <input
                    type="checkbox"
                    id={ "all" }
                    name={ "all" }
                    checked={ this.state.all }
                    style={{ width: "12px", height: "12px" }}
                    onChange={ this.onChange } />
                <label htmlFor="all">
                    <TeamIcon color="ALL" size="12px" />
                    &nbsp;&nbsp;
                    All teams
                </label>
            </li>
        );

        let label;
        if (this.state.all) {
            label = (
                <span className="multiple-teams">
                    <TeamIcon color="ALL" size="12px" />
                    <span>ALL TEAMS</span>
                    <FontAwesomeIcon icon={faChevronDown} />
                </span>
            );
        } else if (this.isMultiSelection()) {
            label = (
                <span className="multiple-teams">
                    {multipleTeamIcon}
                    <span>Multiple Teams</span>
                    <FontAwesomeIcon icon={faChevronDown} />
                </span>
            );
        } else {
            const selected = this.getSelection().filter(key => key !== 'all')[0];
            const team = TEAMS.find(team => team.name === selected);
            label = (
                <span className="multiple-teams">
                    {((team || {}).label &&
                        <>
                            <TeamIcon color={selected} size="12px" />
                            <span>{(team || {}).label}</span>
                        </>
                    ) || "Teams"
                    }
                    <FontAwesomeIcon icon={faChevronDown} />
                </span>
            );
        }

        const hideDropdown = NON_TEAM_SELECTION_PAGES.indexOf(window.location.pathname.split('/')[1]) !== -1;

        return (
            <div ref={  this.wrapperRef  } className={ "team-selection" + (hideDropdown ? " hidden" : "" ) }>
                <div className="info-label">Displaying issues for: </div>
                <div className="picker">
                    <div className="title" onClick={ this.toggleDropdown }>
                        { label }
                    </div>
                    <ul className={ "drop-menu " + (this.state.showDropdown ? 'shown' : 'hidden') }>
                        { allTeamsItem }
                        { items }
                    </ul>
                </div>
            </div>
        );
    }
}
