import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faComment,
    faExclamationCircle,
    faEye,
    faInfoCircle,
    faQuestionCircle,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { faAlarmSnooze } from "@fortawesome/pro-solid-svg-icons";

import { reactPlugin } from "AppInsights";
import QueryParamService from "global/services/QueryParamService";
import { issueSnoozeable } from "global/services/IssueTypeService";
import {
    formatShortDate,
    getCurrentDate as currentDate,
    getDifferenceBetweenDates
} from "global/services/DateTimeService";
import { mapIssuesFromResponse } from "global/services/IssueApiService";
import { PERMISSION } from "global/utils/auth";
import formSortObject from "global/utils/formSortObject";
import mapGridResponseData from "global/utils/mapGridResponseData";
import deleteComment from "global/utils/deleteComment";
import { defaultGridRecordsNumber, issuesDetailsUrl, mapSort, SortParam } from "global/constants";
import SimpleContainer from "components/container/SimpleContainer";
import DataTableComp from "components/datatable/DataTableComp";
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import TeamIcon from "components/team-icon/TeamIcon";
import ActiveSelection from "components/input/ActiveSelection";
import CanAccess from "components/can-access/CanAccess";
import AuthContext from "AuthContext";
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import "./DetachedMissingTrailers.scss";

const CACHE = {};

class DetachedMissingTrailers extends Component {
    static contextType = AuthContext;
    locationIssues = this.props.detached ? "detachedIssues" : "missingIssues"

    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            0: SortParam.TRAILER,
            2: SortParam.COMPANY,
            7: SortParam.START_TIME
        }

        this.columns = [
            { title: "Trailer #", data: "trailerId" },
            { title: "Last Order #", data: "orderId" },
            { title: "Customer ID", data: "customerId"},
            { title: "Driver", data: "driver" },
            { title: "Last known truck position", data: "truckPosition" },
            { title: "Last known trailer position", data: "trailerPosition" },
            { title: "Distance", data: "distance" },
            { title: "Created", data: "created" },
            { title: "Alerts", data: "alerts" },
            { title: "Resolved?", data: "resolved" },
            { title: "Action", data: "action" }
        ];

        this.columnDefs = [
            { width: "5%", "targets": [0, 1, 6, 10] },
            { width: "10%", "targets": [2, 3, 7, 8, 9] },
            { width: "15%", "targets": [4, 5] },
            { className: "dt-align-center", targets: [10] },
            { className: "dt-align-left", targets: [3, 4, 5, 7, 8, 9] },
            { className: "dt-align-right", targets: [0, 1, 6] },
            {
                searchable: false,
                targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            },
            {
                orderable: false,
                targets: [1, 3, 4, 5, 6, 8, 9, 10]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="centerAlign">
                            <TeamIcon color={rowData.division} />
                            <span style={{ marginLeft: 6 }}>
                                {rowData.driver}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="no-flex">
                            {rowData.metaData.truckPosition}
                            <br />
                            <span className="greyColorText">
                                ({formatShortDate(new Date(rowData.metaData.truckUpdated))})
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="no-flex">
                            {rowData.metaData.trailerPosition}
                            <br />
                            <span className="greyColorText">
                                ({formatShortDate(new Date(rowData.metaData.trailerUpdated))})
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            {rowData.metaData.distance + " mi."}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            {rowData.created}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData) => {
                    let className = "";
                    let otherText = "";
                    let coloredText;

                    if (!rowData.alerts) {
                        className += " redColorText";
                        coloredText = "No";
                        otherText = `(for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`;
                    } else {
                        coloredText = rowData.alerts;
                    }

                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }

                    ReactDOM.render(
                        <div className="notifications">
                            <div className={`notification-level bold ${className}`}>
                                {coloredText}
                            </div>
                            <div className="notification-info">
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.alerts ? { display: "none" } : { marginLeft: 4 }} />
                            </div>
                            {
                                otherText &&
                                <div style={{ marginRight: 4, marginLeft: 4 }} className="greyColorText italic snoozed-info">
                                    { otherText }
                                </div>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, rowData) => {
                    let className = "bold";
                    let otherText;
                    let coloredText;

                    if (!rowData.resolved) {
                        className += " redColorText";
                        coloredText = "No";
                        otherText = `(for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                    } else {
                        className += " greenColorText";
                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.resolved)})`;
                        }
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <span className={className}>
                                <FontAwesomeIcon icon={rowData.resolved ? faCheckCircle : faExclamationCircle} />
                                <span style={{ marginRight: 4, marginLeft: 4 }}>
                                    {coloredText}
                                </span>
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.resolved ? { display: "none" } : {}} />
                            </span>
                            <span style={{ marginRight: 4, marginLeft: 4 }} className="greyColorText italic">
                                {otherText}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 10,
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass;
                    let snoozeIconClass;

                    const issueSnoozeableCode = issueSnoozeable(rowData.issue);
                    if (issueSnoozeableCode === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeableCode === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon";
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;
                    const issuePermission = props.detached ? PERMISSION.ISSUE.DETACHED : PERMISSION.ISSUE.MISSING;

                    ReactDOM.render(
                        <div className="action-items">
                            <div className="seen" id={rowData.id}>
                                <FontAwesomeIcon icon={faEye} className={seenIconClass} />
                            </div>
                            <CanAccess
                                permissions={ context.permissions }
                                action={ [PERMISSION.ISSUE.SNOOZE, issuePermission.SNOOZE] }
                                yes={
                                    <div className={ snoozeDivClass }>
                                        { snoozeIconClass === "disabled-icon" ?
                                            <svg width="16" height="12" style={ { marginTop: "2px" } } viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z"/>
                                                <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z"/>
                                                <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z"/>
                                                <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z"/>
                                                <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z"/>
                                                <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z"/>
                                            </svg>
                                            : <FontAwesomeIcon icon={ faAlarmSnooze } className={ snoozeIconClass }/>
                                        }
                                    </div>
                                }
                            />
                            <div className="comment">
                                <FontAwesomeIcon icon={faComment} className={commentIconClass} />
                            </div>
                            <CanAccess
                                permissions={ context.permissions }
                                action={ [PERMISSION.ISSUE.CLOSE, issuePermission.CLOSE] }
                                yes={
                                    <div className={ !isRelevant ? "disabled-resolve" : "resolve" }>
                                        <FontAwesomeIcon icon={ faTimesCircle } className={ !isRelevant ? "disabled-icon" : "resolve-icon" } />
                                    </div>
                                }
                            />
                           <CanAccess
                                permissions={ context.permissions } 
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <div>
                                        <ReeferControlSvg />
                                    </div> 
                                }
                            />
                        </div>,
                        td
                    );
                }
            }
        ];

        let relevantIssues = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : undefined;

        let buttonNode;
        if (!!relevantIssues || relevantIssues === undefined) {
            relevantIssues = true;
            buttonNode = $(`button[id=relevant]`);
        } else {
            relevantIssues = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addActiveLegToQueryString(window.location.search.slice(1), relevantIssues);

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        const page = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page');
        const showModals = localStorage.getItem('showModals');

        this.state = {
            data: [],
            columns: this.columns,
            columnDefs: this.columnDefs,
            sort: [],
            issuesLoaded: false,
            activeLeg: relevantIssues,
            page: page ? parseInt(page) : 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            },
            showModal: showModals && !!JSON.parse(showModals)[this.locationIssues],
            doNotShowTutorial: showModals && !JSON.parse(showModals)[this.locationIssues],
        }
    }

    async componentDidMount() {
        await this.fetchAllIssues();
    }

    componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            this.handlePage(1, true);
        }
    }

    async fetchAllIssues(force) {
        let cachingIndex = this.context.team.slice().join('_');
        cachingIndex += this.state.activeLeg ? '_relevant' : '_irrelevant';

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        const queryString = window.location.search.slice(1);
        const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(queryString);
        const tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }

            let types;
            if (this.props.detached === undefined) {
                types = ["DETACHED", "MISSING"]
            } else if (this.props.detached) {
                types = ["DETACHED"]
            } else {
                types = ["MISSING"]
            }

            const response = await this.context.get(issuesDetailsUrl, {
                types: types,
                active: this.state.activeLeg,
                sort: sort,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                console.error(response.message);
                return {};
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    notificationsLoaded: true,
                    dataUpdated: true
                });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const issues = mapIssuesFromResponse(data);
            const newData = mapGridResponseData(issues, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                issuesLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                issuesLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const existing = sortingArray.findIndex(x => x && x.columnName === this.columnNames[index]);

        if (existing === -1) {
            sortingArray.push({
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            });
        } else if (sorting === "") {
            sortingArray.splice(existing, 1);
        } else {
            sortingArray[existing] = {
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            }
        }

        const queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    changeActiveness(relevantIssues) {
        const queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevantIssues);

        this.setState({
            activeLeg: relevantIssues,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber,
            },
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    onReceiveNewComment(comment) {
        const newData = this.state.data;
        newData.find(data => data.issue.id === comment.issue.id).comments.unshift(comment);

        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        this.setState({
            allIssues: deleteComment(deletedComment, this.state.data)
        });
    }

    async handlePage(page, force) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchAllIssues(force);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues()
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;
        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchAllIssues();
            }, 1000);
        }
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    openTutorialModal() {
        this.setState({
            showModal: true
        });
    }

    closeTutorialModal = () => {
        const existingItem = JSON.parse(localStorage.getItem('showModals'));
        localStorage.setItem('showModals', JSON.stringify({ ...existingItem, [this.locationIssues]: !this.state.doNotShowTutorial }));
        this.setState({
            showModal: false
        });
    }

    onCheckboxChange() {
        this.setState({
            doNotShowTutorial: !this.state.doNotShowTutorial
        });
    }

    render() {
        const headerText = this.props.detached ? "Detached" : "Missing";

        return (
            <div className="container-fluid page detached-missing-trailer-issues">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <CanAccess
                                    action={PERMISSION.PAGE.ALL_ISSUES}
                                    yes={
                                        <li className="breadcrumb-item">
                                            <Link to="/issues/all">
                                                All issues
                                            </Link>
                                        </li>
                                    }
                                    no={
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                Fleet overview
                                            </Link>
                                        </li>
                                    }
                                />
                                <li className="breadcrumb-item active">
                                    <Link to={window.location.pathname}>
                                        {headerText} trailer issues
                                    </Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading">
                                {headerText} trailers
                            </p>
                            <FontAwesomeIcon className="tutorial-icon" icon={faQuestionCircle} onClick={this.openTutorialModal.bind(this)} />
                            <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.issuesLoaded || !this.state.dataUpdated} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="active-toggle">
                            <ActiveSelection activeCallback={this.changeActiveness.bind(this)} initiallyActive={this.state.activeLeg} />
                        </div>
                    </div>
                </div>
                { (!this.state.doNotShowTutorial || this.state.showModal) &&
                <Modal
                    show={ this.state.showModal }
                    onHide={ this.closeTutorialModal }
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={ false }
                    className="tutorial-modal-location"
                >
                    <SimpleContainer className="tutorial-modal-location" title={ (this.props.detached ? "DETACHED" : "MISSING") + " TRAILERS TUTORIAL" } modal={ true }>
                        <Modal.Body className="tutorial-modal-location">
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <div className="tutorial-modal-subtitle">
                                        { this.props.detached ?
                                            <>
                                                Trailers in the responsibility of a driver on home time & they are
                                                not close by
                                                their truck. This is typically found while doing CMP check calls to
                                                verify the
                                                locations have not changed without authorization or that the driver
                                                isn't bobtailing around.

                                                <br/>
                                                <br/>
                                                **this is considering the pre-set requirements to exclude trailers
                                                from this
                                                category that are dropped at customer locations/etc.
                                            </> :
                                            <>
                                                Trailers in the responsibility of a driver on a home time that are
                                                not close by their truck and did not report in the last 24 hours. We
                                                have confirmed with the driver the last known whereabouts and there
                                                is no visual of the trailer.
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-4 col-xs-12">
                                    <table style={ { borderCollapse: "separate", borderSpacing: 10 } }>
                                        <tbody>
                                            <tr>
                                                <td className="align-top" colSpan="2">
                                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M18 11.8125C14.5547 11.8125 11.8125 14.5547 11.8125 18C11.8125 21.4453 14.5547 24.1875 18 24.1875C21.4453 24.1875 24.1875 21.4453 24.1875 18C24.1875 14.5547 21.4453 11.8125 18 11.8125ZM18 20.8125C16.4531 20.8125 15.1875 19.5469 15.1875 18C15.1875 16.4531 16.4531 15.1875 18 15.1875C19.5469 15.1875 20.8125 16.4531 20.8125 18C20.8125 19.5469 19.5469 20.8125 18 20.8125ZM34.3125 16.3125H31.3594C30.5859 10.1953 25.8047 5.41406 19.6875 4.64062V1.6875C19.6875 0.773438 18.9141 0 18 0C17.0859 0 16.3125 0.773438 16.3125 1.6875V4.64062C10.1953 5.41406 5.41406 10.1953 4.64062 16.3125H1.6875C0.773438 16.3125 0 17.0859 0 18C0 18.9141 0.773438 19.6875 1.6875 19.6875H4.64062C5.41406 25.8047 10.1953 30.5859 16.3125 31.3594V34.3125C16.3125 35.2266 17.0859 36 18 36C18.9141 36 19.6875 35.2266 19.6875 34.3125V31.3594C25.8047 30.5859 30.5859 25.8047 31.3594 19.6875H34.3125C35.2266 19.6875 36 18.9141 36 18C36 17.0859 35.2266 16.3125 34.3125 16.3125ZM18 28.125C12.4453 28.125 7.875 23.5547 7.875 18C7.875 12.4453 12.4453 7.875 18 7.875C23.5547 7.875 28.125 12.4453 28.125 18C28.125 23.5547 23.5547 28.125 18 28.125Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td colSpan="4">
                                                    <p className="step-text bold"
                                                        style={ { paddingBottom: 4 } }>
                                                        STEP 1:
                                                    </p>
                                                    <p className="modal-text">
                                                        Verify equipment locations shown in KeepTruckin and ThermoKing.
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr />
                                            <tr>
                                                <td className="modal-text bold" colSpan="6">
                                                    IF REEFER IS OFF, REMOTELY TURN ON THE TRAILER TO 40 DEGREES FOR
                                                    ABOUT 15 MINUTES TO ATTEMPT TO GET A NEW GPS PING, THEN TURN THE
                                                    REEFER BACK OFF.
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="vll-container">
                                                    <div className="vll" />
                                                </td>
                                                <td className="align-top">
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={ { paddingLeft: 10 } } colSpan="4">
                                                    <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                        STEP 2A:
                                                    </p>
                                                    <p className="modal-text">
                                                        If the location does not update, call the Driver to verify where
                                                        the Trailer was dropped.
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="vll-container">
                                                    <div className="vll" />
                                                </td>
                                                <td className="align-top">
                                                    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M25.6641 3.65625C24.8906 3.16406 23.8359 3.375 23.3438 4.14844C22.9219 4.99219 23.1328 6.04688 23.9062 6.46875C27.9844 9 30.375 13.2891 30.375 18C30.375 25.4531 24.3281 31.5 16.875 31.5C9.42188 31.5 3.375 25.4531 3.375 18C3.375 13.2188 5.83594 8.92969 9.84375 6.46875C10.6875 5.97656 10.8984 4.92188 10.4766 4.14844C9.98438 3.375 8.92969 3.09375 8.15625 3.58594C3.02344 6.67969 0 12.0938 0 18C0 27.2812 7.59375 34.875 16.875 34.875C26.1562 34.875 33.75 27.2812 33.75 18C33.75 12.0938 30.7266 6.75 25.6641 3.65625ZM16.875 20.25C17.7891 20.25 18.5625 19.4766 18.5625 18.5625V1.6875C18.5625 0.773438 17.7891 0 16.875 0C15.9609 0 15.1875 0.773438 15.1875 1.6875V18.5625C15.1875 19.4766 15.9609 20.25 16.875 20.25Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={ { paddingLeft: 10 } } colSpan="4">
                                                    <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                        STEP 2B:
                                                    </p>
                                                    <p className="modal-text">
                                                        The reefer typically takes no more than 10 minutes to start up.
                                                        <span className="bold">
                                                            &nbsp;Please do not WAIT longer than 30 minutes to move on to step #3.
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-sm-4 col-xs-12">
                                    <table style={ { borderCollapse: "separate", borderSpacing: 10 } }>
                                        <tbody>
                                            <tr>
                                                <td className="modal-text bold" colSpan="6">
                                                    IF THE TRAILER HAS BEEN DROPPED AT AN UNAPPROVED LOCATION...
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="vll-container">
                                                    <div className="vll" />
                                                </td>
                                                <td className="align-top">
                                                    <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.85938 22.375L14.875 17.4062C15.0391 17.2422 15.0391 16.9766 14.875 16.8125L13.8906 15.8203C13.7266 15.6562 13.4609 15.6562 13.2969 15.8203L9.57812 19.5078L7.96875 17.875C7.80469 17.7109 7.53906 17.7109 7.375 17.875L6.38281 18.8594C6.21875 19.0234 6.21875 19.2891 6.38281 19.4531L9.28125 22.375C9.42969 22.5391 9.69531 22.5391 9.85938 22.375ZM26.25 5H20C20 2.24219 17.7578 0 15 0C12.2422 0 10 2.24219 10 5H3.75C1.67969 5 0 6.67969 0 8.75V36.25C0 38.3203 1.67969 40 3.75 40H26.25C28.3203 40 30 38.3203 30 36.25V8.75C30 6.67969 28.3203 5 26.25 5ZM15 3.75C15.6875 3.75 16.25 4.3125 16.25 5C16.25 5.6875 15.6875 6.25 15 6.25C14.3125 6.25 13.75 5.6875 13.75 5C13.75 4.3125 14.3125 3.75 15 3.75ZM26.25 35.625C26.25 35.9688 25.9688 36.25 25.625 36.25H4.375C4.03125 36.25 3.75 35.9688 3.75 35.625V9.375C3.75 9.03125 4.03125 8.75 4.375 8.75H7.5V11.25C7.5 11.9375 8.0625 12.5 8.75 12.5H21.25C21.9375 12.5 22.5 11.9375 22.5 11.25V8.75H25.625C25.9688 8.75 26.25 9.03125 26.25 9.375V35.625ZM8.75 25.625C7.71094 25.625 6.875 26.4609 6.875 27.5C6.875 28.5391 7.71094 29.375 8.75 29.375C9.78906 29.375 10.625 28.5391 10.625 27.5C10.625 26.4609 9.78906 25.625 8.75 25.625ZM21.875 18.75H16.9297C16.8281 18.8906 16.7656 19.0547 16.6406 19.1797L14.5469 21.25H21.875C22.2188 21.25 22.5 20.9688 22.5 20.625V19.375C22.5 19.0312 22.2188 18.75 21.875 18.75ZM21.875 26.25H13.125C12.7812 26.25 12.5 26.5312 12.5 26.875V28.125C12.5 28.4688 12.7812 28.75 13.125 28.75H21.875C22.2188 28.75 22.5 28.4688 22.5 28.125V26.875C22.5 26.5312 22.2188 26.25 21.875 26.25Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={ { paddingLeft: 10 } } colSpan="4">
                                                    <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                        STEP 3A:
                                                    </p>
                                                    <p className="modal-text">
                                                        Verify that Management approved the drop.
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="vll-container">
                                                    <div className="vll" />
                                                </td>
                                                <td className="align-top">
                                                    <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M27 13.5H24.75V9C24.75 4.00781 20.7422 0 15.75 0C10.7578 0 6.75 4.00781 6.75 9V13.5H4.5C2.03906 13.5 0 15.5391 0 18V31.5C0 33.9609 2.03906 36 4.5 36H27C29.4609 36 31.5 33.9609 31.5 31.5V18C31.5 15.5391 29.4609 13.5 27 13.5ZM10.125 9C10.125 5.90625 12.6562 3.375 15.75 3.375C18.8438 3.375 21.375 5.90625 21.375 9V13.5H10.125V9ZM28.125 31.5C28.125 32.1328 27.6328 32.625 27 32.625H4.5C3.86719 32.625 3.375 32.1328 3.375 31.5V18C3.375 17.3672 3.86719 16.875 4.5 16.875H27C27.6328 16.875 28.125 17.3672 28.125 18V31.5ZM15.75 21.375C14.8359 21.375 14.0625 22.1484 14.0625 23.0625V26.4375C14.0625 27.3516 14.8359 28.125 15.75 28.125C16.6641 28.125 17.4375 27.3516 17.4375 26.4375V23.0625C17.4375 22.1484 16.6641 21.375 15.75 21.375Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={ { paddingLeft: 10 } } colSpan="4">
                                                    <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                        STEP 3B:
                                                    </p>
                                                    <p className="modal-text">
                                                        Verify that a Kingpin lock was used & that the location has cameras.
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr />
                                            <tr>
                                                <td className="modal-text bold" colSpan="6">
                                                    IF MANAGEMENT DID NOT APPROVE THE DROP...
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="vll-container">
                                                    <div className="vll" />
                                                </td>
                                                <td className="align-top">
                                                    <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M27 13.5H24.75V9C24.75 4.00781 20.7422 0 15.75 0C10.7578 0 6.75 4.00781 6.75 9V13.5H4.5C2.03906 13.5 0 15.5391 0 18V31.5C0 33.9609 2.03906 36 4.5 36H27C29.4609 36 31.5 33.9609 31.5 31.5V18C31.5 15.5391 29.4609 13.5 27 13.5ZM10.125 9C10.125 5.90625 12.6562 3.375 15.75 3.375C18.8438 3.375 21.375 5.90625 21.375 9V13.5H10.125V9ZM28.125 31.5C28.125 32.1328 27.6328 32.625 27 32.625H4.5C3.86719 32.625 3.375 32.1328 3.375 31.5V18C3.375 17.3672 3.86719 16.875 4.5 16.875H27C27.6328 16.875 28.125 17.3672 28.125 18V31.5ZM15.75 21.375C14.8359 21.375 14.0625 22.1484 14.0625 23.0625V26.4375C14.0625 27.3516 14.8359 28.125 15.75 28.125C16.6641 28.125 17.4375 27.3516 17.4375 26.4375V23.0625C17.4375 22.1484 16.6641 21.375 15.75 21.375Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={ { paddingLeft: 10 } } colSpan="4">
                                                    <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                        STEP 4A:
                                                    </p>
                                                    <p className="modal-text">
                                                        Verify whether a kingpin lock was used & if the location has cameras.
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="vll-container">
                                                    <div className="vll" />
                                                </td>
                                                <td className="align-top">
                                                    <svg width="45" height="37" viewBox="0 0 45 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M36.5625 12.3836C37.2657 12.3836 37.9688 11.9618 38.1797 11.1883C38.461 10.3446 37.9688 9.36021 37.0547 9.07896L28.0547 6.19615C28.0547 5.98521 28.125 5.84459 28.125 5.63365C28.125 2.5399 25.5938 0.00864809 22.5 0.00864809C20.3907 0.00864809 18.6328 1.13365 17.6485 2.89146L8.92972 0.0789606C8.08597 -0.202289 7.1016 0.289898 6.82035 1.20396C6.5391 2.04771 7.03129 3.03209 7.94535 3.31334L16.9453 6.19615C17.1563 8.44615 18.7032 10.2743 20.8125 10.9774V32.6336H8.43754C7.52347 32.6336 6.75004 33.4071 6.75004 34.3211C6.75004 35.2352 7.52347 36.0086 8.43754 36.0086H21.9375C23.2032 36.0086 24.1875 35.0243 24.1875 33.7586V10.9774C25.1719 10.6258 26.0157 10.0633 26.7188 9.2899L36.0704 12.3133C36.211 12.3836 36.4219 12.3836 36.5625 12.3836ZM22.5 7.88365C21.2344 7.88365 20.25 6.89927 20.25 5.63365C20.25 4.36802 21.2344 3.38365 22.5 3.38365C23.7657 3.38365 24.75 4.36802 24.75 5.63365C24.75 6.89927 23.7657 7.88365 22.5 7.88365ZM39.0235 17.5868C38.3907 16.3915 37.1954 15.7586 36 15.7586C34.8047 15.7586 33.6094 16.3915 32.9766 17.5868C26.9297 29.7508 27 29.2586 27 30.3836C27 33.4774 31.0079 36.0086 36 36.0086C40.9922 36.0086 45 33.4774 45 30.3836C45 29.2586 45.1407 29.8915 39.0235 17.5868ZM30.9375 29.2586L36 19.1336C37.336 21.8055 40.6407 28.3446 41.0625 29.2586H30.9375ZM18 21.3836C18 20.2586 18.1407 20.8915 12.0235 8.58677C11.3907 7.39146 10.1953 6.75865 9.00004 6.75865C7.80472 6.75865 6.60941 7.39146 5.9766 8.58677C-0.0702763 20.7508 3.63184e-05 20.2586 3.63184e-05 21.3836C3.63184e-05 24.4774 4.00785 27.0086 9.00004 27.0086C13.9922 27.0086 18 24.4774 18 21.3836ZM9.00004 10.1336C9.00004 10.1336 13.6407 19.3446 14.0625 20.2586H3.93754L9.00004 10.1336Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={ { paddingLeft: 10 } } colSpan="4">
                                                    <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                        STEP 4B:
                                                    </p>
                                                    <p className="modal-text">
                                                        Determine whether disciplinary action is needed for disregarding company policy.
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-sm-4 col-xs-12">
                                    <table style={ { borderCollapse: "separate", borderSpacing: 10 } }>
                                        <tbody>
                                            <tr>
                                                <td className="modal-text bold" colSpan="6">
                                                    IF A KINGPIN LOCK WAS NOT USED...
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="vll-container">
                                                    <div className="vll" />
                                                </td>
                                                <td className="align-top">
                                                    <svg width="45" height="32" viewBox="0 0 45 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M43.3125 21.375H40.5V4.5C40.5 2.03906 38.4609 0 36 0H4.5C2.03906 0 0 2.03906 0 4.5V20.25C0 22.7109 2.03906 24.75 4.5 24.75H6.75C6.75 28.4766 9.77344 31.5 13.5 31.5C17.2266 31.5 20.25 28.4766 20.25 24.75H43.3125C44.2266 24.75 45 23.9766 45 23.0625C45 22.1484 44.2266 21.375 43.3125 21.375ZM13.5 28.125C11.6719 28.125 10.125 26.5781 10.125 24.75C10.125 22.9219 11.6719 21.375 13.5 21.375C15.3281 21.375 16.875 22.9219 16.875 24.75C16.875 26.5781 15.3281 28.125 13.5 28.125ZM37.125 21.375H19.3359C18.1406 19.3359 15.9609 18 13.5 18C11.0391 18 8.85938 19.3359 7.66406 21.375H4.5C3.86719 21.375 3.375 20.8828 3.375 20.25V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H36C36.6328 3.375 37.125 3.86719 37.125 4.5V21.375ZM23.625 5.625C22.7109 5.625 21.9375 6.39844 21.9375 7.3125V17.4375C21.9375 18.3516 22.7109 19.125 23.625 19.125C24.5391 19.125 25.3125 18.3516 25.3125 17.4375V7.3125C25.3125 6.39844 24.5391 5.625 23.625 5.625ZM16.875 5.625C15.9609 5.625 15.1875 6.39844 15.1875 7.3125V14.0625C15.1875 14.9766 15.9609 15.75 16.875 15.75C17.7891 15.75 18.5625 14.9766 18.5625 14.0625V7.3125C18.5625 6.39844 17.7891 5.625 16.875 5.625ZM10.125 5.625C9.21094 5.625 8.4375 6.39844 8.4375 7.3125V14.0625C8.4375 14.9766 9.21094 15.75 10.125 15.75C11.0391 15.75 11.8125 14.9766 11.8125 14.0625V7.3125C11.8125 6.39844 11.0391 5.625 10.125 5.625ZM30.375 5.625C29.4609 5.625 28.6875 6.39844 28.6875 7.3125V17.4375C28.6875 18.3516 29.4609 19.125 30.375 19.125C31.2891 19.125 32.0625 18.3516 32.0625 17.4375V7.3125C32.0625 6.39844 31.2891 5.625 30.375 5.625Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={ { paddingLeft: 10 } } colSpan="4">
                                                    <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                        STEP 5A:
                                                    </p>
                                                    <p className="modal-text">
                                                        Request the Driver to return to check on the equipment and place one on it ASAP.
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="vll-container">
                                                    <div className="vll" />
                                                </td>
                                                <td className="align-top">
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M28 0H4C1.79375 0 0 1.79375 0 4V22C0 24.2062 1.79375 26 4 26H10V31.25C10 31.6938 10.3625 32 10.75 32C10.9 32 11.0563 31.9563 11.1938 31.85L19 26H28C30.2062 26 32 24.2062 32 22V4C32 1.79375 30.2062 0 28 0ZM29 22C29 22.55 28.55 23 28 23H18L17.2 23.6L13 26.75V23H4C3.45 23 3 22.55 3 22V4C3 3.45 3.45 3 4 3H28C28.55 3 29 3.45 29 4V22ZM23 8.5H9C8.45 8.5 8 8.95 8 9.5V10.5C8 11.05 8.45 11.5 9 11.5H23C23.55 11.5 24 11.05 24 10.5V9.5C24 8.95 23.55 8.5 23 8.5ZM17 14.5H9C8.45 14.5 8 14.95 8 15.5V16.5C8 17.05 8.45 17.5 9 17.5H17C17.55 17.5 18 17.05 18 16.5V15.5C18 14.95 17.55 14.5 17 14.5Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={ { paddingLeft: 10 } } colSpan="4">
                                                    <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                        STEP 5B:
                                                    </p>
                                                    <p className="modal-text">
                                                        If the Driver is unable to do so, coordinate with the team to find a nearby Driver to do so.
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="vll-container">
                                                    <div className="vll" />
                                                </td>
                                                <td className="align-top">
                                                    <svg width="45" height="37" viewBox="0 0 45 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M36.5625 12.3836C37.2657 12.3836 37.9688 11.9618 38.1797 11.1883C38.461 10.3446 37.9688 9.36021 37.0547 9.07896L28.0547 6.19615C28.0547 5.98521 28.125 5.84459 28.125 5.63365C28.125 2.5399 25.5938 0.00864809 22.5 0.00864809C20.3907 0.00864809 18.6328 1.13365 17.6485 2.89146L8.92972 0.0789606C8.08597 -0.202289 7.1016 0.289898 6.82035 1.20396C6.5391 2.04771 7.03129 3.03209 7.94535 3.31334L16.9453 6.19615C17.1563 8.44615 18.7032 10.2743 20.8125 10.9774V32.6336H8.43754C7.52347 32.6336 6.75004 33.4071 6.75004 34.3211C6.75004 35.2352 7.52347 36.0086 8.43754 36.0086H21.9375C23.2032 36.0086 24.1875 35.0243 24.1875 33.7586V10.9774C25.1719 10.6258 26.0157 10.0633 26.7188 9.2899L36.0704 12.3133C36.211 12.3836 36.4219 12.3836 36.5625 12.3836ZM22.5 7.88365C21.2344 7.88365 20.25 6.89927 20.25 5.63365C20.25 4.36802 21.2344 3.38365 22.5 3.38365C23.7657 3.38365 24.75 4.36802 24.75 5.63365C24.75 6.89927 23.7657 7.88365 22.5 7.88365ZM39.0235 17.5868C38.3907 16.3915 37.1954 15.7586 36 15.7586C34.8047 15.7586 33.6094 16.3915 32.9766 17.5868C26.9297 29.7508 27 29.2586 27 30.3836C27 33.4774 31.0079 36.0086 36 36.0086C40.9922 36.0086 45 33.4774 45 30.3836C45 29.2586 45.1407 29.8915 39.0235 17.5868ZM30.9375 29.2586L36 19.1336C37.336 21.8055 40.6407 28.3446 41.0625 29.2586H30.9375ZM18 21.3836C18 20.2586 18.1407 20.8915 12.0235 8.58677C11.3907 7.39146 10.1953 6.75865 9.00004 6.75865C7.80472 6.75865 6.60941 7.39146 5.9766 8.58677C-0.0702763 20.7508 3.63184e-05 20.2586 3.63184e-05 21.3836C3.63184e-05 24.4774 4.00785 27.0086 9.00004 27.0086C13.9922 27.0086 18 24.4774 18 21.3836ZM9.00004 10.1336C9.00004 10.1336 13.6407 19.3446 14.0625 20.2586H3.93754L9.00004 10.1336Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={ { paddingLeft: 10 } } colSpan="4">
                                                    <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                        STEP 5C:
                                                    </p>
                                                    <p className="modal-text">
                                                        Determine whether disciplinary action is needed for disregarding company policy.
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <p className="text-center">
                                <CanAccess
                                    action={ PERMISSION.PAGE.STOLEN_PROCEDURE }
                                    yes={
                                        <Link to={ "/tutorials/issues/" + (this.props.detached ? "detached" : "missing") } className="action-text">
                                            What should I do if I believe this equipment has been stolen?
                                        </Link>
                                    }
                                />
                            </p>
                            <Button variant="continue" onClick={ this.closeTutorialModal.bind(this) } style={ { width: 240, height: 36, marginTop: 15 } }>
                                Got it!
                            </Button>
                            <div className="form-check">
                                <label className={ `checkbox-text ${this.state.doNotShowTutorial ? "bold" : ""}` }>
                                    <input
                                        type="checkbox"
                                        name={ this.state.label }
                                        checked={ this.state.doNotShowTutorial }
                                        onChange={ this.onCheckboxChange.bind(this) }
                                        className="form-check-input"
                                    />
                                    Do not show this tip again
                                </label>
                            </div>
                        </Modal.Body>
                    </SimpleContainer>
                </Modal>
                }
                <div className="row">
                    <div className="col">
                        <div className="detached-missing-trailer-issues-div">
                            {this.state.issuesLoaded && (
                                <DataTableComp
                                    tableId="detachedMissingTrailerIssues"
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    onReceiveNewComment={this.onReceiveNewComment.bind(this)}
                                    onDeleteComment={this.onDeleteComment.bind(this)}
                                    columnDefs={this.state.columnDefs}
                                    customTableClass="cell-border"
                                    tableHeight="1200px"
                                    handlePreviousPage={this.handlePreviousPage}
                                    handleNextPage={this.handleNextPage}
                                    handleFirstPage={this.handleFirstPage}
                                    handleLastPage={this.handleLastPage}
                                    handleRecordsNumber={this.handleRecordsNumber}
                                    handlePageChange={this.handlePageChange}
                                    pagingInfo={this.state.pagingInfo}
                                    onClickLocation={{
                                        pathname: "/details",
                                        state: { trailerId: this.state.data.onClickState },
                                    }}
                                    account={this.props.account}
                                    sortRule={this.state.sort.concat([])}
                                    addSortingForColumn={this.addSortingForColumn.bind(this)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withAITracking(reactPlugin, DetachedMissingTrailers, "DetachedMissingTrailers"));
