import React, { Component } from 'react';

import NavBar from 'components/navigation/NavBar';
import Header from 'components/navigation/Header';
import OutageBanner from './OutageBanner';

export default class WithNavigationPage extends Component {

    render() {
        return (
            <div className="main">
                <Header />
                <NavBar />
                <OutageBanner />
                <main className="application-content">
                    { this.props.children }
                </main>
            </div>
        );
    }
}
