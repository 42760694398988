import React, { Component } from "react";

import { GRAPH_REQUESTS, isAuthEnabled, msal, requiresInteraction, PERMISSION } from "global/utils/auth";
import AuthorizationService from "global/services/AuthorizationService";
import QueryParamService from "global/services/QueryParamService";
import ObjectUtils from "global/utils/ObjectUtils";
import config from 'config';

const { URL_FRONT } = config;

const PERMISSION_TEAM_MAPPING = {
    [PERMISSION.TEAM.DEFAULT.BLACK]: "BLACK",
    [PERMISSION.TEAM.DEFAULT.BLUE]: "BLUE",
    [PERMISSION.TEAM.DEFAULT.GREEN]: "GRE",
    [PERMISSION.TEAM.DEFAULT.GREY]: "GREY",
    [PERMISSION.TEAM.DEFAULT.ORANGE]: "ORA",
    [PERMISSION.TEAM.DEFAULT.PURPLE]: "PUR",
    [PERMISSION.TEAM.DEFAULT.RED]: "RED",
    [PERMISSION.TEAM.DEFAULT.LOCAL]: "LOCAL",
    [PERMISSION.TEAM.DEFAULT.UNKNOWN]: "UNK",
    [PERMISSION.TEAM.DEFAULT.NO_TEAM]: "NONE"
};

// If you support IE, sign-in using Redirect APIs
// const useRedirectFlow = `${URL_FRONT}/`; //isIE();

export default C => class AuthProvider extends Component {
    constructor(props) {
        super(props);

        let permissions;
        if (isAuthEnabled) {
            permissions = this.getPermissions();
        } else {
            permissions = ObjectUtils.getValues(PERMISSION);
        }

        this.state = {
            account: null,
            token: null,
            error: null,
            mounted: false,
            team: this.getTeam(permissions),
            permissions
        };
    }

    getTeam(permissions) {
        if (permissions.length === 0) {
            return [];
        }

        let selectedTeams;

        let defaultTeams = permissions.map(permission => PERMISSION_TEAM_MAPPING[permission]).filter(e => e);
        if (defaultTeams.length === 0) {
            defaultTeams = ["*"];
        }

        if (!AuthorizationService.canAccess(permissions, PERMISSION.TEAM.SELECT)) {
            selectedTeams = defaultTeams;
        } else {
            const queryString = window.location.search.slice(1);
            let paramTeams = QueryParamService.parseSimpleValueFromQueryString(queryString, 'team');

            if (paramTeams) {
                if (!Array.isArray(paramTeams)) {
                    paramTeams = [paramTeams];
                }

                selectedTeams = paramTeams;
            } else {
                let localStorageTeams = localStorage.getItem('selectedTeams');
                localStorageTeams = localStorageTeams ? JSON.parse(localStorageTeams) : [];

                if (localStorageTeams.length > 0) {
                    selectedTeams = localStorageTeams;
                } else {
                    selectedTeams = defaultTeams;
                }
            }
        }

        localStorage.setItem('selectedTeams', JSON.stringify(selectedTeams));
        return selectedTeams;
    }

    getPermissions() {
        return (((msal.getAccount() || {}).idTokenClaims || {}).roles || []);
    }

    async acquireToken(request, redirect) {
        if (isAuthEnabled) {
            return msal.acquireTokenSilent(request).then(function(accessTokenResponse) {
                return accessTokenResponse.idToken.rawIdToken;
            }).catch(error => {
                // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
                // due to consent or interaction required ONLY
                if (requiresInteraction(error.errorCode)) {
                    return redirect
                        ? msal.acquireTokenRedirect({
                            ...request,
                            redirectUri: URL_FRONT
                        })
                        : msal.acquireTokenPopup(request);
                } else {
                    console.error('Non-interactive error:', error)
                }
            });
        } else {
            return null;
        }
    }

    async onSignIn(redirect) {
        if (redirect) {
            return msal.loginRedirect({
                ...GRAPH_REQUESTS.LOGIN,
                redirectUri: window.location.origin
            });
        }

        const loginResponse = await msal
            .loginPopup(GRAPH_REQUESTS.LOGIN)
            .catch(error => {
                this.setState({
                    error: error.message
                });
                return;
            });

        if (loginResponse) {
            this.setState({
                account: loginResponse.account,
                error: null
            });

            const token = await this.acquireToken(
                GRAPH_REQUESTS.LOGIN
            ).catch(error => {
                this.setState({
                    error: error.message
                });
            });

            this.setState({
                token
            });
        }
    }

    onSignOut() {
        this.setState({mounted: false});
        msal.logout();
    }

    async setToken() {
        msal.handleRedirectCallback((error, result) => {
            if (error) {
                const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
                // setState works as long as navigateToLoginRequestUrl: false
                this.setState({
                    mounted: true,
                    error: errorMessage
                });
            }
        });

        const token = await this.acquireToken({
            ...GRAPH_REQUESTS.LOGIN,
            },
            true
        );
        this.setState({
            token
        });
    }

    setTeam(team) {
        this.setState(
            {
                team: !!team ? team : ['*']
            }
        );
    }

    async componentDidMount() {
        this.setState({mounted: false});
        msal.handleRedirectCallback((error, result) => {
            if (error) {
                const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
                // setState works as long as navigateToLoginRequestUrl: false
                this.setState({
                    mounted: true,
                    error: errorMessage
                });
            }
        });

        const account = msal.getAccount();
        await this.setToken();
        this.setState({
            mounted: true,
            account
        });
    }

    render() {
        return (
            this.state.mounted && <C
                {...this.props}
                account={this.state.account}
                token={this.state.token}
                team={this.state.team}
                permissions={this.state.permissions}
                setToken={async () => await this.setToken()}
                setTeam={this.setTeam.bind(this)}
                error={this.state.error}
                history={this.state.history}
                onSignIn={(redirectFlow) => this.onSignIn(redirectFlow)}
                onSignOut={() => this.onSignOut()}
            />
            );
    }
};
