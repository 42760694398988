import React, { Component } from 'react';
import { faCheck as resolutionIcon, faPlus as creationIcon } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandableCard from 'components/card/ExpandableCard';

import "./HelpCenter.scss";

export default class TrailerAuditHelp extends Component {
    render() {
        return (
            <>
                <div className="tab-description">
                    The trailer audit provides insights into all active trailers and fleet trailers with categories that represent different trailer states. Each category has some logic that defines when to put a trailer in that category. A trailer can appear in multiple categories (e.g. it can be in the yard, silent and on repair).
                    <br />
                    <br />
                    An important difference between the trailer audit and the fleet overview is that the trailer audit shows a snapshot of data in time while the fleet overview shows live data. For example, if a mismatched trailer issue is resolved, this will appear on the fleet overview after a page refresh, but it will not show on the trailer audit until a new report is generated.
                </div>

                <ExpandableCard title="Total trailers">
                    <div>
                        Total trailers category contains all active ShipEX trailers. A trailer is considered active if all of these conditions are satisfied:
                        <ul>
                            <li>
                                Trailer company is ShipEX (<i>trl_company = spxq</i>)
                            </li>
                            <li>
                                Trailer retire date is in the future (<i>trl_retiredate >= now</i>)
                            </li>
                            <li>
                                Trailer status is not OUT (<i>trl_status != OUT</i>)
                            </li>
                        </ul>
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Unaccounted trailers">
                    <div>
                        Unaccounted trailers category contains all active trailers that do not fall into any other trailer audit category.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Trailers under dispatch">
                    <div>
                        Trailers under dispatch category contains all active trailers that are on an active leg. If the trailer is on a oneway leg it will not show up in this category.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Drop yard">
                    <div>
                        Drop yard category contains all active trailers that are in a 0.5 miles radius of an approved drop yard. List of approved drop yards can be seen on <b>Trailers in Drop Yards</b> section under <b>Trailer Audit</b>. The location of the trailer is determined by the latest trailer measurement. If the trailer is on a oneway leg it will not show up in this category.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Trailers at the yard">
                    <div>
                        Trailers at the yard category contains all active trailers that are in a 0.5 miles radius of the ShipEX yard. The location of the trailer is determined by the latest trailer measurement. If the trailer is on a oneway leg it will not show up in this category.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Trailers with drivers on home time">
                    <div>
                        Trailers with drivers on home time category contains all active trailers where the driver on the last trailer leg is on home time. A driver is on home time if there exists a TMW expiration that satisfies all of the following conditions:
                        <ul>
                            <li>
                                Expiration type is driver (<i>exp_idtype = DRV</i>)
                            </li>
                            <li>
                                Expiration code is home (<i>exp_code = home</i>)
                            </li>
                            <li>
                                Expiration is not completed (<i>exp_completed != Y</i>)
                            </li>
                            <li>
                                Expiration start time is in the past (<i>exp_expirationdate &lt;= now</i>)
                            </li>
                            <li>
                                Expiration end time is in the future (<i>exp_compldate > now</i>)
                            </li>
                        </ul>
                        If the trailer is on a oneway leg it will not show up in this category.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Oneway trailers">
                    <div>
                        Oneway trailers category contains all active trailers that are on an active oneway leg. A leg is considered oneway if the TMW field <i>legType2</i> is equal to ONEWAY.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="TMW repair expirations">
                    <div>
                        TMW repair expirations category contains all active trailers for which either of these conditions is satisfied:
                        <ul>
                            <li>
                                There exists an expiration on the trailer that satisfies all of the following conditions:
                                <ul>
                                    <li>
                                        Expiration type is trailer (<i>exp_idtype = TRL</i>)
                                    </li>
                                    <li>
                                        Expiration is not completed (<i>exp_completed != Y</i>)
                                    </li>
                                    <li>
                                        Expiration start time is in the past (<i>exp_expirationdate &lt; now</i>)
                                    </li>
                                    <li>
                                        Expiration end time is in the future (<i>exp_compldate > now</i>)
                                    </li>
                                    <li>
                                        Expiration code is one of the following: REFB, TRLB, DOT, PEND, TRLST, INSHOP, ONROAD
                                    </li>
                                </ul>
                            </li>
                            <br />
                            <li>
                                Latest leg the trailer was on satisfies all of the following conditions:
                                <ul>
                                    <li>
                                        Leg is not completed (CMP)
                                    </li>
                                    <li>
                                        Leg field <i>lgh_type2</i> is equal to TRL
                                    </li>
                                    <li>
                                        There exists a stop on this leg with one of following stop events: LUL, LLD, HPL, HLT, DRL, DLT
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        If the trailer is on a oneway leg it will not show up in this category.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Mismatched trailers">
                    <div>
                        Mismatched trailers category contains mismatched trailer issues that were relevant at the time of report generation.
                        <br />
                        See <b>Mismatched trailers</b> under <b>Fleet Overview</b>.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Silent units">
                    <div>
                        Silent units category contains silent unit issues that were relevant at the time of report generation. Silent unit issue rules for creation and resolution are given below.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div>
                        A silent unit issue is only created when the trailer is on an active leg. The issue gets created after we do not receive a trailer measurement from TK for at least 2 hours.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div>
                        A silent unit issue is resolved after we receive a trailer measurement from TK.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Inactive trailers">
                    <div>
                        Inactive trailers category contains inactive trailer issues that were relevant at the time of report generation. Inactive trailer issue rules for creation and resolution are given below.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div>
                        An inactive trailer issue gets created when a trailer does not leave a radius of 0.5 miles for a period of 3 days.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div>
                        An inactive trailer issue is resolved after we receive a trailer measurement from TK where the trailer is located outside the 0.5 miles radius.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Trailers with claim">
                    <div>
                        Trailers with claim category contains trailers with claim issues that were relevant at the time of report generation.
                        <br />
                        See <b>Trailers with claim</b> under <b>Fleet Overview</b>.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Missing trailers">
                    <div>
                        Missing trailers section contains missing trailer issues that were relevant at the time of report generation.
                        <br />
                        See <b>Missing trailers</b> under <b>Fleet Overview</b>.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Detached trailers">
                    <div>
                        Detached trailers section contains detached trailer issues that were relevant at the time of report generation.
                        <br />
                        See <b>Detached trailers</b> under <b>Fleet Overview</b>.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Trailers in Drop Yards">
                    <div>
                        Trailers in Drop Yards section contains all approved drop yards with the number of trailers inside a 0.5 miles radius for each drop yard. The location of the trailer is determined by the latest trailer measurement. If the trailer is on a oneway leg it will not show up in any drop yard.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Fleets">
                    <div>
                        Fleets section contains the following fleets and the number of trailers for each one:
                        <ul>
                            <li>
                                BUF
                            </li>
                            <li>
                                NAMPA
                            </li>
                            <li>
                                TRADE
                            </li>
                        </ul>
                        A trailer is put in of these fleets if the field <i>trl_terminal</i> on TMW contains one of the above mentioned fleets.
                    </div>
                </ExpandableCard>
            </>
        )
    }
}
