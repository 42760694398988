import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AuthContext from 'AuthContext';
import DataTableComp from 'components/datatable/DataTableComp';
import { defaultGridRecordsNumber, mapSort } from 'global/constants';
import QueryParamService from 'global/services/QueryParamService';
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import FunctionUtils from 'global/utils/FunctionUtils';

import './Table.scss';

const CACHE = {};

export class Table extends Component {
    static contextType = AuthContext;

    static propTypes = {
        columns: PropTypes.array.isRequired,
        columnDefs: PropTypes.array,
        columnNames: PropTypes.object,
        fetchData: PropTypes.func.isRequired,
        fetchTimeout: PropTypes.number,
        params: PropTypes.object
    };

    static defaultProps = {
        fetchTimeout: 0
    };

    constructor(props, context) {
        super(props, context);

        const pageQueryParam = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page');
        const page = pageQueryParam ? parseInt(pageQueryParam) : 1;
        const sort = formSortObject(QueryParamService.parseSortingQueryString(window.location.search.slice(1)), this.props.columnNames);

        this.state = {
            page,
            sort,
            dataUpdated: false,
            issuesLoaded: false,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            }
        };

        this.debouncedFetchData = FunctionUtils.debounce(this.fetchData, this.props.fetchTimeout)
        this.debouncedHandleFirstPage = FunctionUtils.debounce(this.handleFirstPage, this.props.fetchTimeout)

        this.fetchData = this.fetchData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleFirstPage = this.handleFirstPage.bind(this);
        this.handleLastPage = this.handleLastPage.bind(this);
        this.addSortingForColumn = this.addSortingForColumn.bind(this);
        this.handleRecordsNumberChange = this.handleRecordsNumberChange.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.params) !== JSON.stringify(this.props.params)) {
            if (this.state.page === 1) {
                this.debouncedFetchData();
            } else {
                this.debouncedHandleFirstPage();
            }
        }
    }

    handlePage(page) {
        this.setState({
            page: page,
            dataUpdated: false
        }, this.fetchData);
    }

    handlePreviousPage() {
        this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage() {
        this.handlePage(parseInt(this.state.page) + 1);
    }

    handleFirstPage() {
        this.handlePage(1);
    }

    handleLastPage() {
        this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handlePageChange(event) {
        const value = event.target.value;

        if (isNaN(value) || value === '') {
            this.setState({
                page: ''
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            }, this.fetchData);
        }
    }

    handleRecordsNumberChange(event) {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
            dataUpdated: false
        }, this.fetchData);
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const existing = sortingArray.findIndex(x => x && x.columnName === this.props.columnNames[index]);

        if (existing === -1) {
            sortingArray.push({
                columnIndex: index,
                columnName: this.props.columnNames[index],
                direction: sorting
            });
        } else if (sorting === '') {
            sortingArray.splice(existing, 1);
        } else {
            sortingArray[existing] = {
                columnIndex: index,
                columnName: this.props.columnNames[index],
                direction: sorting
            };
        }

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, this.fetchData);
    }

    async fetchData() {
        const cachingIndex = this.context.team;

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({ dataUpdated: false });
        }

        try {
            const sort = mapSort(this.state.sort);
            const tableSortObject = formSortObject(sort, this.props.columnNames);

            const response = await this.props.fetchData(
                sort,
                this.state.page,
                this.state.pagingInfo.recordsNumber,
                this.props.params
            );

            if (!response) {
                this.setState({ dataUpdated: false });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const newData = mapGridResponseData(response.data, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                pagingInfo: newData.pagingInfo,
                dataUpdated: true,
                issuesLoaded: true,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                data: [],
                dataUpdated: true,
                issuesLoaded: true,
                sort: []
            });
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col">
                    <div className="table-container">
                        { this.state.issuesLoaded && (
                            <DataTableComp
                                tableId="table"
                                columns={ this.props.columns }
                                columnDefs={ this.props.columnDefs }
                                orderRule={ [] }
                                data={ this.state.data }
                                customTableClass="cell-border"
                                tableHeight="1200px"
                                handlePreviousPage={ this.handlePreviousPage }
                                handleNextPage={ this.handleNextPage }
                                handleFirstPage={ this.handleFirstPage }
                                handleLastPage={ this.handleLastPage }
                                handlePageChange={ this.handlePageChange }
                                handleRecordsNumber={ this.handleRecordsNumberChange }
                                pagingInfo={ this.state.pagingInfo }
                                addSortingForColumn={ this.addSortingForColumn }
                                sortRule={ this.state.sort.concat([]) }
                                account={ this.context.account }
                            />
                        ) }
                    </div>
                </div>
            </div>
        );
    }
}
