import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import AuthContext from "AuthContext";
import SimpleContainer from 'components/container/SimpleContainer';

import './SnoozeRejectPopup.scss';

export default class SnoozeRejectPopup extends Component {

    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.props.closeModal}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="snooze-reject-modal"
            >
                    <SimpleContainer className="modal-container" modal title="SNOOZE NOTIFICATION">
                        <div className="modal-text">
                            {this.props.text}
                        </div>
                        <Button variant="continue" onClick={this.props.closeModal} className="mr-0">
                            OK
                        </Button>
                    </SimpleContainer>
            </Modal>
        );
    }
}

SnoozeRejectPopup.contextType = AuthContext;
