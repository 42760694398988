import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipComp from 'components/tooltip/TooltipComp';

import './DataCard.scss';

export default class DataCard extends Component {

    render() {
        const {
            count,
            linkTo,
            tooltip,
            title,
            color = 'blue',
            icon,
            tooltipPosition
        } = this.props;

        const content = (
            <div className={`data-card simple-container titled-card ${count > 0 ? color : "disabled-box"}`} onClick={ this.props.onClick }>
                <div className="content">
                    <div className="container-title">
                        <h5 className="title">
                            <span>{ title }</span>
                        </h5>
                        { this.props.tooltip && (
                            <div className="tooltip-container">
                                <TooltipComp description={ tooltip } position={ tooltipPosition } />
                            </div>
                        )}
                    </div>
                </div>
                <div className="status-data">
                    <div className="current-active">
                        { icon && (
                            <span className="icon-box">
                                <FontAwesomeIcon icon={ icon } />
                            </span>
                        )}
                        <span>
                            { count }
                        </span>
                    </div>
                </div>
            </div>
        );

        if (linkTo) {
            return (
                <Link className="no-style-link" to={ linkTo }>
                    { content }
                </Link>
            );
        }

        return content;
    }
}
