import React, { Component } from 'react';

import AuthContext from "AuthContext";
import { formatLongDateWithoutTime, formatTime } from 'global/services/DateTimeService';

import './ResolvedTooltip.scss';

export default class ResolvedTooltip extends Component {

    render() {
        return (
            <div className="resolved-tooltip">
                <div className="arrow-box">
                    <p className="heading">
                        Issue {this.props.resolved ? "Resolved Automatically" : "Closed Manually"}
                    </p>
                    {
                        this.props.resolved &&
                        <>
                            <hr className="divider" />
                            <p className="info-label-heading">
                                Resolved at:
                            </p>
                            <p className="info-label">
                                {formatLongDateWithoutTime(new Date(this.props.resolved))} at {formatTime(new Date(this.props.resolved))}
                            </p>
                        </>
                    }
                    {
                        this.props.closed &&
                        <>
                            <hr className="divider" />
                            <p className="info-label-heading">
                                Closed at:
                            </p>
                            <p className="info-label">
                                {formatLongDateWithoutTime(new Date(this.props.closed.closedAt))} at {formatTime(new Date(this.props.closed.closedAt))}
                            </p>
                        </>
                    }
                    {this.props.closed &&
                        <>
                            <p className="info-label-heading">
                                Closed by:
                            </p>
                            <p className="info-label">
                                {this.props.closed.userName}
                            </p>
                            <p className="info-label-heading">
                                Reason for closing the issue:
                            </p>
                            <p className="info-label">
                                {this.props.closed.closeReason.reason}
                            </p>
                            {this.props.closed.closeReason.extended && this.props.closed.note &&
                                <>
                                    <p className="info-label-heading">
                                        Additional note:
                                    </p>
                                    <p className="info-label">
                                        {this.props.closed.note}
                                    </p>
                                </>
                            }
                        </>
                    }
                    { this.props.resolveReason &&
                        <>
                            <p className="info-label-heading">
                                Resolve reason:
                            </p>
                            <p className="info-label">
                                { this.props.resolveReason }
                            </p>
                        </>
                    }
                </div>
            </div>
        );
    }
}

ResolvedTooltip.contextType = AuthContext;
