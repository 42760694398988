import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import {
    faBookOpen,
    faSlidersH,
    faTachometerAlt,
    faWrench,
    faChartPie,
    faMegaphone,
    faClipboardListCheck,
    faParking,
    faMap,
    faTriangleExclamation,
    faTrailer,
    faClockRotateLeft
} from '@fortawesome/pro-solid-svg-icons';

import CanAccess from "components/can-access/CanAccess";
import { shipexBase64 } from 'global/constants';
import { PERMISSION } from 'global/utils/auth';
import ObjectUtils from 'global/utils/ObjectUtils';
import NavItem from './NavItem';
import AuthContext from "AuthContext";
import UnreadContext from 'UnreadContext';

import './NavBar.scss';

export default class NavBar extends Component {
    static contextType = AuthContext;

    render() {
        return (
            <nav className="nav-bar">
                <div className="container">
                    <div className="top">
                        <NavLink to="/">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" className="logo">
                                <circle cx="20" cy="20" r="20" fill="url(#pattern0)" />
                                <defs>
                                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                                        <use href="#image0" transform="scale(0.00277778)" />
                                    </pattern>
                                    <image id="image0" width="360" height="360" href={ shipexBase64 } />
                                </defs>
                            </svg>
                        </NavLink>

                        <CanAccess
                            action={ [
                                PERMISSION.ISSUE.READ,
                                PERMISSION.ISSUE.POWER_STATE.READ,
                                PERMISSION.ISSUE.TEMPERATURE.READ,
                                PERMISSION.ISSUE.UNIT_MODE.READ,
                                PERMISSION.ISSUE.DATA_ENTRY_ERROR.READ,
                                PERMISSION.ISSUE.SILENT.READ,
                                PERMISSION.ISSUE.LOW_FUEL_ALARM.READ,
                                PERMISSION.ISSUE.DETENTION.READ,
                                PERMISSION.ISSUE.INACTIVITY.READ,
                                PERMISSION.ISSUE.MISSING.READ,
                                PERMISSION.ISSUE.DETACHED.READ,
                                PERMISSION.ISSUE.LOCATION_MISMATCH.READ,
                                PERMISSION.ISSUE.CLAIM.READ,
                            ] }
                            yes={
                                <NavLink to="/dashboard" activeClassName="active">
                                    <NavItem icon={ faTachometerAlt } />
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ PERMISSION.PAGE.ALL_ISSUES }
                            yes={
                                <NavLink to="/issues/all" activeClassName="active">
                                    <NavItem icon={ faTriangleExclamation } />
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ PERMISSION.TRAILER.READ }
                            yes={
                                <NavLink to="/trailers" activeClassName="active">
                                    <NavItem icon={ faTrailer } />
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ [
                                PERMISSION.ISSUE.READ,
                                PERMISSION.ISSUE.ALARM.READ,
                                PERMISSION.ISSUE.LOW_FUEL_ALARM.LOCAL.READ
                            ] }
                            yes={
                                <NavLink to="/issues/alarm" activeClassName="active">
                                    <NavItem icon={ faWrench } />
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ PERMISSION.STATISTICS.READ }
                            yes={
                                <NavLink to="/statistics/all-teams" activeClassName="active">
                                    <NavItem icon={ faChartPie } />
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ ObjectUtils.getValuesByKey(PERMISSION.TRAILER_AUDIT, "READ")}
                            yes={
                                <NavLink to="/trailer-audit" activeClassName="active">
                                    <NavItem icon={ faClipboardListCheck } />
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ PERMISSION.EVENT.READ }
                            yes={
                                <NavLink to="/events">
                                    <NavItem icon={ faClockRotateLeft } />
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.INACTIVE_IN_YARD.READ] }
                            yes={
                                <NavLink to="/issues/inactive-in-yard" activeClassName="active">
                                    <NavItem icon={ faParking } />
                                </NavLink>
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.PAGE.TRAILER_MAP] }
                            yes={
                                <NavLink to="/trailer-map" activeClassName="active">
                                    <NavItem icon={ faMap } />
                                </NavLink>
                            }
                        />
                    </div>
                    <div className="bottom">
                        <CanAccess
                            action={ PERMISSION.PAGE.HELP }
                            yes={
                                <NavLink to="/help" activeClassName="active">
                                    <NavItem icon={ faBookOpen } />
                                </NavLink>
                            }
                        />

                        <UnreadContext.Consumer>
                            { ({ data }) => (
                                <CanAccess
                                    action={ PERMISSION.RELEASE_NOTES.READ }
                                    yes={
                                        <NavLink
                                            to="/release-notes"
                                            activeClassName="active"
                                            className={ (data.releaseNotes || {}).read ? '' : 'unread' }
                                        >
                                            <NavItem icon={ faMegaphone } />
                                        </NavLink>
                                    }
                                />
                            ) }
                        </UnreadContext.Consumer>

                        <CanAccess
                            action={ ObjectUtils.getValuesByKey(PERMISSION.SETTINGS, "READ") }
                            yes={
                                <NavLink to="/settings" activeClassName="active">
                                    <NavItem icon={ faSlidersH } />
                                </NavLink>
                            }
                        />
                    </div>
                </div>
            </nav>
        );
    }
}
