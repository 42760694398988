import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import Button from "react-bootstrap/Button";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft as leftArrowIcon,
    faArrowsRotate as refreshIcon,
    faArrowUpRightFromSquare as linkIcon,
    faBuilding,
    faDownload as downloadIcon,
    faMapMarkerAlt
} from "@fortawesome/pro-solid-svg-icons";

import QueryParamService from "global/services/QueryParamService";
import {
    getDefaultTrailerTableColumns,
    getDropYardTrailerTableColumns,
    getTotalTrailerTableColumns,
    getYardTrailerTableColumns,
    getRepairTrailerTableColumns,
    getUnaccountedTrailerTableColumns,
    getHomeTimeTrailerTableColumns,
    getSingleDropYardTrailerTableColumns
} from "global/services/TrailerTableColumnsProvider";
import { formatDateTimeNumeric } from "global/services/DateTimeService";
import mapGridResponseData from "global/utils/mapGridResponseData";
import formSortObject from "global/utils/formSortObject";
import {
    mapSort,
    moreGridRecordsNumber,
    reportUrl,
    setupTemporaryAuditInterval,
    summaryReportUrl,
    yardUrl
} from "global/constants";
import { mapTrailersFromResponse } from "global/services/TrailerReportApiService";
import history from "global/history";
import {PERMISSION} from "global/utils/auth";
import CanAccess from "components/can-access/CanAccess";
import DataTableComp from "components/datatable/DataTableComp";
import ConfirmPopup from "components/popup/ConfirmPopup";
import Message from "components/Message";
import { reactPlugin } from "AppInsights";
import AuthContext from "AuthContext";

import "./TrailerTable.scss";

class TrailerTable extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.initialState = {
            data: [],
            sort: [],
            loading: true,
            initialLoad: false,
            showConfirmPopup: false,
            pagingInfo: {
                recordsNumber: moreGridRecordsNumber,
                totalItemNumber: 0
            },
            csvLoading: false
        }

        const { trailerTable, title, page } = this.prepareInitialState();

        this.state = {
            ...this.initialState,
            title,
            page: page ? parseInt(page) : 1,
            columnNames: trailerTable.columnNames,
            columns: trailerTable.columns,
            columnDefs: trailerTable.columnDefs
        }

        const locationState = this.props.location.state;
        if (this.props.match.params.yardId && locationState) {
            this.state.companyName = locationState.name;
            this.state.companyAddress = locationState.fullAddress;
        }

        this.onRefreshAuditClick = this.onRefreshAuditClick.bind(this);
        this.onCloseConfirmModal = this.onCloseConfirmModal.bind(this);
        this.fetchTemporaryAudit = this.fetchTemporaryAudit.bind(this);
    }

    async componentDidMount() {
        if (this.props.match.params.yardId && !this.props.location.state) {
            await this.fetchYard();
        }

        this.fetchTrailers();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.previousTeams !== this.context.team) {
            this.previousTeams = this.context.team;
            this.handleFirstPage();
        }

        if (this.props.category !== prevProps.category || this.props.match.params.yardId !== prevProps.match.params.yardId) {
            const { trailerTable, title } = this.prepareInitialState();

            if (this.props.match.params.yardId) {
                const locationState = this.props.location.state
                if (locationState) {
                    this.setState({
                        title: locationState.businessId,
                        companyName: locationState.name,
                        companyAddress: locationState.fullAddress,
                    });
                } else {
                    await this.fetchYard();
                }
            } else {
                this.setState({ title });
            }

            this.setState({
                ...this.initialState,
                page: 1,
                columnNames: trailerTable.columnNames,
                columns: trailerTable.columns,
                columnDefs: trailerTable.columnDefs
            }, () => this.fetchTrailers(1));
        }

        if (prevState.temporaryReport !== this.state.temporaryReport) {
            this.checkForTemporaryAuditInterval = setupTemporaryAuditInterval(this.state.temporaryReport,
                this.state.temporary,
                this.fetchTemporaryAudit,
                this.checkForTemporaryAuditInterval
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.checkForTemporaryAuditInterval);
    }

    getTrailerTableColumns() {
        if (this.props.all) {
            return getTotalTrailerTableColumns(this.context.permissions);
        } else if (this.props.atYard) {
            return getYardTrailerTableColumns(this.context.permissions);
        } else if (this.props.match.params.yardId) {
            return getSingleDropYardTrailerTableColumns(this.context.permissions);
        } else if (this.props.atDropYard) {
            return getDropYardTrailerTableColumns(this.context.permissions);
        } else if (this.props.repair) {
            return getRepairTrailerTableColumns(this.context.permissions);
        } else if (this.props.unaccounted) {
            return getUnaccountedTrailerTableColumns(this.context.permissions);
        } else if (this.props.homeTime) {
            return getHomeTimeTrailerTableColumns(this.context.permissions);
        }else {
            return getDefaultTrailerTableColumns(this.context.permissions);
        }
    }

    prepareInitialState() {
        let trailerTable = this.getTrailerTableColumns();

        let title = this.props.title;
        if (this.props.match.params.yardId && this.props.location.state) {
            title = this.props.location.state.businessId;
        } else if (this.props.match.params.yardId) {
            title = '';
        }

        const page = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page');

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        this.previousTeams = this.context.team;

        return { trailerTable, title, page };
    }

    async fetchYard() {
        try {
            const response = await this.context.get(`${yardUrl}/${this.props.match.params.yardId}`, {});

            if (response.status !== "error") {
                const company = response.company;

                this.setState({
                    title: company.businessId,
                    companyName: company.name,
                    companyAddress: company.fullAddress
                });
            }
        } catch (error) {
            console.error('Error while fetching yard.', error);
        }
    }

    async fetchTrailers(pageNumber) {
        this.setState({
            loading: true
        });

        const queryString = window.location.search.slice(1);
        const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(queryString);
        const tableSortObject = formSortObject(sort, this.state.columnNames);

        try {
            const params = {
                all: this.props.all,
                dispatch: this.props.dispatch,
                homeTime: this.props.homeTime,
                atYard: this.props.atYard,
                atDropYard: this.props.atDropYard,
                repair: this.props.repair,
                oneway: this.props.oneway,
                unaccounted: this.props.unaccounted,
                yardId: this.props.match.params.yardId
            };

            const response = await this.context.get(`${reportUrl}/${this.props.match.params.id}/trailers`, {
                ...params,
                pageNumber: pageNumber || this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber,
                sort
            });

            const state = this.props.location.state;

            let temporary;
            let reportCreated;
            let newerTemporaryExists;

            if (state && state.time && state.newerTemporaryExists && state.temporary) {
                temporary = state.temporary;
                reportCreated = state.time;
                newerTemporaryExists = state.newerTemporaryExists;
            } else {
                const reportDetails = await this.context.get(summaryReportUrl, { reportId: this.props.match.params.id });
                temporary = reportDetails.temporary;
                reportCreated = reportDetails.time;
            }

            if (!state || !state.time || !state.newerTemporaryExists || !state.temporary) {
                const temporaryReport = await this.fetchTemporaryAudit();
                const latestReport = await this.context.get(summaryReportUrl, { temporary: false });

                newerTemporaryExists = temporaryReport.time > latestReport.time;
            }

            if (response.status === "error" || !response.data) {
                const statusCode = response.message.response.status;

                if (404 === statusCode) {
                    history.push({
                        pathname: '/trailer-audit',
                    });
                    return;
                }

                this.setState({
                    loading: false,
                });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const trailers = mapTrailersFromResponse(response.data, params, this.props.category, this.props.title);

            this.setState({
                data: trailers,
                newerTemporaryExists,
                reportCreated,
                loading: false,
                initialLoad: true,
                pagingInfo: mapGridResponseData(trailers, response).pagingInfo,
                sort: tableSortObject,
                breadcrumb: " for " + formatDateTimeNumeric(new Date(reportCreated)),
                temporary
            });
        } catch (error) {
            console.error('Error while fetching report trailers.', error);
            this.setState({
                loading: false,
                sort: tableSortObject
            });
        }
    }

    async handlePage(page) {
        this.setState({
            page: page,
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchTrailers();
            this.setState(prevState => ({
                pagingInfo: {
                    ...prevState.pagingInfo,
                    currentPage: prevState.page
                }
            }));
        });
    }

    handlePreviousPage = () => {
        this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = () => {
        this.handlePage(parseInt(this.state.page) + 1);
    }

    handleFirstPage = () => {
        this.handlePage(1);
    }

    handleLastPage = () => {
        this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState(prevState => ({
            pagingInfo: {
                ...prevState.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
        }), () => this.fetchTrailers());
    }

    handlePageChange = async (event) => {
        const value = event.target.value;
        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchTrailers();
            }, 1000);
        }
        this.setState(prevState => ({
            pagingInfo: {
                ...prevState.pagingInfo,
                currentPage: prevState.page
            }
        }));
    }

    addSortingForColumn(index, sorting) {
        this.setState(prevState => {
            const sortingArray = prevState.sort;
            const existing = sortingArray.findIndex(x => x && x.columnName === this.state.columnNames[index]);

            if (existing === -1) {
                sortingArray.push({
                    columnIndex: index,
                    columnName: this.state.columnNames[index],
                    direction: sorting
                });
            } else if (sorting === "") {
                sortingArray.splice(existing, 1);
            } else {
                sortingArray[existing] = {
                    columnIndex: index,
                    columnName: this.state.columnNames[index],
                    direction: sorting
                };
            }

            const queryString = window.location.search.slice(1);
            QueryParamService.addSortToQueryString(queryString, sortingArray);

            return {
                sort: sortingArray,
                dataUpdated: false
            };
        }, () => this.fetchTrailers());
    }

    async exportCsv() {
        try {
            this.setState({
                csvLoading: true
            });

            const queryString = window.location.search.slice(1);
            const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(queryString);

            await this.context.download(`${reportUrl}/${this.props.match.params.id}/export`, {
                all: this.props.all,
                dispatch: this.props.dispatch,
                homeTime: this.props.homeTime,
                atYard: this.props.atYard,
                atDropYard: this.props.atDropYard,
                repair: this.props.repair,
                oneway: this.props.oneway,
                unaccounted: this.props.unaccounted,
                yardId: this.props.match.params.yardId,
                sort
            });
        } catch (error) {
            console.error('Error while downloading report trailers csv.', error);
        } finally {
            this.setState({
                csvLoading: false
            });
        }
    }

    async fetchTemporaryAudit() {
        const response = await this.context.get(`${reportUrl}/temporary`, { });

        this.setState({
            temporaryReport: response
        });

        return response;
    }

    async onRefreshAuditClick() {
        await this.context.post(`${reportUrl}/temporary`, {});
    }

    onCloseConfirmModal(confirmRefresh) {
        this.setState({
            showConfirmPopup: false
        });
        if (confirmRefresh) {
            history.push({
                pathname: "/trailer-audit"
            });
        }
    }

    render() {
        let infoMessage = '';
        let linkButton = '';
        if (this.state.temporary) {
            infoMessage = 'This is a manually generated Temporary Trailer Audit and its action functionality may be disabled for some audit categories.';
            linkButton = (
                <Link className="refresh-button" to={ `/trailer-audit` }>
                    <FontAwesomeIcon icon={ leftArrowIcon } className="icon" />
                    BACK TO TRAILER AUDIT
                </Link>
            );
        } else if (this.state.temporaryReport && !this.state.temporaryReport.completed) {
            infoMessage = 'The Trailer Audit is currently being updated. This may take a couple of minutes.';
        } else if (this.state.temporaryReport && this.state.temporaryReport.completed) {
            infoMessage = `A new temporary Trailer Audit has been manually generated at ${formatDateTimeNumeric(new Date(this.state.temporaryReport.time))}.`;
            linkButton = (
                <Link className="refresh-button" to={ `/trailer-audit/${this.state.temporaryReport.id}` }>
                    <FontAwesomeIcon icon={ linkIcon } className="icon" />
                    OPEN TEMPORARY AUDIT
                </Link>
            );
        }

        const disableRefresh = !this.state.temporary && this.state.temporaryReport && !this.state.temporaryReport.completed;

        const showTemporaryReportMessage = this.state.initialLoad && (this.state.temporary || this.state.newerTemporaryExists);

        return (
            <div className="page report-trailers">
                { showTemporaryReportMessage && (
                    <Message>
                        <div className="message">
                            { infoMessage }
                            { linkButton }
                        </div>
                    </Message>
                ) }
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={ "/trailer-audit/" + this.props.match.params.id }>
                                { this.state.temporary && "Temporary" } Trailer Audit { this.state.breadcrumb }
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to={ window.location.pathname }>
                                { this.state.title }
                            </Link>
                        </li>
                    </ol>
                </nav>
                <div className={ `heading-div ${this.props.match.params.yardId ? "yard-heading" : ""}` }>
                    <p className="heading">
                        { this.state.title }
                        &nbsp;
                        { !this.state.loading && (
                            <span className="trailer-count">
                                ({ this.state.pagingInfo.totalItemNumber } trailer{ this.state.pagingInfo.totalItemNumber !== 1 && "s" })
                            </span>
                        ) }
                        <Loader
                            className="align-self-center"
                            type="TailSpin"
                            color="#289AC2"
                            height={ 36 }
                            width={ 36 }
                            visible={ this.state.loading }
                        />
                    </p>
                    { this.state.data.length > 0 && this.state.initialLoad &&
                        <div className="d-flex">
                            { this.state.initialLoad && (
                                <CanAccess
                                    action={ [PERMISSION.TRAILER_AUDIT.REFRESH] }
                                    yes={
                                        <button className={ `refresh-button ${disableRefresh && "disabled"}` }
                                                onClick={ () => this.setState({ showConfirmPopup: true }) }
                                                disabled={ disableRefresh }>
                                            <FontAwesomeIcon icon={ refreshIcon } className="icon" />
                                            REFRESH
                                        </button>
                                    }
                                />
                            ) }
                            <Button variant="continue" onClick={ this.exportCsv.bind(this) }>
                                <FontAwesomeIcon icon={ downloadIcon } className="add-icon" />
                                Export CSV
                                <Loader
                                    className="csv-loader"
                                    type="TailSpin"
                                    color="white"
                                    height={ 13 }
                                    width={ 13 }
                                    visible={ this.state.csvLoading }
                                />
                            </Button>
                        </div>
                    }
                </div>
                { this.props.match.params.yardId &&
                    <div className="yard-info">
                        <FontAwesomeIcon icon={ faBuilding } className="yard-icon"/>
                        Company: <span className="yard-text">{ this.state.companyName }</span>
                        <FontAwesomeIcon icon={ faMapMarkerAlt } className="yard-icon"/>
                        Location: <span className="yard-text">{ this.state.companyAddress }</span>
                    </div>
                }
                <div className="report-trailers-div">
                    { this.state.initialLoad && (
                        <DataTableComp
                            tableId="reportTrailers"
                            columns={ this.state.columns}
                            columnDefs={ this.state.columnDefs }
                            data={ this.state.data }
                            customTableClass="cell-border"
                            tableHeight="1200px"
                            handlePreviousPage={ this.handlePreviousPage }
                            handleNextPage={ this.handleNextPage }
                            handleFirstPage={ this.handleFirstPage }
                            handleLastPage={ this.handleLastPage }
                            handleRecordsNumber={ this.handleRecordsNumber }
                            handlePageChange={ this.handlePageChange }
                            pagingInfo={ this.state.pagingInfo }
                            sortRule={ this.state.sort.concat([]) }
                            addSortingForColumn={ this.addSortingForColumn.bind(this) }
                            account={ this.props.account }
                            category={ this.props.category }
                        />
                    ) }
                </div>
                <ConfirmPopup
                    closeModal={ this.onCloseConfirmModal }
                    onConfirm={ this.onRefreshAuditClick }
                    showModal={ this.state.showConfirmPopup }
                    title="REFRESH AUDIT"
                    subtitle="Refresh Audit?"
                >
                    Are you sure you want to initiate the refresh procedure for the Trailer Audit?
                    <br /><br />This manually generated Audit is read-only and will not be stored in the Audit History. You will still be able to access the current Trailer Audit via side navigation bar.
                </ConfirmPopup>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, TrailerTable));
