import React, { Component } from 'react';
import TermsConditionsPopup from "components/popup/TermsConditionsPopup";

class TermsConditions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            didAgreeToConditions: !!localStorage.getItem("didAgreeToConditions")
        }
    }

    setAgreedToConditions() {
        this.setState({ didAgreeToConditions: true });
    }

    render() {
        return (
            <>
                { this.state.didAgreeToConditions ?
                    this.props.children :
                    <TermsConditionsPopup updateParentState={ this.setAgreedToConditions.bind(this) } />
                }
            </>
        )
    }
}

export default TermsConditions;
