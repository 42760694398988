import React, { Component } from 'react';
import {
    faCheck as resolutionIcon,
    faClock as periodicCheckIcon,
    faList as trailerMeasurementIcon,
    faLocationDot as locationIcon,
    faPlus as creationIcon,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandableCard from 'components/card/ExpandableCard';

import "./HelpCenter.scss";

export default class FleetOverviewHelp extends Component {
    render() {
        return (
            <>
                <div className="tab-description">
                    The dashboard provides insights into issues detected by the system. Each issue has a lifecycle:
                    <ol>
                        <li>
                            <b>Creation</b> - each issue type has some logic that determines when to create the issue. A trigger for issue creation can be a received trailer measurement from TK, TMW update or some periodic check
                        </li>
                        <br />
                        <li>
                            <b>Update</b> - each issue has some data that is updated over time. A trigger for an update can also be TK, TMW or some period check
                        </li>
                        <br />
                        <li>
                            <b>Resolution</b> - each issue type has some logic that determines when to resolve the issue. Resolved issues fall into the <i>Irrelevant issues</i> tab. A trigger for resolving an issue can also be TK, TMW or some period check. Some issues can be moved to the irrelevant issues tab before they are resolved if the system detects they are no longer relevant (e.g. an order has finished, the trailer is no longer active etc.)
                        </li>
                    </ol>
                </div>

                <ExpandableCard title="Power issues">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        A power issue is only created when the trailer is on an active leg. The issue gets created after receiving a trailer measurement from TK where the trailer power does not match the expected power. The expected power can be:
                        <ul>
                            <li>
                                <b>ON</b> - if trailer is on an active order and the reefer min and max temperature is entered and not equal to 999 on TMW
                            </li>
                            <li>
                                <b>OFF</b> - if trailer is not on an active order or if the min or max temperature entered on TMW is equal to 999
                            </li>
                        </ul>
                        An issue will not be created if the trailer is loading or unloading. We detect this by checking whether the trailer is located near a stop on the active leg with an LLD or LUL stop event or PUP or DRP stop type. The issue will also not be created if the trailer is on a oneway leg.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        A power issue is resolved after we receive a trailer measurement from TK with the power matching the expected power.
                    </div>
                </ExpandableCard>


                <ExpandableCard title="Unauthorized use">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        An unauthorized use issue is only created when the trailer is not on an active order and not on an active leg. The issue gets created after receiving a trailer measurement from TK where the power is ON and the trailer status is AVL.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        An unauthorized use issue is resolved after we receive a trailer measurement from TK with the power OFF.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Temperature issues">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        A temperature issue is only created when the trailer is on an active leg. The issue gets created after receiving a trailer measurement from TK where the set point is not within the configured min and max temperature values in TMW.
                        <br />
                        <br />
                        An issue will not be created if the trailer is loading or unloading. We detect this by checking whether the trailer is located near a stop on the active leg with an LLD or LUL stop event or PUP or DRP stop type. The issue will also not be created if the trailer is on a oneway leg.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        A temperature issue is resolved after we receive a trailer measurement from TK where the set point is within the configured min and max temperature values in TMW.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Mode issues">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        A mode issue is only created when the trailer is on an active leg. The issue gets created after receiving a trailer measurement from TK where the unit mode is different from the unit mode configured on TMW in the <i>legheader.lgh_type3</i> field. There are two different modes: CYCLE-SENTRY and CONTINUOUS.
                        <br />
                        <br />
                        An issue will not be created if the trailer is loading or unloading. We detect this by checking whether the trailer is located near a stop on the active leg with an LLD or LUL stop event or PUP or DRP stop type. The issue will also not be created if the trailer is on a oneway leg.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        A mode issue is resolved after we receive a trailer measurement from TK where the unit mode is the same as the unit mode configured on TMW.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Data entry issues">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        A data entry issue gets created after receiving an order from TMW with invalid temperature or unit mode configuration. There are multiple ways this can happen:
                        <ul>
                            <li>
                                Min or max temperature is set as 111 on TMW
                            </li>
                            <li>
                                Min temperature is set as 999 and max temperature is not set as 999 or vice versa
                            </li>
                            <li>
                                Min temperature is greater than the max temperature
                            </li>
                            <li>
                                The difference between min and max temperature is greater than 20°F
                            </li>
                            <li>
                                Unit mode is not entered on TMW
                            </li>
                        </ul>
                        An issue will not be created if the min or max temperature is set as 111 on TMW, but the trailer has not been loaded yet. We detect this by checking whether the trailer has departed from a stop with an HPL or LLD stop event. The issue will also not be created if the trailer is on a oneway leg.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        A data entry issue is resolved after we receive a valid temperature configuration from TMW.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Fuel level issues">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        A fuel level issue is only created when the trailer is on an active leg. The issue gets created after receiving a trailer measurement from TK that contains alarm code 96.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        A fuel level issue is resolved after we receive a trailer measurement from TK without this alarm code.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Detained trailers">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        A detained trailer issue is only created when the trailer is on an active leg. The issue gets created after a trailer has been stationary (in a radius of 0.5 miles) at an LLD or LUL TMW stop company of the current order for at least 2 hours. In addition to this, the reefer unit must be turned on.
                        <br />
                        <br />
                        The issue will not be created if the trailer is at the ShipEX yard. The issue will also not be created if the trailer is on a oneway leg.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        A detention issue is resolved after we receive a trailer measurement from TK where the trailer is not located at any TMW company.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Detached trailers">
                    <div>
                        A detached trailer issue can be created by a trailer measurement from TK, update from TMW on the truck location or by a periodic check. It can get resolved only by a trailer measurement from TK or an update from TMW on the truck location.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ trailerMeasurementIcon } className="title-icon" />
                        Trailer measurement
                    </h6>
                    <div className="section">
                        After receiving a trailer measurement from TK we fetch the last leg the trailer was on. We first check whether an issue already exists on that trailer.
                        <br />
                        <br />
                        If it exists, the issue can either be updated or resolved. If the leg changed since the issue was created and the last trailer leg is active then we can safely resolve the issue. If the last trailer leg is not active or if the leg did not change, we consider the driver on the issue still responsible. We fetch the driver location. If the driver location is within 500m of the trailer or if the trailer is in a yard we resolve the issue. Otherwise, we will update the issue.
                        <br />
                        <br />
                        If there is not an existing trailer issue, we check whether the last trailer leg is inactive and whether the driver on that leg is on home time. If the last trailer leg is inactive and the driver is on home time we know that the driver is responsible for this trailer and that they should be together. We only compare the driver and trailer locations if the trailer is stationary. A trailer is considered stationary if it did not leave a radius of 50 meters for at least 30 minutes. If the trailer is stationary we check whether the trailer and driver are within 500m or if the trailer is in a yard. If these conditions are satisfied the trailer is safe, if not, an issue is created.
                    </div>
                    <img src={ process.env.PUBLIC_URL + '/detached-missing-issue-trailer-logic.png' }
                         alt="Detached/missing issue trailer logic" />
                    <h6>
                        <FontAwesomeIcon icon={ locationIcon } className="title-icon" />
                        TMW truck location change
                    </h6>
                    <div className="section">
                        After receiving an update on the truck location from TMW (driver measurement) we fetch the last leg the trailer was on. We first check whether an issue already exists on that trailer.
                        <br />
                        <br />
                        If it exists, the issue can either be updated or resolved. If the leg changed since the issue was created and the last trailer leg is active then we can safely resolve the issue. If the last trailer leg is not active or if the leg did not change, we consider the driver on the issue still responsible. We fetch the latest trailer measurement. If the driver location is within 500m of the trailer or if the trailer is in a yard we resolve the issue. Otherwise, we will update the issue.
                        <br />
                        <br />
                        If there is not an existing trailer issue, we check whether the last trailer leg is inactive and whether the driver on that leg is on home time. If the last trailer leg is inactive and the driver is on home time we know that the driver is responsible for this trailer and that they should be together. In order to continue the duration from the received driver measurement and latest trailer measurement needs to be at least 1h 15min. This is to prevent creating an issue in situations where they were parked together and then start moving, but the system still has not received an updated trailer location. We only compare the driver and trailer locations if the trailer is stationary. A trailer is considered stationary if it did not leave a radius of 50 meters for at least 30 minutes. If the trailer is stationary we check whether the trailer and driver are within 500m or if the trailer is in a yard. If these conditions are satisfied the trailer is safe, if not, an issue is created.
                    </div>
                    <img src={ process.env.PUBLIC_URL + '/detached-missing-issue-truck-logic.png' }
                         alt="Detached/missing issue truck logic" />
                    <h6>
                        <FontAwesomeIcon icon={ periodicCheckIcon } className="title-icon" />
                        Periodic check
                    </h6>
                    <div className="section">
                        Since it can happen that a trailer stops reporting to us, after a certain period, we need to treat this as a stationary trailer. We figured out that the optimal threshold for this needs to be 2h 30min.
                        <br />
                        <br />
                        Periodically, we will go through every driver who is on home time, fetch the latest leg he was on, check the trailer on that leg and fetch the latest leg that trailer was on. If both, the driver's latest leg and trailer's latest leg are the same, the driver is still responsible for that trailer. We then check whether the leg is inactive and whether the driver is on home time. If so, we check whether the trailer and driver are within 500m or if the trailer is in a yard. If these conditions are satisfied the trailer is safe, if not, an issue is created if it does not exist already.
                    </div>
                    <img src={ process.env.PUBLIC_URL + '/detached-missing-issue-periodic-logic.png' }
                         alt="Detached/missing issue periodic logic" />
                </ExpandableCard>

                <ExpandableCard title="Missing trailers">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        A missing trailer issue gets created when we do not receive a trailer measurement from TK for 24h for a trailer that has an existing detached issue. This means that a detached issue becomes a missing issue after 24h without trailer measurements.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        Missing issues are resolved in the same way as detached issues.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Mismatched trailers">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        A mismatched trailer issue gets created if the location of the trailer in TMW does not match the location reported via TK. First, the trailer must not be on an active leg and the trailer status must not be STD or PEND. Then, we fetch the last stop the trailer was on for which:
                        <ul>
                            <li>
                                The stop arrival date is in the past
                            </li>
                            <li>
                                Order is not canceled (order status is not CAN or ICO)
                            </li>
                            <li>
                                Assignment is not planned (assignment status is not PLN)
                            </li>
                        </ul>
                        If this stop is on a oneway leg or if the assignment is not completed (assignment status is not CMP) we do not create an issue. Otherwise, we compare the location of the stop company and the trailer location. If they are more than 1 mile apart, we create an issue.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        A mismatched trailer issue is resolved once the locations in TMW and TK match up (closer than 1 mile).
                    </div>
                    <img src={ process.env.PUBLIC_URL + '/mismatched-trailer-logic.png' }
                         alt="Mismatched trailer logic" />
                </ExpandableCard>

                <ExpandableCard title="Trailers with claim">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        A trailer with claim issue gets created after receiving a TMW update where all of the following conditions are satisfied:
                        <ul>
                            <li>
                                The trailer is on an active leg
                            </li>
                            <li>
                                The leg status is not CMP (completed)
                            </li>
                            <li>
                                <i>legType2</i> field of this leg is ACC, CLAIM or OSD
                            </li>
                            <li>
                                There is a stop on this leg with one of the following stop events: LUL, LLD, HPL, HLT, DRL or DLT
                            </li>
                        </ul>
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        A trailer with claim issue is resolved if either of these conditions are no longer satisfied:
                        <ul>
                            <li>
                                <i>legType2</i> field of this leg is ACC, CLAIM or OSD
                            </li>
                            <li>
                                There is a stop on this leg with one of the following stop events: LUL, LLD, HPL, HLT, DRL or DLT
                            </li>
                        </ul>
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Alarm issues">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        An alarm issue gets created after receiving a trailer measurement from TK that contains an alarm code. Each alarm code is a separate issue. The only alarm codes that do not trigger creating an alarm issue are:
                        <ul>
                            <li>
                                0 - alarm code is ignored
                            </li>
                            <li>
                                96 - alarm code triggers a fuel level issue instead
                            </li>
                        </ul>
                        A special <i>No satellite coverage</i> alarm issue is created after receiving a trailer measurement from TK with no trailer position data.
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        An alarm issue is resolved after receiving a trailer measurement from TK without the alarm code that triggered the issue. <i>No satellite coverage</i> alarm issue is resolved after receiving a trailer measurement from TK that contains trailer position data.
                    </div>
                </ExpandableCard>

                <ExpandableCard title="Inactive in yard">
                    <h6>
                        <FontAwesomeIcon icon={ creationIcon } className="title-icon" />
                        Creation
                    </h6>
                    <div className="section">
                        An inactive in yard issue gets created if a trailer has been stationary at a drop yard or at the ShipEX yard for over 4 days. In addition to this, the trailer needs to be available (trailer status is AVL) and the last leg the trailer was on needs to satisfy all of the following conditions:
                        <ul>
                            <li>
                                Leg is not active
                            </li>
                            <li>
                                Leg assignment status is CMP (completed)
                            </li>
                            <li>
                                Leg end time is in the past
                            </li>
                            <li>
                                Corresponding order is not active
                            </li>
                        </ul>
                    </div>
                    <h6>
                        <FontAwesomeIcon icon={ resolutionIcon } className="title-icon" />
                        Resolution
                    </h6>
                    <div className="section">
                        An inactive in yard issue is resolved if the trailer is no longer located at the drop yard or if any of the above conditions are no longer satisfied.
                    </div>
                </ExpandableCard>
            </>
        )
    }
}
