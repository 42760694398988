import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SimpleContainer from 'components/container/SimpleContainer';

import './ConfirmPopup.scss';

export default class ConfirmPopup extends Component {
    async handleConfirm() {
        await this.props.onConfirm();

        this.props.closeModal(true);
    }

    onClose = () => this.props.closeModal()

    render() {
        return (
            <Modal
                show={ this.props.showModal }
                onHide={ this.onClose }
                backdrop="static"
                keyboard={ false }
                centered
                dialogClassName="confirm-modal"
            >
                <SimpleContainer className="modal-container" modal title={ this.props.title }>
                    <div className="warning-header">
                        { this.props.subtitle }
                    </div>
                    <div className="warning-text">
                        { this.props.children }
                    </div>
                    <div className="buttons">
                        <Button variant="light" onClick={ this.onClose } className="cancel-button">Cancel</Button>
                        <Button variant="continue" className="confirm-button" onClick={ this.handleConfirm.bind(this) }>Confirm</Button>
                    </div>
                </SimpleContainer>
            </Modal>
        );
    }
}
