import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import AuthContext from 'AuthContext';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import { SortParam, snoozedIssuesDetailsUrl } from 'global/constants';
import { formatDate, getFullDifferenceBetweenDates } from 'global/services/DateTimeService';
import { issueTypeMap } from 'global/services/IssueTypeService';
import IssuesTable from '../issues-table/IssuesTable';

class SnoozedIssues extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            0: SortParam.ASSIGNMENT,
            1: SortParam.TRAILER,
            2: SortParam.ISSUE_TYPE,
            3: SortParam.SNOOZED_BY,
            4: SortParam.SNOOZED_AT,
            5: SortParam.SNOOZED_FOR
        };

        this.columns = [
            { title: 'Order #', data: 'orderId' },
            { title: 'Trailer #', data: 'trailerId' },
            { title: 'Issue Type', data: 'type' },
            { title: 'Snoozed By', data: 'snoozedBy' },
            { title: 'Snoozed At', data: 'snoozedAt' },
            { title: 'Snoozed For', data: 'snoozedFor' },
            { title: 'Snooze Reason', data: 'snoozeReason' }
        ];

        this.columnDefs = [
            { width: '10%', targets: [0, 1, 2, 5] },
            { width: '15%', targets: [3, 4] },
            { width: '30%', targets: [6] },
            { className: 'dt-align-right', targets: [0, 1] },
            {
                orderable: false,
                targets: [6]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 6,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <div className="no-flex reason-text">
                        { rowData.snoozeReason }
                    </div>,
                    td
                )
            }
        ];

        this.fetchIssues = this.fetchIssues.bind(this);
    }

    async fetchIssues(sort, filter, currentPage, pageSize) {
        const response = await this.context.get(snoozedIssuesDetailsUrl, {
            startTimeFrom: filter.startTime.toDate(),
            endTimeTo: filter.endTime.toDate(),
            types: filter.issueTypes,
            teams: filter.teams,
            sort: sort,
            pageNumber: currentPage,
            pageSize: pageSize
        });

        if (response.status === 'error' || !response.data) {
            return;
        }

        const normalizedIssues = response.data.map(obj => {
            const snoozeStart = new Date(obj.snoozeStart);
            const snoozeEnd = new Date(obj.snoozeEnd);

            return {
                orderId: obj.assignmentBusinessId || 'No order',
                trailerId: obj.trailerBusinessId,
                snoozedBy: obj.snoozedBy,
                snoozeReason: obj.note ? `${obj.reason} (${ obj.note })` : obj.reason,
                snoozedAt: formatDate(snoozeStart),
                snoozedFor: getFullDifferenceBetweenDates(snoozeStart, snoozeEnd),
                type: issueTypeMap(obj.issueType),
                onClickState: {
                    trailerId: obj.trailerId,
                    assignmentId: obj.assignmentId
                }
            };
        });

        return {
            ...response,
            data: normalizedIssues
        };
    }

    render() {
        return (
            <IssuesTable
                title="Snooze History"
                columns={ this.columns }
                columnDefs={ this.columnDefs }
                columnNames={ this.columnNames }
                fetchIssues={ this.fetchIssues }
            />
        );
    }
}

export default SnoozedIssues;
