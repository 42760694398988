import React, { Component } from 'react';
import SimpleContainer from 'components/container/SimpleContainer';
import StackedGroupedBar from '../StackedGroupedBar'
import UserInfoCard from './UserInfoCard'
import StatsFilter from "../StatsFilter"
import { getCurrentDate, getFullDifferenceBetweenDates, getMonthsBefore } from 'global/services/DateTimeService';
import TeamIcon from 'components/team-icon/TeamIcon'
import { singleUser, TEAMS } from "global/constants"
import { withRouter } from "react-router";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { countSeenByUser, countSnoozeByUser, countCloseByUser, avgSeenDuration, userActivity, mapDivisionToColor, allUsers, userTeams } from "global/constants"
import history from 'global/history'
import AuthContext from 'AuthContext'
import { issueTypes } from "global/services/IssueTypeService";
import Loader from 'react-loader-spinner';

import "../Statistics.scss"

const avgIssueResolutionKeysDefault = [
  { name: "seenDuration", label: "Average issue resolution time", stackId: "seenDuration", color: "#172437" },
]

const seenCountKeysDefault = [
  { name: "seenCount", label: "Seen count", stackId: "seenCount", color: "#172437" },
]

const snoozeCountKeysDefault = [
  { name: "snoozeCount", label: "Snooze count", stackId: "snoozeCount", color: "#172437" },
]

const closeCountKeysDefault = [
  { name: "closeCount", label: "Close count", stackId: "closeCount", color: "#172437" },
]
const formatter = (value) => `${(value)} min`;

const data = Object.keys(issueTypes).filter(key => key !== "DEFAULT").map(key => ({label: key}));

class UserStats extends Component {
    static contextType = AuthContext;
    constructor(props) {
      super(props);
      this.state = {
        userPage: 1,
        filter: {
          startTime: getCurrentDate(getMonthsBefore(Date.now(), 1)),
          endTime: getCurrentDate(Date.now()),
          teams: [],
          issueTypes: [],
          users: []
        },
        seenDurationData: data,
        closeData: data,
        snoozeData: data,
        seenData: data,
        seenCountKeys: seenCountKeysDefault,
        avgIssueResolutionKeys: avgIssueResolutionKeysDefault,
        snoozeCountKeys: snoozeCountKeysDefault,
        closeCountKeys: closeCountKeysDefault
      }
    }
    async componentDidMount() {
      if(this.props.match && this.props.match.params.user) {
        if(!this._isUUID(this.props.match.params.user)) {
          history.replace("/404");
        }
        this.setState({
            userName: this.props.location.state ? this.props.location.state.userName : "",
            userId: this.props.match.params.user,
            team: this.props.location.state ? this.props.location.state.team || null : null,
            filter: {
              ...this.state.filter,
              teams: Array.from([])
            },
            data: data
        }, async () => await this.fetchStatistics());
      }
    }

    async componentDidUpdate(prevProps) {
      if(this.props.match && this.props.match.params && this.props.match.params.user && JSON.stringify(this.props.match.params.user) !== JSON.stringify(prevProps.match.params.user))
      {
        let newTypes = [];
        let newData = [];

        if(this.state.filter.issueTypes && this.state.filter.issueTypes.length === 0) {
          newData = data.map((type => ({...type, disabled: false})));
        } else {
          newTypes = this.state.filter.issueTypes;
          newData = data.map(type => newTypes.indexOf(type.label) === -1 ? {...type, disabled: true} : {...type, disabled: false});
        }

        this.setState({
            userId: this.props.match.params.user,
            userName: this.props.location.state ? this.props.location.state.userName : "",
            filter: {
              ...this.state.filter,
              teams: Array.from([])
            },
            team: this.props.location.state ? this.props.location.state.team || null : null,
            data: newData,
            searchName: null,
            userPage: this.state.userPage || 1,
            statisticsLoaded: false,
            usersUpdated: false
        }, async () => await this.fetchStatistics());
      }

    }

    _isUUID(s){
      return s.match("^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$");
    }

    userSelected(user) {
      history.replace({
        pathname: "/statistics/user/" + user.id,
        state: {
          userName: user.name
        }
      })
    }

    async fetchStatistics() {
        if (!this.props.location.state) {
            await this.fetchUser();
        }
        await this.fetchUserActivity();
        await this.fetchSeenCount();
        await this.fetchSnoozeCount();
        await this.fetchCloseCount();
        await this.fetchAvgSeenDuration();
        await this.fetchUserTeams();
        await this.fetchAllUsers();
        this.setState({
          statisticsLoaded: true
        })
    }

    async fetchUser() {
        const response = await this.context.get(singleUser, {
            id: this.props.match.params.user
        });

        if (response.status === "error") {
            console.error(response.message);
            return;
        }

        this.setState({
            userName: response.name
        });
    }

    async fetchUserTeams() {
      const response = await this.context.get(userTeams, {
        user: this.state.userId
      });

      if (response.status === "error") {
        console.error(response.message);
        return {};
      }
      this.setState({
        teams: (response || []).map(r => r.name)
      })
    }

    async fetchAllUsers() {
      const response = await this.context.get(allUsers, {
        searchName: this.state.searchUser,
        pageNumber: this.state.userPage || 1,
        pageSize: 20
      });

      if (response.status === "error") {
        console.error(response.message);
        return {};
      }

      this.setState({
        searchUser: null,
        users: response,
        usersUpdated: true
      });

      return response;
    }

    async fetchMoreUsers(page) {
      const promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));
      await promisedSetState({userPage: page || this.state.userPage + 1, usersUpdated: false});
      return await this.fetchAllUsers();
    }

    async searchUser(searchUser) {
      const promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));
      await promisedSetState({searchUser, userPage: 1, usersUpdated: false});
      return await this.fetchAllUsers();
    }

    async fetchUserActivity() {
      const response = await this.context.get(userActivity, {
        userId: this.state.userId,
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: Array.from(this.state.filter.teams)
      });

      if (response.status === "error") {
        console.error(response.message);
        history.replace({
          pathname: "/404"
        });
        return {};
      }

      this.setState({
        seenCount: response.seenCount,
        snoozeCount: response.snoozeCount,
        closeCount: response.closeCount
      })
    }

    async fetchSeenCount() {
      const response = await this.context.get(countSeenByUser, {
        userId: this.state.userId,
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: Array.from(this.state.filter.teams)
      });

      if (response.status === "error") {
        console.error(response.message);
        history.replace({
          pathname: "/404"
        });
        return {};
      }

      let seenCountKeys = []
      let seenData = [];

      response.forEach(element => {
        seenCountKeys.push({
          name: element.divisionBusinessId,
          label: (TEAMS.find(team => team.name === element.divisionBusinessId) || {label: ""}).label,
          stackId: "seenCount",
          color: mapDivisionToColor(element.divisionBusinessId)
        });
        seenData = data.map(type => ({
          ...seenData.find(d => d.label === type.label),
          label: type.label,
          [element.divisionBusinessId] : (element.countByType.find(d => d.issueType === type.label) || {}).count || 0
        }))
      })
      this.setState({
        seenCountKeys: seenCountKeys.length > 0 ? seenCountKeys : seenCountKeysDefault,
        seenData: seenData.length > 0 ? seenData : data
      })
    }

    async fetchSnoozeCount() {
      const response = await this.context.get(countSnoozeByUser, {
        userId: this.state.userId,
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: Array.from(this.state.filter.teams)
      });
      if (response.status === "error") {
        console.error(response.message);
        return {};
      }
      let snoozeCountKeys = []
      let snoozeData = [];

      response.forEach(element => {
        snoozeCountKeys.push({
          name: element.divisionBusinessId,
          label: (TEAMS.find(team => team.name === element.divisionBusinessId) || {label: ""}).label,
          stackId: "snoozeCount",
          color: mapDivisionToColor(element.divisionBusinessId)
        });
        snoozeData = data.map(type => ({
          ...snoozeData.find(d => d.label === type.label),
          label: type.label,
          [element.divisionBusinessId] : (element.countByType.find(d => d.issueType === type.label) || {}).count || 0
        }))
      })
      this.setState({
        snoozeCountKeys: snoozeCountKeys.length > 0 ? snoozeCountKeys : snoozeCountKeysDefault,
        snoozeData: snoozeData.length > 0 ? snoozeData : data
      })
    }

    async fetchCloseCount() {

      const response = await this.context.get(countCloseByUser, {
        userId: this.state.userId,
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: Array.from(this.state.filter.teams)
      });
      if(response.status === "error") {
        return {};
      }
      let closeCountKeys = []
      let closeData = [];

      response.forEach(element => {
        closeCountKeys.push({
          name: element.divisionBusinessId,
          label: (TEAMS.find(team => team.name === element.divisionBusinessId) || {label: ""}).label,
          stackId: "closeCount",
          color: mapDivisionToColor(element.divisionBusinessId)
        });
        closeData = data.map(type => ({
          ...closeData.find(d => d.label === type.label),
          label: type.label,
          [element.divisionBusinessId] : (element.countByType.find(d => d.issueType === type.label) || {}).count || 0
        }))
      })
      this.setState({
        closeCountKeys: closeCountKeys.length > 0 ? closeCountKeys : closeCountKeysDefault,
        closeData: closeData.length > 0 ? closeData : data
      })
    }

    async fetchAvgSeenDuration() {
      const response = await this.context.get(avgSeenDuration, {
        userId: this.state.userId,
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: Array.from(this.state.filter.teams)
      });
      if (response.status === "error") {
        console.error(response.message);
        return {};
      }
      let avgIssueResolutionKeys = []
      let seenDurationData = [];

      response.forEach(element => {
        avgIssueResolutionKeys.push({
          name: element.divisionBusinessId,
          label: (TEAMS.find(team => team.name === element.divisionBusinessId) || {label: ""}).label,
          stackId: "seenDuration",
          color: mapDivisionToColor(element.divisionBusinessId)
        });
        seenDurationData = data.map(type => ({
          ...seenDurationData.find(d => d.label === type.label),
          label: type.label,
          [element.divisionBusinessId] : (element.timeByType.find(d => d.issueType === type.label) || {}).avgResolutionTime || 0
        }))
      })
      this.setState({
        avgIssueResolutionKeys: avgIssueResolutionKeys.length > 0 ? avgIssueResolutionKeys : avgIssueResolutionKeysDefault,
        seenDurationData: seenDurationData.length > 0 ? seenDurationData : data
      })
    }



    onFilterChange(selectedTeams, selectedTypes, selectedStartDate, selectedEndDate) {

      // select issue types
      let newTypes = [];
      let newData = [];

      if (JSON.stringify(this.state.filter.issueTypes || []) !== JSON.stringify(selectedTypes || [])) {
        if(selectedTypes && selectedTypes.length === 0) {
          newData = this.state.data.map((type => ({...type, disabled: false})));
        } else {
          newTypes = selectedTypes;
          newData = this.state.data.map(type => newTypes.indexOf(type.label) === -1 ? {...type, disabled: true} : {...type, disabled: false});
        }
      }

      if(selectedTeams.indexOf("ALL") !== -1 || !selectedTeams || selectedTeams.length === 0) {
          selectedTeams = [];
      }

      if(selectedTypes.indexOf("ALL") !== -1) {
        selectedTypes = [];
      }

      this.setState({
        data: newData,
        filter : {
          ...this.state.filter,
          teams: selectedTeams,
          startTime: selectedStartDate && this.state.filter.startTime !== selectedStartDate ? selectedStartDate : this.state.filter.startTime,
          endTime: selectedEndDate && this.state.filter.endTime !== selectedEndDate ? selectedEndDate : this.state.filter.endTime,
          issueTypes: selectedTypes,
          statisticsLoaded: false,
          usersUpdated: false
        }
      }, async () => await this.fetchStatistics())
    }



    render(){
      const team = this.state.team || (this.state.teams && this.state.teams[0]);

      return (
        <div className="container-fluid page statistics">
            <div className="row page-heading-row">
              <nav className="col-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/statistics/all-teams">All teams stats</a></li>
                    { team ?
                        <li className="breadcrumb-item">
                            <a href={ team ? ("/statistics/team/" + team) : "/statistics/all-teams" }>
                                { (TEAMS.find(t => t.name === team)).label }
                            </a>
                        </li>
                    : null }
                    <li className="breadcrumb-item active"><a href="#!">{this.state.userName}</a></li>
                  </ol>
              </nav>
              <div className="inline">
                <div className="pageHeading col-12">
                  { this.state.teams && this.state.teams.length > 1 && <TeamIcon color="ALL" size={36}></TeamIcon> }
                  { this.state.teams && this.state.teams.length === 1 && <TeamIcon color={this.state.teams[0]} size={36}></TeamIcon> }
                  { this.state.team && (!this.state.teams || this.state.teams.length < 1) && <TeamIcon color={this.state.team} size={36}></TeamIcon> }
                  <span className={`text with-margin ${this.state.teams && this.state.teams.length > 1 ? "middle-align" : ""}`}>
                    {this.state.userName}
                  </span>
                  <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.statisticsLoaded || !this.state.usersUpdated}/>
                </div>
                </div>
                <StatsFilter userSelected={this.userSelected.bind(this)} searchUser={this.searchUser.bind(this)} fetchMoreUsers={this.fetchMoreUsers.bind(this)} filterUser={this.state.userName} users={this.state.users} filter={this.state.filter} onFilterChange={this.onFilterChange.bind(this)}></StatsFilter>
              </div>
              <UserInfoCard teams={this.state.teams} team={this.state.team} seenCount={this.state.seenCount} snoozeCount={this.state.snoozeCount} closeCount={this.state.closeCount} avgResolutionTime={this.state.avgSeenDuration}></UserInfoCard>

            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <SimpleContainer className="issues-orders-stats" title="SEEN ISSUES">
                    <StackedGroupedBar simpleXAxis={true} barSize={10} data={this.state.seenData} dataKeys={this.state.seenCountKeys} timeFrame={getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime)}></StackedGroupedBar>
                  </SimpleContainer>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pull-right">
                  <SimpleContainer className="issues-orders-stats" title="FROM SEEN TO RESOLVE">
                    <StackedGroupedBar r minuteBar={true} yTickFormatter={formatter} simpleXAxis={true} barSize={10} data={this.state.seenDurationData} dataKeys={this.state.avgIssueResolutionKeys} timeFrame={getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime)}></StackedGroupedBar>
                  </SimpleContainer>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <SimpleContainer className="issues-orders-stats" title="SNOOZED ISSUES">
                    <StackedGroupedBar simpleXAxis={true} barSize={10}  data={this.state.snoozeData} dataKeys={this.state.snoozeCountKeys} timeFrame={getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime)}></StackedGroupedBar>
                  </SimpleContainer>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <SimpleContainer className="issues-orders-stats" title="CLOSED ISSUES">
                    <StackedGroupedBar simpleXAxis={true} barSize={10}  data={this.state.closeData} dataKeys={this.state.closeCountKeys} timeFrame={getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime)}></StackedGroupedBar>
                  </SimpleContainer>
                </div>
            </div>
          </div>
        );
    }
}


export default withRouter(withAITracking(reactPlugin, UserStats, "UserStats"));
;