import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Loader from 'react-loader-spinner';
import { withRouter } from 'react-router';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Link } from 'react-router-dom';

import { reactPlugin } from 'AppInsights';
import AuthContext from 'AuthContext';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import TeamIcon from 'components/team-icon/TeamIcon';
import { Table } from 'components/table/Table';
import { assignmentStateSearchUrl, SortParam } from 'global/constants';
import QueryParamService from 'global/services/QueryParamService';
import formSortObject from 'global/utils/formSortObject';

import './AllTrailers.scss';

class AllTrailers extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            0: SortParam.ASSIGNMENT,
            1: SortParam.TRAILER,
            2: SortParam.DRIVER,
            3: SortParam.DIVISION,
            4: SortParam.LEG,
            5: SortParam.ACTIVE
        };

        this.columns = [
            { title: 'Order #', data: 'orderId' },
            { title: 'Trailer #', data: 'trailerId' },
            { title: 'Driver', data: 'driverName' },
            { title: 'Division', data: 'division' },
            { title: 'Leg #', data: 'legId' },
            { title: 'Leg status', data: 'legStatus' }
        ];

        this.columnDefs = [
            { width: '10%', targets: [0, 1] },
            { width: '15%', targets: [3, 4, 5] },
            { width: '35%', targets: [2] },
            { className: 'dt-align-right', targets: [0, 1] },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 2,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <div className="no-flex">
                        { rowData.driverName }
                    </div>,
                    td
                )
            },
            {
                targets: 3,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <div className="no-flex">
                        { this.getDivisionColumnString(rowData.division) }
                    </div>,
                    td
                )
            },
            {
                targets: 5,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <div className={ `bold ${ rowData.legStatus === 'Active' ? 'greenColorText' : 'greyColorText' }` }>
                        { rowData.legStatus }
                    </div>,
                    td
                )
            }
        ];

        this.state = {
            isLoading: false
        };

        this.fetchTrailers = this.fetchTrailers.bind(this);
    }

    getDivisionColumnString(division) {
        if (!division.businessId) {
            return '-';
        } else {
            return (
                <div className="d-flex align-items-center">
                    <TeamIcon color={ division.businessId } size={ 14 } />
                    &nbsp; &nbsp;
                    { division.contactInfo.name }
                </div>
            );
        }
    }

    async fetchTrailers(sort, pageNumber, pageSize) {
        this.setState({ isLoading: true });
        const response = await this.context.get(assignmentStateSearchUrl, { sort, pageNumber, pageSize });

        this.setState({ isLoading: false });

        if (response.status === 'error' || !response.data) {
            return {
                data: []
            };
        }

        QueryParamService.addSortToQueryString(window.location.search.slice(1), formSortObject(sort, this.columnNames));
        QueryParamService.addPageToQueryString(window.location.search.slice(1), pageNumber);

        return {
            ...response,
            data: this.mapResponseToTrailers(response.data)
        };
    }

    mapResponseToTrailers(assignmentStates) {
        return assignmentStates.map(state => {
            const leg = state.leg || {};
            const driver = leg.driver || {};

            let legStatus;
            if (leg.active === true) {
                legStatus = 'Active';
            } else if (leg.active === false) {
                legStatus = 'Inactive';
            } else {
                legStatus = '-';
            }

            return {
                orderId: state.assignment ? state.assignment.businessId : 'No order',
                trailerId: state.trailer.businessId,
                driverName: driver.contactInfo ? driver.contactInfo.name : 'Unknown',
                division: driver.division || {},
                legId: leg.businessId || '-',
                legStatus: legStatus,
                onClickState: { trailerId: state.trailer.id, assignmentId: (leg.assignment || {}).id }
            };
        });
    }

    render() {
        return (
            <div className="container-fluid page all-trailers">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="#">
                                        All trailers
                                    </Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        <div className="d-flex">
                            <div className="heading">
                                All trailers
                            </div>
                            &nbsp; &nbsp;
                            <Loader
                                type="TailSpin"
                                color="#289AC2"
                                height={ 47 }
                                width={ 47 }
                                visible={ this.state.isLoading }
                            />
                        </div>
                    </div>
                </div>
                <Table
                    columns={ this.columns }
                    columnDefs={ this.columnDefs }
                    columnNames={ this.columnNames }
                    fetchData={ this.fetchTrailers }
                />
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, AllTrailers, 'AllTrailers'));
