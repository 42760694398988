import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import config from 'config';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

const {
    APP_INSIGHTS_INSTRUMENTATION_KEY,
    APP_INSIGHTS
} = config;

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: APP_INSIGHTS_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

if (APP_INSIGHTS.toLowerCase() === "enabled") appInsights.loadAppInsights();
export { reactPlugin, appInsights };
