import React, { Component, createRef } from 'react';
import './SimpleTooltip.scss';

/**
 * The Tooltip Component.
 * Handles displaying tooltips in the specified direction and with the specified alignment. 
 * The parent element of a tooltip must have a position attribute set.
 */
export default class SimpleTooltip extends Component {

    // static propTypes = {
    //     direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    //     align: PropTypes.oneOf(['left-align', 'right-align', 'center-align'])
    // }

    static defaultProps = {
        direction: 'right',
        align: 'center-align'
    }

    _ref = createRef();

    componentDidMount() {
        window.addEventListener('resize', this._windowDidResize.bind(this));
        this._windowDidResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._windowDidResize.bind(this));
    }

    _windowDidResize() {
        const element = this._ref.current;

        if (!element) {
            return;
        }

        const arrowElement = element.children.item(0);

        const tooltipRect = element.getBoundingClientRect();
        const viewportWidth = window.innerWidth;

        // Leaves the viewport on the left edge
        if (tooltipRect.x < 0) {
            const amount = -tooltipRect.x + 14;
            element.style.transform = SimpleTooltip._getTransformStyle(element) + ' translate(' + amount + 'px)';
            arrowElement.style.transform = SimpleTooltip._getTransformStyle(arrowElement) + ' translate(-' + amount + 'px)';
        }

        // Leaves the viewport on the right edge
        if (tooltipRect.width + tooltipRect.x > viewportWidth) {
            const amount = ((tooltipRect.width + tooltipRect.x) - viewportWidth) + 14;
            element.style.transform = SimpleTooltip._getTransformStyle(element) + ' translate(-' + amount + 'px)';
            arrowElement.style.transform = SimpleTooltip._getTransformStyle(arrowElement) + ' translate(' + amount + 'px)';
        }
    }

    static _getTransformStyle(element) {
        const transform = getComputedStyle(element)['transform'];
        return transform !== 'none' ? transform : '';
    }

    render() {
        const className = 'simple-tooltip ' + this.props.direction + ' ' + this.props.align + ' ' + (this.props.className ? ' ' + this.props.className : '');

        return (
            <div ref={ this._ref } className={ className } onClick={ e => e.stopPropagation() }>
                <div className="tooltip-arrow" />
                <div className="tooltip-content">
                    { this.props.children }
                </div>
            </div>
        );
    }
}
