import React, { Component } from 'react';
import './Pill.scss';

export default class Pill extends Component {

    state = {
        checked: !!(this.props || {}).checked
    }

    static defaultProps = {
        checked: false,
        onChange: (checked) => {}
    }

    toggle(external, event) {
        if (!this.props.disabled && this.props.manualChange) {
            this.props.onChange(this.state.checked);
        } else if (!this.props.disabled) {
            this.setState(
                state => ({ checked: !state.checked }),
                () => this.props.onChange(this.state.checked)
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.checked !== prevProps.checked) {
          this.setState({
              checked: this.props.checked
          })
        }
    }

    getClassName() {
        let className = "pill-container";
        if (this.props.uncheckedGreyColor && !this.state.checked) {
            className += " grey-pill-container";
        }
        if (this.props.disabled) {
            className += " disabled";
        }
        return className;
    }

    render() {
        return (
            <div
                className={this.getClassName()}
                onClick={this.toggle.bind(this, false)}
            >
                <div className={"pill " + (this.state.checked ? 'checked' : '')}></div>
            </div>
        );
    }
}
