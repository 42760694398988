import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faComment,
    faExclamationCircle,
    faEye,
    faInfoCircle,
    faTimesCircle,
    faAlarmSnooze,
    faQuestionCircle
} from '@fortawesome/pro-solid-svg-icons';
import $ from 'jquery';

import AuthContext from 'AuthContext';
import { reactPlugin } from 'AppInsights';
import CanAccess from 'components/can-access/CanAccess';
import SimpleContainer from 'components/container/SimpleContainer';
import DataTableComp from 'components/datatable/DataTableComp';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import ActiveSelection from 'components/input/ActiveSelection';
import TeamIcon from 'components/team-icon/TeamIcon';
import { defaultGridRecordsNumber, issuesDetailsUrl, mapSort, SortParam } from 'global/constants';
import { getCurrentDate as currentDate, getDifferenceBetweenDates } from 'global/services/DateTimeService';
import { mapIssuesFromResponse } from 'global/services/IssueApiService';
import { issueSnoozeable } from 'global/services/IssueTypeService';
import QueryParamService from 'global/services/QueryParamService';
import { PERMISSION } from 'global/utils/auth';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import deleteComment from 'global/utils/deleteComment';
import formSortObject from 'global/utils/formSortObject';
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import './ClaimIssues.scss';

const CACHE = {};

class ClaimIssues extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super (props, context);
        
        this.columnNames = {
            0: SortParam.TRAILER,
            1: SortParam.ASSIGNMENT,
            2: SortParam.COMPANY,
            3: SortParam.DRIVER,
            5: SortParam.START_TIME
        }

        this.columns = [
            { title: "Trailer #", data: "trailerId" },
            { title: "Latest Order #", data: "orderId" },
            { title: "Customer ID", data: "customerId"},
            { title: "Driver", data: "driver" },
            { title: "Last known position", data: "address" },
            { title: "Created", data: "created" },
            { title: "Last departure date", data: "lastDepartureDate" },
            { title: "Alerts", data: "alerts" },
            { title: "Resolved?", data: "resolved" },
            { title: "Action", data: "action" }
        ];

        this.columnDefs = [
            { width: "5%", "targets": [0, 1, 9] },
            { width: "10%", "targets": [2, 3, 5, 6, 7, 8] },
            { width: "25%", "targets": [4] },
            { className: "dt-align-center", targets: [9] },
            { className: "dt-align-right", targets: [0, 1] },
            {
                orderable: false,
                targets: [4, 6, 7, 8, 9]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="centerAlign">
                            { rowData.driver !== "Unknown" && rowData.driver !== "-" && (
                                <TeamIcon style={ { marginRight: 6 } } color={ rowData.division } />
                            ) }
                            { rowData.driver }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="long-column">
                            { rowData.address }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            { rowData.lastDepartureDate }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData) => {
                    let className = "";
                    let otherText = "";
                    let coloredText;

                    if (!rowData.alerts) {
                        className += " redColorText";
                        coloredText = "No";
                        otherText = `(for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`;
                    } else if (rowData.alerts === "Escalation") {
                        coloredText = "Escalation"
                        className = "bold"
                    } else if (rowData.alerts === "Warning") {
                        coloredText = "Warning";
                        className = "bold"
                    }

                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }

                    ReactDOM.render(
                        <div className="notifications">
                            <div className={ `notification-level bold ${className}` }>
                                { coloredText }
                            </div>
                            <div className="notification-info">
                                <FontAwesomeIcon icon={ faInfoCircle } className="info-icon" style={ !rowData.alerts ? { display: "none" } : { marginLeft: 4 } } />
                            </div>
                            { otherText && (
                                <div style={ { marginRight: 4, marginLeft: 4 } } className="greyColorText italic snoozed-info">
                                    { otherText }
                                </div>
                            ) }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData) => {
                    let className = "bold";
                    let otherText;
                    let coloredText;

                    if (!rowData.resolved) {
                        className += " redColorText";
                        coloredText = "No";
                        otherText = `(for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                    } else {
                        className += " greenColorText";
                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.resolved)})`;
                        }
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <span className={ className }>
                                <FontAwesomeIcon icon={ rowData.resolved ? faCheckCircle : faExclamationCircle } />
                                <span style={ { marginRight: 4, marginLeft: 4 } }>
                                    { coloredText }
                                </span>
                                <FontAwesomeIcon icon={ faInfoCircle } className="info-icon" style={ !rowData.resolved ? { display: "none" } : {} } />
                            </span>
                            <span style={ { marginRight: 4, marginLeft: 4 } } className="greyColorText italic">
                              { otherText }
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass;
                    let snoozeIconClass;

                    const issueSnoozeableCode = issueSnoozeable(rowData.issue);
                    if (issueSnoozeableCode === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeableCode === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon";
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;

                    ReactDOM.render(
                        <div className="action-items">
                            <div className="seen" id={ rowData.id }>
                                <FontAwesomeIcon icon={ faEye } className={ seenIconClass } />
                            </div>
                            <CanAccess
                                permissions={ context.permissions }
                                action={ [PERMISSION.ISSUE.SNOOZE, PERMISSION.ISSUE.CLAIM.SNOOZE] }
                                yes={
                                    <div className={ snoozeDivClass }>
                                        { snoozeIconClass === "disabled-icon" ?
                                            <svg width="16" height="12" style={ { marginTop: "2px" } } viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z" />
                                                <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z" />
                                                <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z" />
                                                <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z" />
                                                <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z" />
                                                <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z" />
                                            </svg>
                                            : <FontAwesomeIcon icon={ faAlarmSnooze } className={ snoozeIconClass } />
                                        }
                                    </div>
                                }
                            />
                            <div className="comment">
                                <FontAwesomeIcon icon={ faComment } className={ commentIconClass } />
                            </div>
                            <CanAccess
                                permissions={ context.permissions }
                                action={ [PERMISSION.ISSUE.CLOSE, PERMISSION.ISSUE.CLAIM.CLOSE] }
                                yes={
                                    <div className={ !isRelevant ? "disabled-resolve" : "resolve" }>
                                        <FontAwesomeIcon icon={ faTimesCircle } className={ !isRelevant ? "disabled-icon" : "resolve-icon" } />
                                    </div>
                                }
                            />
                            <CanAccess
                                permissions={ context.permissions } 
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <div>
                                        <ReeferControlSvg />
                                    </div> 
                                }
                            />
                        </div>,
                        td
                    );
                } 
            }
        ];

        let relevantIssues = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : undefined;

        let buttonNode;
        if (!!relevantIssues || relevantIssues === undefined) {
            relevantIssues = true;
            buttonNode = $(`button[id=relevant]`);
        } else {
            relevantIssues = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addActiveLegToQueryString(window.location.search.slice(1), relevantIssues);

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        const page = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page');

        this.state = {
            data: [],
            columns: this.columns,
            columnDefs: this.columnDefs,
            sort: [],
            issuesLoaded: false,
            showModal: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).claimIssues !== undefined ? JSON.parse(localStorage.getItem('showModals')).claimIssues : true,
            doNotShowTutorial: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).claimIssues !== undefined ? !JSON.parse(localStorage.getItem('showModals')).claimIssues : false,
            activeLeg: relevantIssues,
            page: page ? parseInt(page) : 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            }
        }

        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
        this.openTutorialModal = this.openTutorialModal.bind(this);
        this.changeActiveness = this.changeActiveness.bind(this);
        this.addSortingForColumn = this.addSortingForColumn.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        
        this.handleFirstPage = this.handleFirstPage.bind(this);
        this.handleLastPage = this.handleLastPage.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleRecordsNumber = this.handleRecordsNumber.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        this.fetchAllIssues();
    }

    componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            this.handlePage(1, true);
        }
    }

    async fetchAllIssues(force) {
        let cachingIndex = this.context.team.slice().join('_');
        cachingIndex += this.state.activeLeg ? '_relevant' : '_irrelevant';

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        const queryString = window.location.search.slice(1);
        const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(queryString);
        const tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }

            const response = await this.context.get(issuesDetailsUrl, {
                types: ["CLAIM"],
                active: this.state.activeLeg,
                sort: sort,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                console.error(response.message);
                this.setState({
                    notificationsLoaded: true,
                    dataUpdated: true,
                    issuesLoaded: true
                });

                return;
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    notificationsLoaded: true,
                    dataUpdated: true,
                    issuesLoaded: true
                });

                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const issues = mapIssuesFromResponse(data);
            const newData = mapGridResponseData(issues, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                issuesLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                issuesLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const existing = sortingArray.findIndex(x => x && x.columnName === this.columnNames[index]);

        if (existing === -1) {
            sortingArray.push({
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            });
        } else if (sorting === "") {
            sortingArray.splice(existing, 1);
        } else {
            sortingArray[existing] = {
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            }
        }

        const queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, () => this.fetchAllIssues());
    }

    changeActiveness(relevantIssues) {
        const queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevantIssues);

        this.setState({
            activeLeg: relevantIssues,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber,
            },
            dataUpdated: false
        }, () => this.fetchAllIssues());
    }

    onReceiveNewComment(comment) {
        const newData = this.state.data;
        newData.find(data => data.issue.id === comment.issue.id).comments.unshift(comment);

        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        this.setState({
            allIssues: deleteComment(deletedComment, this.state.data)
        });
    }

    async handlePage(page, force) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchAllIssues(force);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
        
    }

    handlePreviousPage() {
        this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage() {
        this.handlePage(parseInt(this.state.page) + 1);
    }

    handleFirstPage() {
        this.handlePage(1);
    }

    handleLastPage() {
        this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    async handleLastAvailablePage(lastAvailablePage) {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber(event) {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
            dataUpdated: false
        }, () => this.fetchAllIssues());
    }

    handlePageChange(event) {
        const value = event.target.value;
        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(function() {
                that.fetchAllIssues();
            }, 1000);
        }
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    onCheckboxChange() {
        this.setState({
            doNotShowTutorial: !this.state.doNotShowTutorial
        });
    }

    openTutorialModal() {
        this.setState({
            showModal: true
        });
    }

    handleClose() {
        const existingItem = JSON.parse(localStorage.getItem('showModals'));
        localStorage.setItem('showModals', JSON.stringify({...existingItem, ...{claimIssues: !this.state.doNotShowTutorial }}));
        this.setState({
            showModal: false
        });
    }

    render() {
        let tutorial;

        if (!this.state.doNotShowTutorial || this.state.showModal) {
            tutorial = (
                <Modal
                    show={ this.state.showModal }
                    onHide={ this.handleClose }
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={ false }
                    className="tutorial-modal-claim"
                >
                    <SimpleContainer className="tutorial-modal-claim" title="TRAILERS WITH CLAIM TUTORIAL" modal>
                        <Modal.Body className="tutorial-modal-claim">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <svg 
                                                width="32" 
                                                height="32" 
                                                viewBox="0 0 32 32" 
                                                fill="none" 
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path 
                                                    d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" 
                                                    fill="#828A95"
                                                />
                                            </svg>
                                        </td>
                                        <td style={ { marginLeft: "20%" } } colSpan="2">
                                            <p className="modalText" style={ { paddingBottom: 4 } }>
                                                STEP 1:
                                            </p>
                                            <p className="modalText">
                                                Call driver and get more information.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="vl" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <svg 
                                                width="36" 
                                                height="33" 
                                                viewBox="0 0 36 33" 
                                                fill="none" 
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path 
                                                    d="M31.5 5.25H26.4375L25.875 3.70312C25.1719 1.94531 23.4844 0.75 21.6562 0.75H14.3438C12.5156 0.75 10.8281 1.94531 10.125 3.63281L9.5625 5.25H4.5C2.03906 5.25 0 7.28906 0 9.75V27.75C0 30.2109 2.03906 32.25 4.5 32.25H31.5C33.9609 32.25 36 30.2109 36 27.75V9.75C36 7.28906 33.9609 5.25 31.5 5.25ZM32.625 27.75C32.625 28.3828 32.1328 28.875 31.5 28.875H4.5C3.86719 28.875 3.375 28.3828 3.375 27.75V9.75C3.375 9.11719 3.86719 8.625 4.5 8.625H11.8828L12.7266 6.44531L13.2891 4.82812C13.5 4.40625 13.9219 4.125 14.3438 4.125H21.6562C22.0781 4.125 22.5 4.40625 22.7109 4.82812L23.2734 6.44531L24.1172 8.625H31.5C32.1328 8.625 32.625 9.11719 32.625 9.75V27.75ZM18 10.875C13.6406 10.875 10.125 14.3906 10.125 18.75C10.125 23.1094 13.6406 26.625 18 26.625C22.3594 26.625 25.875 23.1094 25.875 18.75C25.875 14.3906 22.3594 10.875 18 10.875ZM18 23.25C15.5391 23.25 13.5 21.2109 13.5 18.75C13.5 16.2891 15.5391 14.25 18 14.25C20.4609 14.25 22.5 16.2891 22.5 18.75C22.5 21.2109 20.4609 23.25 18 23.25Z" 
                                                    fill="#828A95"
                                                />
                                            </svg>
                                        </td>
                                        <td style={ { marginLeft: "20%" } } colSpan="2">
                                            <p className="modalText" style={ { paddingBottom: 4 } }>
                                                STEP 2:
                                            </p>
                                            <p className="modalText">
                                                Have driver take pictures and/or video of the product or incident.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="vl" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <svg 
                                                width="27" 
                                                height="36" 
                                                viewBox="0 0 27 36" 
                                                fill="none" 
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path 
                                                    d="M5.625 18V22.5C5.625 23.7656 6.60938 24.75 7.875 24.75H19.125C20.3906 24.75 21.375 23.7656 21.375 22.5V18C21.375 16.7344 20.3906 15.75 19.125 15.75H7.875C6.60938 15.75 5.625 16.7344 5.625 18ZM25.6641 6.53906L20.4609 1.33594C19.6172 0.492188 18.4219 0 17.2266 0H4.5C2.03906 0 0 2.03906 0 4.5V31.5C0 33.9609 2.03906 36 4.5 36H22.5C24.9609 36 27 33.9609 27 31.5V9.77344C27 8.57812 26.5078 7.38281 25.6641 6.53906ZM23.625 31.5C23.625 32.1328 23.1328 32.625 22.5 32.625H4.5C3.86719 32.625 3.375 32.1328 3.375 31.5V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H15.75V9C15.75 10.2656 16.7344 11.25 18 11.25H23.625V31.5ZM6.75 9H12.375C13.0078 9 13.5 8.50781 13.5 7.875C13.5 7.24219 13.0078 6.75 12.375 6.75H6.75C6.11719 6.75 5.625 7.24219 5.625 7.875C5.625 8.50781 6.11719 9 6.75 9ZM6.75 13.5H12.375C13.0078 13.5 13.5 13.0078 13.5 12.375C13.5 11.7422 13.0078 11.25 12.375 11.25H6.75C6.11719 11.25 5.625 11.7422 5.625 12.375C5.625 13.0078 6.11719 13.5 6.75 13.5ZM20.25 27H14.625C13.9922 27 13.5 27.4922 13.5 28.125C13.5 28.7578 13.9922 29.25 14.625 29.25H20.25C20.8828 29.25 21.375 28.7578 21.375 28.125C21.375 27.4922 20.8828 27 20.25 27Z" 
                                                    fill="#828A95"
                                                />
                                            </svg>
                                        </td>
                                        <td style={ { marginLeft: "20%" } } colSpan="2">
                                            <p className="modalText" style={ { paddingBottom: 4 } }>
                                                STEP 3:
                                            </p>
                                            <p className="modalText">
                                                Make sure there is a clear notation on the BOL's.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="vl" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <svg 
                                                width="32" 
                                                height="32" 
                                                viewBox="0 0 32 32" 
                                                fill="none" 
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path 
                                                    d="M28 0H4C1.79375 0 0 1.79375 0 4V22C0 24.2062 1.79375 26 4 26H10V31.25C10 31.6938 10.3625 32 10.75 32C10.9 32 11.0563 31.9563 11.1938 31.85L19 26H28C30.2062 26 32 24.2062 32 22V4C32 1.79375 30.2062 0 28 0ZM29 22C29 22.55 28.55 23 28 23H18L17.2 23.6L13 26.75V23H4C3.45 23 3 22.55 3 22V4C3 3.45 3.45 3 4 3H28C28.55 3 29 3.45 29 4V22ZM23 8.5H9C8.45 8.5 8 8.95 8 9.5V10.5C8 11.05 8.45 11.5 9 11.5H23C23.55 11.5 24 11.05 24 10.5V9.5C24 8.95 23.55 8.5 23 8.5ZM17 14.5H9C8.45 14.5 8 14.95 8 15.5V16.5C8 17.05 8.45 17.5 9 17.5H17C17.55 17.5 18 17.05 18 16.5V15.5C18 14.95 17.55 14.5 17 14.5Z" 
                                                    fill="#828A95"
                                                />
                                            </svg>
                                        </td>
                                        <td style={ { marginLeft: "20%" } } colSpan="2">
                                            <p className="modalText" style={ { paddingBottom: 4 } }>
                                                STEP 4:
                                            </p>
                                            <p className="modalText">
                                                Update TMW with any relevant information or notes.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="vl" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <svg 
                                                width="32" 
                                                height="32" 
                                                viewBox="0 0 32 32" 
                                                fill="none" 
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path 
                                                    d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" 
                                                    fill="#828A95"
                                                />
                                            </svg>
                                        </td>
                                        <td style={ { marginLeft: "20%" } } colSpan="2">
                                            <p className="modalText" style={ { paddingBottom: 4 } }>
                                                STEP 5:
                                            </p>
                                            <p className="modalText">
                                                Notify Broker or CSR Rep via email (make sure to include Freight Claims email).
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="vl" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <svg 
                                                width="32" 
                                                height="32" 
                                                viewBox="0 0 32 32" 
                                                fill="none" 
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path 
                                                    d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" 
                                                    fill="#828A95"
                                                />
                                            </svg>
                                        </td>
                                        <td style={ { marginLeft: "20%" } } colSpan="2">
                                            <p className="modalText" style={ { paddingBottom: 4 } }>
                                                STEP 6:
                                            </p>
                                            <p className="modalText">
                                                Update the DM & Ops Manager via email (make sure to include Freight Claims email).
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Button variant="continue" onClick={ this.handleClose } style={ { width: 240, height: 36, marginTop: 15 } }>
                                Got it!
                            </Button>
                            <div className="form-check">
                                <label className={ `checkBoxText ${this.state.doNotShowTutorial ? "bold" : ""}` }>
                                    <input
                                        type="checkbox"
                                        name={ this.state.label }
                                        checked={ this.state.doNotShowTutorial }
                                        onChange={ this.onCheckboxChange }
                                        className="form-check-input"
                                    />
                                    Do not show this tip again
                                </label>
                            </div>
                        </Modal.Body>
                    </SimpleContainer>
                </Modal>
            );
        }

        return (
            <div className="container-fluid page claim-issues">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <CanAccess
                                    action={PERMISSION.PAGE.ALL_ISSUES}
                                    yes={
                                        <li className="breadcrumb-item">
                                            <Link to="/issues/all">
                                                All issues
                                            </Link>
                                        </li>
                                    }
                                    no={
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                Fleet overview
                                            </Link>
                                        </li>
                                    }
                                />
                                <li className="breadcrumb-item active">
                                    <Link to={window.location.pathname}>
                                        Trailers with claim
                                    </Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading">
                                Trailers with claim
                            </p>
                            <FontAwesomeIcon className="tutorial-icon" icon={faQuestionCircle} onClick={this.openTutorialModal} />
                            <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.issuesLoaded || !this.state.dataUpdated} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="active-toggle">
                            <ActiveSelection activeCallback={this.changeActiveness} initiallyActive={this.state.activeLeg} />
                        </div>
                    </div>
                </div>
                { tutorial }
                <div className="row">
                    <div className="col">
                        <div className="claim-issues-div">
                            {this.state.issuesLoaded && (
                                <DataTableComp
                                    tableId="claimIssues"
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    onReceiveNewComment={this.onReceiveNewComment}
                                    onDeleteComment={this.onDeleteComment}
                                    columnDefs={this.state.columnDefs}
                                    customTableClass="cell-border"
                                    tableHeight="1200px"
                                    handlePreviousPage={this.handlePreviousPage}
                                    handleNextPage={this.handleNextPage}
                                    handleFirstPage={this.handleFirstPage}
                                    handleLastPage={this.handleLastPage}
                                    handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                                    handlePageChange={(event) => this.handlePageChange(event)}
                                    pagingInfo={this.state.pagingInfo}
                                    onClickLocation={{
                                        pathname: "/details",
                                        state: { trailerId: this.state.data.onClickState },
                                    }}
                                    account={this.props.account}
                                    sortRule={this.state.sort.concat([])}
                                    addSortingForColumn={this.addSortingForColumn}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withAITracking(reactPlugin, ClaimIssues, "ClaimIssues"));
