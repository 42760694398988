import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import "./ActiveSelection.scss"

export default class ActiveSelection extends Component {

    static defaultProps = {
        initiallyActive: true,
        activeCallback: () => {/* */}
    }

    constructor(props) {
        super(props);

        this.state = {
            activeSelected: this.props.initiallyActive,
        }

        this.onLinkClick = this.onLinkClick.bind(this);
        this.getRoute = this.getRoute.bind(this);
    }

    getRoute(relevant) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('relevant', relevant);
        return `${ window.location.pathname }?${ searchParams.toString() }${ window.location.hash }`;
    }

    onLinkClick(relevant) {
        this.setState({
            activeSelected: relevant
        }, () => {
            this.props.activeCallback && this.props.activeCallback(relevant);
        });
    }

    render() {
        return (
            <div className="active-selection">
                <div className={`info-label ${this.props.disabled ? "disabled" : ""}`}>Displaying: </div>
                <div className="buttons">
                    <Link id="relevant"
                          className={ `btn left ${ this.state.activeSelected ? 'active' : 'inactive' }` }
                          to={ this.getRoute(true) }
                          onClick={ () => this.onLinkClick(true) }>
                        Relevant issues
                    </Link>
                    <Link id="irrelevant"
                          className={ `btn right ${ !this.state.activeSelected ? 'active' : 'inactive' } longer-text ` }
                          to={ this.getRoute(false) }
                          onClick={ () => this.onLinkClick(false) }>
                        Irrelevant issues
                    </Link>
                </div>
            </div>
        );
    }
}
