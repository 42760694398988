import React, { Component } from 'react';
import { DatePicker as ExternalDatePicker } from 'antd';
import PropTypes from 'prop-types';
import { getCurrentDate } from 'global/services/DateTimeService';

import './DatePicker.scss';

export class DatePicker extends Component {

    static propTypes = {
        className: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.instanceOf(Date),
        onChange: PropTypes.func,
        min: PropTypes.instanceOf(Date),
        max: PropTypes.instanceOf(Date)
    };

    static defaultProps = {
        className: '',
        placeholder: 'Select...',
        onChange: () => null
    };

    constructor(props) {
        super(props);

        this.isDisabledDate = this.isDisabledDate.bind(this);
    }

    isDisabledDate(date) {
        return !date ||
            (this.props.min && date.isBefore(this.props.min)) ||
            (this.props.max && date.isAfter(this.props.max));
    }

    render() {
        return (
            <ExternalDatePicker className={ `date-picker ${ this.props.className }` }
                                placeholder={ this.props.placeholder }
                                value={ this.props.value ? getCurrentDate(this.props.value) : this.props.value }
                                onChange={ momentDate => this.props.onChange(momentDate ? momentDate.toDate() : undefined) }
                                disabledDate={ this.isDisabledDate } />
        );
    }
}
