import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withResizeDetector } from 'react-resize-detector';
import { VictoryArea } from 'victory';
import { v4 as uuidv4 } from 'uuid';
import TooltipComp from 'components/tooltip/TooltipComp';
import { hexToRgb } from "global/utils/hexToRgb";
import './SimpleContainer.scss';
import './LineChartContainer.scss';

export default withResizeDetector(class LineChartContainer extends Component {

    id = uuidv4()
    defaultColor = "#289AC2"

    state = {
        id: this.id,
        graph: {
            width: 0,
            height: 0,
            style: {
                data: {
                    fill: "url(#" + this.id + "_graph)",
                    stroke: this.props.color || this.defaultColor,
                    strokeWidth: 2
                }
            }
        }
    }

    updateGraph(containerWidth, containerHeight, color) {
        this.setState(state => ({
            graph: {
                width: containerWidth,
                height: containerHeight * .3,
                style: { data: { ...state.graph.style.data, stroke: color || this.defaultColor } }
            }
        }));
    }

    componentDidUpdate(prevProps) {
        const oldWidth = prevProps.width;
        const oldHeight = prevProps.height;
        const oldColor = prevProps.color;

        const { width, height, color } = this.props;

        if (oldWidth !== width || oldHeight !== height || oldColor !== color) {
            this.updateGraph(width, height, color);
        }
    }

    componentDidMount() {
        const element = document.getElementById(this.state.id);
        this.updateGraph(element.clientWidth, element.clientHeight, this.props.color);
    }

    render() {
        const hasTitle = !!this.props.title
        const noTooltip = !!this.props.noTooltip
        const hasData = !!this.props.data;

        const title = hasTitle ? (
            <h5 className="title">
                <span style={ { textTransform: "uppercase" } }>
                    { this.props.title }
                </span>
                { !noTooltip && <TooltipComp description={ this.props.description } position={ this.props.tooltipPosition } /> }
            </h5>
        ) : <></>
        const className = (this.props.className || "") + " simple-container graph-container" + (hasTitle ? " titled" : "");
        const customStyle = this.props.style || {};
        return (
            <div id={this.state.id} className={className} style={customStyle} onClick={this.props.onClick}>
                <div className="content">
                    {title}
                    {this.props.children}
                </div>
                { this.props.linkTo && <Link className="card-link" to={ this.props.linkTo } /> }
                {hasData && (
                    <div className="graph">
                        <svg className="hidden">
                            <defs>
                                <linearGradient id={this.id + "_graph"} x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" stopColor={ hexToRgb(this.props.color || this.defaultColor, 0.2) }/>
                                    <stop offset="100%" stopColor={ hexToRgb(this.props.color || this.defaultColor, 0) }/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <VictoryArea
                            data={this.props.data}
                            x={this.props.x}
                            y={this.props.y}
                            width={this.state.graph.width}
                            height={this.state.graph.height}
                            padding={0}
                            interpolation="basis"
                            style={this.state.graph.style} />
                    </div>
                )}
            </div>
        );
    }
})
