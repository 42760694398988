import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { allUsers, EVENT_TYPE } from 'global/constants';
import { getCurrentDate, getEndOfDay, getStartOfDay } from 'global/services/DateTimeService';
import { issueTypes } from 'global/services/IssueTypeService';
import FunctionUtils from 'global/utils/FunctionUtils';
import StringUtils from 'global/utils/StringUtils';
import { DatePicker } from 'components/input/DatePicker';
import { Input } from 'components/input/Input';
import { AsyncMultiSelect, moreResultsOption, MultiSelect } from 'components/input/Select';
import AuthContext from 'AuthContext';

import './EventsFilter.scss';

const ISSUE_TYPE_OPTIONS = Object.entries(issueTypes)
    .filter(entry => entry[0] !== 'DEFAULT')
    .map(entry => ({
        value: entry[0],
        label: entry[1]
    }));

const EVENT_TYPE_OPTIONS = Object.entries(EVENT_TYPE).map(entry => ({
    value: entry[0],
    label: entry[1]
}));

const AUTOMATIC_USER_OPTION = { value: 'NONE', label: 'Automatic' };
const MAX_USER_OPTIONS_COUNT = 100;

export default class EventsFilter extends Component {
    static contextType = AuthContext;

    static propTypes = {
        value: PropTypes.object.isRequired,
        onChange: PropTypes.func
    };

    static defaultProps = {
        onChange: () => null
    };

    constructor(props) {
        super(props);

        this.fetchUsers = this.fetchUsers.bind(this);
        this.formUserOptions = this.formUserOptions.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);

        this.debouncedFetchUsers = FunctionUtils.debounce(this.fetchUsers);
    }

    fetchUsers(searchName) {
        return this.context.get(allUsers, { searchName, pageSize: MAX_USER_OPTIONS_COUNT })
            .then(response => {
                if (response.status === 'error') {
                    console.error(response.message);
                    return [];
                }

                return this.formUserOptions(searchName, response);
            })
            .catch(error => {
                console.error(error);
                return [];
            });
    }

    formUserOptions(searchName, response) {
        const userOptions = [];

        if (StringUtils.isBlank(searchName) || AUTOMATIC_USER_OPTION.label.toLowerCase().indexOf(searchName.toLowerCase()) !== -1) {
            userOptions.push(AUTOMATIC_USER_OPTION);
        }

        userOptions.push(
            ...response.map(user => ({
                value: user.id,
                label: user.name
            }))
        );

        if (response.length === MAX_USER_OPTIONS_COUNT) {
            userOptions.push(moreResultsOption);
        }

        return userOptions;
    }

    onFilterChange(partialValue) {
        this.props.onChange({
            ...this.props.value,
            ...partialValue
        });
    }

    render() {
        return (
            <div className="events-filter">
                <div>
                    <div>
                        Start Date
                    </div>
                    <DatePicker value={ this.props.value.from }
                                onChange={ from => this.onFilterChange({ from: from ? getStartOfDay(from) : undefined }) }
                                max={ this.props.value.to || getEndOfDay(getCurrentDate()) } />
                </div>

                <div>
                    <div>
                        End Date
                    </div>
                    <DatePicker value={ this.props.value.to }
                                onChange={ to => this.onFilterChange({ to: to ? getEndOfDay(to) : undefined }) }
                                min={ this.props.value.from }
                                max={ getEndOfDay(getCurrentDate()) } />
                </div>

                <div>
                    <div>
                        Trailer
                    </div>
                    <Input className="filter-input"
                           placeholder="All Trailers"
                           value={ this.props.value.trailerBusinessId }
                           onChange={ e => this.onFilterChange({ trailerBusinessId: e.target.value }) } />
                </div>

                <div>
                    <div>
                        Order
                    </div>
                    <Input className="filter-input"
                           placeholder="All Orders"
                           value={ this.props.value.assignmentBusinessId }
                           onChange={ e => this.onFilterChange({ assignmentBusinessId: e.target.value }) } />
                </div>
                <div>
                    <div>
                        Issue type
                    </div>
                    <MultiSelect className="issue-type-select"
                                 placeholder="All Issues"
                                 options={ ISSUE_TYPE_OPTIONS }
                                 selected={ this.props.value.selectedIssueTypes }
                                 onChange={ selectedIssueTypes => this.onFilterChange({ selectedIssueTypes }) } />
                </div>

                <div>
                    <div>
                        Event type
                    </div>
                    <MultiSelect className="event-type-select"
                                 placeholder="All Events"
                                 options={ EVENT_TYPE_OPTIONS }
                                 selected={ this.props.value.selectedEventTypes }
                                 onChange={ selectedEventTypes => this.onFilterChange({ selectedEventTypes }) } />
                </div>

                <div>
                    <div>
                        Action by
                    </div>
                    <AsyncMultiSelect className="user-select"
                                      placeholder="All Users"
                                      loadOptions={ this.debouncedFetchUsers }
                                      selected={ this.props.value.selectedUsers }
                                      onChange={ selectedUsers => this.onFilterChange({ selectedUsers }) } />
                </div>
            </div>
        );
    }
}
