import React, { Component } from "react";

import { formatLongDateWithoutTime, formatTime, getDifferenceBetweenDates } from "global/services/DateTimeService";
import { ISSUE_TYPE } from 'global/services/IssueTypeService';

import "./NotifiedPersonsTooltip.scss";

export default class NotifiedPersonsTooltip extends Component {
    constructor(props) {
        super();

        this.state = {
            title: props.title,
            time: formatTime(props.firstTime),
            latestTime: props.latestTime ? formatTime(props.latestTime) : null,
            date: formatLongDateWithoutTime(props.firstTime),
            latestDate: props.latestTime ? formatLongDateWithoutTime(props.latestTime) : null,
            emails: props.emails,
            snoozedIssue: props.snoozedIssue
        }
    }

    componentDidUpdate(props) {
        if (props !== this.props) {
            this.setState({
                title: this.props.title,
                time: formatTime(this.props.firstTime),
                latestTime: this.props.latestTime ? formatTime(this.props.latestTime) : null,
                date: formatLongDateWithoutTime(this.props.firstTime),
                latestDate: this.props.latestTime ? formatLongDateWithoutTime(this.props.latestTime) : null,
                emails: this.props.emails,
                snoozedIssue: this.props.snoozedIssue
            });
        }
    }

    seeNotificationHistory() {
        this.props.seeNotificationHistory();
    }

    getNextEscalationTimeString() {
        const now = new Date();

        if (this.props.nextEscalationTime.getTime() < now.getTime()) {
            return ISSUE_TYPE.DETACHED === this.props.issueType ? 'Is being processed' : 'Notification is being processed';
        } else {
            const date = formatLongDateWithoutTime(this.props.nextEscalationTime);
            const time = formatTime(this.props.nextEscalationTime);
            return `${date} at ${time}`;
        }
    }

    render() {
        return (
            <div className="notified-tooltip">
                <div className="arrow-box">
                    <p className="mail-title">
                        {this.state.title}
                        <p className="link see-history" onClick={this.seeNotificationHistory.bind(this)}>
                            See History
                        </p>
                    </p>
                    <p className="info-label">
                        Sent to: 
                    </p>
                    <div className="gathered-info">
                        {this.state.emails.map(el => <React.Fragment key={el+Math.random()} ><div className="email-field">{el} &nbsp; </div> </React.Fragment> )}
                    </div>
                    <p className="info-label">
                       Mail sent at: 
                    </p>
                    <p className="gathered-info">
                        {this.state.date} at {this.state.time}
                    </p>
                    {
                        this.state.snoozedIssue ? 
                        <>
                            <hr className="divider"></hr>
                            <p className="info-label">
                                Snoozed at: 
                            </p>
                            <p className="gathered-info">
                                {formatLongDateWithoutTime(new Date(this.state.snoozedIssue.snoozeStart))} at {formatTime(new Date(this.state.snoozedIssue.snoozeStart))}
                            </p>
                            <p className="info-label">
                                Snoozed by: 
                            </p>
                            <p className="gathered-info">
                                {this.state.snoozedIssue.snoozedBy}
                            </p>
                            <p className="info-label">
                                Snoozed for: 
                            </p>
                            <p className="gathered-info">
                                {getDifferenceBetweenDates(this.state.snoozedIssue.snoozeStart, this.state.snoozedIssue.snoozeEnd)}
                            </p>
                            <p className="info-label">
                                Snooze reason: 
                            </p>
                            <p className="gathered-info last">
                                {this.state.snoozedIssue.snoozeReason.reason}
                            </p>
                            {this.state.snoozedIssue.snoozeReason.extended && this.state.snoozedIssue.note &&
                                <>
                                    <p className="info-label-heading">
                                        Additional note:
                                    </p>
                                    <p className="gathered-info">
                                        {this.state.snoozedIssue.note}
                                    </p>
                                </>
                            }
                        </> : 
                        <>
                            { this.props.nextEscalationTime && (
                                <>
                                    <hr className="divider" />
                                    <p className="info-label">
                                        { this.props.issueType === ISSUE_TYPE.DETACHED ? 'Trailer becomes missing at:' : 'Next Escalation:' }
                                    </p>
                                    <p className="gathered-info">
                                        { this.getNextEscalationTimeString() }
                                    </p>
                                </>
                            )}
                        </>
                    }
                </div>
            </div>
        );
    }
}
