import React, { Component } from "react";
import "./TooltipComp.scss";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export default class TooltipComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    }
  }


  handleMouseIn() {
    this.setState({ hover: true })
  }
  
  handleMouseOut() {
    this.setState({ hover: false })
  }

  render() {
    return (
      <div className={`tooltipText ${this.state.hover ? "darkColor" : "lightColor"} ${this.props.customClassName}`} onMouseOver={this.handleMouseIn.bind(this)} onMouseOut={this.handleMouseOut.bind(this)}>
        <FontAwesomeIcon icon={ faInfoCircle } className="info-icon" />
        <span style={{display: this.state.hover ? "block" : "none"}} className={`tooltipDescription  ${this.props.position || "center"}`}>
          {this.props.description}
        </span>
      </div>
    )
  };
}

