import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AuthContext from "AuthContext";
import SimpleContainer from 'components/container/SimpleContainer';

import './DeletePopup.scss';

export default class DeletePopup extends Component {
    static contextType = AuthContext;

    async handleSubmit() {
        try {
            const response = await this.context.delete(this.props.url, this.props.params);
            if (response.status === "error") {
                console.error(response.message);
                this.props.closeModal();
                return;
            }
            this.props.closeModal(response, this.props.params);
        } catch (error) {
            console.error(error);
            this.props.closeModal();
        }
    }

    onClose = () => this.props.closeModal()

    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.onClose}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="delete-modal"
            >
                <SimpleContainer className="modal-container" modal title={this.props.title}>
                    <div className="warning-header">
                        {this.props.subtitle}
                    </div>
                    <div className="warning-text">
                        {this.props.children}
                    </div>
                    <div className="buttons">
                        <Button variant="light" onClick={this.onClose} className="cancel-button">Cancel</Button>
                        <Button variant="danger" className="delete-button" onClick={this.handleSubmit.bind(this)}>Delete</Button>
                    </div>
                </SimpleContainer>
            </Modal>
        );
    }
}

DeletePopup.contextType = AuthContext;
