import React, { Component } from 'react';
import { default as ExternalSelect, components } from 'react-select';
import { default as ExternalAsyncSelect } from 'react-select/async';
import PropTypes from 'prop-types';

import './Select.scss';

const selectedOptionPropType = PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string.isRequired
});

export class Select extends Component {

    static propTypes = {
        className: PropTypes.string,
        placeholder: PropTypes.string,
        options: PropTypes.arrayOf(selectedOptionPropType),
        selected: PropTypes.oneOfType([ selectedOptionPropType, PropTypes.arrayOf(selectedOptionPropType) ]),
        onChange: PropTypes.func,
        multiple: PropTypes.bool
    };

    static defaultProps = {
        className: '',
        onChange: () => null
    };

    render() {
        return (
            <ExternalSelect classNamePrefix="select"
                            className={ `select ${ this.props.className }` }
                            isSearchable={ false }
                            hideSelectedOptions={ false }
                            closeMenuOnSelect={ !this.props.multiple }
                            placeholder={ this.props.placeholder }
                            options={ this.props.options }
                            isMulti={ this.props.multiple }
                            value={ this.props.selected }
                            onChange={ this.props.onChange }
                            components={ { ValueContainer: CustomValueContainer, Option: CustomOption } } />
        );
    }
}

export class MultiSelect extends Component {

    static propTypes = {
        ...Select.propTypes,
        selected: PropTypes.arrayOf(selectedOptionPropType)
    };

    static defaultProps = {
        ...Select.defaultProps
    };

    render() {
        return (
            <Select { ...this.props } multiple onChange={ selected => this.props.onChange(selected || []) } />
        );
    }
}

export class AsyncSelect extends Component {

    static propTypes = {
        className: PropTypes.string,
        placeholder: PropTypes.string,
        loadOptions: PropTypes.func,
        selected: PropTypes.oneOfType([ selectedOptionPropType, PropTypes.arrayOf(selectedOptionPropType) ]),
        onChange: PropTypes.func,
        multiple: PropTypes.bool
    };

    static defaultProps = {
        className: '',
        onChange: () => null
    };

    render() {
        return (
            <ExternalAsyncSelect classNamePrefix="select"
                                 className={ `select ${ this.props.className }` }
                                 cacheOptions
                                 defaultOptions
                                 hideSelectedOptions={ false }
                                 closeMenuOnSelect={ !this.props.multiple }
                                 placeholder={ this.props.placeholder }
                                 loadOptions={ this.props.loadOptions }
                                 isMulti={ this.props.multiple }
                                 value={ this.props.selected }
                                 onChange={ this.props.onChange }
                                 components={ { ValueContainer: CustomValueContainer, Option: CustomOption } } />
        );
    }
}

export class AsyncMultiSelect extends Component {

    static propTypes = {
        ...AsyncSelect.propTypes,
        selected: PropTypes.arrayOf(selectedOptionPropType)
    };

    static defaultProps = {
        ...AsyncSelect.defaultProps
    };

    render() {
        return (
            <AsyncSelect { ...this.props } multiple onChange={ selected => this.props.onChange(selected || []) } />
        );
    }
}

export const moreResultsOption = {
    label: (
        <div className="font-weight-normal">
            More results available, use the search to further narrow down the results.
        </div>
    ),
    isDisabled: true
};

const { Option, ValueContainer } = components;

class CustomValueContainer extends Component {
    render() {
        const { children, ...props } = this.props;
        let [ values, input ] = children;

        const selectedOptions = props.getValue();
        if (selectedOptions.length) {
            values = selectedOptions.length > 1 ? 'Multiple' : selectedOptions[0].label;
        }

        return (
            <ValueContainer { ...props }>
                { !input.props.value && values }
                { input }
            </ValueContainer>
        );
    }
}

class CustomOption extends Component {
    render() {
        const { children, ...props } = this.props;
        return (
            <Option { ...props }>
                { props.isMulti && props.value && (
                    <input className="option-checkbox" type="checkbox" checked={ props.isSelected } />
                ) }
                { children }
            </Option>
        );
    }
}
