import { snoozeDurationUrl, snoozeReasonsUrl } from 'global/constants';
import DurationUtils from 'global/utils/DurationUtils';

export const snoozeReasons = async function () {
    const response = await this.context.get(snoozeReasonsUrl, {});

    if (response.status === 'error') {
        console.error(response.message);
        return [];
    }

    return response.data.map(snoozeReason => (
        {
            value: snoozeReason,
            label: snoozeReason.reason
        }
    ));
}

export const snoozeDurations = async function (issueType) {
    const response = await this.context.get(snoozeDurationUrl, { issueType });

    if (response.status === 'error') {
        console.error(response.message);
        return [];
    }

    return response.map(snoozeInterval => (
        {
            value: snoozeInterval.duration,
            label: DurationUtils.formatLong(snoozeInterval.duration)
        }
    ));
}
