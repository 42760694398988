import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Loader from 'react-loader-spinner';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle, faHistory } from '@fortawesome/pro-solid-svg-icons';
import {
    faTrailer as trailerIcon,
    faTruck as totalTrailersIcon,
    faRoadCircleCheck as dispatchIcon,
    faTruckRampBox as dropYardIcon,
    faSquareParking as yardIcon,
    faHouse as homeTimeIcon,
    faTruckFlatbed as onewayIcon,
    faWrench as repairsIcon,
    faLinkSlash as mismatchIcon,
    faSignalBarsSlash as silentIcon,
    faPowerOff as inactiveIcon,
    faFileCircleExclamation as claimIcon,
    faDownload as downloadIcon,
    faArrowUpRightFromSquare as linkIcon,
    faArrowLeft as leftArrowIcon,
    faArrowsRotate as refreshIcon
} from '@fortawesome/pro-solid-svg-icons';

import DataCard from 'components/card/DataCard';
import DetachedMissingTable from 'pages/trailer-audit/DetachedMissingTable';
import LineChartContainer from 'components/container/LineChartContainer';
import SimpleContainer from 'components/container/SimpleContainer';
import CanAccess from 'components/can-access/CanAccess';
import SimpleTooltip from 'components/tooltip/SimpleTooltip';
import Message from 'components/Message';
import ConfirmPopup from 'components/popup/ConfirmPopup';
import { mapReportLocationIssuesFromResponse } from 'global/services/IssueApiService';
import { ISSUE_TYPE } from 'global/services/IssueTypeService';
import { formatDateTimeNumeric, pmHourFormat, toLocalHour } from 'global/services/DateTimeService';
import QueryParamService from "global/services/QueryParamService";
import { PERMISSION } from 'global/utils/auth';
import deleteComment from 'global/utils/deleteComment';
import StringUtils from 'global/utils/StringUtils';
import { reportHoursUrl, reportUrl, setupTemporaryAuditInterval } from 'global/constants';
import history from 'global/history';
import { reactPlugin } from 'AppInsights';
import AuthContext from 'AuthContext';

import './TrailerAudit.scss';

const totalTooltip = "The total number of trailers.";
const dispatchTooltip = "The number of trailers which are currently on a dispatched or started load.";
const homeTimeTooltip = "The number of trailers currently under the responsibility of a driver who is currently on home time.";
const yardTooltip = "The number of trailers currently in the vicinity of the ShipEX terminal.";
const dropYardTooltip = "The number of trailers currently in the vicinity of an approved yard.";
const claimsTooltip = "The number of trailers with a claim.";
const repairsTooltip = "The number of trailers currently on repair.";
const mismatchTooltip = "The number of trailers which don't have a matching location in TMW and TK.";
const silentTooltip = "The number of trailers that have not transmitted updates to the system within the last 2 hours";
const inactiveTooltip = "The number of trailers that have NOT moved outside of a radius of 0.5 miles for 3 or more days."
const onewayTooltip = "The number of trailers currently on a oneway trip.";
const unaccountedTooltip = "The number of trailers that do not belong in any other category.";

class TrailerAudit extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props);

        const historyUrl = props.location.state && props.location.state.historyUrl;

        this.previousTeams = context.team;
        this.formatter = new Intl.ListFormat('en-GB');
        this.state = {
            missingIssues: [],
            detachedIssues: [],
            report: {
                total: 0,
                dispatch: 0,
                homeTime: 0,
                yard: 0,
                dropYard: 0,
                claim: 0,
                repair: 0,
                mismatch: 0,
                inactive: 0,
                silent: 0,
                oneway: 0,
                unaccounted: 0
            },
            temporaryReport: null,
            dropYards: [],
            fleets: [],
            showConfirmPopup: false,
            historyUrl,
            loading: true,
            csvLoading: false,
            dropYardsCsvLoading: false,
            title: "Trailer Audit",
            reportHours: []
        }

        this.onReceiveNewMissingComment = this.onReceiveNewMissingComment.bind(this);
        this.onDeleteMissingComment = this.onDeleteMissingComment.bind(this);
        this.onReceiveNewDetachedComment = this.onReceiveNewDetachedComment.bind(this);
        this.onDeleteDetachedComment = this.onDeleteDetachedComment.bind(this);
        this.onRefreshAuditClick = this.onRefreshAuditClick.bind(this);
        this.onCloseConfirmModal = this.onCloseConfirmModal.bind(this);
        this.fetchTemporaryAudit = this.fetchTemporaryAudit.bind(this);
        this.exportDropYardsCsv = this.exportDropYardsCsv.bind(this);
    }

    async componentDidMount() {
        this.fetchReportHours();
        await this.fetchReport();

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id || this.previousTeams !== this.context.team) {
            this.previousTeams = this.context.team;
            this.fetchReport();
        } else if (!this.props.match.params.id && this.state.report.id) {
            window.history.replaceState(null, null, "/trailer-audit/" + this.state.report.id);
        }

        if (prevState.temporaryReport !== this.state.temporaryReport) {
            this.checkForTemporaryAuditInterval = setupTemporaryAuditInterval(this.state.temporaryReport,
                this.state.temporary,
                this.fetchTemporaryAudit,
                this.checkForTemporaryAuditInterval
            );
        }
    }

    componentWillUnmount() {
        clearInterval(this.checkForTemporaryAuditInterval);
    }

    async fetchReport() {
        try {
            this.setState({
                loading: true,
                title: "Loading..."
            });

            const params = this.props.match.params.id ? { reportId: this.props.match.params.id } : { };
            const response = await this.context.get(reportUrl, params);

            if (response.status === "error") {
                const statusCode = response.message.response.status;

                if (404 === statusCode) {
                    history.push({
                        pathname: '/trailer-audit',
                    });
                    return;
                }

                this.setState({
                    loading: false,
                    title: "Trailer Audit"
                });
            }

            if (!response.report.temporary) {
                await this.fetchTemporaryAudit();
            }

            if (!this.props.match.params.id) {
                window.history.replaceState(null, null, "/trailer-audit/" + response.report.id);
            }

            this.setState({
                report: { ...response.report, newerTemporaryExists: response.newerTemporaryExists },
                dropYards: response.dropYards || [],
                fleets: response.fleets || [],
                missingIssues: mapReportLocationIssuesFromResponse(response.missingIssues || []),
                detachedIssues: mapReportLocationIssuesFromResponse(response.detachedIssues || []),
                loading: false,
                title: (response.report.temporary ? 'Temporary ' : '') + 'Trailer Audit for ' + formatDateTimeNumeric(new Date(response.report.time))
            });
        } catch (error) {
            console.error('Error while fetching report.', error);
            this.setState({
                loading: false,
                title: "Trailer Audit"
            });
        }
    }

    async fetchIssues(type, sort) {
        try {
            const response = await this.context.get(`${reportUrl}/${this.state.report.id}/issues`, {
                issueTypes: [type],
                sort
            });

            if (response.status === "error") {
                const statusCode = response.message.response.status;

                if (404 === statusCode) {
                    history.replace({
                        pathname: "/404",
                    });
                }

                return;
            }

            if (type === ISSUE_TYPE.MISSING) {
                this.setState({
                    missingIssues: mapReportLocationIssuesFromResponse(response)
                });
            } else {
                this.setState({
                    detachedIssues: mapReportLocationIssuesFromResponse(response)
                });
            }
        } catch (error) {
            console.error('Error while fetching report issues.', error);
        }
    }

    async fetchTemporaryAudit() {
        const response = await this.context.get(`${reportUrl}/temporary`, { });

        this.setState({
            temporaryReport: response
        });
    }

    fetchReportHours() {
        this.context.get(reportHoursUrl, {})
            .then(response => response.status !== 'error' && this.setState({ reportHours: response }))
            .catch(error => console.error('Error while fetching report hours.', error));
    }

    getRoute(route, trailerNumber, state) {
        if (trailerNumber > 0) {
            return {
                pathname: `/trailer-audit/${ this.state.report.id }/${ route }`,
                state
            };
        }
    }

    async exportCsv() {
        this.setState({
            csvLoading: true
        });

        try {
            await this.context.download(`${reportUrl}/${this.state.report.id}/export`, {
                all: true
            });
        } catch (error) {
            console.error('Error while downloading report csv.', error);
        } finally {
            this.setState({
                csvLoading: false
            });
        }
    }

    exportDropYardsCsv() {
        this.setState({ dropYardsCsvLoading: true });

        this.context.download(`${reportUrl}/${this.state.report.id}/drop-yard/export`, {})
            .catch(error => console.error('Error while downloading drop yards csv.', error))
            .finally(() => this.setState({ dropYardsCsvLoading: false }));
    }

    onReceiveNewComment(comment, isMissingIssue) {
        this.setState(prevState => {
            let data;
            if (isMissingIssue) {
                data = prevState.missingIssues;
            } else {
                data = prevState.detachedIssues;
            }

            const index = data.findIndex(entity => entity.issue.id === comment.issue.id);

            if (index !== -1) {
                data[index].comments.unshift(comment);

                if (isMissingIssue) {
                    return { missingIssues: data };
                } else {
                    return { detachedIssues: data };
                }
            }
        });
    }

    onDeleteComment(deletedComment, isMissingIssue) {
        if (isMissingIssue) {
            this.setState(prevState => ({
                missingIssues: deleteComment(deletedComment, prevState.missingIssues)
            }));
        } else {
            this.setState(prevState => ({
                detachedIssues: deleteComment(deletedComment, prevState.detachedIssues)
            }));
        }
    }

    onReceiveNewMissingComment(comment) {
        this.onReceiveNewComment(comment, true);
    }

    onReceiveNewDetachedComment(comment) {
        this.onReceiveNewComment(comment, false);
    }

    onDeleteMissingComment(comment) {
        this.onDeleteComment(comment, true);
    }

    onDeleteDetachedComment(comment) {
        this.onDeleteComment(comment, false);
    }

    async onRefreshAuditClick() {
        await this.context.post(`${reportUrl}/temporary`, {});

        await this.fetchTemporaryAudit();

        this.setState(prevState => ({
            report: {
                ...prevState.report,
                newerTemporaryExists: true
            }
        }));
    }

    onCloseConfirmModal(confirmRefresh) {
        this.setState({
            showConfirmPopup: false
        });
        if (confirmRefresh) {
            if (this.state.report.temporary) {
                history.push({
                    pathname: "/trailer-audit"
                });
            }
        }
    }

    formatReportHours() {
        return this.formatter.format(
            this.state.reportHours
                .map(toLocalHour)
                .sort((h1, h2) => h1 - h2)
                .map(pmHourFormat)
        );
    }

    render() {
        let infoMessage = '';
        let linkButton = '';
        if (this.state.report.temporary) {
            infoMessage = 'This is a manually generated Temporary Trailer Audit and its action functionality may be disabled for some audit categories.';
            linkButton = (
                <Link className="button" to={ `/trailer-audit` }>
                    <FontAwesomeIcon icon={ leftArrowIcon } className="icon" />
                    BACK TO TRAILER AUDIT
                </Link>
            );
        } else if (this.state.temporaryReport && !this.state.temporaryReport.completed) {
            infoMessage = 'The Trailer Audit is currently being updated. This may take a couple of minutes.';
        } else if (this.state.temporaryReport && this.state.temporaryReport.completed) {
            infoMessage = `A new temporary Trailer Audit has been manually generated at ${formatDateTimeNumeric(new Date(this.state.temporaryReport.time))}.`;
            linkButton = (
                <Link className="button" to={ `/trailer-audit/${this.state.temporaryReport.id}` }>
                    <FontAwesomeIcon icon={ linkIcon } className="icon" />
                    OPEN TEMPORARY AUDIT
                </Link>
            );
        }

        let fleetBoxColClassName;
        if (this.state.fleets.length >= 6) {
            fleetBoxColClassName = "col-xl-2 col-lg-3 col-md-4 col-sm-6";
        } else {
            fleetBoxColClassName = "col-lg col-md-4 col-sm-6";
        }

        let contentBelowTitle;
        if (!this.state.report.id && !this.state.loading) {
            contentBelowTitle = (
                <div className="col heading-error-message-div justify-content-between">
                    <FontAwesomeIcon className="redColorText" icon={ faExclamationCircle } />
                    <span>
                        Failed to load report.
                    </span>
                </div>
            )
        }

        const disableRefresh = !this.state.report.temporary && this.state.temporaryReport && !this.state.temporaryReport.completed;

        const showTemporaryReportMessage = !this.state.loading && (this.state.report.temporary || this.state.report.newerTemporaryExists);

        return (
            <div className="container-fluid page trailer-audit">
                { showTemporaryReportMessage && (
                    <Message>
                        <div className="message">
                            { infoMessage }
                            { linkButton }
                        </div>
                    </Message>
                ) }
                { this.state.historyUrl &&
                    <div className="row">
                        <div className="col">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={this.state.historyUrl}>
                                            AUDIT HISTORY
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to={{ pathname: window.location.pathname, state: { historyUrl: this.state.historyUrl } }}>
                                            TRAILER AUDIT
                                        </Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                }
                <div className="row align-items-center">
                    <div style={ this.state.historyUrl ? { paddingTop: 0 } : null } className="col heading-div justify-content-between">
                        <div className="d-flex flex-wrap align-items-center">
                            <h1 className="page-title my-0">
                                { this.state.title }
                                { !this.state.loading && this.state.report.time && !this.state.report.temporary &&
                                    <div className="tooltip-container">
                                        <FontAwesomeIcon icon={ faInfoCircle } className="tooltip-info-icon"/>
                                        <SimpleTooltip direction="bottom">
                                            A new Trailer Audit report is generated each day at { this.formatReportHours() }.
                                        </SimpleTooltip>
                                    </div>
                                }
                            </h1>
                            <Loader
                                className="align-self-center"
                                type="TailSpin"
                                color="#289AC2"
                                height={ 36 }
                                width={ 36 }
                                visible={ this.state.loading }
                            />
                        </div>
                        <div className="d-flex pr-0">
                            { !this.state.loading && (
                                <>
                                    <CanAccess
                                        action={ [PERMISSION.TRAILER_AUDIT.REFRESH] }
                                        yes={
                                            <button className={ `button ${disableRefresh && "disabled"}` }
                                                    onClick={ () => this.setState({ showConfirmPopup: true }) }
                                                    disabled={ disableRefresh }>
                                                <FontAwesomeIcon icon={ refreshIcon } className="icon" />
                                                REFRESH
                                            </button>
                                        }
                                    />
                                    <CanAccess
                                        action={ [
                                            PERMISSION.TRAILER_AUDIT.READ,
                                            PERMISSION.TRAILER_AUDIT.TOTAL.READ,
                                            PERMISSION.TRAILER_AUDIT.DISPATCH.READ,
                                            PERMISSION.TRAILER_AUDIT.HOME_TIME.READ,
                                            PERMISSION.TRAILER_AUDIT.YARD.READ,
                                            PERMISSION.TRAILER_AUDIT.DROP_YARD.READ,
                                            PERMISSION.TRAILER_AUDIT.CLAIM.READ,
                                            PERMISSION.TRAILER_AUDIT.REPAIR.READ,
                                            PERMISSION.TRAILER_AUDIT.MISMATCH.READ,
                                            PERMISSION.TRAILER_AUDIT.ONEWAY.READ,
                                            PERMISSION.TRAILER_AUDIT.UNACCOUNTED.READ,
                                            PERMISSION.TRAILER_AUDIT.SILENT.READ,
                                            PERMISSION.TRAILER_AUDIT.INACTIVE.READ
                                        ] }
                                        yes={
                                            <div className="col export-csv-div">
                                                <Button variant="continue" onClick={ this.exportCsv.bind(this) }>
                                                    <FontAwesomeIcon icon={ downloadIcon } className="add-icon" />
                                                    Export CSV
                                                    <Loader
                                                        className="csv-loader"
                                                        type="TailSpin"
                                                        color="white"
                                                        height={ 13 }
                                                        width={ 13 }
                                                        visible={ this.state.csvLoading }
                                                    />
                                                </Button>
                                            </div>
                                        }
                                    />
                                </>
                            ) }
                            { !this.state.report.temporary && !this.state.loading && (
                                <Link to="/trailer-audit/history" className="button">
                                    <FontAwesomeIcon icon={ faHistory } className="icon" />
                                    AUDIT HISTORY
                                </Link>
                            ) }
                        </div>
                    </div>
                </div>
                <div className="row content-below-title">
                    { contentBelowTitle }
                </div>

                { !this.state.loading && (
                    <>
                        <div className="row">
                            <div className="col title-small">
                                General
                            </div>
                        </div>

                        <div className="row">
                            <CanAccess
                                action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.TOTAL.READ] }
                                yes={
                                    <div className="col-md-3 col-sm-6 box-col">
                                        <DataCard
                                            count={ this.state.report.total }
                                            linkTo={ this.getRoute("total", this.state.report.total, this.state.report) }
                                            title="Total trailers"
                                            tooltip={ totalTooltip }
                                            icon={ totalTrailersIcon }
                                        />
                                    </div>
                                }
                            />

                            <CanAccess
                                action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.UNACCOUNTED.READ] }
                                yes={
                                    <div className="col-md-3 col-sm-6 box-col">
                                        <DataCard
                                            count={ this.state.report.unaccounted }
                                            linkTo={ this.getRoute("unaccounted", this.state.report.unaccounted, this.state.report) }
                                            title="Unaccounted trailers"
                                            tooltip={ unaccountedTooltip }
                                            icon={ trailerIcon }
                                            color="yellow"
                                        />
                                    </div>
                                }
                            />
                        </div>

                        <div className="row">
                            <div className="col title-small">
                                Location
                            </div>
                        </div>

                        <div className="row">
                            <CanAccess
                                action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.DISPATCH.READ] }
                                yes={
                                    <div className="col-xl-2 col-md-3 col-sm-4 box-col">
                                        <DataCard
                                            count={ this.state.report.dispatch }
                                            linkTo={ this.getRoute("dispatch", this.state.report.dispatch, this.state.report) }
                                            title="Trailers under dispatch"
                                            tooltip={ dispatchTooltip }
                                            icon={ dispatchIcon }
                                        />
                                    </div>
                                }
                            />

                            <CanAccess
                                action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.DROP_YARD.READ] }
                                yes={
                                    <div className="col-xl-2 col-md-3 col-sm-4 box-col">
                                        <DataCard
                                            count={ this.state.report.dropYard }
                                            linkTo={ this.getRoute("drop-yard", this.state.report.dropYard, this.state.report) }
                                            title="Drop yard"
                                            tooltip={ dropYardTooltip }
                                            icon={ dropYardIcon }
                                        />
                                    </div>
                                }
                            />

                            <CanAccess
                                action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.YARD.READ] }
                                yes={
                                    <div className="col-xl-2 col-md-3 col-sm-4 box-col">
                                        <DataCard
                                            count={ this.state.report.yard }
                                            linkTo={ this.getRoute("yard", this.state.report.yard, this.state.report) }
                                            title="Trailers at the yard"
                                            tooltip={ yardTooltip }
                                            icon={ yardIcon }
                                            color="yellow"
                                        />
                                    </div>
                                }
                            />

                            <CanAccess
                                action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.HOME_TIME.READ] }
                                yes={
                                    <div className="col-xl-2 col-md-3 col-sm-4 box-col">
                                        <DataCard
                                            count={ this.state.report.homeTime }
                                            linkTo={ this.getRoute("home-time", this.state.report.homeTime, this.state.report) }
                                            title="Trailers with drivers on home time"
                                            tooltip={ homeTimeTooltip }
                                            icon={ homeTimeIcon }
                                        />
                                    </div>
                                }
                            />

                            <CanAccess
                                action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.ONEWAY.READ] }
                                yes={
                                    <div className="col-xl-2 col-md-3 col-sm-4 box-col">
                                        <DataCard
                                            count={ this.state.report.oneway }
                                            linkTo={ this.getRoute("oneway", this.state.report.oneway, this.state.report) }
                                            title="Oneway trailers"
                                            tooltip={ onewayTooltip }
                                            icon={ onewayIcon }
                                        />
                                    </div>
                                }
                            />
                        </div>

                        <div className="row">
                            <div className="col title-small">
                                Status
                            </div>
                        </div>

                        <div className="row">
                            <CanAccess
                                action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.REPAIR.READ] }
                                yes={
                                    <div className="col-xl-2 col-md-3 col-sm-4 box-col">
                                        <DataCard
                                            count={ this.state.report.repair }
                                            linkTo={ this.getRoute("repair", this.state.report.repair, this.state.report) }
                                            title="TMW repair expirations"
                                            tooltip={ repairsTooltip }
                                            icon={ repairsIcon }
                                            color="yellow"
                                        />
                                    </div>
                                }
                            />

                            <CanAccess
                                action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.MISMATCH.READ] }
                                yes={
                                    <div className="col-xl-2 col-md-3 col-sm-4 box-col">
                                        <DataCard
                                            count={ this.state.report.mismatch }
                                            linkTo={ this.getRoute("mismatch", this.state.report.mismatch, this.state.report) }
                                            title="Mismatched trailers"
                                            tooltip={ mismatchTooltip }
                                            icon={ mismatchIcon }
                                            color="orange"
                                        />
                                    </div>
                                }
                            />

                            { this.state.report.silent !== undefined && (
                                <CanAccess
                                    action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.SILENT.READ] }
                                    yes={
                                        <div className="col-xl-2 col-md-3 col-sm-4 box-col">
                                            <DataCard
                                                count={ this.state.report.silent }
                                                linkTo={ this.getRoute("silent", this.state.report.silent, this.state.report) }
                                                title="Silent units"
                                                tooltip={ silentTooltip }
                                                icon={ silentIcon }
                                                color="orange"
                                            />
                                        </div>
                                    }
                                />
                            )}

                            { this.state.report.inactive !== undefined && (
                                <CanAccess
                                    action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.INACTIVE.READ] }
                                    yes={
                                        <div className="col-xl-2 col-md-3 col-sm-4 box-col">
                                            <DataCard
                                                count={ this.state.report.inactive }
                                                linkTo={ this.getRoute("inactive", this.state.report.inactive, this.state.report) }
                                                title="Inactive trailers"
                                                tooltip={ inactiveTooltip }
                                                icon={ inactiveIcon }
                                                color="yellow"
                                            />
                                        </div>
                                    }
                                />
                            )}

                            <CanAccess
                                action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.CLAIM.READ] }
                                yes={
                                    <div className="col-xl-2 col-md-3 col-sm-4 box-col">
                                        <DataCard
                                            count={ this.state.report.claim }
                                            linkTo={ this.getRoute("claim", this.state.report.claim, this.state.report) }
                                            title="Trailers with claim"
                                            tooltip={ claimsTooltip }
                                            icon={ claimIcon }
                                            color="orange"
                                        />
                                    </div>
                                }
                            />
                        </div>

                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.MISSING.READ] }
                            yes={
                                <div className="row">
                                    <div className="col">
                                        <SimpleContainer className="issues-container" title="MISSING TRAILERS">
                                            { this.state.missingIssues.length === 0 ?
                                                <div className="no-issues-info">
                                                    <FontAwesomeIcon className="blueColorText" icon={ faInfoCircle } />
                                                    <span>
                                                There were no missing trailers.
                                            </span>
                                                </div> :
                                                <>
                                                    <div className="missing-issue-warning">
                                                        <FontAwesomeIcon className="redColorText" icon={ faExclamationCircle } />
                                                        <span>
                                                    These trailers seemed to be detached from the truck and they did not report for 24 hours. They might have been missing or stolen!
                                                </span>
                                                    </div>
                                                    <DetachedMissingTable
                                                        id="missingIssuesTable"
                                                        data={ this.state.missingIssues }
                                                        detached={ false }
                                                        sortBy={ (sort) => this.fetchIssues("MISSING", sort) }
                                                        reportDate={ this.state.report.time }
                                                        onReceiveNewComment={ this.onReceiveNewMissingComment }
                                                        onDeleteComment={ this.onDeleteMissingComment }
                                                    />
                                                </>
                                            }
                                        </SimpleContainer>
                                    </div>
                                </div>
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.DETACHED.READ] }
                            yes={
                                <div className="row">
                                    <div className="col">
                                        <SimpleContainer className="issues-container" title="DETACHED TRAILERS">
                                            { this.state.detachedIssues.length === 0 ?
                                                <div className="no-issues-info">
                                                    <FontAwesomeIcon className="blueColorText" icon={ faInfoCircle }/>
                                                    <span>
                                                There were no detached trailers.
                                            </span>
                                                </div> :
                                                <>
                                                    <div className="detached-issue-warning">
                                                        <FontAwesomeIcon className="orangeColorText" icon={ faExclamationTriangle } />
                                                        <span>
                                                    These trailers seemed to be detached from the truck and were in danger of being stolen.
                                                </span>
                                                    </div>
                                                    <DetachedMissingTable
                                                        id="detachedIssuesTable"
                                                        data={ this.state.detachedIssues }
                                                        detached={ true }
                                                        sortBy={ (sort) => this.fetchIssues("DETACHED", sort) }
                                                        reportDate={ this.state.report.time }
                                                        onReceiveNewComment={ this.onReceiveNewDetachedComment }
                                                        onDeleteComment={ this.onDeleteDetachedComment }
                                                    />
                                                </>
                                            }
                                        </SimpleContainer>
                                    </div>
                                </div>
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.DROP_YARD.READ] }
                            yes={
                                <>
                                    <div className="row align-items-center">
                                        <div className="col title-small">
                                            Trailers in Drop Yards
                                            <span className="greyColorText">
                                                &nbsp;({ this.state.report.dropYard })
                                            </span>
                                        </div>
                                        { !this.state.loading && (
                                            <div className="col-auto">
                                                <Button variant="continue" onClick={ this.exportDropYardsCsv }>
                                                    <FontAwesomeIcon icon={ downloadIcon } className="add-icon" />
                                                    Export Drop Yards CSV
                                                    <Loader
                                                        className="csv-loader"
                                                        type="TailSpin"
                                                        color="white"
                                                        height={ 13 }
                                                        width={ 13 }
                                                        visible={ this.state.dropYardsCsvLoading }
                                                    />
                                                </Button>
                                            </div>
                                        ) }
                                    </div>
                                    <div className="row">
                                        { this.state.dropYards.map(yard => (
                                            <div key={ yard.id } className="col-xl-2 col-lg-3 col-md-4 col-sm-6 box-col">
                                                <LineChartContainer
                                                    noTooltip
                                                    title={ yard.businessId }
                                                    className={ 'box ' + (yard.count === 0 ? 'disabled-box' : '') }
                                                    linkTo={ this.getRoute('drop-yard/' + yard.id, yard.count, yard) }
                                                >
                                                    <div className="total-count-container">
                                                        <div className="yard-icon-box">
                                                            <FontAwesomeIcon icon={ trailerIcon } />
                                                        </div>
                                                        <div className="total-count">
                                                            { yard.count }
                                                        </div>
                                                    </div>
                                                    <div className="yard-info bold">
                                                        { yard.name }
                                                    </div>
                                                    <div className="yard-info">
                                                        { yard.address || '-' }
                                                        <br />
                                                        { StringUtils.concatNotBlank([ yard.city, yard.state, yard.zip ], ', ') }
                                                    </div>
                                                </LineChartContainer>
                                            </div>
                                        )) }
                                    </div>
                                </>
                            }
                        />

                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.FLEET.READ] }
                            yes={ this.state.fleets.length > 0 && (
                                <>
                                    <div className="row">
                                        <div className="col title-small">
                                            Fleets
                                        </div>
                                    </div>
                                    <div className="row last-row row-items">
                                        { this.state.fleets.map(fleet => (
                                            <div  key={ fleet.id } className={ fleetBoxColClassName + " box-col" }>
                                                <DataCard
                                                    count={ fleet.count }
                                                    linkTo={ this.getRoute("fleet/" + fleet.id, fleet.count, fleet) }
                                                    title={ fleet.name }
                                                />
                                            </div>
                                        )) }
                                    </div>
                                </>
                            )
                            }
                        />
                    </>
                )}
                <ConfirmPopup
                    closeModal={ this.onCloseConfirmModal }
                    onConfirm={ this.onRefreshAuditClick }
                    showModal={ this.state.showConfirmPopup }
                    title="REFRESH AUDIT"
                    subtitle="Refresh Audit?"
                >
                    Are you sure you want to initiate the refresh procedure for the Trailer Audit?
                    <br /><br />This manually generated Audit is read-only and will not be stored in the Audit History. You will still be able to access the current Trailer Audit via side navigation bar.
                </ConfirmPopup>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, TrailerAudit));
