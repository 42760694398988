import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons';

import "./ExpandableCard.scss";

class ExpandableCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expand: this.props.expand
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.expand !== this.props.expand || prevProps.disabledExpand !== this.props.disabledExpand) {
            this.setState({
                expand: !this.props.disabledExpand && this.props.expand
            });
        }
    }

    expandClick() {
        if (!this.props.disabledExpand) {
            this.setState({
                expand: !this.state.expand
            })
        }
    }

    editClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.handleEdit();
    }

    deleteClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.handleDelete();
    }

    render() {
        return (
            <div style={this.state.expand ? { height: "max-content" } : this.props.disabledExpand ? { cursor: "default" } : null} className="expandable-card">
                <div style={this.state.expand ? { fontWeight: 700 } : null} className="row title" onClick={this.expandClick.bind(this)}>
                    <div className={this.props.subtitle ? "col-4 container" : "col-9 container"}>
                        {!this.props.disabledExpand &&
                            <>
                                {this.state.expand ?
                                    <FontAwesomeIcon icon={faChevronUp} style={{ color: '#030D1D' }} className="expand-arrow" /> :
                                    <FontAwesomeIcon icon={faChevronDown} className="expand-arrow" />
                                }
                            </>
                        }
                        <span className="title-text" style={this.props.disabledExpand ? { marginLeft: 26 } : null}>
                            {this.props.title}
                        </span>
                    </div>
                    <div className={"subtitle-container " + (this.props.subtitle ? "col-xs-7 col-5" : "")}>
                        <div className="subtitle">
                            {this.props.subtitle}
                        </div>
                        <div className="note">
                            {this.props.note}
                        </div>
                    </div>
                    <div className="col-xs col-3 actions">
                        {!!this.props.handleEdit && <FontAwesomeIcon onClick={this.editClick.bind(this)} icon={faEdit} className="action-icon" />}
                        {!!this.props.handleDelete && <FontAwesomeIcon onClick={this.deleteClick.bind(this)} icon={faTrash} className="action-icon" />}
                    </div>
                </div>
                {this.state.expand &&
                    <>
                        <div className="line" />
                        <div className="content">
                            {this.props.children}
                        </div>
                    </>
                }
            </div>
        )
    }
}

export default ExpandableCard;
