import getKeyByUniqueValue from "global/utils/getKeyByUniqueValue";

/**
 * @description
 * Forms object used for setting a sorting value on a datatable.
 *
 * @param sort The default sorting array used for APIs.
 *
 * @returns The sort object necessary for tables
 */

function formSortObject(sort, columnNames) {
    let sortObject = [];

    sort.forEach((value) => {
        let element = value.split(",");
        sortObject.push({
          columnIndex: parseInt(getKeyByUniqueValue(columnNames, element[0])),
          columnName: element[0],
          direction: element[1]
        });
    });

    return sortObject;
}

export default formSortObject;
