import React, { Component } from 'react';
import CustomRouter from 'Router';
import TermsConditions from 'TermsConditions';
import history from 'global/history'
import { Router } from 'react-router'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'

import './Application.scss';

import ApiServiceProvider from 'global/services/ApiServiceProvider'
import { isAuthEnabled } from "global/utils/auth";
import Integrate from 'init/Integrate';
import AuthContext from 'AuthContext'
import AuthProvider from 'AuthProvider';
import ClearCache from 'react-clear-cache';

library.add(fal, far)

export default AuthProvider(ApiServiceProvider(class Application extends Component {

    redirectSignIn = () => {
        this.props.onSignIn(true)
    }

    render() {
        if (!isAuthEnabled || !!this.props.account) {
            if (!isAuthEnabled || !!this.props.token) {
                return (
                    <ClearCache>
                        { ({ isLatestVersion, emptyCacheStorage }) => (
                            <div>
                                { !isLatestVersion && (
                                    <p>
                                        <a href="#!" ref={ () => emptyCacheStorage() }>
                                            Updating version...
                                        </a>
                                    </p>
                                ) }

                                { isLatestVersion && (
                                    <Router history={ history }>
                                        <AuthContext.Provider value={ this.props }>
                                            <TermsConditions>
                                                <Integrate>
                                                    <CustomRouter />
                                                </Integrate>
                                            </TermsConditions>
                                        </AuthContext.Provider>
                                    </Router>
                                ) }
                            </div>
                        ) }
                    </ClearCache>
                );
            } else {
                return null;
            }
        } else {
            this.redirectSignIn();
            return null;
        }
    }
}))
