export const issueTypes = Object.freeze({
  "TEMPERATURE":"Temp. issue",
  "UNIT_MODE": "Unit mode issue",
  "POWER_STATE": "Power issue",
  "SILENT": "Silent unit",
  "DATA_ENTRY_ERROR": "Config issue (TMW)",
  "ALARM": "Alarm code",
  "DETENTION": "Detained trailer",
  "INACTIVITY": "Inactive trailer",
  "LOW_FUEL_ALARM": "Low fuel level",
  "DETACHED": "Detached trailer",
  "MISSING": "Missing trailer",
  "INACTIVE_IN_YARD": "Inactive in yard",
  "LOCATION_MISMATCH": "Mismatched trailer",
  "CLAIM": "Trailer with claim",
  "DEFAULT": "Unknown issue"
})

export function issueTypeMap(type) {
  switch(type) {
    case "TEMPERATURE":
      return issueTypes.TEMPERATURE;
    case "UNIT_MODE":
      return issueTypes.UNIT_MODE;
    case "POWER_STATE":
        return issueTypes.POWER_STATE;
    case "SILENT":
      return issueTypes.SILENT;
    case "DATA_ENTRY_ERROR":
      return issueTypes.DATA_ENTRY_ERROR;
    case "ALARM":
      return issueTypes.ALARM;
    case "DETENTION":
      return issueTypes.DETENTION;
    case "INACTIVITY":
      return issueTypes.INACTIVITY;
    case "LOW_FUEL_ALARM":
      return issueTypes.LOW_FUEL_ALARM;
    case "DETACHED":
      return issueTypes.DETACHED;
    case "MISSING":
      return issueTypes.MISSING;
    case "INACTIVE_IN_YARD":
      return issueTypes.INACTIVE_IN_YARD;
    case "LOCATION_MISMATCH":
      return issueTypes.LOCATION_MISMATCH;
    case "CLAIM":
      return issueTypes.CLAIM;
    default:
      return issueTypes.DEFAULT;
  }
}

export const ISSUE_TYPE = Object.freeze({
  TEMPERATURE: "TEMPERATURE",
  UNIT_MODE: "UNIT_MODE",
  POWER_STATE: "POWER_STATE",
  ALARM: "ALARM",
  LOW_FUEL_ALARM: "LOW_FUEL_ALARM",
  SILENT: "SILENT",
  DATA_ENTRY_ERROR: "DATA_ENTRY_ERROR",
  DETENTION: "DETENTION",
  INACTIVITY: "INACTIVITY",
  DETACHED: "DETACHED",
  MISSING: "MISSING",
  INACTIVE_IN_YARD: "INACTIVE_IN_YARD",
  LOCATION_MISMATCH: "LOCATION_MISMATCH",
  CLAIM: "CLAIM"
})

export function getIssuePermissionsForAction(issue, action) {
  const permissions = [`issue:${action}`];

  if (issue) {
    const issueType = ISSUE_TYPE[issue.type].toLowerCase().replaceAll("_", "-");
    permissions.push(`issue:${issueType}:${action}`);

    if (issue.type === ISSUE_TYPE.LOW_FUEL_ALARM && issue.metaData.isAtLocalCompany === "true") {
      permissions.push(`issue:low-fuel-alarm:local:${action}`);
    }
  }

  return permissions;
}

export function issueSnoozeable(issue) {

  const relevant = issue.relevant;
  const issueState = issue.state;

  if (relevant) {
    if (issueState === "WARNED" || issueState === "ESCALATED") {
      return 1;
    } else if (issueState === "SNOOZED") {
      return 2;
    }
  } else {
    return 0;
  }
}
