import React, { Component } from 'react';
import Select from 'react-select';
import { dayOptions, getDayName } from 'global/services/DateTimeService';

import "./DayPicker.scss";

class DayPicker extends Component {

    render() {
        return (
            <Select
                key={this.props.defaultDay}
                className={`day-picker ${this.props.className || ""}`}
                classNamePrefix="select-day-picker"
                options={dayOptions(this.props.startDay, this.props.endDay)}
                defaultValue={this.props.defaultDay && { label: getDayName(this.props.defaultDay), value: this.props.defaultDay }}
                placeholder={this.props.placeholder}
                isSearchable={false}
                onChange={this.props.onChange}
            />
        )
    }
}

export default DayPicker;
