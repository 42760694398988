import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckCircle,
    faComment,
    faExclamationCircle,
    faEye,
    faInfoCircle,
    faQuestionCircle,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import { faAlarmSnooze } from '@fortawesome/pro-solid-svg-icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Loader from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import AuthContext from "AuthContext";
import { reactPlugin } from 'AppInsights';
import SimpleContainer from 'components/container/SimpleContainer';
import DataTableComp from 'components/datatable/DataTableComp';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import TeamIcon from "components/team-icon/TeamIcon";
import ActiveSelection from "components/input/ActiveSelection";
import CanAccess from "components/can-access/CanAccess";
import { defaultGridRecordsNumber, issuesDetailsUrl, mapSort, SortParam } from 'global/constants'
import { issueSnoozeable } from 'global/services/IssueTypeService';
import { getCurrentDate as currentDate, getDifferenceBetweenDates } from "global/services/DateTimeService";
import { mapIssuesFromResponse } from "global/services/IssueApiService";
import QueryParamService from "global/services/QueryParamService";
import { PERMISSION } from 'global/utils/auth';
import deleteComment from 'global/utils/deleteComment';
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import './DetainedTrailers.scss';

const $ = require("jquery");

const CACHE = {};

class DetainedTrailers extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.columnNames = {
            0: SortParam.ASSIGNMENT,
            1: SortParam.TRAILER,
            2: SortParam.COMPANY,
            3: SortParam.DRIVER,
            5: SortParam.START_TIME
        }

        this.columns = [
            { title: "Order #", data: "id" },
            { title: "Trailer #", data: "trailerId" },
            { title: "Customer ID", data: "customerId" },
            { title: "Driver", data: "driver" },
            { title: "Status", data: "status" },
            { title: "Since", data: "created" },
            { title: "Detention location", data: "address" },
            { title: "Nearby company", data: "company" },
            { title: "Detention reason", data: "detentionReason" },
            { title: "Alerts", data: "alerts" },
            { title: "Resolved?", data: "resolved" },
            { title: "Action", data: "action" }
        ];

        this.sortingRule = [];
        this.data = Array.from([]);

        this.columnDefs = [
            { width: "5%", "targets": [0, 1, 9, 10, 11] },
            { width: "10%", "targets": [2, 3, 4, 5, 7, 8] },
            { width: "15%", "targets": [6] },
            { className: "dt-align-center", targets: [11] },
            { className: "dt-align-left", targets: [2, 3, 4, 5, 6, 7, 8, 9, 10] },
            { className: "dt-align-right", targets: [0, 1] },
            {
                orderable: false,
                targets: [4, 6, 7, 8, 9, 10, 11]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="centerAlign" style={{ marginRight: "2%" }}>
                            <TeamIcon color={rowData.division} />
                            &nbsp;&nbsp;
                            <span>{rowData.driver}</span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let mainText = "";
                    let otherText = "";
                    if (rowData.detained) {
                        mainText = "Detained \xa0";
                        otherText = ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                        className += "bold orangeColorText";
                    } else if (rowData.status == null) {
                        mainText = "Unknown"
                        className += "bold redColorText"
                    } else if (rowData.status === true) {
                        mainText = "Moving"
                    } else {
                        mainText = "Stopped"
                        className += "bold orangeColorText"
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <div className={className}>
                                {mainText}
                            </div>
                            <span className={className}>
                                {otherText}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex" style={{ minWidth: '40px' }}>
                            {rowData.companyAddress || rowData.address}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            <span className="no-flex">
                                {rowData.companyName || rowData.company}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            <span>
                                {this.capitalFirstMap(rowData.detentionReason)}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "greenColorText bold";
                    let coloredText = "";
                    let otherText = "";
                    if (!rowData.alerts) {
                        coloredText = "No";
                        className = "redColorText bold";
                        otherText = `  (for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`
                    } else if (rowData.alerts === "Escalation") {
                        coloredText = "Escalation"
                        className = "bold"
                    } else if (rowData.alerts === "Warning") {
                        coloredText = "Warning";
                        className = "bold"
                    }

                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }
                    const showIconStyle = coloredText === "No" ? { display: "none" } : {};

                    ReactDOM.render(
                        <div className="notifications" style={{ minWidth: '80px' }}>
                            <div className={`notification-level ` + className}>
                                {coloredText}
                            </div>
                            <div className="notification-info" style={showIconStyle}>
                                &nbsp;&nbsp;<FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
                            </div>
                            {
                                otherText !== "" &&
                                <div className="greyColorText italic snoozed-info">
                                    <br /> {otherText}
                                </div>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 10,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";
                    if (!rowData.resolved) {
                        className += "bold redColorText";
                        coloredText = "No";
                        otherText += ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                    } else if (rowData.resolved) {
                        className += "greenColorText bold";
                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.resolved)})`;
                        }
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <span className={className}>
                                {rowData.resolved ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faExclamationCircle} />}
                                &nbsp;&nbsp;
                                {coloredText}&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.resolved ? { display: "none" } : { marginRight: 4 }} />
                            </span>
                            <span className="greyColorText italic">{otherText}</span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 11,
                className: "justify",
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass = "";
                    let snoozeIconClass = "";
                    if (issueSnoozeable(rowData.issue) === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeable(rowData.issue) === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon"
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;

                    ReactDOM.render(
                        <div className="flex-always">
                            <div className="seen" id={rowData.id}>
                                <FontAwesomeIcon icon={faEye} className={seenIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={[PERMISSION.ISSUE.SNOOZE, PERMISSION.ISSUE.DETENTION.SNOOZE]}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={snoozeDivClass}>
                                            {snoozeIconClass === "disabled-icon" ? <svg width="16" height="12" style={{ marginTop: "2px" }} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                    <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z" />
                                                    <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z" />
                                                    <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z" />
                                                    <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z" />
                                                    <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z" />
                                                    <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z" />
                                                </svg>
                                                : <FontAwesomeIcon icon={faAlarmSnooze} className={snoozeIconClass} />}
                                        </div>
                                    </>
                                }
                            />
                            &nbsp; &nbsp;
                            <div className="comment">
                                <FontAwesomeIcon icon={faComment} className={commentIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={[PERMISSION.ISSUE.CLOSE, PERMISSION.ISSUE.DETENTION.CLOSE]}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={isRelevant ? "resolve" : "disabled-resolve"}>
                                            <FontAwesomeIcon icon={faTimesCircle} className={isRelevant ? "resolve-icon" : "disabled-icon"} />
                                        </div>
                                    </>
                                }
                            />
                            <CanAccess
                                permissions={ context.permissions } 
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <ReeferControlSvg />
                                    </> 
                                }
                            />
                        </div>,
                        td
                    );
                }
            }
        ];

        let relevantIssues = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : undefined;

        let buttonNode;
        if (!!relevantIssues || relevantIssues === undefined) {
            relevantIssues = true;
            buttonNode = $(`button[id=relevant]`);
        } else {
            relevantIssues = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addActiveLegToQueryString(window.location.search.slice(1), relevantIssues);

        let currentPage = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page') ? parseInt(QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page')) : 1;

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        this.state = {
            account: this.props.account,
            data: this.data,
            columns: this.columns,
            columnDefs: this.columnDefs,
            sort: [],
            activeLeg: relevantIssues,
            sortingRule: this.sortingRule,
            issuesLoaded: false,
            dataUpdated: false,
            showModal: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).detentionIssues !== undefined ? JSON.parse(localStorage.getItem('showModals')).detentionIssues : true,
            doNotShowTutorial: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).detentionIssues !== undefined ? !JSON.parse(localStorage.getItem('showModals')).detentionIssues : false,
            page: currentPage,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            }
        }

        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
    }

    capitalFirstMap(str) {
        if (!str) return null;
        const lowerCase = str.toLowerCase().replace(/_/g, " ")
        return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
    }

    async componentDidMount() {
        await this.fetchAllIssues();
    }

    componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            this.handlePage(1, true);
        }
    }

    onReceiveNewComment(comment) {
        let newData = this.state.data;
        newData.find((data) => data.issue.id === comment.issue.id).comments.unshift(comment);

        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        let newData = this.state.data;

        this.setState({
            allIssues: deleteComment(deletedComment, newData)
        });
    }

    async handlePage(page, force) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchAllIssues(force);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value),
            },
            page: 1,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;

        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchAllIssues();
            }, 1000);
        }

        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    async fetchAllIssues(force) {
        let cachingIndex = this.context.team.slice().join('_');
        cachingIndex += this.state.activeLeg ? '_relevant' : '_irrelevant';

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        let sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
        let tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }

            const response = await this.context.get(issuesDetailsUrl, {
                types: ["DETENTION"],
                active: this.state.activeLeg,
                sort: sort,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                console.error(response.message);
                return {};
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    notificationsLoaded: true,
                    dataUpdated: true
                });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const issues = mapIssuesFromResponse(data);
            const newData = mapGridResponseData(issues, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                data: Array.from([]),
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    onCheckboxChange() {
        this.setState({
            doNotShowTutorial: !this.state.doNotShowTutorial
        });
    }

    openTutorialModal() {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        const existingItem = JSON.parse(localStorage.getItem('showModals'));
        localStorage.setItem('showModals', JSON.stringify({ ...existingItem, ...{ detentionIssues: !this.state.doNotShowTutorial } }));
        this.setState({
            showModal: false
        });
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }

        let queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    changeActiveness = (relevantIssues) => {
        let queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevantIssues);

        this.setState({
            activeLeg: relevantIssues,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber,
            },
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    render() {
        return (
            <div className="container-fluid page detention-issues">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <CanAccess
                                    action={PERMISSION.PAGE.ALL_ISSUES}
                                    yes={<li className="breadcrumb-item"><a href="/issues/all">All issues</a></li>}
                                    no={<li className="breadcrumb-item"><a href="/">Fleet Overview</a></li>}
                                />
                                <li className="breadcrumb-item active"><a href="#!">Detained trailers</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading">
                                Detained trailers
                            </p>
                            <div className="tutorial-info">
                                <p className="tutorial-text"> <FontAwesomeIcon className="tutorial-icon" icon={faQuestionCircle} onClick={this.openTutorialModal.bind(this)}></FontAwesomeIcon></p>
                            </div>
                            &nbsp; &nbsp; <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.issuesLoaded || !this.state.dataUpdated} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="active-toggle">
                            <ActiveSelection activeCallback={this.changeActiveness.bind(this)} initiallyActive={this.state.activeLeg} />
                        </div>
                    </div>
                </div>
                {(!this.state.doNotShowTutorial || this.state.showModal) &&
                    <Modal
                        show={this.state.showModal}
                        onHide={this.handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                        keyboard={false}
                        className="tutorial-modal-detained"
                    >
                        <SimpleContainer className="tutorial-modal-detained" title="DETENTION ALERTS TUTORIAL" modal={true}>
                            <Modal.Body className="tutorial-modal-detained">
                                <div className="row">
                                    <div className="col-12">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", marginBottom: -20 }}>
                                            <tbody>
                                                <tr>
                                                    <td className="align-top">
                                                        <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M43.9917 13.3333H40V9.33333C40 7.125 38.2083 5.33333 36 5.33333H22.6667L17.3333 0H4C1.79167 0 0 1.79167 0 4V28C0 30.2083 1.79167 32 4 32H37.3333C38.7083 32 39.9917 31.2917 40.725 30.1167L47.3833 19.45C49.05 16.7917 47.1333 13.3333 43.9917 13.3333ZM4 4.5C4 4.225 4.225 4 4.5 4H15.675L21.0083 9.33333H35.5C35.775 9.33333 36 9.55833 36 9.83333V13.3333H12.6667C11.2667 13.3333 9.96667 14.0667 9.24167 15.2667L4 23.95V4.5ZM37.3333 28H6L12.4333 17.3333H44L37.3333 28Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }}>
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 1:
                                                        </p>
                                                        <p className="modalText">
                                                            Open TMW Trip Folder.
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="vl-container">
                                                        <div style={{ height: 13 }} class="vl"></div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="modalText modalStepText" style={{ marginLeft: "20%" }} colSpan="5">
                                                        IF LOAD IS BROKER LOAD
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 2:
                                                        </p>
                                                        <p className="modalText">
                                                            If Driver has been detained over 2 hours, call the broker contact to notify them and send a follow up email with the person’s name that you spoke with
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            Follow up if Driver continues to be detained
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.85938 22.375L14.875 17.4062C15.0391 17.2422 15.0391 16.9766 14.875 16.8125L13.8906 15.8203C13.7266 15.6562 13.4609 15.6562 13.2969 15.8203L9.57812 19.5078L7.96875 17.875C7.80469 17.7109 7.53906 17.7109 7.375 17.875L6.38281 18.8594C6.21875 19.0234 6.21875 19.2891 6.38281 19.4531L9.28125 22.375C9.42969 22.5391 9.69531 22.5391 9.85938 22.375ZM26.25 5H20C20 2.24219 17.7578 0 15 0C12.2422 0 10 2.24219 10 5H3.75C1.67969 5 0 6.67969 0 8.75V36.25C0 38.3203 1.67969 40 3.75 40H26.25C28.3203 40 30 38.3203 30 36.25V8.75C30 6.67969 28.3203 5 26.25 5ZM15 3.75C15.6875 3.75 16.25 4.3125 16.25 5C16.25 5.6875 15.6875 6.25 15 6.25C14.3125 6.25 13.75 5.6875 13.75 5C13.75 4.3125 14.3125 3.75 15 3.75ZM26.25 35.625C26.25 35.9688 25.9688 36.25 25.625 36.25H4.375C4.03125 36.25 3.75 35.9688 3.75 35.625V9.375C3.75 9.03125 4.03125 8.75 4.375 8.75H7.5V11.25C7.5 11.9375 8.0625 12.5 8.75 12.5H21.25C21.9375 12.5 22.5 11.9375 22.5 11.25V8.75H25.625C25.9688 8.75 26.25 9.03125 26.25 9.375V35.625ZM8.75 25.625C7.71094 25.625 6.875 26.4609 6.875 27.5C6.875 28.5391 7.71094 29.375 8.75 29.375C9.78906 29.375 10.625 28.5391 10.625 27.5C10.625 26.4609 9.78906 25.625 8.75 25.625ZM21.875 18.75H16.9297C16.8281 18.8906 16.7656 19.0547 16.6406 19.1797L14.5469 21.25H21.875C22.2188 21.25 22.5 20.9688 22.5 20.625V19.375C22.5 19.0312 22.2188 18.75 21.875 18.75ZM21.875 26.25H13.125C12.7812 26.25 12.5 26.5312 12.5 26.875V28.125C12.5 28.4688 12.7812 28.75 13.125 28.75H21.875C22.2188 28.75 22.5 28.4688 22.5 28.125V26.875C22.5 26.5312 22.2188 26.25 21.875 26.25Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 4:
                                                        </p>
                                                        <p className="modalText">
                                                            Ensure that the Driver gets in & out times listed on the BOL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 5:
                                                        </p>
                                                        <p className="modalText">
                                                            Review broker Rate Confirmation for detention payment terms and notify broker via email to request payment
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="modalText modalStepText" style={{ marginLeft: "20%" }} colSpan="5">
                                                        IF LOAD IS CUSTOMER LOAD
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18 11.8125C14.5547 11.8125 11.8125 14.5547 11.8125 18C11.8125 21.4453 14.5547 24.1875 18 24.1875C21.4453 24.1875 24.1875 21.4453 24.1875 18C24.1875 14.5547 21.4453 11.8125 18 11.8125ZM18 20.8125C16.4531 20.8125 15.1875 19.5469 15.1875 18C15.1875 16.4531 16.4531 15.1875 18 15.1875C19.5469 15.1875 20.8125 16.4531 20.8125 18C20.8125 19.5469 19.5469 20.8125 18 20.8125ZM34.3125 16.3125H31.3594C30.5859 10.1953 25.8047 5.41406 19.6875 4.64062V1.6875C19.6875 0.773438 18.9141 0 18 0C17.0859 0 16.3125 0.773438 16.3125 1.6875V4.64062C10.1953 5.41406 5.41406 10.1953 4.64062 16.3125H1.6875C0.773438 16.3125 0 17.0859 0 18C0 18.9141 0.773438 19.6875 1.6875 19.6875H4.64062C5.41406 25.8047 10.1953 30.5859 16.3125 31.3594V34.3125C16.3125 35.2266 17.0859 36 18 36C18.9141 36 19.6875 35.2266 19.6875 34.3125V31.3594C25.8047 30.5859 30.5859 25.8047 31.3594 19.6875H34.3125C35.2266 19.6875 36 18.9141 36 18C36 17.0859 35.2266 16.3125 34.3125 16.3125ZM18 28.125C12.4453 28.125 7.875 23.5547 7.875 18C7.875 12.4453 12.4453 7.875 18 7.875C23.5547 7.875 28.125 12.4453 28.125 18C28.125 23.5547 23.5547 28.125 18 28.125Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 2:
                                                        </p>
                                                        <p className="modalText">
                                                            Identify if the location is for either the PU or DELV, communicate to CSR rep so the customer can be notified, and request detention pay according to the customer’s policies
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            When notifying CSR, ensure to provide all pertinent information, including but not limited to arrival time, if arrival time was after set apt. provide information as to why we arrived late, any messages relayed from the dock to the driver regarding delays, etc. into the email notification to avoid unnecessary back and forth
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr style={{ height: 18 }}>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 4:
                                                        </p>
                                                        <p className="modalText">
                                                            Follow up if Driver continues to be detained
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.85938 22.375L14.875 17.4062C15.0391 17.2422 15.0391 16.9766 14.875 16.8125L13.8906 15.8203C13.7266 15.6562 13.4609 15.6562 13.2969 15.8203L9.57812 19.5078L7.96875 17.875C7.80469 17.7109 7.53906 17.7109 7.375 17.875L6.38281 18.8594C6.21875 19.0234 6.21875 19.2891 6.38281 19.4531L9.28125 22.375C9.42969 22.5391 9.69531 22.5391 9.85938 22.375ZM26.25 5H20C20 2.24219 17.7578 0 15 0C12.2422 0 10 2.24219 10 5H3.75C1.67969 5 0 6.67969 0 8.75V36.25C0 38.3203 1.67969 40 3.75 40H26.25C28.3203 40 30 38.3203 30 36.25V8.75C30 6.67969 28.3203 5 26.25 5ZM15 3.75C15.6875 3.75 16.25 4.3125 16.25 5C16.25 5.6875 15.6875 6.25 15 6.25C14.3125 6.25 13.75 5.6875 13.75 5C13.75 4.3125 14.3125 3.75 15 3.75ZM26.25 35.625C26.25 35.9688 25.9688 36.25 25.625 36.25H4.375C4.03125 36.25 3.75 35.9688 3.75 35.625V9.375C3.75 9.03125 4.03125 8.75 4.375 8.75H7.5V11.25C7.5 11.9375 8.0625 12.5 8.75 12.5H21.25C21.9375 12.5 22.5 11.9375 22.5 11.25V8.75H25.625C25.9688 8.75 26.25 9.03125 26.25 9.375V35.625ZM8.75 25.625C7.71094 25.625 6.875 26.4609 6.875 27.5C6.875 28.5391 7.71094 29.375 8.75 29.375C9.78906 29.375 10.625 28.5391 10.625 27.5C10.625 26.4609 9.78906 25.625 8.75 25.625ZM21.875 18.75H16.9297C16.8281 18.8906 16.7656 19.0547 16.6406 19.1797L14.5469 21.25H21.875C22.2188 21.25 22.5 20.9688 22.5 20.625V19.375C22.5 19.0312 22.2188 18.75 21.875 18.75ZM21.875 26.25H13.125C12.7812 26.25 12.5 26.5312 12.5 26.875V28.125C12.5 28.4688 12.7812 28.75 13.125 28.75H21.875C22.2188 28.75 22.5 28.4688 22.5 28.125V26.875C22.5 26.5312 22.2188 26.25 21.875 26.25Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 5:
                                                        </p>
                                                        <p className="modalText">
                                                            Ensure that the Driver gets in & out times listed on the BOL
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 6:
                                                        </p>
                                                        <p className="modalText">
                                                            If the trailer was dropped for loading or unloading and appears to be detained longer than expected, please notify the CSR email. CSR must notify the customer and request detention pay if appropriate and obtain ETA for completion
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <Button variant="continue" onClick={this.handleClose.bind(this)} style={{ width: "240px", height: "36px", marginTop: "15px" }}>
                                    Got it!
                                </Button>
                                <div className="form-check">
                                    <label className={`checkBoxText ${this.state.doNotShowTutorial ? "bold" : ""}`}>
                                        <input
                                            type="checkbox"
                                            name={this.state.label}
                                            checked={this.state.doNotShowTutorial}
                                            onChange={this.onCheckboxChange.bind(this)}
                                            className="form-check-input"
                                        />
                                        Do not show this tip again
                                    </label>
                                </div>
                            </Modal.Body>
                        </SimpleContainer>
                    </Modal>
                }
                <div className="row">
                    <div className="col">
                        <div className="detained-trailers-div">
                            {this.state.issuesLoaded && <DataTableComp
                                tableId="detainedTrailersIssues"
                                account={this.state.account}
                                columns={this.state.columns}
                                orderRule={this.state.sortingRule}
                                data={this.state.data}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                isDetailView={false}
                                columnDefs={this.state.columnDefs}
                                customTableClass="cell-border"
                                tableHeight="1200px"
                                handlePreviousPage={this.handlePreviousPage}
                                handleNextPage={this.handleNextPage}
                                handleFirstPage={this.handleFirstPage}
                                handleLastPage={this.handleLastPage}
                                handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                                handlePageChange={(event) => this.handlePageChange(event)}
                                pagingInfo={this.state.pagingInfo}
                                sortRule={this.state.sort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAITracking(reactPlugin, DetainedTrailers, "DetainedTrailers");
