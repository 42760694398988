import React, { Component } from 'react';

import LineChartContainer from 'components/container/LineChartContainer';

import './Dashboard.scss';

export default class IssueBox extends Component {
    render() {
        const {
            activeIssuesCount,
            snoozedIssuesCount,
            resolvedIssuesCount,
            groupedIssues,
            tooltip,
            pathname,
            title
        } = this.props;

        let chartData;
        if (groupedIssues.length > 1) {
            chartData = groupedIssues.map((x, y) => ({
                index: y,
                measurement: x
            }));
        } else {
            chartData = [{index: 0, measurement: 1}, {index: 1, measurement: 1}];
        }

        return (
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                <LineChartContainer
                    linkTo={ `/issues/${ pathname }` }
                    title={ title }
                    description={ tooltip }
                    data={ chartData }
                    tooltipPosition="right"
                    className="overview-box"
                    x="index"
                    y="measurement"
                >
                    <div className="status-data">
                        <p className={ `current-active ${activeIssuesCount !== 0 ? "redColorText" : "greenColorText"}` }>
                            { activeIssuesCount }
                            { snoozedIssuesCount !== 0 && (
                                <span className="num-snoozed">
                                    (<b>{ snoozedIssuesCount }</b> snoozed)
                                </span>
                            ) }
                        </p>
                        <p className="num-resolved">
                            <b>{ resolvedIssuesCount }</b> resolved today
                        </p>
                    </div>
                </LineChartContainer>
            </div>
        )
    }
}
