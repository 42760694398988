import React, { Component } from 'react';
import SimpleContainer from 'components/container/SimpleContainer';
import StackedGroupedBar from '../StackedGroupedBar'
import InfoCard from './InfoCard'
import StatsFilter from "../StatsFilter"
import { getCurrentDate, getMonthsBefore, getFullDifferenceBetweenDates } from 'global/services/DateTimeService';
import TeamIcon from 'components/team-icon/TeamIcon'
import { issueInfoUrl, TEAMS } from "global/constants"
import { withRouter } from "react-router";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import { mapDivisionToGraphColor, issuesByTypeUrl, snoozedIssuesUrl, typeIssueInfoUrl, teamMembers} from "global/constants"
import history from 'global/history'
import AuthContext from 'AuthContext'
import { issueTypes } from "global/services/IssueTypeService";
import Loader from 'react-loader-spinner';

import "../Statistics.scss"

const avgIssueResolutionKeys = [
  { name: "avgResolutionTime", label: "average issue resolution time", stackId: "avgResolution", color: "#172437" },
]

const numOfSnoozedKeys = [
  { name: "numSnoozed", label: "Snoozed issues", stackId: "snoozed", color: "#172437" },
]
const formatter = (value) => `${(value)} min`;

const data = Object.keys(issueTypes).map(key => ({label: key}));
const teamData = Object.keys(issueTypes).map(key => ({label: key}));
const snoozedTeamData = Object.keys(issueTypes).map(key => ({label: key}));

class TeamStats extends Component {
    static contextType = AuthContext;

    constructor(props) {
      super(props);
      this.state = {
        filter: {
          startTime: getMonthsBefore(Date.now(), 1),
          endTime: getCurrentDate(Date.now()),
          teams: [],
          issueTypes: []
        },
        dataKeys: [
          { name: "automaticallyResolved", label: "Automatically resolved issues", stackId: "issues", color: mapDivisionToGraphColor("UNK", "automaticallyResolved") },
          { name: "manuallyResolved", label: "Manually resolved issues",  stackId: "issues", color: mapDivisionToGraphColor("UNK", "manuallyResolved")  },
          { name: "unresolved", label: "Unresolved issues",  stackId: "issues", color: mapDivisionToGraphColor("UNK", "unresolved")  },
          { name: "escalations", label: "Escalations",  stackId: "escalations", color: mapDivisionToGraphColor("UNK", "escalations")  },
        ],
        data: data,
        teamData: teamData,
        snoozedTeamData: snoozedTeamData,
        statisticsLoaded: false,
        membersLoaded: false,
        teamMembers: []
      }
    }

    async componentDidMount() {
      window.scrollTo(0, 0);
      if(this.props.match && this.props.match.params.team) {
          if(!TEAMS.find(team => team.name === this.props.match.params.team)) {
            history.push({
              pathname: "/404"
            });
            return;
          }
          const dataKeys = [
            { name: "automaticallyResolved", label: "Automatically resolved issues", stackId: "issues", color: mapDivisionToGraphColor(this.props.match.params.team, "automaticallyResolved") },
            { name: "manuallyResolved", label: "Manually resolved issues",  stackId: "issues", color: mapDivisionToGraphColor(this.props.match.params.team, "manuallyResolved")  },
            { name: "unresolved", label: "Unresolved issues",  stackId: "issues", color: mapDivisionToGraphColor(this.props.match.params.team, "unresolved")  },
            { name: "escalations", label: "Escalations",  stackId: "escalations", color: mapDivisionToGraphColor(this.props.match.params.team, "escalations")  },
          ]
          this.setState({
              team: this.props.match.params.team,
              filter: {
                ...this.state.filter,
                teams: Array.from([this.props.match.params.team === "NONE" ? null : this.props.match.params.team])
              },
              dataKeys: dataKeys,
              data: data,
              teamData: teamData
          }, async () => await this.fetchStatistics());
      }
    }

    async componentDidUpdate(prevProps) {
      if(this.props.match && this.props.match.params && this.props.match.params.team && JSON.stringify(this.props.match.params.team) !== JSON.stringify(prevProps.match.params.team)) {
        if(!TEAMS.find(team => team.name === this.props.match.params.team)) {
          history.push({
            pathname: "/404"
          });
          return;
        }
        const dataKeys = [
          { name: "automaticallyResolved", label: "Automatically resolved issues", stackId: "issues", color: mapDivisionToGraphColor(this.props.match.params.team, "automaticallyResolved") },
          { name: "manuallyResolved", label: "Manually resolved issues",  stackId: "issues", color: mapDivisionToGraphColor(this.props.match.params.team, "manuallyResolved")  },
          { name: "unresolved", label: "Unresolved issues",  stackId: "issues", color: mapDivisionToGraphColor(this.props.match.params.team, "unresolved")  },
          { name: "escalations", label: "Escalations",  stackId: "escalations", color: mapDivisionToGraphColor(this.props.match.params.team, "escalations")  },
        ]

        let newTypes = [];
        let newData = [];
        let newTypeData = [];

        if(this.state.filter.issueTypes && this.state.filter.issueTypes.length === 0) {
          newData = data.map((type => ({...type, disabled: false})));
          newTypeData = teamData.map(type => ({...type, disabled: false}));
        } else {
          newTypes = this.state.filter.issueTypes;
          newData = data.map(type => newTypes.indexOf(type.label) === -1 ? {...type, disabled: true} : {...type, disabled: false});
          newTypeData = teamData.map(type => newTypes.indexOf(type.label) === -1 ? {...type, disabled: true} : {...type, disabled: false});
        }

        this.setState({
            team: this.props.match.params.team || "NONE",
            filter: {
              ...this.state.filter,
              teams: Array.from([this.props.match.params.team === "NONE" ? null : this.props.match.params.team])
            },
            dataKeys: dataKeys,
            data: newData,
            teamData: newTypeData,
            statisticsLoaded: false,
            membersLoaded: false,
        }, async () => await this.fetchStatistics());
      }

    }

    async fetchStatistics() {
      await this.fetchIssuesByType();
      await this.fetchTypeIssueInfo();
      await this.fetchIssueInfo();
      await this.fetchSnoozedIssues();

      this.setState({
        statisticsLoaded: true
      });

      await this.fetchUsers();
      this.setState({
        membersLoaded: true
      });
    }

    async fetchUsers() {
      const response = await this.context.get(teamMembers, {
        team: this.state.team,
      });

      if (response.status === "error") {
        console.error(response.message);
        return {};
      }

      this.setState({
        teamMembers: response
      })
    }


    async fetchIssuesByType() {
      const response = await this.context.get(issuesByTypeUrl, {
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: Array.from([this.state.team])
      });
      if (response.status === "error") {
        console.error(response.message);
        return {};
      }
      this.setState({
        data: response.map(r => ({
          ...r,
          avgIssueResolutionTime: r.avgIssueResolutionTime ? r.avgIssueResolutionTime.toFixed(1) : 0,
          label: r.issueType
        }))
      })
    }

    async fetchTypeIssueInfo() {
      const response = await this.context.get(typeIssueInfoUrl, {
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: Array.from([this.state.team])
      });
      if (response.status === "error") {
        console.error(response.message);
        return {};
      }
      this.setState({
        teamData: response.map(r => ({
          ...r,
          avgResolutionTime: r.avgResolutionTime ? r.avgResolutionTime.toFixed(1) : 0,
          label: r.issueType
        }))
      })
    }

    async fetchSnoozedIssues() {
      const response = await this.context.get(snoozedIssuesUrl, {
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: Array.from([this.state.team])
      });
      if (response.status === "error") {
        console.error(response.message);
        return {};
      }
      this.setState({
        snoozedTeamData: response.map(r => ({
          ...r,
          label: r.issueType,
          numSnoozed: r.count
        }
      ))
      })
    }

    async fetchIssueInfo() {

      const response = await this.context.get(issueInfoUrl, {
        startTimeFrom: this.state.filter.startTime.toDate(),
        endTimeTo: this.state.filter.endTime.toDate(),
        types: this.state.filter.issueTypes,
        teams: Array.from([this.state.team])
      });
      if (response.status === "error") {
        console.error(response.message);
        return {};
      }
      this.setState({
        issueCount: response.issueCount,
        orderCount: response.orderCount,
        escalationsCount: response.escalationsCount,
        snoozedIssueCount: response.snoozedIssueCount,
        resolvedIssueCount: response.resolvedIssueCount,
        avgIssueResolutionTime: response.avgIssueResolutionTime ? response.avgIssueResolutionTime.toFixed(1) : 0
      })
    }

    onFilterChange(selectedTeams, selectedTypes, selectedStartDate, selectedEndDate) {

      // select issue types
      let newTypes = [];
      let newData = [];
      let newTypeData = [];


      if (JSON.stringify(this.state.filter.issueTypes || []) !== JSON.stringify(selectedTypes || [])) {
        if(selectedTypes && selectedTypes.length === 0) {
          newData = this.state.data.map((type => ({...type, disabled: false})));
          newTypeData = this.state.teamData.map(type => ({...type, disabled: false}));
        } else {
          if(selectedTypes.indexOf("ALL") !== -1) {
            selectedTypes = [];
          }
          newTypes = selectedTypes;
          newData = this.state.data.map(type => newTypes.indexOf(type.label) === -1 ? {...type, disabled: true} : {...type, disabled: false});
          newTypeData = this.state.teamData.map(type => newTypes.indexOf(type.label) === -1 ? {...type, disabled: true} : {...type, disabled: false});
        }
      }

      this.setState({
        data: newData,
        teamData: newTypeData,
        filter : {
          ...this.state.filter,
          startTime: selectedStartDate && this.state.filter.startTime !== selectedStartDate ? selectedStartDate : this.state.filter.startTime,
          endTime: selectedEndDate && this.state.filter.endTime !== selectedEndDate ? selectedEndDate : this.state.filter.endTime,
          issueTypes: selectedTypes
        },
        statisticsLoaded: false,
        membersLoaded: false,
      }, async () => await this.fetchStatistics())

      selectedTeams = (selectedTeams || []).map(team => team === "NONE" ? null : team);
      // different team
      if (JSON.stringify(this.state.filter.teams || []) !== JSON.stringify(selectedTeams || [])) {
        if(selectedTeams.indexOf("ALL") !== -1 || !selectedTeams || selectedTeams.length === 0) {
          history.push({
            pathname: "/statistics/all-teams",
            state: {
            }
          })
        } else {
          let difference = selectedTeams.filter(x => this.state.filter.teams.indexOf(x) === -1);
          history.replace({
            pathname: "/statistics/team/" + (difference[0] || "NONE"),
            state: {
              team: difference[0]
            }
          })
        }
      }
    }


    render(){
      return (
        <div className="container-fluid page statistics">
            <div className="row page-heading-row">
              <nav className="col-12">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/statistics/all-teams">Teams stats</a></li>
                    <li className="breadcrumb-item active"><a href="#!">{this.state.team ? (TEAMS.find(team => team.name === this.state.team) || {label: ""}).label : ""}</a></li>
                  </ol>
              </nav>
              <div className="inline">
                <div className="pageHeading col-12">
                  <TeamIcon color={this.state.team} size={36}></TeamIcon>
                  <span className="text with-margin">
                  {this.state.team && (TEAMS.find(team => team.name === this.state.team) || {label: ""}).label}
                  </span>
                  <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.statisticsLoaded}/>
                </div>
              </div>
                <StatsFilter filter={this.state.filter} onFilterChange={this.onFilterChange.bind(this)}></StatsFilter>
              </div>
              <div className="row">
                  <div className="col-12">
                      <InfoCard
                        isTeamCard={true}
                        team={this.state.team}
                        members={this.state.teamMembers}
                        issueCount={this.state.issueCount}
                        resolvedIssueCount={this.state.resolvedIssueCount}
                        avgResolutionTime={this.state.avgIssueResolutionTime}
                        snoozedIssueCount={this.state.snoozedIssueCount}
                        orderCount={this.state.orderCount}
                        escalatedIssueCount={this.state.escalationsCount}
                        membersLoaded={this.state.membersLoaded}
                      >
                      </InfoCard>
                  </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <SimpleContainer className="issues-orders-stats" title="ISSUES AND ORDERS">
                    <StackedGroupedBar simpleXAxis={true} barSize={35}  data={this.state.data} dataKeys={this.state.dataKeys} timeFrame={getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime)}></StackedGroupedBar>
                  </SimpleContainer>
                </div>
              </div>

            <div className="row">
                <div className="col-12">
                  <SimpleContainer className="issues-orders-stats" title="AVERAGE ISSUE RESOLUTION TIME">
                    <StackedGroupedBar minuteBar={true} yTickFormatter={formatter} simpleXAxis={true} barSize={10} data={this.state.teamData} dataKeys={avgIssueResolutionKeys} timeFrame={getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime)}></StackedGroupedBar>
                  </SimpleContainer>
                </div>
                <div className="col-12">
                  <SimpleContainer className="issues-orders-stats" title="SNOOZED ISSUES">
                    <StackedGroupedBar simpleXAxis={true} barSize={10}  data={this.state.snoozedTeamData} dataKeys={numOfSnoozedKeys} timeFrame={getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime)}></StackedGroupedBar>
                  </SimpleContainer>
                </div>
            </div>
          </div>
        );
    }
}


export default withRouter(withAITracking(reactPlugin, TeamStats, "TeamStats"));
;