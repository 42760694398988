import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import EventsFilter from 'pages/events/EventsFilter';
import { EVENT_TYPE, eventsUrl, SortParam } from 'global/constants';
import { formatDate } from 'global/services/DateTimeService';
import { issueTypeMap } from 'global/services/IssueTypeService';
import QueryParamService from 'global/services/QueryParamService';
import formSortObject from 'global/utils/formSortObject';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import { Table } from 'components/table/Table';
import { reactPlugin } from 'AppInsights';
import AuthContext from 'AuthContext';

import './Events.scss';

class Events extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            6: SortParam.TIME
        };

        this.columns = [
            { title: 'Order #', data: 'orderId' },
            { title: 'Trailer #', data: 'trailerId' },
            { title: 'Issue type', data: 'issueType' },
            { title: 'Event type', data: 'eventType' },
            { title: 'Event info', data: 'description' },
            { title: 'Action by', data: 'userName' },
            { title: 'Date and time', data: 'time' }
        ];

        this.columnDefs = [
            { width: '5%', targets: [ 0, 1 ] },
            { width: '15%', targets: [ 2, 3, 5, 6 ] },
            { width: '30%', targets: [ 4 ] },
            { className: 'dt-align-right', targets: [ 6 ] },
            {
                orderable: false,
                targets: [ 0, 1, 2, 3, 4, 5 ]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData) => ReactDOM.render(
                    <div className="event-info">
                        { cellData }
                        { rowData.metadata.recipients && (
                            <FontAwesomeIcon icon={ faInfoCircle } className="info-icon" />
                        ) }
                    </div>,
                    td
                )
            }
        ];

        this.state = {
            isLoading: true,
            filter: this.getFilterFromQueryParams()
        };

        this.fetchEvents = this.fetchEvents.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.search !== this.props.location.search) {
            this.setState({
                filter: this.getFilterFromQueryParams()
            });
        }
    }

    getFilterFromQueryParams() {
        const fromISOString = QueryParamService.parseSimpleValue('from');
        const toISOString = QueryParamService.parseSimpleValue('to');

        return {
            from: fromISOString !== undefined ? new Date(fromISOString) : undefined,
            to: toISOString !== undefined ? new Date(toISOString) : undefined,
            trailerBusinessId: QueryParamService.parseSimpleValue('trailerBusinessId'),
            assignmentBusinessId: QueryParamService.parseSimpleValue('assignmentBusinessId'),
            selectedIssueTypes: JSON.parse(QueryParamService.parseSimpleValue('selectedIssueTypes') || '[]'),
            selectedEventTypes: JSON.parse(QueryParamService.parseSimpleValue('selectedEventTypes') || '[]'),
            selectedUsers: JSON.parse(QueryParamService.parseSimpleValue('selectedUsers') || '[]')
        };
    }

    fetchEvents(sort, pageNumber, pageSize, params) {
        this.setState({ isLoading: true });
        return this.context.get(eventsUrl, { sort, pageNumber, pageSize, ...params })
            .then(response => {
                if (response.status === 'error' || !response.data) {
                    return {
                        data: []
                    };
                }

                QueryParamService.addPageToQueryString(window.location.search.slice(1), pageNumber);
                QueryParamService.addSortToQueryString(window.location.search.slice(1), formSortObject(sort, this.columnNames));

                return {
                    ...response,
                    data: this.mapEvents(response.data)
                };
            })
            .finally(() => this.setState({ isLoading: false }));
    }

    mapEvents(events) {
        return events.map(event => ({
            time: formatDate(new Date(event.time)),
            description: event.description,
            orderId: event.assignment ? event.assignment.businessId : 'No order',
            trailerId: event.trailer.businessId,
            issueType: event.issue ? issueTypeMap(event.issue.type) : '-',
            eventType: EVENT_TYPE[event.type],
            userName: event.userName,
            metadata: event.metadata,
            onClickState: { trailerId: event.trailer.id, assignmentId: (event.assignment || {}).id }
        }));
    }

    onFilterChange(filter) {
        QueryParamService.addParamsToUrl({
            ...filter,
            from: filter.from ? filter.from.toISOString() : undefined,
            to: filter.to ? filter.to.toISOString() : undefined,
            selectedIssueTypes: filter.selectedIssueTypes.length > 0 ? JSON.stringify(filter.selectedIssueTypes) : undefined,
            selectedEventTypes: filter.selectedEventTypes.length > 0 ? JSON.stringify(filter.selectedEventTypes) : undefined,
            selectedUsers: filter.selectedUsers.length > 0 ? JSON.stringify(filter.selectedUsers) : undefined
        });
    }

    getSelectedValues(selectedOptions) {
        return selectedOptions ? selectedOptions.map(option => option.value) : undefined;
    }

    render() {
        const params = {
            from: this.state.filter.from,
            to: this.state.filter.to,
            trailerBusinessId: this.state.filter.trailerBusinessId,
            assignmentBusinessId: this.state.filter.assignmentBusinessId,
            issueTypes: this.getSelectedValues(this.state.filter.selectedIssueTypes),
            eventTypes: this.getSelectedValues(this.state.filter.selectedEventTypes),
            userIds: this.getSelectedValues(this.state.filter.selectedUsers)
        };

        return (
            <div className="container-fluid page events">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="#">
                                All events
                            </Link>
                        </li>
                    </ol>
                </nav>
                <div className="heading-container">
                    <div className="d-flex">
                        <div className="heading">
                            Event history
                        </div>
                        &nbsp; &nbsp;
                        <Loader type="TailSpin"
                                color="#289AC2"
                                height={ 47 }
                                width={ 47 }
                                visible={ this.state.isLoading } />
                    </div>
                    <EventsFilter value={ this.state.filter } onChange={ this.onFilterChange } />
                </div>
                <Table columns={ this.columns }
                       columnDefs={ this.columnDefs }
                       columnNames={ this.columnNames }
                       fetchData={ this.fetchEvents }
                       fetchTimeout={ 250 }
                       params={ params } />
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, Events, 'Events'));
