import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import Button from "react-bootstrap/Button";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowLeft as leftArrowIcon,
    faArrowsRotate as refreshIcon,
    faArrowUpRightFromSquare as linkIcon,
    faDownload as downloadIcon
} from "@fortawesome/pro-solid-svg-icons";

import SimpleContainer from "components/container/SimpleContainer";
import GridList from "components/list/GridList";
import Pagination from "components/paging/Pagination";
import QueryParamService from "global/services/QueryParamService";
import formSortObject from "global/utils/formSortObject";
import history from "global/history";
import {
    defaultListColumnsNumber,
    defaultListRowsNumber,
    fleetUrl,
    mapSort,
    reportUrl,
    SortParam,
    summaryReportUrl
} from "global/constants";
import { reactPlugin } from "AppInsights";
import AuthContext from "AuthContext";
import { formatDateTimeNumeric } from "global/services/DateTimeService";
import Message from "components/Message";
import CanAccess from "components/can-access/CanAccess";
import { PERMISSION } from "global/utils/auth";
import ConfirmPopup from "components/popup/ConfirmPopup";

import "./TrailerTable.scss";

class FleetTrailerList extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        let title = "";
        let breadcrumbTitle = "FLEET";
        if (this.props.match.params.fleetId && this.props.location.state) {
            title = this.props.location.state.name;
            breadcrumbTitle += ` "${this.props.location.state.name}"`;
        }

        const queryString = window.location.search.slice(1);
        const page = QueryParamService.parseSimpleValueFromQueryString(queryString, 'page');
        const sort = formSortObject(QueryParamService.parseSortingQueryString(queryString), { 0: SortParam.TRAILER });

        this.state = {
            title,
            breadcrumbTitle,
            data: [],
            loading: true,
            csvLoading: false,
            pagingInfo: {
                pageNumber: page ? parseInt(page) : 1,
                pageSize: 0,
                available: 0
            },
            sort
        };

        this.onRefreshAuditClick = this.onRefreshAuditClick.bind(this);
        this.onCloseConfirmModal = this.onCloseConfirmModal.bind(this);
        this.fetchTemporaryAudit = this.fetchTemporaryAudit.bind(this);
    }

    async componentDidMount() {
        if (this.props.match.params.fleetId && !this.props.location.state) {
            try {
                const response = await this.context.get(`${fleetUrl}/${this.props.match.params.fleetId}`, {});

                if (response.status !== "error") {
                    this.setState({
                        title: response.name,
                        breadcrumbTitle: `FLEET "${response.name}"`
                    });
                }
            } catch (error) {
                console.error('Error while fetching fleet.', error);
            }
        }

        this.fetchFleetTrailers(this.state.sort, this.state.pagingInfo.pageNumber);
    }

    async fetchFleetTrailers(sort, pageNumber) {
        try {
            this.setState({
                loading: true
            });

            const response = await this.context.get(`${reportUrl}/${this.props.match.params.id}/fleet/trailers`, {
                fleetId: this.props.match.params.fleetId,
                pageNumber,
                pageSize: defaultListRowsNumber * defaultListColumnsNumber,
                sort: mapSort(sort)
            });

            const reportDetails = await this.context.get(summaryReportUrl, { reportId: this.props.match.params.id });
            const temporary = reportDetails.temporary;
            const reportCreated = reportDetails.time;
            const breadcrumb = "Trailer Audit for " + formatDateTimeNumeric(new Date(reportCreated));

            let newerTemporaryExists = false;
            if (!temporary) {
                const temporaryReport = await this.fetchTemporaryAudit();
                const latestReport = await this.context.get(summaryReportUrl, { temporary: false });
                newerTemporaryExists = temporaryReport.time > latestReport.time;
            }

            if (response.status === "error" || !response.data) {
                this.setState({
                    loading: false
                });
                return;
            }

            QueryParamService.addSortToQueryString(window.location.search.slice(1), sort);
            QueryParamService.addPageToQueryString(window.location.search.slice(1), pageNumber);

            this.setState({
                data: response.data.map(trailerFleet => trailerFleet.trailer.businessId),
                loading: false,
                reportCreated,
                newerTemporaryExists,
                breadcrumb,
                temporary,
                pagingInfo: {
                    pageNumber: response.pageNumber,
                    pageSize: response.pageSize,
                    available: response.available
                },
                sort
            });
        } catch (error) {
            console.error('Error while fetching report fleet trailers.', error);
            this.setState({
                loading: false,
                sort
            });
        }
    }

    async exportCsv() {
        try {
            this.setState({
                csvLoading: true
            });

            await this.context.download(`${reportUrl}/${this.props.match.params.id}/fleet/export`, {
                fleetId: this.props.match.params.fleetId,
                sort: mapSort(this.state.sort)
            });
        } catch (error) {
            console.error('Error while downloading report fleet trailers csv.', error);
        } finally {
            this.setState({
                csvLoading: false
            });
        }
    }

    async fetchTemporaryAudit() {
        const response = await this.context.get(`${reportUrl}/temporary`, { });

        this.setState({
            temporaryReport: response
        });

        return response;
    }

    async onRefreshAuditClick() {
        await this.context.post(`${reportUrl}/temporary`, {});
    }

    onCloseConfirmModal(confirmRefresh) {
        this.setState({
            showConfirmPopup: false
        });
        if (confirmRefresh) {
            history.push({
                pathname: "/trailer-audit"
            });
        }
    }

    render() {
        let infoMessage = '';
        let linkButton = '';
        if (this.state.temporary) {
            infoMessage = 'This is a manually generated Temporary Trailer Audit and its action functionality may be disabled for some audit categories.';
            linkButton = (
                <Link className="refresh-button" to={ `/trailer-audit` }>
                    <FontAwesomeIcon icon={ leftArrowIcon } className="icon" />
                    BACK TO TRAILER AUDIT
                </Link>
            );
        } else if (this.state.temporaryReport && !this.state.temporaryReport.completed) {
            infoMessage = 'The Trailer Audit is currently being updated. This may take a couple of minutes.';
        } else if (this.state.temporaryReport && this.state.temporaryReport.completed) {
            infoMessage = `A new temporary Trailer Audit has been manually generated at ${formatDateTimeNumeric(new Date(this.state.temporaryReport.time))}.`;
            linkButton = (
                <Link className="refresh-button" to={ `/trailer-audit/${this.state.temporaryReport.id}` }>
                    <FontAwesomeIcon icon={ linkIcon } className="icon" />
                    OPEN TEMPORARY AUDIT
                </Link>
            );
        }

        const disableRefresh = !this.state.temporary && this.state.temporaryReport && !this.state.temporaryReport.completed;

        const showTemporaryReportMessage = !this.state.loading && (this.state.temporary || this.state.newerTemporaryExists);

        return (
            <div className="container-fluid report-trailers">
                { showTemporaryReportMessage && (
                    <div className="fleet-message">
                        <Message>
                            <div className="message">
                                { infoMessage }
                                { linkButton }
                            </div>
                        </Message>
                    </div>
                ) }
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/trailer-audit/" + this.props.match.params.id}>
                                        { this.state.temporary && "Temporary" } { this.state.breadcrumb ? this.state.breadcrumb : "TRAILER AUDIT" }
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    <Link to={window.location.pathname}>
                                        {this.state.breadcrumbTitle}
                                    </Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading">
                                {this.state.title}
                                &nbsp;
                                {(!this.state.loading || this.state.data.length > 0) && (
                                    <span className="trailer-count">
                                        ({this.state.pagingInfo.available} trailer{this.state.pagingInfo.available !== 1 && "s"})
                                    </span>
                                )}
                                <Loader type="TailSpin" color="#289AC2" height={ 36 } width={ 36 } visible={ this.state.loading } />
                            </p>
                            {(!this.state.loading || this.state.data.length > 0) &&
                                <div className="d-flex">
                                    <CanAccess
                                        action={ [PERMISSION.TRAILER_AUDIT.REFRESH] }
                                        yes={
                                            <button className={ `refresh-button ${disableRefresh && "disabled"}` }
                                                    onClick={ () => this.setState({ showConfirmPopup: true }) }
                                                    disabled={ disableRefresh }>
                                                <FontAwesomeIcon icon={ refreshIcon } className="icon" />
                                                REFRESH
                                            </button>
                                        }
                                    />
                                    <Button variant="continue" onClick={ this.exportCsv.bind(this) }>
                                        <FontAwesomeIcon icon={ downloadIcon } className="add-icon" />
                                        Export CSV
                                        <Loader
                                            className="csv-loader"
                                            type="TailSpin"
                                            color="white"
                                            height={ 13 }
                                            width={ 13 }
                                            visible={ this.state.csvLoading }
                                        />
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="report-trailers-div">
                            <SimpleContainer className="fleet-container" title="LIST OF TRAILERS BY ID">
                                <GridList
                                    header={{ title: "Trailer #", sort: SortParam.TRAILER }}
                                    data={this.state.data}
                                    sort={this.state.sort}
                                    loading={this.state.loading}
                                    onSort={sort => this.fetchFleetTrailers(sort, this.state.pagingInfo.pageNumber)}
                                />
                                <Pagination
                                    className="fleet-pagination"
                                    page={this.state.pagingInfo.pageNumber}
                                    size={this.state.pagingInfo.pageSize}
                                    total={this.state.pagingInfo.available}
                                    onChange={page => this.fetchFleetTrailers(this.state.sort, page)}
                                />
                            </SimpleContainer>
                        </div>
                    </div>
                </div>
                <ConfirmPopup
                    closeModal={ this.onCloseConfirmModal }
                    onConfirm={ this.onRefreshAuditClick }
                    showModal={ this.state.showConfirmPopup }
                    title="REFRESH AUDIT"
                    subtitle="Refresh Audit?"
                >
                    Are you sure you want to initiate the refresh procedure for the Trailer Audit?
                    <br /><br />This manually generated Audit is read-only and will not be stored in the Audit History. You will still be able to access the current Trailer Audit via side navigation bar.
                </ConfirmPopup>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, FleetTrailerList));
