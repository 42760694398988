import React, { Component } from 'react';
import { DatePicker, Select } from 'antd';
import TeamIcon from 'components/team-icon/TeamIcon'
import { TEAMS } from 'global/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { formatDate, getCurrentDate, getCurrentDateWithoutTime, getRange, getMonthsBefore } from 'global/services/DateTimeService';
import { issueTypes } from 'global/services/IssueTypeService';

import "./StatsFilter.scss"
const { Option } = Select;

export default class StatsFilter extends Component {

  constructor(props) {
    super(props);
    const children = (props.users || []).map(user => <Option key={JSON.stringify(user)}>{user.name}</Option>)
    this.state = {
      selectedTeams: this.setTeamSelected(props.filter.teams || ["ALL"]),
      selectedTypes: this.setTypeSelected(props.filter.issueTypes || ["ALL"]),
      selectedStartDate: props.startTime || getCurrentDate(getMonthsBefore(Date.now(), 1)),
      selectedEndDate: props.endTime || getCurrentDate(Date.now()),
      children,
      loading: false,
      searched: false
    }
    this.wrapperRef = React.createRef();
    this.setTeamSelected = this.setTeamSelected.bind(this);
    this.setTypeSelected = this.setTypeSelected.bind(this);

  }


  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  componentDidUpdate(prevProps) {
    if (this.props.filter.teams !== prevProps.filter.teams) {
      this.setState({
        selectedTeams: this.setTeamSelected(this.props.filter.teams && this.props.filter.teams.length > 0 ? this.props.filter.teams : ["ALL"])
      });
    }
    if(this.props.filter.issueTypes !== prevProps.filter.issueTypes) {
      this.setState({
        selectedTypes: this.setTypeSelected(this.props.filter.issueTypes && this.props.filter.issueTypes.length > 0 ? this.props.filter.issueTypes : ["ALL"])
      });
    }

    if(this.props.users !== prevProps.users && !this.state.searched) {
      const children = [...this.state.children, ...(this.props.users || []).map(user => <Option key={JSON.stringify(user)}>{user.name}</Option>)];
      this.setState({
        children
      })
    } else if (this.props.users !== prevProps.users) {
      this.setState({
        children: [(this.props.users || []).map(user => <Option key={JSON.stringify(user)}>{user.name}</Option>)]
      })
    }
  }

  setTeamSelected(teams) {
    let selectedTeams = [];
    teams.forEach(team => {
      selectedTeams[team || "NONE"] = true;
    })
    return selectedTeams;
  }

  setTypeSelected(types) {
    let selectedTypes = [];
    types.forEach(team => {
      selectedTypes[team] = true;
    })
    return selectedTypes;
  }

  handleClickOutside(event) {
      if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
          this.setState({ showTeamDropdown: false, showTypeDropdown: false });
      }
  }

  onStartDateChange(date, dateString) {
    this.setState(
      { selectedStartDate: date ? date : getMonthsBefore(Date.now(), 1)},
      async () => await this.handleStateChange(), 
    );
  }

  onEndDateChange(date, dateString) {
    this.setState(
      { selectedEndDate: date ? date : getCurrentDate(Date.now()) },
      async () => await this.handleStateChange(), 
    );
  }

  onTeamSelectChange(event) {
    const target = event.target;
    let selectedTeams = this.state.selectedTeams;
    selectedTeams[target.name] = target.checked;
    if (selectedTeams["ALL"] && target.name !== "ALL") {
      selectedTeams["ALL"] = false;
    } else if (target.name === "ALL" && target.checked) {
      selectedTeams = [];
      selectedTeams["ALL"] = true;
    }
    this.setState(
        { selectedTeams },
        async () => await this.handleStateChange(), 
    );
  }


  onTypeSelectChange(event) {
    const target = event.target;
    let selectedTypes = this.state.selectedTypes;
    selectedTypes[target.name] = target.checked;
    if (selectedTypes["ALL"] && target.name !== "ALL") {
      selectedTypes["ALL"] = false;
    } else if (target.name === "ALL" && target.checked) {
      selectedTypes = [];
      selectedTypes["ALL"] = true;
    }
    this.setState(
        { selectedTypes},
        async () => await this.handleStateChange(), 
    );
  }

  toggleTeamDropdown() {
    this.setState(state => ({ showTeamDropdown: !state.showTeamDropdown, showTypeDropdown: false, showUserDropdown: false }));

  }

  toggleTypeDropdown() {
    this.setState(state => ({ showTypeDropdown: !state.showTypeDropdown, showTeamDropdown: false, showUserDropdown: false }));
  }

  toggleUserDropdown() {
    this.setState(state => ({ showUserDropdown: !state.showUserDropdown, showTeamDropdown: false, showTypeDropdown: false  }));
  }

  isTeamMultiSelection() {
    return this.getTeamSelection().length > 1;
  }

  getTeamSelection() {
      return Object.entries(this.state.selectedTeams)
          .filter(([_, value]) => value)
          .map(([key, _]) => key);
  }

  isTypeMultiSelection() {
    return this.getTypeSelection().length > 1;
  }

  getTypeSelection() {
      return Object.entries(this.state.selectedTypes)
          .filter(([_, value]) => value)
          .map(([key, _]) => key);
  }

 
  handleChange = (value) => {
    const user = JSON.parse(value);
    this.props.userSelected(user);
  }

  async onSearchChange(search) {
    if (!search || search.length < 2) return;
    const that = this;
    if(!this.timer)
    {
      this.timer = setTimeout(function(){
        that.setState({loading: true}, async () => {
          const newChildren = await that.props.searchUser(search);
          that.setState({ searched: true, children:  (newChildren || []).map(user => <Option key={JSON.stringify(user)}>{user.name}</Option>)}, ()=>{
              that.setState({loading: false})
          })
          that.timer = null;
        })
      }, 300)
    }
  }

  onScroll = (event) => {
    var target = event.target
    if (!this.state.loading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      this.setState({ loading: true }, async () => {
        target.scrollTo(0, target.scrollHeight);
        const newChildren = await this.props.fetchMoreUsers();
        const children = [...this.state.children, ...(newChildren || []).map(user => <Option key={JSON.stringify(user)}>{user.name}</Option>)];
        this.setState({ searched: false, children: children}, ()=>{
            this.setState({ loading: false })
        })
      })
    }
  }


  async handleStateChange() {
    const selectedTeams = this.getTeamSelection();
    const selectedTypes = this.getTypeSelection();
    const selectedStartDate = this.state.selectedStartDate;
    const selectedEndDate = this.state.selectedEndDate;
    await this.props.onFilterChange(selectedTeams, selectedTypes, selectedStartDate, selectedEndDate);
  }

  render() {
    const ALL_TEAMS = [...[{ "name": "ALL", "label": "Select all"}], ...TEAMS];
    const ALL_TYPES = {...{"ALL": "Select all"}, ...issueTypes};

    const items = ALL_TEAMS.map(team => {
      return (
          <li className="team-picker-item" key={"team-picker-"+team.name}>
              <input
                  type="checkbox"
                  id={"team-picker-" + team.name}
                  name={team.name}
                  checked={!!this.state.selectedTeams[team.name]}
                  style={{width: "12px", height: "12px"}}
                  onChange={this.onTeamSelectChange.bind(this)} />
              <label htmlFor={"team-picker-"+team.name}> <TeamIcon color={team.name} size="12" /> <span>&nbsp;&nbsp;</span>{team.label}</label>
          </li>
      );
    })
    let issueTypeItems = [];
    for (const [key, value] of Object.entries(ALL_TYPES)) {
      if (key === "DEFAULT") continue;
      issueTypeItems.push(
        <li className="issue-type-picker-item" key={"type-picker-"+key}>
        <input
            type="checkbox"
            id={"type-picker-" + key}
            name={key}
            checked={!!this.state.selectedTypes[key]}
            style={{width: "12px", height: "12px"}}
            onChange={this.onTypeSelectChange.bind(this)} />

        <label htmlFor={"type-picker-"+key}> <span>&nbsp;&nbsp;</span>{value}</label>
    </li>
      )
    }
    let teamLabel;
    if (this.isTeamMultiSelection()) {
      teamLabel = <span className="multiple-teams"> <span>Multiple </span> <FontAwesomeIcon className="chevron-icon" icon={faChevronDown} /></span>;
    } else {
        const selected = this.getTeamSelection()[0];
        const team = ALL_TEAMS.find(team => team.name === selected);
        const color = selected;
        teamLabel = <span className="multiple-teams"> {((team || {}).label && team.name !== "ALL" && <><TeamIcon color={color} size="12" /> <span>{(team || {}).label.split(' ')[0]}</span></>) || <span className="not-selected">All teams</span>} <FontAwesomeIcon className="chevron-icon" icon={faChevronDown} /> </span>;
    }

    let typeLabel;
    if (this.isTypeMultiSelection()) {
      typeLabel = <span className="multiple-types"> <span>Multiple </span> <FontAwesomeIcon className="chevron-icon" icon={faChevronDown} /></span>;
    } else {
        const selected = this.getTypeSelection()[0];
        const type = ALL_TYPES[selected];
        typeLabel = <span className="multiple-types"> {type && type !== "Select all" ? <span>{type}</span> : <span className="not-selected">All issues</span>} <FontAwesomeIcon className="chevron-icon" icon={faChevronDown} /> </span>;
    }

    const startDateClassName = this.props.filter.startTime.isSame(getMonthsBefore(Date.now(), 1)) ? "picker" : "not-selected picker"
    const endDateClassName = this.props.filter.endTime.isSame(getCurrentDate(Date.now())) ? "picker" : "not-selected picker"

    return (
      <>
      <form onSubmit={this.onSubmit} onKeyPress={this.onKey} className="filter-form">
        <div className="start-date-div">
          <label htmlFor="start-date" >Start date</label>
            <DatePicker className={startDateClassName}
                        allowClear
                        onChange={this.onStartDateChange.bind(this)}
                        showNow={false}
                        onSelect={this.onStartDateChange.bind(this)}
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        value={this.props.filter.startTime}
                        disabledDate={d => !d || d.isSameOrAfter(getCurrentDate())}
                        placeholder={formatDate(getCurrentDate())}
            />

        </div>
        <div className="end-date-div">
          <label htmlFor="end-date">End date</label>
          <DatePicker   className={endDateClassName}
                        allowClear
                        showNow={false}
                        showTime={{ format: 'HH:mm' }}
                        format="YYYY-MM-DD HH:mm"
                        value={this.props.filter.endTime}
                        onSelect={this.onEndDateChange.bind(this)}
                        disabledTime={d => (!d || d.isSame(getCurrentDateWithoutTime())) && {
                          disabledHours: () => getRange(getCurrentDateWithoutTime().hour() + 1, 24), 
                          disabledMinutes: (hour) => (hour === getCurrentDateWithoutTime().hour() ? getRange(getCurrentDateWithoutTime().minutes() + 1, 60) : []
                        )}}
                        disabledDate={d => !d || d.isSameOrBefore(this.state.selectedStartDate) || d.isSameOrBefore("1960-01-01") } 
                        placeholder={formatDate(getCurrentDate())}
                        onChange={this.onEndDateChange.bind(this)}
                        onOk={this.onEndDateChange.bind(this)} />
        </div>
        <div style={{display: "inline-flex"}} ref={this.wrapperRef}>
          <div className="team-select-div">
            <label htmlFor="team-select">Team</label>
            <div className="picker team-select-picker" id="team-select">
                <div className={`picker-label`} onClick={this.toggleTeamDropdown.bind(this)}>
                      {teamLabel}
                </div>
                <ul className={"team-drop-menu " + (this.state.showTeamDropdown ? 'shown' : 'hidden')}>
                    {items}
                </ul>
            </div>
          </div>
          <div className="issue-type-select-div">
            <label htmlFor="issue-type-select">Issue type</label>
            <div className="picker issue-type-select-picker" id="type-select">
                <div className={`picker-label`} onClick={this.toggleTypeDropdown.bind(this)}>
                      {typeLabel}
                </div>
                <ul className={"issue-type-drop-menu " + (this.state.showTypeDropdown ? 'shown' : 'hidden')}>
                    {issueTypeItems}
                </ul>
            </div>
          </div>
          {this.props.filterUser && <div className="issue-type-select-div">
            <label htmlFor="user-select">User</label>
              <Select
                suffixIcon={<FontAwesomeIcon className="chevron-icon" icon={faChevronDown} />}
                showSearch
                onSearch={search => this.onSearchChange(search)}
                placeholder={this.props.filterUser || "Select user"}
                value={this.state.value}
                className="picker picker-label"
                onChange={this.handleChange}
                onPopupScroll={this.onScroll}
                filterOption={false}
              >
              {!this.state.loading ? this.state.children : [...this.state.children, <Option key="loading">Loading...</Option>]}
              </Select>
          </div>}
        </div>
      </form>
      </>
    )
  }
}
