import React, { Component } from 'react';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Loader from 'react-loader-spinner';

import history from "global/history";
import { formatDate, getDateWithOffset } from "global/services/DateTimeService";
import { assignmentWithLegsUrl, issuesDetailsUrl } from "global/constants";
import { mapIssuesFromResponse } from "global/services/IssueApiService";
import { ISSUE_TYPE, issueTypeMap } from "global/services/IssueTypeService";
import { PERMISSION } from "global/utils/auth";
import SimpleContainer from 'components/container/SimpleContainer';
import CanAccess from "components/can-access/CanAccess";
import AlarmIssueTable from "pages/trailer/AlarmIssueTable";
import LegCard from "./LegCard";
import IssueTable from "./IssueTable";
import { reactPlugin } from 'AppInsights';
import AuthContext from "AuthContext";

import './DetailView.scss';

const noDataString = "NO DATA";
const CACHE = {};

class AssignmentDetailView extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.cacheIndex = 'assignment_' + this.props.match.params.assignmentId;

    this.state = {
      loaded:false,
      activeAlarmIssues: [],
      previousAlarmIssues: [],
      issuesList: [],
      statusClassName: "gathered-info redColorText",
      lastKnownLocationClassName: "gathered-info greenColorText",
      account: props.account,
      relevantLegIssues: [],
      irrelevantLegIssues: []
    }

    this.fetchAlarmIssues = this.fetchAlarmIssues.bind(this);
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params.assignmentId) {
      Promise.all([ this.fetchAssignment(), this.fetchAlarmIssues() ])
          .catch(console.error)
          .finally(() => this.setState({ loaded: true }));
    }
  }

  getTemperatures(issues, assignmentMinTemp, assignmentMaxTemp){
    const issue = issues.find(issue => issue.type === issueTypeMap("DATA_ENTRY_ERROR") && issue.state.indexOf("RESOLVED") === -1 && Object.keys(issue.metaData).length !== 0);
    let minTemp = (assignmentMinTemp === null || assignmentMinTemp === undefined) ? noDataString : assignmentMinTemp;
    let maxTemp = (assignmentMaxTemp === null || assignmentMaxTemp === undefined) ? noDataString : assignmentMaxTemp;
    if(issue){
      if(isFinite(issue.metaData.temperatureMin)) {
          minTemp = issue.metaData.temperatureMin;
      } else {
          minTemp = 999;
      }
      if(isFinite(issue.metaData.temperatureMax)) {
          maxTemp = issue.metaData.temperatureMax;
      } else {
          maxTemp = 999;
      }
    }
    return {
      minTemp,
      maxTemp
    }
  }

  mapAssignmentData(assignment, legInfoList, relevantLeg, relevantLegIssues, inactiveLegs, irrelevantLegIssues) {
    return {
      assignmentId: assignment.id,
      assignmentBusinessId: assignment.businessId,
      assignmentStatus: assignment.active ? "Started" : "Completed",
      trailerBusinessId: relevantLeg ? relevantLeg.leg.trailer.businessId : "Unknown",
      driverName: ((((relevantLeg || {}).leg || {}).driver || {}).contactInfo || {}).name || "Unknown",
      driverPhone: ((((relevantLeg || {}).leg || {}).driver || {}).contactInfo || {}).phone || "Unknown",
      driverMail: ((((relevantLeg || {}).leg || {}).driver || {}).contactInfo || {}).email || "Unknown",
      team: (((((relevantLeg || {}).leg || {}).driver || {}).division || {}).contactInfo || {}).name || ((((relevantLeg || {}).leg || {}).driver || {}).division || {}).businessId || "Unknown",
      teamMail: (((((relevantLeg || {}).leg || {}).driver || {}).division || {}).contactInfo || {}).email || "Unknown",
      insideSalesRepName: ((assignment.insideSalesRep || {}).contactInfo || {}).name || "Unknown",
      mode: assignment.unitMode === "CONTINUOUS" ? "Continuous": "Cycle-Sentry",
      minTemp: assignment.temperatureMin || this.getTemperatures(relevantLegIssues, assignment.temperatureMin, assignment.temperatureMax).minTemp,
      maxTemp: assignment.temperatureMax || this.getTemperatures(relevantLegIssues, assignment.temperatureMin, assignment.temperatureMax).maxTemp,
      expectedPower: assignment.temperatureControlled ? "On" : "Off",
      legs: legInfoList,
      irrelevantLegIssues,
      relevantLegIssues,
      relevantLeg: relevantLeg,
      inactiveLegs
    }
  }

  async fetchAssignment() {
    if (CACHE[this.cacheIndex] !== undefined) {
      this.setState(CACHE[this.cacheIndex].mappedAssignmentData);
    } 

    const response = await this.context.get(assignmentWithLegsUrl, { id: this.props.match.params.assignmentId }, true);
    if(response.status === "error" || !response || response.length <= 0 || !response.assignment) {
       history.push("/404");
       return;
    }
    const assignment = response.assignment;
    if(assignment.temperatureMax === null || assignment.temperatureMin === undefined) {
      assignment.temperatureMin = null;
    } else if (!isFinite(assignment.temperatureMin)) {
      assignment.temperatureMin = 999
    }
    if(assignment.temperatureMax === null || assignment.temperatureMax === undefined) {
      assignment.temperatureMax = null;
    } else if (!isFinite(assignment.temperatureMax)) {
      assignment.temperatureMax = 999
    }

    const relevantLeg = response.legInfoList && response.relevantLeg ? response.legInfoList.find(legInfo => legInfo.leg.id === response.relevantLeg.id) : null;
    const relevantLegIssues = relevantLeg && relevantLeg.issues ? mapIssuesFromResponse(relevantLeg.issues) : [];
    const inactiveLegs = response.legInfoList ? Array.from(response.legInfoList.filter(legInfo => !relevantLeg || legInfo.leg.id !== relevantLeg.leg.id)) : [];
    const irrelevantLegIssues = inactiveLegs.length > 0 ? mapIssuesFromResponse(inactiveLegs.map(leg => leg.issues).flat(1)) : [];
    const mappedAssignmentData = this.mapAssignmentData(assignment, response.legInfoList, relevantLeg, relevantLegIssues, inactiveLegs, irrelevantLegIssues);

    CACHE[this.cacheIndex] = {
      ...CACHE[this.cacheIndex],
      mappedAssignmentData
    };
    
    this.setState(mappedAssignmentData);
  }

  async fetchIssues(sort, active) {
    if (CACHE[this.cacheIndex] !== undefined) {
      if (active && CACHE[this.cacheIndex].activeLegIssues !== undefined) {
        this.setState({
          activeLegIssues: CACHE[this.cacheIndex].activeLegIssues
        });
      } else if (!active && CACHE[this.cacheIndex].inactiveLegIssues !== undefined) {
        this.setState({
          inactiveLegIssues: CACHE[this.cacheIndex].inactiveLegIssues
        });
      }
    }

    const legIds = active ? [this.state.relevantLeg.leg.id] : this.state.inactiveLegs.map(inactiveLeg => inactiveLeg.leg.id);

    let response = await this.context.get(issuesDetailsUrl, { excludeTypes: [ISSUE_TYPE.ALARM], legIds, sort: sort || [], trailerId: this.props.match.params.trailerId, assignmentId: this.state.assignmentId }, true);
    if(response.status === "error" || !response || !response.data) {
      return {}
    }

    const issues = mapIssuesFromResponse(response.data);

    if (active) {
      CACHE[this.cacheIndex] = {
        ...CACHE[this.cacheIndex],
        activeLegIssues: issues
      };

      this.setState({
        relevantLegIssues: issues
      })
    } else {
      CACHE[this.cacheIndex] = {
        ...CACHE[this.cacheIndex],
        inactiveLegIssues: issues
      };

      this.setState({
        irrelevantLegIssues: issues
      })
    }
  }

  async fetchAlarmIssues(sort) {
    if (CACHE[this.cacheIndex] !== undefined) {
      this.setState(CACHE[this.cacheIndex].mappedAlarmIssues);
    }

    const response = await this.context.get(issuesDetailsUrl, {
      types: [ 'ALARM', 'LOW_FUEL_ALARM' ],
      assignmentId: this.props.match.params.assignmentId,
      sort: sort || []
    });

    if (!response || 'error' === response.status) {
      console.error(response.message);
      return;
    }

    const issues = mapIssuesFromResponse(response.data);
    const mappedAlarmData = {
      activeAlarmIssues: issues.filter(issue => !issue.resolved),
      previousAlarmIssues: issues.filter(issue => issue.resolved),
    };

    CACHE[this.cacheIndex] = {
      ...CACHE[this.cacheIndex],
      mappedAlarmIssues: mappedAlarmData
    };

    this.setState({
      activeAlarmIssues: mappedAlarmData.activeAlarmIssues,
      previousAlarmIssues: mappedAlarmData.previousAlarmIssues,
    });
  }

  sortFunction(a, b) {
      const checkNulls = (!a.firstMeasurement) - (!b.firstMeasurement);
      const activeCheck = (a.leg.active === b.leg.active) ? 0 : a.leg.active ? -100 : 100;
      let datesCheck = null;
      if(a.legInfos && b.legInfos) {
        datesCheck = -(a.firstMeasurement > b.firstMeasurement) || +(a.firstMeasurement < b.firstMeasurement)
      }
      return datesCheck || checkNulls || activeCheck;
  }

  render() {
    const currentTrailerInfo = {
        activeTrailerId: this.state.relevantLeg && this.state.relevantLeg.leg && this.state.relevantLeg.leg.trailer && this.state.relevantLeg.leg.trailer.businessId,
        mode: this.state.mode,
        actualMode: this.state.relevantLeg && this.state.relevantLeg.lastMeasurement.modeState.unitMode ? this.state.relevantLeg.lastMeasurement.modeState.unitMode === "CONTINUOUS" ? "Continuous" : "Cycle-Sentry" : null,
        minTemp: this.state.minTemp,
        maxTemp: this.state.maxTemp,
        setPoint: this.state.relevantLeg && this.state.relevantLeg.lastMeasurement.temperatureState.setTemperature,
        returnAir: this.state.relevantLeg && this.state.relevantLeg.lastMeasurement.temperatureState.actualTemperature,
        detentionState: this.state.relevantLeg && this.state.relevantLeg.lastMeasurement.detentionState,
        inactivityState: this.state.relevantLeg && this.state.relevantLeg.lastMeasurement,
        expectedPower: this.state.expectedPower,
        power: this.state.relevantLeg && (this.state.relevantLeg.lastMeasurement.powerState.power === true ? "On" : this.state.relevantLeg.lastMeasurement.powerState.power === false ? "Off" : null),
        issuesList: this.state.relevantLeg && this.state.relevantLegIssues,
        driverName: this.state.driverName,
        driverMail: this.state.driverMail,
        driverPhone: this.state.driverPhone,
        team: this.state.team,
        teamMail: this.state.teamMail,
        insideSalesRepName: this.state.insideSalesRepName,
        relevantLeg: this.state.relevantLeg
    }

    const pendingStatus = ['AVL', 'PLN', 'PND'];
    return (
      <>
      <div className="container-fluid page detailview assignmentDetails">
        {
          <div className="row">
            <nav className="col-12">
              <ol className="breadcrumb">
                  <CanAccess
                      action={ PERMISSION.PAGE.ALL_ISSUES }
                      yes={ <li className="breadcrumb-item"><a href="/issues/all">All issues</a></li> }
                      no={ <li className="breadcrumb-item"><a href="/">Fleet Overview</a></li> }
                  />
                  <li className="breadcrumb-item active"><a href="#!">Order #{this.state.assignmentBusinessId}</a></li>
                  <NavLink to="/" className="back-link">
                      <p>
                          &larr; Back to dashboard
                      </p>
                  </NavLink>
              </ol>
            </nav>
          <div className="orderHeading col-12">
            Order #{this.state.assignmentBusinessId} <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.loaded}/>
          </div>
            <CanAccess
              action={ PERMISSION.PAGE.ASSIGNMENT.LEGS }
              yes={
                  <div className="col-12">
                    {this.state.legs && this.state.legs.sort((a, b) => this.sortFunction(a,b)).map((legInfos, legX) => {
                      const leg = legInfos.leg;
                      const legInfo = {
                        assignmentId: leg.assignment.id,
                        trailerId: leg.trailer.id,
                        legBusinessId: leg.businessId,
                        trailerBusinessId: leg.trailer.businessId,
                        resolvedIssues: legInfos.issues.filter(function(element){ return element.issue && element.issue.state && element.issue.state.startsWith("RESOLVED")}).length + this.state.previousAlarmIssues.filter(issue => issue.leg.id === leg.id).length,
                        issues: legInfos.issues.length + this.state.activeAlarmIssues.filter(issue => issue.leg.id === leg.id).length + this.state.previousAlarmIssues.filter(issue => issue.leg.id === leg.id).length,
                        legStatus: leg.active ? (legInfos.lastMeasurement ? (legInfos.lastMeasurement.positionState.moving ? "Moving" : "Stopped") : "Silent") : (this.state.relevantLeg && leg.id === this.state.relevantLeg.leg.id) || leg.startDate > new Date() || pendingStatus.indexOf(leg.legStatus) !== -1 ? "Pending" : "Finished",
                        active: leg.active,
                        driver: ((leg.driver || {}).contactInfo || {}).name || "Unknown",
                        startDate: leg.startTime ? formatDate(getDateWithOffset(leg.startTime, leg.startTimeOffset), true) : "Unknown",
                        endDate: leg.endTime ? formatDate(getDateWithOffset(leg.endTime, leg.endTimeOffset), true) : "Unknown",
                        location: legInfos.lastMeasurement ? legInfos.lastMeasurement.positionState.position || "Unknown" : "Unknown"
                      }
                      return (
                          leg.active || (this.state.relevantLeg && leg.id === this.state.relevantLeg.leg.id) ?
                              <LegCard key={legX} className="active" legInfo={legInfo} currentInfo={leg.active === true || (this.state.relevantLeg && leg.id === this.state.relevantLeg.leg.id) ? currentTrailerInfo : null} isRelevant={this.state.relevantLeg ? leg.id === this.state.relevantLeg.leg.id : false}></LegCard>
                              : <LegCard key={legX} className="inactive" legInfo={legInfo} currentInfo={leg.active === true || (this.state.relevantLeg && leg.id === this.state.relevantLeg.leg.id) ? currentTrailerInfo : null} isRelevant={this.state.relevantLeg ? leg.id === this.state.relevantLeg.leg.id : false}></LegCard>
                      )
                    })}
                    {
                      !this.state.legs && <LegCard key={0} className="active" legInfo={{}} currentInfo={null}></LegCard>
                    }
                  </div>
              }
            />

            <CanAccess
              action={ PERMISSION.PAGE.ASSIGNMENT.ISSUES }
              yes={
                  (this.state.relevantLegIssues.length > 0 || this.state.irrelevantLegIssues.length > 0) && (
                    <div className="col-12 col-lg-12">
                      <SimpleContainer title="ISSUES HISTORY">
                        <IssueTable relevantLegIssues={this.state.relevantLegIssues || []} irrelevantLegIssues={this.state.irrelevantLegIssues || []} account={this.state.account} fetchIssues={this.fetchIssues.bind(this)}></IssueTable>
                      </SimpleContainer>
                    </div>
                  )
              }
            />

            <CanAccess
                action={ PERMISSION.PAGE.ASSIGNMENT.ALARMS }
                yes={
                  <>
                    { this.state.activeAlarmIssues.length > 0 && (
                        <div className="col-12">
                          <SimpleContainer title="ACTIVE ALARM ISSUES">
                            <AlarmIssueTable tableName="activeAlarmIssues"
                                             account={ this.state.account }
                                             includeTrailerId
                                             alarmIssues={ this.state.activeAlarmIssues }
                                             fetchIssues={ this.fetchAlarmIssues } />
                          </SimpleContainer>
                        </div>
                    ) }
                    { this.state.previousAlarmIssues.length > 0 && (
                        <div className="col-12">
                          <SimpleContainer title="PREVIOUS ALARM ISSUES">
                            <AlarmIssueTable tableName="previousAlarmIssues"
                                             account={ this.state.account }
                                             includeTrailerId
                                             alarmIssues={ this.state.previousAlarmIssues }
                                             fetchIssues={ this.fetchAlarmIssues } />
                          </SimpleContainer>
                        </div>
                    ) }
                  </>
                }
            />
        </div>
      }
    </div>
    </>
    )
  }
}

export default withRouter(withAITracking(reactPlugin, AssignmentDetailView, "AssignmentDetailView"));
