import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { groupToRuleUrl, notificationRulesUrl } from 'global/constants';
import { ISSUE_TYPE } from 'global/services/IssueTypeService';
import AuthorizationService from "global/services/AuthorizationService";
import { PERMISSION } from 'global/utils/auth';
import TextTooltip from 'components/tooltip/TextTooltip';
import Pill from 'components/input/Pill';
import AuthContext from "AuthContext";

import "./NotificationToggles.scss";

class NotificationToggles extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            showTooltip: false
        }
    }

    handleNotificationToggle = async (checked, rule, issueType, notificationReason, contactGroup) => {
        let response;
        try {
            if (checked) {
                response = await this.context.delete(groupToRuleUrl, {
                    notificationRuleId: rule.id,
                    contactGroupId: contactGroup.id
                });
            } else {
                if (!rule) {
                    response = await this.context.post(notificationRulesUrl, {
                        issueType,
                        notificationReason,
                        contactGroups: [contactGroup]
                    });
                } else {
                    response = await this.context.post(groupToRuleUrl, {
                        notificationRuleId: rule.id,
                        contactGroupId: contactGroup.id
                    });
                }
            }
            if (!response || response.status === "error") {
                return;
            } else {
                if (checked || rule) {
                    this.props.updateRule(response);
                } else {
                    this.props.addRule(response);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    handleInfoIcon(showTooltip) {
        this.setState({
            showTooltip
        });
    }

    getTooltipText() {
        switch (this.props.issueType) {
            case ISSUE_TYPE.LOW_FUEL_ALARM:
                return "These rules apply only for trailers with an active leg. " +
                "Warning and Resolved emails will be sent only if the trailer is in range of the local company. " +
                "Escalation emails will be sent no matter where the trailer is located.";
            case ISSUE_TYPE.ALARM:
                return "These rules apply only to trailers with no order or with an active order and leg.";
            case ISSUE_TYPE.INACTIVE_IN_YARD:
                return "These rules apply only to trailers with no order.";
            case ISSUE_TYPE.LOCATION_MISMATCH:
                return "These rules apply to trailers whose most recent leg has been completed.";
            default:
                return "These rules apply only to trailers with an active order and leg.";
        }
    }

    render() {
        const canWrite = AuthorizationService.canAccess(
            this.context.permissions,
            [PERMISSION.SETTINGS.WRITE, PERMISSION.SETTINGS.NOTIFICATIONS.WRITE]
        );

        return (
            <div className="notification-toggles">
                <div className="row header">
                    <div className="col-md-6 col-4">
                        Email Recipients
                        { !this.props.noTooltip &&
                            <FontAwesomeIcon
                                onMouseOver={ () => this.handleInfoIcon(true) }
                                onMouseLeave={ () => this.handleInfoIcon(false) }
                                icon={ faInfoCircle }
                                className="info-icon"
                            />
                        }
                        {this.state.showTooltip && <TextTooltip text={this.getTooltipText()}/>}
                    </div>
                    <div className="col-2">
                        { !this.props.noHeader && "Warning" }
                    </div>
                    {!this.props.noEscalation &&
                        <div className="col-2">
                            Escalation
                        </div>
                    }
                    {!this.props.noResolved &&
                        <div style={!(this.props.issueTypeRule && this.props.issueTypeRule.resolved) ? { color: '#D0D2DD' } : null} className="col-2">
                            Resolved
                        </div>
                    }
                </div>
                {this.props.contactGroups.map(contactGroup => {
                    const warningChecked = this.props.warningRule && this.props.warningRule.contactGroups.some(id => id === contactGroup.id);
                    const escalationChecked = !this.props.noEscalation && this.props.escalationRule && this.props.escalationRule.contactGroups.some(id => id === contactGroup.id);;
                    const resolvedChecked = !this.props.noResolved && this.props.resolvedRule && this.props.resolvedRule.contactGroups.some(id => id === contactGroup.id);

                    return (
                        <div className="row recipient-row">
                            <div className="col-md-6 col-4 recipient">
                                {contactGroup.name}
                                {contactGroup.fallbackOnDivision &&
                                    <span className="division">
                                        (Division)
                                    </span>
                                }
                                {contactGroup.fallbackOnSalesRep &&
                                    <span className="division">
                                        (Inside Sales Rep)
                                    </span>
                                }
                            </div>
                            <div className="col-2">
                                <Pill
                                    uncheckedGreyColor
                                    manualChange
                                    checked={warningChecked}
                                    onChange={checked => this.handleNotificationToggle(
                                        checked,
                                        this.props.warningRule,
                                        this.props.issueType,
                                        this.props.notificationTypes[0],
                                        contactGroup
                                    )}
                                    disabled={!canWrite}
                                />
                            </div>
                            {!this.props.noEscalation &&
                                <div className="col-2">
                                    <Pill
                                        uncheckedGreyColor
                                        manualChange
                                        checked={escalationChecked}
                                        onChange={checked => this.handleNotificationToggle(
                                            checked,
                                            this.props.escalationRule,
                                            this.props.issueType,
                                            this.props.notificationTypes[1],
                                            contactGroup
                                        )}
                                        disabled={!canWrite}
                                    />
                                </div>
                            }
                            {!this.props.noResolved &&
                                <div className="col-2">
                                    <Pill
                                        uncheckedGreyColor
                                        manualChange
                                        checked={this.props.issueTypeRule && this.props.issueTypeRule.resolved ? resolvedChecked : false}
                                        disabled={!(this.props.issueTypeRule && this.props.issueTypeRule.resolved) || !canWrite}
                                        onChange={checked => this.handleNotificationToggle(
                                            checked,
                                            this.props.resolvedRule,
                                            this.props.issueType,
                                            this.props.notificationTypes[2],
                                            contactGroup
                                        )}
                                    />
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default NotificationToggles;
