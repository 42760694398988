import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { DatePicker } from 'antd';

import { getCurrentDate } from 'global/services/DateTimeService';
import history from 'global/history';
import { PERMISSION } from 'global/utils/auth';
import Message from 'components/Message';
import CanAccess from 'components/can-access/CanAccess';
import AuthContext from 'AuthContext';

import './ReleaseNotes.scss';

export default withRouter(class EditReleaseNotes extends Component {
    static contextType = AuthContext;

    state = {
        id: undefined,
        date: '',
        version: '',
        content: ''
    }

    async componentDidMount() {
        const id = ((this.props.match || {}).params || {}).id;

        if (id) {
            const releaseNote = await this.context.get(`/api/v1/release-notes/${id}`, {});
            this.setState(releaseNote);
        }
    }

    async _submit(event) {
        event.preventDefault();

        if (!this.state.date) {
            alert('Release Date is required.');
            return;
        }

        if (!this.state.version) {
            alert('Release Version is required.');
            return;
        }

        if (!this.state.content) {
            alert('Release Notes content is required.');
            return;
        }

        const result = await this.context.post('/api/v1/admin/release-notes', this.state);

        if (result.status === "error") {
            this.setState({ error: (((result.message || {}).response || {}).data || {}).message || 'Failed to process request.' })
        } else {
            history.push('/release-notes');
        }
    }

    render() {
        let errorMessage = (<></>);
        if (this.state.error) {
            errorMessage = (
                <div className="col-12">
                    <Message type="error">
                        { this.state.error }
                    </Message>
                </div>
            );
        }

        return (
            <div className="container-fluid page release-notes-page">
                <div className="row">
                    <div className="col">
                        <ol className="breadcrumb">
                            <CanAccess
                                action={ PERMISSION.PAGE.ALL_ISSUES }
                                yes={ <li className="breadcrumb-item"><a href="/issues/all">All issues</a></li> }
                                no={ <li className="breadcrumb-item"><a href="/">Fleet Overview</a></li> }
                            />
                            <li className="breadcrumb-item"><a href="/release-notes">Changelog</a></li>
                            <li className="breadcrumb-item active"><a href="#!">Edit Release Notes</a></li>

                            <NavLink to="/" className="back-link">
                                <p>
                                    &larr; Back to dashboard
                                </p>
                            </NavLink>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="page-title col">
                        Edit Release Notes
                    </div>
                </div>
                <div className="row">
                    <div className="col page-content">
                        <form className="row release" onSubmit={ this._submit.bind(this) }>
                            { errorMessage }
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="release-date">Release Date:</label>
                                    <DatePicker className="form-control"
                                                format="YYYY-MM-DD"
                                                value={ this.state.date ? getCurrentDate(this.state.date) : undefined }
                                                onChange={ (_, dateString) => this.setState({ date: dateString }) } />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="release-version">Version:</label>
                                    <input id="release-version"
                                           className="form-control"
                                           type="text"
                                           placeholder="e.g. 1.5.0"
                                           value={ this.state.version }
                                           onChange={ (e) => this.setState({ version: e.target.value }) } />
                                </div>
                            </div>

                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="release-notes">Release Notes:</label>
                                    <textarea
                                        id="release-notes"
                                        className="form-control"
                                        rows="10"
                                        value={ this.state.content }
                                        onChange={ (e) => this.setState({ content: e.target.value }) } />
                                </div>
                            </div>

                            <div className="col-sm-12 actions">
                                <button className="btn btn-secondary" onClick={ () => history.push('/release-notes') }>Cancel</button>
                                <button type="submit" className="btn btn-continue">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
})
