import { Component } from 'react';

import AuthorizationService from 'global/services/AuthorizationService';
import AuthContext from 'AuthContext';

export default class CanAccess extends Component {
    static contextType = AuthContext;

    static defaultProps = {
        yes: null,
        no: null,
        data: null,
        permissions: null, // used only in ReactDOM.render functions for DataTable compatibility, should be removed after DataTable is migrated
    };

    render() {
        const permissions = this.props.permissions || this.context.permissions;
        const hasPermission = AuthorizationService.canAccess(permissions, this.props.action, this.props.data);

        return hasPermission ? this.props.yes : this.props.no;
    }
}
