import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faCircle, faGasPump, faTrailer } from '@fortawesome/pro-solid-svg-icons';
import { CustomControl, Layer, Map, Marker, MarkerCluster, Popup } from 'components/map/Map';
import { formatPower, latestMeasurementUrl, locationStateUrl, UNIT_MODE } from 'global/constants';
import { formatDate } from 'global/services/DateTimeService';
import StringUtils from 'global/utils/StringUtils';
import GeometryUtils from 'global/utils/GeometryUtils';
import blueTrailerIcon from 'files/map-icons/blue_trailer_icon.svg';
import blueCheckTrailerIcon from 'files/map-icons/blue_check_trailer_icon.svg';
import greenTrailerIcon from 'files/map-icons/green_trailer_icon.svg';
import greenCheckTrailerIcon from 'files/map-icons/green_check_trailer_icon.svg';
import orangeTrailerIcon from 'files/map-icons/orange_trailer_icon.svg';
import orangeCheckTrailerIcon from 'files/map-icons/orange_check_trailer_icon.svg';
import redTrailerIcon from 'files/map-icons/red_trailer_icon.svg';
import redCheckTrailerIcon from 'files/map-icons/red_check_trailer_icon.svg';
import yellowTrailerIcon from 'files/map-icons/yellow_trailer_icon.svg';
import yellowCheckTrailerIcon from 'files/map-icons/yellow_check_trailer_icon.svg';
import AuthContext from 'AuthContext';

import './TrailerMap.scss';

const TRAILER_COLOR = {
    BLUE: 'BLUE',
    GREEN: 'GREEN',
    ORANGE: 'ORANGE',
    RED: 'RED',
    YELLOW: 'YELLOW'
};

const NO_ORDER_TRAILER_MARKER_ICON = {
    BLUE: blueTrailerIcon,
    GREEN: greenTrailerIcon,
    ORANGE: orangeTrailerIcon,
    RED: redTrailerIcon,
    YELLOW: yellowTrailerIcon
};

const ORDER_TRAILER_MARKER_ICON = {
    BLUE: blueCheckTrailerIcon,
    GREEN: greenCheckTrailerIcon,
    ORANGE: orangeCheckTrailerIcon,
    RED: redCheckTrailerIcon,
    YELLOW: yellowCheckTrailerIcon
};

function getTrailerColor(trailerLocation) {
    if (trailerLocation.notResponding) {
        return TRAILER_COLOR.BLUE;
    }

    if (trailerLocation.moving) {
        return TRAILER_COLOR.GREEN;
    }

    if (trailerLocation.power) {
        return TRAILER_COLOR.YELLOW;
    }

    if (trailerLocation.power === false) {
        return TRAILER_COLOR.RED;
    }

    return TRAILER_COLOR.ORANGE;
}

export class TrailerMap extends Component {
    static contextType = AuthContext;

    static defaultProps = {
        onLoadingChange: (loading, available, pageSize) => null,
        pageSize: 500,
        trailerId: undefined,
        showAllTrailers: true,
        showLegend: true
    };

    constructor(props) {
        super(props);

        this.previousMapZoom = undefined;
        this.pageLoaded = undefined;
        this.allLoaded = undefined;

        this.state = {
            map: null,
            trailerLocations: [],
            highlightedTrailerLocation: null,
            selectedTrailerId: undefined
        };

        this.onDragEnd = this.onDragEnd.bind(this);
        this.onZoomEnd = this.onZoomEnd.bind(this);
        this.renderTrailerLocation = this.renderTrailerLocation.bind(this);
    }

    componentDidMount() {
        if (this.props.trailerId) {
            this.context.get(locationStateUrl, { trailerId: this.props.trailerId })
                .then(response => {
                    if (!response.data.length || response.status === 'error') {
                        this.props.onLoadingChange(false);
                        console.error(response.message);

                        if (this.props.showAllTrailers) {
                            this.loadTrailerLocations();
                        }

                        return;
                    }

                    this.setState(
                        { highlightedTrailerLocation: response.data[0] },
                        () => {
                            this.state.map.setView(GeometryUtils.wktToJson(this.state.highlightedTrailerLocation.location).coordinates.reverse(), 12);
                            this.props.onLoadingChange(false, 0, this.props.pageSize)
                        }
                    );
                })
                .catch(error => console.error('Error while fetching trailer location.', error));

            return;
        }

        this.loadTrailerLocations();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.showAllTrailers !== this.props.showAllTrailers) {
            if (this.props.showAllTrailers) {
                this.allLoaded = undefined;
                this.loadTrailerLocations();
            } else {
                this.setState(
                    { trailerLocations: [] },
                    () => {
                        const available = this.props.trailerId && this.state.highlightedTrailerLocation ? 0 : undefined;
                        this.props.onLoadingChange(false, available, this.props.pageSize);
                    }
                );
            }
        }

        if (!prevState.map && this.state.map) {
            this.previousMapZoom = this.state.map.getZoom();
        }
    }

    onDragEnd() {
        if (this.state.map && this.props.showAllTrailers && this.allLoaded !== true && !this.state.selectedTrailerId) {
            let geofence;
            if (this.allLoaded === false) {
                geofence = GeometryUtils.jsonToWkt(
                    GeometryUtils.createPolygonFromLatLongBounds(this.state.map.getBounds().pad(0.01)).toGeoJSON()
                );
            }

            this.loadTrailerLocations(geofence);
        }
    }

    onZoomEnd() {
        if (this.state.map) {
            if (this.state.map.getZoom() < this.previousMapZoom || !this.pageLoaded) {
                this.onDragEnd();
            }

            this.previousMapZoom = this.state.map.getZoom();
        }
    }

    loadTrailerLocations(geofence) {
        this.props.onLoadingChange(true);
        this.context.get(locationStateUrl, {
            geofence,
            excludeTrailerId: this.props.trailerId,
            pageSize: this.props.pageSize
        })
            .then(response => {
                if (!this.props.showAllTrailers) {
                    // Happens only if the prop changed after sending the request
                    this.setState(
                        { trailerLocations: [] },
                        () => {
                            const available = this.props.trailerId && this.state.highlightedTrailerLocation ? 0 : undefined;
                            this.props.onLoadingChange(false, available, this.props.pageSize);
                        }
                    );

                    return;
                }

                if (response.status === 'error') {
                    this.props.onLoadingChange(false);
                    console.error(response.message);
                    return;
                }

                this.pageLoaded = response.available <= this.props.pageSize;
                this.allLoaded = !geofence && this.pageLoaded;

                if (!geofence && this.props.showAllTrailers && !this.pageLoaded) {
                    // In case more trailers are available and no geofence was provided, repeat request with geofence
                    this.onDragEnd();
                    return;
                }

                this.setState(
                    { trailerLocations: response.data },
                    () => this.props.onLoadingChange(false, response.available, this.props.pageSize)
                );
            })
            .catch(error => console.error('Error while fetching trailer locations.', error));
    }

    renderTrailerLocation(trailerLocation) {
        const isMarkerSelected = trailerLocation.trailerId === this.state.selectedTrailerId;
        const trailerColor = getTrailerColor(trailerLocation);
        let markerIcon;
        if (trailerLocation.assignmentId) {
            markerIcon = ORDER_TRAILER_MARKER_ICON[trailerColor];
        } else {
            markerIcon = NO_ORDER_TRAILER_MARKER_ICON[trailerColor];
        }

        return (
            <Marker key={ trailerLocation.trailerId }
                    icon={ {
                        iconUrl: markerIcon,
                        iconSize: isMarkerSelected ? [ 40, 40 ] : [ 28, 28 ],
                        className: isMarkerSelected ? 'selected-trailer-marker' : ''
                    } }
                    position={ GeometryUtils.wktToJson(trailerLocation.location).coordinates }
                    onBoundPopupOpen={ e => this.setState({ selectedTrailerId: e.popup.options.id }) }
                    onBoundPopupClose={ () => this.setState({ selectedTrailerId: undefined }) }>
                <Popup id={ trailerLocation.trailerId }
                       className="trailer-popup"
                       offset={ [ 0, -10 ] }>
                    <TrailerPopupContent trailerId={ trailerLocation.trailerId }
                                         trailerColor={ trailerColor }
                                         showTrailerDetailsLink={ this.props.trailerId !== trailerLocation.trailerId } />
                </Popup>
            </Marker>
        );
    }

    render() {
        return (
            <Map className="trailer-map"
                 onLoad={ map => this.setState({ map }) }
                 onDragEnd={ this.onDragEnd }
                 onZoomEnd={ this.onZoomEnd }
                 { ...this.props }>
                <Layer name="Trailers">
                    <MarkerCluster>
                        { this.state.trailerLocations.map(this.renderTrailerLocation) }
                    </MarkerCluster>

                    { this.state.highlightedTrailerLocation && (
                        this.renderTrailerLocation(this.state.highlightedTrailerLocation)
                    ) }
                </Layer>
                { this.props.showLegend && (
                    <CustomControl position="bottomcenter">
                        <div className="custom-trailer-map-legend-container">
                            <TrailerMapLegend />
                        </div>
                    </CustomControl>
                ) }
            </Map>
        );
    }
}

export class TrailerMapLegend extends Component {
    render() {
        return (
            <div className={ `trailer-map-legend-container ${ this.props.className }` }>
                <span>
                    <FontAwesomeIcon icon={ faCircle } className="legend-icon green" />
                    Moving
                </span>

                <span>
                    <FontAwesomeIcon icon={ faCircle } className="legend-icon red" />
                    Stopped, power off
                </span>

                <span>
                    <FontAwesomeIcon icon={ faCircle } className="legend-icon orange" />
                    Stopped, unknown power
                </span>

                <span>
                    <FontAwesomeIcon icon={ faCircle } className="legend-icon yellow" />
                    Stopped, power on
                </span>

                <span>
                    <FontAwesomeIcon icon={ faCircle } className="legend-icon blue" />
                    Not responding
                </span>
            </div>
        );
    }
}

class TrailerPopupContent extends Component {
    static contextType = AuthContext;

    static defaultProps = {
        showTrailerDetailsLink: true
    };

    constructor(props) {
        super(props);

        this.state = {
            latestTrailerMeasurement: undefined,
            loading: true
        };
    }

    componentDidMount() {
        this.context.get(latestMeasurementUrl, { trailerId: this.props.trailerId })
            .then(response => this.setState({ latestTrailerMeasurement: response }))
            .catch(error => console.error('Error while fetching latest trailer measurement.', error))
            .finally(() => this.setState({ loading: false }));
    }

    roundTemperature(temperature) {
        return temperature ? (`${ Number.parseFloat(temperature).toFixed(0) } °F`) : '-';
    }

    render() {
        let content;
        if (this.state.loading) {
            content = <Loader type="TailSpin" color="#289AC2" height={ 47 } width={ 47 } />;
        } else if (this.state.latestTrailerMeasurement) {
            const {
                alarmState,
                assignmentState,
                engineState,
                modeState,
                positionState,
                powerState,
                temperatureState
            } = this.state.latestTrailerMeasurement;

            let driver = '-';
            if ((assignmentState.leg || {}).driver) {
                driver = assignmentState.leg.driver.contactInfo.name;
            }

            let trailerDetailsLink = `/details/${ this.props.trailerId }`;
            if (assignmentState.assignment) {
                trailerDetailsLink += `/${ assignmentState.assignment.id }`;
            }

            const hasLowFuelAlarm = alarmState.alarms.includes('96');

            content = (
                <>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={ faTrailer }
                                             className={ `trailer-popup-icon ${ this.props.trailerColor.toLowerCase() }` } />
                            <div>
                                <div className="trailer-popup-header">
                                    Trailer #{ assignmentState.trailer.businessId }
                                    { this.props.showTrailerDetailsLink && (
                                        <Link to={ trailerDetailsLink }
                                              className="popup-external-link"
                                              target="_blank"
                                              rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={ faArrowUpRightFromSquare } />
                                        </Link>
                                    ) }
                                </div>
                                <div className="trailer-popup-subheader">
                                    { formatDate(new Date(this.state.latestTrailerMeasurement.time)) }
                                </div>
                            </div>
                        </div>
                        <div>
                            <FontAwesomeIcon icon={ faGasPump }
                                             className={ `fuel-level-icon ${ hasLowFuelAlarm ? 'red' : '' }` } />
                            { engineState.fuelPercentage ? (`${ engineState.fuelPercentage }%`) : '-' }
                        </div>
                    </div>

                    { assignmentState.assignment && (
                        <div className="row no-gutters">
                            <div className="col">
                                <div className="info-label">Order ID</div>
                                <div>
                                    #{ assignmentState.assignment.businessId }
                                    <Link to={ `/assignment-details/${ assignmentState.assignment.id }` }
                                          className="popup-external-link"
                                          target="_blank"
                                          rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={ faArrowUpRightFromSquare } />
                                    </Link>
                                </div>
                            </div>
                            <div className="col">
                                <div className="info-label">Driver</div>
                                <div>{ driver }</div>
                            </div>
                        </div>
                    ) }

                    <div className="line" />

                    <div className="info-label">Location</div>
                    <div>{ positionState.position }</div>

                    <div className="row no-gutters">
                        <div className="col">
                            <div className="info-label">Power</div>
                            <div>{ formatPower(powerState.power) }</div>
                        </div>
                        <div className="col">
                            <div className="info-label">Mode</div>
                            <div>{ UNIT_MODE[modeState.unitMode] || '-' }</div>
                        </div>
                    </div>

                    <div className="row no-gutters">
                        <div className="col">
                            <div className="info-label">Set Point</div>
                            <div>
                                { this.roundTemperature(temperatureState.setTemperature) }
                            </div>
                        </div>
                        <div className="col">
                            <div className="info-label">Return Air</div>
                            <div>
                                { this.roundTemperature(temperatureState.actualTemperature) }
                            </div>
                        </div>
                    </div>

                    { alarmState.alarms.length > 0 && (
                        <>
                            <div className="info-label">
                                { StringUtils.pluralize(alarmState.alarms.length, 'Alarm') }
                            </div>
                            <div>{ alarmState.alarms.join(', ') }</div>
                        </>
                    ) }
                </>
            );
        } else {
            content = 'Error while fetching latest trailer measurement.';
        }

        return (
            <div className={ 'trailer-popup-content ' + (!this.state.latestTrailerMeasurement && 'content-not-loaded') }>
                { content }
            </div>
        );
    }
}
