/**
 * @description
 * Gets key from map when provided with unique value
 *
 * @param object The map from which the key is fetched.
 * @param value The unique value necessary to fetch key
 *
 * @returns The key that is associated with the given unique value.
 */

function getKeyByUniqueValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

export default getKeyByUniqueValue;
