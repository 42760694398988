import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Input.scss';

export class Input extends Component {

    static propTypes = {
        className: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        onKeyPress: PropTypes.func,
        leftIcon: PropTypes.node
    };

    static defaultProps = {
        className: '',
        value: '',
        onChange: () => null,
        onKeyPress: () => null
    };

    render() {
        const inputLeftIconClassName = this.props.leftIcon ? 'with-left-icon' : '';

        return (
            <div className="input-container">
                { this.props.leftIcon && (
                    <div className="left-icon">
                        { this.props.leftIcon }
                    </div>
                ) }

                <input type="text"
                       className={ `input ${ inputLeftIconClassName } ${ this.props.className }` }
                       placeholder={ this.props.placeholder }
                       value={ this.props.value }
                       onChange={ this.props.onChange }
                       onKeyPress={ this.props.onKeyPress } />
            </div>
        );
    }
}
