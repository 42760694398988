import React, { Component } from 'react';
import Select from 'react-select';
import { timeOptions, getPmTime } from 'global/services/DateTimeService';

import "./TimePicker.scss";

class TimePicker extends Component {

    render() {
        return (
            <Select
                key={this.props.defaultTime}
                className={`time-picker ${this.props.className || ""}`}
                classNamePrefix="select-time-picker"
                options={timeOptions(this.props.startTime, this.props.endTime, this.props.interval)}
                defaultValue={this.props.defaultTime && { label: getPmTime(this.props.defaultTime), value: this.props.defaultTime }}
                placeholder={this.props.placeholder}
                isSearchable={false}
                onChange={this.props.onChange}
            />
        )
    }
}

export default TimePicker;
