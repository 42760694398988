import React, { Component } from 'react';
import ReactDOM from "react-dom";

import { defaultGridRecordsNumber, mapSort, SortParam } from 'global/constants';
import DataTableComp from 'components/datatable/DataTableComp';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import TeamIcon from 'components/team-icon/TeamIcon';
import AuthContext from 'AuthContext';

import "./FleetTable.scss";

export default class FleetTable extends Component {
    static contextType = AuthContext;
    constructor(props, context) {
        super(props, context);
        this.columnNames = {
            0: SortParam.ASSIGNMENT,
            1: SortParam.TRAILER,
            2: SortParam.DRIVER,
            3: SortParam.DIVISION,
            4: SortParam.LEG,
            5: SortParam.ACTIVE
          }
        const columns = [
          { title: "Order #", data: "orderId" }, // 0
          { title: "Trailer #", data: "trailerId" }, // 1
          { title: "Driver", data: "driver" }, // 2
          { title: "Division", data: "division" }, // 3
          { title: "Leg #", data: "leg" }, // 4
          { title: "Leg status", data: "active" }, // 5
        ];

        const sortingRule = [
        ];  
        
        const columnDefs = [
            { className: "dt-align-center", targets: [ ] },
            { className: "dt-align-left", targets: [0, 1, 2, 3] },
            { className: "dt-align-right", targets: [] },
            {
                "targets": [ 4 ],
                "visible": true,
                "searchable": false
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            {rowData.driver ? rowData.driver.contactInfo.name : "/"}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            { rowData.driver ? 
                            <>
                                <TeamIcon color={rowData.driver.division.businessId} />
                                &nbsp; &nbsp;
                                {rowData.driver.division.contactInfo.name || rowData.driver.division.businessId}
                            </> : <div>/</div>}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            {rowData.leg ||  "/"}
                        </div>,
                        td
                    );
                }
              },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className={`${rowData.active === true ? "bold greenColorText" : rowData.active === false && "bold greyColorText"}`}>
                            {rowData.active === true ? "Active" : rowData.active === false ? "Inactive" : "/"}
                        </div>,
                        td
                    );
                }
            }
        ];

        this.state = {
            columns: columns,
            columnDefs: columnDefs,
            sort: [],
            sortingRule: sortingRule,
            account: this.props.account,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber //default value
              }
        };
    }

    componentDidMount() {
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
          }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }

        this.setState({
          sort: sortingArray
          }, async () => {
            await this.props.fetchFleet(mapSort(this.state.sort));
          }
        );
      }
    

    render() {
        if(this.props.data) {
            const data = this.props.data;
            return (
                    <div className="fleet-table-div">
                        <DataTableComp
                            tableId="searchData"
                            columns={this.state.columns}
                            columnDefs={this.state.columnDefs}
                            customTableClass="cell-border"
                            orderRule={this.state.sortingRule}
                            rowGroupColumn="active"
                            data={data}
                            isDetailView={true}
                            account={this.state.account}
                            tableHeight="1200px"
                            handlePreviousPage={this.props.handlePreviousPage}
                            handleNextPage={this.props.handleNextPage}
                            handleFirstPage={this.props.handleFirstPage}
                            handleLastPage={this.props.handleLastPage}
                            handleRecordsNumber={(event) => this.props.handleRecordsNumber(event)}
                            handlePageChange={(event) => this.props.handlePageChange(event)}
                            pagingInfo = {this.props.pagingInfo}
                            sortRule={this.state.sort.concat([])}
                            addSortingForColumn={this.addSortingForColumn.bind(this)}
                        />
                    </div> 
            );
        } else return <></>
    }
}
