import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';

import { adminYardsUrl, companyUrl } from 'global/constants';
import SimpleContainer from 'components/container/SimpleContainer';
import AuthContext from 'AuthContext';

import './AddDropYardPopup.scss';

export default class AddDropYardPopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.initialState = {
            companyId: null,
            submittable: false
        };

        this.state = { ...this.initialState };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showModal !== this.props.showModal && !this.props.showModal) {
            this.setState(this.initialState);
        }
    }

    async handleSubmit() {
        try {
            const response = await this.context.post(adminYardsUrl, { companyId: this.state.companyId, local: false });

            if (!response || response.status === 'error') {
                this.props.closeModal();
                return;
            }

            this.props.closeModal(response);
        } catch (error) {
            console.log(error);
            this.props.closeModal();
        }
    }

    async fetchCompanies(input) {
        try {
            const response = await this.context.get(`${companyUrl}/search`, {
                name: input,
                excludeYards: true
            });

            if (response.status === 'error') {
                console.error(response.message);
                return [];
            }

            return response.data.map(company => ({
                value: company.id,
                label: (
                    <div className="company-option">
                        { company.businessId }
                        <span className="company-name">
                           { company.name }
                       </span>
                    </div>
                )
            }));
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    onChange(selected) {
        this.setState({
            companyId: selected.value,
            submittable: !!selected.value
        });
    }

    render() {
        return (
            <Modal
                show={ this.props.showModal }
                onHide={ this.props.closeModal }
                backdrop="static"
                keyboard={ false }
                centered
                dialogClassName="add-drop-yard-modal"
            >
                <SimpleContainer className="modal-container" modal title="ADD DROP YARD">
                    <p className="company-label">Company ID: </p>
                    <AsyncSelect
                        classNamePrefix="select-company"
                        cacheOptions
                        defaultOptions
                        loadOptions={ this.fetchCompanies }
                        onChange={ this.onChange }
                    />
                    <div className="buttons">
                        <Button variant="light" onClick={ () => this.props.closeModal(false) } className="cancel-button">
                            CANCEL
                        </Button>
                        <Button
                            variant="continue"
                            className="add-button"
                            disabled={ !this.state.submittable }
                            onClick={ this.handleSubmit }
                        >
                            ADD YARD
                        </Button>
                    </div>
                </SimpleContainer>
            </Modal>
        );
    }
}
