import React, { Component } from "react";

import "./NoteTooltip.scss";

export default class NoteTooltip extends Component {

    render() {
        return (
            <div className="note-tooltip">
                <div className="arrow-box">
                    <p className="info-label-heading">
                        {this.props.title}
                    </p>
                    <p className="gathered-info">
                        {this.props.note}
                    </p>
                </div>
            </div>
        );
    }
}
