import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import './TermsConditionsPopup.scss';

export default class TermsConditionsPopup extends Component {
    constructor(props) {
        super();
        this.state = {
            showModal: true,
            allChecked: false,
            numChecked: 0,
            checkbox_1: {
                label: "I agree to use the ThermoKing platform to review & adjust reefer settings as needed, including Power, Mode, and Set Point.",
                isChecked: false
            },
            checkbox_2: {
                label: "I acknowledge that I am only permitted to adjust these settings listed and no others within the ThermoKing system.",
                isChecked: false
            },
            checkbox_3: {
                label: "I accept responsibility for these added permissions and understand the need to use extreme caution in making changes.",
                isChecked: false
            },
            checkbox_4: {
                label: "I understand that ShipEX reserves the right to disable this access due to failure to comply.",
                isChecked: false
            }
        }
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
        localStorage.setItem('didAgreeToConditions', true);
        if(this.props.updateParentState) {
            this.props.updateParentState();
        }
    }

    handleEnableButton = (numChecked) => {
        if (numChecked === 4) {
            this.setState({
                allChecked: true
            });      
        } else {
            this.setState({
                allChecked: false
            }); 
        }
    }

    onCheckboxChange = (event) => {
        let numChecked = this.state.numChecked;
        let currentCheckbox, key;

        if (this.state.checkbox_1.label === event.target.name) {
            currentCheckbox = this.state.checkbox_1;
            key = 'checkbox_1';
        } else if (this.state.checkbox_2.label === event.target.name) {
            currentCheckbox = this.state.checkbox_2;
            key = 'checkbox_2';
        } else if (this.state.checkbox_3.label === event.target.name) {
            currentCheckbox = this.state.checkbox_3;
            key = 'checkbox_3';
        } else if (this.state.checkbox_4.label === event.target.name) {
            currentCheckbox = this.state.checkbox_4;
            key = 'checkbox_4';
        }

        currentCheckbox.isChecked = !currentCheckbox.isChecked;
        if (currentCheckbox.isChecked) {
            numChecked = numChecked + 1;
        } else if (numChecked > 0) {
            numChecked = numChecked - 1;
        }

        this.setState({
            [key]: currentCheckbox,
            numChecked: numChecked
        }, function () {
            this.handleEnableButton(numChecked);
        });
    }

    render() {
        return (
            <Modal
                show={this.state.showModal}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <Modal.Title className="title">
                        Important <FontAwesomeIcon className="redColorText" icon={faExclamationCircle}></FontAwesomeIcon>
                    </Modal.Title>
                    <p className="modalText">
                        This tool is intended to help you monitor & correct Reefer issues in real-time for the ShipEX Fleet. 
                    </p>
                    <p className="modalText">
                        In addition to this tool, you have been given access to remotely adjust reefer units in ThermoKing via the TracKing website: <a href="https://www.tktracking.com/Combo/v2/logon">https://www.tktracking.com/Combo/v2/logon</a>
                    </p>
                    <p className="modalText">
                        This includes permission to adjust:
                        <ul>
                            <li><strong>Power Settings</strong> (On/Off)</li>
                            <li><strong>Mode Settings</strong> (Continuous vs Cycle Sentry)</li>
                            <li><strong>Temperature Settings</strong> (Set Point)</li>
                        </ul>
                    </p>
                    <p className="modalText italic">
                        These settings are extremely sensitive and must be adjusted with the utmost attention to detail and caution. Reefer settings should only be adjusted if we are able to confirm that the settings are not running per the correct specifications. Errors with these settings can cause costly claims, so any/all issues or mistakes in this regard will be reviewed by Management.
                    </p>
                    <div className="form-check">
                        <label className={`checkBoxText ${this.state.checkbox_1.isChecked ? "bold" : ""}`}>
                            <input
                                type="checkbox"
                                name={this.state.checkbox_1.label}
                                checked={this.state.checkbox_1.isChecked}
                                onChange={this.onCheckboxChange}
                                className="form-check-input"
                            />
                            {this.state.checkbox_1.label}
                        </label>
                        <label className={`checkBoxText ${this.state.checkbox_2.isChecked ? "bold" : ""}`}>
                            <input
                                type="checkbox"
                                name={this.state.checkbox_2.label}
                                checked={this.state.checkbox_2.isChecked}
                                onChange={this.onCheckboxChange}
                                className="form-check-input"
                            />
                            {this.state.checkbox_2.label}
                        </label>
                        <label className={`checkBoxText ${this.state.checkbox_3.isChecked ? "bold" : ""}`}>
                            <input
                                type="checkbox"
                                name={this.state.checkbox_3.label}
                                checked={this.state.checkbox_3.isChecked}
                                onChange={this.onCheckboxChange}
                                className="form-check-input"
                            />
                            {this.state.checkbox_3.label}
                        </label>
                        <label className={`checkBoxText ${this.state.checkbox_4.isChecked ? "bold" : ""}`}>
                            <input
                                type="checkbox"
                                name={this.state.checkbox_4.label}
                                checked={this.state.checkbox_4.isChecked}
                                onChange={this.onCheckboxChange}
                                className="form-check-input"
                            />
                            {this.state.checkbox_4.label}
                        </label>
                    </div>
                    <br/> 
                    <p className="modalText center italic">
                        Please click CONTINUE below to acknowledge this message.
                    </p>
                    <Button variant="continue" onClick={this.handleClose} disabled={!this.state.allChecked}>
                        Continue
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }
}
