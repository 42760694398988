import React, { Component } from "react";
import { withRouter } from "react-router";

import history from "global/history";
import { formatPower, latestMeasurementUrl, maxCommentsNumber, reportCommentUrl } from "global/constants";
import AuthorizationService from "global/services/AuthorizationService";
import { getIssuePermissionsForAction, issueTypeMap } from "global/services/IssueTypeService";
import { PERMISSION, reportCategoryToCommentPermission } from "global/utils/auth";
import PagingComp from "components/paging/PagingComp"
import SnoozeIssuePopup from 'components/popup/SnoozeIssuePopup';
import SnoozeRejectPopup from 'components/popup/SnoozeRejectPopup';
import NotificationHistoryPopup from "components/popup/NotificationHistoryPopup";
import CloseIssuePopup from "components/popup/CloseIssuePopup";
import ReeferControlPopup from "components/popup/ReeferControlPopup";
import NotifiedPersonsTooltip from 'components/tooltip/NotifiedPersonsTooltip';
import DetentionInfoTooltip from 'components/tooltip/DetentionInfoTooltip';
import SeenByTooltip from 'components/tooltip/SeenByTooltip';
import CommentTooltip from 'components/tooltip/CommentTooltip';
import TextTooltip from 'components/tooltip/TextTooltip';
import InactivityInfoTooltip from "components/tooltip/InactivityInfoTooltip";
import LowFuelLevelTooltip from "components/tooltip/LowFuelLevelTooltip";
import NoteTooltip from "components/tooltip/NoteTooltip";
import ResolvedTooltip from "components/tooltip/ResolvedTooltip";
import LocationInfoTooltip from "components/tooltip/LocationInfoTooltip";
import RecipientsTooltip from 'components/tooltip/RecipientsTooltip';
import AuthContext from "AuthContext";

import "./DataTableComp.scss";

const qs = require("qs");
const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-dt");
require("datatables.net-autofill-dt");
require("datatables.net-buttons-dt");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
require("datatables.net-colreorder-dt");
require("datatables.net-fixedcolumns-dt");
require("datatables.net-fixedheader-dt");
require("datatables.net-keytable-dt");
require("datatables.net-responsive-dt");
require("datatables.net-rowgroup-dt");
require("datatables.net-rowreorder-dt");
require("datatables.net-scroller-dt");
require("datatables.net-select-dt");

let timeoutVar;

class DataTableComp extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns,
      data: props.data,
      orderRule: props.orderRule,
      columnDefs: props.columnDefs,
      sortRule: [],
      customTableClass: props.customTableClass || "",
      tableHeight: props.tableHeight || "300px",
      redirect: false,
      isTooltipShown: null,
      latestNotification: null,
      latestWarning: null,
      latestEscalation: null,
      nextEscalationTime: null,
      isDetailView: props.isDetailView || false,
      isManager: false,
      seenByBefore: [],
      seenByAfter: [],
      seenTooltipShown: false,
      snoozePopupShown: false,
      snoozeRejectPopupShown: false,
      closeIssuePopupShown: false,
      trailerId: null,
      issueType: null,
      account: props.account,
      issue: null,
      snoozedIssue: null,
      showTextTooltip: false,
      showNoteTooltip: false,
      detentionTooltipShown: false,
      resolvedTooltipShown: false,
      closed: null,
      textTooltip: "",
      snoozeRejectText: "",
      inactivityTooltipShown: false,
      locationTooltipShown: false,
      recipientsTooltipShown: false,
      inactiveLeg: false,
      commentShown: false,
      comments: [],
      lowFuelLevelTooltipShown: false,
      location: null,
      fuelLevel: null,
      resolved: null
    };
    this.createDataTable = this.createDataTable.bind(this);
    this.updateSortColumnHeaders = this.updateSortColumnHeaders.bind(this);
    this.handler = this.handler.bind(this);
    this.handlerDelete = this.handlerDelete.bind(this);
    this.closeReeferControlPopup = this.closeReeferControlPopup.bind(this);
    this.yPosition = window.pageYOffset;
    this.xPosition = window.pageXOffset;
  }

  componentDidMount() {
    const that = this;
    timeoutVar = setTimeout(function () {
      that.createDataTable();
    }, 100);
  }

  handler(comment) {
    if (this.state.isIssueComment) {
      this.props.onReceiveNewComment(comment);
    } else {
      this.setState(prevState => ({
        comments: [comment, ...prevState.comments]
      }));
    }
  }

  handlerDelete(deletedComment) {
    if (this.state.isIssueComment) {
      this.props.onDeleteComment(deletedComment);
    } else {
      this.setState(prevState => ({
        comments: prevState.comments.map(comment => comment.id === deletedComment.id ? deletedComment : comment)
      }));
    }
  }

  createDataTable(nextData, nextSort) {
    const that = this;

    if ( $.fn.DataTable.isDataTable('#'+that.props.tableId) ) {
      $('#'+that.props.tableId).DataTable().clear().destroy();
    }

    // Closing open tooltips
    $(document).on('click', function(e) {
      var container = $(`.seen`);
      var toHide = $(`#seen-by-${that.props.tableId}`);

      var notificationContainer = $(`.notification-info`);
      var toHideNotification = $(`#tooltip-notifications-${that.props.tableId}`);

      var detentionContainer = $(`.detention-info`);
      var toHideDetention = $(`#tooltip-detention-${that.props.tableId}`);

      let commentContainer = $(`.comment`);
      let toHideComment = $(`#comments-by-${that.props.tableId}`);

      var inactivityContainer = $(`.inactivity-info`);
      var toHideInactivity = $(`#tooltip-inactivity-${that.props.tableId}`);

      let lowFuelContainer = $(`.low-fuel-level-info`);
      let toHideLowFuel = $(`#tooltip-low-fuel-level-${that.props.tableId}`);

      const locationContainer = $(`.location-info`);
      const toHideLocation = $(`#tooltip-location-${that.props.tableId}`);

      const recipientsContainer = $(`.event-info`);
      const toHideRecipients = $(`#tooltip-recipients-${that.props.tableId}`);

      let resolvedContainer = $(`.resolvedContainer`);
      let toHideResolved = $(`#tooltip-resolved-${that.props.tableId}`);

      let snoozeNoteContainer = $(`.reason`);
      let toHideSnoozeNote = $(`#snooze-note-${that.props.tableId}`);

      if (!container.is(e.target) && !toHide.is(e.target) && container.has(e.target).length === 0 && toHide.has(e.target).length === 0 &&
      !notificationContainer.is(e.target) && !toHideNotification.is(e.target) && notificationContainer.has(e.target).length === 0 && toHideNotification.has(e.target).length === 0 &&
      !detentionContainer.is(e.target) && !toHideDetention.is(e.target) && detentionContainer.has(e.target).length === 0 && toHideDetention.has(e.target).length === 0 &&
      !commentContainer.is(e.target) && !toHideComment.is(e.target) && commentContainer.has(e.target).length === 0 && toHideComment.has(e.target).length === 0 &&
      !inactivityContainer.is(e.target) && !toHideInactivity.is(e.target) && inactivityContainer.has(e.target).length === 0 && toHideInactivity.has(e.target).length === 0 &&
      !lowFuelContainer.is(e.target) && !toHideLowFuel.is(e.target) && lowFuelContainer.has(e.target).length === 0 && toHideLowFuel.has(e.target).length === 0 &&
      !locationContainer.is(e.target) && !toHideLocation.is(e.target) && locationContainer.has(e.target).length === 0 && toHideLocation.has(e.target).length === 0 &&
      !toHideRecipients.is(e.target) && recipientsContainer.has(e.target).length === 0 && toHideRecipients.has(e.target).length === 0 &&
      !resolvedContainer.is(e.target) && !toHideResolved.is(e.target) && resolvedContainer.has(e.target).length === 0 && toHideResolved.has(e.target).length === 0 &&
      !snoozeNoteContainer.is(e.target) && !toHideSnoozeNote.is(e.target) && snoozeNoteContainer.has(e.target).length === 0 && toHideSnoozeNote.has(e.target).length === 0) {
        toHide.hide();
        toHideNotification.hide();
        toHideDetention.hide();
        toHideComment.hide();
        toHideInactivity.hide();
        toHideLowFuel.hide();
        toHideLocation.hide();
        toHideRecipients.hide();
        toHideResolved.hide();
        toHideSnoozeNote.hide();
      }

      let icon = $(`#${that.props.tableId} .seen-icon`);
      icon.removeClass('activated');

      let infoIcon = $(`#${that.props.tableId} .info-icon`);
      infoIcon.removeClass('activated');

      let detentionInfoIcon = $(`#${that.props.tableId} .detention .info-icon`);
      detentionInfoIcon.removeClass('activated');

      let commentIcon = $(`#${that.props.tableId} .comment-icon`);
      commentIcon.removeClass('activated');

      let inactivityInfoIcon = $(`#${that.props.tableId} .inactivity .info-icon`);
      inactivityInfoIcon.removeClass('activated');

      let lowFuelLevelInfoIcon = $(`#${that.props.tableId} .low-fuel-level .info-icon`);
      lowFuelLevelInfoIcon.removeClass('activated');

      const locationInfoIcon = $(`#${that.props.tableId} .location .info-icon`);
      locationInfoIcon.removeClass('activated');

      const recipientsInfoIcon = $(`#${that.props.tableId} .event-info .info-icon`);
      recipientsInfoIcon.removeClass('activated');

      let resolvedInfoIcon = $(`#${that.props.tableId} .resolvedContainer .info-icon`);
      resolvedInfoIcon.removeClass('activated');

      let snoozeNoteInfoIcon = $(`#${that.props.tableId} .reason .info-icon`);
      snoozeNoteInfoIcon.removeClass('activated');
    });

    $(document).on('scroll', function(e) {
      const newYPosition = window.pageYOffset;
      const newXPosition = window.pageXOffset;
      if(newYPosition === this.yPosition && newXPosition === this.xPosition) {
          return;
      } else {
        this.xPosition = newXPosition;
        this.yPosition = newYPosition;
      }

      var container = $(`.seen`);
      var toHide = $(`#seen-by-${that.props.tableId}`);

      var notificationContainer = $(`.notification-info`);
      var toHideNotification = $(`#tooltip-notifications-${that.props.tableId}`);

      var detentionContainer = $(`.detention-info`);
      var toHideDetention = $(`#tooltip-detention-${that.props.tableId}`);

      var inactivityContainer = $(`.inactivity-info`);
      var toHideInactivity = $(`#tooltip-inactivity-${that.props.tableId}`);

      let lowFuelContainer = $(`.low-fuel-level-info`);
      let toHideLowFuel = $(`#tooltip-low-fuel-level-${that.props.tableId}`);

      const locationContainer = $(`.location-info`);
      const toHideLocation = $(`#tooltip-location-${that.props.tableId}`);

      const recipientsContainer = $(`.event-info`);
      const toHideRecipients = $(`#tooltip-recipients-${that.props.tableId}`);

      let resolvedContainer = $(`.resolvedContainer`);
      let toHideResolved = $(`#tooltip-resolved-${that.props.tableId}`);

      if (!container.is(e.target) && !toHide.is(e.target) && container.has(e.target).length === 0 && toHide.has(e.target).length === 0 &&
      !notificationContainer.is(e.target) && !toHideNotification.is(e.target) && notificationContainer.has(e.target).length === 0 && toHideNotification.has(e.target).length === 0 &&
      !detentionContainer.is(e.target) && !toHideDetention.is(e.target) && detentionContainer.has(e.target).length === 0 &&  toHideDetention.has(e.target).length === 0 &&
      !inactivityContainer.is(e.target) && !toHideInactivity.is(e.target) && inactivityContainer.has(e.target).length === 0 && toHideInactivity.has(e.target).length === 0 &&
      !lowFuelContainer.is(e.target) && !toHideLowFuel.is(e.target) && lowFuelContainer.has(e.target).length === 0 && toHideLowFuel.has(e.target).length === 0 &&
      !locationContainer.is(e.target) && !toHideLocation.is(e.target) && locationContainer.has(e.target).length === 0 && toHideLocation.has(e.target).length === 0 &&
      !toHideRecipients.is(e.target) && recipientsContainer.has(e.target).length === 0 && toHideRecipients.has(e.target).length === 0 &&
      !resolvedContainer.is(e.target) && !toHideResolved.is(e.target) && resolvedContainer.has(e.target).length === 0 && toHideResolved.has(e.target).length === 0) {
        toHide.hide();
        toHideNotification.hide();
        toHideDetention.hide();
        toHideInactivity.hide();
        toHideLowFuel.hide();
        toHideLocation.hide();
        toHideRecipients.hide();
        toHideResolved.hide();
      }

      let icon = $(`#${that.props.tableId} .seen-icon`);
      icon.removeClass('activated');

      let infoIcon = $(`#${that.props.tableId} .info-icon`);
      infoIcon.removeClass('activated');

      let detentionInfoIcon = $(`#${that.props.tableId} .detention .info-icon`);
      detentionInfoIcon.removeClass('activated');

      let inactivityInfoIcon = $(`#${that.props.tableId} .inactivity .info-icon`);
      inactivityInfoIcon.removeClass('activated');

      let lowFuelLevelInfoIcon = $(`#${that.props.tableId} .low-fuel-level .info-icon`);
      lowFuelLevelInfoIcon.removeClass('activated');

      const locationInfoIcon = $(`#${that.props.tableId} .location .info-icon`);
      locationInfoIcon.removeClass('activated');

      const recipientsInfoIcon = $(`#${that.props.tableId} .event-info .info-icon`);
      recipientsInfoIcon.removeClass('activated');

      let resolvedInfoIcon = $(`#${that.props.tableId} .resolvedContainer .info-icon`);
      resolvedInfoIcon.removeClass('activated');
    });

    if (!$.fn.DataTable.isDataTable('#'+that.props.tableId) ) {
      that.$el = $(that.el);
      that.$el
      .DataTable({
        dom: '<"data-table-wrapper"lrt>',
        ordering: true,
        data: nextData || that.state.data,
        columns: that.state.columns,
        scrollX: true,
        scrollY: that.state.tableHeight,
        scrollCollapse: true,
        search: false,
        order: [],
        columnDefs: that.state.columnDefs,
        autoWidth: true,
        lengthChange: false,
        paging: false
      })
      .columns.adjust();
      }
      const table = $('#'+that.props.tableId).DataTable();
      const thead = table.columns().header().to$();
      this.updateSortColumnHeaders(table, nextSort || that.props.sortRule);

      table.on('draw', function () {
        that.updateSortColumnHeaders(table, nextSort || that.props.sortRule);
      });
      thead.off('click.DT');
      thead.off().on('click', function () {
        const index = table.column(this).index();
        if(index === undefined || index === null || $(this).hasClass("sorting_disabled")) return;
        let sorting = "";
        if ($(this).hasClass("sorting_both")) {
          sorting = "ASC";
        } else if ($(this).hasClass("sorting_asc")) {
          sorting = "DESC";
        }
        that.props.addSortingForColumn(index, sorting, that.props.tableId);
       });


      // Notified persons tooltip
      table.on('click', 'td .notifications .info-icon', function(e) {
      var row = $(this).closest('tr').index();
      const data = table.row(row).data();
      if (data) {
        that.setState({
          isTooltipShown: true,
          latestNotification: data.latestNotification,
          nextEscalationTime: data.nextEscalationTime,
          issue: data.issue,
          snoozedIssue: data.snoozedIssue,
        }, function() {
          $(`.tooltip-detention`).hide();
          $(`.comments-by`).hide();
          $(`.seen-by`).hide();
          $(`.tooltip-resolved`).hide();
          $(`.tooltip-notifications`).hide();
          $(`.tooltip-inactivity`).hide();
          $(`.tooltip-low-fuel-level`).hide();
          $(`.tooltip-location`).hide();
          $(`#tooltip-notifications-${this.props.tableId}`).show();
          setTimeout(() => {
            let element = $(`#tooltip-notifications-${this.props.tableId}`);
            let icon = $(`#${this.props.tableId} .notifications .info-icon`).eq(row);
            if (icon.length !== 0) {
              $(`#tooltip-notifications-${this.props.tableId}`).animate({
                left: icon.position().left + icon.outerWidth() + 10,
                top: e.pageY - window.pageYOffset - 0.9 * element.outerHeight()}, 1);
              icon.addClass('activated');
            }
          }, 5);
        });
        }
      });

      // Resolved tooltip
      table.on('click', 'td .resolvedContainer .info-icon', function (e) {
        var row = $(this).closest('tr').index();
        const data = table.row(row).data();
        if (data) {
          that.setState({
            resolvedTooltipShown: true,
            resolved: data.state !== "CLOSED" && data.resolved,
            closed: data.closed,
            resolveReason: (data.issue.metaData || {}).resolveReason
          }, function() {
            $(`.tooltip-detention`).hide();
            $(`.comments-by`).hide();
            $(`.seen-by`).hide();
            $(`.tooltip-resolved`).hide();
            $(`.tooltip-notifications`).hide();
            $(`.tooltip-inactivity`).hide();
            $(`.tooltip-low-fuel-level`).hide();
            $(`.tooltip-location`).hide();
            $(`#tooltip-resolved-${this.props.tableId}`).show();
            setTimeout(() => {
              let element = $(`#tooltip-resolved-${this.props.tableId}`);
              let icon = $(`#${that.props.tableId} .resolvedContainer .info-icon`).eq(row);
              if (icon.length !== 0) {
                $(`#tooltip-resolved-${this.props.tableId}`).animate({
                  left: e.pageX - window.pageXOffset - element.outerWidth() + 25,
                  top: e.pageY - window.pageYOffset - element.outerHeight() - 15}, 1);
                icon.addClass('activated');
              }
            }, 5);
          });
        }
      });

      table.on('click', 'td .manager-notified .info-icon', function(e) {
        var row = $(this).closest('tr').index();
        const data = table.row(row).data()
        if (data) {
          that.setState({
            isTooltipShown: true,
            latestWarning: data.latestWarning,
            nextEscalationTime: data.nextEscalationTime,
            isManager: true,
            issue: data.issue,
            snoozedIssue: data.snoozedIssue
          }, function() {
            $(`.tooltip-detention`).hide();
            $(`.comments-by`).hide();
            $(`.seen-by`).hide();
            $(`.tooltip-resolved`).hide();
            $(`.tooltip-notifications`).hide();
            $(`.tooltip-inactivity`).hide();
            $(`.tooltip-low-fuel-level`).hide();
            $(`.tooltip-location`).hide();
            $(`#tooltip-notifications-${this.props.tableId}`).show();
            setTimeout(() => {
              let element = $(`#tooltip-notifications-${this.props.tableId}`);
              let icon = $(`#${this.props.tableId} .manager-notified .info-icon`).eq(row);
              if (icon.length !== 0) {
                $(`#tooltip-notifications-${this.props.tableId}`).animate({
                  left: icon.position().left + icon.outerWidth() + 10,
                  top: e.pageY - window.pageYOffset - 0.9 * element.outerHeight()
                }, 1);
                icon.addClass('activated');
              }
            }, 5);
          });
        }
      });

      table.on('click', 'td .operations-notified .info-icon', function(e) {
        var row = $(this).closest('tr').index();
        const data = table.row(row).data()
        if (data) {
          that.setState({
            isTooltipShown: true,
            latestEscalation: data.latestEscalation,
            nextEscalationTime: data.nextEscalationTime,
            isManager: false,
            issue: data.issue,
            snoozedIssue: data.snoozedIssue
          }, function() {
            $(`.tooltip-detention`).hide();
            $(`.comments-by`).hide();
            $(`.seen-by`).hide();
            $(`.tooltip-resolved`).hide();
            $(`.tooltip-notifications`).hide();
            $(`.tooltip-inactivity`).hide();
            $(`.tooltip-low-fuel-level`).hide();
            $(`.tooltip-location`).hide();
            $(`#tooltip-notifications-${this.props.tableId}`).show();
            setTimeout(() => {
              let element = $(`#tooltip-notifications-${this.props.tableId}`);
              let icon = $(`#${this.props.tableId} .operations-notified .info-icon`).eq(row);
              if (icon.length !== 0) {
                $(`#tooltip-notifications-${this.props.tableId}`).animate({
                  left: icon.position().left + icon.outerWidth() + 10,
                  top: e.pageY - window.pageYOffset - 0.9 * element.outerHeight()
                }, 1);
                icon.addClass('activated');
              }
            }, 5);
          });
        }
      });

      // Detention tooltip
      table.on('click', 'td .type', function(e) {
        var row = $(this).closest('tr').index();
        const data = table.row(row).data()
        if (data) {
          that.setState({
            detentionTooltipShown: true,
            detentionIssue: data.issue,
            detentionState: data.detentionState
          }, function() {
            $(`.comments-by`).hide();
            $(`.seen-by`).hide();
            $(`.tooltip-resolved`).hide();
            $(`.tooltip-notifications`).hide();
            $(`.tooltip-detention`).hide();
            $(`.tooltip-inactivity`).hide();
            $(`.tooltip-low-fuel-level`).hide();
            $(`.tooltip-location`).hide();
            $(`#tooltip-detention-${this.props.tableId}`).show();
            setTimeout(() => {
              let element = $(`#tooltip-detention-${this.props.tableId}`);
              let icon = $(`#${this.props.tableId} .type .info-icon`).eq(row);
              if (icon.length !== 0) {
                $(`#tooltip-detention-${this.props.tableId}`).animate({
                  left: icon.position().left + icon.outerWidth() + 10,
                  top: e.pageY - window.pageYOffset - 0.9 * element.outerHeight()
                }, 1);
                icon.addClass('activated');
              }
            }, 5);
          });
        }
      });

      // Inactivity issue tooltip
      table.on('click', 'td .inactivity', function(e) {
        var row = $(this).closest('tr').index();
        const data = table.row(row).data()
        if(data) {
          that.setState({
            inactivityTooltipShown: true,
            inactivityIssue: data.issue
          }, function() {
            $(`.tooltip-detention`).hide();
            $(`.comments-by`).hide();
            $(`.seen-by`).hide();
            $(`.tooltip-resolved`).hide();
            $(`.tooltip-notifications`).hide();
            $(`.tooltip-inactivity`).hide();
            $(`.tooltip-low-fuel-level`).hide();
            $(`.tooltip-location`).hide();
            $(`#tooltip-inactivity-${this.props.tableId}`).show();
            setTimeout(() => {
              let element = $(`#tooltip-inactivity-${this.props.tableId}`);
              let icon = $(`#${this.props.tableId} .type .info-icon`).eq(row);
              $(`#tooltip-inactivity-${this.props.tableId}`).animate({ left: icon.position().left + icon.outerWidth() + 10, top: e.pageY - window.pageYOffset - 0.5 * element.outerHeight()}, 1);
              icon.addClass('activated');
            }, 5);
          });
        }
      });

      // Low fuel level tooltip
      table.on('click', 'td .low-fuel-level', function (e) {
        var row = $(this).closest('tr').index();
        const data = table.row(row).data();
        if (data) {
          that.setState({
            lowFuelLevelTooltipShown: true,
            trailerId: data.trailerId,
            location: data.address,
            fuelLevel: data.fuelLevel,
            resolved: data.resolved
          }, function() {
            $(`.tooltip-detention`).hide();
            $(`.comments-by`).hide();
            $(`.seen-by`).hide();
            $(`.tooltip-resolved`).hide();
            $(`.tooltip-notifications`).hide();
            $(`.tooltip-inactivity`).hide();
            $(`.tooltip-low-fuel-level`).hide();
            $(`.tooltip-location`).hide();
            $(`#tooltip-low-fuel-level-${this.props.tableId}`).show();
            setTimeout(() => {
              let element = $(`#tooltip-low-fuel-level-${this.props.tableId}`);
              let icon = $(`#${this.props.tableId} .type .info-icon`).eq(row);
              $(`#tooltip-low-fuel-level-${this.props.tableId}`).animate({ left: icon.position().left + icon.outerWidth() + 10, top: e.pageY - window.pageYOffset - 0.5 * element.outerHeight()}, 1);
              icon.addClass('activated');
            }, 5);
          });
        }
      });

      // Location issue (detached or missing) tooltip
      table.on('click', 'td .location', function (e) {
        const row = $(this).closest('tr').index();
        const data = table.row(row).data()
        if (data) {
          that.setState({
            locationTooltipShown: true,
            locationIssue: data.issue
          }, function () {
            $(`.tooltip-detention`).hide();
            $(`.comments-by`).hide();
            $(`.seen-by`).hide();
            $(`.tooltip-resolved`).hide();
            $(`.tooltip-notifications`).hide();
            $(`.tooltip-inactivity`).hide();
            $(`.tooltip-low-fuel-level`).hide();
            $(`.tooltip-location`).hide();
            $(`#tooltip-location-${ this.props.tableId }`).show();
            setTimeout(() => {
              const element = $(`#tooltip-location-${ this.props.tableId }`);
              const icon = $(`#${ this.props.tableId } .type .info-icon`).eq(row);
              element.animate({
                left: icon.position().left + icon.outerWidth() + 10,
                top: e.pageY - window.pageYOffset - 0.5 * element.outerHeight()
              }, 1);
              icon.addClass('activated');
            }, 5);
          });
        }
      });

    // Recipients tooltip
    table.on('click', 'td .event-info .info-icon', function (e) {
      const row = $(this).closest('tr').index();
      const data = table.row(row).data();
      if (data) {
        that.setState({
          recipientsTooltipShown: true,
          recipients: data.metadata.recipients.split(',')
        }, function () {
          $(`.tooltip-recipients`).hide();
          $(`#tooltip-recipients-${ this.props.tableId }`).show();
          setTimeout(() => {
            const element = $(`#tooltip-recipients-${ this.props.tableId }`);
            const icon = $(`#${ this.props.tableId } .event-info`).eq(row).find('.info-icon');
            element.animate({
              left: icon.position().left + icon.outerWidth() + 10,
              top: e.pageY - window.pageYOffset - 0.5 * element.outerHeight()
            }, 1);
            icon.addClass('activated');
          }, 5);
        });
      }
    });

      // Seen tooltip
      table.on('click', 'td .seen', function (e) {
        var row = $(this).closest('tr').index();
        const data = table.row(row).data()
        if (data) {
          that.setState({
            seenTooltipShown: true,
            seenByBefore: data.seenByBefore,
            seenByAfter: data.seenByAfter
          }, function() {
            $(`.tooltip-detention`).hide();
            $(`.comments-by`).hide();
            $(`.tooltip-resolved`).hide();
            $(`.tooltip-notifications`).hide();
            $(`.seen-by`).hide();
            $(`.tooltip-low-fuel-level`).hide();
            $(`.tooltip-inactivity`).hide();
            $(`#seen-by-${this.props.tableId}`).show();
            setTimeout(() => {
              let icon = $(`#${this.props.tableId} .seen-icon`).eq(row);
              let element = $(`#seen-by-${this.props.tableId}`);
              $(`#seen-by-${this.props.tableId}`).animate({
                left: icon.position().left - element.outerWidth() - 10,
                top: e.pageY - window.pageYOffset - 0.8 * element.outerHeight()
              }, 1);
              icon.addClass('activated');
            }, 5);
          });
        }
      });

      table.on('mouseover', 'td .disabled-seen', function(e) {
        var row = this.parentNode.parentNode.parentNode.rowIndex;
        const data = table.row(row - 1).data();
        if (data) {
          const text = "This functionality was unavailable at the time this report was generated.";
          that.setState({
            showTextTooltip: true,
            textTooltip: text
          }, function() {
            $(`#tooltip-text-${this.props.tableId}`).show();
            setTimeout(() => {
              let icon = $(`#${this.props.tableId} .disabled-seen .disabled-seen-icon`).eq(row - 1);
              let end = icon.position() ? icon.position().left : e.pageX - window.pageXOffset;
              let top = e.pageY - window.pageYOffset + 15;
              const element = $(`#tooltip-text-${this.props.tableId}`);
              $(`#tooltip-text-${this.props.tableId}`).animate({
                left: end - 0.5 * element.outerWidth() + 5,
                top: top
              }, 1);
              icon.addClass('activated');
            }, 1);
          });
        }
      });

      table.on('mouseleave', 'td .disabled-seen', function(e) {
        that.setState({
          showTextTooltip: false,
          textTooltip: ""
        }, function() {
          $(`#tooltip-text-${this.props.tableId}`).hide();
          $(`#${this.props.tableId} .disabled-seen .disabled-seen-icon`).removeClass('activated');
        });
      });

      // Comment tooltip
      table.on('click', 'td .comment', async function (e) {
        const row = $(this).closest('tr').index();
        const data = table.row(row).data();
        if (data) {
          if (!!data.issue) {
            that.handleIssueComment(e, row, data);
          } else {
            that.handleReportCategoryComment(e, row, data);
          }
        }
      });

      table.on('mouseover', 'td .disabled-comment', function(e) {
        var row = this.parentNode.parentNode.parentNode.rowIndex;
        const data = table.row(row - 1).data();
        if (data) {
          const text = "This functionality was unavailable at the time this report was generated.";
          that.setState({
            showTextTooltip: true,
            textTooltip: text
          }, function() {
            $(`#tooltip-text-${this.props.tableId}`).show();
            setTimeout(() => {
              let icon = $(`#${this.props.tableId} .disabled-comment .disabled-comment-icon`).eq(row - 1);
              let end = icon.position() ? icon.position().left : e.pageX - window.pageXOffset;
              let top = e.pageY - window.pageYOffset + 15;
              const element = $(`#tooltip-text-${this.props.tableId}`);
              $(`#tooltip-text-${this.props.tableId}`).animate({
                left: end - 0.5 * element.outerWidth() + 5,
                top: top
              }, 1);
              icon.addClass('activated');
            }, 1);
          });
        }
      });

      table.on('mouseleave', 'td .disabled-comment', function(e) {
        that.setState({
          showTextTooltip: false,
          textTooltip: ""
        }, function() {
          $(`#tooltip-text-${this.props.tableId}`).hide();
          $(`#${this.props.tableId} .disabled-comment .disabled-comment-icon`).removeClass('activated');
        });
      });

      // Snooze popup and tooltip
      table.on('click', 'td .snooze', function (e) {
        var row = this.parentNode.parentNode.parentNode.rowIndex;
        const data = table.row(row - 1).data();
        if (data) {
          that.setState({
            issue: data.issue,
            snoozePopupShown: true,
            trailerId: data.trailerId || data.trailer,
            issueType: data.type
          });
        }
      });

      table.on('mouseover', 'td .disabled-snooze', function(e) {
        var row = this.parentNode.parentNode.parentNode.rowIndex;
        const data = table.row(row - 1).data();
        if (data) {
          const text = that.getSnoozeText(data);
          that.setState({
            showTextTooltip: true,
            textTooltip: text
          }, function() {
            $(`#tooltip-text-${this.props.tableId}`).show();
            setTimeout(() => {
              let icon = $(`#${this.props.tableId} .disabled-snooze .disabled-icon`).eq(row - 1);
              let end = icon.position() ? icon.position().left : e.pageX - window.pageXOffset;
              let top = e.pageY - window.pageYOffset + 15;
              const element = $(`#tooltip-text-${this.props.tableId}`);
              $(`#tooltip-text-${this.props.tableId}`).animate({
                left: end - 0.5 * element.outerWidth() + 5,
                top: top
              }, 1);
              icon.addClass('activated');
            }, 1);
          });
        }
      });

      table.on('mouseleave', 'td .disabled-snooze', function(e) {
        that.setState({
          showTextTooltip: false,
          textTooltip: ""
        }, function() {
          $(`#tooltip-text-${this.props.tableId}`).hide();
          $(`#${this.props.tableId} .disabled-snooze .disabled-icon`).removeClass('activated');
        });
      });

      table.on('click', 'td .resolve', function (e) {
        const row = this.parentNode.parentNode.parentNode.rowIndex;
        const data = table.row(row - 1).data();
        if (data) {
          that.setState({
            issue: data.issue,
            closeIssuePopupShown: true,
            trailerId: data.trailerId || data.trailer,
            issueType: data.type
          });
        }
      });

      table.on('mouseover', 'td .disabled-resolve', function(e) {
        const row = this.parentNode.parentNode.parentNode.rowIndex;
        const data = table.row(row - 1).data();
        if (data) {
          that.setState({
            showTextTooltip: true,
            textTooltip: "Irrelevant issues can't be closed."
          }, function() {
            $(`#tooltip-text-${this.props.tableId}`).show();
            setTimeout(() => {
              let icon = $(`#${this.props.tableId} .disabled-resolve .disabled-icon`).eq(row - 1);
              let end = icon.position() ? icon.position().left : e.pageX - window.pageXOffset;
              let top = e.pageY - window.pageYOffset + 15;
              const element = $(`#tooltip-text-${this.props.tableId}`);
              $(`#tooltip-text-${this.props.tableId}`).animate({
                left: end - 0.5 * element.outerWidth() + 5,
                top: top
              }, 1);
            }, 1);
          });
        }
      });

      table.on('mouseleave', 'td .disabled-resolve', function(e) {
        that.setState({
          showTextTooltip: false,
          textTooltip: ""
        }, function() {
          $(`#tooltip-text-${this.props.tableId}`).hide();
        });
      });

      // Remote reefer control
      table.on('click', 'td .reefer-control-icon', async function() {
        const row = $(this).closest('tr').index();
        const data = table.row(row).data();
        
        if (data) {
          that.setState({
            reeferControlLoading: true,
            showReeferControlPopup: true,
            trailerId: data.issue.trailer.id
          });
          
          const response = await that.fetchLatestMeasurement(data.issue.trailer.id);
          if (!response) {
            that.closeReeferControlPopup();
            return;
          }

          const { powerState, modeState, temperatureState } = response;

          let mode = modeState.unitMode;
          if (mode === 'CONTINUOUS') {
            mode = 'Continuous';
          } else {
            mode = 'Cycle-Sentry';
          }
          
          that.setState({
            currentPower: formatPower(powerState.power, powerState.power),
            currentMode: mode,
            currentSetPoint: temperatureState.setTemperature,
            reeferControlLoading: false
          });
        }
      });

      // Inactive leg tooltip
      table.on('mouseover', 'td .inactive-leg', function(e) {
        var row = this.parentNode.parentNode.parentNode.rowIndex;
        that.setState({
          showTextTooltip: true,
          textTooltip: "This leg is inactive."
        }, function() {
          $(`#tooltip-text-${this.props.tableId}`).show();
          setTimeout(() => {
            const icon = $(`#${this.props.tableId} .inactive-leg .disabled-icon`).eq(row - 1);
            let end = icon.position() ? icon.position().left : e.pageX - window.pageXOffset;
            const element = $(`#tooltip-text-${this.props.tableId}`);
            $(`#tooltip-text-${this.props.tableId}`).animate({
              left: end - 0.5 * element.outerWidth() + 5,
              top: e.pageY - window.pageYOffset + 5
            }, 1);
          }, 1);
        });
      });

      table.on('mouseleave', 'td .inactive-leg', function(e) {
        that.setState({
          showTextTooltip: false,
          textTooltip: ""
        }, function() {
          $(`#tooltip-text-${this.props.tableId}`).hide();
        });
      });

      // Notification history popup reason tooltip
      table.on('click', 'td .reason .info-icon', function (e) {
        var row = $(this).closest('tr').index();
        const data = table.row(row).data();
        if (data) {
          that.setState({
            showNoteTooltip: true,
            textTooltip: data.snoozeNote
          }, function() {
            $(`#snooze-note-${this.props.tableId}`).show();
            setTimeout(() => {
              let element = $(`#snooze-note-${this.props.tableId}`);
              let icon = $(`#${this.props.tableId} .reason .info-icon`).eq(row);
              if (icon.length !== 0) {
                $(`#snooze-note-${this.props.tableId}`).animate({
                  left: icon.position().left + icon.outerWidth() + 15,
                  top: e.pageY - window.pageYOffset - 0.5 * element.outerHeight()}, 1);
                icon.addClass('activated');
              }
            }, 1);
          });
        }
      });

      if (this.props.tableId === "reportHistory") {
        table.on('click', 'tr', function (e) {
          if (e.target.tagName.toLowerCase() !== 'a') {
            const data = table.row(this.rowIndex - 1).data();
            if (data) {
              history.push({
                pathname: "/trailer-audit/" + data.id,
                state: { historyUrl: window.location.pathname + window.location.search }
              });
            }
          }
        });
      }

      // Disabled comment tooltip
      table.on('mouseover', 'td .disabled-comment-icon', function(e) {
        var row = this.parentNode.parentNode.parentNode.rowIndex;
        that.setState({
          showTextTooltip: true,
          textTooltip: "This is a manually generated Temporary Trailer Audit and the comment functionality is disabled."
        }, function() {
          $(`#tooltip-text-${this.props.tableId}`).show();
          setTimeout(() => {
            const icon = $(`#${this.props.tableId} .inactive-leg .disabled-icon`).eq(row - 1);
            let end = icon.position() ? icon.position().left : e.pageX - window.pageXOffset;
            const element = $(`#tooltip-text-${this.props.tableId}`);
            $(`#tooltip-text-${this.props.tableId}`).animate({
              left: end - 0.5 * element.outerWidth() + 5,
              top: e.pageY - window.pageYOffset + 5
            }, 1);
          }, 1);
        });
      });

      table.on('mouseleave', 'td .disabled-comment-icon', function(e) {
        that.setState({
          showTextTooltip: false,
          textTooltip: ""
        }, function() {
          $(`#tooltip-text-${this.props.tableId}`).hide();
        });
      });

      // Closing open tooltips
      table.on('click', function(e) {
        var container = $(`.seen`);
        var toHide = $(`#seen-by-${that.props.tableId}`);

        var notificationContainer = $(`.notification-info`);
        var toHideNotification = $(`#tooltip-notifications-${that.props.tableId}`);

        var detentionContainer = $(`.detention-info`);
        var toHideDetention = $(`#tooltip-detention-${that.props.tableId}`);

        let commentContainer = $(`.comment`);
        let toHideComment = $(`#comments-by-${that.props.tableId}`);

        var inactivityContainer = $(`.inactivity-info`);
        var toHideInactivity = $(`#tooltip-inactivity-${that.props.tableId}`);

        let lowFuelContainer = $(`.low-fuel-level-info`);
        let toHideLowFuel = $(`#tooltip-low-fuel-level-${that.props.tableId}`);

        const locationContainer = $(`.location-info`);
        const toHideLocation = $(`#tooltip-location-${that.props.tableId}`);

        const recipientsContainer = $(`.event-info`);
        const toHideRecipients = $(`#tooltip-recipients-${that.props.tableId}`);

        let resolvedContainer = $(`.resolvedContainer`);
        let toHideResolved = $(`#tooltip-resolved-${that.props.tableId}`);

        let snoozeNoteContainer = $(`.reason`);
        let toHideSnoozeNote = $(`#snooze-note-${that.props.tableId}`);

        if (!container.is(e.target) && !toHide.is(e.target) && container.has(e.target).length === 0 && toHide.has(e.target).length === 0 &&
        !notificationContainer.is(e.target) && !toHideNotification.is(e.target) && notificationContainer.has(e.target).length === 0 && toHideNotification.has(e.target).length === 0 &&
        !detentionContainer.is(e.target) && !toHideDetention.is(e.target) && detentionContainer.has(e.target).length === 0 &&  toHideDetention.has(e.target).length === 0 &&
        !commentContainer.is(e.target) && !toHideComment.is(e.target) && commentContainer.has(e.target).length === 0 && toHideComment.has(e.target).length === 0 &&
        !inactivityContainer.is(e.target) && !toHideInactivity.is(e.target) && inactivityContainer.has(e.target).length === 0 && toHideInactivity.has(e.target).length === 0 &&
        !lowFuelContainer.is(e.target) && !toHideLowFuel.is(e.target) && lowFuelContainer.has(e.target).length === 0 && toHideLowFuel.has(e.target).length === 0 &&
        !locationContainer.is(e.target) && !toHideLocation.is(e.target) && locationContainer.has(e.target).length === 0 && toHideLocation.has(e.target).length === 0 &&
        !toHideRecipients.is(e.target) && recipientsContainer.has(e.target).length === 0 && toHideRecipients.has(e.target).length === 0 &&
        !resolvedContainer.is(e.target) && !toHideResolved.is(e.target) && resolvedContainer.has(e.target).length === 0 && toHideResolved.has(e.target).length === 0 &&
        !snoozeNoteContainer.is(e.target) && !toHideSnoozeNote.is(e.target) && snoozeNoteContainer.has(e.target).length === 0 && toHideSnoozeNote.has(e.target).length === 0) {
          toHide.hide();
          toHideNotification.hide();
          toHideDetention.hide();
          toHideComment.hide();
          toHideLowFuel.hide();
          toHideLocation.hide();
          toHideRecipients.hide();
          toHideResolved.hide();
          toHideSnoozeNote.hide();
        }

        let icon = $(`#${that.props.tableId} .seen-icon`);
        icon.removeClass('activated');

        let infoIcon = $(`#${that.props.tableId} .info-icon`);
        infoIcon.removeClass('activated');

        let detentionInfoIcon = $(`#${that.props.tableId} .detention .info-icon`);
        detentionInfoIcon.removeClass('activated');

        let commentIcon = $(`#${that.props.tableId} .comment-icon`);
        commentIcon.removeClass('activated');

        let inactivityInfoIcon = $(`#${that.props.tableId} .inactivity .info-icon`);
        inactivityInfoIcon.removeClass('activated');

        let lowFuelLevelInfoIcon = $(`#${that.props.tableId} .low-fuel-level .info-icon`);
        lowFuelLevelInfoIcon.removeClass('activated');

        const locationInfoIcon = $(`#${that.props.tableId} .location .info-icon`);
        locationInfoIcon.removeClass('activated');

        const recipientsInfoIcon = $(`#${that.props.tableId} .event-info .info-icon`);
        recipientsInfoIcon.removeClass('activated');

        let resolvedInfoIcon = $(`#${that.props.tableId} .resolvedContainer .info-icon`);
        resolvedInfoIcon.removeClass('activated');

        let snoozeNoteInfoIcon = $(`#${that.props.tableId} .reason .info-icon`);
        snoozeNoteInfoIcon.removeClass('activated');
      });

      const snoozeIssueId = qs.parse(window.location.search, { ignoreQueryPrefix: true }).snooze;

      if (snoozeIssueId) {
        const snoozeNode = $(`td div[id=${snoozeIssueId}]`).next('.snooze');
        if (snoozeNode.length) {
          snoozeNode.trigger('click');
        } else {
          const data = table.row($(`td div[id=${snoozeIssueId}]`).closest('tr')).data();
          if (data) {
            const text = <>Can’t snooze <span className="bold">"{data.type}"</span> for <span className="bold">Trailer #{data.trailerId}</span> because {this.getSnoozeText(data).toLowerCase()}</>;
            that.setState({
              snoozeRejectPopupShown: true,
              snoozeRejectText: text
            });
          }
        }
      }
  }

  closeReeferControlPopup() {
    this.setState({
      showReeferControlPopup: false,
      currentPower: null,
      currentMode: null,
      currentSetPoint: null,
      trailerId: null,
    });
  }

  async fetchLatestMeasurement(trailerId) {
    try {
      const response = await this.context.get(latestMeasurementUrl, { trailerId });
      if (response.status === 'error') {
          console.error(response.message);
          return;
      }
      return response;
    } catch(error) {
      console.error(error);
      return;
    }
  }

  handleIssueComment(e, row, data) {
    this.setState({
      commentShown: true,
      isIssueComment: true,
      comments: data.comments,
      commentData: {
        id: data.issue.id,
        trailerId: data.issue.trailer.businessId,
        type: issueTypeMap(data.issue.type)
      },
      commentPayload: {
        issue: data.issue
      }
    }, () => this.showCommentTooltip(e, row));
  }

  async handleReportCategoryComment(e, row, data) {
    let comments = [];

    this.setState({
      commentsLoading: true,
      commentShown: true,
      isIssueComment: false,
      commentData: {
        id: data.id,
        trailerId: data.trailerId,
        type: data.title
      },
      commentPayload: {
        trailerReportStateId: data.id,
        category: data.category
      },
      comments
    }, () => this.showCommentTooltip(e, row));

    try {
      const response = await this.context.get(`${reportCommentUrl}/state/${data.id}`, {
        ...data.params,
        pageSize: maxCommentsNumber
      });

      if (response && response.status !== "error") {
        comments = response.data;
      }
    } catch (error) {
      console.error(error);
    }

    this.setState({
      commentsLoading: false,
      comments,
    });
  }

  showCommentTooltip(e, row) {
    $(`.tooltip-detention`).hide();
    $(`.seen-by`).hide();
    $(`.comments-by`).hide();
    $(`.tooltip-resolved`).hide();
    $(`.tooltip-notifications`).hide();
    $(`.tooltip-low-fuel-level`).hide();
    $(`.tooltip-inactivity`).hide();
    $(`#comments-by-${this.props.tableId}`).show();
    setTimeout(() => {
      const icon = $(`#${this.props.tableId} .comment-icon`).eq(row);
      const element = $(`#comments-by-${this.props.tableId}`);
      element.animate({
        left: icon.position().left - element.outerWidth() - 10,
        top: e.pageY - window.pageYOffset - 0.9 * element.outerHeight()
      }, 1);
      icon.addClass('activated');
    }, 5);
  }

  updateSortColumnHeaders(table, sort) {
    const thead = table.columns().header().to$();
    const spanSorting = '<span class="arrow-hack sort"></span>';
    const spanAsc = '<span class="arrow-hack asc"></span>';
    const spanDesc = '<span class="arrow-hack desc"></span>';
    thead.each(function() {
      const index = table.column(this).index();
      if(index === undefined || index === null || $(this).hasClass("sorting_disabled")) return;
      if(sort) {
        const column = sort.find(s => s.columnIndex === index);
        $(this).removeClass("sorting_desc");
        $(this).removeClass("sorting_both");
        $(this).removeClass("sorting_asc");
        $(this).find('.desc').remove();
        $(this).find('.sort').remove();
        $(this).find('.asc').remove();
        const html = $(this).html();

        if(!column || column.length <= 0) {
          $(this).addClass("sorting_both");
          $(this).html(html+spanSorting);
          return;
        }
        switch(column.direction) {
          case "ASC":
            $(this).addClass("sorting_asc");
            $(this).html(html+spanAsc);
            break;
          case "DESC":
            $(this).addClass("sorting_desc");
            $(this).html(html+spanDesc);
            break;
          default:
            $(this).addClass("sorting_both");
            $(this).html(html+spanSorting);
            break;
        }
      }
    });
  }

  getSnoozeText = (data) => {
    switch(true) {
      case !!data.snoozed:
        return "This issue is already snoozed.";
      case data.state === "CLOSED":
        return "This issue has been closed.";
      case !!data.resolved:
        return "This issue has been resolved.";
      default:
        return "Snooze is unavailable for this issue.";
    }
  }

  componentWillUnmount() {
    if ( $.fn.DataTable.isDataTable('#'+this.props.tableId) ) {
      $('#'+this.props.tableId).DataTable().destroy();
    }
    clearTimeout(timeoutVar);
  }

  reloadTableData = async (data, sortRule) => {
    const table = $('#'+this.props.tableId).DataTable();
    const thead = table.columns().header().to$();
    const sleep = m => new Promise(r => setTimeout(r, m));
    table.clear().rows.add(data);
    await sleep(200);
    let order = [];

    thead.each(function() {
      const index = table.column(this).index();
      if(index === undefined || index === null || $(this).hasClass("sorting_disabled")) return;
      if(sortRule) {
        const column = sortRule.find(s => s.columnIndex === index);
        if(!column || column.length <= 0) return;
        order.push(Array.from([index, column.direction.toLowerCase()]))
      }
    });
    table.columns.adjust().order([]).draw();
  };

  async shouldComponentUpdate(nextProps, nextState) {
    const sleep = m => new Promise(r => setTimeout(r, m));
    const that = this;
    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data) || JSON.stringify(nextProps.sortRule) !== JSON.stringify(this.props.sortRule)) {
      await sleep(200); //wait until table renders
      that.createDataTable(nextProps.data, nextProps.sortRule);
      await that.reloadTableData(nextProps.data, nextProps.sortRule);
    } else if (nextState.isTooltipShown !== this.state.isTooltipShown) {
      await that.reloadTableData(nextProps.data, nextProps.sortRule);
    }
    return false;
  }

  hideCloseIssuePopup() {
    this.setState({
      closeIssuePopupShown: false
    });
  }

  closeSnoozeModal() {
    this.setState({
      snoozePopupShown: false
    });
  }

  closeSnoozeRejectModal() {
    history.replace();
    this.setState({
      snoozeRejectPopupShown: false
    });
  }

  closeNotificationHistoryModal() {
    this.setState({
      seeNotificationHistoryModalShown: false
    });
  }

  seeNotificationHistory(){
    this.setState({
      seeNotificationHistoryModalShown: true
    })
  }

  render() {
    let hasCommentWritePermission;
    if (this.props.category) {
      // Comment on trailer audit category
      hasCommentWritePermission = AuthorizationService.canAccess(
          this.context.permissions,
          PERMISSION.TRAILER_AUDIT.COMMENT
      );

      if (!hasCommentWritePermission) {
        hasCommentWritePermission = AuthorizationService.canAccess(
            this.context.permissions,
            reportCategoryToCommentPermission(this.props.category)
        );
      }
    } else if (this.state.commentPayload) {
      hasCommentWritePermission = AuthorizationService.canAccess(
          this.context.permissions,
          getIssuePermissionsForAction(this.state.commentPayload.issue, "comment")
      );
    }

    return (
      <>
        {this.state.seeNotificationHistoryModalShown ? <NotificationHistoryPopup modalClosed={this.closeNotificationHistoryModal.bind(this)} showNotificationHistoryModal={true} account={this.state.account} issue={this.state.issue}/> : <></>}
        {this.state.snoozePopupShown ? <SnoozeIssuePopup modalClosed={this.closeSnoozeModal.bind(this)} showSnoozeModal={true} trailerId={this.state.trailerId} issueType={this.state.issueType} account={this.state.account} issue={this.state.issue}/> : <></>}
        <SnoozeRejectPopup closeModal={this.closeSnoozeRejectModal.bind(this)} showModal={this.state.snoozeRejectPopupShown} text={this.state.snoozeRejectText} />
        {this.state.closeIssuePopupShown && <CloseIssuePopup closeModal={this.hideCloseIssuePopup.bind(this)} showModal={this.state.closeIssuePopupShown} trailerId={this.state.trailerId} issueType={this.state.issueType} account={this.state.account} issue={this.state.issue}/>}
        <div className="dataTableDiv">
          <div className="tooltip-text" id={`tooltip-text-${this.props.tableId}`}>
            {this.state.showTextTooltip ? <TextTooltip text={this.state.textTooltip}/>
            : <></>}
          </div>
          <div className="snooze-note" id={`snooze-note-${this.props.tableId}`}>
            {this.state.showNoteTooltip && <NoteTooltip title="Note:" note={this.state.textTooltip} />}
          </div>
          <div className="seen-by" id={`seen-by-${this.props.tableId}`}>
            {this.state.seenTooltipShown ?
              <SeenByTooltip seenByBefore={this.state.seenByBefore} seenByAfter={this.state.seenByAfter}/> :
              <></>
            }
          </div>
          { this.state.commentShown && (
              <div className="comments-by" id={ `comments-by-${this.props.tableId}` }>
                <CommentTooltip
                    readOnly={ this.props.commentReadOnly || !hasCommentWritePermission }
                    key={ this.state.commentData.id }
                    handler={ this.handler }
                    handlerDelete={ this.handlerDelete }
                    account={ this.state.account }
                    comments={ this.state.comments }
                    data={ this.state.commentData }
                    payload={ this.state.commentPayload }
                    isIssueComment={ this.state.isIssueComment }
                    loading={ this.state.commentsLoading }
                />
              </div>
          ) }
          <ReeferControlPopup 
            closeModal={this.closeReeferControlPopup}
            showModal={this.state.showReeferControlPopup}
            trailerId={this.state.trailerId}
            currentPower={this.state.currentPower}
            currentMode={this.state.currentMode}
            currentSetPoint={this.state.currentSetPoint}
            isLoading={this.state.reeferControlLoading}
          />
          <div className="tooltip-resolved" id={`tooltip-resolved-${this.props.tableId}`}>
            {this.state.resolvedTooltipShown && <ResolvedTooltip resolveReason={this.state.resolveReason} closed={this.state.closed} resolved={this.state.resolved} />}
          </div>
          <div className="tooltip-detention" id={`tooltip-detention-${this.props.tableId}`}>
            {this.state.detentionTooltipShown && this.state.detentionIssue && this.state.detentionState ?
              <DetentionInfoTooltip detentionIssue={this.state.detentionIssue} detentionState={this.state.detentionState} /> :
              <></>
            }
          </div>
          <div className="tooltip-inactivity" id={`tooltip-inactivity-${this.props.tableId}`}>
            {this.state.inactivityTooltipShown && this.state.inactivityIssue ?
              <InactivityInfoTooltip inactivityIssue={this.state.inactivityIssue} /> :
              <></>
            }
          </div>
          <div  className="tooltip-low-fuel-level" id={`tooltip-low-fuel-level-${this.props.tableId}`}>
            {this.state.lowFuelLevelTooltipShown ?
              <LowFuelLevelTooltip trailerId={this.state.trailerId} location={this.state.location} fuelLevel={this.state.fuelLevel} resolved={this.state.resolved} /> : <></>
            }
          </div>
          <div className="tooltip-location" id={ `tooltip-location-${this.props.tableId}` }>
            { this.state.locationTooltipShown && this.state.locationIssue &&
              <LocationInfoTooltip locationIssue={ this.state.locationIssue } />
            }
          </div>
          <div className="tooltip-recipients" id={ `tooltip-recipients-${this.props.tableId}` }>
            { this.state.recipientsTooltipShown && this.state.recipients &&
              <RecipientsTooltip recipients={ this.state.recipients } />
            }
          </div>
          <div className="tooltip-notifications" id={`tooltip-notifications-${this.props.tableId}`}>
            {this.state.isTooltipShown && this.state.latestNotification ?
              <NotifiedPersonsTooltip
                seeNotificationHistory={this.seeNotificationHistory.bind(this)}
                snoozedIssue={this.state.snoozedIssue} 
                title={this.state.latestNotification.type + " mail sent"}
                firstTime={this.state.latestNotification.time} 
                emails={this.state.latestNotification.emails} 
                nextEscalationTime={this.state.nextEscalationTime}
                issueType={this.state.issue.type}
              /> :
              <></>
            }
            {this.state.isDetailView && this.state.isManager && this.state.isTooltipShown && this.state.latestWarning ?
              <NotifiedPersonsTooltip 
                seeNotificationHistory={this.seeNotificationHistory.bind(this)} 
                snoozedIssue={this.state.snoozedIssue} 
                title="Warning mail sent" 
                firstTime={this.state.latestWarning.time}
                emails={this.state.latestWarning.emails}
                nextEscalationTime={this.state.nextEscalationTime}
                issueType={this.state.issue.type}
              /> :
              <></>
            }
            {this.state.isDetailView && !this.state.isManager && this.state.isTooltipShown && this.state.latestEscalation ?
              <NotifiedPersonsTooltip
                seeNotificationHistory={this.seeNotificationHistory.bind(this)}
                snoozedIssue={this.state.snoozedIssue}
                title="Escalation mail sent"
                firstTime={this.state.latestEscalation.time}
                emails={this.state.latestEscalation.emails}
                nextEscalationTime={this.state.nextEscalationTime}
                issueType={this.state.issue.type}
              /> :
              <></>
            }
          </div>
          <table
            className={
              "table nowrap hover row-border " + this.state.customTableClass
            }
            id={this.props.tableId}
            width="100%"
            cellSpacing="0"
            ref={(el) => (this.el = el)}
          />
          { this.props.pagingInfo && <PagingComp
            pagingInfo={this.props.pagingInfo}
            handlePreviousPage={this.props.handlePreviousPage}
            handleNextPage={this.props.handleNextPage}
            handleFirstPage={this.props.handleFirstPage}
            handleLastPage={this.props.handleLastPage}
            handleRecordsNumber={this.props.handleRecordsNumber}
            handlePageChange={this.props.handlePageChange}
          ></PagingComp>}
        </div>
      </>
    );
  }
}
export default withRouter(DataTableComp);