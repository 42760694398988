import React, { Component } from 'react';
import { mapDivisionToColor } from 'global/constants'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons'

export default class TeamIcon extends Component {

    render() {
      const divisionColor = mapDivisionToColor(this.props.color) 
      var iconStyle = {
          color: divisionColor,
          width: this.props.size || "10px",
          height: this.props.size || "10px",
          minWidth: this.props.size  || "10px",
          maxWidth: this.props.size  || "10px",
          display: 'inline',
          ...this.props.style
        };
        let icon;
        switch(this.props.color) {
          case "LOCAL":
            icon = <svg style={iconStyle} width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 3.9375C4.86094 3.9375 3.9375 4.86094 3.9375 6C3.9375 7.13906 4.86094 8.0625 6 8.0625C7.13906 8.0625 8.0625 7.13906 8.0625 6C8.0625 4.86094 7.13906 3.9375 6 3.9375ZM6 6.9375C5.48297 6.9375 5.0625 6.51703 5.0625 6C5.0625 5.48297 5.48297 5.0625 6 5.0625C6.51703 5.0625 6.9375 5.48297 6.9375 6C6.9375 6.51703 6.51703 6.9375 6 6.9375ZM11.625 5.4375H10.4611C10.2068 3.40289 8.59711 1.7932 6.5625 1.53891V0.375C6.5625 0.167812 6.39469 0 6.1875 0H5.8125C5.60531 0 5.4375 0.167812 5.4375 0.375V1.53891C3.40289 1.7932 1.7932 3.40289 1.53891 5.4375H0.375C0.167812 5.4375 0 5.60531 0 5.8125V6.1875C0 6.39469 0.167812 6.5625 0.375 6.5625H1.53891C1.7932 8.59711 3.40289 10.2068 5.4375 10.4611V11.625C5.4375 11.8322 5.60531 12 5.8125 12H6.1875C6.39469 12 6.5625 11.8322 6.5625 11.625V10.4611C8.59711 10.2068 10.2068 8.59688 10.4611 6.5625H11.625C11.8322 6.5625 12 6.39469 12 6.1875V5.8125C12 5.60531 11.8322 5.4375 11.625 5.4375ZM6 9.375C4.13906 9.375 2.625 7.86094 2.625 6C2.625 4.13906 4.13906 2.625 6 2.625C7.86094 2.625 9.375 4.13906 9.375 6C9.375 7.86094 7.86094 9.375 6 9.375Z" fill="black"/>
            </svg>;
            break;
          case "UNK": 
            icon = <svg style={iconStyle} width="10" height="10" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="5.5" fill="white" stroke="#828A95"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1.64648 9.64648L9.64648 1.64648L10.3536 2.35359L2.35359 10.3536L1.64648 9.64648Z" fill="#828A95"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M2.35352 1.64648L10.3535 9.64648L9.64641 10.3536L1.64641 2.35359L2.35352 1.64648Z" fill="#828A95"/>
            </svg>;            
            break;
          case "ALL":
            icon = <svg style={iconStyle} width="10" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="10">
            <path d="M12.1875 6.875H11.875V4.76562C11.875 4.53125 11.7578 4.27734 11.582 4.10156L9.64844 2.16797C9.47266 1.99219 9.21875 1.875 8.98438 1.875H8.125V0.9375C8.125 0.429688 7.69531 0 7.1875 0H0.9375C0.410156 0 0 0.429688 0 0.9375V7.1875C0 7.71484 0.410156 8.125 0.9375 8.125H1.25C1.25 9.16016 2.08984 10 3.125 10C4.16016 10 5 9.16016 5 8.125H7.5C7.5 9.16016 8.33984 10 9.375 10C10.4102 10 11.25 9.16016 11.25 8.125H12.1875C12.3438 8.125 12.5 7.98828 12.5 7.8125V7.1875C12.5 7.03125 12.3438 6.875 12.1875 6.875ZM3.125 9.0625C2.59766 9.0625 2.1875 8.65234 2.1875 8.125C2.1875 7.61719 2.59766 7.1875 3.125 7.1875C3.63281 7.1875 4.0625 7.61719 4.0625 8.125C4.0625 8.65234 3.63281 9.0625 3.125 9.0625ZM9.375 9.0625C8.84766 9.0625 8.4375 8.65234 8.4375 8.125C8.4375 7.61719 8.84766 7.1875 9.375 7.1875C9.88281 7.1875 10.3125 7.61719 10.3125 8.125C10.3125 8.65234 9.88281 9.0625 9.375 9.0625ZM10.9375 5H8.125V2.8125H8.98438L10.9375 4.76562V5Z" fill="#289AC2"/>
            </mask>
            <g mask="url(#mask0)">
            <rect x="11.3203" y="-7" width="5" height="20" transform="rotate(60 11.3203 -7)" fill="#D3424E"/>
            <rect x="13.8203" y="-2.66992" width="5" height="20" transform="rotate(60 13.8203 -2.66992)" fill="#F27C46"/>
            <rect x="16.3203" y="1.66016" width="5" height="20" transform="rotate(60 16.3203 1.66016)" fill="#289AC2"/>
            </g>
            </svg>;  
            break;
          case "NONE":
            icon = <svg style={iconStyle} width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3269 7.07879H12.0091V5.09238C12.0091 4.85401 11.9296 4.61564 11.7707 4.45673L9.9432 2.62923C9.78429 2.47032 9.54592 2.3114 9.30755 2.3114H8.51298V1.43738C8.51298 0.960642 8.1157 0.56336 7.63896 0.56336H1.60027C1.52081 0.56336 1.3619 0.56336 1.28244 0.642816L0.64679 0.0866211C0.487877 -0.0722919 0.249507 0.00716458 0.0905944 0.166078C-0.0683185 0.32499 -0.00310377 0.56336 0.170051 0.722273L0.805702 1.19901L9.06918 7.71444L10.5788 8.90629L11.9296 9.93922C12.0466 10.0187 12.0885 10.0187 12.168 10.0187C12.2474 10.0187 12.4063 9.93922 12.4858 9.85977C12.6447 9.70085 12.5653 9.46248 12.4063 9.30357L11.4529 8.50901C11.4529 8.42955 11.4529 8.35009 11.4529 8.27064H12.3269C12.4858 8.27064 12.6447 8.11172 12.6447 7.95281V7.39662C12.6447 7.2377 12.4858 7.07879 12.3269 7.07879ZM8.43353 3.26488H9.22809L11.135 5.09238V5.33075H8.43353V3.26488Z" fill="black"/>
            <path d="M9.62311 9.22397C9.22583 9.22397 8.82855 8.90614 8.74909 8.50886L0.644531 2.15234V7.39647C0.644531 7.87321 1.04181 8.27049 1.51855 8.27049H1.83638C1.83638 9.22397 2.63094 10.0185 3.58442 10.0185C4.5379 10.0185 5.41192 9.22397 5.41192 8.27049H7.79561C7.79561 9.22397 8.59018 10.0185 9.54366 10.0185C9.86148 10.0185 10.1793 9.93908 10.4177 9.78016L9.62311 9.22397ZM3.66388 9.22397C3.18714 9.22397 2.78986 8.82669 2.78986 8.34995C2.78986 7.87321 3.18714 7.47593 3.66388 7.47593C4.14062 7.47593 4.5379 7.87321 4.5379 8.34995C4.61735 8.82669 4.22007 9.22397 3.66388 9.22397Z" fill="black"/>
            </svg>
            ;
            break;              
          default:
            icon = <FontAwesomeIcon width={iconStyle.width} height={iconStyle.height} icon={faTruck} className="icon" style={iconStyle}></FontAwesomeIcon>
            break;
        }
        return (
            icon
        );
    }
}
