import React, { Component } from 'react';
import "./NotAuthorized.scss";

class NotAuthorized extends Component {

    render() {
        return (
            <div className="not-authorized">
                <div className="image-container" />
                <div className="text-container">
                    <div className="logo-container" />
                    <div className="title">
                        Sorry, not authorized :(
                    </div>
                    <div className="description">
                        You do not have permission to view this page.
                    </div>
                </div>
            </div>
        )
    }
}

export default NotAuthorized;
