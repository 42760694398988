import L from 'leaflet';
import Wkt from 'wicket';

export default class GeometryUtils {
    static createPolygonFromLatLongBounds(bounds) {
        return new L.Polygon([
            bounds.getNorthWest(),
            bounds.getNorthEast(),
            bounds.getSouthEast(),
            bounds.getSouthWest(),
            bounds.getNorthWest()
        ]);
    }

    static wktToJson(wktString) {
        const wkt = new Wkt.Wkt();
        wkt.read(wktString);

        if (!wkt.components) {
            throw new Error('Invalid WKT string provided to read()');
        }

        return wkt.toJson();
    }

    static jsonToWkt(jsonObject) {
        const wkt = new Wkt.Wkt();
        wkt.read(JSON.stringify(jsonObject));

        if (!wkt.components) {
            throw new Error('Invalid JSON string provided to read()');
        }

        return wkt.write();
    }
}
