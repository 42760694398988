import moment from 'moment';

export default class DurationUtils {

    static DURATION_REGEX = /^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/;

    static formatLong(duration) {
        if (!DurationUtils.isDurationValid(duration)) {
            throw new Error('Provided duration string is not valid.')
        }

        const parsedDuration = moment.duration(duration);
        const minutes = parsedDuration.minutes();
        const hours = parsedDuration.hours();
        const days = parsedDuration.days();
        let time = '';

        if (days > 0) {
            time += `${days} ${days > 1 ? 'days ' : 'day '}`;
        }

        if (hours > 0) {
            time += `${hours} ${hours > 1 ? 'hours ' : 'hour '}`;
        }

        if (minutes > 0) {
            time += `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
        }

        if (time.length === 0) {
            time += 'less than a minute';
        }
        
        return time.trim();
    }

    static isDurationValid(duration) {
        return DurationUtils.DURATION_REGEX.test(duration);
    }
}
