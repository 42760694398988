import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faComment,
    faExclamationCircle,
    faExclamationTriangle,
    faEye,
    faInfoCircle,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { faAlarmSnooze } from '@fortawesome/pro-solid-svg-icons';

import { getCurrentDate as currentDate, getDifferenceBetweenDates } from "global/services/DateTimeService";
import { issueSnoozeable, issueTypes, getIssuePermissionsForAction } from "global/services/IssueTypeService";
import { activityUrl, mapSort, SortParam } from "global/constants";
import deleteComment from 'global/utils/deleteComment';
import DataTableComp from 'components/datatable/DataTableComp';
import TeamIcon from 'components/team-icon/TeamIcon';
import CanAccess from "components/can-access/CanAccess";
import AuthContext from "AuthContext";

import './DetailView.scss';

export default class IssueTable extends Component {
    static contextType = AuthContext;
    constructor(props, context) {
        super(props, context);
        this.columnNames = {
            0: SortParam.ISSUE_TYPE,
            1: SortParam.DRIVER,
            2: SortParam.START_TIME,
            5: SortParam.ISSUE_STATE
        }

        const columns = [
          { title: "Issue type", data: "type" }, // 0
          { title: "Leg ID", data: "legId" }, // 1
          { title: "Driver", data: "driver" }, // 2
          { title: "Created", data: "created" }, // 3
          { title: "Manager notified?", data: "notified" }, // 4
          { title: "Operations notified?", data: "o_notified"}, // 5
          { title: "Issue resolved?", data: "resolved" }, // 6
          { title: "Action", data: "action" }, // 7
        ];
    
        const sortingRule = [];  
        
        const columnDefs = [
            { width: "10%", "targets": [2] },
            { width: "15%", "targets": [0, 3] },
            { width: "20%", "targets": [4, 5, 6] },
            { width: 70, "targets": [7] },
            { className: "dt-align-center", targets: [7] },
            { className: "dt-align-left", targets: [0, 1, 2, 4, 5, 6] },
            { className: "dt-align-right", targets: [3] },
            {
                orderable: false,
                targets: [3, 4, 5, 6, 7]
            },
            {
                targets: 0,
                createdCell: (td, cellData, rowData, row, col) => {
                    const isDetention = rowData.type === "Detained trailer";
                    const isInactivity = rowData.type === "Inactive trailer";
                    const isLowFuelLevel = rowData.type === "Low fuel level";
                    const isLocation = rowData.type === issueTypes.DETACHED || rowData.type === issueTypes.MISSING;
                    const showComponentStyle = !isDetention && !isInactivity && !isLowFuelLevel && !isLocation ? { display: "none" } : { };
                    ReactDOM.render(
                        <div className={`flex ${isInactivity ? "inactivity" : ""} ${isDetention ? "detention" : ""} ${isLowFuelLevel ? "low-fuel-level" : ""} ${isLocation ? "location" : ""} type`} style={{flexWrap: "wrap"}}>
                            <div className={`${isInactivity ? "inactivity-info" : ""} ${isDetention ? "detention-info" : ""} ${isLowFuelLevel ? "low-fuel-level-info" : ""} ${isLocation ? "location-info" : ""}`} style={{display: "flex", float: "left"}}>
                                    <div style={{float: "left", marginRight: "2%"}}>
                                        {(rowData.type === issueTypes.SILENT || rowData.type === issueTypes.DETACHED) && !rowData.resolved && <FontAwesomeIcon className="orangeColorText bold" icon={faExclamationTriangle}/>}
                                        {!rowData.resolved && rowData.type !== issueTypes.SILENT && rowData.type !== issueTypes.DETACHED && <FontAwesomeIcon className="redColorText bold" icon={faExclamationCircle}/>}
                                    </div>
                                    &nbsp;&nbsp;
                                    {rowData.type}
                                    &nbsp;
                                    <>
                                        <div className="notified-man greyColorText italic nowrap-text" style={showComponentStyle}>
                                            &nbsp; &nbsp;
                                            <div>
                                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={showComponentStyle}/>
                                            </div>
                                        </div>
                                    </>
                            </div>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className={`flex leg ${rowData.leg && !rowData.leg.active && "inactive-leg"}`}>
                            {rowData.legId}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            <TeamIcon color={rowData.division} />
                            &nbsp; &nbsp;
                            {rowData.driver || "Unknown"}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";

                    if (!rowData.notified) {
                        className += "bold redColorText";
                        coloredText = "No";
                        otherText += ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                    } else if (rowData.notified) {
                        className += "greenColorText bold";
                        coloredText = `Yes` 
                        otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.notified)})`;
                    }
                    ReactDOM.render(
                    <div className={`no-flex manager-notified`}>
                        <div className={`notification-level ` + className} style={{ marginRight: "2%" }}>
                            { coloredText.indexOf("Yes") !== -1  ? <FontAwesomeIcon icon={faCheckCircle}/> : <FontAwesomeIcon icon={faExclamationCircle}/> }
                            &nbsp; &nbsp; {coloredText}
                        </div>
                        {
                            <div className="notification-info">
                                &nbsp; <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={coloredText === "No" ? {display: "none"} : {}}/>
                            </div>
                        }
                        <div className="notified-man greyColorText italic">
                            {otherText}
                        </div>
                    </div>,
                    td
                    );
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";

                    if(!rowData.resolved) {
                        if (!rowData.o_notified) {
                            className += "bold redColorText";
                            coloredText = "No";
                            if(!rowData.resolved) otherText += ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                        } else if (rowData.o_notified) {
                            className += "greenColorText bold";
                            coloredText = "Yes" 
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.o_notified)})`;
                        }
                        ReactDOM.render(
                        <div className={`no-flex operations-notified`}>
                            <div className={`notification-level ` + className} style={{ marginRight: "2%" }}>
                                { coloredText.indexOf("Yes") !== -1  ? <FontAwesomeIcon icon={faCheckCircle}/> : <FontAwesomeIcon icon={faExclamationCircle}/> }
                                &nbsp; &nbsp; {coloredText}
                            </div>
                            {
                                <div className="notification-info">
                                    &nbsp; <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={coloredText === "No" ? {display: "none"} : {}}/>
                                </div>
                            }
                            <div className="notified-op greyColorText italic">
                                {otherText}
                            </div>
                        </div>,
                        td
                        );
                    } else {
                        let text = "";
                        let className = "";
                        let coloredText = "";
                        if (!rowData.o_notified) {
                            coloredText = "No";
                            className = "";
                        } else {
                            coloredText = `Yes` 
                            text = `(after ${getDifferenceBetweenDates(rowData.created, rowData.o_notified)})`;
                            className="greenColorText"
                        }
                        ReactDOM.render(
                            <div className={`no-flex operations-notified`}>
                                <div className={`notification-level ` + className} style={{ marginRight: "2%" }}>
                                    {coloredText.indexOf("Yes") !== -1  ? <><FontAwesomeIcon icon={faCheckCircle}/>&nbsp;</> : <></> }
                                    {coloredText}
                                </div>
                                {
                                    <div className="notification-info">
                                        &nbsp; <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={coloredText === "No" ? {display: "none"} : {}}/>
                                    </div>
                                }
                                <div className="notified-op greyColorText italic">
                                    {text}
                                </div>
                            </div>,
                            td
                        );
                    }

                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";
                    if (!rowData.resolved) {
                      className += "bold redColorText";
                      coloredText = "Unresolved";
                    } else if (rowData.resolved) {
                      className += "greenColorText bold";
                      if (rowData.issue.state === "CLOSED") {
                        coloredText = "Manually";
                        otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                      } else {
                        coloredText = "Resolved";
                        otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.resolved)})`;
                      }
                    }
                    ReactDOM.render(
                      <div className="resolvedContainer">
                        <span className={className}>
                            {rowData.resolved ? <FontAwesomeIcon className="greenColorText" icon={faCheckCircle}/> : <FontAwesomeIcon className="redColorText" icon={faExclamationCircle}/>}
                            &nbsp;&nbsp;
                            {coloredText}&nbsp;&nbsp;
                            <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.resolved ? {display: "none"} : {marginRight: 4}}/>
                        </span>
                        &nbsp;&nbsp;
                        <div className="greyColorText italic">{otherText}</div>
                      </div>,
                      td
                    );
                },
            },
            {
                targets: 7,
                className: "justify",
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass = "";
                    let snoozeIconClass = "";
                    if (issueSnoozeable(rowData.issue) === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeable(rowData.issue) === 2) {
                          snoozeDivClass = "disabled-snooze";
                          snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                          snoozeDivClass = "disabled-snooze";
                          snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon"
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;

                    ReactDOM.render(
                        <div className="flex-always">
                            <div
                                className="seen"
                                id={rowData.id}
                            >
                                <FontAwesomeIcon icon={faEye} className={seenIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={getIssuePermissionsForAction(rowData.issue, "snooze")}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={snoozeDivClass}>
                                            {snoozeIconClass === "disabled-icon" ? <svg width="16" height="12" style={{marginTop: "2px"}} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                    <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z"/>
                                                    <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z"/>
                                                    <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z"/>
                                                    <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z"/>
                                                    <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z"/>
                                                    <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z"/>
                                                </svg>
                                                : <FontAwesomeIcon icon={faAlarmSnooze} className={snoozeIconClass}/>}
                                        </div>
                                    </>
                                }
                            />
                            &nbsp; &nbsp;
                            <div className="comment">
                                <FontAwesomeIcon icon={faComment} className={commentIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={getIssuePermissionsForAction(rowData.issue, "close")}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={isRelevant ? "resolve" : "disabled-resolve"}>
                                            <FontAwesomeIcon icon={faTimesCircle} className={isRelevant ? "resolve-icon" : "disabled-icon"} />
                                        </div>
                                    </>
                                }
                            />
                        </div>,
                        td
                    );
                }
            }
        ];

        this.state = {
            allIssues: (this.props.unresolvedIssues || []).concat(this.props.resolvedIssues || []),
            resolved: this.props.resolvedIssues || [],
            unresolved: this.props.unresolvedIssues || [],
            resolvedIssuesSort: [],
            unresolvedIssuesSort: [],
            columns: columns,
            columnDefs: columnDefs,
            sortingRule: sortingRule,
            account: this.props.account
        };
        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
    }

    onReceiveNewComment(comment) {
		let newData = this.state.allIssues;
		newData.find((data) => data.issue.id === comment.issue.id).comments.unshift(comment);
		this.setState({
            allIssues: newData
		});
    }
    
    onDeleteComment(deletedComment) {
        let newData = this.state.allIssues;

        this.setState({
            allIssues: deleteComment(deletedComment, newData)
        });
    }

    addSortingForColumn(index, sorting, tableId) {
        const isResolvedIssuesSort = tableId === "detailResolvedIssues";
        const sortingArray = isResolvedIssuesSort ? this.state.resolvedIssuesSort : this.state.unresolvedIssuesSort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);
        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
          }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }
        if(isResolvedIssuesSort) {
            this.setState({
            resolvedIssuesSort: sortingArray
            }, async () => {
                await this.props.fetchIssues(mapSort(this.state.resolvedIssuesSort), true);
            })
        } else {
            this.setState({
                unresolvedIssuesSort: sortingArray
                }, async () => {
                    await this.props.fetchIssues(mapSort(this.state.unresolvedIssuesSort), false);
                })
        }
    }

    componentDidMount() {
        this.state.allIssues.forEach(issue => {
            this.context.post(activityUrl, {
                activity: 'SEEN',
                issue: issue.dbIssue
            });
        });
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setState({
                allIssues: (this.props.unresolvedIssues || []).concat(this.props.resolvedIssues || []),
                resolved: this.props.resolvedIssues || [],
                unresolved: this.props.unresolvedIssues || [],
                account: this.props.account
            }, () => {
                    this.state.allIssues.forEach(issue => {
                        this.context.post(activityUrl, {
                            activity: 'SEEN',
                            issue: issue.dbIssue
                        });
                    });
                }
            )
        }
    }

    render() {
        if(this.props.resolvedIssues || this.props.unresolvedIssues) {
            const resolvedIssues = this.props.resolvedIssues || [];
            const unresolvedIssues = this.props.unresolvedIssues || [];
            return (
                <div className="detail-tables">
                    {unresolvedIssues.length !== 0 ? 
                    <>
                        <br/>
                        <p className="resolution-text">
                            Unresolved issues: 
                        </p>
                        <p className="remaining-issues">
                            ({unresolvedIssues.length} issues remaining)
                        </p>
                        <div className="unresolved-table">
                            {unresolvedIssues && <DataTableComp
                                tableId="detailUnresolvedIssues"
                                columns={this.state.columns}
                                orderRule={this.state.sortingRule}
                                data={unresolvedIssues}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                isDetailView={true}
                                columnDefs={this.state.columnDefs}
                                tableHeight="500px"
                                account={this.state.account}
                                sortRule={this.state.unresolvedIssuesSort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            />}
                        </div>
                    </> : <></>
                    }
                    {resolvedIssues.length !== 0 ? 
                    <>
                        <p className="resolution-text">
                            Resolved issues:
                        </p>
                        <div className="resolved-table">
                            {resolvedIssues && <DataTableComp
                                tableId="detailResolvedIssues"
                                columns={this.state.columns}
                                columnDefs={this.state.columnDefs}
                                isDetailView={true}
                                orderRule={this.state.sortingRule}
                                data={resolvedIssues}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                tableHeight="500px"
                                account={this.state.account}
                                sortRule={this.state.resolvedIssuesSort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            /> }
                        </div>
                    </> : <></> } 
                </div>
            );
        } else {
            return null;
        }
    }
}
