export default class FunctionUtils {
    static debounce(fn, ms = 250) {
        let timeoutId;

        return function (...args) {
            clearTimeout(timeoutId);
            return new Promise((resolve, reject) => {
                timeoutId = setTimeout(() => {
                    try {
                        const result = fn.apply(this, args);
                        if (result instanceof Promise) {
                            result.then(resolve).catch(reject);
                            return;
                        }

                        resolve(result);
                    } catch (e) {
                        reject(e);
                    }
                }, ms);
            });
        };
    }
}
