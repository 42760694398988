import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core'
import { faPen, faEdit, faPlus, faTools, faTrash, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Markdown from 'markdown-to-jsx';

import AuthContext from 'AuthContext';
import { reactPlugin } from 'AppInsights';
import * as DateTimeService from 'global/services/DateTimeService';
import history from 'global/history';
import { PERMISSION } from 'global/utils/auth';
import TermsConditionsPopup from 'components/popup/TermsConditionsPopup';
import Pagination from 'components/paging/Pagination';
import CanAccess from 'components/can-access/CanAccess';
import UnreadContext from 'UnreadContext';

import './ReleaseNotes.scss';

faLibrary.add(faEdit, faPlus, faTools);

class ReleaseNotes extends Component {
    static contextType = AuthContext;

    _markdownOptions = {
        wrapper: Fragment,
        overrides: {
            FontAwesomeIcon: {
                component: FontAwesomeIcon
            }
        }
    };

    state = {
        releaseNotes: [],
        pageNumber: 1,
        pageSize: 0,
        available: 0
    }

    async componentDidMount() {
        await this._fetchNotes();
    }

    async _fetchNotes(page = 1) {
        const response = await this.context.get('/api/v1/release-notes', { pageNumber: page, pageSize: 10 }) || {};

        this.setState({
            releaseNotes: response.data || [] ,
            pageNumber: response.pageNumber,
            pageSize: response.pageSize,
            available: response.available
        });
    }

    async _deleteNote(note) {
        if (window.confirm(`Are you sure you to permanently delete release notes for version ${note.version}?`)) {
            await this.context.delete(`/api/v1/admin/release-notes/${note.id}`);
            await this._fetchNotes();
        }
    }

    _replaceIcons(input) {
        let output = input;
        const regex = /@icon-(.+?) /g;

        let match;
        while ((match = regex.exec(input)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (match.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            output = output.replace(match[0], `<FontAwesomeIcon icon="${match[1]}" />`);
        }

        return output;
    }

    _onPageChange(page) {
        this._fetchNotes(page);
    }

    render() {
        const notes = this.state.releaseNotes.map(releaseNote => (
            <div key={ releaseNote.id } className="row release" >
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <div className="release-header">
                                <img className="logo" src={ process.env.PUBLIC_URL + '/shipex_favicon.svg' } alt="shipex" />
                                <div className="release-info">
                                    <h1 className="date">
                                        Released on { DateTimeService.formatLongDateWithoutTime(new Date(releaseNote.date)) }.
                                    </h1>
                                    <h2 className="version">
                                        Version { releaseNote.version }
                                    </h2>
                                </div>
                                <CanAccess
                                    action={ PERMISSION.RELEASE_NOTES.WRITE }
                                    yes={
                                        <>
                                            <FontAwesomeIcon icon={ faPen }
                                                             className="edit"
                                                             onClick={ () => history.push({ pathname: `/release-notes/edit/${releaseNote.id}` }) } />
                                            <FontAwesomeIcon icon={ faTrash }
                                                             className="delete"
                                                             onClick={ async () => await this._deleteNote(releaseNote) } />
                                        </>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col content">
                            <Markdown options={ this._markdownOptions }>
                                { this._replaceIcons(releaseNote.content) }
                            </Markdown>
                        </div>
                    </div>
                </div>
            </div>
        ));

        return (
            <>
                <UnreadContext.Consumer>
                    { ({ data, updateData }) => {
                        // Mark the latest release note as read to the current user.
                        const notes = (this.state.releaseNotes || []);
                        const readState = (data.releaseNotes || {})

                        if (notes.length > 0 && (notes[0].id !== readState.latest || !readState.read)) {
                            updateData({ releaseNotes: { latest: notes[0].id, read: true } });
                        }
                    } }
                </UnreadContext.Consumer>

                <div className="container-fluid page release-notes-page">
                    <div className="row">
                        <div className="col">
                            <ol className="breadcrumb">
                                <CanAccess
                                    action={ PERMISSION.PAGE.ALL_ISSUES }
                                    yes={ <li className="breadcrumb-item"><a href="/issues/all">All issues</a></li> }
                                    no={ <li className="breadcrumb-item"><a href="/">Fleet Overview</a></li> }
                                />
                                <li className="breadcrumb-item active"><a href="#!">Changelog</a></li>

                                <NavLink to="/" className="back-link">
                                    <p>
                                        &larr; Back to dashboard
                                    </p>
                                </NavLink>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="page-title col">
                            Changelog
                        </div>
                        <CanAccess
                            action={ PERMISSION.RELEASE_NOTES.WRITE }
                            yes={
                                <div className="col-sm col-xs-12 publish-new">
                                    <button className="publish-new btn" onClick={ () => history.push({ pathname: '/release-notes/edit' }) }>
                                        <FontAwesomeIcon icon={ faPlusCircle } />
                                        Add new Release Notes
                                    </button>
                                </div>
                            }
                        />
                    </div>
                    <div className="row">
                        <div className="col page-content">
                            { notes }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Pagination page={ this.state.pageNumber }
                                        size={ this.state.pageSize }
                                        total={ this.state.available }
                                        onChange={ this._onPageChange.bind(this) } />
                        </div>
                    </div>
                </div>
                {!localStorage.getItem('didAgreeToConditions') ?
                    <TermsConditionsPopup/> : <></>
                }
            </>
        );
    }
}

export default withAITracking(reactPlugin, ReleaseNotes, "ReleaseNotes");
