import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckCircle,
    faComment,
    faExclamationCircle,
    faEye,
    faInfoCircle,
    faQuestionCircle,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { faAlarmSnooze } from '@fortawesome/pro-solid-svg-icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Loader from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { reactPlugin } from 'AppInsights';
import AuthContext from "AuthContext"
import SimpleContainer from 'components/container/SimpleContainer';
import DataTableComp from 'components/datatable/DataTableComp';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import TeamIcon from "components/team-icon/TeamIcon"
import ActiveSelection from "components/input/ActiveSelection";
import CanAccess from "components/can-access/CanAccess";
import {
    moreGridRecordsNumber,
    issuesDetailsUrl,
    issuesExportUrl,
    mapSort,
    SortParam
} from 'global/constants'
import { issueSnoozeable } from 'global/services/IssueTypeService';
import { getCurrentDate as currentDate, getDifferenceBetweenDates } from "global/services/DateTimeService"
import { mapIssuesFromResponse } from "global/services/IssueApiService";
import QueryParamService from "global/services/QueryParamService";
import { PERMISSION } from 'global/utils/auth';
import deleteComment from 'global/utils/deleteComment';
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import './InactiveTrailers.scss';

const $ = require("jquery");

const CACHE = {};

class InactiveTrailers extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.columnNames = {
            0: SortParam.ASSIGNMENT,
            1: SortParam.TRAILER,
            2: SortParam.DRIVER,
            4: SortParam.START_TIME
        }

        this.columns = [
            { title: "Order #", data: "id" },
            { title: "Trailer #", data: "trailerId" },
            { title: "Driver", data: "driver" },
            { title: "Status", data: "status" },
            { title: "Since", data: "created" },
            { title: "Last departure date", data: "lastDepartureDate" },
            { title: "Inactive on location", data: "address" },
            { title: "Fuel level", data: "fuelLevel" },
            { title: "Alerts", data: "alerts" },
            { title: "Resolved?", data: "resolved" },
            { title: "Action", data: "action" }
        ];

        this.sortingRule = [];
        this.data = Array.from([]);

        let relevantIssues = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : undefined;

        let buttonNode;
        if (!!relevantIssues || relevantIssues === undefined) {
            relevantIssues = true;
            buttonNode = $(`button[id=relevant]`);

        } else {
            relevantIssues = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addActiveLegToQueryString(window.location.search.slice(1), relevantIssues);

        let currentPage = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page') ? parseInt(QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page')) : 1;

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);
        
        this.state = {
            account: this.props.account,
            data: this.data,
            columns: this.columns,
            sort: [],
            activeLeg: relevantIssues,
            sortingRule: this.sortingRule,
            issuesLoaded: false,
            dataUpdated: false,
            showModal: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).inactivityIssues !== undefined ? JSON.parse(localStorage.getItem('showModals')).inactivityIssues : true,
            doNotShowTutorial: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).inactivityIssues !== undefined ? !JSON.parse(localStorage.getItem('showModals')).inactivityIssues : false,
            page: currentPage,
            pagingInfo: {
                recordsNumber: moreGridRecordsNumber
            },
            csvLoading: false,
        }

        this.state.columnDefs = this.getColumnDefs();

        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
        this.exportCsv = this.exportCsv.bind(this);
        this.getColumnDefs = this.getColumnDefs.bind(this);
    }

    async componentDidMount() {
        await this.fetchAllIssues();
    }

    getColumnDefs() {
        return ([
            { width: "5%", "targets": [0, 1, 2, 7, 10] },
            { width: "10%", "targets": [4, 5, 8, 9] },
            { width: "15%", "targets": [3] },
            { width: "20%", "targets": [6] },
            { className: "dt-align-center", targets: [10] },
            { className: "dt-align-left", targets: [2, 3, 4, 5, 6, 8, 9] },
            { className: "dt-align-right", targets: [0, 1] },
            {
                orderable: false,
                targets: [3, 5, 6, 7, 8, 9, 10]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ this.context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ this.context.permissions } />,
                    td
                )
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="centerAlign" style={{ marginRight: "2%" }}>
                            <TeamIcon color={rowData.division} />
                            &nbsp;&nbsp;
                            <span>{rowData.driver}</span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let mainText = "";
                    let otherText = "";
                    if (rowData.inactive) {
                        mainText = "Inactive \xa0";
                        otherText = ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                        className += "bold orangeColorText";
                    } else if (rowData.status == null) {
                        mainText = "Unknown"
                        className += "bold redColorText"
                    } else if (rowData.status === true) {
                        mainText = "Moving"
                    } else {
                        mainText = "Stopped"
                        className += "bold orangeColorText"
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <div className={className}>
                                {mainText}
                            </div>
                            <span className={className}>
                                {otherText}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex" style={{ minWidth: '40px' }}>
                            {rowData.metaData.position || "Unknown"}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = `bold ${rowData.metaData.fuelLevel && rowData.metaData.fuelLevel >= 50 ? "" : "redColorText"}`;
                    ReactDOM.render(
                        <div>
                            <span className={className}>
                                {rowData.metaData.fuelLevel || "Unknown"} {rowData.metaData.fuelLevel !== undefined && <>%</>}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "greenColorText bold";
                    let coloredText = "";
                    let otherText = "";
                    if (!rowData.alerts) {
                        coloredText = "No";
                        className = "redColorText bold";
                        otherText = `  (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`
                    } else if (rowData.alerts === "Escalation") {
                        coloredText = "Escalation"
                        className = "bold"
                    } else if (rowData.alerts === "Warning" && !rowData.snoozed) {
                        coloredText = "Warning";
                        className = "bold"
                    } else {
                        coloredText = "Warning";
                        className = "bold"
                    }

                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }
                    const showIconStyle = coloredText === "No" ? { display: "none" } : {};

                    ReactDOM.render(
                        <div className="notifications" style={{ minWidth: '80px' }}>
                            <div className={`notification-level ` + className}>
                                {coloredText}
                            </div>
                            <div className="notification-info" style={showIconStyle}>
                                &nbsp;&nbsp;<FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
                            </div>
                            {
                                otherText !== "" &&
                                <div className="greyColorText italic snoozed-info">
                                    <br /> {otherText}
                                </div>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";
                    if (!rowData.resolved) {
                        className += "bold redColorText";
                        coloredText = "No";
                        otherText += ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                    } else if (rowData.resolved) {
                        className += "greenColorText bold";
                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.resolved)})`;
                        }
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <span className={className}>
                                {rowData.resolved ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faExclamationCircle} />}
                                &nbsp;&nbsp;
                                {coloredText}&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.resolved ? { display: "none" } : { marginRight: 4 }} />
                            </span>
                            <span className="greyColorText italic">{otherText}</span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 10,
                className: "justify",
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass = "";
                    let snoozeIconClass = "";
                    if (issueSnoozeable(rowData.issue) === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeable(rowData.issue) === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon"
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;

                    ReactDOM.render(
                        <div className="flex-always">
                            <div className="seen" id={rowData.id}>
                                <FontAwesomeIcon icon={faEye} className={seenIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={ this.context.permissions }
                                action={[PERMISSION.ISSUE.SNOOZE, PERMISSION.ISSUE.INACTIVITY.SNOOZE]}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={snoozeDivClass}>
                                            {snoozeIconClass === "disabled-icon" ? <svg width="16" height="12" style={{ marginTop: "2px" }} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                    <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z" />
                                                    <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z" />
                                                    <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z" />
                                                    <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z" />
                                                    <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z" />
                                                    <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z" />
                                                </svg>
                                                : <FontAwesomeIcon icon={faAlarmSnooze} className={snoozeIconClass} />}
                                        </div>
                                    </>
                                }
                            />
                            &nbsp; &nbsp;
                            <div className="comment">
                                <FontAwesomeIcon icon={faComment} className={commentIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={ this.context.permissions }
                                action={[PERMISSION.ISSUE.CLOSE, PERMISSION.ISSUE.INACTIVITY.CLOSE]}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={isRelevant ? "resolve" : "disabled-resolve"}>
                                            <FontAwesomeIcon icon={faTimesCircle} className={isRelevant ? "resolve-icon" : "disabled-icon"} />
                                        </div>
                                    </>
                                }
                            />
                            <CanAccess
                                permissions={ this.context.permissions } 
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <ReeferControlSvg />
                                    </> 
                                }
                            />
                        </div>,
                        td
                    );
                }
            }
        ])
    }

    componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            this.handlePage(1, true);
        }
    }

    onReceiveNewComment(comment) {
        let newData = this.state.data;
        newData.find((data) => data.issue.id === comment.issue.id).comments.unshift(comment);
        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        let newData = this.state.data;

        this.setState({
            allIssues: deleteComment(deletedComment, newData)
        });
    }

    async handlePage(page, force) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchAllIssues(force);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value),
            },
            page: 1,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;

        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchAllIssues();
            }, 1000);
        }

        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    async fetchAllIssues(force) {
        let cachingIndex = this.context.team.slice().join('_');
        cachingIndex += this.state.activeLeg ? '_relevant' : '_irrelevant';

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        let sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
        let tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }
            const response = await this.context.get(issuesDetailsUrl, {
                types: ["INACTIVITY"],
                active: this.state.activeLeg,
                sort: sort,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                console.error(response.message);
                return {};
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    notificationsLoaded: true,
                    dataUpdated: true
                });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const issues = mapIssuesFromResponse(data);
            const newData = mapGridResponseData(issues, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                data: Array.from([]),
                columns: this.columns,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    onCheckboxChange() {
        this.setState({
            doNotShowTutorial: !this.state.doNotShowTutorial
        });
    }

    openTutorialModal() {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        const existingItem = JSON.parse(localStorage.getItem('showModals'));
        localStorage.setItem('showModals', JSON.stringify({ ...existingItem, ...{ inactivityIssues: !this.state.doNotShowTutorial } }));
        this.setState({
            showModal: false
        });
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }

        let queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    changeActiveness = (relevantIssues) => {
        let queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevantIssues);

        this.setState(prevState => {
            const newState = {
                activeLeg: relevantIssues,
                page: 1,
                pagingInfo: { recordsNumber: moreGridRecordsNumber },
                dataUpdated: false
            };
        
            if (!relevantIssues && queryString.indexOf('start_time') !== -1) {
                QueryParamService.deleteParam('start_time');
                newState.sort = prevState.sort.filter(s => s.columnName !== 'START_TIME');
            }
        
            return newState;
        }, this.fetchAllIssues);
    }

    exportCsv() {
        this.setState({
            csvLoading: true
        });
        
        const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
        this.context.download(issuesExportUrl, {
            types: ["INACTIVITY"],
            active: this.state.activeLeg,
            sort: sort
        })
        .catch((error) => console.error('Error while downloading report inactive trailers csv.', error))
        .finally(() => this.setState({
            csvLoading: false
        }));
    }

    render() {
        return (
            <div className="container-fluid page inactivity-issues">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <CanAccess
                                    action={PERMISSION.PAGE.ALL_ISSUES}
                                    yes={<li className="breadcrumb-item"><a href="/issues/all">All issues</a></li>}
                                    no={<li className="breadcrumb-item"><a href="/">Fleet Overview</a></li>}
                                />
                                <li className="breadcrumb-item active"><a href="#!">Inactive trailers</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading my-0">
                                Inactive trailers
                            </p>
                            <div className="tutorial-info">
                                <p className="tutorial-text"> <FontAwesomeIcon className="tutorial-icon" icon={faQuestionCircle} onClick={this.openTutorialModal.bind(this)}></FontAwesomeIcon></p>
                            </div>
                            &nbsp; &nbsp; <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.issuesLoaded || !this.state.dataUpdated} />
                        </div>
                    </div>
                </div>
                {this.state.issuesLoaded && (this.state.data || []).length > 0 &&
                    <div className="row">
                        <div className="col">
                            <div className="export-csv-div">
                                <Button variant="link" onClick={this.exportCsv}>
                                    Export CSV
                                </Button>
                                <Loader className="csv-loader" type="TailSpin" color="#289AC2" height={20} width={20} visible={this.state.csvLoading} />
                            </div>
                        </div>
                    </div> 
                }
                <div className="row">
                    <div className="col">
                        <div className="active-toggle">
                            <ActiveSelection activeCallback={this.changeActiveness.bind(this)} initiallyActive={this.state.activeLeg} />
                        </div>
                    </div>
                </div>

                {(!this.state.doNotShowTutorial || this.state.showModal) &&
                    <Modal
                        show={this.state.showModal}
                        onHide={this.handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                        keyboard={false}
                        className="tutorial-modal-inactivity"
                    >
                        <SimpleContainer className="tutorial-modal-inactivity" title="INACTIVE TRAILERS TUTORIAL" modal={true}>
                            <Modal.Body className="tutorial-modal-inactivity">
                                <div className="row">
                                    <div className="highlightModalText">
                                        Please first finish steps 1 and 2,  then ONE of the next 4 options depending on what you found.
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="align-top">
                                                        <svg width="27" height="36" viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M25.6641 6.53906L20.4609 1.33594C19.6172 0.492188 18.4219 0 17.2266 0H4.5C2.03906 0 0 2.03906 0 4.5V31.5C0 33.9609 2.03906 36 4.5 36H22.5C24.9609 36 27 33.9609 27 31.5V9.77344C27 8.57812 26.5078 7.38281 25.6641 6.53906ZM23.625 31.5C23.625 32.1328 23.1328 32.625 22.5 32.625H4.5C3.86719 32.625 3.375 32.1328 3.375 31.5V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H15.75V9C15.75 10.2656 16.7344 11.25 18 11.25H23.625V31.5ZM6.75 19.6875C6.75 20.6016 7.52344 21.375 8.4375 21.375H18.5625C19.4766 21.375 20.25 20.6016 20.25 19.6875C20.25 18.7734 19.4766 18 18.5625 18H8.4375C7.52344 18 6.75 18.7734 6.75 19.6875ZM18.5625 24.75H8.4375C7.52344 24.75 6.75 25.5234 6.75 26.4375C6.75 27.3516 7.52344 28.125 8.4375 28.125H18.5625C19.4766 28.125 20.25 27.3516 20.25 26.4375C20.25 25.5234 19.4766 24.75 18.5625 24.75Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 1:
                                                        </p>
                                                        <p className="modalText">
                                                            Check TMW Asset Assignment using the trailer # to determine whether trailer is available for a reload, dropped at a customer facility, etc.
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="vl-container">
                                                        <div style={{ height: 47 }} className="vl"></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="align-top">
                                                        <svg style={{ marginRight: -6 }} width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M40 2.66667H36V1.33333C36 0.596667 35.4033 0 34.6667 0H26.6667C25.93 0 25.3333 0.596667 25.3333 1.33333V2.665L17.3333 2.66667V1.33333C17.3333 0.596667 16.7367 0 16 0H8C7.26333 0 6.66667 0.596667 6.66667 1.33333V2.66667H2.66667C1.19417 2.66667 0 3.86083 0 5.33333V29.3333C0 30.8058 1.19417 32 2.66667 32H40C41.4725 32 42.6667 30.8058 42.6667 29.3333V5.33333C42.6667 3.86083 41.4725 2.66667 40 2.66667ZM38.6667 28H4V6.66667H38.6667V28ZM16.6667 14H7.33333C6.965 14 6.66667 14.2983 6.66667 14.6667V17.3333C6.66667 17.7017 6.965 18 7.33333 18H16.6667C17.035 18 17.3333 17.7017 17.3333 17.3333V14.6667C17.3333 14.2983 17.035 14 16.6667 14ZM35.3333 14H32.6667V11.3333C32.6667 10.965 32.3683 10.6667 32 10.6667H29.3333C28.965 10.6667 28.6667 10.965 28.6667 11.3333V14H26C25.6317 14 25.3333 14.2983 25.3333 14.6667V17.3333C25.3333 17.7017 25.6317 18 26 18H28.6667V20.6667C28.6667 21.035 28.965 21.3333 29.3333 21.3333H32C32.3683 21.3333 32.6667 21.035 32.6667 20.6667V18H35.3333C35.7017 18 36 17.7017 36 17.3333V14.6667C36 14.2983 35.7017 14 35.3333 14Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 2:
                                                        </p>
                                                        <p className="modalText">
                                                            Check Thermoking to confirm current temp settings & location if on an active load
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="modalText modalStepText" style={{ marginLeft: "20%" }} colSpan="5">
                                                        DETERMINE WHETHER:
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="modalText" style={{ marginLeft: "20%", fontWeight: 600 }} colSpan="5">
                                                        <div className="modalStepText" style={{ color: "#289AC2", marginBottom: 5 }}>
                                                            OPTION 1:
                                                        </div>
                                                        Trailer will be loaded soon with a reload or is dropped with a current load waiting to be offloaded
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15 0C6.6875 0 0 6.6875 0 15C0 23.3125 6.6875 30 15 30C23.3125 30 30 23.3125 30 15C30 6.6875 23.3125 0 15 0ZM15 27C8.375 27 3 21.625 3 15C3 8.375 8.375 3 15 3C21.625 3 27 8.375 27 15C27 21.625 21.625 27 15 27ZM16.5 15.25V8.5C16.5 7.6875 15.8125 7 15 7C14.1875 7 13.5 7.6875 13.5 8.5V16C13.5 16.5 13.75 16.9375 14.125 17.1875L18.125 20.1875C18.375 20.375 18.6875 20.5 19 20.5C19.4375 20.5 19.875 20.3125 20.1875 19.875C20.6875 19.25 20.5625 18.3125 19.875 17.8125L16.5 15.25Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            In this case, ensure the trailer is loaded/reloaded in a reasonable amount of time. If time goes beyond broker/customer specifications for requesting Detention Pay, make sure to request this via email
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td className="modalText" style={{ marginLeft: "20%", fontWeight: 600 }} colSpan="5">
                                                        <div className="modalStepText" style={{ color: "#289AC2", marginBottom: 5 }}>
                                                            OPTION 2:
                                                        </div>
                                                        Trailer must remain at customer/drop location
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            Email CSR rep to ensure trailer gets loaded next (vs newly arrived trailers)
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr style={{ height: 18 }}>
                                                </tr>
                                                <tr>
                                                    <td className="modalText" style={{ marginLeft: "20%", fontWeight: 600 }} colSpan="5">
                                                        <div className="modalStepText" style={{ color: "#289AC2", marginBottom: 5 }}>
                                                            OPTION 3:
                                                        </div>
                                                        Trailer must be picked up to ONEWAY back to UT
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            Contact Stacy Day to arrange this
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 4:
                                                        </p>
                                                        <p className="modalText">
                                                            Verify with CSR if trailer is needed for upcoming loads at the location or if it can be removed by ONEWAY
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td className="modalText" style={{ marginLeft: "20%", fontWeight: 600 }} colSpan="5">
                                                        <div className="modalStepText" style={{ color: "#289AC2", marginBottom: 5 }}>
                                                            OPTION 4:
                                                        </div>
                                                        There are discrepancies on trailer #s in TMW (If so, please fix)
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            If the load is already closed out in TMW, reach out to Haley Sorenson to fix on the back end
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <Button variant="continue" onClick={this.handleClose.bind(this)} style={{ width: "240px", height: "36px", marginTop: "15px" }}>
                                    Got it!
                                </Button>
                                <div className="form-check">
                                    <label className={`checkBoxText ${this.state.doNotShowTutorial ? "bold" : ""}`}>
                                        <input
                                            type="checkbox"
                                            name={this.state.label}
                                            checked={this.state.doNotShowTutorial}
                                            onChange={this.onCheckboxChange.bind(this)}
                                            className="form-check-input"
                                        />
                                        Do not show this tip again
                                    </label>
                                </div>
                            </Modal.Body>
                        </SimpleContainer>
                    </Modal>
                }
                <div className="row">
                    <div className="col">
                        <div className="inactive-trailers-div">
                            {this.state.issuesLoaded && <DataTableComp
                                key={this.state.activeLeg}
                                tableId="inactiveTrailersIssues"
                                account={this.state.account}
                                columns={this.state.columns}
                                orderRule={this.state.sortingRule}
                                data={this.state.data}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                isDetailView={false}
                                columnDefs={this.getColumnDefs()}
                                customTableClass="cell-border"
                                tableHeight="1000px"
                                handlePreviousPage={this.handlePreviousPage}
                                handleNextPage={this.handleNextPage}
                                handleFirstPage={this.handleFirstPage}
                                handleLastPage={this.handleLastPage}
                                handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                                handlePageChange={(event) => this.handlePageChange(event)}
                                pagingInfo={this.state.pagingInfo}
                                sortRule={this.state.sort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAITracking(reactPlugin, InactiveTrailers, "InactiveTrailers");
