import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { DatePicker } from "antd";

import QueryParamService from "global/services/QueryParamService";
import {
    formatDateWithoutTime,
    formatLongDateWithoutSeconds,
    getCurrentDate,
    getEndOfDay,
    getStartOfDay,
} from "global/services/DateTimeService";
import AuthorizationService from "global/services/AuthorizationService";
import { PERMISSION } from "global/utils/auth";
import mapGridResponseData from "global/utils/mapGridResponseData";
import formSortObject from "global/utils/formSortObject";
import { mapSort, moreGridRecordsNumber, reportHistoryUrl, SortParam } from "global/constants";
import history from "global/history";
import DataTableComp from "components/datatable/DataTableComp";
import DataTableLink from 'components/datatable/DataTableLink';
import { reactPlugin } from "AppInsights";
import AuthContext from "AuthContext";

import "./TrailerAuditHistory.scss";

class TrailerAuditHistory extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            0: SortParam.TIME,
            1: SortParam.TOTAL_COUNT,
            2: SortParam.DISPATCH_COUNT,
            3: SortParam.HOME_TIME_COUNT,
            4: SortParam.YARD_COUNT,
            5: SortParam.DROP_YARD_COUNT,
            6: SortParam.CLAIM_COUNT,
            7: SortParam.REPAIR_COUNT,
            8: SortParam.MISMATCH_COUNT,
            9: SortParam.ONEWAY_COUNT,
            10: SortParam.UNACCOUNTED_COUNT,
            11: SortParam.DETACHED_COUNT,
            12: SortParam.MISSING_COUNT,
            13: SortParam.SILENT_COUNT,
            14: SortParam.INACTIVE_COUNT
        };

        this.columns = [
            { title: "Created", data: "time" },
            { title: "Total", data: "total", defaultContent: "" },
            { title: "Dispatch", data: "dispatch", defaultContent: "" },
            { title: "Home Time", data: "homeTime", defaultContent: "" },
            { title: "Yard", data: "yard", defaultContent: "" },
            { title: "Drop Yards", data: "dropYard", defaultContent: "" },
            { title: "Claim", data: "claim", defaultContent: "" },
            { title: "Repair", data: "repair", defaultContent: "" },
            { title: "Mismatch", data: "mismatch", defaultContent: "" },
            { title: "Oneway", data: "oneway", defaultContent: "" },
            { title: "Unaccounted", data: "unaccounted", defaultContent: "" },
            { title: "Detached", data: "detached", defaultContent: "" },
            { title: "Missing", data: "missing", defaultContent: "" },
            { title: "Silent", data: "silent", defaultContent: "" },
            { title: "Inactive", data: "inactive", defaultContent: "" },
        ];

        const canReadAll = AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.READ);

        this.columnDefs = [
            { width: "10%", "targets": [0] },
            { width: "6%", "targets": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14] },
            { className: "dt-align-left", targets: [0] },
            { className: "dt-align-right", targets: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14] },
            {
                searchable: false,
                targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
            },
            {
                targets: 0,
                createdCell: (td, cellData, rowData) => {
                    const linkTo = {
                        pathname: `/trailer-audit/${ rowData.id }`,
                        state: { historyUrl: window.location.pathname + window.location.search }
                    };

                    ReactDOM.render(
                        <DataTableLink className="light-bold" to={ linkTo }>
                            { formatLongDateWithoutSeconds(new Date(rowData.time)) }
                        </DataTableLink>,
                        td
                    );
                }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.total }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.TOTAL.READ)
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.dispatch }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.DISPATCH.READ)
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.homeTime }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.HOME_TIME.READ)
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.yard }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.YARD.READ)
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.dropYard }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.DROP_YARD.READ)
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.claim }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.CLAIM.READ)
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.repair }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.REPAIR.READ)
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.mismatch }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.MISMATCH.READ)
            },
            {
                targets: 9,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.oneway }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.ONEWAY.READ)
            },
            {
                targets: 10,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.unaccounted }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.UNACCOUNTED.READ)
            },
            {
                targets: 11,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="count-cell">
                            { rowData.detached }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.DETACHED.READ)
            },
            {
                targets: 12,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div style={ { paddingRight: 23 } }>
                            { rowData.missing }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.MISSING.READ)
            },
            {
                targets: 13,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div style={ { paddingRight: 23 } }>
                            { rowData.silent === undefined ? '-' : rowData.silent }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.SILENT.READ)
            },
            {
                targets: 14,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div style={ { paddingRight: 23 } }>
                            { rowData.inactive === undefined ? '-' : rowData.inactive }
                        </div>,
                        td
                    );
                },
                visible: canReadAll || AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER_AUDIT.INACTIVE.READ)
            }
        ];

        const page = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page');
        const from = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'from');
        const to = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'to');

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        this.previousTeams = context.team;
        this.state = {
            data: [],
            columns: this.columns,
            columnDefs: this.columnDefs,
            sort: [],
            loading: true,
            initialLoad: false,
            page: page ? parseInt(page) : 1,
            pagingInfo: {
                recordsNumber: moreGridRecordsNumber
            },
            from: from || "",
            to: to || ""
        }
    }

    componentDidMount() {
        this.fetchReports();
    }

    componentDidUpdate() {
        if (this.previousTeams !== this.context.team) {
            this.previousTeams = this.context.team;
            this.fetchReports(1);
        }
    }

    async fetchReports(pageNumber) {
        this.setState({
            loading: true
        });

        const queryString = window.location.search.slice(1);
        const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(queryString);
        const tableSortObject = formSortObject(sort, this.columnNames);

        try {
            const response = await this.context.get(reportHistoryUrl, {
                pageNumber: pageNumber || this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber,
                sort,
                from: this.state.from,
                to: this.state.to
            });

            if (response.status === "error" || !response.data) {
                const statusCode = response.message.response.status;

                if (404 === statusCode) {
                    history.replace({
                        pathname: "/404",
                    });
                    return;
                }

                this.setState({
                    loading: false,
                });
                return;
            }

            const currentPage = Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize));
            QueryParamService.addPageToQueryString(queryString, currentPage || 1);

            this.setState({
                data: response.data,
                columns: this.columns,
                columnDefs: this.columnDefs,
                loading: false,
                initialLoad: true,
                pagingInfo: mapGridResponseData(response.data, response).pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error('Error while fetching report history.', error);
            this.setState({
                loading: false,
                sort: tableSortObject
            });
        }
    }

    async handlePage(page) {
        this.setState({
            page,
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchReports();
            this.setState(prevState => ({
                pagingInfo: {
                    ...prevState.pagingInfo,
                    currentPage: prevState.page
                }
            }));
        });
    }

    handlePreviousPage = () => {
        this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = () => {
        this.handlePage(parseInt(this.state.page) + 1);
    }

    handleFirstPage = () => {
        this.handlePage(1);
    }

    handleLastPage = () => {
        this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState(prevState => ({
            pagingInfo: {
                ...prevState.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
        }), () => this.fetchReports());
    }

    handlePageChange = async (event) => {
        const value = event.target.value;
        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
            });
            const that = this;
            setTimeout(function () {
                that.fetchReports();
            }, 1000);
        }
        this.setState(prevState => ({
            pagingInfo: {
                ...prevState.pagingInfo,
                currentPage: prevState.page
            }
        }));
    }

    addSortingForColumn(index, sorting) {
        this.setState(prevState => {
            const sortingArray = prevState.sort;
            const existing = sortingArray.findIndex(x => x && x.columnName === this.columnNames[index]);

            if (existing === -1) {
                sortingArray.push({
                    columnIndex: index,
                    columnName: this.columnNames[index],
                    direction: sorting
                });
            } else if (sorting === "") {
                sortingArray.splice(existing, 1);
            } else {
                sortingArray[existing] = {
                    columnIndex: index,
                    columnName: this.columnNames[index],
                    direction: sorting
                };
            }

            const queryString = window.location.search.slice(1);
            QueryParamService.addSortToQueryString(queryString, sortingArray);

            return {
                sort: sortingArray
            };
        }, () => this.fetchReports());
    }

    onDateChange(fromDate, toDate) {
        const from = fromDate ? getStartOfDay(fromDate) : null;
        const to = toDate ? getEndOfDay(toDate) : null;

        QueryParamService.addParamsToUrl({
            from: from ? from.toISOString() : "",
            to: to ? to.toISOString() : "",
        });

        this.setState({ from, to }, () => this.fetchReports());
    }

    render() {
        const createdFromClassName = this.state.from ? "picker" : "not-selected picker"
        const createdToClassName = this.state.to ? "picker" : "not-selected picker"

        return (
            <div className="container-fluid page report-history">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/trailer-audit">
                                TRAILER AUDIT
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to={ window.location.pathname }>
                                AUDIT HISTORY
                            </Link>
                        </li>
                    </ol>
                </nav>
                <div className="heading-div">
                    <div className="d-flex align-items-center">
                        <p className="heading">
                            Audit History
                        </p>
                        <Loader
                            type="TailSpin"
                            color="#289AC2"
                            height={ 36 }
                            width={ 36 }
                            visible={ this.state.loading }
                        />
                    </div>
                    <div className="filter-container">
                        <div className="filter">
                            <label htmlFor="created-from">
                                Created From
                            </label>
                            <DatePicker
                                allowClear
                                className={ createdFromClassName }
                                showNow={ false }
                                showTime={ false }
                                onChange={ (date) => this.onDateChange(date, this.state.to) }
                                format="YYYY-MM-DD"
                                disabledDate={ d => !d || d.isSameOrAfter(this.state.to || getEndOfDay(getCurrentDate())) }
                                placeholder="Select..."
                                defaultValue={ this.state.from ? getCurrentDate(this.state.from) : "" }
                            />
                        </div>
                        <div className="filter">
                            <label htmlFor="created-to">
                                Created To
                            </label>
                            <DatePicker
                                allowClear
                                className={ createdToClassName }
                                showNow={ false }
                                showTime={ false }
                                format="YYYY-MM-DD"
                                onChange={ (date) => this.onDateChange(this.state.from, date) }
                                disabledDate={ d => !d || d.isSameOrBefore(this.state.from) || d.isSameOrAfter(getEndOfDay(getCurrentDate())) }
                                placeholder={ formatDateWithoutTime(getCurrentDate()) }
                                defaultValue={ this.state.to ? getCurrentDate(this.state.to) : "" }
                            />
                        </div>
                    </div>
                </div>
                <div className="report-history-div">
                    { this.state.initialLoad && (
                        <DataTableComp
                            tableId="reportHistory"
                            columns={ this.state.columns }
                            data={ this.state.data }
                            columnDefs={ this.state.columnDefs }
                            customTableClass="cell-border"
                            tableHeight="1200px"
                            handlePreviousPage={ this.handlePreviousPage }
                            handleNextPage={ this.handleNextPage }
                            handleFirstPage={ this.handleFirstPage }
                            handleLastPage={ this.handleLastPage }
                            handleRecordsNumber={ this.handleRecordsNumber }
                            handlePageChange={ this.handlePageChange }
                            pagingInfo={ this.state.pagingInfo }
                            sortRule={ this.state.sort.concat([]) }
                            addSortingForColumn={ this.addSortingForColumn.bind(this) }
                        />
                    ) }
                </div>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, TrailerAuditHistory));
