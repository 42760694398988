import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheckCircle,
    faComment,
    faExclamationCircle,
    faEye,
    faInfoCircle,
    faTimesCircle,
    faAlarmSnooze,
    faQuestionCircle
} from "@fortawesome/pro-solid-svg-icons";
import $ from "jquery";

import AuthContext from "AuthContext";
import { reactPlugin } from "AppInsights";
import CanAccess from 'components/can-access/CanAccess';
import SimpleContainer from 'components/container/SimpleContainer';
import DataTableComp from "components/datatable/DataTableComp";
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import ActiveSelection from "components/input/ActiveSelection";
import TeamIcon from "components/team-icon/TeamIcon";
import { defaultGridRecordsNumber, issuesDetailsUrl, mapSort, SortParam } from "global/constants";
import { getCurrentDate as currentDate, getDifferenceBetweenDates } from "global/services/DateTimeService";
import { mapIssuesFromResponse } from "global/services/IssueApiService";
import { issueSnoozeable } from "global/services/IssueTypeService";
import QueryParamService from "global/services/QueryParamService";
import { PERMISSION } from "global/utils/auth";
import mapGridResponseData from "global/utils/mapGridResponseData";
import deleteComment from "global/utils/deleteComment";
import formSortObject from "global/utils/formSortObject";
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import "./MismatchIssues.scss";

const CACHE = {};

class MismatchIssues extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            0: SortParam.TRAILER,
            1: SortParam.ASSIGNMENT,
            2: SortParam.COMPANY,
            3: SortParam.DRIVER,
            7: SortParam.START_TIME
        }

        this.columns = [
            { title: "Trailer #", data: "trailerId" },
            { title: "Latest Order #", data: "orderId" },
            { title: "Customer ID", data: "customerId" },
            { title: "Driver", data: "driver" },
            { title: "TMW Company", data: "stopCompanyId" },
            { title: "Actual Company", data: "companyId" },
            { title: "Last known position", data: "address" },
            { title: "Created", data: "created" },
            { title: "Last departure date", data: "lastDepartureDate" },
            { title: "Alerts", data: "alerts" },
            { title: "Resolved?", data: "resolved" },
            { title: "Action", data: "action" }
        ];

        this.columnDefs = [
            { width: "5%", "targets": [0, 1, 3, 9, 10, 11] },
            { width: "10%", "targets": [2, 4, 5, 7, 8] },
            { width: "20%", "targets": [6] },
            { className: "dt-align-left", targets: [2, 3, 4, 5, 6, 7, 8] },
            { className: "dt-align-right", targets: [0, 1] },
            {
                orderable: false,
                targets: [4, 5, 6, 8, 9, 10, 11]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="centerAlign">
                            { rowData.driver !== "Unknown" && rowData.driver !== "-" && (
                                <TeamIcon style={ { marginRight: 6 } } color={ rowData.division } />
                            ) }
                            { rowData.driver }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="long-column">
                            { rowData.stopCompanyId }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="long-column">
                            { rowData.companyBusinessId }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="long-column">
                            { rowData.address }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            { rowData.lastDepartureDate }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, rowData) => {
                    let className = "";
                    let otherText = "";
                    let coloredText;

                    if (!rowData.alerts) {
                        className += " redColorText";
                        coloredText = "No";
                        otherText = `(for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`;
                    } else {
                        coloredText = rowData.alerts;
                    }

                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }

                    ReactDOM.render(
                        <div className="notifications">
                            <div className={ `notification-level bold ${className}` }>
                                { coloredText }
                            </div>
                            <div className="notification-info">
                                <FontAwesomeIcon icon={ faInfoCircle } className="info-icon" style={ !rowData.alerts ? { display: "none" } : { marginLeft: 4 } }/>
                            </div>
                            {
                                otherText &&
                                <div style={ { marginRight: 4, marginLeft: 4 } } className="greyColorText italic snoozed-info">
                                    <br/> { otherText }
                                </div>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 10,
                createdCell: (td, cellData, rowData) => {
                    let className = "bold";
                    let otherText;
                    let coloredText;

                    if (!rowData.resolved) {
                        className += " redColorText";
                        coloredText = "No";
                        otherText = `(for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                    } else {
                        className += " greenColorText";
                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.resolved)})`;
                        }
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <span className={ className }>
                                <FontAwesomeIcon icon={ rowData.resolved ? faCheckCircle : faExclamationCircle }/>
                                <span style={ { marginRight: 4, marginLeft: 4 } }>
                                    { coloredText }
                                </span>
                                <FontAwesomeIcon icon={ faInfoCircle } className="info-icon" style={ !rowData.resolved ? { display: "none" } : {} }/>
                            </span>
                            <span style={ { marginRight: 4, marginLeft: 4 } } className="greyColorText italic">
                              { otherText }
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 11,
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass;
                    let snoozeIconClass;

                    const issueSnoozeableCode = issueSnoozeable(rowData.issue);
                    if (issueSnoozeableCode === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeableCode === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon";
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;

                    ReactDOM.render(
                        <div className="action-items">
                            <div className="seen" id={ rowData.id }>
                                <FontAwesomeIcon icon={ faEye } className={ seenIconClass }/>
                            </div>
                            <CanAccess
                                permissions={ context.permissions }
                                action={ [PERMISSION.ISSUE.SNOOZE, PERMISSION.ISSUE.LOCATION_MISMATCH.SNOOZE] }
                                yes={
                                    <div className={ snoozeDivClass }>
                                        { snoozeIconClass === "disabled-icon" ?
                                            <svg width="16" height="12" style={ { marginTop: "2px" } } viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z"/>
                                                <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z"/>
                                                <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z"/>
                                                <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z"/>
                                                <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z"/>
                                                <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z"/>
                                            </svg>
                                            : <FontAwesomeIcon icon={ faAlarmSnooze } className={ snoozeIconClass }/>
                                        }
                                    </div>
                                }
                            />
                            <div className="comment">
                                <FontAwesomeIcon icon={ faComment } className={ commentIconClass }/>
                            </div>
                            <CanAccess
                                permissions={ context.permissions }
                                action={ [PERMISSION.ISSUE.CLOSE, PERMISSION.ISSUE.LOCATION_MISMATCH.CLOSE] }
                                yes={
                                    <div className={ !isRelevant ? "disabled-resolve" : "resolve" }>
                                        <FontAwesomeIcon icon={ faTimesCircle } className={ !isRelevant ? "disabled-icon" : "resolve-icon" } />
                                    </div>
                                }
                            />
                            <CanAccess
                                permissions={ context.permissions } 
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <div>
                                        <ReeferControlSvg />
                                    </div> 
                                }
                            />
                        </div>,
                        td
                    );
                }
            }
        ];

        let relevantIssues = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : undefined;

        let buttonNode;
        if (!!relevantIssues || relevantIssues === undefined) {
            relevantIssues = true;
            buttonNode = $(`button[id=relevant]`);
        } else {
            relevantIssues = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addActiveLegToQueryString(window.location.search.slice(1), relevantIssues);

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        const page = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page');

        this.state = {
            data: [],
            columns: this.columns,
            columnDefs: this.columnDefs,
            sort: [],
            issuesLoaded: false,
            showModal: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).mismatchIssues !== undefined ? JSON.parse(localStorage.getItem('showModals')).mismatchIssues : true,
            doNotShowTutorial: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).mismatchIssues !== undefined ? !JSON.parse(localStorage.getItem('showModals')).mismatchIssues : false,
            activeLeg: relevantIssues,
            page: page ? parseInt(page) : 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            }
        }
    }

    componentDidMount() {
        this.fetchAllIssues();
    }

    componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            this.handlePage(1, true);
        }
    }

    async fetchAllIssues(force) {
        let cachingIndex = this.context.team.slice().join('_');
        cachingIndex += this.state.activeLeg ? '_relevant' : '_irrelevant';

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        const queryString = window.location.search.slice(1);
        const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(queryString);
        const tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }

            const response = await this.context.get(issuesDetailsUrl, {
                types: ["LOCATION_MISMATCH"],
                active: this.state.activeLeg,
                sort: sort,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                console.error(response.message);
                this.setState({
                    data: [],
                    notificationsLoaded: true,
                    dataUpdated: true,
                    issuesLoaded: true
                });

                return;
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    data: [],
                    notificationsLoaded: true,
                    dataUpdated: true,
                    issuesLoaded: true
                });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const issues = mapIssuesFromResponse(data);
            const newData = mapGridResponseData(issues, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                issuesLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                issuesLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const existing = sortingArray.findIndex(x => x && x.columnName === this.columnNames[index]);

        if (existing === -1) {
            sortingArray.push({
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            });
        } else if (sorting === "") {
            sortingArray.splice(existing, 1);
        } else {
            sortingArray[existing] = {
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            }
        }

        const queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    changeActiveness(relevantIssues) {
        const queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevantIssues);

        this.setState({
            activeLeg: relevantIssues,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber,
            },
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    onReceiveNewComment(comment) {
        const newData = this.state.data;
        newData.find(data => data.issue.id === comment.issue.id).comments.unshift(comment);

        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        this.setState({
            allIssues: deleteComment(deletedComment, this.state.data)
        });
    }

    async handlePage(page, force) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchAllIssues(force);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues()
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;
        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchAllIssues();
            }, 1000);
        }
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    onCheckboxChange() {
        this.setState({
            doNotShowTutorial: !this.state.doNotShowTutorial
        });
    }

    openTutorialModal() {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        const existingItem = JSON.parse(localStorage.getItem('showModals'));
        localStorage.setItem('showModals', JSON.stringify({...existingItem, ...{mismatchIssues: !this.state.doNotShowTutorial }}));
        this.setState({
            showModal: false
        });
    }

    render() {
        let tutorial;

        if (!this.state.doNotShowTutorial || this.state.showModal) {
            tutorial = (
                <Modal
                    show={this.state.showModal}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                    className="tutorial-modal-fuel"
                >
                    <SimpleContainer className="tutorial-modal-fuel" title="MISMATCHED TRAILERS TUTORIAL" modal={true}>
                        <Modal.Body className="tutorial-modal-fuel">
                            <div className="row">
                                <div className="col-sm-4 col-xs-12">
                                    <table style={{borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto"}}>
                                        <tbody>
                                            <tr>
                                                <td className="align-top">
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={{ paddingLeft: 10 }} colSpan="2">
                                                    <p className="modalText modalStepText" style={{paddingBottom:"4px"}}>
                                                        STEP 1:
                                                    </p>
                                                    <p className="modalText">
                                                        Compare the TMW Company with the Actual Company column
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="vl-container">
                                                    <div style={{ height: 24 }} class="vl"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-top">
                                                    <svg width="36" height="33" viewBox="0 0 36 33" fill="none">
                                                        <path d="M31.5 5.25H26.4375L25.875 3.70312C25.1719 1.94531 23.4844 0.75 21.6562 0.75H14.3438C12.5156 0.75 10.8281 1.94531 10.125 3.63281L9.5625 5.25H4.5C2.03906 5.25 0 7.28906 0 9.75V27.75C0 30.2109 2.03906 32.25 4.5 32.25H31.5C33.9609 32.25 36 30.2109 36 27.75V9.75C36 7.28906 33.9609 5.25 31.5 5.25ZM32.625 27.75C32.625 28.3828 32.1328 28.875 31.5 28.875H4.5C3.86719 28.875 3.375 28.3828 3.375 27.75V9.75C3.375 9.11719 3.86719 8.625 4.5 8.625H11.8828L12.7266 6.44531L13.2891 4.82812C13.5 4.40625 13.9219 4.125 14.3438 4.125H21.6562C22.0781 4.125 22.5 4.40625 22.7109 4.82812L23.2734 6.44531L24.1172 8.625H31.5C32.1328 8.625 32.625 9.11719 32.625 9.75V27.75ZM18 10.875C13.6406 10.875 10.125 14.3906 10.125 18.75C10.125 23.1094 13.6406 26.625 18 26.625C22.3594 26.625 25.875 23.1094 25.875 18.75C25.875 14.3906 22.3594 10.875 18 10.875ZM18 23.25C15.5391 23.25 13.5 21.2109 13.5 18.75C13.5 16.2891 15.5391 14.25 18 14.25C20.4609 14.25 22.5 16.2891 22.5 18.75C22.5 21.2109 20.4609 23.25 18 23.25Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={{ paddingLeft: 10 }} colSpan="2">
                                                    <p className="modalText modalStepText" style={{paddingBottom:"4px"}}>
                                                        STEP 2:
                                                    </p>
                                                    <p className="modalText">
                                                        Open the load in TMW
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-sm-4 col-xs-6">
                                    <table style={{borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto"}}>
                                        <tbody>
                                            <tr>
                                                <td className="modalText" style={{ marginLeft: "20%", fontWeight: 600 }} colSpan="5">
                                                    <div className="modalStepText" style={{ color: "#289AC2", marginBottom: 5 }}>
                                                    OPTION 1:
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr />
                                            <tr>
                                                <td colSpan="2" className="vllContainer">
                                                    <div className="vll"></div>
                                                </td>
                                                <td className="align-top">
                                                    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17 23.125C15.7344 23.125 14.75 24.1094 14.75 25.375C14.75 26.6406 15.7344 27.625 17 27.625C18.2656 27.625 19.25 26.6406 19.25 25.375C19.25 24.1094 18.2656 23.125 17 23.125ZM19.3906 8.5H15.7344C12.9922 8.5 10.8125 10.6797 10.8125 13.4219C10.8125 14.3359 11.5859 15.1094 12.5 15.1094C13.4141 15.1094 14.1875 14.3359 14.1875 13.4219C14.1875 12.5781 14.8906 11.875 15.7344 11.875H19.3906C20.2344 11.875 20.9375 12.5781 20.9375 13.4219C20.9375 13.9844 20.6562 14.4766 20.1641 14.7578L16.1562 17.1484C15.5938 17.5 15.3125 18.0625 15.3125 18.625V19.75C15.3125 20.6641 16.0859 21.4375 17 21.4375C17.9141 21.4375 18.6875 20.6641 18.6875 19.75V19.6094L21.9219 17.6406C23.3984 16.7266 24.3125 15.1094 24.3125 13.4219C24.3125 10.6797 22.1328 8.5 19.3906 8.5ZM17 0.625C7.64844 0.625 0.125 8.14844 0.125 17.5C0.125 26.8516 7.64844 34.375 17 34.375C26.3516 34.375 33.875 26.8516 33.875 17.5C33.875 8.14844 26.3516 0.625 17 0.625ZM17 31C9.54688 31 3.5 24.9531 3.5 17.5C3.5 10.0469 9.54688 4 17 4C24.4531 4 30.5 10.0469 30.5 17.5C30.5 24.9531 24.4531 31 17 31Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={{ paddingLeft: 10 }} colSpan="2">
                                                    <p className="modalText">
                                                        The load may be missing a BMT or DMT
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr />
                                            <tr>
                                                <td className="modalText" style={{ marginLeft: "20%", fontWeight: 600 }} colSpan="5">
                                                    <div className="modalStepText" style={{ color: "#289AC2", marginBottom: 5 }}>
                                                    OPTION 2:
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr />
                                            <tr>
                                                <td colSpan="2" className="vllContainer">
                                                    <div className="vll"></div>
                                                </td>
                                                <td className="align-top">
                                                    <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M35.5781 14.8828L28.125 2.29688C27.5625 1.38281 26.5078 0.75 25.3828 0.75H10.6172C9.42188 0.75 8.4375 1.38281 7.875 2.29688L0.421875 14.8828C-0.140625 15.8672 -0.140625 17.1328 0.421875 18.1172L7.875 30.7031C8.4375 31.6172 9.42188 32.25 10.6172 32.25H25.3828C26.5781 32.25 27.5625 31.6172 28.125 30.7031L35.5781 18.1172C36.1406 17.1328 36.1406 15.8672 35.5781 14.8828ZM25.3125 28.875H10.6875L3.44531 16.5L10.6875 4.125H25.3125L32.5547 16.5L25.3125 28.875ZM23.9766 10.5234C23.2734 9.89062 22.2188 9.89062 21.5859 10.5234L18 14.1094L14.4141 10.5234C13.7812 9.89062 12.7266 9.89062 12.0234 10.5234C11.3906 11.2266 11.3906 12.2812 12.0234 12.9141L15.6094 16.5L12.0234 20.0859C11.3906 20.7188 11.3906 21.7734 12.0234 22.4766C12.7266 23.1094 13.7812 23.1094 14.4141 22.4766L18 18.8906L21.5859 22.4766C22.2188 23.1094 23.2734 23.1094 23.9766 22.4766C24.6094 21.7734 24.6094 20.7188 23.9766 20.0859L20.3906 16.5L23.9766 12.9141C24.6094 12.2812 24.6094 11.2266 23.9766 10.5234Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={{ paddingLeft: 10 }} colSpan="2">
                                                    <p className="modalText">
                                                        The load may have incorrect event codes (i.e. LLD vs HPL)
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr />
                                            <tr>
                                                <td className="modalText" style={{ marginLeft: "20%", fontWeight: 600 }} colSpan="5">
                                                    <div className="modalStepText" style={{ color: "#289AC2", marginBottom: 5 }}>
                                                    OPTION 3:
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr />
                                            <tr>
                                                <td colSpan="2" className="vllContainer">
                                                    <div className="vll"></div>
                                                </td>
                                                <td className="align-top">
                                                    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.1875 6.19941H34.3125C35.2266 6.19941 36 5.42597 36 4.51191C36 3.59784 35.2266 2.82441 34.3125 2.82441H15.1875C14.2734 2.82441 13.5 3.59784 13.5 4.51191C13.5 5.42597 14.2734 6.19941 15.1875 6.19941ZM3.375 24.7619C2.10938 24.7619 1.125 25.7463 1.125 27.0119C1.125 28.2775 2.10938 29.2619 3.375 29.2619C4.64062 29.2619 5.625 28.2775 5.625 27.0119C5.625 25.7463 4.64062 24.7619 3.375 24.7619ZM34.3125 14.0744H15.1875C14.2734 14.0744 13.5 14.8478 13.5 15.7619C13.5 16.676 14.2734 17.4494 15.1875 17.4494H34.3125C35.2266 17.4494 36 16.676 36 15.7619C36 14.8478 35.2266 14.0744 34.3125 14.0744ZM34.3125 25.3244H12.9375C12.0234 25.3244 11.25 26.0978 11.25 27.0119C11.25 27.926 12.0234 28.6994 12.9375 28.6994H34.3125C35.2266 28.6994 36 27.926 36 27.0119C36 26.0978 35.2266 25.3244 34.3125 25.3244ZM8.29688 0.574407L4.42969 4.86347L2.88281 3.31659C2.25 2.68378 1.125 2.68378 0.492188 3.31659C-0.140625 3.94941 -0.140625 5.07441 0.492188 5.70722L3.30469 8.51972C3.65625 8.80097 4.07812 9.01191 4.5 9.01191H4.57031C4.99219 9.01191 5.41406 8.80097 5.76562 8.44941L10.8281 2.82441C11.4609 2.12128 11.3906 1.06659 10.6875 0.433782C9.98438 -0.19903 8.92969 -0.128718 8.29688 0.574407ZM8.29688 11.8244L4.42969 16.1135L2.88281 14.5666C2.25 13.9338 1.125 13.9338 0.492188 14.5666C-0.140625 15.1994 -0.140625 16.3244 0.492188 16.9572L3.30469 19.7697C3.65625 20.051 4.07812 20.2619 4.5 20.2619H4.57031C4.99219 20.2619 5.41406 20.051 5.76562 19.6994L10.8281 14.0744C11.4609 13.3713 11.3906 12.3166 10.6875 11.6838C9.98438 11.051 8.92969 11.1213 8.29688 11.8244Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={{ paddingLeft: 10 }} colSpan="2">
                                                    <p className="modalText">
                                                        Review for any other issues that would cause it to be mismatched
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-sm-4 col-xs-6">
                                    <table style={{borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto"}}>
                                        <tbody>
                                            <tr>
                                                <td className="align-top">
                                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95"/>
                                                    </svg>
                                                </td>
                                                <td style={{ paddingLeft: 10 }} colSpan="2">
                                                    <p className="modalText modalStepText" style={{paddingBottom:"4px"}}>
                                                        STEP 3:
                                                    </p>
                                                    <p className="modalText">
                                                        If discrepancies are found, correct them in TMW. If the load is already invoiced and greyed out, please contact helpdesk@shipex.com to perform the edit and fix the issue.
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Button variant="continue" onClick={this.handleClose.bind(this)} style={{width: "240px", height: "36px", marginTop: "15px"}}>
                                Got it!
                            </Button>
                            <div className="form-check">
                                <label className={`checkBoxText ${this.state.doNotShowTutorial ? "bold" : ""}`}>
                                    <input
                                        type="checkbox"
                                        name={this.state.label}
                                        checked={this.state.doNotShowTutorial}
                                        onChange={this.onCheckboxChange.bind(this)}
                                        className="form-check-input"
                                    />
                                    Do not show this tip again
                                </label>
                            </div>
                        </Modal.Body>
                    </SimpleContainer>
                </Modal>
            );
        }

        return (
            <div className="container-fluid page mismatch-issues">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <CanAccess
                                    action={PERMISSION.PAGE.ALL_ISSUES}
                                    yes={
                                        <li className="breadcrumb-item">
                                            <Link to="/issues/all">
                                                All issues
                                            </Link>
                                        </li>
                                    }
                                    no={
                                        <li className="breadcrumb-item">
                                            <Link to="/">
                                                Fleet overview
                                            </Link>
                                        </li>
                                    }
                                />
                                <li className="breadcrumb-item active">
                                    <Link to={window.location.pathname}>
                                        Mismatched trailer issues
                                    </Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading">
                                Mismatched trailers
                            </p>
                            <FontAwesomeIcon className="tutorial-icon" icon={faQuestionCircle} onClick={this.openTutorialModal.bind(this)} />
                            <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.issuesLoaded || !this.state.dataUpdated} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="active-toggle">
                            <ActiveSelection activeCallback={this.changeActiveness.bind(this)} initiallyActive={this.state.activeLeg} />
                        </div>
                    </div>
                </div>
                { tutorial }
                <div className="row">
                    <div className="col">
                        <div className="mismatch-issues-div">
                            {this.state.issuesLoaded && (
                                <DataTableComp
                                    tableId="mismatchedTrailerIssues"
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    onReceiveNewComment={this.onReceiveNewComment.bind(this)}
                                    onDeleteComment={this.onDeleteComment.bind(this)}
                                    columnDefs={this.state.columnDefs}
                                    customTableClass="cell-border"
                                    tableHeight="1200px"
                                    handlePreviousPage={this.handlePreviousPage}
                                    handleNextPage={this.handleNextPage}
                                    handleFirstPage={this.handleFirstPage}
                                    handleLastPage={this.handleLastPage}
                                    handleRecordsNumber={this.handleRecordsNumber}
                                    handlePageChange={this.handlePageChange}
                                    pagingInfo={this.state.pagingInfo}
                                    onClickLocation={{
                                        pathname: "/details",
                                        state: { trailerId: this.state.data.onClickState },
                                    }}
                                    account={this.props.account}
                                    sortRule={this.state.sort.concat([])}
                                    addSortingForColumn={this.addSortingForColumn.bind(this)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, MismatchIssues, "MismatchIssues"));
