import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import {
    faClipboardListCheck as trailerAuditIcon,
    faTachometerAlt as fleetOverviewIcon
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FleetOverviewHelp from 'pages/help/FleetOverviewHelp';
import TrailerAuditHelp from 'pages/help/TrailerAuditHelp';
import history from 'global/history';

import "./HelpCenter.scss";

export default class HelpCenter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tab: props.tab
        };
    }

    changeTab(tab) {
        history.push(`/help/${ tab }`);
    }

    render() {
        const fleetOverviewTabTitle = (
            <>
                <FontAwesomeIcon icon={ fleetOverviewIcon } className="tab-icon" />
                Fleet Overview
            </>
        );

        const trailerAuditTabTitle = (
            <>
                <FontAwesomeIcon icon={ trailerAuditIcon } className="tab-icon" />
                Trailer Audit
            </>
        );

        return (
            <>
                <div className="container-fluid help-center">
                    <h1 className="page-title">Help Center</h1>
                    <Tabs id="help-tabs"
                          defaultActiveKey={ this.state.tab }
                          mountOnEnter
                          unmountOnExit
                          onSelect={ this.changeTab }>
                        <Tab key="fleet-overview"
                             eventKey="fleet-overview"
                             title={ fleetOverviewTabTitle }
                             tabClassName="tab">
                            <FleetOverviewHelp />
                        </Tab>

                        <Tab key="trailer-audit"
                             eventKey="trailer-audit"
                             title={ trailerAuditTabTitle }
                             tabClassName="tab">
                            <TrailerAuditHelp />
                        </Tab>
                    </Tabs>
                </div>
            </>
        )
    }
}
