import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import Pill from 'components/input/Pill';
import { TrailerMap } from 'components/map/TrailerMap';
import Message from 'components/Message';
import AuthContext from 'AuthContext';

import './TrailerLocation.scss';

export default class TrailerLocation extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            showAllTrailers: 'true' === localStorage.getItem('showAllTrailersOnMap'),
            message: null,
            messageType: null,
            loading: true
        };

        this.onMapLoadingChange = this.onMapLoadingChange.bind(this);
        this.onShowAllTrailersChange = this.onShowAllTrailersChange.bind(this);
    }

    onMapLoadingChange(loading, available, pageSize) {
        this.setState(prevState => {
            let message = prevState.message;
            let messageType = prevState.messageType;

            if (!loading) {
                if (available === undefined) {
                    message = 'Unknown trailer location';
                    messageType = 'error';
                } else if (available > pageSize) {
                    message = (
                        <span>
                            Showing <b>{ pageSize }</b> out of <b>{ available }</b> trailers in this region.
                        </span>
                    );
                    messageType = 'info';
                } else {
                    message = null;
                }
            }

            return { loading, message, messageType };
        });
    }

    onShowAllTrailersChange(showAllTrailers) {
        this.setState(
            { showAllTrailers },
            () => localStorage.setItem('showAllTrailersOnMap', showAllTrailers.toString())
        );
    }

    render() {
        return (
            <div className="trailer-location">
                <div className="trailer-map-info">
                    { this.state.message && (
                        <Message type={ this.state.messageType }>
                            <div className="text-right">
                                { this.state.message }
                            </div>
                        </Message>
                    ) }
                    <Loader type="TailSpin"
                            color="#289AC2"
                            height={ 26 }
                            width={ 26 }
                            visible={ this.state.loading } />
                    <div className="trailers-pill-container">
                        Show all trailers
                        <Pill uncheckedGreyColor
                              disabled={ this.state.loading }
                              checked={ this.state.showAllTrailers }
                              onChange={ this.onShowAllTrailersChange } />
                    </div>
                </div>
                <TrailerMap height={ 390 }
                            trailerId={ this.props.trailerId }
                            showAllTrailers={ this.state.showAllTrailers }
                            onLoadingChange={ this.onMapLoadingChange } />
            </div>
        );
    }
}
