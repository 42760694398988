import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faAlarmSnooze,
    faComment,
    faEye,
    faInfoCircle,
    faTimesCircle,
    faCheckCircle,
    faExclamationCircle
} from '@fortawesome/pro-solid-svg-icons';

import DataTableComp from 'components/datatable/DataTableComp';
import { activityUrl, mapSort, SortParam } from 'global/constants';
import { issueSnoozeable } from 'global/services/IssueTypeService';
import deleteComment from 'global/utils/deleteComment';
import { getCurrentDate as currentDate, getDifferenceBetweenDates } from 'global/services/DateTimeService';
import AuthContext from 'AuthContext';

import './DetailView.scss';

export default class InactiveInYardTable extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.columnNames = {
            2: SortParam.START_TIME
        }

        const columns = [
            { title: "Trailer position", data: "trailerPosition"}, // 0
            { title: "Drop yard", data: "companyName" }, // 1
            { title: "Created", data: "created" }, // 2
            { title: "Alerts", data: "alerts" }, // 3
            { title: "Resolved", data: "resolved" }, // 4
            { title: "Action", data: "action" } // 5
        ];

        const sortingRule = [];  

        const columnDefs = [
            { width: "10%", "targets": [3, 5]},
            { width: "15%", "targets": [1, 2, 4]},
            { width: "30%", "targets": [0] },
            { className: "dt-align-left", targets: [0, 1, 2, 3, 4] },
            {
                orderable: false,
                targets: [0, 1, 3, 4, 5]
            },
            {
              targets: 0,
              createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                      <div>
                          {rowData.trailerPosition}
                      </div>,
                      td
                  );
              }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            {rowData.companyName}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            {rowData.created}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "greenColorText bold";
                    let coloredText = "";
                    let otherText = "";
                    if (!rowData.alerts) {
                        coloredText = "No";
                        className = "redColorText bold";
                        otherText = `  (for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`
                    } else if (rowData.alerts === "Warning") {
                        coloredText = "Warning";
                        className = "bold"
                    }
                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }
                    const showIconStyle = coloredText === "No" ? {display: "none"} : {};
                    ReactDOM.render(
                        <div className="notifications" style={{minWidth: '80px'}}>
                            <div className={`notification-level ` + className}>
                                {coloredText}
                            </div>
                            <div className="notification-info" style={showIconStyle}>
                                &nbsp;&nbsp;<FontAwesomeIcon icon={faInfoCircle} className="info-icon"/>
                            </div>
                            {
                                otherText !== "" &&
                                <div className="greyColorText italic snoozed-info">
                                    <br /> {otherText}
                                </div>
                            }
                        </div>,
                        td
                    );
                    }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";

                    if (!rowData.resolved) {
                        className += "bold redColorText";
                        coloredText = "No";
                        otherText += ` (for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`;
                    } else if (rowData.resolved) {
                        className += "greenColorText bold";
                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.beginning, rowData.resolved)})`;
                        }
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer" >
                            <div className={className}>
                                {rowData.resolved ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faExclamationCircle} />}
                                &nbsp;&nbsp;
                                {coloredText}&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.resolved ? { display: "none" } : { marginRight: 4 }} />
                            </div>
                            <div className="greyColorText italic">{otherText}</div>
                        </div>,
                        td
                    );
                }
            },
            {
              targets: 5,
              className: "justify",
              createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass = "";
                    let snoozeIconClass = "";
                    
                    const issueSnoozeableCode = issueSnoozeable(rowData.issue);
                    if (issueSnoozeableCode === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeableCode === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon"
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;
                    
                    ReactDOM.render(
                      <div className="flex-always">
                          <div
                              className="seen"
                              id={rowData.id}
                          >
                              <FontAwesomeIcon icon={faEye} className={seenIconClass}/>
                          </div>
                          &nbsp; &nbsp;
                          <div className={snoozeDivClass}>
                              {snoozeIconClass === "disabled-icon" ? <svg width="16" height="12" style={{marginTop: "2px"}} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                      <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z"/>
                                      <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z"/>
                                      <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z"/>
                                      <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z"/>
                                      <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z"/>
                                      <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z"/>
                                  </svg>
                                  : <FontAwesomeIcon icon={faAlarmSnooze} className={snoozeIconClass}/>}
                          </div>
                          &nbsp; &nbsp;
                          <div className="comment">
                              <FontAwesomeIcon icon={faComment} className={commentIconClass}></FontAwesomeIcon>
                          </div>
                          &nbsp; &nbsp;
                          <div className={isRelevant ? "resolve" : "disabled-resolve"}>
                               <FontAwesomeIcon icon={faTimesCircle} className={isRelevant ? "resolve-icon" : "disabled-icon"} />
                          </div>
                      </div>,
                      td
                    );
              }
            }
        ];

        this.state = {
            allIssues: (this.props.unresolvedIssues || []).concat(this.props.resolvedIssues || []),
            resolved: this.props.resolvedIssues || [],
            unresolved: this.props.unresolvedIssues || [],
            resolvedIssuesSort: [],
            unresolvedIssueSort: [],
            columns: columns,
            columnDefs: columnDefs,
            sortingRule: sortingRule,
            account: this.props.account
        };
        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
    }

    onReceiveNewComment(comment) {
        let newData = this.state.allIssues;
        newData.find((data) => data.issue.id === comment.issue.id).comments.unshift(comment);
        this.setState({
            allIssues: newData
        });
    }
    
    onDeleteComment(deletedComment) {
        let newData = this.state.allIssues;

        this.setState({
            allIssues: deleteComment(deletedComment, newData)
        });
    }

    addSortingForColumn(index, sorting, tableId) {
        const isResolvedIssuesSort = tableId === "inYardResolvedIssues";
        const sortingArray = isResolvedIssuesSort ? this.state.resolvedIssuesSort : this.state.unresolvedIssueSort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);
        if (existing === -1) {
            sortingArray.push({
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            });
        } else if (sorting === "") {
            sortingArray.splice(existing, 1)
        } else {
            sortingArray[existing] = {
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            };
        }
        
        if (isResolvedIssuesSort) {
            this.setState({
            resolvedIssuesSort: sortingArray
            }, () => {
                this.props.fetchIssues(mapSort(this.state.resolvedIssuesSort), true);
            })
        } else {
            this.setState({
                unresolvedIssueSort: sortingArray
                }, () => {
                    this.props.fetchIssues(mapSort(this.state.unresolvedIssueSort), false);
                })
        }
    }

    componentDidMount() {
        this.state.allIssues.forEach(issue => {
            this.context.post(activityUrl, {
                activity: 'SEEN',
                issue: issue.dbIssue
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setState({
                allIssues: (this.props.unresolvedIssue || []).concat(this.props.resolvedIssues || []),
                resolved: this.props.resolvedIssues || [],
                unresolved: this.props.unresolvedIssues || [],
            }, () => {
                    this.state.allIssues.forEach(issue => {
                        this.context.post(activityUrl, {
                            activity: 'SEEN',
                            issue: issue.dbIssue
                        });
                    });
                }
            )
        }
    }

    render() {
        if (this.props.resolvedIssues || this.props.unresolvedIssue) {
            const resolvedIssues = this.props.resolvedIssues || [];
            const unresolvedIssue = this.props.unresolvedIssue || [];
            return (
                <div className="detail-tables">
                    {unresolvedIssue.length !== 0 && 
                    <>
                        <br/>
                        <p className="resolution-text">
                            Active issue: 
                        </p>
                        <p className="remaining-issues">
                            ({unresolvedIssue.length} issue remaining)
                        </p>
                        <div className="unresolved-table">
                            {unresolvedIssue && <DataTableComp
                                tableId="inYardUnresolvedIssue"
                                columns={this.state.columns}
                                orderRule={this.state.sortingRule}
                                data={unresolvedIssue}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                isDetailView={true}
                                columnDefs={this.state.columnDefs}
                                tableHeight="500px"
                                sortRule={this.state.unresolvedIssueSort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            />}
                        </div>
                    </>
                    }
                    {resolvedIssues.length !== 0 && 
                    <>
                        <p className="resolution-text">
                            Past issues:
                        </p>
                        <div className="resolved-table">
                            {resolvedIssues && <DataTableComp
                                tableId="inYardResolvedIssues"
                                columns={this.state.columns}
                                columnDefs={this.state.columnDefs}
                                isDetailView={true}
                                orderRule={this.state.sortingRule}
                                data={resolvedIssues}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                tableHeight="500px"
                                sortRule={this.state.resolvedIssuesSort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            /> }
                        </div>
                    </>
                    } 
                </div>
            );
        } else {
            return null;
        }
    }
}
