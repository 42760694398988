import React, { Component } from 'react';

import { notificationIssueTypeRulesUrl } from 'global/constants';
import AuthorizationService from 'global/services/AuthorizationService';
import { PERMISSION } from 'global/utils/auth';
import Pill from 'components/input/Pill';
import AuthContext from "AuthContext";

import "./NotificationDescription.scss";

class NotificationDescription extends Component {
    static contextType = AuthContext;

    handleNotificationToggle = async (rule, checkedOther, checkedResolved) => {
        try {
            const response = await this.context.put(notificationIssueTypeRulesUrl, {
                ...rule,
                resolved: !checkedResolved,
                other: !checkedOther,
            });
            if (!response || response.status === "error") {
                console.error(response.message);
                return;
            } else {
                this.props.updateIssueTypeRule(response);
            }
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const canWrite = AuthorizationService.canAccess(
            this.context.permissions,
            [PERMISSION.SETTINGS.WRITE, PERMISSION.SETTINGS.NOTIFICATIONS.WRITE]
        );

        return (
            <div className="notification-description">
                {this.props.businessRules &&
                    <div className="row m-0">
                        <span className="rule-title">
                            Business rules
                        </span>
                        <Pill
                            uncheckedGreyColor
                            manualChange
                            checked={this.props.rule && this.props.rule.other}
                            onChange={checked => this.handleNotificationToggle(
                                this.props.rule,
                                checked,
                                !(this.props.rule || {}).resolved
                            )}
                            disabled={!canWrite}
                        />
                    </div>
                }

                {this.props.businessRules && this.props.businessRules.map(rule => (
                    <div className="row rule-description">
                        {rule}
                    </div>
                ))}

                {this.props.resolutionRule &&
                    <>
                        <div style={!this.props.businessRules ? { marginTop: 0 } : null} className="row resolved-title">
                            <span className="rule-title">
                                Issue Resolution Rules
                            </span>
                            <Pill
                                uncheckedGreyColor
                                manualChange
                                checked={this.props.rule && this.props.rule.resolved}
                                onChange={checked => this.handleNotificationToggle(
                                    this.props.rule,
                                    !(this.props.rule || {}).other,
                                    checked
                                )}
                                disabled={!canWrite}
                            />
                        </div>

                        <div className="row rule-description">
                            {this.props.resolutionRule}
                        </div>
                    </>
                }
            </div>
        )
    }
}

export default NotificationDescription;
