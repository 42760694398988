import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { TrailerMap, TrailerMapLegend } from 'components/map/TrailerMap';
import Message from 'components/Message';
import AuthContext from 'AuthContext';

import './TrailerMapPage.scss';

export default class TrailerMapPage extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            message: null,
            messageType: null
        };

        this.onMapLoadingChange = this.onMapLoadingChange.bind(this);
    }

    onMapLoadingChange(loading, available, pageSize) {
        this.setState(prevState => {
            let message = prevState.message;
            let messageType = prevState.messageType;

            if (!loading) {
                if (available === undefined) {
                    message = 'Error while fetching trailer locations';
                    messageType = 'error';
                } else if (available > pageSize) {
                    message = (
                        <span>
                            Showing <b>{ pageSize }</b> out of <b>{ available }</b> trailers in this region.
                        </span>
                    );
                    messageType = 'info';
                } else {
                    message = null;
                }
            }

            return { loading, message, messageType };
        });
    }

    render() {
        return (
            <div className="container-fluid page trailer-map-page">
                <div className="heading-container">
                    <h1 className="page-title">
                        Trailer Map
                    </h1>
                    <Loader type="TailSpin"
                            color="#289AC2"
                            height={ 36 }
                            width={ 36 }
                            visible={ this.state.loading } />
                </div>

                <div className="label-container">
                    { this.state.message && (
                        <Message type={ this.state.messageType }>
                            <div className="text-right">
                                { this.state.message }
                            </div>
                        </Message>
                    ) }

                    <TrailerMapLegend />
                </div>

                <div className="trailer-map-container">
                    <TrailerMap showLegend={ false } onLoadingChange={ this.onMapLoadingChange } />
                </div>
            </div>
        );
    }
}
