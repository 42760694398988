import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import AuthContext from 'AuthContext';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import { SortParam, closedIssuesDetailsUrl } from 'global/constants';
import { formatDate } from 'global/services/DateTimeService';
import { issueTypeMap } from 'global/services/IssueTypeService';
import IssuesTable from '../issues-table/IssuesTable';

class ClosedIssues extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            0: SortParam.ASSIGNMENT,
            1: SortParam.TRAILER,
            2: SortParam.ISSUE_TYPE,
            3: SortParam.CLOSED_BY,
            4: SortParam.CLOSED_AT
        };

        this.columns = [
            { title: 'Order #', data: 'orderId' },
            { title: 'Trailer #', data: 'trailerId' },
            { title: 'Issue Type', data: 'type' },
            { title: 'Closed by', data: 'closedBy' },
            { title: 'Closed At', data: 'closedAt' },
            { title: 'Close Reason', data: 'closeReason' }
        ];

        this.columnDefs = [
            { width: '10%', targets: [0, 1] },
            { width: '15%', targets: [2, 3, 4] },
            { width: '35%', targets: [5] },
            { className: 'dt-align-right', targets: [0, 1] },
            {
                orderable: false,
                targets: [5]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 5,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <div className="no-flex reason-text">
                        { rowData.closeReason }
                    </div>,
                    td
                )
            }
        ];

        this.fetchIssues = this.fetchIssues.bind(this);
    }

    async fetchIssues(sort, filter, currentPage, pageSize) {
        const response = await this.context.get(closedIssuesDetailsUrl, {
            startTimeFrom: filter.startTime.toDate(),
            endTimeTo: filter.endTime.toDate(),
            types: filter.issueTypes,
            teams: filter.teams,
            sort: sort,
            pageNumber: currentPage,
            pageSize: pageSize
        });

        if (response.status === 'error' || !response.data) {
            return;
        }

        const normalizedIssues = response.data.map(obj => ({
            orderId: obj.assignmentBusinessId || 'No order',
            trailerId: obj.trailerBusinessId,
            closedBy: obj.userName,
            closeReason: obj.note ? `${obj.reason} (${ obj.note })` : obj.reason,
            closedAt: formatDate(new Date(obj.closedAt)),
            type: issueTypeMap(obj.issueType),
            onClickState: {
                trailerId: obj.trailerId,
                assignmentId: obj.assignmentId
            }
        }));

        return {
            ...response,
            data: normalizedIssues
        };
    }

    render() {
        return (
            <IssuesTable
                title="Closed issues"
                columns={ this.columns }
                columnDefs={ this.columnDefs }
                columnNames={ this.columnNames }
                fetchIssues={ this.fetchIssues }
            />
        );
    }
}

export default ClosedIssues;
