import React, { Component } from "react";
import AuthContext from 'AuthContext';

import "./InactivityInfoTooltip.scss";

export default class InactivityInfoTooltip extends Component {
    static contextType = AuthContext; 
    constructor(props) {
        super();
        this.state = {
            title: props.title,
            inactivityIssue: props.inactivityIssue,
            company: "",
            location: "",
            loaded: true
        }
    }

    componentDidUpdate(props) {
        if (props !== this.props) {
            this.setState({
                title: this.props.title,
                inactivityIssue: this.props.inactivityIssue
            });
        }
    }

    render() {
        return (
            <div className="inactivity-tooltip">
                <div className="arrow-box">
                    <p className="inactivity-title">
                        Inactive trailer
                    </p>
                    <p className="inline-text">
                      <p className="info-label inline-info-label">
                          Trailer ID:
                      </p>
                      <p className="gathered-info inline-gathered-info">
                          {this.state.inactivityIssue.trailer.businessId}
                      </p>
                    </p>
                    <p className="info-label" style={{marginBottom: ""}}>
                      <svg width="200" height="1" viewBox="0 0 200 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="200" height="1" fill="#E2E5E9"/>
                      </svg>
                    </p>
                    <p className="info-label">
                        Inactive on location:
                    </p>
                    <p className="gathered-info">
                       {this.state.inactivityIssue.metaData.position || "Unknown"}
                    </p>
                    <p className="info-label">
                        Fuel level:
                    </p>
                    <p className="gathered-info last">
                        <span className={`bold ${this.state.inactivityIssue.metaData.fuelLevel && this.state.inactivityIssue.metaData.fuelLevel >= 50 ? "" : "redColorText"}`}>
                          {this.state.inactivityIssue.metaData.fuelLevel || "Unknown"} { this.state.inactivityIssue.metaData.fuelLevel !== undefined && <>%</>}
                        </span>
                    </p>
                </div>
            </div> 
        );
    }

}