import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faPaperPlane, faTrash } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';

import { commentUrl, reportCommentUrl } from 'global/constants';
import { formatDate, getUnalteredDifferenceBetweenDateTime } from 'global/services/DateTimeService';
import AuthContext from 'AuthContext';

import './CommentTooltip.scss';

export default class CommentTooltip extends Component {
    constructor(props) {
        super(props);

        let disabled = props.account ? props.comments.filter(
            (comment) => comment.accountIdentifier === props.account.accountIdentifier
        ).length > 500 : false;

        this.state = {
            comment: "",
            button: "send-button",
            shiftKey: false,
            disabled: disabled,
            deletingId: null
        };

        if (!props.readOnly) {
            this.commentBox = React.createRef();
        }
    }

    componentDidMount() {
        if (this.commentBox) {
            setTimeout(() => {
                this.commentBox.current.focus();
            }, 10);
        }
    }

    componentDidUpdate(props) {
        if (props !== this.props) {
            this.setState({
                comment: "",
                button: "send-button",
                shiftKey: false,
                disabled: this.props.disabled
            });
        }
    }

    handleSend = async () => {
        const comment = this.state.comment;

        if (comment.replace(/\s/g, '').length > 0) {
            const url = this.props.isIssueComment ? commentUrl : reportCommentUrl;
            const newComment = await this.context.post(url, {
                ...this.props.payload,
                text: comment
            });

            this.setState(prevState => ({
                comment: "",
                button: "send-button",
                shiftKey: prevState.shiftKey,
                disabled: prevState.disabled
            }));

            this.props.handler(newComment);
        }
    }

    handleComment = (event) => {
        if (event.key === 'Enter' && !this.state.shiftKey) {
            event.preventDefault();
            if (this.state.comment !== "") {
                this.handleSend();
            }
        } else {
            this.setState({
                comment: event.target.value,
                button: event.target.value.replace(/\s/g, '').length > 0 ? "text-button" : "send-button"
            });
        }
    }

    handleKey = (event) => {
        if (event.key === 'Shift') {
            this.setState({
                shiftKey: !this.state.shiftKey
            });
        }
    }

    handleDelete = async (id) => {
        let deletedComment;
        if (this.props.isIssueComment) {
            deletedComment = await this.context.delete(commentUrl, {
                id: id
            });
        } else {
            deletedComment = await this.context.delete(`${reportCommentUrl}/${id}`);
        }

        this.props.handlerDelete(deletedComment);

        this.setState({
            deletingId: null
        });
    }

    handleDeleteIconClick = (id) => {
        setTimeout(() => {
            this.setState({
                deletingId: id
            });
        }, 0);
    }

    handleCancelDeleteAction = () => {
        setTimeout(() => {
            this.setState({
                deletingId: null
            });
        }, 0);
    }

    comment(comment) {
        return (
            <div className="comment" key={ comment.id }>
                <div className="info-box">
                    <div className="circle">
                        <div className="initials">
                            { comment.userName.split(' ').map(letter => letter[0]).join('') }
                        </div>
                    </div>
                    <div className="text">
                        <p className="name">
                            { comment.userName }
                            { this.props.account && this.props.account.accountIdentifier === comment.userId && (
                                <>
                                    <span className="you">
                                        &nbsp; (You)
                                    </span>
                                    <FontAwesomeIcon
                                        icon={ faTrash }
                                        className="comment-delete-icon"
                                        onClick={ () => this.handleDeleteIconClick(comment.id) }
                                    />
                                </>
                            ) }
                        </p>
                        <p className="time">
                            { formatDate(new Date(comment.time)) }
                        </p>
                    </div>
                </div>
                <p className="comment-text">
                    { comment.text }
                </p>
            </div>
        );
    }

    deletedComment(comment) {
        return (
            <div className="comment" key={ comment.id }>
                <p className="comment-deleted-text">
                    <span className="name">{ comment.userName }</span> deleted their comment.
                </p>
                <p className="comment-deleted-date">
                    ({ getUnalteredDifferenceBetweenDateTime(comment.deletedAt, new Date()) })
                </p>
            </div>
        );
    }

    deleteCommentPrompt(comment) {
        return (
            <div className="comment" key={ comment.id }>
                <p className="delete-prompt">
                    Delete comment?
                </p>
                <p className="delete-confirmation">
                    Are you sure you want to delete your comment? You will permanently delete this comment for all members.
                </p>
                <div className="buttons">
                    <Button
                        variant="light"
                        className="comment-prompt-button"
                        value="Cancel"
                        onClick={ this.handleCancelDeleteAction }
                    >
                        Cancel
                    </Button>
                    <Button
                        as="input"
                        variant="danger"
                        className="comment-prompt-button"
                        type="submit"
                        value="Delete"
                        onClick={ () => this.handleDelete(comment.id) }
                        readOnly
                    />
                </div>
            </div>
        );
    }

    noComments() {
        return (
            <div className="no-comments">
                <FontAwesomeIcon icon={ faComment } className="icon" />
                <p className="heading">
                    No comments yet.
                </p>
                { !this.props.readOnly && (
                    <p className="explanation">
                        Be the first one to comment
                        <br />
                        on this { this.props.isIssueComment ? "issue" : "trailer" }.
                    </p>
                ) }
            </div>
        );
    }

    commentsLoading() {
        return (
            <div className="no-comments">
                <Loader className="icon" type="TailSpin" color="#289AC2" height={ 24 } width={ 24 } />
                <p className="heading">
                    Loading Comments
                </p>
                <p className="explanation">
                    This will only take a moment.
                    <br />
                    Please wait...
                </p>
            </div>
        );
    }

    render() {
        let comments;
        if (this.props.comments.length > 0) {
            comments = this.props.comments.map((comment, index) => {
                let commentBlock;

                if (comment.deletedAt) {
                    commentBlock = this.deletedComment(comment);
                } else {
                    if (this.state.deletingId === comment.id) {
                        commentBlock = this.deleteCommentPrompt(comment);
                    } else {
                        commentBlock = this.comment(comment);
                    }
                }

                if (index !== this.props.comments.length - 1) {
                    commentBlock = (
                        <div key={ comment.id }>
                            { commentBlock }
                            <hr className="divider" />
                        </div>
                    );
                }

                return <React.Fragment key={comment.id}>{commentBlock}</React.Fragment>;
            });
        } else {
            if (!this.props.isIssueComment && this.props.loading) {
                comments = this.commentsLoading();
            } else {
                comments = this.noComments();
            }
        }

        return (
            <div className="comments-tooltip">
                <div className={ `right-arrow-box ${this.props.readOnly ? "read-only" : "" }` }>
                    <p className="heading">
                        Comments for Trailer #{ this.props.data.trailerId }
                    </p>
                    <p className="info-label-heading">
                        { this.props.isIssueComment ? "Issue type:" : "Category:" }
                    </p>
                    <p className="info-label">
                        &nbsp; { this.props.data.type }
                    </p>
                    <hr className="divider"/>
                    { !this.props.readOnly && (
                        <div className="add-comment">
                            <p className="info-label-heading">
                                Add new comment:
                            </p>
                            <textarea
                                value={ this.state.comment }
                                onChange={ this.handleComment }
                                onKeyPress={ this.handleComment }
                                onKeyDown={ this.handleKey }
                                onKeyUp={ this.handleKey }
                                placeholder="Type a comment..."
                                className="comment-box"
                                maxLength="350"
                                disabled={ this.state.disabled || this.props.loading }
                                ref={ this.commentBox }
                                required
                            />
                            <FontAwesomeIcon
                                className={ this.state.button }
                                icon={ faPaperPlane }
                                onClick={ this.handleSend }
                            />
                        </div>
                    ) }
                    <div className="comments">
                        { comments }
                    </div>
                </div>
            </div>
        );
    }
}

CommentTooltip.contextType = AuthContext;
