export default class ArrayUtils {

    static arrayToMatrix(array, columnNumber) {
        if (!array || !Array.isArray(array) || columnNumber <= 0) {
            return array;
        }

        const matrix = [];
        const length = array.length;
        for (let i = 0; i < length; i += columnNumber) {
            matrix.push(array.slice(i, i + columnNumber));
        }

        return matrix;
    }
}
