import React from 'react';
import ReactDOM from 'react-dom';
import Application from 'Application';
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Router><Application/></Router>, document.getElementById('root'));

window.addEventListener( "pageshow", function ( event ) {
  var historyPage = event.persisted || 
  ( typeof window.performance != "undefined" && 
  window.performance.navigation.type === 2 );
  if ( historyPage ) {
  // Handle page restore.
    window.location.reload();
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
