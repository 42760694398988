import { SortParam } from 'global/constants';
import history from "global/history";
import StringUtils from 'global/utils/StringUtils';

var qs = require('qs');

export default class QueryParamService {
    static SORT_QUERY_PARAMS = Object.values(SortParam);
    static defaultOptions = { indices: false, addQueryPrefix: true };

    static addSortToQueryString(queryString, sortingArray) {
        let path = window.location.pathname;
        let currentParams = qs.parse(queryString);

        Object.keys(currentParams).forEach((key) => {
            if (QueryParamService.SORT_QUERY_PARAMS.indexOf(key.toUpperCase()) !== -1) {
                delete currentParams[key];
            }
        })

        sortingArray.forEach((value) => {
            currentParams[value.columnName.toLowerCase()] = value.direction.toLowerCase();
        });
    
        let newQueryString = qs.stringify(currentParams, QueryParamService.defaultOptions);
        window.history.replaceState(
            { path: `${path}${newQueryString}${window.location.hash}` },
            '',
            `${path}${newQueryString}${window.location.hash}`
        );
    }

    static addTeamsToQueryString(queryString, teams) {
        let path = window.location.pathname;

        let currentParams = qs.parse(queryString);
        delete currentParams.team;
        
        currentParams['team'] = teams;

        let newQueryString = qs.stringify(currentParams, QueryParamService.defaultOptions);
        history.replace(`${path}${newQueryString}${window.location.hash}`);
    }

    static addIssueTypesToQueryString(queryString, types) {
        const path = window.location.pathname;

        const currentParams = qs.parse(queryString);
        delete currentParams['issueType'];

        currentParams['issueType'] = types;

        const newQueryString = qs.stringify(currentParams, QueryParamService.defaultOptions);
        history.replace(`${path}${newQueryString}${window.location.hash}`);
    }

    static addActiveLegToQueryString(queryString, relevantIssues) {
        let path = window.location.pathname;

        let currentParams = qs.parse(queryString);
        delete currentParams['relevant'];
        currentParams['relevant'] = relevantIssues;
       

        let newQueryString = qs.stringify(currentParams, QueryParamService.defaultOptions);
        window.history.replaceState(
            { path: `${path}${newQueryString}${window.location.hash}` },
            '', 
            `${path}${newQueryString}${window.location.hash}`
        );
    }

    static addPageToQueryString(queryString, pageNumber) {
        let path = window.location.pathname;

        let currentParams = qs.parse(queryString);
        delete currentParams['page'];
        if (pageNumber !== 1) {
            currentParams['page'] = pageNumber;
        }

        let newQueryString = qs.stringify(currentParams, QueryParamService.defaultOptions);
        window.history.replaceState(
            { path: `${path}${newQueryString}${window.location.hash}` },
            '', 
            `${path}${newQueryString}${window.location.hash}`
        );
    }

    static parseSortingQueryString(queryString) {
        const filterParams = [];
        const queryMap = qs.parse(queryString);

        Object.keys(queryMap).forEach((key) => {
            if (QueryParamService.SORT_QUERY_PARAMS.indexOf(key.toUpperCase()) !== -1) {
                filterParams.push(key.toUpperCase() + "," + queryMap[key].toUpperCase());
            }
        });
        return filterParams;
    }

    static parseSimpleValue(key) {
        return QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), key);
    }

    static parseSimpleValueFromQueryString(queryString, key) {
        return qs.parse(queryString)[key];
    }

    static addParamsToUrl(params) {
        const urlSearchParams = new URLSearchParams({
            ...qs.parse(window.location.search, { ignoreQueryPrefix: true }),
            ...params
        });

        for (const key in params) {
            if (StringUtils.isBlank(params[key])) {
                urlSearchParams.delete(key);
            }
        }

        history.push(window.location.pathname + "?" + urlSearchParams.toString());
    }

    static deleteParam(param) {
        const queryParamsString = window.location.search.slice(1);
        const queryParams = qs.parse(queryParamsString);
        delete queryParams[param];

        const newQueryString = qs.stringify(queryParams, QueryParamService.defaultOptions);
        const path = window.location.pathname;

        window.history.replaceState(
            { path: `${path}${newQueryString}${window.location.hash}` },
            '',
            `${path}${newQueryString}${window.location.hash}`
        );
    }
}
