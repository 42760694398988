import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CanAccess from 'components/can-access/CanAccess';
import WithNavigationPage from 'components/WithNavigationPage';
import { CanAccessSwitch, AccessCase } from 'components/can-access/CanAccessSwitch';
import { TRAILER_REPORT_CATEGORY } from 'global/constants';
import { PERMISSION } from 'global/utils/auth';
import ObjectUtils from 'global/utils/ObjectUtils';
import UnreadContextProvider from 'UnreadContextProvider';
import AuthContext from 'AuthContext';

import Dashboard from 'pages/dashboard/Dashboard';
import FleetOrders from 'pages/fleet/FleetOrders';
import Fleet from 'pages/fleet/Fleet';
import ModeIssues from 'pages/issues/ModeIssues';
import Events from 'pages/events/Events';
import SilentUnitIssues from 'pages/issues/SilentUnitIssues';
import TemperatureIssues from 'pages/issues/TemperatureIssues';
import MissingTemperature from 'pages/issues/MissingTemperature';
import DetachedMissingTrailers from 'pages/issues/DetachedMissingTrailers';
import MismatchIssues from 'pages/issues/MismatchIssues';
import ClaimIssues from 'pages/issues/ClaimIssues';
import DetailView from 'pages/trailer/DetailView';
import AssignmentDetailView from 'pages/assignment/DetailView'
import PowerIssues from 'pages/issues/PowerIssues';
import DetainedTrailers from 'pages/issues/DetainedTrailers';
import InactiveTrailers from 'pages/issues/InactiveTrailers';
import LowFuelLevel from 'pages/issues/LowFuelLevel';
import AlarmIssues from 'pages/issues/AlarmIssues';
import InactiveInYard from 'pages/issues/InactiveInYard'
import AllTrailers from 'pages/all-trailers/AllTrailers';
import Settings from 'pages/settings/Settings';
import NotFoundPage from 'pages/not-found/NotFoundPage';
import NotAuthorized from 'pages/not-authorized/NotAuthorized';
import EditReleaseNotes from 'pages/release-notes/EditReleaseNotes';
import AllTeamsStats from 'pages/statistics/all-teams/AllTeamsStats';
import TeamStats from 'pages/statistics/team/TeamStats';
import UserStats from 'pages/statistics/user/UserStats';
import SnoozedIssues from 'pages/statistics/snoozed-issues/SnoozedIssues';
import ClosedIssues from 'pages/statistics/closed-issues/ClosedIssues';
import StolenProcedure from "pages/stolen-procedure/StolenProcedure";
import ReleaseNotes from 'pages/release-notes/ReleaseNotes';
import TrailerAudit from "pages/trailer-audit/TrailerAudit";
import TrailerTable from 'pages/trailer-audit/TrailerTable';
import TrailerAuditHistory from 'pages/trailer-audit/TrailerAuditHistory';
import FleetTrailerList from 'pages/trailer-audit/FleetTrailerList';
import TrailerWithClaimTable from 'pages/trailer-audit/TrailerWithClaimTable';
import SilentTrailerTable from 'pages/trailer-audit/SilentTrailerTable';
import InactiveTrailerTable from 'pages/trailer-audit/InactiveTrailerTable';
import MismatchTrailerTable from 'pages/trailer-audit/MismatchTrailerTable';
import HelpCenter from 'pages/help/HelpCenter'
import TrailerMapPage from 'pages/trailer-map/TrailerMapPage';

export default class Router extends Component {
    static contextType = AuthContext;

    render() {
        return (
            <UnreadContextProvider>
                <Switch>
                    <Route exact path="/">
                        { /* Redirect root to the appropriate destination, based on user permissions */ }
                        <CanAccessSwitch>
                            <AccessCase
                                action={ [
                                    PERMISSION.ISSUE.READ,
                                    PERMISSION.ISSUE.POWER_STATE.READ,
                                    PERMISSION.ISSUE.TEMPERATURE.READ,
                                    PERMISSION.ISSUE.UNIT_MODE.READ,
                                    PERMISSION.ISSUE.DATA_ENTRY_ERROR.READ,
                                    PERMISSION.ISSUE.SILENT.READ,
                                    PERMISSION.ISSUE.LOW_FUEL_ALARM.READ,
                                    PERMISSION.ISSUE.DETENTION.READ,
                                    PERMISSION.ISSUE.INACTIVITY.READ,
                                    PERMISSION.ISSUE.MISSING.READ,
                                    PERMISSION.ISSUE.DETACHED.READ,
                                    PERMISSION.ISSUE.LOCATION_MISMATCH.READ,
                                    PERMISSION.ISSUE.CLAIM.READ
                                ] }
                            >
                                <Redirect to="/dashboard" />
                            </AccessCase>

                            <AccessCase action={ PERMISSION.PAGE.ALL_ISSUES }>
                                <Redirect to="/issues/all" />
                            </AccessCase>

                            <AccessCase
                                action={ [
                                    PERMISSION.ISSUE.READ,
                                    PERMISSION.ISSUE.ALARM.READ,
                                    PERMISSION.ISSUE.LOW_FUEL_ALARM.LOCAL.READ
                                ] }
                            >
                                <Redirect to="/issues/alarm" />
                            </AccessCase>

                            <AccessCase action={ PERMISSION.STATISTICS.READ }>
                                <Redirect to="/statistics/all-teams" />
                            </AccessCase>

                            <AccessCase action={ ObjectUtils.getValuesByKey(PERMISSION.TRAILER_AUDIT, "READ") }>
                                <Redirect to="/trailer-audit" />
                            </AccessCase>

                            <AccessCase action={ PERMISSION.EVENT.READ }>
                                <Redirect to="/events" />
                            </AccessCase>

                            <AccessCase action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.INACTIVE_IN_YARD.READ] }>
                                <Redirect to="/issues/inactive-in-yard" />
                            </AccessCase>

                            <AccessCase action={ PERMISSION.PAGE.HELP }>
                                <Redirect to="/help" />
                            </AccessCase>

                            <AccessCase action={ PERMISSION.RELEASE_NOTES.READ }>
                                <Redirect to="/release-notes" />
                            </AccessCase>

                            <AccessCase action={ ObjectUtils.getValuesByKey(PERMISSION.SETTINGS, "READ") }>
                                <Redirect to="/settings" />
                            </AccessCase>

                            <AccessCase>
                                <WithNavigationPage>
                                    <NotAuthorized />
                                </WithNavigationPage>
                            </AccessCase>
                        </CanAccessSwitch>
                    </Route>

                    <Route exact path="/dashboard">
                        <CanAccess
                            action={ [
                                PERMISSION.ISSUE.READ,
                                PERMISSION.ISSUE.POWER_STATE.READ,
                                PERMISSION.ISSUE.TEMPERATURE.READ,
                                PERMISSION.ISSUE.UNIT_MODE.READ,
                                PERMISSION.ISSUE.DATA_ENTRY_ERROR.READ,
                                PERMISSION.ISSUE.SILENT.READ,
                                PERMISSION.ISSUE.LOW_FUEL_ALARM.READ,
                                PERMISSION.ISSUE.DETENTION.READ,
                                PERMISSION.ISSUE.INACTIVITY.READ,
                                PERMISSION.ISSUE.MISSING.READ,
                                PERMISSION.ISSUE.DETACHED.READ,
                                PERMISSION.ISSUE.LOCATION_MISMATCH.READ,
                                PERMISSION.ISSUE.CLAIM.READ
                            ] }
                            yes={
                                <WithNavigationPage>
                                    <Dashboard team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/power">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.POWER_STATE.READ] }
                            yes={
                                <WithNavigationPage>
                                    <PowerIssues team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/temperature">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.TEMPERATURE.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TemperatureIssues team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/mode">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.UNIT_MODE.READ] }
                            yes={
                                <WithNavigationPage>
                                    <ModeIssues team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/temperature-missing">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.DATA_ENTRY_ERROR.READ] }
                            yes={
                                <WithNavigationPage>
                                    <MissingTemperature team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/silent">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.SILENT.READ] }
                            yes={
                                <WithNavigationPage>
                                    <SilentUnitIssues team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/low-fuel">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.LOW_FUEL_ALARM.READ] }
                            yes={
                                <WithNavigationPage>
                                    <LowFuelLevel team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/detention">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.DETENTION.READ] }
                            yes={
                                <WithNavigationPage>
                                    <DetainedTrailers team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/inactivity">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.INACTIVITY.READ] }
                            yes={
                                <WithNavigationPage>
                                    <InactiveTrailers team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/missing">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.MISSING.READ] }
                            yes={
                                <WithNavigationPage>
                                    <DetachedMissingTrailers
                                        detached={ false }
                                        team={ this.context.team }
                                        account={ this.context.account }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/detached">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.DETACHED.READ] }
                            yes={
                                <WithNavigationPage>
                                    <DetachedMissingTrailers
                                        detached={ true }
                                        team={ this.context.team }
                                        account={ this.context.account }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/inactive-in-yard">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.INACTIVE_IN_YARD.READ] }
                            yes={
                                <WithNavigationPage>
                                    <InactiveInYard team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/mismatched">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.LOCATION_MISMATCH.READ] }
                            yes={
                                <WithNavigationPage>
                                    <MismatchIssues team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/claim">
                        <CanAccess
                            action={ [PERMISSION.ISSUE.READ, PERMISSION.ISSUE.CLAIM.READ] }
                            yes={
                                <WithNavigationPage>
                                    <ClaimIssues team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/orders/search">
                        <CanAccess
                            action={ [
                                PERMISSION.ISSUE.ALARM.SEARCH,
                                PERMISSION.ASSIGNMENT.READ,
                                PERMISSION.TRAILER.READ
                            ] }
                            yes={
                                <WithNavigationPage>
                                    <FleetOrders team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/all">
                        <CanAccess
                            action={ PERMISSION.PAGE.ALL_ISSUES }
                            yes={
                                <WithNavigationPage>
                                    <Fleet team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailers">
                        <CanAccess
                            action={ PERMISSION.TRAILER.READ }
                            yes={
                                <WithNavigationPage>
                                    <AllTrailers />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/issues/alarm">
                        <CanAccess
                            action={ [
                                PERMISSION.ISSUE.READ,
                                PERMISSION.ISSUE.ALARM.READ,
                                PERMISSION.ISSUE.LOW_FUEL_ALARM.LOCAL.READ
                            ] }
                            yes={
                                <WithNavigationPage>
                                    <AlarmIssues team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/statistics/all-teams">
                        <CanAccess
                            action={ PERMISSION.STATISTICS.READ }
                            yes={
                                <WithNavigationPage>
                                    <AllTeamsStats account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/statistics/team/:team">
                        <CanAccess
                            action={ PERMISSION.STATISTICS.READ }
                            yes={
                                <WithNavigationPage>
                                    <TeamStats account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/statistics/user/:user">
                        <CanAccess
                            action={ PERMISSION.STATISTICS.READ }
                            yes={
                                <WithNavigationPage>
                                    <UserStats account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/statistics/snoozed">
                        <CanAccess 
                            action={ PERMISSION.STATISTICS.READ }
                            yes={
                                <WithNavigationPage>
                                    <SnoozedIssues team={ this.context.team } />
                                </WithNavigationPage>
                            }
                        />
                    </Route>

                    <Route exact path="/statistics/closed">
                        <CanAccess
                            action={ PERMISSION.STATISTICS.READ }
                            yes={
                                <WithNavigationPage>
                                    <ClosedIssues team={ this.context.team } />
                                </WithNavigationPage>
                            }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/history">
                        <CanAccess
                            action={ ObjectUtils.getValuesByKey(PERMISSION.TRAILER_AUDIT, "READ") }
                            yes={
                                <WithNavigationPage>
                                    <TrailerAuditHistory />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id?">
                        <CanAccess
                            action={ ObjectUtils.getValuesByKey(PERMISSION.TRAILER_AUDIT, "READ") }
                            yes={
                                <WithNavigationPage>
                                    <TrailerAudit />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/total">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.TOTAL.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TrailerTable
                                        all
                                        title="Total Trailers"
                                        account={ this.context.account }
                                        category={ TRAILER_REPORT_CATEGORY.TOTAL }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/dispatch">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.DISPATCH.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TrailerTable
                                        dispatch
                                        title="Trailers Under Dispatch"
                                        account={ this.context.account }
                                        category={ TRAILER_REPORT_CATEGORY.DISPATCH }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/home-time">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.HOME_TIME.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TrailerTable
                                        homeTime
                                        title="Trailers with Drivers on Home Time"
                                        account={ this.context.account }
                                        category={ TRAILER_REPORT_CATEGORY.HOME_TIME }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/yard">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.YARD.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TrailerTable
                                        atYard
                                        title="Trailers at the Yard"
                                        account={ this.context.account }
                                        category={ TRAILER_REPORT_CATEGORY.AT_YARD }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/drop-yard">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.DROP_YARD.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TrailerTable
                                        atDropYard
                                        title="Trailers in Drop Yards"
                                        account={ this.context.account }
                                        category={ TRAILER_REPORT_CATEGORY.AT_DROP_YARD }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/drop-yard/:yardId">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.DROP_YARD.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TrailerTable
                                        atDropYard
                                        title="Trailers in Drop Yards"
                                        account={ this.context.account }
                                        category={ TRAILER_REPORT_CATEGORY.AT_DROP_YARD }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/silent">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.SILENT.READ] }
                            yes={
                                <WithNavigationPage>
                                    <SilentTrailerTable team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/inactive">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.INACTIVE.READ] }
                            yes={
                                <WithNavigationPage>
                                    <InactiveTrailerTable team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/claim">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.CLAIM.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TrailerWithClaimTable
                                        team={ this.context.team }
                                        account={ this.context.account }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/repair">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.REPAIR.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TrailerTable
                                        repair
                                        title="TMW Repair Expirations"
                                        account={ this.context.account }
                                        category={ TRAILER_REPORT_CATEGORY.REPAIR }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/mismatch">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.MISMATCH.READ] }
                            yes={
                                <WithNavigationPage>
                                    <MismatchTrailerTable team={ this.context.team } account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/oneway">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.ONEWAY.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TrailerTable
                                        oneway
                                        title="Oneway Trailers"
                                        account={ this.context.account }
                                        category={ TRAILER_REPORT_CATEGORY.ONEWAY }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/unaccounted">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.UNACCOUNTED.READ] }
                            yes={
                                <WithNavigationPage>
                                    <TrailerTable
                                        unaccounted
                                        title="Unaccounted Trailers"
                                        account={ this.context.account }
                                        category={ TRAILER_REPORT_CATEGORY.UNACCOUNTED }
                                    />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-audit/:id/fleet/:fleetId">
                        <CanAccess
                            action={ [PERMISSION.TRAILER_AUDIT.READ, PERMISSION.TRAILER_AUDIT.FLEET.READ] }
                            yes={
                                <WithNavigationPage>
                                    <FleetTrailerList />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/events">
                        <CanAccess
                            action={ PERMISSION.EVENT.READ }
                            yes={
                                <WithNavigationPage>
                                    <Events />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/trailer-map">
                        <CanAccess
                            action={ PERMISSION.PAGE.TRAILER_MAP }
                            yes={
                                <WithNavigationPage>
                                    <TrailerMapPage />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/release-notes">
                        <CanAccess
                            action={ PERMISSION.RELEASE_NOTES.READ }
                            yes={
                                <WithNavigationPage>
                                    <ReleaseNotes />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/release-notes/edit/:id?">
                        <CanAccess
                            action={ PERMISSION.RELEASE_NOTES.WRITE }
                            yes={
                                <WithNavigationPage>
                                    <EditReleaseNotes />
                                </WithNavigationPage>
                            }
                            no={ <Redirect to="/release-notes" /> }
                        />
                    </Route>

                    <Route exact path="/settings">
                        <CanAccessSwitch>
                            <AccessCase action={ [PERMISSION.SETTINGS.READ, PERMISSION.SETTINGS.NOTIFICATIONS.READ] }>
                                <Redirect to="/settings/notifications" />
                            </AccessCase>

                            <AccessCase action={ PERMISSION.SETTINGS.CLOSE_REASONS.READ }>
                                <Redirect to="/settings/close-reasons" />
                            </AccessCase>

                            <AccessCase action={ PERMISSION.SETTINGS.SNOOZE_REASONS.READ }>
                                <Redirect to="/settings/snooze-reasons" />
                            </AccessCase>

                            <AccessCase action={ PERMISSION.SETTINGS.DROP_YARDS.READ }>
                                <Redirect to="/settings/drop-yards" />
                            </AccessCase>

                            <AccessCase>
                                <NotFoundPage />
                            </AccessCase>
                        </CanAccessSwitch>
                    </Route>

                    <Route exact path="/settings/notifications">
                        <CanAccess
                            action={ [PERMISSION.SETTINGS.READ, PERMISSION.SETTINGS.NOTIFICATIONS.READ] }
                            yes={
                                <WithNavigationPage>
                                    <Settings tab="notifications" />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/settings/close-reasons">
                        <CanAccess
                            action={ [PERMISSION.SETTINGS.READ, PERMISSION.SETTINGS.CLOSE_REASONS.READ] }
                            yes={
                                <WithNavigationPage>
                                    <Settings tab="close-reasons" />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/settings/snooze-reasons">
                        <CanAccess
                            action={ [PERMISSION.SETTINGS.READ, PERMISSION.SETTINGS.SNOOZE_REASONS.READ] }
                            yes={
                                <WithNavigationPage>
                                    <Settings tab="snooze-reasons" />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/settings/drop-yards">
                        <CanAccess
                            action={ [PERMISSION.SETTINGS.READ, PERMISSION.SETTINGS.DROP_YARDS.READ] }
                            yes={
                                <WithNavigationPage>
                                    <Settings tab="drop-yards" />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/details/:trailerId/:assignmentId?">
                        <CanAccess
                            action={ PERMISSION.TRAILER.READ }
                            yes={
                                <WithNavigationPage>
                                    <DetailView account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/assignment-details/:assignmentId">
                        <CanAccess
                            action={ PERMISSION.ASSIGNMENT.READ }
                            yes={
                                <WithNavigationPage>
                                    <AssignmentDetailView account={ this.context.account } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> } />
                    </Route>

                    <Route exact path="/tutorials/issues/missing">
                        <CanAccess
                            action={ PERMISSION.PAGE.STOLEN_PROCEDURE }
                            yes={
                                <WithNavigationPage>
                                    <StolenProcedure detached={ false } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/tutorials/issues/detached">
                        <CanAccess
                            action={ PERMISSION.PAGE.STOLEN_PROCEDURE }
                            yes={
                                <WithNavigationPage>
                                    <StolenProcedure detached={ true } />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/help">
                        <CanAccessSwitch>
                            <AccessCase action={ PERMISSION.PAGE.HELP }>
                                <Redirect to="/help/fleet-overview" />
                            </AccessCase>

                            <AccessCase>
                                <NotFoundPage />
                            </AccessCase>
                        </CanAccessSwitch>
                    </Route>

                    <Route exact path="/help/fleet-overview">
                        <CanAccess
                            action={ PERMISSION.PAGE.HELP }
                            yes={
                                <WithNavigationPage>
                                    <HelpCenter tab="fleet-overview" />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route exact path="/help/trailer-audit">
                        <CanAccess
                            action={ PERMISSION.PAGE.HELP }
                            yes={
                                <WithNavigationPage>
                                    <HelpCenter tab="trailer-audit" />
                                </WithNavigationPage>
                            }
                            no={ <NotFoundPage /> }
                        />
                    </Route>

                    <Route>
                        <NotFoundPage />
                    </Route>
                </Switch>
            </UnreadContextProvider>
        );
    }
}
