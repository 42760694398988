export default class StringUtils {

    static concatNotBlank(parts, delimiter) {
        return parts.filter(part => !StringUtils.isBlank(part)).join(delimiter);
    }

    static isBlank(field) {
        return !field || field.trim() === '';
    }

    static snakeCaseToCapitalized(string) {
        if (!string) {
            return string;
        }

        return string
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .reduce((prev, current) => prev + ' ' + current);
    }

    static pluralize(amount, word, suffix = 's') {
        return amount === 1 ? word : word + suffix;
    }

    static concatSentence(words, lastSeparator = 'and') {
        return words.reduce((rest, nextWord, index) => {
            if (index === 0) {
                return `${ nextWord }`;
            } else if (index === words.length - 1) {
                return `${ rest } ${ lastSeparator } ${ nextWord }`;
            } else {
                return `${ rest }, ${ nextWord }`;
            }
        }, '');
    }
}
