export default class AuthorizationService {
    static canAccess(permissions, action, data) {
        const actions = Array.isArray(action) ? action : [action];

        let allowed = permissions && permissions.some(p => actions.indexOf(p) !== -1);

        if (!allowed) {
            for (const actionItem of actions) {
                const dynamicRule = AuthorizationService.dynamicRules[actionItem];

                if (dynamicRule) {
                    allowed = dynamicRule(data);

                    if (allowed) {
                        break;
                    }
                }
            }
        }

        return allowed;
    }

    static dynamicRules = {
        // if needed, this is how we dynamically authorize permissions for actions

        "issue:alarm:comment:delete": ({ userId, postOwnerId }) => {
            if (!userId || !postOwnerId) {
                return false;
            }

            return userId === postOwnerId;
        }
    }
}
