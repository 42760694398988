import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faComment,
    faExclamationCircle,
    faExclamationTriangle,
    faEye,
    faInfoCircle,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { faAlarmSnooze } from '@fortawesome/pro-solid-svg-icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Loader from 'react-loader-spinner';

import AuthContext from 'AuthContext';
import { reactPlugin } from 'AppInsights';
import DataTableComp from 'components/datatable/DataTableComp';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import TeamIcon from "components/team-icon/TeamIcon";
import ActiveSelection from "components/input/ActiveSelection";
import CanAccess from "components/can-access/CanAccess";
import { defaultGridRecordsNumber, issuesDetailsUrl, mapSort, SortParam } from 'global/constants';
import { issueSnoozeable, issueTypes, getIssuePermissionsForAction } from "global/services/IssueTypeService";
import QueryParamService from "global/services/QueryParamService";
import { getCurrentDate, getDifferenceBetweenDates } from "global/services/DateTimeService";
import { mapIssuesFromResponse } from "global/services/IssueApiService";
import deleteComment from 'global/utils/deleteComment';
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import { PERMISSION } from 'global/utils/auth';
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import './Fleet.scss';

const $ = require("jquery");

const CACHE = {};

class Fleet extends Component {
    static contextType = AuthContext;
    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            0: SortParam.ASSIGNMENT,
            1: SortParam.TRAILER,
            2: SortParam.COMPANY,
            3: SortParam.DRIVER,
            5: SortParam.ISSUE_TYPE,
            7: SortParam.START_TIME,
            14: SortParam.ISSUE_STATE
        }

        this.columns = [{
            title: "Order #",
            data: "orderId"
        }, {
            title: "Trailer #",
            data: "trailerId"
        }, {
            title: "Customer ID",
            data: "customerId"
        }, {
            title: "Driver",
            data: "driver"
        }, {
            title: "Status",
            data: "status"
        }, {
            title: "Issue type",
            data: "type"
        }, {
            title: "Last known position",
            data: "address"
        }, {
            title: "Created",
            data: "created"
        }, {
            title: '<span data-toggle="tooltip" title="Minimum temperature allowed">Min. temp</span>',
            data: "minTemp"
        }, {
            title: '<span data-toggle="tooltip" title="Temperature reading from ThermoKing">Set point</span>',
            data: "setpoint"
        }, {
            title: '<span data-toggle="tooltip" title="Temperature reading from ThermoKing">Return Air</span>',
            data: "returnAir"
        }, {
            title: '<span data-toggle="tooltip" title="Maximum temperature allowed">Max. temp</span>',
            data: "maxTemp"
        }, {
            title: '<span data-toggle="tooltip" title="Mode configuration in TMW">Mode</span>',
            data: "modeC"
        }, {
            title: '<span data-toggle="tooltip" title="Mode reading from ThermoKing">Mode R</span>',
            data: "modeR"
        }, {
            title: "Alerts",
            data: "notifications"
        }, {
            title: "Resolved?",
            data: "resolved"
        }, {
            title: "Action",
            data: "action"
        }];

        this.sortingRule = [];
        this.data = Array.from([]);

        this.columnDefs = [
            { width: "5%", "targets": [0, 1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 15] },
            { width: "10%", "targets": [5, 14] },
            { width: "15%", "targets": [6] },
            { width: 70, "targets": [16] },
            { className: "dt-align-center", targets: [16] },
            { className: "dt-align-left", targets: [2, 3, 4, 5, 6, 7, 12, 13, 14, 15] },
            { className: "dt-align-right", targets: [0, 1, 8, 9, 10, 11] },
            {
                orderable: false,
                targets: [4, 6, 8, 9, 10, 11, 12, 13, 14, 15, 16]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="centerAlign" style={{ marginRight: "2%" }}>
                            <TeamIcon color={rowData.division} />
                            &nbsp;&nbsp;
                            <span className="no-flex">{ rowData.driver }</span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let text = "";
                    if (rowData.type === issueTypes.SILENT && !rowData.resolved) {
                        text = "Silent";
                        className += "bold orangeColorText"
                    } else if (rowData.detained) {
                        text = "Detained \xa0";
                        className += "bold redColorText";
                    } else if (rowData.inactive) {
                        text = "Inactive \xa0";
                        className += "bold redColorText";
                    } else if (rowData.status === null) {
                        text = "Unknown"
                        if (!rowData.resolved && rowData.type !== issueTypes.DATA_ENTRY_ERROR) {
                            className += "bold redColorText"
                        }
                    } else if (rowData.status === true) {
                        text = "Moving"
                    } else {
                        text = "Stopped"
                        className += "bold orangeColorText"
                    }

                    ReactDOM.render(
                        <div className={ className }>
                            {text}
                            {!rowData.resolved && (rowData.type === issueTypes.SILENT) && <><span>&nbsp;&nbsp;</span><FontAwesomeIcon className="orangeColorText" icon={faExclamationTriangle} /> </>}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = 'no-flex';

                    if (!rowData.resolved && rowData.type === issueTypes.POWER_STATE) {
                        className += ' bold redColorText';
                    }

                    ReactDOM.render(
                        <div className={ className }>
                            {rowData.type}
                            {!rowData.resolved && rowData.type === issueTypes.POWER_STATE && <><span>&nbsp;&nbsp;</span><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /></>}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex" style={{ minWidth: '40px' }}>
                            {rowData.address}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData) => {
                    const [ date, time ] = rowData.created.split(',');
                    ReactDOM.render(
                        <div>
                            { date }
                            <br />
                            { time }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let minTemp = rowData.minTemp;
                    if (rowData.minTemp === null || rowData.minTemp === undefined || rowData.minTemp === "Unknown") {
                        minTemp = "NO DATA"
                    } else if (!isFinite(rowData.minTemp)) {
                        minTemp = 999
                    }
                    const minTempIssue = !rowData.resolved && ((rowData.type === issueTypes.DATA_ENTRY_ERROR && rowData.metaData.validTemperature !== 'true') || (rowData.type === issueTypes.TEMPERATURE && rowData.setpoint < minTemp));

                    if (minTempIssue) {
                        className = "bold redColorText";
                    }

                    ReactDOM.render(
                        <div className={className}>
                            {minTempIssue && <><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /> <span>&nbsp;&nbsp;</span></>}
                            {minTemp}
                            {minTemp !== "NO DATA" && minTemp !== 999 && <span>&deg;F</span>}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";

                    const tempIssue = !rowData.resolved && rowData.type === issueTypes.TEMPERATURE && (rowData.metaData || {}).setPointIssue === 'true';
                    if (tempIssue) {
                        className = "bold redColorText";
                    }

                    const hasData = (rowData.setpoint || rowData.setpoint === 0) && rowData.setpoint !== "NO DATA";

                    ReactDOM.render(
                        <div className={className}>
                            { tempIssue && <><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /><span>&nbsp;&nbsp;</span></> }
                            { hasData ? <>{Number.parseFloat(rowData.setpoint).toFixed(0)}<span>&deg;F</span></> : 'NO DATA' }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 10,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";

                    const tempIssue = !rowData.resolved && rowData.type === issueTypes.TEMPERATURE && (rowData.metaData || {}).returnAirIssue === 'true';
                    if (tempIssue) {
                        className = "bold redColorText";
                    }

                    const hasData = (rowData.returnAir || rowData.returnAir === 0) && rowData.returnAir !== "NO DATA";

                    ReactDOM.render(
                        <div className={className}>
                            {tempIssue && <><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /><span>&nbsp;&nbsp;</span></>}
                            {hasData ? <>{Number.parseFloat(rowData.returnAir).toFixed(0)}<span>&deg;F</span></> : 'NO DATA'}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 11,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let maxTemp = rowData.maxTemp;
                    if (rowData.maxTemp === null || rowData.maxTemp === undefined || rowData.maxTemp === "Unknown") {
                        maxTemp = "NO DATA";
                    } else if (!isFinite(rowData.maxTemp)) {
                        maxTemp = 999
                    }

                    const maxTempIssue = !rowData.resolved && ((rowData.type === issueTypes.DATA_ENTRY_ERROR && rowData.metaData.validTemperature !== 'true') || (rowData.type === issueTypes.TEMPERATURE && rowData.setpoint > maxTemp));
                    if (maxTempIssue) {
                        className = "bold redColorText";
                    }

                    ReactDOM.render(
                        <div className={className}>
                            {maxTempIssue && <><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /> <span>&nbsp;&nbsp;</span></>}
                            {maxTemp}
                            {maxTemp !== "NO DATA" && maxTemp !== 999 && <span>&deg;F</span>}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 12,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    const modeIssue = !rowData.resolved &&
                        (rowData.type === issueTypes.UNIT_MODE ||
                            (rowData.type === issueTypes.DATA_ENTRY_ERROR && rowData.metaData.validUnitMode === 'false'));
                    if (modeIssue) {
                        className = "bold redColorText";
                    }

                    ReactDOM.render(
                        <div className={className}>
                            {modeIssue && <><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /> <span>&nbsp;&nbsp;</span></>}
                            {rowData.modeC}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 13,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    const modeIssue = !rowData.resolved && rowData.type === issueTypes.UNIT_MODE;
                    if (modeIssue) {
                        className = "bold redColorText";
                    }

                    ReactDOM.render(
                        <div className={className}>
                            {modeIssue && <><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /> <span>&nbsp;&nbsp;</span></>}
                            {rowData.modeR ? rowData.modeR : "NO DATA"}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 14,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "greenColorText bold";
                    let coloredText = "";
                    let otherText = "";

                    if (!rowData.alerts) {
                        coloredText = "No";
                        className = "redColorText bold";
                        otherText = `  (for ${getDifferenceBetweenDates(rowData.beginning, getCurrentDate())})`
                    } else if (rowData.alerts === "Escalation") {
                        coloredText = "Escalation"
                        className = "bold"
                    } else if (rowData.alerts === "Warning") {
                        coloredText = "Warning";
                        className = "bold"
                    }

                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }

                    ReactDOM.render(
                        <div className="notifications">
                            <div className={`notification-level ` + className}>
                                {coloredText}
                            </div>
                            {
                                <div className="notification-info" style={coloredText === "No" ? { display: "none" } : {}}>
                                    &nbsp;&nbsp;<FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
                                </div>
                            }
                            {
                                otherText !== "" &&
                                <div className="greyColorText italic snoozed-info">
                                    <br /> {otherText}
                                </div>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 15,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";

                    if (!rowData.resolved) {
                        className += "bold redColorText";
                        coloredText = "No";
                        otherText += ` (for ${getDifferenceBetweenDates(rowData.beginning, getCurrentDate())})`;
                    } else if (rowData.resolved) {
                        className += "greenColorText bold";
                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.beginning, rowData.resolved)})`;
                        }
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <div className={className}>
                                {rowData.resolved ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faExclamationCircle} />}
                                &nbsp;&nbsp;
                                {coloredText}&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.resolved ? { display: "none" } : { marginRight: 4 }} />
                            </div>
                            <div className="greyColorText italic">{otherText}</div>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 16,
                className: "justify",
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass = "";
                    let snoozeIconClass = "";
                    if (issueSnoozeable(rowData.issue) === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeable(rowData.issue) === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon"
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;

                    ReactDOM.render(
                        <div className="flex-always">
                            <div className="seen" id={rowData.id}>
                                <FontAwesomeIcon icon={faEye} className={seenIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={getIssuePermissionsForAction(rowData.issue, "snooze")}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={snoozeDivClass}>
                                            {snoozeIconClass === "disabled-icon" ? <svg width="16" height="12" style={{ marginTop: "2px" }} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                    <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z" />
                                                    <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z" />
                                                    <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z" />
                                                    <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z" />
                                                    <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z" />
                                                    <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z" />
                                                </svg>
                                                : <FontAwesomeIcon icon={faAlarmSnooze} className={snoozeIconClass} />}
                                        </div>
                                    </>
                                }
                            />
                            &nbsp; &nbsp;
                            <div className="comment">
                                <FontAwesomeIcon icon={faComment} className={commentIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={getIssuePermissionsForAction(rowData.issue, "close")}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={isRelevant ? "resolve" : "disabled-resolve"}>
                                            <FontAwesomeIcon icon={faTimesCircle} className={isRelevant ? "resolve-icon" : "disabled-icon"} />
                                        </div>
                                    </>
                                }
                            />
                            <CanAccess
                                permissions={ context.permissions } 
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <ReeferControlSvg />
                                    </> 
                                }
                            />
                        </div>,
                        td
                    );
                }
            },
        ];

        let relevantIssues = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : undefined;

        let buttonNode;
        if (!!relevantIssues || relevantIssues === undefined) {
            relevantIssues = true;
            buttonNode = $(`button[id=relevant]`);
        } else {
            relevantIssues = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addActiveLegToQueryString(window.location.search.slice(1), relevantIssues);

        let currentPage = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page') ? parseInt(QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page')) : 1;

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        this.state = {
            issuesLoaded: false,
            dataUpdated: false,
            page: currentPage,
            activeLeg: relevantIssues,
            sort: [],
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            },
            account: this.props.account
        }

        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
    }

    async componentDidMount() {
        await this.fetchAllIssues();
    }

    componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;
        
        if (oldTeam !== newTeam) {
            this.handlePage(1, true);
        }
    }

    async handlePage(page, force) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchAllIssues(force);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;

        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchAllIssues();
            }, 1000);
        }

        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    onReceiveNewComment(comment) {
        let newData = this.state.data;
        newData.find((data) => data.issue.id === comment.issue.id).comments.unshift(comment);
        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        let newData = this.state.data;

        this.setState({
            allIssues: deleteComment(deletedComment, newData)
        });
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }

        let queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    async fetchAllIssues(force) {
        let cachingIndex = this.context.team.slice().join('_');
        cachingIndex += this.state.activeLeg ? '_relevant' : '_irrelevant';

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                dataUpdated: false,
                issuesLoaded: true,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        let sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
        let tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }

            const response = await this.context.get(issuesDetailsUrl, {
                sort: sort,
                active: this.state.activeLeg,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                console.error(response.message);
                return {};
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    notificationsLoaded: true,
                    dataUpdated: true
                });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const numExcluded = data.filter(issue => issue.type === "ALARM" || issue.type === "INACTIVE_IN_YARD").length;
            const issues = mapIssuesFromResponse(data);
            const newData = mapGridResponseData(issues, response, numExcluded);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
            
        } catch (error) {
            console.error(error);
            this.setState({
                data: Array.from([]),
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    changeActiveness = (relevantIssues) => {
        const queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevantIssues);

        this.setState({
            activeLeg: relevantIssues,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber,
            },
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    render() {
        return (
            <div className="container-fluid page fleet">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active"><a href="#!">All issues </a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <div className="heading">
                                All issues <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.issuesLoaded || !this.state.dataUpdated} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="active-toggle">
                            <ActiveSelection activeCallback={this.changeActiveness.bind(this)} initiallyActive={this.state.activeLeg} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="fleetTableDiv">
                            {this.state.issuesLoaded && <DataTableComp
                                tableId="fleetIssues"
                                columns={this.state.columns}
                                orderRule={this.state.sortingRule}
                                data={this.state.data}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                columnDefs={this.state.columnDefs}
                                isDetailView={false}
                                customTableClass="cell-border"
                                tableHeight="1200px"
                                handlePreviousPage={this.handlePreviousPage}
                                handleNextPage={this.handleNextPage}
                                handleFirstPage={this.handleFirstPage}
                                handleLastPage={this.handleLastPage}
                                handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                                handlePageChange={(event) => this.handlePageChange(event)}
                                pagingInfo={this.state.pagingInfo}
                                onClickLocation={{ pathname: "/details", state: { trailerId: this.state.data.onClickState } }}
                                account={this.state.account}
                                sortRule={this.state.sort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withAITracking(reactPlugin, Fleet, "Fleet");
