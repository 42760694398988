/**
 * @description
 * Converts a hex color value to rgba.
 *
 * @param hex Hex color value.
 * @param alpha Number from 0 to 1 indicating color transparency.
 *
 * @returns RGBA string value of the provided hex value.
 */

export function hexToRgb(hex, alpha) {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${ r },${ g },${ b },${ alpha })`;
}
