export const notificationType = Object.freeze({
    VALUE_MISMATCH_WARNING: "VALUE_MISMATCH_WARNING",
    VALUE_MISMATCH_ESCALATION: "VALUE_MISMATCH_ESCALATION",
    VALUE_MISMATCH_RESOLVED: "VALUE_MISMATCH_RESOLVED",
    SILENT_UNIT_WARNING: "SILENT_UNIT_WARNING",
    SILENT_UNIT_ESCALATION: "SILENT_UNIT_ESCALATION",
    SILENT_UNIT_RESOLVED: "SILENT_UNIT_RESOLVED",
    LOW_FUEL_WARNING: "LOW_FUEL_WARNING",
    LOW_FUEL_ESCALATION: "LOW_FUEL_ESCALATION",
    LOW_FUEL_RESOLVED: "LOW_FUEL_RESOLVED",
    MAINTENANCE: "MAINTENANCE",
    VALUE_MISSING_WARNING: "VALUE_MISSING_WARNING",
    VALUE_MISSING_RESOLVED: "VALUE_MISSING_RESOLVED",
    DETENTION_WARNING: "DETENTION_WARNING",
    DETENTION_RESOLVED: "DETENTION_RESOLVED",
    INACTIVITY_WARNING: "INACTIVITY_WARNING",
    INACTIVITY_RESOLVED: "INACTIVITY_RESOLVED",
    DETACHED_WARNING: "DETACHED_WARNING",
    DETACHED_RESOLVED: "DETACHED_RESOLVED",
    MISSING_WARNING: "MISSING_WARNING",
    MISSING_RESOLVED: "MISSING_RESOLVED",
    INACTIVE_IN_YARD_WARNING: "INACTIVE_IN_YARD_WARNING",
    INACTIVE_IN_YARD_RESOLVED: "INACTIVE_IN_YARD_RESOLVED",
    LOCATION_MISMATCH_WARNING: "LOCATION_MISMATCH_WARNING",
    LOCATION_MISMATCH_ESCALATION: "LOCATION_MISMATCH_ESCALATION",
    LOCATION_MISMATCH_RESOLVED: "LOCATION_MISMATCH_RESOLVED",
    CLAIM_WARNING: "CLAIM_WARNING",
    CLAIM_ESCALATION: "CLAIM_ESCALATION",
    CLAIM_RESOLVED: "CLAIM_RESOLVED",
    TRAILER_REPORT: "TRAILER_REPORT"
});
