import React, { Component } from 'react';
import CanAccess from 'components/can-access/CanAccess';
import DataTableLink from 'components/datatable/DataTableLink';
import { PERMISSION } from 'global/utils/auth';

export default class TrailerCell extends Component {
    render() {
        const { className, rowData, permissions } = this.props;

        let to = `/details/${ rowData.onClickState.trailerId }`;
        if (rowData.onClickState.assignmentId) {
            to += `/${ rowData.onClickState.assignmentId }`;
        }

        return (
            <CanAccess
                permissions={ permissions }
                action={ PERMISSION.TRAILER.READ }
                yes={
                    <DataTableLink className={ className } to={ to }>
                        { rowData.trailerId }
                    </DataTableLink>
                }
                no={
                    <div className={ className }>
                        { rowData.trailerId }
                    </div>
                }
            />
        );
    }
}
