import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckCircle,
    faComment,
    faExclamationCircle,
    faEye,
    faInfoCircle,
    faQuestionCircle,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { faAlarmSnooze } from '@fortawesome/pro-solid-svg-icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { withRouter } from "react-router";
import Loader from 'react-loader-spinner';

import { reactPlugin } from 'AppInsights';
import AuthContext from "AuthContext";
import DataTableComp from 'components/datatable/DataTableComp';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import SimpleContainer from 'components/container/SimpleContainer';
import TeamIcon from "components/team-icon/TeamIcon"
import ActiveSelection from "components/input/ActiveSelection";
import CanAccess from "components/can-access/CanAccess";
import { defaultGridRecordsNumber, issuesDetailsUrl, mapSort, SortParam } from 'global/constants';
import { issueSnoozeable, issueTypes } from 'global/services/IssueTypeService';
import { getCurrentDate as currentDate, getDifferenceBetweenDates } from 'global/services/DateTimeService';
import { mapIssuesFromResponse } from "global/services/IssueApiService"
import QueryParamService from "global/services/QueryParamService";
import { PERMISSION } from 'global/utils/auth';
import deleteComment from 'global/utils/deleteComment';
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import './TemperatureIssues.scss';

const $ = require("jquery");

const CACHE = {};

class TemperatureIssues extends Component {
    static contextType = AuthContext;
    constructor(props, context) {
        super(props, context);
        this.columnNames = {
            0: SortParam.ASSIGNMENT,
            1: SortParam.TRAILER,
            2: SortParam.COMPANY,
            3: SortParam.DRIVER,
            6: SortParam.START_TIME,
            7: SortParam.END_TIME,
            12: SortParam.ISSUE_STATE
        }

        this.columns = [{
            title: "Order #",
            data: "orderId"
        }, {
            title: "Trailer #",
            data: "trailerId"
        }, {
            title: "Customer ID",
            data: "customerId"
        }, {
            title: "Driver",
            data: "driver"
        }, {
            title: "Status",
            data: "status"
        }, {
            title: "Last known position",
            data: "address"
        }, {
            title: 'Created',
            data: 'created'
        }, {
            title: '<span data-toggle="tooltip" title="Last time trailer reported via ThermoKing">Last event date</span>',
            data: "eventDate"
        }, {
            title: '<span data-toggle="tooltip" title="Minimum temperature allowed">Min. temp</span>',
            data: "minTemp"
        }, {
            title: '<span data-toggle="tooltip" title="Temperature reading from ThermoKing">Set point</span>',
            data: "setpoint"
        }, {
            title: '<span data-toggle="tooltip" title="Temperature reading from ThermoKing">Return Air</span>',
            data: "returnAir"
        }, {
            title: '<span data-toggle="tooltip" title="Maximum temperature allowed">Max. temp</span>',
            data: "maxTemp"
        }, {
            title: '<span data-toggle="tooltip" title="Mode configuration in TMW">Mode</span>',
            data: "modeC"
        }, {
            title: "Alerts",
            data: "alerts"
        }, {
            title: "Resolved",
            data: "resolved"
        }, {
            title: "Action",
            data: "action"
        }];

        this.sortingRule = [];
        this.data = Array.from([]);

        this.columnDefs = [
            { width: "5%", "targets": [0, 1, 4, 6, 8, 9, 10, 11, 12, 13, 14, 15] },
            { width: "10%", "targets": [2, 3, 5, 7] },
            { className: "dt-align-center", targets: [15] },
            { className: "dt-align-left", targets: [2, 3, 4, 5, 6, 7, 12, 13, 14] },
            { className: "dt-align-right", targets: [0, 1, 8, 9, 10, 11] },
            {
                orderable: false,
                targets: [4, 5, 8, 9, 10, 11, 12, 13, 14, 15]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="centerAlign" style={{ marginRight: "2%" }}>
                            <TeamIcon color={rowData.division} />
                            &nbsp;&nbsp;
                            <span>{rowData.driver}</span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let text = "";
                    if (rowData.status == null) {
                        text = "Unknown"
                        className += "bold redColorText"
                    } else if (rowData.status === true) {
                        text = "Moving"
                    } else {
                        text = "Stopped"
                        className += "bold orangeColorText"
                    }

                    ReactDOM.render(
                        <div className={className} style={{ minWidth: '70px' }}>
                            {text}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex" style={{ minWidth: '40px' }}>
                            {rowData.address}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    if (rowData.minTemp === null || rowData.minTemp === undefined) {
                        rowData.minTemp = "NO DATA"
                    } else if (!isFinite(rowData.minTemp)) {
                        rowData.minTemp = 999;
                    }

                    const minTempIssue = !rowData.resolved && rowData.type === issueTypes.TEMPERATURE && rowData.setpoint < rowData.minTemp;
                    if (minTempIssue) {
                        className = "bold redColorText";
                    }

                    ReactDOM.render(
                        <div className={className}>
                            {minTempIssue && <><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /> <span>&nbsp;&nbsp;</span></>}
                            {rowData.minTemp}
                            {rowData.minTemp !== "NO DATA" && rowData.minTemp !== 999 && <span>&deg;F</span>}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";

                    const tempIssue = !rowData.resolved && rowData.type === issueTypes.TEMPERATURE && (rowData.metaData || {}).setPointIssue === 'true';
                    if (tempIssue) {
                        className = "bold redColorText";
                    }

                    const hasData = (rowData.setpoint || rowData.setpoint === 0) && rowData.setpoint !== "NO DATA";

                    ReactDOM.render(
                        <div className={className}>
                            {tempIssue && <><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /> <span>&nbsp;&nbsp;</span></>}
                            {hasData ? <>{Number.parseFloat(rowData.setpoint).toFixed(0)}<span>&deg;F</span></> : 'NO DATA'}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 10,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";

                    const tempIssue = !rowData.resolved && rowData.type === issueTypes.TEMPERATURE && (rowData.metaData || {}).returnAirIssue === 'true';
                    if (tempIssue) {
                        className = "bold redColorText";
                    }

                    const hasData = (rowData.returnAir || rowData.returnAir === 0) && rowData.returnAir !== "NO DATA";

                    ReactDOM.render(
                        <div className={className}>
                            {tempIssue && <><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /> <span>&nbsp;&nbsp;</span></>}
                            {hasData ? <>{Number.parseFloat(rowData.returnAir).toFixed(0)}<span>&deg;F</span></> : 'NO DATA'}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 11,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    if (rowData.maxTemp === null || rowData.maxTemp === undefined) {
                        rowData.maxTemp = "NO DATA"
                    } else if (!isFinite(rowData.maxTemp)) {
                        rowData.maxTemp = 999
                    }

                    const maxTempIssue = !rowData.resolved && rowData.type === issueTypes.TEMPERATURE && rowData.setpoint > rowData.maxTemp;
                    if (maxTempIssue) {
                        className = "bold redColorText";
                    }

                    ReactDOM.render(
                        <div className={className}>
                            {maxTempIssue && <><FontAwesomeIcon className="redColorText" icon={faExclamationCircle} /> <span>&nbsp;&nbsp;</span></>}
                            {rowData.maxTemp}
                            {rowData.maxTemp !== "NO DATA" && rowData.maxTemp !== 999 && <span>&deg;F</span>}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 12,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            {rowData.modeC}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 13,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";

                    if (!rowData.alerts) {
                        coloredText = "No";
                        className = "redColorText bold";
                        otherText = `  (for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`
                    } else if (rowData.alerts === "Escalation") {
                        coloredText = "Escalation"
                        className = "bold"
                    } else if (rowData.alerts === "Warning") {
                        coloredText = "Warning";
                        className = "bold"
                    }

                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }

                    ReactDOM.render(
                        <div className="notifications" style={{ minWidth: '80px' }}>
                            <div className={`notification-level ` + className}>
                                {coloredText}
                            </div>
                            <div className="notification-info">
                                &nbsp; <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={coloredText === "No" ? { display: "none" } : {}} />
                            </div>
                            {
                                otherText !== "" &&
                                <div className="greyColorText italic snoozed-info">
                                    <br /> {otherText}
                                </div>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 14,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";

                    if (!rowData.resolved) {
                        className += "bold redColorText";
                        coloredText = "No";
                        otherText += ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                    } else if (rowData.resolved) {
                        className += "greenColorText bold";

                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.resolved)})`;
                        }
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <span className={className}>
                                {rowData.resolved ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faExclamationCircle} />}
                                &nbsp;&nbsp;
                                {coloredText}&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.resolved ? { display: "none" } : { marginRight: 4 }} />
                            </span>
                            <span className="greyColorText italic">
                                {otherText}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 15,
                className: "justify",
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass = "";
                    let snoozeIconClass = "";

                    if (issueSnoozeable(rowData.issue) === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeable(rowData.issue) === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon"
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;

                    ReactDOM.render(
                        <div className="flex-always">
                            <div className="seen" id={rowData.id}>
                                <FontAwesomeIcon icon={faEye} className={seenIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={[PERMISSION.ISSUE.SNOOZE, PERMISSION.ISSUE.TEMPERATURE.SNOOZE]}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={snoozeDivClass}>
                                            {snoozeIconClass === "disabled-icon" ? <svg width="16" height="12" style={{marginTop: "2px"}} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                    <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z"/>
                                                    <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z"/>
                                                    <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z"/>
                                                    <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z"/>
                                                    <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z"/>
                                                    <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z"/>
                                                </svg>
                                                : <FontAwesomeIcon icon={faAlarmSnooze} className={snoozeIconClass}/>}
                                        </div>
                                    </>
                                }
                            />
                            &nbsp; &nbsp;
                            <div className="comment">
                                <FontAwesomeIcon icon={faComment} className={commentIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={[PERMISSION.ISSUE.CLOSE, PERMISSION.ISSUE.TEMPERATURE.CLOSE]}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={isRelevant ? "resolve" : "disabled-resolve"}>
                                            <FontAwesomeIcon icon={faTimesCircle} className={isRelevant ? "resolve-icon" : "disabled-icon"} />
                                        </div>
                                    </>
                                }
                            />
                            <CanAccess
                                permissions={ context.permissions } 
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <ReeferControlSvg />
                                    </> 
                                }
                            />
                        </div>,
                        td
                    );
                }
            }
        ];

        let relevantIssues = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : undefined;

        let buttonNode;
        if (!!relevantIssues || relevantIssues === undefined) {
            relevantIssues = true;
            buttonNode = $(`button[id=relevant]`);
        } else {
            relevantIssues = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addActiveLegToQueryString(window.location.search.slice(1), relevantIssues);

        let currentPage = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page') ? parseInt(QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page')) : 1;

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        this.state = {
            account: this.props.account,
            data: this.data,
            sort: [],
            columns: this.columns,
            columnDefs: this.columnDefs,
            sortingRule: this.sortingRule,
            issuesLoaded: false,
            dataUpdated: false,
            activeLeg: relevantIssues,
            showModal: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).temperatureIssues !== undefined ? JSON.parse(localStorage.getItem('showModals')).temperatureIssues : true,
            doNotShowTutorial: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).temperatureIssues !== undefined ? !JSON.parse(localStorage.getItem('showModals')).temperatureIssues : false,
            page: currentPage,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            }
        }

        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
    }

    async componentDidMount() {
        await this.fetchAllIssues();
    }

    async componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            this.handlePage(1, true);
        }
    }

    async handlePage(page, force) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchAllIssues(force);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value),
            },
            page: 1,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;

        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchAllIssues();
            }, 1000);
        }

        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }

        let queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    async fetchAllIssues(force) {
        let cachingIndex = this.context.team.slice().join('_');
        cachingIndex += this.state.activeLeg ? '_relevant' : '_irrelevant';

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        let sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
        let tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }

            const response = await this.context.get(issuesDetailsUrl, {
                active: this.state.activeLeg,
                sort: sort,
                types: ["TEMPERATURE"],
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                console.error(response.message);
                return {};
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    notificationsLoaded: true,
                    dataUpdated: true
                });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const issues = mapIssuesFromResponse(data);
            const newData = mapGridResponseData(issues, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                data: Array.from([]),
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    onReceiveNewComment(comment) {
        let newData = this.state.data;
        newData.find((data) => data.issue.id === comment.issue.id).comments.unshift(comment);
        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        let newData = this.state.data;

        this.setState({
            allIssues: deleteComment(deletedComment, newData)
        });
    }

    onCheckboxChange() {
        this.setState({
            doNotShowTutorial: !this.state.doNotShowTutorial
        });
    }

    openTutorialModal() {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        const existingItem = JSON.parse(localStorage.getItem('showModals'));
        localStorage.setItem('showModals', JSON.stringify({ ...existingItem, ...{ temperatureIssues: !this.state.doNotShowTutorial } }));
        this.setState({
            showModal: false
        });
    }

    changeActiveness = (relevantIssues) => {
        let queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevantIssues);

        this.setState({
            activeLeg: relevantIssues,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber,
            },
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    render() {
        return (
            <div className="container-fluid page temperature-issues">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <CanAccess
                                    action={PERMISSION.PAGE.ALL_ISSUES}
                                    yes={<li className="breadcrumb-item"><a href="/issues/all">All issues</a></li>}
                                    no={<li className="breadcrumb-item"><a href="/">Fleet Overview</a></li>}
                                />
                                <li className="breadcrumb-item active"><a href="#!">Temperature issues</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading">
                                Temperature issues
                            </p>
                            <div className="tutorial-info">
                                <p className="tutorial-text"> <FontAwesomeIcon className="tutorial-icon" icon={faQuestionCircle} onClick={this.openTutorialModal.bind(this)}></FontAwesomeIcon></p>
                            </div>
                            &nbsp; &nbsp; <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.issuesLoaded || !this.state.dataUpdated} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="active-toggle">
                            <ActiveSelection activeCallback={this.changeActiveness.bind(this)} initiallyActive={this.state.activeLeg} />
                        </div>
                    </div>
                </div>
                {(!this.state.doNotShowTutorial || this.state.showModal) &&
                    <Modal
                        show={this.state.showModal}
                        onHide={this.handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                        keyboard={false}
                        className="tutorial-modal-temperature"
                    >
                        <SimpleContainer className="tutorial-modal-temperature" title="TEMPERATURE ISSUES TUTORIAL" modal={true}>
                            <Modal.Body className="tutorial-modal-temperature">
                                <div className="row">
                                    <div className="col-sm-4 col-xs-12">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="modalText modalStepText" style={{ marginLeft: "20%" }} colSpan="5">
                                                        MATCHING THE RATE CONFIRMATION, DATA IN THE TRIP FOLDER, INFORMATION ON THE BOL AGAINST THE THERMOKING UNIT PROCESSES
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.875 0C7.52344 0 0 7.52344 0 16.875C0 26.2266 7.52344 33.75 16.875 33.75C26.2266 33.75 33.75 26.2266 33.75 16.875C33.75 7.52344 26.2266 0 16.875 0ZM16.875 30.375C9.42188 30.375 3.375 24.3281 3.375 16.875C3.375 9.42188 9.42188 3.375 16.875 3.375C24.3281 3.375 30.375 9.42188 30.375 16.875C30.375 24.3281 24.3281 30.375 16.875 30.375ZM22.4297 11.1797L14.625 18.9844L11.3203 15.6797C10.6875 15.0469 9.5625 15.0469 8.92969 15.6797C8.29688 16.3125 8.29688 17.4375 8.92969 18.0703L13.4297 22.5703C13.7812 22.9219 14.2031 23.0625 14.625 23.0625C15.0469 23.0625 15.4688 22.9219 15.8203 22.5703L24.8203 13.5703C25.4531 12.9375 25.4531 11.8125 24.8203 11.1797C24.1875 10.5469 23.0625 10.5469 22.4297 11.1797Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 1:
                                                        </p>
                                                        <p className="modalText">
                                                            If all 4 match, we are good. There is no issue
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="modalText modalStepText" style={{ marginLeft: "20%" }} colSpan="5">
                                                        IF TK, RC OR BOL SHOWS A TEMP THAT DOESN'T MATCH ALL :
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 1:
                                                        </p>
                                                        <p className="modalText">
                                                            Call and email the broker (What is written in TMW Trip Folder is irrelevant if the official documents don't show a match)
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M28 0H4C1.79375 0 0 1.79375 0 4V22C0 24.2062 1.79375 26 4 26H10V31.25C10 31.6938 10.3625 32 10.75 32C10.9 32 11.0563 31.9563 11.1938 31.85L19 26H28C30.2062 26 32 24.2062 32 22V4C32 1.79375 30.2062 0 28 0ZM29 22C29 22.55 28.55 23 28 23H18L17.2 23.6L13 26.75V23H4C3.45 23 3 22.55 3 22V4C3 3.45 3.45 3 4 3H28C28.55 3 29 3.45 29 4V22ZM23 8.5H9C8.45 8.5 8 8.95 8 9.5V10.5C8 11.05 8.45 11.5 9 11.5H23C23.55 11.5 24 11.05 24 10.5V9.5C24 8.95 23.55 8.5 23 8.5ZM17 14.5H9C8.45 14.5 8 14.95 8 15.5V16.5C8 17.05 8.45 17.5 9 17.5H17C17.55 17.5 18 17.05 18 16.5V15.5C18 14.95 17.55 14.5 17 14.5Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 2:
                                                        </p>
                                                        <p className="modalText">
                                                            Update TMW - min/max temps, leave notes, etc.
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            Update the Driver and resend total mail dispatch
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="modalText modalStepText" style={{ marginLeft: "20%" }} colSpan="5">
                                                        IF TK SHOWS A TEMP THAT IS NOT MATCHING WHAT BOTH THE RC AND BOL, & TMW TRIP FOLDER SAY, THAT IS EITHER A MECHANICAL ISSUE OR DRIVER NEGLIGENCE:
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M43.9917 13.3333H40V9.33333C40 7.125 38.2083 5.33333 36 5.33333H22.6667L17.3333 0H4C1.79167 0 0 1.79167 0 4V28C0 30.2083 1.79167 32 4 32H37.3333C38.7083 32 39.9917 31.2917 40.725 30.1167L47.3833 19.45C49.05 16.7917 47.1333 13.3333 43.9917 13.3333ZM4 4.5C4 4.225 4.225 4 4.5 4H15.675L21.0083 9.33333H35.5C35.775 9.33333 36 9.55833 36 9.83333V13.3333H12.6667C11.2667 13.3333 9.96667 14.0667 9.24167 15.2667L4 23.95V4.5ZM37.3333 28H6L12.4333 17.3333H44L37.3333 28Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 1:
                                                        </p>
                                                        <p className="modalText">
                                                            Open Trip folder
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M25.875 0C22.7812 0 20.25 2.53125 20.25 5.625C20.25 8.71875 22.7812 11.25 25.875 11.25C28.9688 11.25 31.5 8.71875 31.5 5.625C31.5 2.53125 28.9688 0 25.875 0ZM25.875 7.875C24.6094 7.875 23.625 6.89062 23.625 5.625C23.625 4.35938 24.6094 3.375 25.875 3.375C27.1406 3.375 28.125 4.35938 28.125 5.625C28.125 6.89062 27.1406 7.875 25.875 7.875ZM18 7.875C18 3.51562 14.4844 0 10.125 0C5.76562 0 2.25 3.51562 2.25 7.875V19.5469C0.84375 21.3047 0 23.4844 0 25.875C0 31.4297 4.57031 36 10.125 36C15.6797 36 20.25 31.4297 20.25 25.875C20.25 23.4844 19.4062 21.3047 18 19.5469V7.875ZM10.125 32.625C6.39844 32.625 3.375 29.6016 3.375 25.875C3.375 23.9766 4.21875 22.5703 4.92188 21.7266L5.625 20.7422V7.875C5.625 5.41406 7.66406 3.375 10.125 3.375C12.5859 3.375 14.625 5.41406 14.625 7.875V20.7422L15.3281 21.6562C16.0312 22.5703 16.875 23.9766 16.875 25.875C16.875 29.6016 13.8516 32.625 10.125 32.625ZM11.25 22.7109V7.875C11.25 7.24219 10.7578 6.75 10.125 6.75C9.49219 6.75 9 7.24219 9 7.875V22.7109C7.66406 23.2031 6.75 24.3984 6.75 25.875C6.75 27.7031 8.29688 29.25 10.125 29.25C11.9531 29.25 13.5 27.7031 13.5 25.875C13.5 24.3984 12.5859 23.2031 11.25 22.7109Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 2:
                                                        </p>
                                                        <p className="modalText">
                                                            Check RC Document against what is in the Trip Folder min/max temp fields
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.85938 22.375L14.875 17.4062C15.0391 17.2422 15.0391 16.9766 14.875 16.8125L13.8906 15.8203C13.7266 15.6562 13.4609 15.6562 13.2969 15.8203L9.57812 19.5078L7.96875 17.875C7.80469 17.7109 7.53906 17.7109 7.375 17.875L6.38281 18.8594C6.21875 19.0234 6.21875 19.2891 6.38281 19.4531L9.28125 22.375C9.42969 22.5391 9.69531 22.5391 9.85938 22.375ZM26.25 5H20C20 2.24219 17.7578 0 15 0C12.2422 0 10 2.24219 10 5H3.75C1.67969 5 0 6.67969 0 8.75V36.25C0 38.3203 1.67969 40 3.75 40H26.25C28.3203 40 30 38.3203 30 36.25V8.75C30 6.67969 28.3203 5 26.25 5ZM15 3.75C15.6875 3.75 16.25 4.3125 16.25 5C16.25 5.6875 15.6875 6.25 15 6.25C14.3125 6.25 13.75 5.6875 13.75 5C13.75 4.3125 14.3125 3.75 15 3.75ZM26.25 35.625C26.25 35.9688 25.9688 36.25 25.625 36.25H4.375C4.03125 36.25 3.75 35.9688 3.75 35.625V9.375C3.75 9.03125 4.03125 8.75 4.375 8.75H7.5V11.25C7.5 11.9375 8.0625 12.5 8.75 12.5H21.25C21.9375 12.5 22.5 11.9375 22.5 11.25V8.75H25.625C25.9688 8.75 26.25 9.03125 26.25 9.375V35.625ZM8.75 25.625C7.71094 25.625 6.875 26.4609 6.875 27.5C6.875 28.5391 7.71094 29.375 8.75 29.375C9.78906 29.375 10.625 28.5391 10.625 27.5C10.625 26.4609 9.78906 25.625 8.75 25.625ZM21.875 18.75H16.9297C16.8281 18.8906 16.7656 19.0547 16.6406 19.1797L14.5469 21.25H21.875C22.2188 21.25 22.5 20.9688 22.5 20.625V19.375C22.5 19.0312 22.2188 18.75 21.875 18.75ZM21.875 26.25H13.125C12.7812 26.25 12.5 26.5312 12.5 26.875V28.125C12.5 28.4688 12.7812 28.75 13.125 28.75H21.875C22.2188 28.75 22.5 28.4688 22.5 28.125V26.875C22.5 26.5312 22.2188 26.25 21.875 26.25Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            Compare RC & TMW with the BOL in Synergize
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 4:
                                                        </p>
                                                        <p className="modalText">
                                                            Call the Driver and correct temp as soon as possible
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 5:
                                                        </p>
                                                        <p className="modalText">
                                                            Notify DM Fleet
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M28 0H4C1.79375 0 0 1.79375 0 4V22C0 24.2062 1.79375 26 4 26H10V31.25C10 31.6938 10.3625 32 10.75 32C10.9 32 11.0563 31.9563 11.1938 31.85L19 26H28C30.2062 26 32 24.2062 32 22V4C32 1.79375 30.2062 0 28 0ZM29 22C29 22.55 28.55 23 28 23H18L17.2 23.6L13 26.75V23H4C3.45 23 3 22.55 3 22V4C3 3.45 3.45 3 4 3H28C28.55 3 29 3.45 29 4V22ZM23 8.5H9C8.45 8.5 8 8.95 8 9.5V10.5C8 11.05 8.45 11.5 9 11.5H23C23.55 11.5 24 11.05 24 10.5V9.5C24 8.95 23.55 8.5 23 8.5ZM17 14.5H9C8.45 14.5 8 14.95 8 15.5V16.5C8 17.05 8.45 17.5 9 17.5H17C17.55 17.5 18 17.05 18 16.5V15.5C18 14.95 17.55 14.5 17 14.5Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 6:
                                                        </p>
                                                        <p className="modalText">
                                                            Leave Notes in TMW
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="modalText modalStepText" style={{ marginLeft: "20%" }} colSpan="5">
                                                        IF THE BOL HAS NO TEMPERATURE REQUIREMENTS, ENSURE THE DRIVER SUBMITS A COPY OF THE BILL OF LADING TO YOU IMMEDIATELY:
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M25.875 0C22.7812 0 20.25 2.53125 20.25 5.625C20.25 8.71875 22.7812 11.25 25.875 11.25C28.9688 11.25 31.5 8.71875 31.5 5.625C31.5 2.53125 28.9688 0 25.875 0ZM25.875 7.875C24.6094 7.875 23.625 6.89062 23.625 5.625C23.625 4.35938 24.6094 3.375 25.875 3.375C27.1406 3.375 28.125 4.35938 28.125 5.625C28.125 6.89062 27.1406 7.875 25.875 7.875ZM18 7.875C18 3.51562 14.4844 0 10.125 0C5.76562 0 2.25 3.51562 2.25 7.875V19.5469C0.84375 21.3047 0 23.4844 0 25.875C0 31.4297 4.57031 36 10.125 36C15.6797 36 20.25 31.4297 20.25 25.875C20.25 23.4844 19.4062 21.3047 18 19.5469V7.875ZM10.125 32.625C6.39844 32.625 3.375 29.6016 3.375 25.875C3.375 23.9766 4.21875 22.5703 4.92188 21.7266L5.625 20.7422V7.875C5.625 5.41406 7.66406 3.375 10.125 3.375C12.5859 3.375 14.625 5.41406 14.625 7.875V20.7422L15.3281 21.6562C16.0312 22.5703 16.875 23.9766 16.875 25.875C16.875 29.6016 13.8516 32.625 10.125 32.625ZM11.25 22.7109V7.875C11.25 7.24219 10.7578 6.75 10.125 6.75C9.49219 6.75 9 7.24219 9 7.875V22.7109C7.66406 23.2031 6.75 24.3984 6.75 25.875C6.75 27.7031 8.29688 29.25 10.125 29.25C11.9531 29.25 13.5 27.7031 13.5 25.875C13.5 24.3984 12.5859 23.2031 11.25 22.7109Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 1:
                                                        </p>
                                                        <p className="modalText">
                                                            If the BOL has no temp, have the Inside Sales or Customer Service Rep confirm what the temperature should be at. Ensure you have this in writing to avoid a claim situation
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg style={{ marginRight: -6 }} width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M40 2.66667H36V1.33333C36 0.596667 35.4033 0 34.6667 0H26.6667C25.93 0 25.3333 0.596667 25.3333 1.33333V2.665L17.3333 2.66667V1.33333C17.3333 0.596667 16.7367 0 16 0H8C7.26333 0 6.66667 0.596667 6.66667 1.33333V2.66667H2.66667C1.19417 2.66667 0 3.86083 0 5.33333V29.3333C0 30.8058 1.19417 32 2.66667 32H40C41.4725 32 42.6667 30.8058 42.6667 29.3333V5.33333C42.6667 3.86083 41.4725 2.66667 40 2.66667ZM38.6667 28H4V6.66667H38.6667V28ZM16.6667 14H7.33333C6.965 14 6.66667 14.2983 6.66667 14.6667V17.3333C6.66667 17.7017 6.965 18 7.33333 18H16.6667C17.035 18 17.3333 17.7017 17.3333 17.3333V14.6667C17.3333 14.2983 17.035 14 16.6667 14ZM35.3333 14H32.6667V11.3333C32.6667 10.965 32.3683 10.6667 32 10.6667H29.3333C28.965 10.6667 28.6667 10.965 28.6667 11.3333V14H26C25.6317 14 25.3333 14.2983 25.3333 14.6667V17.3333C25.3333 17.7017 25.6317 18 26 18H28.6667V20.6667C28.6667 21.035 28.965 21.3333 29.3333 21.3333H32C32.3683 21.3333 32.6667 21.035 32.6667 20.6667V18H35.3333C35.7017 18 36 17.7017 36 17.3333V14.6667C36 14.2983 35.7017 14 35.3333 14Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 2:
                                                        </p>
                                                        <p className="modalText">
                                                            Update TMW accordingly
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <Button variant="continue" onClick={this.handleClose.bind(this)} style={{ width: "240px", height: "36px", marginTop: "15px" }}>
                                    Got it!
                                </Button>
                                <div className="form-check">
                                    <label className={`checkBoxText ${this.state.doNotShowTutorial ? "bold" : ""}`}>
                                        <input
                                            type="checkbox"
                                            name={this.state.label}
                                            checked={this.state.doNotShowTutorial}
                                            onChange={this.onCheckboxChange.bind(this)}
                                            className="form-check-input"
                                        />
                                        Do not show this tip again
                                    </label>
                                </div>
                            </Modal.Body>
                        </SimpleContainer>
                    </Modal>}
                <div className="row">
                    <div className="col">
                        <div className="temp-issues-div">
                            {this.state.issuesLoaded && <DataTableComp
                                tableId="temperatureIssues"
                                columns={this.state.columns}
                                orderRule={this.state.sortingRule}
                                data={this.state.data}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                isDetailView={false}
                                columnDefs={this.state.columnDefs}
                                customTableClass="cell-border"
                                tableHeight="1200px"
                                handlePreviousPage={this.handlePreviousPage}
                                handleNextPage={this.handleNextPage}
                                handleFirstPage={this.handleFirstPage}
                                handleLastPage={this.handleLastPage}
                                handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                                handlePageChange={(event) => this.handlePageChange(event)}
                                pagingInfo={this.state.pagingInfo}
                                account={this.state.account}
                                onClickLocation={{ pathname: "/details", state: { trailerId: this.state.data.onClickState } }}
                                sortRule={this.state.sort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, TemperatureIssues, "TemperatureIssues"));
