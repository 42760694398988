import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faTruck,
    faUser
} from '@fortawesome/pro-solid-svg-icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Loader from 'react-loader-spinner';

import {
    formatDate,
    formatLongDate,
    formatLongDateWithoutTime,
    formatShortDate
} from "global/services/DateTimeService";
import { mapIssuesFromResponse } from "global/services/IssueApiService";
import { ISSUE_TYPE, issueTypeMap } from "global/services/IssueTypeService";
import AuthorizationService from "global/services/AuthorizationService";
import history from "global/history";
import {
    assignmentUrl,
    issuesDetailsUrl,
    mapResponseData,
    measurementsUrl,
    RESOLVED_STATES,
    trailerUrl,
    UNRESOLVED_STATES
} from "global/constants";
import { PERMISSION } from "global/utils/auth";
import StringUtils from 'global/utils/StringUtils';
import SimpleContainer from 'components/container/SimpleContainer';
import TrailerSettings from 'components/trailer-settings/TrailerSettings';
import ReeferControlPopup from 'components/popup/ReeferControlPopup';
import CanAccess from "components/can-access/CanAccess";
import IssueTable from 'pages/trailer/IssueTable';
import MeasurementsFilter from 'pages/trailer/MeasurementsFilter';
import MeasurementsTable from 'pages/trailer/MeasurementsTable';
import CommandsTable from 'pages/trailer/CommandsTable';
import AlarmIssueTable from "pages/trailer/AlarmIssueTable";
import InactiveInYardTable from 'pages/trailer/InactiveInYardTable';
import TrailerLocation from 'pages/trailer/TrailerLocation';
import { reactPlugin } from 'AppInsights';
import AuthContext from "AuthContext";
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import './DetailView.scss';

const noDataString = "NO DATA";
const CACHE = {};

class DetailView extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        const assignmentCacheString = this.props.match.params.assignmentId ? '_assignment_' + this.props.match.params.assignmentId : '';
        this.state = {
            loaded:false,
            measurements: [],
            measurementsLoading: true,
            measurementsFilter: {},
            issuesList: [],
            statusClassName: "gathered-info orangeColorText",
            lastKnownLocationClassName: "gathered-info",
            account: props.account,
            cacheIndex: 'trailer_' + this.props.match.params.trailerId + assignmentCacheString,
            moving: null,
            date: "NO DATA",
            location: "Unknown",
            actualMode: null,
            setPoint: null,
            returnAir: null,
            power: null,
            resolvedIssues: [],
            unresolvedIssues: [],
            previousInactiveInYardIssues: [],
            activeInactiveInYardIssue: []
        }
        this.fetchInactiveInYardIssues = this.fetchInactiveInYardIssues.bind(this)
        this.onMeasurementsFilterChange = this.onMeasurementsFilterChange.bind(this)
        this.fetchMeasurements = this.fetchMeasurements.bind(this)
    }

    async componentDidMount() {
        if(this.props.match && (this.props.match.params.trailerId || this.props.match.params.assignmentId)) {
            try {
                await this.fetchTrailer();
                await this.fetchAssignment();
                const measurementsResponse = await this.fetchMeasurements();
                this.setStateFromLatestMeasurement(measurementsResponse.data[0]);
                await this.fetchIssues();
                await this.fetchAlarmIssues();
                await this.fetchInactiveInYardIssues();
                await this.fetchCommands();
            } catch (e) {
                console.error(e);
            } finally {
                this.setState({
                    loaded: true
                })
            }
        }
    }

    async fetchTrailer() {
        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState({
                trailerBusinessId: CACHE[this.state.cacheIndex].trailerBusinessId
            });
        }

        const response = await this.context.get(trailerUrl, { id: this.props.match.params.trailerId }, true);
        if(response.status === "error" || !response || response.length <= 0) {
            history.push("/404");
            return {};
        }

        CACHE[this.state.cacheIndex] = {
            ...CACHE[this.state.cacheIndex],
            trailerBusinessId: response.businessId,
            trailerSerialNumber: response.serialNumber
        };

        this.setState({
            trailerBusinessId: response.businessId,
            trailerSerialNumber: response.serialNumber
        });
    }

    async fetchAssignment() {
        if (!this.props.match.params.assignmentId || !AuthorizationService.canAccess(this.context.permissions, PERMISSION.ASSIGNMENT.READ)) {
            return {};
        }

        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState({
                assignmentId: CACHE[this.state.cacheIndex].assignmentId,
                assignmentBusinessId: CACHE[this.state.cacheIndex].assignmentBusinessId,
                measurementsFilter: CACHE[this.state.cacheIndex].measurementsFilter || {},
                isActiveTrailer: CACHE[this.state.cacheIndex].isActiveTrailer,
                assignmentStatus: CACHE[this.state.cacheIndex].active ? "Started" : "Completed",
                driverName: (((CACHE[this.state.cacheIndex].activeLeg || {}).driver || {}).contactInfo || {}).name || "Unknown",
                driverPhone: (((CACHE[this.state.cacheIndex].activeLeg || {}).driver || {}).contactInfo || {}).phone || "Unknown",
                driverMail: (((CACHE[this.state.cacheIndex].activeLeg || {}).driver || {}).contactInfo || {}).email || "Unknown",
                team: ((((CACHE[this.state.cacheIndex].activeLeg || {}).driver || {}).division || {}).contactInfo || {}).name 
                      || (((CACHE[this.state.cacheIndex].activeLeg || {}).driver || {}).division || {}).businessId  
                      || "Unknown",
                teamMail: ((((CACHE[this.state.cacheIndex].activeLeg || {}).driver || {}).division || {}).contactInfo || {}).email || "Unknown",
                insideSalesRepName: ((CACHE[this.state.cacheIndex].insideSalesRep || {}).contactInfo || {}).name || "Unknown",
                mode: CACHE[this.state.cacheIndex].unitMode === "CONTINUOUS" ? "Continuous": "Cycle-Sentry",
                minTemp: CACHE[this.state.cacheIndex].temperatureMin,
                maxTemp: CACHE[this.state.cacheIndex].temperatureMax,
                expectedPower: CACHE[this.state.cacheIndex].temperatureControlled ? "On" : "Off"
            });
        }

        const response = await this.context.get(assignmentUrl, { id: this.props.match.params.assignmentId }, true);
        if(response.status === "error" || !response || response.length <= 0) {
            history.push("/404");
            return {};
        }
        if(response.temperatureMin === null || response.temperatureMin === undefined) {
            response.temperatureMin = noDataString;
        } else if (!isFinite(response.temperatureMin)) {
            response.temperatureMin = 999
        }
        if(response.temperatureMax === null || response.temperatureMax === undefined) {
            response.temperatureMax = noDataString;
        } else if (!isFinite(response.temperatureMax)) {
            response.temperatureMax = 999
        }
        const activeLeg = response.legs && response.relevantLeg ? response.relevantLeg : null;
        const isActiveTrailer = activeLeg ? activeLeg.trailer.businessId === this.state.trailerBusinessId : false;

        CACHE[this.state.cacheIndex] = {
            ...CACHE[this.state.cacheIndex],
            assignmentId: response.id,
            assignmentBusinessId: response.businessId,
            measurementsFilter: { assignmentBusinessId: response.businessId },
            isActiveTrailer: isActiveTrailer,
            active: response.active,
            activeLeg: activeLeg,
            insideSalesRep: response.insideSalesRep,
            unitMode: response.unitMode,
            temperatureMin: response.temperatureMin,
            maxTemp: response.temperatureMax,
            temperatureControlled: response.temperatureControlled
        };

        this.setState({
            assignmentId: response.id,
            assignmentBusinessId: response.businessId,
            measurementsFilter: { assignmentBusinessId: response.businessId },
            isActiveTrailer: isActiveTrailer,
            relevantLeg: activeLeg,
            assignmentStatus: response.active ? "Started" : "Completed",
            driverName: (((activeLeg || {}).driver || {}).contactInfo || {}).name || "Unknown",
            driverPhone: (((activeLeg || {}).driver || {}).contactInfo || {}).phone || "Unknown",
            driverMail: (((activeLeg || {}).driver || {}).contactInfo || {}).email || "Unknown",
            team: ((((activeLeg || {}).driver || {}).division || {}).contactInfo || {}).name || (((activeLeg || {}).driver || {}).division || {}).businessId  || "Unknown",
            teamMail: ((((activeLeg || {}).driver || {}).division || {}).contactInfo || {}).email || "Unknown",
            insideSalesRepName: ((response.insideSalesRep || {}).contactInfo || {}).name || "Unknown",
            mode: response.unitMode === "CONTINUOUS" ? "Continuous": "Cycle-Sentry",
            minTemp: response.temperatureMin,
            maxTemp: response.temperatureMax,
            expectedPower: response.temperatureControlled ? "On" : "Off"
        });
    }

    setStateFromLatestMeasurement(latestMeasurement) {
        this.setState({
            moving: this.state.isActiveTrailer ? latestMeasurement.positionState.moving ? 'Moving' : 'Stopped' : 'Finished',
            statusClassName: 'gathered-info ' + (latestMeasurement.positionState.moving || !this.state.isActiveTrailer ? 'greenColorText' : !latestMeasurement.positionState.moving ? 'orangeColorText' : 'redColorText'),
            detentionState: (latestMeasurement || {}).detentionState || {},
            inactivityState: latestMeasurement,
            longDate: latestMeasurement.time ? formatLongDate(new Date(latestMeasurement.time)) : 'Unknown',
            date: latestMeasurement.time ? formatLongDateWithoutTime(new Date(latestMeasurement.time)) : 'Unknown',
            location: latestMeasurement.positionState.position ? latestMeasurement.positionState.position : 'Unknown',
            fuelLevel: latestMeasurement.engineState && latestMeasurement.engineState.fuelPercentage !== undefined ? latestMeasurement.engineState.fuelPercentage : 'Unknown',
            lastKnownLocationClassName: 'gathered-info ' + (latestMeasurement.positionState.position ? 'greenColorText' : 'redColorText'),
            actualMode: !latestMeasurement.modeState.unitMode ? null : latestMeasurement.modeState.unitMode === 'CONTINUOUS' ? 'Continuous' : 'Cycle-Sentry',
            setPoint: latestMeasurement.temperatureState.setTemperature !== null ? latestMeasurement.temperatureState.setTemperature : 'NO DATA',
            returnAir: latestMeasurement.temperatureState.actualTemperature !== null ? latestMeasurement.temperatureState.actualTemperature : 'NO DATA',
            power: latestMeasurement.powerState.power !== null && latestMeasurement.powerState.power !== undefined ? (latestMeasurement.powerState.power ? 'On' : 'Off') : noDataString,
            expectedPower: !this.state.isActiveTrailer ? (latestMeasurement.powerState.expectedPower === true ? 'On' : latestMeasurement.powerState.expectedPower === false ? 'Off' : null) : this.state.expectedPower,
            minTemp: !this.state.isActiveTrailer ? (latestMeasurement.temperatureState.temperatureMin !== null ? latestMeasurement.temperatureState.temperatureMin : 'NO DATA') : this.state.minTemp,
            maxTemp: !this.state.isActiveTrailer ? (latestMeasurement.temperatureState.temperatureMax !== null ? latestMeasurement.temperatureState.temperatureMax : 'NO DATA') : this.state.maxTemp,
            mode: !this.state.isActiveTrailer ? (latestMeasurement.modeState.expectedUnitMode === 'CONTINUOUS' ? 'Continuous' : 'Cycle-Sentry') : this.state.mode
        });
    }

    onMeasurementsFilterChange(measurementsFilter, reloadData) {
        this.setState(
            { measurementsFilter },
            () => reloadData && this.fetchMeasurements(1, this.state.measurementsSort)
        );
    }

    async fetchMeasurements(page, sort) {
        this.setState({ measurementsLoading: true });

        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState({ measurementsResponse: CACHE[this.state.cacheIndex].measurementsResponse });
        }

        let assignmentBusinessId;
        if (!StringUtils.isBlank(this.state.measurementsFilter.assignmentBusinessId)) {
            assignmentBusinessId = this.state.measurementsFilter.assignmentBusinessId;
        }

        const params = {
            trailerId: this.props.match.params.trailerId,
            pageNumber: page,
            pageSize: 10,
            sort: sort || this.state.measurementsSort,
            assignmentBusinessId,
            from: this.state.measurementsFilter.from,
            to: this.state.measurementsFilter.to
        };

        if (this.props.match.params.assignmentId && this.state.measurementsFilter.assignmentBusinessId === undefined) {
            params.assignmentId = this.props.match.params.assignmentId;
        }

        const response = await this.context.get(measurementsUrl, params, true);
        if (response.status === 'error') {
            console.error(response.message);
            this.setState({ measurementsLoading: false });
            return;
        }

        const measurementsResponse = mapResponseData(response);

        CACHE[this.state.cacheIndex] = {
            ...CACHE[this.state.cacheIndex],
            measurementsResponse
        };

        this.setState({ measurementsResponse, measurementsLoading: false, measurementsSort: sort });
        return measurementsResponse;
    }

    async fetchIssues(sort, resolved) {
        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState(CACHE[this.state.cacheIndex].mappedIssues);
        }

        let state;
        if (resolved) {
            state = RESOLVED_STATES;
        } else if (resolved === false) {
            state = UNRESOLVED_STATES;
        }

        const withoutAssignment = this.state.assignmentId ? null : true;
        let response = await this.context.get(issuesDetailsUrl, { states: state, excludeTypes: [ISSUE_TYPE.ALARM, ISSUE_TYPE.INACTIVE_IN_YARD], sort: sort || [], trailerId: this.props.match.params.trailerId, assignmentId: this.state.assignmentId, withoutAssignment }, true);
        if (response.status === "error" || !response || !response.data) {
            return {};
        }

        const issues = mapIssuesFromResponse(response.data);
        // if data entry error exists, get min & max temperatures from data entry error issue metadata
        let minTemp = this.state.minTemp;
        let maxTemp = this.state.maxTemp;
        const dataEntryErrorIssue = issues.find(issue => issue.type === issueTypeMap("DATA_ENTRY_ERROR") && issue.state.indexOf("RESOLVED") === -1 && Object.keys(issue.metaData).length !== 0);
        if (dataEntryErrorIssue) {
            minTemp = isFinite(dataEntryErrorIssue.metaData.temperatureMin) ? parseInt(dataEntryErrorIssue.metaData.temperatureMin) : 999;
            maxTemp = isFinite(dataEntryErrorIssue.metaData.temperatureMax) ? parseInt(dataEntryErrorIssue.metaData.temperatureMax) : 999;
        }

        if (resolved) {
            CACHE[this.state.cacheIndex] = {
                ...CACHE[this.state.cacheIndex],
                mappedIssues: {
                    resolvedIssues: issues
                }
            };

            this.setState({
                resolvedIssues: issues
            });
        } else if (resolved === false) {
            CACHE[this.state.cacheIndex] = {
                ...CACHE[this.state.cacheIndex],
                mappedIssues: {
                    unresolvedIssues: issues
                }
            };

            this.setState({
                unresolvedIssues: issues
            });
        } else {
            const detained = issues.filter(function(element){ return element && element.type && element.type.startsWith("Detained") && RESOLVED_STATES.indexOf(element.state) === -1}).length;
            const inactive = issues.filter(function(element){ return element && element.type && element.type.startsWith("Inactive") && RESOLVED_STATES.indexOf(element.state) === -1}).length;

            const unresolvedIssues = issues.filter(function(element){ return element && element.state && RESOLVED_STATES.indexOf(element.state) === -1});
            const resolvedIssuesNumber = parseInt(issues.filter(function(element){ return element && element.state && RESOLVED_STATES.indexOf(element.state) !== -1}).length) || 0;
            const resolvedIssues = issues.filter(function(element){ return element && element.state && RESOLVED_STATES.indexOf(element.state) !== -1});

            CACHE[this.state.cacheIndex] = {
                ...CACHE[this.state.cacheIndex],
                mappedIssues: {
                    minTemp: minTemp,
                    maxTemp: maxTemp,
                    detained: detained,
                    inactive: inactive,
                    unresolvedIssues: unresolvedIssues,
                    issuesNumber: issues.length || 0,
                    resolvedIssuesNumber: resolvedIssuesNumber,
                    issues: issues || Array.from([]),
                    resolvedIssues: resolvedIssues
                }
            };

            this.setState({
                minTemp: minTemp,
                maxTemp: maxTemp,
                detained: detained,
                inactive: inactive,
                unresolvedIssues: unresolvedIssues,
                issuesNumber: issues.length || 0,
                resolvedIssuesNumber: resolvedIssuesNumber,
                issues: issues || Array.from([]),
                resolvedIssues: resolvedIssues
            });
        }
    }

    mapAlarmIssues(active, issues, response) {
        const activeAlarmIssues = issues.filter(issue => !issue.resolved) || [];
        const previousAlarmIssues = issues.filter(issue => issue.resolved) || [];

        return {
            activeAlarmIssues: active === undefined || active === true ? Array.from(activeAlarmIssues) : this.state.activeAlarmIssues,
            previousAlarmIssues: active === undefined || active === false ? Array.from(previousAlarmIssues) : this.state.previousAlarmIssues,
            resolvedIssuesNumber: (this.state.resolvedIssuesNumber || 0) + parseInt(previousAlarmIssues.length),
            issuesNumber: (this.state.issuesNumber || 0) + parseInt(issues.length),
            pagingInfo: {
                itemNumber: Math.min(response.pageSize, response.data.length),
                totalItemNumber: response.available,
                totalPageNumber: Math.ceil(response.available / response.pageSize),
                currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
                recordsNumber: response.pageSize
            }
        };
    }

    async fetchAlarmIssues(sort, active) {
        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState(CACHE[this.state.cacheIndex].mappedAlarmIssues);
        }

        const response = await this.context.get(issuesDetailsUrl, {
            types: ["ALARM", "LOW_FUEL_ALARM"],
            trailerId: this.props.match.params.trailerId,
            assignmentId: this.state.assignmentId,
            sort: sort || []
        });

        if (response.status === "error") {
            console.error(response.message);
            return {};
        }

        const data = response.data;
        if (!data) {
            return {};
        }

        const issues = mapIssuesFromResponse(data);
        const mappedAlarmData = this.mapAlarmIssues(active, issues, response);

        CACHE[this.state.cacheIndex] = {
            ...CACHE[this.state.cacheIndex],
            mappedAlarmIssues: mappedAlarmData
        };

        this.setState({
            activeAlarmIssues: mappedAlarmData.activeAlarmIssues,
            previousAlarmIssues: mappedAlarmData.previousAlarmIssues,
            resolvedIssuesNumber: mappedAlarmData.resolvedIssuesNumber,
            issuesNumber: mappedAlarmData.issuesNumber,
            columns: this.columns,
            columnDefs: this.columnDefs,
            sortingRule: this.sortingRule,
            issuesLoaded: true,
            pagingInfo: mappedAlarmData.pagingInfo
        });
    }

    mapInactiveInYardIssues(resolved, issues, response) {
        const activeInactiveInYardIssue = issues.filter(issue => !issue.resolved) || [];
        const previousInactiveInYardIssues = issues.filter(issue => issue.resolved) || [];

        return {
            activeInactiveInYardIssue: resolved === undefined || resolved === true ? Array.from(activeInactiveInYardIssue) : this.state.activeInactiveInYardIssue,
            previousInactiveInYardIssues: resolved === undefined || resolved === false ? Array.from(previousInactiveInYardIssues) : this.state.previousInactiveInYardIssues,
            resolvedIssuesNumber: (this.state.resolvedIssuesNumber || 0) + parseInt(previousInactiveInYardIssues.length),
            issuesNumber: (this.state.issuesNumber || 0) + parseInt(issues.length),
            pagingInfo: {
                itemNumber: Math.min(response.pageSize, response.data.length),
                totalItemNumber: response.available,
                totalPageNumber: Math.ceil(response.available / response.pageSize),
                currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
                recordsNumber: response.pageSize
            }
        };
    }

    async fetchInactiveInYardIssues(sort, resolved) {
        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState(CACHE[this.state.cacheIndex].mappedInactiveInYardIssues);
        }

        const response = await this.context.get(issuesDetailsUrl, {
            types: ["INACTIVE_IN_YARD"],
            trailerId: this.props.match.params.trailerId,
            sort: sort || []
        });

        if (response.status === "error") {
            console.error(response.message);
            return {};
        }

        const data = response.data;
        if (!data) {
            return {};
        }

        const issues = mapIssuesFromResponse(data);
        const mappedInactiveInYardData = this.mapInactiveInYardIssues(resolved, issues, response);

        CACHE[this.state.cacheIndex] = {
            ...CACHE[this.state.cacheIndex],
            mappedInactiveInYardIssues: mappedInactiveInYardData
        };

        this.setState({
            activeInactiveInYardIssue: mappedInactiveInYardData.activeInactiveInYardIssue,
            previousInactiveInYardIssues: mappedInactiveInYardData.previousInactiveInYardIssues,
            resolvedIssuesNumber: mappedInactiveInYardData.resolvedIssuesNumber,
            issuesNumber: mappedInactiveInYardData.issuesNumber,
            columns: this.columns,
            columnDefs: this.columnDefs,
            sortingRule: this.sortingRule,
            issuesLoaded: true,
            pagingInfo: mappedInactiveInYardData.pagingInfo
        });
    }

    async fetchCommands() {
        const response = await this.context.get('/api/v1/trailers/commands', {
            trailerId: this.props.match.params.trailerId,
            pageSize: 10
        });

        this.setState({
            commands: ((response || {}).data || []).map(command => ({
                ...command,
                date: formatDate(new Date(command.date))
            }))
        });
    }

    closeReeferControlPopup() {
        this.setState({ showReeferControlPopup: false }, () => this.fetchCommands());
    }

    render() {
        const currentTrailerInfo = {
            mode: this.state.mode,
            actualMode: this.state.actualMode,
            minTemp: this.state.minTemp,
            maxTemp: this.state.maxTemp,
            setPoint: this.state.setPoint,
            returnAir: this.state.returnAir,
            expectedPower: this.state.expectedPower,
            power: this.state.power,
            issuesList: this.state.issues || [],
            driverName: this.state.driverName,
            driverMail: this.state.driverMail,
            driverPhone: this.state.driverPhone,
            team: this.state.team,
            teamMail: this.state.teamMail,
            insideSalesRepName: this.state.insideSalesRepName,
            hasAssignment: this.state.assignmentId ? true : false,
            lastMeasurementTime: this.state.longDate,
            activeTrailerId: this.state.trailerBusinessId,
            relevantLeg: this.state.relevantLeg
        }

        const detachedIssue = currentTrailerInfo.issuesList.find(issue => issueTypeMap("DETACHED") === issue.type && !issue.resolved && issue.trailerId === this.state.trailerBusinessId);
        const missingIssue = currentTrailerInfo.issuesList.find(issue => issueTypeMap("MISSING") === issue.type && !issue.resolved && issue.trailerId === this.state.trailerBusinessId);

        let locationIssue;
        let borderClassName = "";
        if (detachedIssue) {
            borderClassName = "detached-border";
            locationIssue = detachedIssue;
        } else if (missingIssue) {
            borderClassName = "missing-border";
            locationIssue = missingIssue;
        }

        return(
                <div className="container-fluid page detailview">
                    <div className="row">
                        <nav className="col-12">
                            <ol className="breadcrumb">
                                <CanAccess
                                    action={ PERMISSION.PAGE.ALL_ISSUES }
                                    yes={ <li className="breadcrumb-item"><a href="/issues/all">All issues</a></li> }
                                    no={ <li className="breadcrumb-item"><a href="/">Fleet Overview</a></li> }
                                />
                                <li className="breadcrumb-item active"><a href="#!">Trailer #{this.state.trailerBusinessId}</a></li>
                                <NavLink to="/" className="back-link">
                                    <p>
                                        &larr; Back to dashboard
                                    </p>
                                </NavLink>
                            </ol>
                        </nav>
                        <div className="trailerHeading col-12">
                            <span className="page-title">
                                Trailer #{this.state.trailerBusinessId}
                                { this.state.trailerSerialNumber &&
                                    <span className="serial-number">(Serial Number: { this.state.trailerSerialNumber })</span>
                                }
                                <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.loaded}/>
                            </span>

                            <CanAccess
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <div className="manage">
                                        <Button className="remote-reefer-control-button"
                                                onClick={() => this.setState({ showReeferControlPopup: true })}>
                                            <ReeferControlSvg />
                                            REMOTE REEFER CONTROL
                                        </Button>
                                        <ReeferControlPopup
                                            closeModal={this.closeReeferControlPopup.bind(this)}
                                            showModal={this.state.showReeferControlPopup}
                                            trailerId={this.props.match.params.trailerId}
                                            currentPower={currentTrailerInfo.power}
                                            currentMode={currentTrailerInfo.actualMode}
                                            currentSetPoint={currentTrailerInfo.setPoint}/>
                                    </div>
                                }
                            />
                        </div>
                        <div className="col-12 col-lg-12">
                            <SimpleContainer className={ "basic-info " + borderClassName }>
                                <div className="row trailer-info">
                                    {this.state.assignmentBusinessId && <div>
                                        <p className="info-label">
                                            { locationIssue && "Last " } Order ID:
                                        </p>
                                        <CanAccess
                                            action={ PERMISSION.ASSIGNMENT.READ }
                                            yes={
                                                <Link className="gathered-info link"
                                                      to={ `/assignment-details/${ this.state.assignmentId }` }>
                                                    { this.state.assignmentBusinessId }
                                                </Link>
                                            }
                                            no={
                                                <p className="gathered-info">
                                                    { this.state.assignmentBusinessId }
                                                </p>
                                            }
                                        />
                                    </div>}
                                    {this.state.assignmentStatus && <div>
                                        <p className="info-label">
                                            { locationIssue && "Last " } Order status:
                                        </p>
                                        <p className="gathered-info">
                                            {this.state.assignmentStatus}
                                        </p>
                                    </div>}
                                    <div>
                                        <p className="info-label">
                                            Resolved issues:
                                        </p>
                                        <p className="gathered-info">
                                            {this.state.resolvedIssuesNumber !== null && this.state.resolvedIssuesNumber === this.state.activeIssuesNumber && <span><FontAwesomeIcon icon={faCheckCircle} className="col-0 col-sm-0 issue-icon greenColorText"></FontAwesomeIcon> <span>&nbsp;</span></span>}
                                            {this.state.resolvedIssuesNumber ? this.state.resolvedIssuesNumber : 0}/{this.state.issuesNumber}
                                        </p>
                                    </div>
                                    {this.state.driverName && <div>
                                        <p className="info-label">
                                            { locationIssue && "Last " } Driver:
                                        </p>
                                        <p className="gathered-info">
                                            <FontAwesomeIcon icon={ faUser } className="icon" />
                                            &nbsp;
                                            { !locationIssue ? this.state.driverName : locationIssue.driver }
                                        </p>
                                    </div>}
                                    <div>
                                        <p className="info-label">
                                            Trailer status:
                                        </p>
                                        <p className={` ${this.state.statusClassName} ${(this.state.detained || this.state.inactive) && "redColorText"}`}>
                                            <FontAwesomeIcon icon={faTruck} className="icon"></FontAwesomeIcon> {
                                            this.state.inactive ? "Inactive" :
                                            this.state.detained ? "Detained" :
                                            this.state.moving == null ? "Silent" :
                                            this.state.moving}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="info-label">
                                            Date:
                                        </p>
                                        <p className="gathered-info">
                                            {this.state.date}
                                        </p>
                                    </div>
                                    { locationIssue ?
                                        <>
                                            <div>
                                                <p className="info-label">
                                                    Last known truck position:
                                                </p>
                                                <p className={ this.state.lastKnownLocationClassName }>
                                                    { locationIssue.metaData.truckPosition }
                                                    <span className="date-updated">
                                                        ({ formatShortDate(new Date(locationIssue.metaData.truckUpdated)) })
                                                    </span>
                                                </p>
                                            </div>
                                            <div>
                                                <p className="info-label">
                                                    Last known trailer position:
                                                </p>
                                                <p className={ this.state.lastKnownLocationClassName }>
                                                    { locationIssue.metaData.trailerPosition }
                                                    <span className="date-updated">
                                                        ({ formatShortDate(new Date(locationIssue.metaData.trailerUpdated)) })
                                                    </span>
                                                </p>
                                            </div>
                                        </> :
                                        <div>
                                            <p className="info-label">
                                                Last known position:
                                            </p>
                                            <p className={ this.state.lastKnownLocationClassName }>
                                                { this.state.location }
                                            </p>
                                        </div>
                                    }
                                </div>
                            </SimpleContainer>
                        </div>
                        { detachedIssue ?
                            <div className="col-12 detached-issue-warning">
                                <FontAwesomeIcon className="orangeColorText" icon={ faExclamationTriangle }/>
                                <span>
                                    This trailer seems to be detached from the truck and is in danger of being stolen.
                                </span>
                            </div> : missingIssue &&
                            <div className="col-12 missing-issue-warning">
                                <FontAwesomeIcon className="redColorText" icon={ faExclamationCircle }/>
                                <span>
                                    This trailer seems to be detached from the truck and did not report in the last 24 hours. It might be missing or stolen!
                                </span>
                            </div>
                        }

                        <CanAccess
                            action={ PERMISSION.PAGE.TRAILER.SETTINGS }
                            yes={
                                <div className="col-12 col-lg-12">
                                    <SimpleContainer title="TRAILER SETTINGS" className="trailer-settings">
                                        <TrailerSettings currentInfo={currentTrailerInfo} activeTrailer={this.state.isActiveTrailer}></TrailerSettings>
                                    </SimpleContainer>
                                </div>
                            }
                        />

                        <CanAccess
                            action={ PERMISSION.PAGE.TRAILER.ISSUES }
                            yes={
                                (this.state.resolvedIssues.length > 0 || this.state.unresolvedIssues.length > 0) && (
                                    <div className="col-12 col-lg-12">
                                        <SimpleContainer title="TRAILER ISSUES">
                                            <IssueTable resolvedIssues={this.state.resolvedIssues} unresolvedIssues={this.state.unresolvedIssues} account={this.state.account} fetchIssues={this.fetchIssues.bind(this)}></IssueTable>
                                        </SimpleContainer>
                                    </div>
                                )
                            }
                        />

                        <CanAccess
                            action={ PERMISSION.PAGE.TRAILER.LOCATION }
                            yes={
                                <div className="col-12">
                                    <SimpleContainer title="TRAILER LOCATION">
                                        <TrailerLocation trailerId={ this.props.match.params.trailerId } />
                                    </SimpleContainer>
                                </div>
                            }
                        />

                        <CanAccess
                            action={ PERMISSION.PAGE.TRAILER.MEASUREMENTS }
                            yes={
                                <div className="col-12">
                                    <SimpleContainer title="MEASUREMENT HISTORY">
                                        <MeasurementsFilter loading={ this.state.measurementsLoading }
                                                            value={ this.state.measurementsFilter }
                                                            onChange={ this.onMeasurementsFilterChange } />
                                        <MeasurementsTable response={ this.state.measurementsResponse }
                                                           fetchData={ this.fetchMeasurements } />
                                    </SimpleContainer>
                                </div>
                            }
                        />

                        <CanAccess
                            action={ PERMISSION.PAGE.TRAILER.ALARMS }
                            yes={
                                    <>
                                        { this.state.activeAlarmIssues && this.state.activeAlarmIssues.length > 0 && <div className="col-12 col-lg-12">
                                            <SimpleContainer title="ACTIVE ALARM ISSUES">
                                                <AlarmIssueTable tableName={"activeAlarmIssues"} alarmIssues={this.state.activeAlarmIssues} account={this.state.account} fetchIssues={this.fetchAlarmIssues.bind(this)}></AlarmIssueTable>
                                            </SimpleContainer>
                                        </div> }
                                        { this.state.previousAlarmIssues && this.state.previousAlarmIssues.length > 0 && <div className="col-12 col-lg-12">
                                            <SimpleContainer title="PREVIOUS ALARM ISSUES">
                                                <AlarmIssueTable tableName={"previousAlarmIssues"} alarmIssues={this.state.previousAlarmIssues} account={this.state.account} fetchIssues={this.fetchAlarmIssues.bind(this)}></AlarmIssueTable>
                                            </SimpleContainer>
                                        </div> }
                                    </>
                            }
                        />

                        <CanAccess
                            action={ PERMISSION.PAGE.TRAILER.INACTIVE_IN_YARD }
                            yes={
                                (this.state.previousInactiveInYardIssues.length > 0 || this.state.activeInactiveInYardIssue.length > 0) && (
                                    <div className="col-12 col-lg-12">
                                        <SimpleContainer title="INACTIVE IN YARD">
                                            <InactiveInYardTable
                                                resolvedIssues={this.state.previousInactiveInYardIssues}
                                                unresolvedIssue={this.state.activeInactiveInYardIssue}
                                                fetchIssues={this.fetchInactiveInYardIssues}
                                            />
                                        </SimpleContainer>
                                    </div>
                                )
                            }
                        />

                        <CanAccess
                            action={ PERMISSION.PAGE.TRAILER.COMMANDS }
                            yes={
                                <div className="col-12 col-lg-12">
                                    <SimpleContainer title="LAST 10 TRAILER COMMANDS">
                                        { (this.state.commands || []).length > 0 ?
                                            <CommandsTable data={ this.state.commands } /> :
                                            <p className="info-label trailer-settings">No commands submitted for this trailer yet.</p>
                                        }
                                    </SimpleContainer>
                                </div>
                            }
                        />
                    </div>
                </div>
        );
    }
};

export default withRouter(withAITracking(reactPlugin, DetailView, "TrailerDetailView"));

