import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TEAMS } from 'global/constants'
import TeamIcon from 'components/team-icon/TeamIcon'

class UserInfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  getRoute(team) {
    team = team === "NO TEAM" ? "NONE" : team;
    return {
      pathname: "/statistics/team/" + team,
      state: {
        team: team 
      }
    };
  }

  render() {
      let colorStyle= {
          //borderTop: `1px solid black`
      };
      return (
        <div className="team-card info-card" style={colorStyle}>
          <div className="issue-count-heading row equal is-flex  grid-divider"> 
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              Seen issues
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              Snoozed issues
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
              Closed issues
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 nowrap-always right-align">
              Teams
            </div>
          </div>
          <div className="issue-count large-font row grid-divider small-screen-font"> 
          <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 flex">
              <span>{this.props.seenCount || 0} </span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 flex">
              <span>{this.props.snoozeCount || 0}</span>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 flex">
              <span>{this.props.closeCount || 0}</span>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 right-align">
              {(this.props.teams || []).map( team => <div key={team} className="team-info">
                  <Link className="team-text with-margin no-style-link" to={this.getRoute(team)}>
                    <span className="team-icon" ><TeamIcon color={team} size={15}></TeamIcon></span>
                    {team && (TEAMS.find(tm => tm.name === team) || {label: "No team"}).label}
                  </Link>
                </div>)
                }
              
            </div>
          </div>
        </div>
    );
  }
}
export default UserInfoCard;