import React, { Component } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import AuthorizationService from 'global/services/AuthorizationService';
import { assignmentStateSearchUrl, legSearchUrl } from 'global/constants';
import history from 'global/history';
import { PERMISSION } from 'global/utils/auth';
import AuthContext from 'AuthContext';

import './SearchBar.scss';

export default class SearchBar extends Component {
    static contextType = AuthContext;

    static placeholders = {
        alarmIssues: "Search for trailer or alarm code",
        legs: "Search for order, trailer, driver",
        trailers: "Search for trailer",
    };

    constructor(props, context) {
        super(props, context);

        const hasAlarmSearch = AuthorizationService.canAccess(context.permissions, PERMISSION.ISSUE.ALARM.SEARCH);
        const hasOrderSearch = AuthorizationService.canAccess(context.permissions, PERMISSION.ASSIGNMENT.READ);
        const hasTrailerSearch = AuthorizationService.canAccess(context.permissions, PERMISSION.TRAILER.READ);

        this.options = [];

        if (hasAlarmSearch) {
            this.options.push({ value: 'alarmIssues', label: 'Issues' });
        }

        if (hasOrderSearch) {
            this.options.push({ value: 'legs', label: 'Orders', searchUrl: legSearchUrl});
        }

        if (hasTrailerSearch) {
            this.options.push({ value: 'trailers', label: 'Trailers', searchUrl: assignmentStateSearchUrl });
        }

        this.state = {
            value: "",
            selectedSearchItem: this.options[0]
        };
    }

    onChange = event => {
        event.preventDefault();
        this.setState({ value: event.target.value });
    }

    onClearButtonClick = event => {
        event.preventDefault();
        this.setState({
            value: ""
        });
    }

    onSubmit = event => {
        event.preventDefault();
        if (this.state.value && this.state.selectedSearchItem.value !== "alarmIssues") {
            this.routeChange("/orders/search", { contains: this.state.value, searchBy: this.state.selectedSearchItem.searchUrl });
        } else if (this.state.value) {
            this.routeChange("/issues/alarm", { contains: this.state.value });
        }
    }

    routeChange = (pathname, state) => {
        history.push({
            pathname: pathname,
            state: state,
        });
    };

    render() {
        const customStyles = {
            container: (provided, state) => ({
                ...provided,
                minHeight: 20,
                maxHeight: 32,
                width: 91
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                minHeight: 20,
                maxHeight: 32,
                width: 91,
                position: "absolut"
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                minHeight: 20,
                maxHeight: 32,
                width: 30
            }),
            control: (provided, state) => ({
                ...provided,
                border: '1px solid #D0D2DD',
                boxShadow: 'none',
                '&:hover': {
                    border: '1px solid #D0D2DD',
                },
                minHeight: 20,
                maxHeight: 32,
                width: 91
            }),
            menu: (provided, state) => ({
                ...provided,

            }),
            option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
                ...provided,
                backgroundColor: isFocused || isSelected ? '#F4F5FA' : "white",
                color: "black",
                maxHeight: 32,
                paddingTop: 8,
                paddingLeft: 10,
                paddingBottom: 32,
                height: 32,
                minHeight: 32,
                textAlign: "left"
            })
        };

        return (
            <form onSubmit={ this.onSubmit } className="search-bar">
                <input
                    id="main-search-bar"
                    type="text"
                    data-items="4"
                    value={ this.state.value }
                    placeholder={ SearchBar.placeholders[this.state.selectedSearchItem.value] }
                    onChange={ this.onChange } />
                <div className="search-buttons">
                    { this.state.value !== "" && (
                        <>
                            <button
                                className="btn clear-button"
                                type="button"
                                onClick={ this.onClearButtonClick.bind(this) }
                            >
                                <FontAwesomeIcon icon={ faTimesCircle } />
                            </button>
                            <span className="vertical-line" />
                        </>
                    ) }
                    <button
                        className={ `btn btnTransparent search-button ${ this.state.value !== "" ? "active" : "" }` }
                        type="submit" onClick={ this.onSubmit.bind(this) }
                    >
                        <FontAwesomeIcon icon={ faSearch } />
                    </button>
                    { this.options.length > 1 && (
                        <>
                            <div className="by-div">
                                In:
                            </div>
                            <div className="select-div">
                                <Select
                                    styles={ customStyles }
                                    options={ this.options }
                                    value={ this.state.selectedSearchItem }
                                    onChange={ e => {
                                        this.setState({
                                            selectedSearchItem: e
                                        });
                                    } }
                                />
                            </div>
                        </>
                    ) }
                </div>
            </form>
        );
    }
}
