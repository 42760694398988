import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CanAccess from "components/can-access/CanAccess";
import { PERMISSION } from "global/utils/auth";

import "./StolenProcedure.scss";

class StolenProcedure extends Component {

    render() {
        const location = this.props.detached ? "Detached" : "Missing";

        return (
            <>
                <div className="page stolen-procedure">
                    <nav className="navigation-bar">
                        <ol className="breadcrumb">
                            <CanAccess
                                action={ PERMISSION.PAGE.ALL_ISSUES }
                                yes={
                                    <li className="breadcrumb-item">
                                        <Link to="/issues/all">
                                            All issues
                                        </Link>
                                    </li>
                                }
                                no={
                                    <li className="breadcrumb-item">
                                        <Link to="/">
                                            Fleet overview
                                        </Link>
                                    </li>
                                }
                            />
                            <li className="breadcrumb-item">
                                <Link to={ "/issues/" + location.toLowerCase() }>
                                    { location + " trailers" }
                                </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to={ window.location.pathname }>
                                    SOP on Stolen Truck or Trailer
                                </Link>
                            </li>
                        </ol>
                        <Link to={ "/issues/" + location.toLowerCase() } className="back-navigation">
                            <FontAwesomeIcon style={ { marginRight: 5 } } icon={ faArrowLeft } />
                            BACK TO { location.toUpperCase() } TRAILERS
                        </Link>
                    </nav>
                    <div className="row tutorial-container">
                        <div className="col-12">
                            <p className="heading">
                                SOP on Stolen Truck or Trailer
                            </p>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <table style={ { borderCollapse: "separate", borderSpacing: 10 } }>
                                <tbody>
                                    <tr>
                                        <td className="align-top" colSpan="2">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M30.9119 10.2825C30.8169 10.2038 30.0456 9.5825 29 8.74125V6C29 4.34312 27.6569 3 26 3H21.8669C20.4525 1.80313 18.3606 0 16 0C13.6469 0 11.5687 1.78937 10.1344 3H6C4.34312 3 3 4.34312 3 6V8.74063C1.94938 9.58563 1.17813 10.2069 1.095 10.2756C0.75272 10.557 0.477011 10.9107 0.287682 11.3113C0.0983535 11.7119 0.000106415 12.1494 0 12.5925L0 29C0 30.6569 1.34312 32 3 32H29C30.6569 32 32 30.6569 32 29V12.5944C32 11.7 31.6012 10.8519 30.9119 10.2825ZM6 6H26V15.7913C23.7337 17.6238 21.0819 19.7706 20.5881 20.1631C19.4531 21.07 17.475 23 16 23C14.5194 23 12.5087 21.0394 11.4119 20.1631C10.9181 19.7706 8.26625 17.6231 6 15.79V6ZM29 28.625C29 28.8319 28.8319 29 28.625 29H3.375C3.16812 29 3 28.8319 3 28.625V17.2225C5.435 19.19 8.99687 22.0756 9.54125 22.5088C10.9194 23.61 13.2844 26 16 26C18.7006 26 21.04 23.6425 22.4587 22.5088C23.0212 22.0619 26.5737 19.1844 29 17.2237V28.625ZM11 12H21C21.5525 12 22 11.5525 22 11V10C22 9.4475 21.5525 9 21 9H11C10.4475 9 10 9.4475 10 10V11C10 11.5525 10.4475 12 11 12ZM22 16V15C22 14.4475 21.5525 14 21 14H11C10.4475 14 10 14.4475 10 15V16C10 16.5525 10.4475 17 11 17H21C21.5525 17 22 16.5525 22 16Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td colSpan="4">
                                            <p className="step-text bold"
                                               style={ { paddingBottom: 4 } }>
                                                STEP 1:
                                            </p>
                                            <p className="modal-text">
                                                First report needs to be sent via email, subject line: Stolen (Equipment) and Unit #
                                                <br />
                                                <br />
                                                Example: Stolen Trailer #12345
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="vl-container">
                                            <div style={ { height: 49 } } className="vl" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-top" colSpan="2">
                                            <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.625 11.25H1.125C0.492188 11.25 0 11.7422 0 12.375V16.875C0 17.5078 0.492188 18 1.125 18H5.625C6.25781 18 6.75 17.5078 6.75 16.875V12.375C6.75 11.7422 6.25781 11.25 5.625 11.25ZM5.625 22.5H1.125C0.492188 22.5 0 22.9922 0 23.625V28.125C0 28.7578 0.492188 29.25 1.125 29.25H5.625C6.25781 29.25 6.75 28.7578 6.75 28.125V23.625C6.75 22.9922 6.25781 22.5 5.625 22.5ZM5.625 0H1.125C0.492188 0 0 0.492188 0 1.125V5.625C0 6.25781 0.492188 6.75 1.125 6.75H5.625C6.25781 6.75 6.75 6.25781 6.75 5.625V1.125C6.75 0.492188 6.25781 0 5.625 0ZM34.3125 12.9375H12.9375C12.0234 12.9375 11.25 13.7109 11.25 14.625C11.25 15.5391 12.0234 16.3125 12.9375 16.3125H34.3125C35.2266 16.3125 36 15.5391 36 14.625C36 13.7109 35.2266 12.9375 34.3125 12.9375ZM34.3125 1.6875H12.9375C12.0234 1.6875 11.25 2.46094 11.25 3.375C11.25 4.28906 12.0234 5.0625 12.9375 5.0625H34.3125C35.2266 5.0625 36 4.28906 36 3.375C36 2.46094 35.2266 1.6875 34.3125 1.6875ZM34.3125 24.1875H12.9375C12.0234 24.1875 11.25 24.9609 11.25 25.875C11.25 26.7891 12.0234 27.5625 12.9375 27.5625H34.3125C35.2266 27.5625 36 26.7891 36 25.875C36 24.9609 35.2266 24.1875 34.3125 24.1875Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td colSpan="4">
                                            <p className="step-text bold"
                                               style={ { paddingBottom: 4 } }>
                                                STEP 2:
                                            </p>
                                            <p className="modal-text">
                                                Include all details that you can find out, the who, what, when, where, why.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr />
                                    <tr>
                                        <td className="modal-text bold" colSpan="6">
                                            REQUEST POLICE REPORT ASAP.
                                            <br />
                                            <br />
                                            1 PERSON MUST TAKE OWNERSHIP OF BEING IN CONTACT WITH THE POLICE ONCE THE REPORT IS RECEIVED.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="vll-container">
                                            <div className="vll"/>
                                        </td>
                                        <td className="align-top">
                                            <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M36.3516 11.8828L9.77344 0.140625C9.49219 0.0703125 9.21094 0 8.92969 0C8.15625 0 7.38281 0.421875 7.03125 1.125L0.28125 12.8672C0 13.4297 -0.0703125 14.1328 0.140625 14.7656C0.351562 15.3984 0.84375 15.8906 1.47656 16.1016L13.0078 20.8828L10.0547 28.6875H3.375V26.4375C3.375 25.5234 2.60156 24.75 1.6875 24.75C0.773438 24.75 0 25.5234 0 26.4375V34.3125C0 35.2266 0.773438 36 1.6875 36C2.60156 36 3.375 35.2266 3.375 34.3125V32.0625H11.25C11.9531 32.0625 12.5859 31.6406 12.7969 31.0078L16.1719 22.1484L21.4453 24.3984C21.7266 24.4688 22.0078 24.5391 22.2188 24.5391C22.6406 24.5391 23.0625 24.3984 23.4141 24.1875L36.7031 15.8203C38.3203 14.9062 38.1094 12.5156 36.3516 11.8828ZM22.1484 21.0234L3.86719 13.4297L9.42188 3.65625L33.1172 14.1328L22.1484 21.0234ZM40.4297 19.8984C40.2891 19.6172 40.0781 19.4062 39.7969 19.2656L37.2656 18.1406L27.5625 24.2578L36 27.9141C36.5625 28.1953 37.2656 27.9141 37.5469 27.3516L40.4297 20.8125C40.5 20.5312 40.5 20.1797 40.4297 19.8984Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10 } } colSpan="4">
                                            <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 3A:
                                            </p>
                                            <p className="modal-text">
                                                Ask if the police asked for cameras in the area and if they have asked for those videos.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="vll-container">
                                            <div className="vll"/>
                                        </td>
                                        <td className="align-top">
                                            <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.125 21.375H12.375C5.55469 21.375 0 26.9297 0 33.75C0 35.0156 0.984375 36 2.25 36H29.25C30.5156 36 31.5 35.0156 31.5 33.75C31.5 26.9297 25.9453 21.375 19.125 21.375ZM3.44531 32.625C4.00781 28.1953 7.80469 24.75 12.375 24.75H19.125C23.6953 24.75 27.4922 28.1953 28.0547 32.625H3.44531ZM15.75 18C20.7422 18 24.75 13.9922 24.75 9C24.75 4.00781 20.7422 0 15.75 0C10.7578 0 6.75 4.00781 6.75 9C6.75 13.9922 10.7578 18 15.75 18ZM15.75 3.375C18.8438 3.375 21.375 5.90625 21.375 9C21.375 12.0938 18.8438 14.625 15.75 14.625C12.6562 14.625 10.125 12.0938 10.125 9C10.125 5.90625 12.6562 3.375 15.75 3.375Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10 } } colSpan="4">
                                            <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 3B:
                                            </p>
                                            <p className="modal-text">
                                                Ask them to obtain a detailed statement from the driver to help us ensure the driver wasn’t involved.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="vll-container">
                                            <div className="vll"/>
                                        </td>
                                        <td className="align-top">
                                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.875 0C7.52344 0 0 7.52344 0 16.875C0 26.2266 7.52344 33.75 16.875 33.75C26.2266 33.75 33.75 26.2266 33.75 16.875C33.75 7.52344 26.2266 0 16.875 0ZM16.875 30.375C9.42188 30.375 3.375 24.3281 3.375 16.875C3.375 9.42188 9.42188 3.375 16.875 3.375C24.3281 3.375 30.375 9.42188 30.375 16.875C30.375 24.3281 24.3281 30.375 16.875 30.375ZM19.6875 22.5H18.5625V16.3125C18.5625 15.3984 17.7891 14.625 16.875 14.625H14.625C13.7109 14.625 12.9375 15.3984 12.9375 16.3125C12.9375 17.2266 13.7109 18 14.625 18H15.1875V22.5H14.0625C13.1484 22.5 12.375 23.2734 12.375 24.1875C12.375 25.1016 13.1484 25.875 14.0625 25.875H19.6875C20.6016 25.875 21.375 25.1016 21.375 24.1875C21.375 23.2734 20.6016 22.5 19.6875 22.5ZM16.875 12.375C18.1406 12.375 19.125 11.3906 19.125 10.125C19.125 8.85938 18.1406 7.875 16.875 7.875C15.6094 7.875 14.625 8.85938 14.625 10.125C14.625 11.3906 15.6094 12.375 16.875 12.375Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10 } } colSpan="4">
                                            <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 3C:
                                            </p>
                                            <p className="modal-text">
                                                Ask them if they have any unofficial information or suspicions about what happened.
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <table style={ { borderCollapse: "separate", borderSpacing: 10 } }>
                                <tbody>
                                    <tr>
                                        <td className="align-top">
                                            <svg width="27" height="36" viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.625 18V22.5C5.625 23.7656 6.60938 24.75 7.875 24.75H19.125C20.3906 24.75 21.375 23.7656 21.375 22.5V18C21.375 16.7344 20.3906 15.75 19.125 15.75H7.875C6.60938 15.75 5.625 16.7344 5.625 18ZM25.6641 6.53906L20.4609 1.33594C19.6172 0.492188 18.4219 0 17.2266 0H4.5C2.03906 0 0 2.03906 0 4.5V31.5C0 33.9609 2.03906 36 4.5 36H22.5C24.9609 36 27 33.9609 27 31.5V9.77344C27 8.57812 26.5078 7.38281 25.6641 6.53906ZM23.625 31.5C23.625 32.1328 23.1328 32.625 22.5 32.625H4.5C3.86719 32.625 3.375 32.1328 3.375 31.5V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H15.75V9C15.75 10.2656 16.7344 11.25 18 11.25H23.625V31.5ZM6.75 9H12.375C13.0078 9 13.5 8.50781 13.5 7.875C13.5 7.24219 13.0078 6.75 12.375 6.75H6.75C6.11719 6.75 5.625 7.24219 5.625 7.875C5.625 8.50781 6.11719 9 6.75 9ZM6.75 13.5H12.375C13.0078 13.5 13.5 13.0078 13.5 12.375C13.5 11.7422 13.0078 11.25 12.375 11.25H6.75C6.11719 11.25 5.625 11.7422 5.625 12.375C5.625 13.0078 6.11719 13.5 6.75 13.5ZM20.25 27H14.625C13.9922 27 13.5 27.4922 13.5 28.125C13.5 28.7578 13.9922 29.25 14.625 29.25H20.25C20.8828 29.25 21.375 28.7578 21.375 28.125C21.375 27.4922 20.8828 27 20.25 27Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10 } } colSpan="5">
                                            <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 4:
                                            </p>
                                            <p className="modal-text">
                                                If loaded, request pictures of the Bill of Ladings ASAP.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr />
                                    <tr>
                                        <td className="modal-text bold" colSpan="6">
                                            GO TO KEEPTRUCKIN AND PULL ALL RELEVANT DATA:
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="vll-container">
                                            <div className="vll"/>
                                        </td>
                                        <td className="align-top">
                                            <svg width="27" height="36" viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M25.6641 6.53906L20.4609 1.33594C19.6172 0.492188 18.4219 0 17.2266 0H4.5C2.03906 0 0 2.03906 0 4.5V31.5C0 33.9609 2.03906 36 4.5 36H22.5C24.9609 36 27 33.9609 27 31.5V9.77344C27 8.57812 26.5078 7.38281 25.6641 6.53906ZM23.625 31.5C23.625 32.1328 23.1328 32.625 22.5 32.625H4.5C3.86719 32.625 3.375 32.1328 3.375 31.5V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H15.75V9C15.75 10.2656 16.7344 11.25 18 11.25H23.625V31.5ZM6.75 19.6875C6.75 20.6016 7.52344 21.375 8.4375 21.375H18.5625C19.4766 21.375 20.25 20.6016 20.25 19.6875C20.25 18.7734 19.4766 18 18.5625 18H8.4375C7.52344 18 6.75 18.7734 6.75 19.6875ZM18.5625 24.75H8.4375C7.52344 24.75 6.75 25.5234 6.75 26.4375C6.75 27.3516 7.52344 28.125 8.4375 28.125H18.5625C19.4766 28.125 20.25 27.3516 20.25 26.4375C20.25 25.5234 19.4766 24.75 18.5625 24.75Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10 } } colSpan="4">
                                            <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 5A:
                                            </p>
                                            <p className="modal-text">
                                                Last DVIR Inspection.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="vll-container">
                                            <div className="vll"/>
                                        </td>
                                        <td className="align-top">
                                            <svg width="27" height="36" viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M25.6641 6.53906L20.4609 1.33594C19.6172 0.492188 18.4219 0 17.2266 0H4.5C2.03906 0 0 2.03906 0 4.5V31.5C0 33.9609 2.03906 36 4.5 36H22.5C24.9609 36 27 33.9609 27 31.5V9.77344C27 8.57812 26.5078 7.38281 25.6641 6.53906ZM23.625 31.5C23.625 32.1328 23.1328 32.625 22.5 32.625H4.5C3.86719 32.625 3.375 32.1328 3.375 31.5V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H15.75V9C15.75 10.2656 16.7344 11.25 18 11.25H23.625V31.5ZM16.875 20.25C16.875 18.9844 15.8906 18 14.625 18H7.875C6.60938 18 5.625 18.9844 5.625 20.25V27C5.625 28.2656 6.60938 29.25 7.875 29.25H14.625C15.8906 29.25 16.875 28.2656 16.875 27V25.8047L19.9688 27.9844C20.5312 28.4062 21.375 27.9844 21.375 27.2109V20.0391C21.375 19.2656 20.5312 18.8438 19.9688 19.2656L16.875 21.4453V20.25Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10, fontWeight: 600 } } colSpan="4">
                                            <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 5B:
                                            </p>
                                            <p className="modal-text">
                                                Request Camera footage ASAP (this is time sensitive) for (as applicable):
                                                <br />
                                                <br />
                                                <ul>
                                                    <li>
                                                        Footage of Time of DVIR
                                                    </li>
                                                    <li>
                                                        Footage of time of reported problem
                                                    </li>
                                                    <li>
                                                        Footage of Last Alert Event (Speeding, Braking, etc)
                                                    </li>
                                                    <li>
                                                        The entire Footage (Before During and After event):
                                                    </li>
                                                </ul>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr />
                                    <tr>
                                        <td className="modal-text bold" colSpan="6">
                                            GO TO TMW AND PULL ALL RELEVANT DATA:
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="vll-container">
                                            <div className="vll"/>
                                        </td>
                                        <td className="align-top">
                                            <svg width="45" height="32" viewBox="0 0 45 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M43.3125 21.375H40.5V4.5C40.5 2.03906 38.4609 0 36 0H4.5C2.03906 0 0 2.03906 0 4.5V20.25C0 22.7109 2.03906 24.75 4.5 24.75H6.75C6.75 28.4766 9.77344 31.5 13.5 31.5C17.2266 31.5 20.25 28.4766 20.25 24.75H43.3125C44.2266 24.75 45 23.9766 45 23.0625C45 22.1484 44.2266 21.375 43.3125 21.375ZM13.5 28.125C11.6719 28.125 10.125 26.5781 10.125 24.75C10.125 22.9219 11.6719 21.375 13.5 21.375C15.3281 21.375 16.875 22.9219 16.875 24.75C16.875 26.5781 15.3281 28.125 13.5 28.125ZM37.125 21.375H19.3359C18.1406 19.3359 15.9609 18 13.5 18C11.0391 18 8.85938 19.3359 7.66406 21.375H4.5C3.86719 21.375 3.375 20.8828 3.375 20.25V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H36C36.6328 3.375 37.125 3.86719 37.125 4.5V21.375ZM23.625 5.625C22.7109 5.625 21.9375 6.39844 21.9375 7.3125V17.4375C21.9375 18.3516 22.7109 19.125 23.625 19.125C24.5391 19.125 25.3125 18.3516 25.3125 17.4375V7.3125C25.3125 6.39844 24.5391 5.625 23.625 5.625ZM16.875 5.625C15.9609 5.625 15.1875 6.39844 15.1875 7.3125V14.0625C15.1875 14.9766 15.9609 15.75 16.875 15.75C17.7891 15.75 18.5625 14.9766 18.5625 14.0625V7.3125C18.5625 6.39844 17.7891 5.625 16.875 5.625ZM10.125 5.625C9.21094 5.625 8.4375 6.39844 8.4375 7.3125V14.0625C8.4375 14.9766 9.21094 15.75 10.125 15.75C11.0391 15.75 11.8125 14.9766 11.8125 14.0625V7.3125C11.8125 6.39844 11.0391 5.625 10.125 5.625ZM30.375 5.625C29.4609 5.625 28.6875 6.39844 28.6875 7.3125V17.4375C28.6875 18.3516 29.4609 19.125 30.375 19.125C31.2891 19.125 32.0625 18.3516 32.0625 17.4375V7.3125C32.0625 6.39844 31.2891 5.625 30.375 5.625Z"  fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10 } } colSpan="4">
                                            <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 6A:
                                            </p>
                                            <p className="modal-text">
                                                If loaded, Order # & all shipment cargo details.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="vll-container">
                                            <div className="vll"/>
                                        </td>
                                        <td className="align-top">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M28 0H4C1.79375 0 0 1.79375 0 4V22C0 24.2062 1.79375 26 4 26H10V31.25C10 31.6938 10.3625 32 10.75 32C10.9 32 11.0563 31.9563 11.1938 31.85L19 26H28C30.2062 26 32 24.2062 32 22V4C32 1.79375 30.2062 0 28 0ZM29 22C29 22.55 28.55 23 28 23H18L17.2 23.6L13 26.75V23H4C3.45 23 3 22.55 3 22V4C3 3.45 3.45 3 4 3H28C28.55 3 29 3.45 29 4V22ZM23 8.5H9C8.45 8.5 8 8.95 8 9.5V10.5C8 11.05 8.45 11.5 9 11.5H23C23.55 11.5 24 11.05 24 10.5V9.5C24 8.95 23.55 8.5 23 8.5ZM17 14.5H9C8.45 14.5 8 14.95 8 15.5V16.5C8 17.05 8.45 17.5 9 17.5H17C17.55 17.5 18 17.05 18 16.5V15.5C18 14.95 17.55 14.5 17 14.5Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10 } } colSpan="4">
                                            <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 6B:
                                            </p>
                                            <p style={ { fontWeight: 600 } } className="modal-text">
                                                If empty, inform the DM to push PTA.
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <table style={ { borderCollapse: "separate", borderSpacing: 10 } }>
                                <tbody>
                                    <tr>
                                        <td className="modal-text bold" colSpan="6">
                                            CALL SURROUNDING BUSINESS AND REQUEST VIDEO FOOTAGE AND ADVISE THEM OF THE THEFT.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="vll-container">
                                            <div className="vll"/>
                                        </td>
                                        <td className="align-top">
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10 } } colSpan="4">
                                            <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 7:
                                            </p>
                                            <p className="modal-text">
                                                Request any videos are sent and/or preserved if police have not done so already.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr />
                                    <tr>
                                        <td className="align-top">
                                            <svg width="27" height="36" viewBox="0 0 27 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.5 4.5H18.9844C18.4922 1.89844 16.2422 0 13.5 0C10.7578 0 8.50781 1.89844 8.01562 4.5H4.5C2.03906 4.5 0 6.53906 0 9V31.5C0 33.9609 2.03906 36 4.5 36H22.5C24.9609 36 27 33.9609 27 31.5V9C27 6.53906 24.9609 4.5 22.5 4.5ZM13.5 3.375C14.4141 3.375 15.1875 4.14844 15.1875 5.0625C15.1875 5.97656 14.4141 6.75 13.5 6.75C12.5859 6.75 11.8125 5.97656 11.8125 5.0625C11.8125 4.14844 12.5859 3.375 13.5 3.375ZM23.625 31.5C23.625 32.1328 23.1328 32.625 22.5 32.625H4.5C3.86719 32.625 3.375 32.1328 3.375 31.5V9C3.375 8.36719 3.86719 7.875 4.5 7.875H5.76562C5.69531 8.22656 5.625 8.57812 5.625 9V10.125C5.625 10.7578 6.11719 11.25 6.75 11.25H20.25C20.8828 11.25 21.375 10.7578 21.375 10.125V9C21.375 8.57812 21.3047 8.22656 21.2344 7.875H22.5C23.1328 7.875 23.625 8.36719 23.625 9V31.5Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10 } } colSpan="5">
                                            <p className="modal-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 8:
                                            </p>
                                            <p className="modal-text">
                                                Create timeline of all events and include phone recordings, snippets etc.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="vl-container">
                                            <div style={ { height: 15, left: 18 } } className="vl" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-top">
                                            <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.125 21.375H12.375C5.55469 21.375 0 26.9297 0 33.75C0 35.0156 0.984375 36 2.25 36H29.25C30.5156 36 31.5 35.0156 31.5 33.75C31.5 26.9297 25.9453 21.375 19.125 21.375ZM3.44531 32.625C4.00781 28.1953 7.80469 24.75 12.375 24.75H19.125C23.6953 24.75 27.4922 28.1953 28.0547 32.625H3.44531ZM15.75 18C20.7422 18 24.75 13.9922 24.75 9C24.75 4.00781 20.7422 0 15.75 0C10.7578 0 6.75 4.00781 6.75 9C6.75 13.9922 10.7578 18 15.75 18ZM15.75 3.375C18.8438 3.375 21.375 5.90625 21.375 9C21.375 12.0938 18.8438 14.625 15.75 14.625C12.6562 14.625 10.125 12.0938 10.125 9C10.125 5.90625 12.6562 3.375 15.75 3.375Z" fill="#828A95"/>
                                            </svg>
                                        </td>
                                        <td style={ { paddingLeft: 10 } } colSpan="5">
                                            <p className="step-text bold" style={ { paddingBottom: 4 } }>
                                                STEP 9:
                                            </p>
                                            <p className="modal-text">
                                                When driver returns to terminal, they should be interviewed about specifics of how it happened. Check the GPS locations etc. and make sure their story matches the information we have. Go through the timeline.
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default StolenProcedure;
