import React, { Component } from 'react';

import CanAccess from 'components/can-access/CanAccess';
import SearchBar from 'components/input/SearchBar';
import TeamSelection from 'components/input/TeamSelection';
import AccountWidget from 'components/navigation/AccountWidget';
import { PERMISSION } from 'global/utils/auth';
import AuthContext from 'AuthContext';

import './Header.scss';

export default class Header extends Component {
    static contextType = AuthContext;

    render() {
        return (
            <header className="header container-fluid">
                <div className="row justify-content-end">
                    <div className="search col-12 col-md-12 col-lg-6">
                        <CanAccess
                            action={ [
                                PERMISSION.ISSUE.ALARM.SEARCH,
                                PERMISSION.ASSIGNMENT.READ,
                                PERMISSION.TRAILER.READ
                            ] }
                            yes={ <SearchBar /> }
                        />
                    </div>
                    <div className="user-settings col-10 col-md-10 col-lg-5">
                        <CanAccess
                            action={ PERMISSION.TEAM.SELECT }
                            yes={ <TeamSelection team={ this.context.team } setTeam={ this.context.setTeam } /> }
                        />
                    </div>
                    <div className="user-settings col-2 col-md-2 col-lg-1">
                        <AccountWidget />
                    </div>
                </div>
            </header>
        );
    }
}
