import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import DataTableComp from 'components/datatable/DataTableComp';
import { formatDate } from 'global/services/DateTimeService';
import { formatPower, formatTemperature, mapSort, SortParam, UNIT_MODE } from 'global/constants';
import StringUtils from 'global/utils/StringUtils';
import AuthContext from 'AuthContext';

import './DetailView.scss';

const noDataString = '-';

export default class MeasurementsTable extends Component {
    static contextType = AuthContext;

    static propTypes = {
        response: PropTypes.object,
        fetchData: PropTypes.func
    };

    static defaultProps = {
        response: {
            data: [],
            pagingInfo: {
                currentPage: 1
            }
        },
        fetchData: () => null
    };

    constructor(props, context) {
        super(props, context);

        this.columnNames = {
            11: SortParam.TIME
        };

        this.columns = [
            { title: 'Order', data: 'orderId' },
            { title: 'Mode', data: 'expectedUnitMode' },
            { title: 'Mode R', data: 'unitMode' },
            { title: 'Min. temp', data: 'minTemp' },
            { title: 'Max. temp', data: 'maxTemp' },
            { title: 'Set point', data: 'setPoint' },
            { title: 'Return air', data: 'returnAir' },
            { title: 'Op mode', data: 'opMode' },
            { title: 'Power', data: 'expectedPower' },
            { title: 'Power R', data: 'power' },
            { title: 'Alarms', data: 'alarms' },
            { title: 'Date and time', data: 'created' }
        ];

        this.columnDefs = [
            { width: '5%', targets: [ 3, 4, 5, 6, 8, 9 ] },
            { width: '11%', targets: [ 0, 1, 2, 7, 10 ] },
            { width: '15%', targets: [ 11 ] },
            { className: 'dt-align-right', targets: [ 11 ] },
            {
                orderable: false,
                targets: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            }
        ];

        this.state = {
            sort: [],
            page: 1
        };

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleFirstPage = this.handleFirstPage.bind(this);
        this.handleLastPage = this.handleLastPage.bind(this);
        this.handleRecordsNumberChange = this.handleRecordsNumberChange.bind(this);
        this.addSortingForColumn = this.addSortingForColumn.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.response !== this.props.response) {
            this.setState({ page: this.props.response.pagingInfo.currentPage });
        }
    }

    handlePage(page) {
        this.setState({ page }, () => this.props.fetchData(this.state.page, mapSort(this.state.sort)));
    }

    handlePreviousPage() {
        this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage() {
        this.handlePage(parseInt(this.state.page) + 1);
    }

    handleFirstPage() {
        this.handlePage(1);
    }

    handleLastPage() {
        this.handlePage(parseInt(this.props.response.pagingInfo.totalPageNumber));
    }

    handlePageChange(event) {
        const value = event.target.value;

        if (isNaN(value) || value === '') {
            this.setState({
                page: ''
            });
        } else {
            this.setState(
                { page: parseInt(value) },
                () => this.props.fetchData(this.state.page, mapSort(this.state.sort))
            );
        }
    }

    handleRecordsNumberChange(event) {
        this.setState({
            pagingInfo: {
                ...this.props.response.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1
        }, () => this.props.fetchData(this.state.page, mapSort(this.state.sort)));
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const existing = sortingArray.findIndex(x => x && x.columnName === this.columnNames[index]);

        if (existing === -1) {
            sortingArray.push({
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            });
        } else if (sorting === '') {
            sortingArray.splice(existing, 1);
        } else {
            sortingArray[existing] = {
                columnIndex: index,
                columnName: this.columnNames[index],
                direction: sorting
            };
        }

        this.setState({ sort: sortingArray }, () => this.props.fetchData(this.state.page, mapSort(this.state.sort)));
    }

    render() {
        const data = this.props.response.data.map(measurement => {
            const { time, assignmentState, modeState, temperatureState, powerState, alarmState } = measurement;

            return {
                orderId: (assignmentState.assignment || {}).businessId || 'No order',
                expectedUnitMode: UNIT_MODE[modeState.expectedUnitMode] || noDataString,
                unitMode: UNIT_MODE[modeState.unitMode] || noDataString,
                minTemp: formatTemperature(temperatureState.temperatureMin, noDataString),
                maxTemp: formatTemperature(temperatureState.temperatureMax, noDataString),
                setPoint: formatTemperature(temperatureState.setTemperature, noDataString),
                returnAir: formatTemperature(temperatureState.actualTemperature, noDataString),
                opMode: temperatureState.opMode ? StringUtils.snakeCaseToCapitalized(temperatureState.opMode) : noDataString,
                expectedPower: formatPower(powerState.expectedPower, noDataString),
                power: formatPower(powerState.power, noDataString),
                alarms: alarmState.alarms.length > 0 ? alarmState.alarms.join(', ') : noDataString,
                created: formatDate(new Date(time)),
                onClickState: { assignmentId: (assignmentState.assignment || {}).id }
            };
        });

        return (
            <div className="detail-tables">
                <DataTableComp tableId="detailMeasurements"
                               columns={ this.columns }
                               columnDefs={ this.columnDefs }
                               tableHeight="370px"
                               data={ data }
                               handlePreviousPage={ this.handlePreviousPage }
                               handleNextPage={ this.handleNextPage }
                               handleFirstPage={ this.handleFirstPage }
                               handleLastPage={ this.handleLastPage }
                               handlePageChange={ this.handlePageChange }
                               handleRecordsNumber={ this.handleRecordsNumberChange }
                               pagingInfo={ this.props.response.pagingInfo }
                               sortRule={ this.state.sort.concat([]) }
                               addSortingForColumn={ this.addSortingForColumn } />
            </div>
        );
    }
}
