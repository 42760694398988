import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './NavBar.scss';

export default class NavItem extends Component {

    render() {
        const icon = this.props.icon ? <FontAwesomeIcon icon={this.props.icon} /> : <></>;
        const title = this.props.title ? <p>{this.props.title}</p> : <></>;

        return (
            <div className="nav-item">
                {icon}
                {title}
            </div>
        );
    }
}
