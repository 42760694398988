import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './Chip.scss';

export default class Chip extends Component {
    render() {
        return (
            <div className="chip" onClick={ this.props.onClick }>
                { this.props.icon && <FontAwesomeIcon icon={this.props.icon} className="category-icon" /> }
                { this.props.children }
            </div>
        );
    }
}
