import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./NotFoundPage.scss"

class NotFoundPage extends Component{
    render(){
        return <div className="not-found">
            <div className="container">
              <div className="header">
                404 - Page Not Found
              </div>
              <div className="description">
                The page you are looking for does not exist or it is temporarily unavailable.
              </div>
              <Link to="/" className="btn btn-primary">BACK TO HOME</Link>
            </div>
          </div>;
    }
}
export default NotFoundPage;