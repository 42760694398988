import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

import { defaultListColumnsNumber } from 'global/constants';
import ArrayUtils from 'global/utils/ArrayUtils';

import './GridList.scss';

export default class GridList extends Component {

    static defaultProps = {
        data: [],
        columnNumber: defaultListColumnsNumber,
        className: '',
        rowClassName: '',
        loading: false
    }

    state = {
        sortingRules: this.props.sort || []
    };

    componentDidUpdate(prevProps) {
        if (prevProps.sort !== this.props.sort) {
            this.setState({
                sortingRules: this.props.sort || []
            });
        }
    }

    onSortClick(columnName) {
        if (!columnName) {
            return;
        }

        const existingSortRule = this.state.sortingRules.find(rule => rule.columnName === columnName);

        if (existingSortRule) {
            if (existingSortRule.direction === 'ASC') {
                this.setState(prevState => {
                    const sortingRules = prevState.sortingRules;
                    const index = sortingRules.findIndex(rule => rule.columnName === columnName);

                    if (index !== -1) {
                        sortingRules[index] = { columnName: sortingRules[index].columnName, direction: 'DESC' };
                        return { sortingRules };
                    }
                }, () => this.props.onSort(this.state.sortingRules));
            } else {
                this.setState(prevState => ({
                    sortingRules: prevState.sortingRules.filter(rule => rule.columnName !== columnName)
                }), () => this.props.onSort(this.state.sortingRules));
            }
        } else {
            this.setState(prevState => ({
                sortingRules: [...prevState.sortingRules, { columnName, direction: 'ASC' }]
            }), () => this.props.onSort(this.state.sortingRules));
        }
    }

    getSortClassName(columnName, isAsc) {
        let className = isAsc ? 'asc' : 'desc';

        const existingSortRule = this.state.sortingRules.find(rule => rule.columnName === columnName);

        if (existingSortRule && ((existingSortRule.direction === 'ASC' && isAsc) || (existingSortRule.direction === 'DESC' && !isAsc))) {
            className += ' active';
        }

        return className;
    }

    getTableData(data, columnNumber, rowClassName, loading) {
        if (data.length > 0) {
            const mappedData = ArrayUtils.arrayToMatrix(data, columnNumber);

            // Add empty columns to last row if needed
            const emptyColumnsLength = columnNumber - (data.length % columnNumber)
            if (emptyColumnsLength !== columnNumber) {
                const emptyColumns = Array(emptyColumnsLength).fill(<></>);
                mappedData[mappedData.length - 1].push(...emptyColumns);
            }

            return (
                mappedData.map((row, rowIndex) => (
                    <div key={ 'row-' + rowIndex } className={ `data-row row ${rowClassName}` }>
                        { row.map((col, colIndex) => (
                            <div key={ 'col-' + colIndex } className={ 'cell col' }>
                                { col }
                            </div>
                        )) }
                    </div>
                ))
            );
        } else {
            return (
                <div className={ `data-row row ${rowClassName}` }>
                    <div className='cell col text-center'>
                        { loading ?
                            <Loader
                                className='d-flex justify-content-center'
                                type='TailSpin'
                                color='#289AC2'
                                height={ 16 }
                                width={ 16 }
                            /> :
                            'No items'
                        }
                    </div>
                </div>
            );
        }
    }

    render() {
        const { data, columnNumber, header, className, rowClassName, loading } = this.props;

        return (
            <div className={ `grid-list ${className}` }>
                <div className='header-row row'>
                    { (!loading || data.length > 0) && header && (
                        <div
                            className={ `cell ${header.sort ? 'clickable' : '' }` }
                            onClick={ () => this.onSortClick(header.sort) }
                        >
                            { header.title }
                            { header.sort &&
                                <div className='sort-icon'>
                                    <FontAwesomeIcon
                                        icon={ faSortUp }
                                        className={ this.getSortClassName(header.sort, true) }
                                    />
                                    <FontAwesomeIcon
                                        icon={ faSortDown }
                                        className={ this.getSortClassName(header.sort, false) }
                                    />
                                </div>
                            }
                        </div>
                    ) }
                </div>
                { this.getTableData(data, columnNumber, rowClassName, loading) }
            </div>
        );
    }
}
