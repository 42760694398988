export default class ObjectUtils {

    static getValuesByKey(obj, keyToFind) {
        return Object.entries(obj).reduce((acc, [key, value]) => {
            if (key === keyToFind) {
                return acc.concat(value);
            } else {
                if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
                    return acc.concat(ObjectUtils.getValuesByKey(value, keyToFind));
                } else {
                    return acc;
                }
            }
        }, []);
    }

    static getValues(obj) {
        if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
            return Object.values(obj).map(ObjectUtils.getValues).flat();
        } else {
            return [obj];
        }
    }
}
