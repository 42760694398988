import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TeamIcon from 'components/team-icon/TeamIcon'
import { TEAMS } from "global/constants"
import { mapDivisionToColor } from 'global/constants'
import { minsToHours } from 'global/services/DateTimeService';

class TeamCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hovered: false
        }
        this.onHoverChange = this.onHoverChange.bind(this);
    }

    onHoverChange() {
        this.setState({
            hovered: !this.state.hovered
        })
    }

    render() {
        let colorStyle;
        if (this.state.hovered) {
            colorStyle = {
                borderTop: `4px solid ${mapDivisionToColor(this.props.team)}`,
                borderLeft: `1px solid ${mapDivisionToColor(this.props.team)}`,
                borderRight: `1px solid ${mapDivisionToColor(this.props.team)}`,
                borderBottom: `1px solid ${mapDivisionToColor(this.props.team)}`,
                borderRadius: '4px',
                boxSizing: 'border-box',
                boxShadow: '0px 4px 8px #E2E5E9'

            };
        } else {
            colorStyle = {
                borderTop: `4px solid ${mapDivisionToColor(this.props.team)}`
            };
        }

        return (
            <Link className="no-style-link"
                  to={ { pathname: `/statistics/team/${ this.props.team }`, state: { team: this.props.team } } }>
                <div className="team-card" onMouseEnter={this.onHoverChange} onMouseLeave={this.onHoverChange} style={colorStyle}>
                    <div className="heading">
                        <TeamIcon color={this.props.team} size={14}></TeamIcon>
                        <span className="text">
                            {TEAMS.find(team => team.name === this.props.team).label}
                        </span>
                    </div>
                    <div className="issue-count-heading row equal">
                        <div className="col-4">
                            Issues
                        </div>
                        <div className="col-4">
                            Resolved issues
                        </div>
                        <div className="col-4">
                            Avg. resolution time
                        </div>
                    </div>
                    <div className="issue-count row">
                        <div className="col-4">
                            <span>{this.props.issueCount || 0}</span>
                        </div>
                        <div className="col-4">
                            <span>{this.props.resolvedIssueCount || 0}</span>
                        </div>
                        <div className="col-4 nowrap-always">
                            <span className="team-time">{minsToHours(this.props.avgResolutionTime || 0)}</span>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default TeamCard;