import React from "react";
import {
    faTrailer as unaccountedTrailerIcon,
    faRoadCircleCheck as dispatchIcon,
    faTruckRampBox as dropYardIcon,
    faSquareParking as yardIcon,
    faHouse as homeTimeIcon,
    faTruckFlatbed as onewayIcon,
    faWrench as repairsIcon,
    faLinkSlash as mismatchIcon,
    faSignalBarsSlash as silentIcon,
    faPowerOff as inactiveIcon,
    faFileCircleExclamation as claimIcon
} from '@fortawesome/pro-solid-svg-icons';
import {
    formatDate,
    getDateWithOffset,
    getDifferenceInDays,
    getFullDifferenceBetweenDates
} from "global/services/DateTimeService";
import { ISSUE_TYPE } from "global/services/IssueTypeService";
import { formatPower } from 'global/constants';
import Chip from "components/Chip";
import DataTableLink from 'components/datatable/DataTableLink';

export function mapTrailersFromResponse(data, params, category, title) {
    return data.map(entity => {
        const state = entity.state;

        let lastDepartureDate;
        if (state.lastDepartureDate) {
            lastDepartureDate = formatDate(getDateWithOffset(state.lastDepartureDate, state.lastDepartureDateOffset), true);
        } else {
            lastDepartureDate = "Unknown";
        }

        let onClickState = {
            trailerId: state.trailer.id
        };

        if (state.leg) {
            onClickState = {
                ...onClickState,
                assignmentId: state.leg.assignment.id
            };
        }
        let trailerStationarySince;
        if (state.trailerStationarySince && !isNaN(Date.parse(state.trailerStationarySince))) {
            trailerStationarySince = getFullDifferenceBetweenDates(state.trailerStationarySince, state.trailerReport.time);
        } else {
            trailerStationarySince = '-';
        }

        return {
            id: state.id,
            trailerId: state.trailer.businessId,
            orderId: ((state.leg || {}).assignment || {}).businessId || "Unknown",
            driver: ((state.driver || {}).contactInfo || {}).name || "Unknown",
            division: (state.division || {}).businessId || "UNK",
            lastKnownAddress: state.lastKnownAddress || "Unknown",
            lastDepartureDate,
            fuelLevel: state.fuelLevel ? (state.fuelLevel + "%") : "-",
            stopCompanyId: state.stopCompanyId ? state.stopCompanyId : "Unknown",
            companyName: state.yard ? state.yard.company.name : "Unknown",
            trailerStationarySince,
            companyId: state.company ? state.company.businessId : "Unknown",
            dropYard: getDropYard(state),
            atYardSince: state.atYardSince ? getDifferenceInDays(new Date(state.atYardSince), new Date()) : '-',
            expirations: state.expirations || '-',
            earliestExpirationDate: state.earliestExpirationDate ? formatDate(new Date(state.earliestExpirationDate)) : '-',
            homeTimeStartDate: state.homeTimeStartDate ? formatDate(new Date(state.homeTimeStartDate)) : '-',
            homeTimeEndDate: state.homeTimeEndDate ? formatDate(new Date(state.homeTimeEndDate)) : "-",
            legType2: state.legType2 || "-",
            categories: getCategories(state),
            reportCreated: state.trailerReport.time,
            temporary: state.trailerReport.temporary,
            hasComments: entity.hasComments,
            power: formatPower(state.powerState),
            onClickState,
            params,
            category,
            title
        }
    });
}

function getCategories(data) {
    let categories = [];
    if (data.dispatch) {
        categories.push({ name: "Dispatched Trailer", icon: dispatchIcon, route: "dispatch" });
    }
    if (data.homeTime) {
        categories.push({ name: "Driver on Home Time", icon: homeTimeIcon, route: "home-time" });
    }
    if (data.oneway) {
        categories.push({name: "Trailer on oneway trip", icon: onewayIcon, route: "oneway" });
    }
    if (data.atYard) {
        categories.push({name: "Trailer at " + data.yard.company.businessId + " Yard", icon: yardIcon, route: "yard" });
    }
    if (data.claim) {
        categories.push({ name: "Trailer with Claim", icon: claimIcon, route: "claim" });
    }
    if (data.silent) {
        categories.push({ name: "Silent Trailer", icon: silentIcon, route: "silent" });
    }
    if (data.inactive) {
        categories.push({ name: "Inactive Trailer", icon: inactiveIcon, route: "inactive" });
    }
    if (data.repair) {
        categories.push({ name: "Trailer on Repair", icon: repairsIcon, route: "repair" });
    }
    if (data.mismatch) {
        categories.push({ name: "Trailer location mismatched with TMW", icon: mismatchIcon, route: "mismatch" });
    }

    let trailerIssue = "";
    if (data.issueType === ISSUE_TYPE.DETACHED) {
        trailerIssue = <span className="orangeColorText">Detached trailer</span>
    } else if (data.issueType === ISSUE_TYPE.MISSING) {
        trailerIssue = <span className="redColorText">Missing trailer</span>
    }

    if (data.unaccounted) {
        categories.push({ name: "Unaccounted Trailer", icon: unaccountedTrailerIcon, route: "unaccounted" });
    }

    return (
        <>
            { trailerIssue }
            { trailerIssue && categories && ", " }
            { !categories.length && '-' }
            { categories.map(category => (
                <DataTableLink key={ category.name }
                               styled={ false }
                               to={ `/trailer-audit/${ data.trailerReport.id }/${ category.route }` }>
                    <Chip icon={ category.icon }>
                        { category.name }
                    </Chip>
                </DataTableLink>
            ))}
        </>
    );
}

function getDropYard(data) {
    if (data.atDropYard) {
        const linkTo = {
            pathname: `/trailer-audit/${ data.trailerReport.id }/drop-yard/${ data.yard.id }`,
            state: data.yard.company
        };

        return (
            <DataTableLink styled={ false } to={ linkTo }>
                <Chip icon={ dropYardIcon }>
                    { data.yard.company.businessId }
                </Chip>
            </DataTableLink>
        );
    }

    return "-";
}
