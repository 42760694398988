import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from 'components/input/DatePicker';
import { Input } from 'components/input/Input';
import { getCurrentDate, getEndOfDay, getStartOfDay } from 'global/services/DateTimeService';

import './DetailView.scss';

export default class MeasurementsFilter extends Component {

    static propTypes = {
        value: PropTypes.object.isRequired,
        onChange: PropTypes.func,
        loading: PropTypes.bool
    };

    static defaultProps = {
        onChange: () => null
    };

    constructor(props) {
        super(props);

        this.onFilterChange = this.onFilterChange.bind(this);
    }

    onFilterChange(partialValue, reloadData = true) {
        this.props.onChange(
            { ...this.props.value, ...partialValue },
            reloadData
        );
    }

    render() {
        const triggerSearch = () => this.props.onChange(this.props.value, true);

        return (
            <div className="measurements-filter-container">
                <div className="order-filter">
                    <div>
                        <div>Search by Order ID</div>
                        <Input className="order-input"
                               placeholder="Enter Order ID..."
                               value={ this.props.value.assignmentBusinessId }
                               onChange={ e => this.onFilterChange({ assignmentBusinessId: e.target.value }, false) }
                               onKeyPress={ e => e.key === 'Enter' && triggerSearch() }
                               leftIcon={ <FontAwesomeIcon className="search-icon"
                                                           icon={ faSearch }
                                                           onClick={ triggerSearch } /> } />
                    </div>
                    <Loader type="TailSpin"
                            color="#289AC2"
                            height={ 34 }
                            width={ 34 }
                            visible={ this.props.loading } />
                </div>

                <div className="date-filter">
                    <div>
                        <div>Start Date</div>
                        <DatePicker className="start-date-picker"
                                    value={ this.props.value.from }
                                    onChange={ from => this.onFilterChange({ from: from ? getStartOfDay(from) : undefined }) }
                                    max={ this.props.value.to || getEndOfDay(getCurrentDate()) } />
                    </div>
                    <div>
                        <div>End Date</div>
                        <DatePicker className="end-date-picker"
                                    value={ this.props.value.to }
                                    onChange={ to => this.onFilterChange({ to: to ? getEndOfDay(to) : undefined }) }
                                    min={ this.props.value.from }
                                    max={ getEndOfDay(getCurrentDate()) } />
                    </div>
                </div>
            </div>
        );
    }
}
