import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AsyncSelect from 'react-select/async';

import { closeIssue } from "global/constants";
import { closeReasons } from 'global/services/CloseIssueOptionsProvider';
import AuthContext from "AuthContext";
import SimpleContainer from 'components/container/SimpleContainer';

import './CloseIssuePopup.scss';

export default class CloseIssuePopup extends Component {
    constructor() {
        super();

        this.state = {
            note: '',
            closeReason: null,
            submittable: false,
            lastWarning: false,
            characterCount: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                note: this.state.note || '',
                closeReason: this.state.closeReason || null,
                submittable: this.state.submittable || false,
                lastWarning: !prevProps.showModal ? false : this.state.lastWarning,
                characterCount: this.state.characterCount || 0,
            });
        }
    }

    updateReason = (props) => {
        this.setState({
            closeReason: props.value
        }, function () {
            this.setState({
                note: '',
                submittable: !this.state.closeReason.extended,
                characterCount: 0
            });
        });
    }

    handleSubmit = async (e) => {
        if (!this.state.lastWarning) {
            e.target.blur();
            this.setState({
                lastWarning: true
            })
        } else {
            await this.context.post(closeIssue, {
                issue: this.props.issue,
                closeReason: this.state.closeReason,
                note: this.state.note.trim() || null
            });

            this.props.closeModal();
            document.location.reload();
        }
    }

    handleNote = (event) => {
        const value = event.target.value;
        this.setState({
            note: value,
            characterCount: value.length,
            submittable: value && /\S/.test(value)
        });
    }

    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.props.closeModal}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="close-modal"
            >
                <SimpleContainer className="modal-container" modal title="CLOSE ISSUE">
                    {this.state.lastWarning ? 
                    <>
                        <div className="last-warning-header">
                            Close issue?
                        </div>
                        <div className="last-warning-text">
                            Are you sure you want to <span className="bold">permanently</span> close <span className="bold">“{this.props.issueType}”</span> for <span className="bold">Trailer #{this.props.trailerId}</span>?
                            { this.props.issue.leg && (
                                <>
                                    &nbsp;This is for <span className="bold">Leg #{ this.props.issue.leg.businessId }</span>
                                    { this.props.issue.assignment && (
                                        <>
                                            &nbsp;of <span className="bold">Order #{ this.props.issue.assignment.businessId }</span>
                                        </>
                                    ) }
                                    .
                                </>
                            ) }
                        </div>
                    </> : 
                    <>
                        <div className="warning-text">
                            *Closing this alert & issue means that you have <span className="bold">VERIFIED</span> all content and you understand that this will <span className="bold">STOP</span> alerts for the rest of the duration of the leg of the trip
                        </div>
                        <div className="header">
                            <p className="heading-label">Trailer ID: </p>
                            <p className="info">#{this.props.trailerId}</p>
                        </div>
                        <div className="header">
                            <p className="heading-label">Issue type: </p>
                            <p className="info">{this.props.issueType}</p>
                        </div>
                        <hr style={{ marginTop: 16 }}></hr>
                        <p className="action-label">Reason for closing issue:</p>
                        <AsyncSelect
                            className="basic-single-reason"
                            classNamePrefix="select-reason"
                            cacheOptions
                            loadOptions={closeReasons.bind(this)}
                            defaultOptions
                            defaultValue={null}
                            isSearchable={false}
                            onChange={this.updateReason}
                        />
                        {
                            this.state.closeReason && this.state.closeReason.extended &&
                            <>
                                <p className="action-label">Note: <span className="required-text">(required)</span> </p>
                                <textarea
                                    value={this.state.note}
                                    onChange={this.handleNote}
                                    className="reason-box"
                                    maxLength="240"
                                    required
                                    placeholder="Please be as specific and detailed as possible and write down the reason for closing issue for this trailer..."
                                />
                                <div className="character-count">
                                    {this.state.characterCount}/240
                                </div>
                            </>
                        }
                    </>}
                    <div className="buttons">
                        <Button variant="light" onClick={this.props.closeModal} className="cancel-button">Cancel</Button>
                        <Button style={this.state.lastWarning ? { width: 138 } : null} as="input" variant="danger" className="close-button" type="submit" value={this.state.lastWarning ? "Yes, close issue" : "Close issue"} disabled={!this.state.submittable} onClick={this.handleSubmit} readOnly></Button>
                    </div>
                </SimpleContainer>
            </Modal>
        );
    }
}

CloseIssuePopup.contextType = AuthContext;
