import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { withRouter } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faComment,
    faExclamationCircle,
    faEye,
    faInfoCircle,
    faQuestionCircle,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { faAlarmSnooze } from '@fortawesome/pro-solid-svg-icons';
import SimpleContainer from 'components/container/SimpleContainer';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';

import { reactPlugin } from 'AppInsights';
import AuthContext from "AuthContext";
import DataTableComp from 'components/datatable/DataTableComp';
import AssignmentCell from 'components/datatable/cell/AssignmentCell';
import TrailerCell from 'components/datatable/cell/TrailerCell';
import ActiveSelection from "components/input/ActiveSelection";
import TeamIcon from "components/team-icon/TeamIcon";
import CanAccess from "components/can-access/CanAccess";
import { defaultGridRecordsNumber, issuesDetailsUrl, mapSort, SortParam } from 'global/constants';
import { getIssuePermissionsForAction, issueSnoozeable } from 'global/services/IssueTypeService';
import { getCurrentDate as currentDate, getDifferenceBetweenDates } from "global/services/DateTimeService";
import { mapIssuesFromResponse } from "global/services/IssueApiService";
import QueryParamService from "global/services/QueryParamService";
import { PERMISSION } from 'global/utils/auth';
import deleteComment from 'global/utils/deleteComment';
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';
import { ReactComponent as ReeferControlSvg } from 'files/reefer-control-icon/reefer_control_icon.svg';

import './LowFuelLevel.scss';

const $ = require("jquery");

const CACHE = {};

class LowFuelLevel extends Component {
    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);
        this.columnNames = {
            0: SortParam.ASSIGNMENT,
            1: SortParam.TRAILER,
            2: SortParam.COMPANY,
            3: SortParam.DRIVER,
            6: SortParam.START_TIME
        };

        this.columns = [
            { title: "Order #", data: "orderId" },
            { title: "Trailer #", data: "trailerId" },
            { title: "Customer ID", data: "customerId" },
            { title: "Driver", data: "driver" },
            { title: "Status", data: "status" },
            { title: "Last known position", data: "address" },
            { title: "Created", data: "created" },
            { title: "Fuel level", data: "fuelLevel" },
            { title: "Alerts", data: "alerts" },
            { title: "Resolved?", data: "resolved" },
            { title: "Action", data: "action" },
        ];

        this.sortingRule = [];
        this.data = Array.from([]);

        this.columnDefs = [
            { width: "5%", "targets": [0, 1, 7] },
            { width: "10%", "targets": [2, 3, 4, 6, 8] },
            { width: "15%", "targets": [9] },
            { width: "20%", "targets": [5] },
            { width: 70, "targets": [10] },
            { className: "dt-align-center", targets: [10] },
            { className: "dt-align-left", targets: [3, 4, 5, 6, 7, 8, 9] },
            { className: "dt-align-right", targets: [0, 1] },
            {
                orderable: false,
                targets: [4, 5, 7, 8, 9, 10]
            },
            {
                targets: 0,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <AssignmentCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 1,
                createdCell: (td, _cellData, rowData) => ReactDOM.render(
                    <TrailerCell rowData={ rowData } permissions={ context.permissions } />,
                    td
                )
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="centerAlign" style={{ marginRight: "2%" }}>
                            <TeamIcon color={rowData.division} />
                            &nbsp;&nbsp;
                            <span>{rowData.driver}</span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let mainText = "";
                    let otherText = "";
                    if (rowData.inactive) {
                        mainText = "Inactive \xa0";
                        otherText = ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                        className += "bold orangeColorText";
                    } else if (rowData.status == null) {
                        mainText = "Unknown"
                        className += "bold redColorText"
                    } else if (rowData.status === true) {
                        mainText = "Moving"
                    } else {
                        mainText = "Stopped"
                        className += "bold orangeColorText"
                    }

                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <div className={className}>
                                {mainText}
                            </div>
                            <span className={className}>
                                {otherText}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex" style={{ minWidth: '40px' }}>
                            {rowData.metaData.position || rowData.address || "Unknown"}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = `bold ${rowData.resolved ? "greenColorText" : "redColorText"}`;

                    let displayedFuelLevel;
                    if (rowData.fuelLevel || rowData.fuelLevel === 0) {
                        displayedFuelLevel = rowData.fuelLevel + "%";
                    } else {
                        className = "bold orangeColorText"
                        displayedFuelLevel = "Unknown";
                    }

                    ReactDOM.render(
                        <div>
                            <span className={className}>
                                {displayedFuelLevel}
                            </span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "greenColorText bold";
                    let coloredText = "";
                    let otherText = "";
                    if (!rowData.alerts) {
                        coloredText = "No";
                        className = "redColorText bold";
                        otherText = `  (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`
                    } else if (rowData.alerts === "Escalation") {
                        coloredText = "Escalation"
                        className = "bold"
                    } else if (rowData.alerts === "Warning" && !rowData.snoozed) {
                        coloredText = "Warning";
                        className = "bold"
                    } else {
                        coloredText = "Warning";
                        className = "bold"
                    }
                    if (rowData.snoozed) {
                        otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                    }
                    const showIconStyle = coloredText === "No" ? { display: "none" } : {};
                    ReactDOM.render(
                        <div className="notifications" style={{ minWidth: '80px' }}>
                            <div className={`notification-level ` + className}>
                                {coloredText}
                            </div>
                            {
                                <div className="notification-info" style={showIconStyle}>
                                    &nbsp;&nbsp;<FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
                                </div>
                            }
                            {
                                otherText !== "" &&
                                <div className="greyColorText italic snoozed-info">
                                    <br /> {otherText}
                                </div>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, rowData, row, col) => {
                    let className = "";
                    let coloredText = "";
                    let otherText = "";
                    if (!rowData.resolved) {
                        className += "bold redColorText";
                        coloredText = "No";
                        otherText += ` (for ${getDifferenceBetweenDates(rowData.created, currentDate())})`;
                    } else if (rowData.resolved) {
                        className += "greenColorText bold";
                        if (rowData.issue.state === "CLOSED") {
                            coloredText = "Manually";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.closed.closedAt)})`;
                        } else {
                            coloredText = "Yes";
                            otherText = `(after ${getDifferenceBetweenDates(rowData.created, rowData.resolved)})`;
                        }
                    }
                    ReactDOM.render(
                        <div className="resolvedContainer">
                            <span className={className}>
                                {rowData.resolved ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faExclamationCircle} />}
                                &nbsp;&nbsp;
                                {coloredText}&nbsp;&nbsp;
                                <FontAwesomeIcon icon={faInfoCircle} className="info-icon" style={!rowData.resolved ? { display: "none" } : { marginRight: 4 }} />
                            </span>
                            <span className="greyColorText italic">{otherText}</span>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 10,
                className: "justify",
                createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass = "";
                    let snoozeIconClass = "";
                    if (issueSnoozeable(rowData.issue) === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeable(rowData.issue) === 2) {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                        snoozeDivClass = "disabled-snooze";
                        snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon"
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                    const isRelevant = rowData.issue.relevant;

                    ReactDOM.render(
                        <div className="flex-always">
                            <div className="seen" id={rowData.id}>
                                <FontAwesomeIcon icon={faEye} className={seenIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={getIssuePermissionsForAction(rowData.issue, "snooze")}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={snoozeDivClass}>
                                            {snoozeIconClass === "disabled-icon" ? <svg width="16" height="12" style={{marginTop: "2px"}} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                                    <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z"/>
                                                    <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z"/>
                                                    <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z"/>
                                                    <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z"/>
                                                    <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z"/>
                                                    <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z"/>
                                                </svg>
                                                : <FontAwesomeIcon icon={faAlarmSnooze} className={snoozeIconClass}/>}
                                        </div>
                                    </>
                                }
                            />
                            &nbsp; &nbsp;
                            <div className="comment">
                                <FontAwesomeIcon icon={faComment} className={commentIconClass}></FontAwesomeIcon>
                            </div>
                            <CanAccess
                                permissions={context.permissions}
                                action={getIssuePermissionsForAction(rowData.issue, "close")}
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <div className={isRelevant ? "resolve" : "disabled-resolve"}>
                                            <FontAwesomeIcon icon={faTimesCircle} className={isRelevant ? "resolve-icon" : "disabled-icon"} />
                                        </div>
                                    </>
                                }
                            />
                            <CanAccess
                                permissions={ context.permissions } 
                                action={ PERMISSION.TRAILER.COMMANDS.WRITE }
                                yes={
                                    <>
                                        &nbsp; &nbsp;
                                        <ReeferControlSvg />
                                    </> 
                                }
                            />
                        </div>,
                        td
                    );
                }
            }
        ];

        let relevantIssues = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : undefined;

        let buttonNode;
        if (!!relevantIssues || relevantIssues === undefined) {
            relevantIssues = true;
            buttonNode = $(`button[id=relevant]`);
        } else {
            relevantIssues = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addActiveLegToQueryString(window.location.search.slice(1), relevantIssues);

        let currentPage = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page') ? parseInt(QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page')) : 1;

        const selectedTeams = JSON.parse(localStorage.getItem('selectedTeams'));
        QueryParamService.addTeamsToQueryString(window.location.search.slice(1), selectedTeams);

        this.state = {
            account: this.props.account,
            data: this.data,
            columns: this.columns,
            columnDefs: this.columnDefs,
            sort: [],
            activeLeg: relevantIssues,
            sortingRule: this.sortingRule,
            issuesLoaded: false,
            dataUpdated: false,
            showModal: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).lowFuelIssues !== undefined ? JSON.parse(localStorage.getItem('showModals')).lowFuelIssues : true,
            doNotShowTutorial: localStorage.getItem('showModals') && JSON.parse(localStorage.getItem('showModals')).lowFuelIssues !== undefined ? !JSON.parse(localStorage.getItem('showModals')).lowFuelIssues : false,
            page: currentPage,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            }
        }
        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
    }

    async componentDidMount() {
        await this.fetchAllIssues();
    }

    componentDidUpdate(previousProps) {
        const oldTeam = (previousProps || {}).team;
        const newTeam = (this.props || {}).team;

        if (oldTeam !== newTeam) {
            this.handlePage(1, true);
        }
    }

    onReceiveNewComment(comment) {
        let newData = this.state.data;
        newData.find((data) => data.issue.id === comment.issue.id).comments.unshift(comment);

        this.setState({
            data: newData
        });
    }

    onDeleteComment(deletedComment) {
        let newData = this.state.data;

        this.setState({
            allIssues: deleteComment(deletedComment, newData)
        });
    }

    async handlePage(page, force) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            const queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, page);

            await this.fetchAllIssues(force);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleLastAvailablePage = async (lastAvailablePage) => {
        await this.handlePage(parseInt(lastAvailablePage));
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value),
            },
            page: 1,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;

        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchAllIssues();
            }, 1000);
        }

        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    onCheckboxChange() {
        this.setState({
            doNotShowTutorial: !this.state.doNotShowTutorial
        });
    }

    openTutorialModal() {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        const existingItem = JSON.parse(localStorage.getItem('showModals'));
        localStorage.setItem('showModals', JSON.stringify({ ...existingItem, ...{ lowFuelIssues: !this.state.doNotShowTutorial } }));
        this.setState({
            showModal: false
        });
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }

        let queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    changeActiveness = (relevantIssues) => {
        let queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevantIssues);

        this.setState({
            activeLeg: relevantIssues,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber,
            },
            dataUpdated: false
        }, async () => {
            await this.fetchAllIssues();
        });
    }

    async fetchAllIssues(force) {
        let cachingIndex = this.context.team.slice().join('_');
        cachingIndex += this.state.activeLeg ? '_relevant' : '_irrelevant';

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        let sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
        let tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }

            const response = await this.context.get(issuesDetailsUrl, {
                types: ["LOW_FUEL_ALARM"],
                active: this.state.activeLeg,
                sort: sort,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                console.error(response.message);
                return {};
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    notificationsLoaded: true,
                    dataUpdated: true
                });
                return;
            }

            const totalPageNumber = Math.ceil(response.available / response.pageSize);
            const currentPage = this.state.page || 1;
            if (currentPage > totalPageNumber && totalPageNumber !== 0) {
                this.handleLastAvailablePage(totalPageNumber);
                return;
            }

            const issues = mapIssuesFromResponse(data);
            const newData = mapGridResponseData(issues, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    issues: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                data: Array.from([]),
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                issuesLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    render() {
        return (
            <div className="container-fluid page low-fuel-level-issues">
                <div className="row">
                    <div className="col">
                        <nav>
                            <ol className="breadcrumb">
                                <CanAccess
                                    action={PERMISSION.PAGE.ALL_ISSUES}
                                    yes={<li className="breadcrumb-item"><a href="/issues/all">All issues</a></li>}
                                    no={<li className="breadcrumb-item"><a href="/">Fleet Overview</a></li>}
                                />
                                <li className="breadcrumb-item active"><a href="#!">Low fuel level</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="heading-div">
                            <p className="heading">
                                Low fuel level
                            </p>
                            <div className="tutorial-info">
                                <p className="tutorial-text"> <FontAwesomeIcon className="tutorial-icon" icon={faQuestionCircle} onClick={this.openTutorialModal.bind(this)}></FontAwesomeIcon></p>
                            </div>
                            &nbsp; &nbsp; <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.issuesLoaded || !this.state.dataUpdated} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="active-toggle">
                            <ActiveSelection activeCallback={this.changeActiveness.bind(this)} initiallyActive={this.state.activeLeg} />
                        </div>
                    </div>
                </div>
                {(!this.state.doNotShowTutorial || this.state.showModal) &&
                    <Modal
                        show={this.state.showModal}
                        onHide={this.handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        backdrop="static"
                        keyboard={false}
                        className="tutorial-modal-fuel"
                    >
                        <SimpleContainer className="tutorial-modal-fuel" title="LOW FUEL ALERTS TUTORIAL" modal={true}>
                            <Modal.Body className="tutorial-modal-fuel">
                                <div className="row">
                                    <div className="highlightModalText">
                                        Please first finish steps 1 and 2,  then ONE of the next 3 options depending on what you found.
                                    </div>
                                    <div className="col-sm-4 col-xs-12">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="align-top">
                                                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M18 11.8125C14.5547 11.8125 11.8125 14.5547 11.8125 18C11.8125 21.4453 14.5547 24.1875 18 24.1875C21.4453 24.1875 24.1875 21.4453 24.1875 18C24.1875 14.5547 21.4453 11.8125 18 11.8125ZM18 20.8125C16.4531 20.8125 15.1875 19.5469 15.1875 18C15.1875 16.4531 16.4531 15.1875 18 15.1875C19.5469 15.1875 20.8125 16.4531 20.8125 18C20.8125 19.5469 19.5469 20.8125 18 20.8125ZM34.3125 16.3125H31.3594C30.5859 10.1953 25.8047 5.41406 19.6875 4.64062V1.6875C19.6875 0.773438 18.9141 0 18 0C17.0859 0 16.3125 0.773438 16.3125 1.6875V4.64062C10.1953 5.41406 5.41406 10.1953 4.64062 16.3125H1.6875C0.773438 16.3125 0 17.0859 0 18C0 18.9141 0.773438 19.6875 1.6875 19.6875H4.64062C5.41406 25.8047 10.1953 30.5859 16.3125 31.3594V34.3125C16.3125 35.2266 17.0859 36 18 36C18.9141 36 19.6875 35.2266 19.6875 34.3125V31.3594C25.8047 30.5859 30.5859 25.8047 31.3594 19.6875H34.3125C35.2266 19.6875 36 18.9141 36 18C36 17.0859 35.2266 16.3125 34.3125 16.3125ZM18 28.125C12.4453 28.125 7.875 23.5547 7.875 18C7.875 12.4453 12.4453 7.875 18 7.875C23.5547 7.875 28.125 12.4453 28.125 18C28.125 23.5547 23.5547 28.125 18 28.125Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 1:
                                                        </p>
                                                        <p className="modalText">
                                                            Open Thermoking to confirm trailer’s location & fuel level
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="vl-container">
                                                        <div style={{ height: 24 }} class="vl"></div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="align-top">
                                                        <svg width="45" height="32" viewBox="0 0 45 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M43.3125 21.375H40.5V4.5C40.5 2.03906 38.4609 0 36 0H4.5C2.03906 0 0 2.03906 0 4.5V20.25C0 22.7109 2.03906 24.75 4.5 24.75H6.75C6.75 28.4766 9.77344 31.5 13.5 31.5C17.2266 31.5 20.25 28.4766 20.25 24.75H43.3125C44.2266 24.75 45 23.9766 45 23.0625C45 22.1484 44.2266 21.375 43.3125 21.375ZM13.5 28.125C11.6719 28.125 10.125 26.5781 10.125 24.75C10.125 22.9219 11.6719 21.375 13.5 21.375C15.3281 21.375 16.875 22.9219 16.875 24.75C16.875 26.5781 15.3281 28.125 13.5 28.125ZM37.125 21.375H19.3359C18.1406 19.3359 15.9609 18 13.5 18C11.0391 18 8.85938 19.3359 7.66406 21.375H4.5C3.86719 21.375 3.375 20.8828 3.375 20.25V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H36C36.6328 3.375 37.125 3.86719 37.125 4.5V21.375ZM23.625 5.625C22.7109 5.625 21.9375 6.39844 21.9375 7.3125V17.4375C21.9375 18.3516 22.7109 19.125 23.625 19.125C24.5391 19.125 25.3125 18.3516 25.3125 17.4375V7.3125C25.3125 6.39844 24.5391 5.625 23.625 5.625ZM16.875 5.625C15.9609 5.625 15.1875 6.39844 15.1875 7.3125V14.0625C15.1875 14.9766 15.9609 15.75 16.875 15.75C17.7891 15.75 18.5625 14.9766 18.5625 14.0625V7.3125C18.5625 6.39844 17.7891 5.625 16.875 5.625ZM10.125 5.625C9.21094 5.625 8.4375 6.39844 8.4375 7.3125V14.0625C8.4375 14.9766 9.21094 15.75 10.125 15.75C11.0391 15.75 11.8125 14.9766 11.8125 14.0625V7.3125C11.8125 6.39844 11.0391 5.625 10.125 5.625ZM30.375 5.625C29.4609 5.625 28.6875 6.39844 28.6875 7.3125V17.4375C28.6875 18.3516 29.4609 19.125 30.375 19.125C31.2891 19.125 32.0625 18.3516 32.0625 17.4375V7.3125C32.0625 6.39844 31.2891 5.625 30.375 5.625Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 2:
                                                        </p>
                                                        <p className="modalText">
                                                            Open TMW to verify if trailer is under a load / with a Driver
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="modalText" style={{ marginLeft: "20%", fontWeight: 600 }} colSpan="5">
                                                        <div className="modalStepText" style={{ color: "#289AC2", marginBottom: 5 }}>
                                                            OPTION 1:
                                                        </div>
                                                        If a Driver currently has the trailer
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            Call the Driver and instruct him to refuel
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td className="modalText" style={{ marginLeft: "20%", fontWeight: 600 }} colSpan="5">
                                                        <div className="modalStepText" style={{ color: "#289AC2", marginBottom: 5 }}>
                                                            OPTION 2:
                                                        </div>
                                                        If the trailer is dropped at a customer/drop location…
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="41" height="32" viewBox="0 0 41 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M38.9531 0.0735572L27 4.50324L14.9062 0.214182C14.4844 0.0735572 13.9922 0.00324466 13.5 0.00324466C13.0078 0.00324466 12.5156 0.0735572 12.0938 0.214182L1.40625 3.94074C0.5625 4.29231 0 5.13606 0 6.05012V30.3782C0 31.1517 0.773438 31.7142 1.54688 31.4329L13.5 27.0032L25.5938 31.2923C26.5078 31.5736 27.4922 31.5736 28.4062 31.2923L39.0938 27.5657C39.9375 27.2142 40.5 26.3704 40.5 25.4564V1.12824C40.5 0.354807 39.7266 -0.207693 38.9531 0.0735572ZM15.75 4.08137L24.75 7.31574V27.4251L15.75 24.1907V4.08137ZM3.375 27.1439V6.82356L12.375 3.65949V23.8392H12.3047L3.375 27.1439ZM37.125 24.6829L28.125 27.847V7.66731H28.1953L37.125 4.36262V24.6829Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            Use KeepTruckin to find any Drivers who are nearby
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="45" height="32" viewBox="0 0 45 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M43.3125 21.375H40.5V4.5C40.5 2.03906 38.4609 0 36 0H4.5C2.03906 0 0 2.03906 0 4.5V20.25C0 22.7109 2.03906 24.75 4.5 24.75H6.75C6.75 28.4766 9.77344 31.5 13.5 31.5C17.2266 31.5 20.25 28.4766 20.25 24.75H43.3125C44.2266 24.75 45 23.9766 45 23.0625C45 22.1484 44.2266 21.375 43.3125 21.375ZM13.5 28.125C11.6719 28.125 10.125 26.5781 10.125 24.75C10.125 22.9219 11.6719 21.375 13.5 21.375C15.3281 21.375 16.875 22.9219 16.875 24.75C16.875 26.5781 15.3281 28.125 13.5 28.125ZM37.125 21.375H19.3359C18.1406 19.3359 15.9609 18 13.5 18C11.0391 18 8.85938 19.3359 7.66406 21.375H4.5C3.86719 21.375 3.375 20.8828 3.375 20.25V4.5C3.375 3.86719 3.86719 3.375 4.5 3.375H36C36.6328 3.375 37.125 3.86719 37.125 4.5V21.375ZM23.625 5.625C22.7109 5.625 21.9375 6.39844 21.9375 7.3125V17.4375C21.9375 18.3516 22.7109 19.125 23.625 19.125C24.5391 19.125 25.3125 18.3516 25.3125 17.4375V7.3125C25.3125 6.39844 24.5391 5.625 23.625 5.625ZM16.875 5.625C15.9609 5.625 15.1875 6.39844 15.1875 7.3125V14.0625C15.1875 14.9766 15.9609 15.75 16.875 15.75C17.7891 15.75 18.5625 14.9766 18.5625 14.0625V7.3125C18.5625 6.39844 17.7891 5.625 16.875 5.625ZM10.125 5.625C9.21094 5.625 8.4375 6.39844 8.4375 7.3125V14.0625C8.4375 14.9766 9.21094 15.75 10.125 15.75C11.0391 15.75 11.8125 14.9766 11.8125 14.0625V7.3125C11.8125 6.39844 11.0391 5.625 10.125 5.625ZM30.375 5.625C29.4609 5.625 28.6875 6.39844 28.6875 7.3125V17.4375C28.6875 18.3516 29.4609 19.125 30.375 19.125C31.2891 19.125 32.0625 18.3516 32.0625 17.4375V7.3125C32.0625 6.39844 31.2891 5.625 30.375 5.625Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 4:
                                                        </p>
                                                        <p className="modalText">
                                                            Arrange to have a Driver pick up the trailer to refuel it and return to the customer location
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4 col-xs-6">
                                        <table style={{ borderCollapse: "separate", borderSpacing: "10px", margin: "0 auto" }}>
                                            <tbody>
                                                <tr>
                                                    <td className="modalText" style={{ marginLeft: "20%", fontWeight: 600 }} colSpan="5">
                                                        <div className="modalStepText" style={{ color: "#289AC2", marginBottom: 5 }}>
                                                            OPTION 3:
                                                        </div>
                                                        If the trailer is at the yard
                                                    </td>
                                                </tr>
                                                <tr />
                                                <tr>
                                                    <td colSpan="2" className="vllContainer">
                                                        <div className="vll"></div>
                                                    </td>
                                                    <td className="align-top">
                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M29.7815 1.4306L23.8946 0.0740488C22.5447 -0.238522 21.1698 0.461636 20.6261 1.73692L17.9076 8.0821C17.4077 9.24487 17.7452 10.6202 18.7263 11.4204L21.2261 13.4646C19.4387 16.7403 16.739 19.4409 13.4643 21.2226L11.4208 18.722C10.6209 17.7405 9.24601 17.403 8.08362 17.9031L1.73425 20.6224C0.459377 21.1663 -0.234304 22.5416 0.0719154 23.8919L1.42803 29.7807C1.728 31.0873 2.87789 32 4.21525 32C19.52 32.0062 32 19.6097 32 4.21873C32 2.88093 31.0876 1.73067 29.7815 1.4306ZM4.33399 29.0056L3.02787 23.3355L9.16476 20.7037L12.6457 24.9609C18.8201 22.0603 22.0572 18.8345 24.9569 12.6456L20.7011 9.1636L23.3321 3.02471L29.0003 4.33126C28.9378 17.9343 17.9326 28.9431 4.33399 29.0056Z" fill="#828A95" />
                                                        </svg>
                                                    </td>
                                                    <td style={{ paddingLeft: 10 }} colSpan="2">
                                                        <p className="modalText modalStepText" style={{ paddingBottom: "4px" }}>
                                                            STEP 3:
                                                        </p>
                                                        <p className="modalText">
                                                            Reach out to Security through Phone or Teams to have them get the soonest local to refuel
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <Button variant="continue" onClick={this.handleClose.bind(this)} style={{ width: "240px", height: "36px", marginTop: "15px" }}>
                                    Got it!
                                </Button>
                                <div className="form-check">
                                    <label className={`checkBoxText ${this.state.doNotShowTutorial ? "bold" : ""}`}>
                                        <input
                                            type="checkbox"
                                            name={this.state.label}
                                            checked={this.state.doNotShowTutorial}
                                            onChange={this.onCheckboxChange.bind(this)}
                                            className="form-check-input"
                                        />
                                        Do not show this tip again
                                    </label>
                                </div>
                            </Modal.Body>
                        </SimpleContainer>
                    </Modal>}
                <div className="row">
                    <div className="col">
                        <div className="low-fuel-level-div">
                            {this.state.issuesLoaded && <DataTableComp
                                tableId="lowFuelLevelIssues"
                                account={this.state.account}
                                columns={this.state.columns}
                                orderRule={this.state.sortingRule}
                                data={this.state.data}
                                onReceiveNewComment={this.onReceiveNewComment}
                                onDeleteComment={this.onDeleteComment}
                                isDetailView={false}
                                columnDefs={this.state.columnDefs}
                                customTableClass="cell-border"
                                tableHeight="1000px"
                                handlePreviousPage={this.handlePreviousPage}
                                handleNextPage={this.handleNextPage}
                                handleFirstPage={this.handleFirstPage}
                                handleLastPage={this.handleLastPage}
                                handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                                handlePageChange={(event) => this.handlePageChange(event)}
                                pagingInfo={this.state.pagingInfo}
                                sortRule={this.state.sort.concat([])}
                                addSortingForColumn={this.addSortingForColumn.bind(this)}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, LowFuelLevel, "LowFuelLevel"));
