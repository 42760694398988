import { closeReasonsUrl } from "global/constants";

export const closeReasons = async function () {
    const response = await this.context.get(closeReasonsUrl, {});

    if (response.status === "error") {
        console.error(response.message);
        return [];
    }

    return response.data.map(closeReason => (
        {
            value: closeReason,
            label: closeReason.reason
        }
    ));
}
