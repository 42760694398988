import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import L from 'leaflet';
import {
    MapContainer,
    Marker as LeafletMarker,
    Popup as LeafletPopup,
    TileLayer,
    ZoomControl,
    LayersControl,
    FeatureGroup,
    useMap,
    useMapEvents,
    AttributionControl
} from 'react-leaflet';
import { createElementObject, createPathComponent, extendContext } from '@react-leaflet/core';
import PropTypes from 'prop-types';
import { CENTER_OF_US } from 'global/constants';
import defaultMarkerIcon from 'files/map-icons/default_marker_icon.svg';
import 'leaflet.markercluster/dist/leaflet.markercluster';

import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import './Map.scss';

L.Map.include({
    _initControlPos() {
        const corners = this._controlCorners = {};
        const baseClassName = 'leaflet-';
        const container = this._controlContainer = L.DomUtil.create('div', baseClassName + 'control-container', this._container);

        function createCorner(verticalSide, horizontalSide) {
            const className = baseClassName + verticalSide + ' ' + baseClassName + horizontalSide;
            corners[verticalSide + horizontalSide] = L.DomUtil.create('div', className, container);
        }

        createCorner('top', 'left');
        createCorner('top', 'center');
        createCorner('top', 'right');

        createCorner('middle', 'left');
        createCorner('middle', 'center');
        createCorner('middle', 'right');

        createCorner('bottom', 'left');
        createCorner('bottom', 'center');
        createCorner('bottom', 'right');
    }
});

export class Map extends Component {

    static propTypes = {
        height: PropTypes.number,
        defaultPosition: PropTypes.array, // [long, lat]
        defaultZoom: PropTypes.number,
        showLayersControl: PropTypes.bool,
        onLoad: PropTypes.func,
        onDragEnd: PropTypes.func,
        onZoomEnd: PropTypes.func,
        className: PropTypes.string
    };

    static defaultProps = {
        height: 608,
        defaultPosition: CENTER_OF_US,
        defaultZoom: 4,
        showLayersControl: false,
        onLoad: () => { /* */ },
        onDragEnd: () => { /* */ },
        onZoomEnd: () => { /* */ },
        className: ''
    };

    render() {
        const layersControlClassName = !this.props.showLayersControl && 'disable-layers-control';
        const tileLayer = {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            minZoom: 1,
            maxNativeZoom: 19,
            maxZoom: 20
        };

        return (
            <MapContainer
                style={ { height: this.props.height } }
                className={ `map ${ layersControlClassName } ${ this.props.className }` }
                zoomControl={ false }
                attributionControl={ false }
                center={ [ ...this.props.defaultPosition ].reverse() }
                zoom={ this.props.defaultZoom }
            >
                <TileLayer { ...tileLayer } />
                <ZoomControl position="topright" />
                <LayersControl position="bottomleft">
                    { this.props.children }
                </LayersControl>
                <AttributionControl position="bottomright" prefix={ false } />
                <MapEvents
                    onLoad={ this.props.onLoad }
                    onDragEnd={ this.props.onDragEnd }
                    onZoomEnd={ this.props.onZoomEnd }
                />
            </MapContainer>
        );
    }
}

export class Layer extends Component {

    static propTypes = {
        name: PropTypes.string.isRequired,
        show: PropTypes.bool
    };

    static defaultProps = {
        show: true
    };

    render() {
        return (
            <LayersControl.Overlay checked={ this.props.show } name={ this.props.name }>
                <FeatureGroup>
                    { this.props.children }
                </FeatureGroup>
            </LayersControl.Overlay>
        );
    }
}

export class Marker extends Component {

    static propTypes = {
        icon: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
        position: PropTypes.array.isRequired, // [long, lat]
        onBoundPopupOpen: PropTypes.func,
        onBoundPopupClose: PropTypes.func
    };

    static defaultProps = {
        icon: { iconUrl: defaultMarkerIcon, iconSize: [ 32, 32 ] },
        onBoundPopupOpen: () => { /* */ },
        onBoundPopupClose: () => { /* */ }
    };

    render() {
        let icon = this.props.icon;
        if (typeof this.props.icon === 'string') {
            icon = { iconUrl: this.props.icon };
        }

        return (
            <LeafletMarker
                icon={ new L.Icon(icon) }
                position={ [ ...this.props.position ].reverse() }
                eventHandlers={ {
                    popupopen: this.props.onBoundPopupOpen,
                    popupclose: this.props.onBoundPopupClose
                } }
            >
                { this.props.children }
            </LeafletMarker>
        );
    }
}

export class Popup extends Component {

    static propTypes = {
        className: PropTypes.string
    };

    static defaultProps = {
        className: ''
    };

    render() {
        const { className, children, ...rest } = this.props;

        return (
            <LeafletPopup className={ `custom-popup ${ className }` } closeButton={ false } { ...rest }>
                { children }
            </LeafletPopup>
        );
    }
}

CustomControl.propTypes = {
    position: PropTypes.oneOf([
        'topleft', 'topcenter', 'topright',
        'middleleft', 'middlecenter', 'middleright',
        'bottomleft', 'bottomcenter', 'bottomright'
    ])
};

CustomControl.defaultProps = {
    position: 'topleft'
};

export function CustomControl(props) {
    const map = useMap();

    const CustomControl = L.Control.extend({
        onAdd() {
            const div = L.DomUtil.create('div');
            ReactDOM.render(props.children, div);
            return div;
        }
    });

    useEffect(() => {
        const customControl = new CustomControl({ position: props.position });
        map.addControl(customControl);
        return () => map.removeControl(customControl);
        // eslint-disable-next-line
    }, []);

    return null;
}

export const MarkerCluster = createPathComponent(({ children, ...props }, ctx) => {
    const markerClusterGroup = L.markerClusterGroup({
        showCoverageOnHover: false,
        spiderfyOnMaxZoom: false,
        disableClusteringAtZoom: 18,
        iconCreateFunction: (cluster) => new L.DivIcon({
            html: `<div class="middle-circle"><div class="inner-circle"><span>${ cluster.getChildCount() }</span></div></div>`,
            className: 'custom-marker-cluster',
            iconSize: [ 40, 40 ]
        }),
        ...props
    });

    return createElementObject(markerClusterGroup, extendContext(ctx, { layerContainer: markerClusterGroup }));
});

function MapEvents({ onLoad, onDragEnd, onZoomEnd }) {
    const map = useMap();

    useMapEvents({
        dragend: onDragEnd,
        zoomend: onZoomEnd
    });

    useEffect(() => {
        if (map) {
            onLoad(map);
        }
        // eslint-disable-next-line
    }, [ map ]);

    return null;
}
